import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {create_slaugh_freezing, create_slaugh_explode, create_slaugh_packing, data_logist_slaught_explode, data_logist_slaught_packing, data_logist_slaught_freezing, deleteExplodeFunct, deletePackingFunct, deleteFreezingFunct, data_trazabilidad_meat, row_traza_lote } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";

  export default class ModalSlaughterhouseExtend extends React.Component {
      state={
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModalExplode:false,
    sendOpenModalPacking:false,
    sendOpenModalFreezing:false,
    data_lote_explode:[],
    data_lote_packing:[],
    data_lote_freezing:[],
    dsableBtn:false,
    dsableBtnTrans:false,
    dsableBtnTask:false,
    data_trnsport:[]
  }
    async componentDidMount(){
        
  }
  render(){

  return (
    <>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataSlaughterhouseExplode.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                            <SectionExplode getIdExplode={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataExplodeExtend={this.props.sendInfoProps.dataSlaughterhouseExplode} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>

            <Row>
            {
                  this.props.sendInfoProps.dataSlaughterhousePacking.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                        <SectionPacking getIdPacking={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataPackingExtend={this.props.sendInfoProps.dataSlaughterhousePacking} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>

            <Row>
            {
                  this.props.sendInfoProps.dataSlaughterhouseFreezing.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                        <SectionFreezing getIdFreezing={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataFreezingExtend={this.props.sendInfoProps.dataSlaughterhouseFreezing} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

export class SectionExplode extends React.Component {
  state={
                          explode_id:'noind',
                          lote_numero_explode:'',
                          fecha_despiece:'',
                          hora_inicio:'',
                          hora_final:'',
                          nro_canales:'',
                          nro_medias_canales:'',
                          nro_cuartos_canales:'',
                          carcasa_entera:'',
                          pierna:'',
                          paleta:'',
                          costillar:'',
                          otro_corte:'',
                          cantidad:'',
                          informacion_adicional_explode:'',
                          file_document_explode:undefined,
                      }
  async componentDidMount(){
    this.setInfoSlaughterhouseExplode(this.props.getIdExplode)
  }
            setDataCamposExplode = (data)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
                 if (data!=null) {
                  this.setState({
                          explode_id:(data.id=="null")?(null):(data.id),
                          lote_numero_explode:(data.lote_numero=="null")?(null):(data.lote_numero),
                          fecha_despiece:(data.fecha_despiece=="null")?(completeDate):(data.fecha_despiece),
                          hora_inicio:(data.hora_inicio=="null")?(timeString):(data.hora_inicio),
                          hora_final:(data.hora_final=="null")?(timeString):(data.hora_final),
                          nro_canales:( data.nro_canales=="null")?(null):( data.nro_canales),
                          nro_medias_canales:(data.nro_medias_canales=="null")?(null):(data.nro_medias_canales),
                          nro_cuartos_canales:(data.nro_cuartos_canales=="null")?(null):(data.nro_cuartos_canales),
                          carcasa_entera:(data.carcasa_entera=="null")?(null):(data.carcasa_entera),
                          pierna:(data.pierna=="null")?(null):(data.pierna),
                          paleta:(data.paleta=="null")?(null):(data.paleta),
                          costillar:(data.costillar=="null")?(null):(data.costillar),
                          otro_corte:(data.otro_corte=="null")?(null):(data.otro_corte),
                          cantidad:(data.cantidad=="null")?(null):(data.cantidad),
                          informacion_adicional_explode:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
                          file_document_explode:(data.archivo=="null")?(null):(data.archivo),
                  })
                }else{
                        this.setState({
                          explode_id:'noind',
                          lote_numero_explode:'',
                          fecha_despiece:completeDate,
                          hora_inicio:timeString,
                          hora_final:timeString,
                          nro_canales:'',
                          nro_medias_canales:'',
                          nro_cuartos_canales:'',
                          carcasa_entera:'',
                          pierna:'',
                          paleta:'',
                          costillar:'',
                          otro_corte:'',
                          cantidad:'',
                          informacion_adicional_explode:'',
                          file_document_explode:undefined,
                      })

                }
          }
       setInfoSlaughterhouseExplode = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = this.props.setParamsUrl
          var dataExplode = await data_logist_slaught_explode(this.props.dataExplodeExtend,id_set)
          this.setDataCamposExplode(dataExplode)
  }
  render(){
    return(

            <Row>
            <Col md={12}>
              <h3 className="mb-2">Registro de Despiece {this.props.getIndex+1}</h3>
            </Col>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label>
                    <br /><span>
                    {(this.state.lote_numero_explode=="undefined")?(null):(this.state.lote_numero_explode)}
                    </span>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Despiece
                  </label>
                  <br /><span>
                    {(this.state.fecha_despiece=="undefined")?(null):(this.state.fecha_despiece)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Inicio
                  </label>
                  <br /><span>
                    {(this.state.hora_inicio=="undefined")?(null):(this.state.hora_inicio)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora Final
                  </label>
                  <br /><span>
                  {(this.state.hora_final=="undefined")?(null):(this.state.hora_final)}
                </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Nro de Canales
                  </label>
                  <br /><span>
                    {(this.state.nro_canales=="undefined")?(null):(this.state.nro_canales)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Nro de Medias Canales
                  </label>
                  <br /><span>
                    {(this.state.nro_medias_canales=="undefined")?(null):(this.state.nro_medias_canales)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nro de Cuartos Canales
                  </label>
                  <br /><span>
                    {(this.state.nro_cuartos_canales=="undefined")?(null):(this.state.nro_cuartos_canales)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Carcasa Entera

                  </label>
                  <br /><span>
                    {(this.state.carcasa_entera=="undefined")?(null):(this.state.carcasa_entera)}
                   </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                   Pierna
                  </label>
                  <br /><span>
                    {(this.state.pierna=="undefined")?(null):(this.state.pierna)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Paleta
                  </label>
                  <br /><span>
                    {(this.state.paleta=="undefined")?(null):(this.state.paleta)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Costillar
                  </label>
                  <br /><span>
                    {(this.state.costillar=="undefined")?(null):(this.state.costillar)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Otro Corte

                  </label>
                  <br /><span>
                    {(this.state.otro_corte=="undefined")?(null):(this.state.otro_corte)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Cantidad
                  </label>
                  <br /><span>
                    {(this.state.cantidad=="undefined")?(null):(this.state.cantidad)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <br /><span>
                    {(this.state.informacion_adicional_explode=="undefined")?(null):(this.state.informacion_adicional_explode)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                <br />
                {(this.state.file_document_explode==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_explode}>{String(this.state.file_document_explode).split('/').pop()}</a>)}

              </Col>
            </Row>
      )
  }
}

export class SectionPacking extends React.Component {
  state={
                packing_id:'noind',
                lote_numero_packing:'',
                fecha_packing:'',
                producto:'',
                piezas_caja:'',
                cantidad_cajas:'',
                informacion_adicional_packing:'',
                file_document_packing:undefined,
            }
  async componentDidMount(){
    this.setInfoSlaughterhousePacking(this.props.getIdPacking)

  }
    setInfoSlaughterhousePacking= async (id_set,verify) =>{ 
              var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = this.props.setParamsUrl
              var dataTransExportLogisc = await data_logist_slaught_packing(this.props.dataPackingExtend,id_set)
            this.setDataCamposPacking(dataTransExportLogisc)
  }
  setDataCamposPacking = (data)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];

      if (data!=null) {
        this.setState({
                packing_id:(data.id=="null")?(null):(data.id),
                lote_numero_packing:(data.lote_numero=="null")?(null):(data.lote_numero),
                fecha_packing:(data.fecha=="null")?(completeDate):(data.fecha),
                producto:(data.producto=="null")?(null):(data.producto),
                piezas_caja:(data.piezas_caja=="null")?(null):(data.piezas_caja),
                cantidad_cajas:(data.cantidad_cajas=="null")?(null):(data.cantidad_cajas),
                informacion_adicional_packing:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
                file_document_packing:(data.file_document=="null")?(null):(data.file_document),
        })
      }else{
            this.setState({
                packing_id:'noind',
                lote_numero_packing:'',
                fecha_packing:completeDate,
                producto:'',
                piezas_caja:'',
                cantidad_cajas:'',
                informacion_adicional_packing:'',
                file_document_packing:undefined,
            })
      }
    }
  render(){
    return(
<Row>
            <Col md={12}>
              <h3 className="mb-2">Empaque {this.props.getIndex+1}</h3>
            </Col>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label>
                    <br /><span>
                      {this.state.lote_numero_packing}
                    </span>
                  </FormGroup>
                </Col>

                
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha
                  </label>
                  <br /><span>
                    {(this.state.fecha_packing=="undefined")?(null):(this.state.fecha_packing)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Producto
                  </label>
                  <br /><span>
                    {(this.state.producto=="undefined")?(null):(this.state.producto)}
                </span>
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Piezas por caja

                    </label>
                    <br /><span>
                    {this.state.piezas_caja}
                      </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de cajas
                    </label>
                    <br />
                    <span>
                      {this.state.cantidad_cajas}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <span>
                      {this.state.informacion_adicional_packing}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Subir Documento
                  </label>
                  <br />
                  {(this.state.file_document_packing==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_packing}>{String(this.state.file_document_packing).split('/').pop()}</a>)}
                </Col>
              </Row>
      )
  }
}

export class SectionFreezing extends React.Component {
  state={
                freezing_id:'noind',
                lote_numero_freezing:'',
                cantidad_producto:'',
                fecha_ingreso:'',
                hora_ingreso:'',
                aplico_preenfriamiento:'',
                temperatura_inicial:'',
                temperatura_final:'',
                informacion_adicional_freezing:'',
                file_document_freezing:undefined,
            }
  async componentDidMount(){
    this.setInfoSlaughterhouseFreezing(this.props.getIdFreezing)
  }
  setDataCamposFreezing = (data)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];

            if (data!=undefined) {
        this.setState({
                freezing_id:(data.id=="null")?(null):(data.id),
                lote_numero_freezing:(data.lote_numero=="null")?(null):(data.lote_numero),
                cantidad_producto:(data.cantidad_producto=="null")?(null):(data.cantidad_producto),
                producto_freezing:(data.producto=="null")?(null):(data.producto),
                fecha_ingreso:(data.fecha_ingreso=="null")?(completeDate):(data.fecha_ingreso),
                hora_ingreso:(data.hora_ingreso=="null")?(null):(data.hora_ingreso),
                aplico_preenfriamiento:(data.aplico_preenfriamiento=="null")?(null):(data.aplico_preenfriamiento),
                temperatura_inicial:(data.temperatura_inicial=="null")?(null):(data.temperatura_inicial),
                temperatura_final:(data.temperatura_final=="null")?(null):(data.temperatura_final),
                informacion_adicional_freezing:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
                file_document_freezing:(data.file_document=="null")?(null):(data.file_document),
        })
      }else{
            this.setState({
                freezing_id:'noind',
                lote_numero_freezing:'',
                cantidad_producto:'',
                producto_freezing:'',
                fecha_ingreso:completeDate,
                hora_ingreso:timeString,
                aplico_preenfriamiento:'',
                temperatura_inicial:'',
                temperatura_final:'',
                informacion_adicional_freezing:'',
                file_document_freezing:undefined,
            })

      }


    }
   setInfoSlaughterhouseFreezing = async (id_set) =>{
            var userdata = localStorage.getItem('user');
            var jsondata = JSON.parse(userdata);
            var gerParam = this.props.setParamsUrl
            var dataTransExportLogisc = await data_logist_slaught_freezing(this.props.dataFreezingExtend,id_set)
            this.setDataCamposFreezing(dataTransExportLogisc)
  }
  render(){
    return(
            <Row>
            <Col md={12}>
              <h3 className="mb-2">Camara de Frio {this.props.getIndex+1}</h3>
            </Col>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label><br />
                    <span>
                      {this.state.lote_numero_freezing} id="exampleFormControlSelect1" type="select">
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Producto
                    </label>
                    <span><br />
                    {this.state.producto_freezing}
                    </span>
                  </FormGroup>
                </Col>  
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Producto
                    </label>
                    <span><br />
                    {this.state.cantidad_producto}
                    </span>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Ingreso
                  </label>
                    <br />
                  <span>
                    {(this.state.fecha_ingreso=="undefined")?(null):(this.state.fecha_ingreso)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora
                  </label>
                  <br />
                  <span>
                    {(this.state.hora_ingreso=="undefined")?(null):(this.state.hora_ingreso)}
                  </span>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                 Se Aplico Preenfriamiento
                  </label>
                  <br /><span>
                    {(this.state.aplico_preenfriamiento=="undefined")?(null):(this.state.aplico_preenfriamiento)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Temperatura Inicial
                  </label>
                  <br /><span>
                    {(this.state.temperatura_inicial=="undefined")?(null):(this.state.temperatura_inicial)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Temperatura Final
                  </label>
                  <br /><span>
                    {(this.state.temperatura_final=="undefined")?(null):(this.state.temperatura_final)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <br />
                  <span>
                    {(this.state.informacion_adicional_freezing=="undefined")?(null):(this.state.informacion_adicional_freezing)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                  <br />
                {(this.state.file_document_freezing==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_freezing}>{String(this.state.file_document_freezing).split('/').pop()}</a>)}
              </Col>
            </Row>
      )
  }
}