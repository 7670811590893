import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import ModalNFinal from "./ModalExtend/ModalNFinalView";

import { row_fertilizer_production, get_permission } from "network/ApiAxios";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export default class CosechaTransporteCampoView extends React.Component {
    state={
    dataFertilizer:[],
  }
async componentDidMount(){
        this.initData()
}
initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataFertilizer = this.props.putDataAll
        //await row_fertilizer_production(jsondata._id,gerParam,null,this.props.table_ex)
        if (dataFertilizer.length!=0) {
          if (this.props.table_ex=='info_producer_g') {
            this.props.getData(dataFertilizer[0],this.props.table_ex)
          }
        }
        this.setState({dataFertilizer:dataFertilizer})
}
  render(){

  return (
    <>
        <ModalNFinal {...this.props} sendInfoProps={this.state.dataFertilizer} refreshData={this.initData} setParamsUrl={window.location.search} tableCall={this.props.table_ex} titleCall={this.props.title} />
    </>
  );
 }
}