import React, {Component} from "react";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeaderGrain from "components/Headers/SimpleHeaderGrain.js";
import GrainTrazaNavBar from "views/pages/components/GrainTrazaNavBar.js";
import { row_documents_list_grains, printPdf, get_permission } from "network/ApiAxios";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
//import print from 'print-js'
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import PropTypes from 'prop-types';
class Documents extends Component {
state={
  data:[],
  create_plant_product:[],
  create_plant_sample:[],
  create_plant_treatment:[],
  create_plant_weight_product:[],
  production_fertilizer:[],
  transport_cosecha:[],
  transport_vehicule_cosecha:[],
  set_content:[]
}
async componentDidMount(){
 var dataArr = 
[  {'Información General':{'grain_info_crops_t':'Info del Cultivo'}},
   {'Producción': {'grain_seed_t':'Semilla','grain_fertilizer_t':'Fertilización','grain_fert_agro_t':'Agroquimico'}},
   {'Cosecha': {'grain_harvest_info_transport_t':'Información de transporte','grain_harvest_info_pos_harvest_t':'Información de Pos-Cosecha'}},
];
this.setState({set_content:dataArr})
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.location.search).get("code");
  var data = await row_documents_list_grains(jsondata._id,gerParam,'.grain_info_crops_t')
   this.setState({data_doc:data})

}
getContentInfo = (table_name,data,tabled) =>{
  var content=[]
  data.map((a,b)=>{
  if (a!='' && a.indexOf('||')==-1) {
    if (a.indexOf(tabled)!=-1) {  
    content[b] = <tr>
                <td>
                  <span className="text-muted">
                    Create
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {table_name}
                  </span>
                </td>                
                <td>
                  <span className="text-muted">
                  {(a=='')?('Sin archivo'):(a)}
                  </span>
                </td>
               

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+a}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{this.download_file("/storage/file_documents/"+a,String(a).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{this.printer_pdf("/storage/file_documents/"+a,String(a).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
              </tr>
    }
  }
  })
  return content
}

getInfo = (info_content) =>{
  if (this.state.data_doc!=undefined) {
    return Object.keys(this.state.data_doc).map((a,b)=>{

      if (info_content[0][a]!=undefined) {
         var data_logi=[]
         if (typeof this.state.data_doc[a] == 'string') {
          // if (typeof this.state.data_doc[a] == 'string') {
          // }
           data_logi=this.state.data_doc[a].split('|')
         }else{
           data_logi = Object.values(this.state.data_doc[a]).join("|").split("|").filter((a,b)=>a!='|')
           //data_logi = this.state.data_doc[a].filter((a,b)=>a!='|')
         }
         return this.getContentInfo(info_content[0][a],data_logi,a)
      }
    })
  }
}
setContent = (content) => {
  var put_content = []
  for (var i = 0; i < content.length; i++) {
        put_content[i] = <Card key={i}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
              
                <h3 className="mb-0">{Object.keys(content[i])}</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Formulario</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            {this.getInfo(Object.values(content[i]))}
<tbody>
            </tbody>
          </Table>
        </Card>
  }
  return put_content
}

 printFile = (urlFile) => {
       //var Pagelink = urlFile;
        var Pagelink = "about:blank";
        var pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(this.ImagetoPrint(urlFile));
        pwa.document.close();
  };
   ImagetoPrint = (source)=>{
           return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<img src='" + source + "' /></body></html>";
  }
   convertBase64ToFile = (base64String, fileName) => {
     let arr = base64String.split(',');
     let mime = arr[0].match(/:(.*?);/)[1];
     let bstr = atob(arr[1]);
     let n = bstr.length;
     let uint8Array = new Uint8Array(n);
     while (n--) {
        uint8Array[n] = bstr.charCodeAt(n);
     }
     let file = new File([uint8Array], fileName, { type: mime });
     return file;
}
 printer_pdf = async (url,fileExtend) => {
   var printer = await printPdf(url)
  if (printer) {
    if (url.indexOf(".pdf") == -1) {
      this.printFile('data:image/'+fileExtend+';base64,'+printer)
    }else{

        let byteCharacters = atob(printer);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], {type: 'application/pdf;base64'});
    let fileURL = URL.createObjectURL(file);
    const newWindow = window.open(fileURL);
    newWindow.print();
    //newWindow.close();
     }
  }
}

   download_file = async (url,fileName)=>{
   var printer = await printPdf(url)
   if (printer) {
    if (fileName.indexOf(".pdf") == -1) {
      var baseUrl = 'data:image/'+fileName+';base64,'+printer;
    }else{
      var baseUrl = 'data:application/'+fileName+';base64,'+printer;
  }

   var file = this.convertBase64ToFile(baseUrl, fileName);
   saveAs(file, fileName);
   
   }

  }
  render(){
  return (
    <>
      <GrainTrazaNavBar getParams={this.props.location.search}/>  
      <SimpleHeaderGrain name="Documentos" parentName="Documentos" section="rice" section_two="Documentos" section_table_form="null" />
      <div className="mt-1 container-fluid">
      {this.setContent(this.state.set_content)}
      </div>
    </>
  );
 }
}
Documents.propTypes = {
  name: PropTypes.string
};
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
      this.context.getAffiliates()
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('G')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.document_grain_t==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }

render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Documents {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

//export default Documents;
