
import React, { useContext } from 'react';
//import { LayoutContext } from './context/layoutcontext';
import {UserContext} from "layouts/store";
const AppFooter = () => {
    const { layoutConfig } = useContext(UserContext);

    const yearTemplate = () => {
        const d = new Date();
        let year = d.getFullYear();
    
        return year;
    }

    return (
        <div className="layout-footer">
            <img src={`/layout/images/logo.png`} alt="Logo" height="20" className="mr-2" />
            <span className="font-medium ml-2" style={{ color: '#002a5c' }}>FoodChain Trace © Todos los derechos reservados - { yearTemplate() }</span>
        </div>
    );
};

export default AppFooter;
