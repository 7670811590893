import { stateData } from "network/DataState";

export const setFieldValueDuplicateHook = (position, value, field, isrequired, thisState) =>{
  var getDataOne = thisState.state.field_params_duplicate
  var getDataTwo = getDataOne[position]
  getDataTwo={...getDataTwo,[field]:value}
  getDataOne[position]=getDataTwo
  thisState.setState({field_params_duplicate: getDataOne })
  thisState.setState({field_params: {...thisState.state.field_params, ['field_duplicate']:JSON.stringify(getDataOne) }})
  if (isrequired.getAttribute('data_filter')!=null) {
    isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
      if (a!='upf') {
        Object.entries(thisState.state.selectForFilter).map((val,indx)=>{
          if (val[0]==a) {
            var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
            thisState.setState({optionsSelect: {...thisState.state.optionsSelect, [val[0]]:get_results }})
          }
        })
      }
    })
  }
}
export const setFieldValueSelectDuplicateHook = (position, value, field, isrequired,code_country,list_default,type_cn,if_states,thisState) => {

      thisState.setState({dataStatesS:0})
      selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states,thisState)
          
          if (if_states==undefined) {
		      var getDataOne = thisState.state.field_params_duplicate
		      var getDataTwo = getDataOne[position]
		      getDataTwo={...getDataTwo,[field]:value}
		      getDataOne[position]=getDataTwo
		      thisState.setState({field_params_duplicate: getDataOne })
		      thisState.setState({field_params: {...thisState.state.field_params, ['field_duplicate']:JSON.stringify(getDataOne) }})
            //this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = thisState.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
	      	
	      	  var getDataOneLDE = thisState.state.field_params_duplicate
	      	  var getDataTwo = getDataOneLDE[position]
		      getDataTwo={...getDataTwo,[field]:value_for_states[0].value}
		      getDataOneLDE[position]=getDataTwo
		      thisState.setState({field_params_duplicate: getDataOneLDE })
		      thisState.setState({field_params: {...thisState.state.field_params, ['field_duplicate']:JSON.stringify(getDataOneLDE) }})
            //this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
    }
     const selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states,thisState) =>{
          var dataState=[]
          var dataSelect = {}
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (thisState.state.field_content.length>0) {
                 for (var i = 0; i < thisState.state.field_content.length; i++) {
                   if (thisState.state.field_content[i].list_default!=null) {
                     if (thisState.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        dataSelect = contentForSelect(
                        										dataState[0].states,
																thisState.state.field_content[i].name_field,
																thisState.state.field_content[i].req_field,
																thisState.state.field_content[i].list_default
																)
                        thisState.setState({optionsSelect: {
                        	...thisState.state.optionsSelect,
                         	[thisState.state.field_content[i].name_field]:contentForSelect(dataState[0].states,
                         	thisState.state.field_content[i].name_field,thisState.state.field_content[i].req_field,
                         	thisState.state.field_content[i].list_default) }
                         })
                       }
                     }
                   }
                 }
               }
            }
          }
          return dataSelect
    }
     const contentForSelect = (data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }

 export const geolocacionDuplicateHook = (position, options, indx_position, thisState) => {
      thisState.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{VerPosicion(e,options,indx_position,position,thisState)}, ()=>{thisState.setState({loadlocali:null})});
       }else{
         thisState.setState({loadlocali:null})
       }
    };
    const VerPosicion = (posicion,optcon,indx_position,position,thisState)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    setFieldValueDuplicateHook(position,lat+','+lon+'-(Información restante)',optcon.id,optcon,thisState)
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              setinfolocation(position,lat,lon,data,optcon,thisState)
            }

        }).catch(err => console.log(err.message)));
    }
         const setinfolocation = (position,lat,lon,data,optcon,thisState) => {
              thisState.setState({loadlocali:null})
              thisState.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
			      	  var getDataOneVGP = thisState.state.field_params_duplicate
			      	  var getDataTwoVGP = getDataOneVGP[position]
				      getDataTwoVGP={...getDataTwoVGP,[val.name_field]: String(data.results[0].components.country) }
				      getDataOneVGP[position]=getDataTwoVGP
				      thisState.setState({field_params_duplicate: getDataOneVGP })
				      thisState.setState({field_params: {...thisState.state.field_params, ['field_duplicate']:JSON.stringify(getDataOneVGP) }})
                    //this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
			      	  var getDataOneVGE = thisState.state.field_params_duplicate
			      	  var getDataTwoVGE = getDataOneVGE[position]
				      getDataTwoVGE={...getDataTwoVGE,[val.name_field]: String(data.results[0].components.state) }
				      getDataOneVGE[position]=getDataTwoVGE
				      thisState.setState({field_params_duplicate: getDataOneVGE })
				      thisState.setState({field_params: {...thisState.state.field_params, ['field_duplicate']:JSON.stringify(getDataOneVGE) }})
                    //this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
			      	  var getDataOneVGC = thisState.state.field_params_duplicate
			      	  var getDataTwoVGC = getDataOneVGC[position]
				      getDataTwoVGC={...getDataTwoVGC,[val.name_field]: String(data.results[0].components.city) }
				      getDataOneVGC[position]=getDataTwoVGC
				      thisState.setState({field_params_duplicate: getDataOneVGC })
				      thisState.setState({field_params: {...thisState.state.field_params, ['field_duplicate']:JSON.stringify(getDataOneVGC) }})
                    //this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    setFieldValueSelectDuplicateHook(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    setFieldValueSelectDuplicateHook(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              setFieldValueDuplicateHook(position,lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon,thisState)
    }

    const noPermitir = (argument) => {
      
    }