export const put_arr_data_kdes = [
      {
        table_receive:{table:'create_establishment_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_establishment_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        prefix:'detailsReceiving-',
        name_label:'Detallista-Tienda',
        data_f_config_vt:{title:'title_establish',view:'view_table_establish'},
        data_details_select:[
                              {label:'Detallista Datos Recibido',value:'create_establishment_receiving_kdes'}
                             ],
		icon:'https://cdn-icons-png.flaticon.com/512/862/862893.png'
        //icon:'fas fa-store-alt'
      },
      {
        table_receive:{table:'create_distributor_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_distributor_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'distributorShipp-',
        name_label:'Distributor',
        data_f_config_vt:{title:'title_distributor',view:'view_table_distributor'},
        data_details_select:[
                              {value:'create_distributor_receiving_kdes',label:'Distributor Datos Recibido'},
                              {value:'create_distributor_shipping_kdes',label:'Distributor Datos Enviado'}
                             ],
		icon:'https://cdn-icons-png.flaticon.com/512/4363/4363531.png'
        //icon:'fas fa-shipping-fast'
      },
      {
        table_receive:{table:'create_distributor_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_distributor_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'distributorReceiv-',
        name_label:'Distributor',
        data_f_config_vt:{title:'title_distributor',view:'view_table_distributor'},
        data_details_select:[
                              {value:'create_distributor_receiving_kdes',label:'Distributor Datos Recibido'},
                              {value:'create_distributor_shipping_kdes',label:'Distributor Datos Enviado'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/1524/1524714.png'
        //icon:'fab fa-get-pocket'
      },

      {
        table_receive:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importShipp-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Importador Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Importador Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Importador Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Importador Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
		icon:'https://cdn-icons-png.flaticon.com/512/10485/10485926.png'
        //icon:'fas fa-shipping-fast'
      },

      {
        table_receive:{table:'create_producer_food_kdes',change:'change_nuevo_codigo_de_lote_del_alimento',codigo:'nuevo_codigo_de_lote_del_alimento'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importFood-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Importador Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Importador Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Importador Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Importador Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
        icon:'https://cdn-icons-png.flaticon.com/512/562/562678.png'
        //icon:'fas fa-fish'
      },

      {
        table_receive:{table:'create_producer_transformation_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importTrans-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Importador Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Importador Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Importador Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Importador Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
        icon:'https://cdn-icons-png.flaticon.com/512/2211/2211642.png'
        //icon:'fas fa-cogs'
      },

      {
        table_receive:{table:'create_producer_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importReceiv-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Importador Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Importador Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Importador Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Importador Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
		icon:'https://cdn-icons-png.flaticon.com/512/7046/7046452.png'
        //icon:'fab fa-get-pocket'
      },

      {
        table_receive:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantShipp-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Empaque Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Empaque Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Empaque Inicial',value:'create_plant_product_kdes'},
                              {label:'Empaque Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/1585/1585176.png'
        //icon:'fas fa-shipping-fast'
      },

      {
        table_receive:{table:'create_plant_product_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantProduct-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Empaque Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Empaque Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Empaque Inicial',value:'create_plant_product_kdes'},
                              {label:'Empaque Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/15453/15453574.png'
        //icon:'fas fa-cog'
      },

      {
        table_receive:{table:'create_plant_cooling_kdes',change:'change_codigo_de_lote_que_asignado',codigo:'codigo_de_lote_que_asignado'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantCooling-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Empaque Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Empaque Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Empaque Inicial',value:'create_plant_product_kdes'},
                              {label:'Empaque Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/9644/9644745.png'
        //icon:'fas fa-temperature-high'
      },

      {
        table_receive:{table:'create_plant_receiver_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantReceiver-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Empaque Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Empaque Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Empaque Inicial',value:'create_plant_product_kdes'},
                              {label:'Empaque Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/6384/6384822.png'
      },

      {
        table_receive:{table:'fruit_vegetable_user'},
        prefix:'producer-',
        name_label:'Productor',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
          {value:'fruit_vegetable_user',label:'Datos Productor'},
          {value:'create_harvest_kdes',label:'Datos Cosecha'}
        ],
        icon:'https://cdn-icons-png.flaticon.com/512/6384/6384822.png'
      },

      {
        table_receive:{table:'create_harvest_kdes'},
        prefix:'producer-',
        name_label:'',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
          {value:'fruit_vegetable_user',label:'Datos Productor'},
          {value:'create_harvest_kdes',label:'Datos Cosecha'}
        ],
        icon:'https://cdn-icons-png.flaticon.com/512/6384/6384822.png'
      }

    ]