import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { get_data_transport_trace, get_permission } from "network/ApiAxios";
import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentSubPlant, PutContentSubTablaSatelite} from 'views/pages/examples/LogisticsForVistaTrace/InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogisticMRV'
import ModalObservation from 'views/pages/examples/LogisticsForVistaTrace/ModalObservationMRV'
import DetailsObservationsRev from "views/pages/examples/Production/DetailProductionTime/DetailsObservationsRev";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery'
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default class SectionNamaG extends React.Component {
  state = {
    cross_transport:['cargando'],
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false],
    notificationAlertRef: React.createRef(),
    set_type_content:{},
    set_type_content_onre:null,
    data_user:null,
    db_prod:new URLSearchParams(window.location.search).get("db"),
    code_prod:new URLSearchParams(window.location.search).get("code"),
    form_prod:null,
    put_table:null,
    type_user_asigned:null
  }
  async componentDidMount(){
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var get_data_user = await get_permission(jsondata._id)
    this.setState({data_user:get_data_user,type_user_asigned:jsondata.type_traza})
  }
     opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
     openedChild = (isChange,ident,valuePut,id_register) =>{
  var put_title_activity
    if (this.state.type_user_asigned=='ON') {
      put_title_activity = this.state.put_title_g+' - '+valuePut+' * '+id_register
    }
    if (this.state.type_user_asigned=='RE') {
      put_title_activity = this.state.put_title_g+' - '+valuePut+' * '+id_register
    }
    if (this.state.type_user_asigned=='VA') {
      put_title_activity = this.state.put_title_g+' - '+valuePut+' * '+id_register
    }
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
        this.setState({put_title:put_title_activity})
      }
 funcOpenModal = (chan,form,put_table,put_title) =>{
  var id_register = jQuery('.get_info_register_'+put_table).attr('id-register')
  var put_id_register = ''
  if (id_register!=undefined) {
    put_id_register = ' * '+id_register
  }
  var put_title_activity
    if (this.state.type_user_asigned=='ON') {
      put_title_activity = "Monitoreo de "+put_title+put_id_register
    }
    if (this.state.type_user_asigned=='RE') {
      put_title_activity = "Revisión de "+put_title+put_id_register
    }
    if (this.state.type_user_asigned=='VA') {
      put_title_activity = "Validación de "+put_title+put_id_register
    }
    this.setState({
      openModal:chan,
      // db_prod:this.state.routegetThree,
      // code_prod:this.state.routeget,
      form_prod:form,
      put_table:put_table,
      put_title:put_title_activity,
      put_title_g:put_title_activity
    })
    if (this.state.set_type_content[put_table]==undefined) {
      this.setState({set_type_content: {[put_table]:2} })
    }else{    
      if (this.state.set_type_content[put_table]==1) {
        this.setState({set_type_content: {[put_table]:2} })
      }else{
        this.setState({set_type_content:{[put_table]:1}})
      }
    }
  }
funcObs = async (chan,type_user_role) =>{
    var type_user_p=null
    if (this.state.data_user!=null) {
     type_user_p = this.state.type_user_asigned
    }
    if (this.state.set_type_content_onre==type_user_role) {
      this.setState({set_type_content_onre:null})  
    }else{
      this.setState({set_type_content_onre:type_user_role})      
    }
}
  render(){
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  const insertDataObservation = async () =>{
       notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
  }
  return (
    <>
        <SimpleHeader name="Observaciones Realizadas" parentName="Observaciones Realizadas" section="mrv" section_two="Tabla" section_table_form="table_traza_proccess" />
        <div className="rna-wrapper">
          <NotificationAlert ref={this.state.notificationAlertRef} />
        </div>
        <Container className="mt--6" fluid>
              <div className="card">
                <Row className="mt-2">
                          {(this.state.data_user!=null)?(
                            (this.state.type_user_asigned=='RE' || this.state.type_user_asigned=='VA')?(
                                                        <Col md={6}>
                                                          <button className="btn" onClick={()=>{this.funcObs(true,'ON')}}>{(this.state.set_type_content_onre!="ON")?('Ver Observaciones de Monitoreo'):('Cerrar')}</button>
                                                        </Col>
                                                    ):(null)
                            ):(null)}
                          {(this.state.data_user!=null)?((this.state.type_user_asigned=='VA')?(
                            <Col md={6}>
                              <button className="btn" onClick={()=>{this.funcObs(true,'RE')}}>{(this.state.set_type_content_onre!="RE")?('Ver Observaciones de Revisión'):('Cerrar')}</button>
                            </Col>
                          ):(null)):(null)}
                  <Col md={12} className="pt-2">
                            {(this.state.set_type_content_onre==null)?(null):(
                                  (this.state.set_type_content_onre=='ON' || this.state.set_type_content_onre=='RE')?(
                                    <DetailsObservationsRev thisStates={this.state} />
                                  ):(null)
                                )}
                  </Col>
                </Row>
          </div>
        </Container>
    </>
  );
}
}

