import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse
} from "reactstrap";
import SimpleHeaderProd from "components/Headers/SimpleHeaderProd.js";
import GrainNamaNavBar from "views/pages/components/GrainNamaNavBar.js";
import ModalPLSeedForm from "./Modal/ModalPLSeedForm";
//import ModalMWeed from "./Modal/ModalMWeedForm";
import axios from 'axios';
import GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob } from 'geotiff';
import * as plotty from 'plotty';
import { get_data_satelite, get_data_satelital,get_tiff, row_fertilizer_production, get_permission, save_data_satelite,img_convert_satelite } from "network/ApiAxios";
import jQuery from 'jquery';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import jsPDF from 'jspdf';

// import Tiff from 'tiff.js';
// import GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob } from 'geotiff';

export class GNamaPLSatelite extends React.Component {
    state={
    plotViewImg:{},
    code:null,
    dataFertilizerOne:[],
    dataFertilizerTwo:[],
    dataFertilizerTree:[],
    dataFertilizerFor:[],
    mess:[],
    data_satelital:null,
    btnd:false,
    openColapse:[],
    setValueData:[],
    colorPallet:'mycolorspectral',
    dateSatelite:'Fecha',
    loadlocali:null,
  }
async componentDidMount(){
        this.initData()
  jQuery(".documentFormAll").remove()
}

initData = async () =>{
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(window.location.search).get("code");
  this.setState({data_satelital:'cargando'})
  var get_info = await get_data_satelite(gerParam)
  this.setState({code:get_info.code_satelital, data_satelital:get_info.data_satelital})
        // var dataFertilizerOne = await row_fertilizer_production(jsondata._id,gerParam,null,'production_info_satelite')
        // var dataFertilizerTwo = await row_fertilizer_production(jsondata._id,gerParam,null,'production_info_satelite_ndre')
        // var dataFertilizerTree = await row_fertilizer_production(jsondata._id,gerParam,null,'production_info_satelite_ndmi')
        // var dataFertilizerFor = await row_fertilizer_production(jsondata._id,gerParam,null,'production_info_satelite_ndhs')
        // this.setState({
        //   dataFertilizerOne:dataFertilizerOne,
        //   dataFertilizerTwo:dataFertilizerTwo,
        //   dataFertilizerTree:dataFertilizerTree,
        //   dataFertilizerFor:dataFertilizerFor,
        // })
}

  generateInfo=async(code)=>{
    if (code=='' || code==null) {
      alert('Se necesita un código')
      return
    }
    this.setState({btnd:true})
    var gerParam = new URLSearchParams(window.location.search).get("code");
    var get_info = await save_data_satelite(gerParam,code)
    if (get_info) {
      this.initData()
      this.setState({btnd:false})
    }
  }
  getCode = (dni)=>{
    var codeDni=""
    if (dni!='' || dni!=null) {
      codeDni = '04-A-1-'+dni.replace(/(\d{1,2})(\d{3})(\d{4})/g,"$1-$2-$3")
    }
    return codeDni
  }
  geneCode=()=>{
    //console.log(this.props.data_user)
    if (this.props.data_user.code_satelital!=null || this.props.data_user.code_satelital!='') {
      this.setState({code: this.props.data_user.code_satelital })
    }else{
      alert('Usted no posee ningun código.')
    }
    // if (this.props.data_user.pais=='Panama') {
    //   this.setState({code:this.getCode(this.props.data_user.dni)})
    // }else{
    //   this.setState({code:this.getCode(this.props.data_user.dni)})
    // }
  }
     opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
loadFileTiff=async(indentifier,urlTiff,dataView)=>{
  const response = await get_tiff(urlTiff)
  const arrayBufferRes = await response.arrayBuffer();
  const tiff = await fromArrayBuffer(arrayBufferRes);
  const image = await tiff.getImage();
  const data = await image.readRasters();

  const array1 = data[0];
   var maxBand = parseFloat(dataView[4])
   var minBand = parseFloat(dataView[3])

    try{
      var canvas = document.querySelector(".plotView"+indentifier);
      //plotty.addColorScale("mycolorspectral", ["#2b83ba", "#fdae61", "#ffffbf", "#abdda4", "#d7191c"], [1, 1, 0.4, 0.8, 0]);
      plotty.addColorScale("mycolorspectral", ["#1a9641", "#fdae61", "#ffffc0", "#a6d96a", "#d7191c"], [1, 1, 0.4, 0.8, 0]);
        var plots = new plotty.plot({
          canvas,
          data: data[0],
          width: image.getWidth(),
          height: image.getHeight(),
          domain: [minBand, maxBand],
          colorScale: "mycolorspectral"
        });
        plots.render();
              var canvasUrl = document.querySelector(".plotView"+indentifier);
              var getWidthVar = canvasUrl.getAttribute('width')
              var getHeightVar = canvasUrl.getAttribute('height')
              const dataURL = canvasUrl.toDataURL();
              var img = document.querySelector(".ploteViewImg"+indentifier).src=dataURL;
              var getImgStyle = document.querySelector(".ploteViewImg"+indentifier)
    var getWidth =parseInt(getWidthVar)
    var getHeight =parseInt(getHeightVar)
    // var min=Math.min(getWidth,getHeight);
    // var result=Math.max(min*(100/100),(getHeight/getWidth)*100);
    // var newHeight=(result/min) * getHeight;
    // var newWidth=(result/min) * getWidth;
              if (getWidth<100 || getWidth>100) {
                canvasUrl.style.width=String(100)+"px"
                getImgStyle.style.width=String(100)+"px"
              }
              if (getHeight<100 || getHeight>100) {
                var calculeHeith = (getHeight/getWidth)*100
                canvasUrl.style.height=String(calculeHeith)+"px"
                getImgStyle.style.height=String(calculeHeith)+"px"
              }

        //var img = document.querySelector("."+jQuery(b).attr('class').replace('plotView','ploteViewImg')).src=dataURL;

        // if (this.state.plotViewImg==undefined) {
        //   this.setState({plotViewImg:{[indentifier]:dataURL}})
        // }else{
        //   this.setState({plotViewImg:{...this.state.plotViewImg,[indentifier]:dataURL}})
        // }

    }catch(error){
      console.log(error)
    }

        //plots.setColorScale(this.state.colorPallet)

}
     openModalCanvas = (target,indentifier,indentifierSpan,typeClk)=>{
      if (typeClk=='span') {
        var targetCanvas = jQuery('.'+indentifier)[0]
        var getWidthVar = targetCanvas.getAttribute('width')
        var getHeightVar = targetCanvas.getAttribute('height')
        var getWidth =parseInt(getWidthVar)
        var getHeight =parseInt(getHeightVar)

        jQuery(target).removeAttr('style')
        jQuery(targetCanvas).removeAttr('style')
        jQuery('.closeIconTime'+indentifier.replace('plotView','')).hide()

        target.style.color= "black";
        target.style.fontSize= "13px";

        // targetCanvas.style.width= "100px";
        // targetCanvas.style.height= "100px";
        if (getWidth<10 || getWidth>10) {
          targetCanvas.style.width=String(100)+"px"
          var calculeHeith = (getHeight/getWidth)*100
          targetCanvas.style.height=String(calculeHeith)+"px"
        }
        targetCanvas.style.backgroundColor="white"
        targetCanvas.style.position='absolute'
        targetCanvas.style.top='0px'
        targetCanvas.style.left='0px'
        targetCanvas.style.right='0px'
        targetCanvas.style.margin='auto'
      }
      if (typeClk=='canvas') {
        jQuery('.closeIconTime'+indentifier.replace('plotView','')).show()
        // target.style.width='420px'
        // target.style.height='400px'
        var getWidthVar = target.getAttribute('width')
        var getHeightVar = target.getAttribute('height')
        var getWidth =parseInt(getWidthVar)
        var getHeight =parseInt(getHeightVar)

          target.style.width=String(400)+"px"
          var calculeHeith = (getHeight/getWidth)*400
          target.style.height=String(calculeHeith)+"px"

        target.style.position='fixed'
        target.style.top='0px'
        target.style.bottom='0px'
        target.style.left='0px'
        target.style.right='0px'
        target.style.margin='auto'
        target.style.borderRadius='10px'
        target.style.zIndex= "1060";
        var targetSpan = jQuery('.'+indentifierSpan)[0]
        targetSpan.style.color= "black";
        targetSpan.style.fontSize= "13px";
        targetSpan.style.width= "100%";
        targetSpan.style.height= "100%";
        targetSpan.style.position= "fixed";
        targetSpan.style.background= "#ffffffc4";
        targetSpan.style.inset= "0px";
        targetSpan.style.margin= "auto";
        targetSpan.style.zIndex= "1055";
      }
    }
    viewImgTiff=(dataUrl,indentifier,dataView)=>{
      // img_convert_satelite(dataUrl).then((data,indx)=>{
      // })
      var contents = []
//      var get_url_img = dataUrl.split('getfile=')
      if (dataUrl!=null || dataUrl!='') {
      var getReplace = dataUrl.replace('http://app.yttrium.farm:9000','https://app.itrio.ai')
        contents[0]= <>
{/*        <a data-url={getReplace} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} href={getReplace}>Descargar este archivo</a>
        <br/>*/}
            <img className={"ploteViewImg"+indentifier} style={{width:'100px',height:'100px',position: 'absolute',top: '0px',left: '0px',right: '0px',margin: 'auto'}} />
        {(this.state.btnd)?(null):(

            <canvas
                onClick={(e)=>{
                  this.openModalCanvas(e.target,"plotView"+indentifier,"spanPlotView"+indentifier,'canvas')
                }}
             onLoad={this.loadFileTiff(indentifier,getReplace,dataView)} className={"plotView"+indentifier} style={{position: 'absolute',top: '0px',left: '0px',right: '0px',margin: 'auto',backgroundColor: 'white'}}></canvas>
          )}
        </>
      }
      return contents
    }
 printer_pdf = async () => {
              //var canvasUrl = document.querySelector(".plotView"+indentifier);
              // const dataURL = canvasUrl.toDataURL();
              // var img = document.querySelector(".plotViewImg"+indentifier).src=dataURL;
              // var data_change = {}
              // jQuery('[class*="plotView"]').map((a,b)=>{
              //   const dataURL = b.toDataURL();
              //   //data_change[] = dataURL
              //   var img = document.querySelector("."+jQuery(b).attr('class').replace('plotView','ploteViewImg')).src=dataURL;

              // })
              var thisState = this
              await this.setState({btnd:true})
//              this.setState({plotViewImg:data_change})
             var component = document.querySelector(".contentForPDF").innerHTML
             var divContents = component

            var doc = new jsPDF({
              orientation: 'landscape' ,
              unit: 'px',
              format: 'A3',
            });
            doc.setFont('Inter-Regular', 'normal');
            doc.html(divContents, {
              async callback(doc) {
                var get_blob = await doc.output('bloburl')
                window.open(get_blob,'_blank')
                await thisState.setState({btnd:false})
                jQuery('.clk_'+thisState.state.dateSatelite).click()
              },
                x: 0,
                y: 0,
                width: 900,
                windowWidth: 1100
            });
}
getDataSatelital =async(codeVal,dateVal)=>{
  var getData =await get_data_satelital(codeVal,dateVal)
  return getData
}
viewWeather=async(fill)=>{
  if (fill==1) {
    this.geolocacion()
  }
}
 geolocacion = () => {
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerClima(e)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
  searchLocationString=async(lat,lon)=>{
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              console.log(data)
              return {code:data.results[0].components.country_code,city:data.results[0].components.city}
            }

        }).catch(err => console.log(err.message)));
  }
  VerClima = async (posicion)=>{
       var lat = posicion.coords.latitude;
       var lon = posicion.coords.longitude;
       console.log(await this.searchLocationString(lat,lon))
      var apiWea = '3c22d7f09642785e6fdb27aaad5eb1b9'
      //var getUrl = 'https://api.openweathermap.org/data/2.5/weather?q=Ciudad Bolivar,VE&units=metric&mode=html&lang=es&appid='+apiWea
      var getUrl = 'https://api.openweathermap.org/data/2.5/weather?lat='+lat+'&lon='+lon+'&units=metric&mode=html&lang=es&appid='+apiWea
      var getData = await fetch(getUrl)    
      .then(response => response.text()
        .then(data => {
          jQuery('#putContentWrather').html(data)
        }).catch(err => console.log(err.message))
        );
  }
  noPermitir = (argument) => {
    jQuery('#putContentWrather').html('No permitido')
  }
  render(){
const getElementTr=(data)=>{
 var content = []
    if (data!=null) {
   content[0] =<>
                     <tr>
                      <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                       <span style={{color: 'black', fontSize: '13px'}}>
                        Descripción
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Vigor del Cultivo<br/>
                       C*(0,6-0,8) M*(0,3-0,5)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Salud del Cultivo<br/>
                       C(0,3-0,5) M(0,2-0,4)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Humedad de la Vegetación<br/>
                       C(0,4-0,6) M(0,2-0,4)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Humedad del Suelo<br/>
                       C(0,7-0,8) M(0,6-0,7)
                       </span>
                      </td>
                    </tr>
   </>
   //<Button data-url={data['Humedad de la Vegetacion'][indx]} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{data['Humedad de la Vegetacion'][indx]}</Button>
                    if (data['Descripción']!=undefined) {
                      Object.values(data['Descripción']).map((val,indx)=>{
                        if (val!='Índice del Cultivo') {
                          content[indx+1] = <>
                                        <tr>
                                         <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                           {val}
                                          </span>
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{this.openModalCanvas(e.target,"plotView_NDVI","spanPlotView_NDVI",'span')}}  className={'spanPlotView_NDVI'}><i class="fas fa-times closeIconTime_NDVI" onClick={()=>{jQuery('.spanPlotView_NDVI').click()}} style={{display:'none',color: 'gray',fontSize:'15px'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {this.viewImgTiff(data['Vigor del cultivo'][indx],'_NDVI',data['Vigor del cultivo'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Vigor del cultivo'][indx]}
                                          </span>
                                          )}
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{this.openModalCanvas(e.target,"plotView_NDRE","spanPlotView_NDRE",'span')}} className={'spanPlotView_NDRE'}><i class="fas fa-times closeIconTime_NDRE" onClick={()=>{jQuery('.spanPlotView_NDRE').click()}} style={{display:'none',color: 'gray',fontSize:'15px'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {this.viewImgTiff(data['Salud del cultivo'][indx],'_NDRE',data['Salud del cultivo'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Salud del cultivo'][indx]}
                                          </span>
                                          )}
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{this.openModalCanvas(e.target,"plotView_NDMI","spanPlotView_NDMI",'span')}}  className={'spanPlotView_NDMI'}><i class="fas fa-times closeIconTime_NDMI" onClick={()=>{jQuery('.spanPlotView_NDMI').click()}} style={{display:'none',color: 'gray',fontSize:'15px'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {this.viewImgTiff(data['Humedad de la Vegetacion'][indx],'_NDMI',data['Humedad de la Vegetacion'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Humedad de la Vegetacion'][indx]}
                                          </span>
                                          )}
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{this.openModalCanvas(e.target,"plotView_Suelo","spanPlotView_Suelo",'span')}} className={'spanPlotView_Suelo'}><i class="fas fa-times closeIconTime_Suelo" onClick={()=>{jQuery('.spanPlotView_Suelo').click()}} style={{display:'none',color: 'gray',fontSize:'15px'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {this.viewImgTiff(data['Humedad del Suelo'][indx],'_Suelo',data['Humedad del Suelo'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Humedad del Suelo'][indx]}
                                          </span>
                                          )}
                                         </td>
                                       </tr>
                          </>
                        }

                      })
                    }
    }
   return content
}

  return (
    <>


{/*      <GrainNamaNavBar getParams={window.location.search}/>  
      <SimpleHeaderProd name="Información Satélite" parentName="Gestión de cultivos" section="namas" section_two="Produccion" section_table_form="production_info_satelite" mrvtable="production_info_satelite"/>*/}
      <div className="mt-1 container-fluid">
        <Card className="mb-4">
          <CardBody className="pt-0">
                <Row>
                    <Col md={2}>
                     <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Código productor
                        </label>
                      <InputGroup>
                          <Input
                          value={this.state.code}
                          onChange={(e) => {this.setState({code:e.target.value})}}
                          placeholder="Puede pegar o generar el código automaticamente y luego guarde"
                          type="text"
                        />
                      </InputGroup>
                      <button onClick={()=>{this.geneCode()}} className="btn p-0">Insertar código registrado</button>
                     </FormGroup>
                    </Col>
              <Col md="2" style={{display:'flex'}}>
                <Button 
                style={{margin:'auto'}}
                className="btn-icon" 
                color="traza-blue" 
                type="button"
                disabled={this.state.btnd}
                onClick={()=>{this.generateInfo(this.state.code)}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-save" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
                </Row>
<Row>
                {(this.state.data_satelital=='cargando')?('Cargando...'):(
                    (this.state.data_satelital==null)?('Sin datos'):
                    (

                      (typeof this.state.data_satelital=='string')?(this.state.data_satelital):(<>
                      <Col md={2}>
                      {  Object.entries(this.state.data_satelital).reverse().map((val,index)=>{
                                     return <>
                                     <Col md={12}>
                                        <label
                                          className={"form-control-label mb-0 mt-1 clk_"+val[0]}
                                          htmlFor="example3cols2Input"
                                          onClick={async()=>{
                                            if (this.state.openColapse[0]==false || this.state.openColapse[0]==undefined) {
                                              this.opened(this.state.openColapse[0],0)
                                            }
                                            this.setState({setValueData:'load',dateSatelite:val[0]})
                                            var get_date = await this.getDataSatelital(this.state.code,val[0])
                                            this.setState({setValueData:get_date})
                                          }}
                                        >
                                        {val[0]}
                                        </label>
                                     </Col>
                                      </>
                                    })}
                      </Col>
<Col md={10}>
{(this.state.setValueData=='load')?(
  <label
    className="form-control-label mb-0 mt-1"
    htmlFor="example3cols2Input">
    Cargando...
  </label>
):(
<>
                                      <Collapse isOpen={this.state.openColapse[0]}>
{/*                                  <select 
                                  value={this.state.colorPallet}
                                  onChange={e => {this.setState({colorPallet:e.target.value})}}
                                        >
                                    <option value="mycolorspectral">Rampa de colores</option>
                                    <option value="inferno">Inferno</option>
                                    <option value="magma">Magma</option>
                                    <option value="plasma">Plasma</option>
                                    <option value="turbo">Turbo</option>
                                    <option value="viridis">Viridis</option>
                                  </select>*/}

                                     <div className="contentForPDF">
                                      <div style={{textAlign: 'center'}}>
                                        <label
                                        className="form-control-label mb-0 mt-1"
                                        htmlFor="example3cols2Input">
                                        {this.state.dateSatelite}
                                      </label>
                                      </div>
                                       <table className="align-items-center table-flush" responsive style={{border: '1px solid',width: '100%',color: 'black', fontSize: '13px'}}>
                                          <thead className="thead-light">
                                            <tr>
                                              <th style={{border: '1px solid',padding: '3px',width: '265px'}}>Ínidice del Cultivo</th>
                                              <th style={{border: '1px solid',padding: '3px'}}>NDVI</th>
                                              <th style={{border: '1px solid',padding: '3px'}}>NDRE</th>
                                              <th style={{border: '1px solid',padding: '3px'}}>NDMI</th>
                                              <th style={{border: '1px solid',padding: '3px'}}>HR Suelo</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {getElementTr(this.state.setValueData)}
                                          </tbody>
                                        </table>
                                        <div style={{color: 'black'}}>
                                           <span style={{fontSize: '12px'}}>* C: Crecimiento. M: Maduración.</span><br/>
                                         </div>
                                     </div>
                                     <Button 
                                       className="btn-icon" 
                                       color="traza-blue" 
                                       type="button" 
                                       disabled={this.state.btnd}
                                       onClick={()=>{ this.printer_pdf() } }>
                                       <span className="btn-inner--icon mr-1">
                                         <i className="fas fa-file-pdf" />
                                       </span>
                                       <span className="btn-inner--text">Guardar en PDF</span>
                                     </Button>
                                      </Collapse>
</>)}
</Col>
                      </>)

                    )
                  )}
</Row>
          </CardBody>
        </Card>
{/*        
      <ModalPSateliteModal sendInfoProps={this.state.dataFertilizerOne} refreshData={this.initData} setParamsUrl={this.props.location.search} tableCall="production_info_satelite" titleCall="Satelite" />
      */}
{/*        <ModalMWeed sendInfoProps={this.state.dataFertilizerOne} refreshData={this.initData} setParamsUrl={this.props.location} tableCall="production_info_satelite" titleCall="Satelite" />
        <ModalMWeed sendInfoProps={this.state.dataFertilizerTwo} refreshData={this.initData} setParamsUrl={this.props.location} tableCall="production_info_satelite_ndre" titleCall="Satelite NDRE (Salud del Cultivo)" />
        <ModalMWeed sendInfoProps={this.state.dataFertilizerTree} refreshData={this.initData} setParamsUrl={this.props.location} tableCall="production_info_satelite_ndmi" titleCall="Satelite NDMI (Humedad de la Vegetación)" />
        <ModalMWeed sendInfoProps={this.state.dataFertilizerFor} refreshData={this.initData} setParamsUrl={this.props.location} tableCall="production_info_satelite_ndhs" titleCall="Satelite HR-SUELO (Humedad Relativa del Suelo)" />*/}
      </div>
    </>
  );
 }
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false,
    data_user:null
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    // var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    this.setState({data_user:dataPermission})
          var data_type_area = dataPermission.type_traza.indexOf('P')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.production_nama_g==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <GNamaPLSatelite {...this.props} data_user={this.state.data_user} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

//export default Elements;
