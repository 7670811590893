import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
//import { useRouter } from 'next/navigation';
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";

const CardDocuments = () => {

    const router = useHistory(); 

    const header = (
        <img 
            alt="production" 
            src="/layout/images/icons/documents1.png"  
            className='w-2'
        />
    );

    return (
        <div>
            <Card title="Documentos" header={ header } >
                <Button 
                    label="Agregar" 
                    size="small"
                    className='button-color-fod-blue'
                    style={{width: '100%'}}
                    onClick={ () => {
                     router.push('/admin/grains-nama-documents'+router.location.search)
                    } }
                />
                <p className="mt-4">
                    {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                </p>
            </Card>
        </div>
    )
}

export default CardDocuments;