import React, { memo, useRef, useCallback } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle, 
  Position
} from 'reactflow';
 import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import 'reactflow/dist/style.css';
import {PutContentStatus} from 'views/pages/examples/Trazability/SectionsViewTrace/InfoLogistic'
import { OverlayPanel } from 'primereact/overlaypanel';

  //export const contentTypeProducer = ({data, isConnectable})=>{
   const ContentTypeProducer=({data, isConnectable})=>{

    const op = useRef(null);
    //console.log(data)
  return (<>
    <div 
    onClick={(e)=>{
      op.current.toggle(e);
    }} 
    style={{display:'flex',padding: '5px',border: 'solid 1px',borderRadius: '5px',width: '150px',fontFamily: 'Albert Sans'}}>
      <div style={{margin:'auto'}}>
      {(data.main_title=='')?(null):(
              <div className="text-center" style={{fontSize: '12px',color: 'black',fontWeight: '200',height: 'auto',lineHeight: '1',margin:'0px'}}>{data.main_title}</div>
        )}
          {data.data_top.map((a,b)=>{
            return (<div className="text-center" style={{fontSize: '12px',color: 'black',fontWeight: '200',height: 'auto',lineHeight: '1', margin: '0px'}}>{a}</div>)
          })}
          <div style={{display:'flex'}}>
            <div style={{margin:'auto'}}>
              <div style={{display: 'flex',width: '50px',height: '50px',  border: '1px solid #eee',  padding: '0px',  borderRadius: '30px', backgroundColor:data.colorStatus}}>
                <div id="vegefru" style={{margin:'auto'}}>
                  <span 
                  style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}}
                  className="eael-tab-item-trigger">
                  {/*<span style={{color: '#204d74',backgroundColor: 'white',padding: '10px',borderRadius: '30px',fontSize:'20px', textAlign: 'center', width: '40px', height: '40px'}} className={data.icon} ></span>*/}
                 <img 
                  style={{color: '#204d74',backgroundColor: 'white',padding: '0px',borderRadius: '30px',fontSize:'20px', textAlign: 'center', width: '40px', height: '40px'}}
                  //src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
                  src={data.icon}
                  alt="new"
                  />
                  </span>
                  <Handle
                    type="source"
                    position={Position.Right}
                    id={data.lote_numero}
                    isConnectable={isConnectable}
                  />
                </div>
              </div>
            </div>
          </div>
          {data.data_bottom.map((a,b)=>{
            return (<div className="text-center" style={{fontSize: '12px',color: 'black',fontWeight: '200',height: 'auto',lineHeight: '1', margin: '0px'}}>{a}</div>)
          })}
      </div>
    </div>
    {/*backgroundColor: 'white', border: 'solid 1px', borderRadius: '5px;'*/}
    <OverlayPanel ref={op} style={{ width: '230px' }}>
        <h5>Detalle de los datos</h5>
                {data.data_details.length > 0
                    ? (<>
                      {data.data_details.map((x, n) => (
                          <div
                              key={n}
                              style={{color:'black'}}
                              className="mb-2 cursor-pointer"
                          >
                              {x}
                          </div>
                      ))}
                      <button className="btn p-0" onClick={()=>{data.clkDataTraza(data,data.data_select,data.table_selected_info)}}>Ver información completa</button>
                    </>): (<><div>Sin datos principal</div><button className="btn p-0" onClick={()=>{data.clkDataTraza(data,data.data_select,data.table_selected_info)}}>Ver información completa</button></>)}
    </OverlayPanel>
  </>);
 }
 export default memo(ContentTypeProducer)