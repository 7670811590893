import React, {createRef} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import { get_permission, edit_user, upload_file_photo, send_email_repeat, check_guides, save_signature_mrv } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import NotificationAlert from "react-notification-alert";
import config from "config";
import jQuery from 'jquery';
import {GuideMessage} from "views/pages/components/GuideMessage";
import {UserContext} from "layouts/store";
import SignatureCanvas from 'react-signature-canvas';

class Profile extends React.Component {
  static contextType = UserContext
  constructor(props){
    super(props);
    this.myRefErase = React.createRef();
  }
state={
  nombre:'',
  apellido:'',
  email:'',
  empresa:'',
  pais:'',
  password:'',
  img_profile:null,
  notificationAlertRef: React.createRef(),
  disabledbtn:false,
  verfificar_label:'Comprobando',
  georeferencia:null,
  check_guide:false,
  sigDrawn:false,
  asigSigDrawn:false,
  codeProd:'',
  type_traza:''
}
async componentDidMount(){
this.setData()
}
setData = async () => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var getPermission = await get_permission(jsondata._id)
  this.setState({
  nombre:getPermission[0].name,
  apellido:getPermission[0].second_name_ap,
  phone:getPermission[0].telefono,
  email:getPermission[0].email,
  dni:getPermission[0].dni,
  empresa:getPermission[0].empresa,
  pais:getPermission[0].pais,
  roleUser:getPermission[0].role,
  img_profile:getPermission[0].photo,
  georeferencia:getPermission[0].ubicacion,
  verfificar_label:(getPermission[0].active_validation==0)?('Sin verificar'):('Verificado'),
  password:'',
  codeProd:getPermission[0].code_satelital,
  check_guide:(getPermission[0].guide_users==1)?(true):(false),
  type_traza:getPermission[0].type_traza
  })
  this.setState({get_data_user:getPermission[0]})
      if (getPermission[0].asigned_signature==1) {
        this.setState({asigSigDrawn:true})
        if (getPermission[0].draw_digital_signature==null) {
          this.setState({sigDrawn:true})
        }
      }else{
        this.setState({asigSigDrawn:false})
      }
  //const [role, setRole] = React.useState(user.userType);
}
checkGuide=async(e)=>{
  this.setState({check_guide: jQuery(e).is(':checked')})
  if (jQuery(e).is(':checked')) {
    jQuery('.fa-exclamation-circle').show()
  }else{
    jQuery('.fa-exclamation-circle').hide()
  }
  await check_guides((jQuery(e).is(':checked'))?(1):(0))
  await this.context.getAffiliates()
  this.setData()
}
changeSigDraw=(data)=>{
  this.setState({sigDrawn:data})
}
asignedSigDraw=async(data)=>{
      this.setState({asigSigDrawn:data})
      this.setState({sigDrawn:data})
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var data_result = await save_signature_mrv(jsondata._id,data,1)
}
saveSignature= async()=>{
     var get_signature=this.sigPad.getTrimmedCanvas().toDataURL('image/png')
      if (get_signature.indexOf('iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC')!=-1) {
        alert('Es Necesario una Firma')
        return
      }
      this.setState({disabledbtn:true})
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var data_result = await save_signature_mrv(jsondata._id,get_signature,0)
      if (data_result.success) {
        this.setState({disabledbtn:false})
        var get_data_user = await get_permission(jsondata._id)
        this.setState({get_data_user:get_data_user[0]})
        this.setState({sigDrawn:false})
      }
}
generateSigDraw=async()=>{
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var getDataUser = this.state.get_data_user
      getDataUser.system_digital_signature = 'Generando Código'
      var putDataUser = getDataUser
      this.setState({get_data_user:putDataUser})
      var data_result = await save_signature_mrv(jsondata._id,null,2)
      if (data_result.success) {
        var get_data_user = await get_permission(jsondata._id)
        this.setState({get_data_user:get_data_user[0]})
      }
}
saveActivity=async(indx_one,indx_two,dataUser)=>{
  await this.setGlobalValues(dataUser.draw_digital_signature,'signature_draw','general',indx_one,indx_two)
  await this.setGlobalValues(dataUser.system_digital_signature,'signature_digital','general',indx_one,indx_two)
  await this.functionSave(this.state.activity_selected)
  this.setState({sendOpenModal:!this.state.sendOpenModal})
}
eraseSignature=()=>{
  this.sigPad.clear();
}
render(){
const geolocacion = () => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(VerPosicion,noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    function VerPosicion(posicion){
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              setinfolocation(lat,lon,data)
            }

        })
        .catch(err => console.log(err.message)));
    }
        const setinfolocation = (lat,lon,data) => {
              this.setState({loadlocali:null})
              this.setState({georeferencia:data.results[0].formatted})
    }

    function noPermitir(argument) {
      this.setState({loadlocali:null})
    // $('.cargadores').hide();
    }  
  const editProfile = async () => {
    this.setState({ disabledbtn:true })
    let user_data = JSON.parse(localStorage.getItem("user"));
    var changeEmail;
    if (user_data.email==this.state.email) {
      changeEmail = 0
    }else{
      changeEmail = 1
    }
    const response = await edit_user(user_data._id, this.state, changeEmail);
    const { data } = response;
    if (data.success) {
      this.setData()
      localStorage.setItem("user", JSON.stringify(data));
      this.setState({ disabledbtn:false })
      notify("success", "¡Registrado exitosamente!", 'Información modificada exitosamente');
      this.props.history.push("/admin/home");
    }
  }
  const uploadFile = async (file) => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var data = await upload_file_photo(file,jsondata._id)

      if (data.message=="exito") {
          localStorage.setItem("photo_profile", data.namephoto);
          notify("success", "¡Registrado exitosamente!", 'Se guardo la imagen.');
          this.setData()
      }
  }
  const sendMail = async () => {
    if (this.state.verfificar_label=='Verificado') {
      alert('Edite su correo.')
    }else{
      var data = await send_email_repeat(this.state.email,this.state.nombre)
      if (data=='exito') {
        notify("success", "¡Link de verificación enviado!", 'Verifique su correo.');
      }
    }
  }
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    const listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
    const listCountryCode = CountrysData.sort(listaArray)

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      {/*<ProfileHeader check_guide={this.state.check_guide}/>*/}
      <div className="mt-3">
        <Row>
          <div>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Datos del usuario</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="traza-blue"
                      href="#"
                      onClick={editProfile}
                      size="sm"
                      disabled={this.state.disabledbtn}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
{/*                <Form>
                </Form>*/}
                  <div className="pl-lg-4">
                    <Row>
                <Col md={12} className="text-center pb-4" onClick={()=>{jQuery("#uploadFile").click()}}>
                {(this.state.img_profile==null)?('Subir Imagen'):((this.state.img_profile=='null')?('Sin imagen'):(
                  <img style={{width: '100px',borderRadius: '100px'}} src={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.img_profile} />
                  ))}
                  <input
                  autoComplete="none" type="file" id="uploadFile" style={{display: 'none'}} accept="image/png, image/jpeg" onChange={(e)=>{uploadFile(e.target.files[0])}} />
                  <br />
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Subir imagen
                          </label>
                </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Nombre
                          </label>
                          <Input
                            value={this.state.nombre}
                            onChange={e => this.setState({nombre:e.target.value})}
                            id="input-name"
                            placeholder="Ingresa un nombre"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Apellido
                          </label>
                          <Input
                            value={this.state.apellido}
                            onChange={e => this.setState({apellido:e.target.value})}
                            id="input-name"
                            placeholder="Ingresa un apellido"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Cédula
                          </label>
                          <Input
                            value={this.state.dni}
                            onChange={e => this.setState({dni:e.target.value})}
                            id="input-name"
                            placeholder="Ingresa un cédula"
                            type="text"
                            
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Teléfono
                          </label>
                          <Input
                            value={this.state.phone}
                            onChange={e => this.setState({phone:e.target.value})}
                            id="input-name"
                            placeholder="Ingresa un número de teléfono"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Empresa
                          </label>
                          <Input
                            value={this.state.empresa}
                            onChange={e => this.setState({empresa:e.target.value})}
                            id="input-name"
                            placeholder="Ingresa un nombre de empresa"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            País
                          </label>
                        <Input 
                          type="select" 
                            value={this.state.pais}
                            onChange={e => this.setState({pais:e.target.value})}
                                >
                            <option value="0">Pais</option>
                           {  listCountryCode.map((value,index)=>{
                               return <option value={value.Name}>{value.Name}</option>
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                                <Col md="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Ubicación
                                      </label>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                          value={this.state.georeferencia}
                                          id="example3cols2Input"
                                          className="ubicacionex"
                                          placeholder="Escriba su Ubicación"
                                          type="textarea"
                                          onChange={(e) => this.setState({georeferencia:e.target.value})}
                                        />
                                          {this.state.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <a href="javascript:void(0)" style={{color: '#002a5c'}} onClick={()=> {geolocacion()}}>
                                          <span style={{fontSize: '20px'}} className="fa fa-map-marker"></span>
                                        </a>
                                        </Col>  
                                      </Row>
                                      </FormGroup>
                                  </Col>
                      <Col lg="6">
                        <FormGroup className="mb-0">
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Correo electrónico
                          </label>
                          <Input
                            value={this.state.email}
                            onChange={e => this.setState({email:e.target.value})}
                            id="input-email"
                            placeholder="Ingresa un correo electrónico"
                            disabled
                            type="email"
                          />
                        </FormGroup>
                          <label onClick={()=>{sendMail()}} style={{color:(this.state.verfificar_label=='Verificado')?('#66c755'):('#c75555')}} className="form-control-label" htmlFor="input-email">
                            {this.state.verfificar_label}
                          </label>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Contraseña
                          </label>
                          <Input
                            value={this.state.password}
                            onChange={e => this.setState({password:e.target.value})}
                            id="input-country"
                            placeholder="Ingresa una nueva contraseña"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      
                      {(this.state.type_traza.indexOf('P')!=-1)?(<>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Código productor
                            </label>
                            <Input
                              value={this.state.codeProd}
                              onChange={e => this.setState({codeProd:e.target.value})}
                              id="input-country"
                              placeholder="Ingresa código productor"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </>):(null)}
                      <Col md="12">
                        <Row>
                          <Col md={6}>
                              <label className="form-control-label" htmlFor="input-country">
                                Mi firma digital
                              </label>
                              <div style={{textAlign:'center'}}>
                                {(this.state.get_data_user?.system_digital_signature==null)?(<>
                                        <div style={{width:"380px",margin:'auto'}}>
                                          <button className="btn" onClick={()=>{this.generateSigDraw()}}>Generar firma digital (Genere la firma digital para guardar)</button>
                                        </div>
                                  </>):(<>
                                      <h4>{this.state.get_data_user?.system_digital_signature}</h4>
                                  </>)}
                              </div>
                          </Col>
                          <Col md={6}>
                            <label className="form-control-label" htmlFor="input-country">
                              Mi firma dibujo
                            </label>
                            <div style={{textAlign:'center'}}>
                              <label className="form-control-label">Asignar dibujo firma</label> <input type="checkbox" onChange={()=>{this.asignedSigDraw(!this.state.asigSigDrawn)}} checked={this.state.asigSigDrawn}/>
                            </div>
                            {(this.state.asigSigDrawn)?(
                                      (this.state.sigDrawn)?(<>
                                                  <div style={{border: '1px solid #dee2e6',width:"380px",height:"200px",margin:'auto',marginBottom:'40px'}}>
                                                    <SignatureCanvas 
                                                    penColor='black' 
                                                    canvasProps={{width: 380, height: 200, className: 'sigCanvas'}}
                                                    ref={(ref) => { this.sigPad = ref }}
                                                    />
                                                  <button disabled={this.state.disabledbtn} className="btn" onClick={()=>{this.saveSignature()}}>Guardar Firma</button>
                                                  <button className="btn" onClick={()=>{this.eraseSignature()}}>Borrar Firma</button>
                                                  </div>
                                        </>):(
                                              (this.state.get_data_user?.draw_digital_signature==null)?(null):(<>
                                                <div style={{border: '1px solid #dee2e6',width:"380px",height:"auto",margin:'auto'}}>
                                                  <div style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
                                                    <img 
                                                      style={{ backgroundSize: '100px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                                                      src={this.state.get_data_user?.draw_digital_signature} 
                                                      />
                                                  </div>
                                                    <br />
                                                </div>
                                                <div style={{width:"380px",margin:'auto'}}>
                                                  <button className="btn" onClick={()=>{this.changeSigDraw(!this.state.sigDrawn)}}>Cambiar Firma</button>
                                                </div>
                                              </>)
                                        )
                              ):(null)}
                          </Col>
                        </Row>
                      </Col>
{/*                      <Col md={6}>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Guía
                              </label>
                          <div className="custom-control custom-switch" style={{width: '140px',margin: 'auto'}}>
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.check_guide} onClick={(e)=>{this.checkGuide(e.target)}} />
                            <label className="custom-control-label form-control-label" for="customSwitch1"></label>
                          </div>
                          </Col>*/}
                    </Row>
                  </div>
                
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
 }
}

export default Profile;
