import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table,
  InputGroup
} from "reactstrap";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_traza_lote_job, create_traza_animal_edit, delete_traza_animal, data_details_animal, set_vacuna_register, set_peso_register, set_aftosa_register, set_fattening_register, set_medicine_register, set_moreinfo_register, set_task_register, delete_vacuna, delete_peso, delete_aftosa, delete_fattening, delete_medicine, delete_moreinfo, delete_task, delete_arete, set_arete_register, upload_file_img, get_permission} from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import jQuery from "jquery";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class DetailsAnimal extends React.Component {
  state ={
    notificationAlertRef: React.createRef(),
    sumFieldVacuna:0,
    sumFieldpeso:0,
    sumFieldaftosa:0,
    sumFieldfattening:0,
    sumFieldmedicine:0,
    sumFieldmoreinfo:0,
    sumFieldtask:0,
    dataAnimal: null,
    fecha_registro:'',
    hora_registro:'',
    madre_id:'',
    animal_arete_id:'',
    fecha_nacimiento:'',
    peso_inicial:'',
    raza:'',
    edad:'',
    unidadedad:'',
    sexo:'',
    type_lote:[],
    compartiment:[],
    btnd:false,
    input_vacuna_fecha:[],
    dataVacunas:[],
    dataVacunasTatic:[],
    datapeso:[],
    datapesoTatic:[],
    dataaftosa:[],
    dataaftosaTatic:[],
    datafattening:[],
    datafatteningTatic:[],
    datamedicine:[],
    datamedicineTatic:[],
    datamoreinfo:[],
    datamoreinfoTatic:[],
    datatask:[],
    datataskTatic:[],
    sumFieldarete:0,
    dataarete:[],
    dataareteTatic:[],
    openModal:false,
    btnd:false,
    mess:'',
    sumFieldtaskAnimalGlobal:[],
    datataskAnimalGlobal:[],
    datataskAnimalGlobalTatic:[],
    data_traza_inventory:[],
    animal_register_id:'',
    codigo_traza:'',
    nombre_traza:''
  } 
 async componentDidMount(){
    this.dataInitSet()
  }
  dataInitSet = async () => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
    var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
    var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
    var gerParamTree = new URLSearchParams(this.props.location.search).get("edit");
    var gerParamFour = new URLSearchParams(this.props.location.search).get("lote");

    var data = await data_details_animal(gerParamOne,gerParamTwo,gerParamFour)
    this.setState({animal_id:gerParamTwo})
    this.setState({codigo:gerParamOne})
    this.setState({confirmEdit:gerParamTree})
      var dataAnimal = await row_traza_lote_job(jsondata._id,gerParamOne,gerParamFour)
      this.setState({
        data_traza_inventory:dataAnimal.trazaDataLote
      })
if (data.vacuna.length!=0) {
    this.setState({
      sumFieldVacuna:data.vacuna.length,
      dataVacunas:data.vacuna,
      dataVacunasTatic:data.vacuna
    })
}
if (data.peso.length!=0) {
    this.setState({
      sumFieldpeso:data.peso.length,
      datapeso:data.peso,
      datapesoTatic:data.peso
    })
}
if (data.aftosa.length!=0) {
    this.setState({
      sumFieldaftosa:data.aftosa.length,
      dataaftosa:data.aftosa,
      dataaftosaTatic:data.aftosa
    })
}
if (data.fattening.length!=0) {
    this.setState({
      sumFieldfattening:data.fattening.length,
      datafattening:data.fattening,
      datafatteningTatic:data.fattening
    })
}
if (data.medicine.length!=0) {
    this.setState({
      sumFieldmedicine:data.medicine.length,
      datamedicine:data.medicine,
      datamedicineTatic:data.medicine
    })
}
if (data.moreinfo.length!=0) {
    this.setState({
      sumFieldmoreinfo:data.moreinfo.length,
      datamoreinfo:data.moreinfo,
      datamoreinfoTatic:data.moreinfo
    })
}
if (data.task.length!=0) {
    this.setState({
      sumFieldtask:data.task.length,
      datatask:data.task,
      datataskTatic:data.task
    })
}
if (data.arete.length!=0) {
    this.setState({
      sumFieldarete:data.arete.length,
      dataarete:data.arete,
      dataareteTatic:data.arete,
    })
  }
if (data.taskAnimalGlobal.length!=0) {
    this.setState({
      sumFieldtaskAnimalGlobal:data.taskAnimalGlobal.length,
      datataskAnimalGlobal:data.taskAnimalGlobal,
      datataskAnimalGlobalTatic:data.taskAnimalGlobal,
    })
  }
    //(this.state.dataarete[0]==undefined)?(null):(this.state.dataarete.pop().informacion_adicional)

    this.setState({
      animal_register_id:data.dataAnimal.id,
      codigo_traza:data.dataAnimal.codigo,
      nombre_traza:data.traza_meat.nombre_traza,
      detailAnimal:data.dataAnimal,
      fecha_registro:data.dataAnimal.fecha_registro,
      hora_registro:data.dataAnimal.hora_registro,
      madre_id:data.dataAnimal.madre_id,
      animal_arete_id:data.dataAnimal.animal_id,
      fecha_nacimiento:data.dataAnimal.fecha_nacimiento,
      peso_inicial:data.dataAnimal.peso_inicial,
      raza:(data.dataAnimal.raza=="null")?('Sin Datos'):(data.dataAnimal.raza),
      edad:(data.dataAnimal.edad=="null")?('Sin Datos'):(data.dataAnimal.edad),
      unidadedad:(data.dataAnimal.unidadedad=="null")?('Sin Datos'):(data.dataAnimal.unidadedad),
      sexo:(data.dataAnimal.sexo=="null")?('Sin Datos'):(data.dataAnimal.sexo),
      img_animal:(data.dataAnimal.archivo==null)?(null):(data.dataAnimal.archivo),
      type_lote:(data.dataAnimal.type_lote=="0")?('Sin lote asignado'):(data.dataAnimal.type_lote),
      compartiment:(data.compartment==null)?('Sin fecha'):((data.compartment.fecha_recibido=="0")?('Sin fecha'):(data.compartment.fecha_recibido+' '+data.compartment.hora_recibido)),
    })
  }
  render(){

const sumFieldVacuna = () => {

     /* var content = document.querySelectorAll('.dataVacunaRowVacuna')
      var contentDosis = document.querySelectorAll('.dataVacunaRowDosis')
  for (var i = 0; i < content.length; i++) {
      if (content!=null) {
        if (content[i]!=undefined) {
        content[i].value=content[i].value
        contentDosis[i].value=contentDosis[i].value
        }
      }
  }*/

  this.setState({sumFieldVacuna:this.state.sumFieldVacuna+1})
   //this.setState({dataVacunas:[1]})
  this.setState({dataVacunasTatic:[...this.state.dataVacunasTatic,{id:'idNone'}]})


  //this.setState({dataVacunas:[...this.state.dataVacunas,{id:this.state.sumFieldVacuna+1,vacuna:null,dosis:null}]})
}
const restFieldVacuna = async (index,id_vacuna) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_vacuna!='idNone') {
    var result = await delete_vacuna(id_vacuna,jsondata._id,this.state.codigo)
    if (result=="exito") {
      this.setState({dataVacunasTatic:[]})
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }

  }else{

      //document.querySelector("."+index).remove()
       // jQuery('.SectionSpanVacuna').children().last().insertBefore(jQuery(this).prev());
       // jQuery("."+index).insertBefore('.SectionSpanVacuna').children().last();
       //jQuery('.SectionSpanVacuna').children("."+index).remove();
        var globalSelector = jQuery('.SectionSpanVacuna').children().last()
        var selectorEspecific = jQuery("."+index)

        var vacunaValFecha = globalSelector.find('.dataVacunaRowFecha').val();
        var vacunaValDosis = globalSelector.find('.dataVacunaRowDosis').val();
        var vacunaValVacuna = globalSelector.find('.dataVacunaRowVacuna').val();

        selectorEspecific.find('.dataVacunaRowFecha').val(vacunaValFecha)
        selectorEspecific.find('.dataVacunaRowDosis').val(vacunaValDosis)
        selectorEspecific.find('.dataVacunaRowVacuna').val(vacunaValVacuna)

        var dataArray = this.state.dataVacunasTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('vacunaSection','')) );
        this.setState({dataVacunasTatic:newArray})
        //this.setState({dataVacunasTatic:[]})
      // jQuery("."+index).appendTo('.SectionSpanVacuna');
      // jQuery('.SectionSpanVacuna').children().last().prependTo('.SectionSpanVacuna');

      // this.setState({dataVacunasTatic:[]})

      //dataArray.push(dataArray.splice(index.replace('vacunaSection',''), 1)[0]);
      //delete dataArray[parseInt(index.replace('vacunaSection',''))]
      //this.dataInitSet()
  }
}

const vacunasContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
var setDataFor = []
if (this.state.dataVacunasTatic[0]==undefined) {
 setDataFor = []
}else{
setDataFor = this.state.dataVacunasTatic
}
  for (var index = 0; index < this.state.dataVacunasTatic.length; index++) {
  //document.querySelectorAll(".dataVacunaRowVacuna")[i].value = (value==undefined)?(null):(value.vacuna)
             //this.state.dataVacunas.map((value,index)=>{

             data[index] = <Row style={{marginBottom: '5px'}} key={index} className={"vacunaSection"+index}>
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.dataVacunas[index]==undefined)?(completeDate):('Fecha: '+this.state.dataVacunas[index].fecha)}</span>)
                              :
                              <Input
                              autoComplete="none"
                                                          style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                                                          onChange={(e)=>{this.setState({dataVacunas: {[index]:{fecha:e.target.value}} })}}
                                                          className="dataVacunaRowFecha"
                                                          vacuna-id={(this.state.dataVacunasTatic[index]==undefined)?('idNone'):(this.state.dataVacunasTatic[index].id)}
                                                          defaultValue={(this.state.dataVacunas[index]==undefined)?(completeDate):((this.state.dataVacunas[index].fecha==undefined)?(completeDate):(this.state.dataVacunas[index].fecha))}
                                                          id="example-date-input"
                                                          type="date"
                                                        />}
                        </Col> 
                         <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.dataVacunas[index]==undefined)?(null):('Vacuna: '+this.state.dataVacunas[index].vacuna)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({dataVacunas:{[index]:{vacuna:e.target.value}} })} }
                              //value={(value==undefined)?(null):(value.vacuna)}
                              onLoad={loadInfoVacuna(this.state.dataVacunas[index],index)}
                              className="dataVacunaRowVacuna"
                              //vacuna-id={(this.state.dataVacunasTatic[index]==undefined)?('idNone'):(this.state.dataVacunasTatic[index].id)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="Vacuna"
                              id="example-date-input"
                              type="text"
                            />}
                        </Col>
                        <Col md={3}>
                              {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.dataVacunas[index]==undefined)?(null):('Dosis: '+this.state.dataVacunas[index].dosis)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({dataVacunas: {[index]:{dosis:e.target.value}} })}}
                              //value={(value==undefined)?(null):(value.dosis)}
                              className="dataVacunaRowDosis"
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="Dosis"
                              id="example-date-input"
                              type="text"
                            />}
                        </Col>
 {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"vacunaSection"+index} vacuna-id={(this.state.dataVacunasTatic[index]==undefined)?('idNone'):(this.state.dataVacunasTatic[index].id)} onClick={(e) => {restFieldVacuna(e.target.id,e.target.getAttribute('vacuna-id'))} }>-</Button>
                        </Col>}
                      </Row>
             //})
                    }
                    return(data)
                    

                    

    }
const loadInfoVacuna = (result,section) => {
var content = document.querySelectorAll('.dataVacunaRowVacuna')[section]
var contentDosis = document.querySelectorAll('.dataVacunaRowDosis')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.vacuna==undefined)?(content.value):(result.vacuna)
    contentDosis.value=(result.dosis==undefined)?(contentDosis.value):(result.dosis)
    }
  }
}
  const saveInfoVacuna = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var dataInfoPut = []
      var dataVacunaGetFecha = document.querySelectorAll(".dataVacunaRowFecha");
      var dataVacunaGetVacuna = document.querySelectorAll(".dataVacunaRowVacuna");
      var dataVacunaGetDosis = document.querySelectorAll(".dataVacunaRowDosis");
      for (var i = 0; i < dataVacunaGetFecha.length; i++) {
        let id = dataVacunaGetFecha[i].getAttribute("vacuna-id");
        let vacuna_fecha = dataVacunaGetFecha[i].value
        let vacuna_vacuna = dataVacunaGetVacuna[i].value
        let vacuna_dosis = dataVacunaGetDosis[i].value

        dataInfo[i] = {id_vacuna:id,vacuna_fecha:vacuna_fecha,vacuna_vacuna:vacuna_vacuna,vacuna_dosis:vacuna_dosis}
        dataInfoPut[i] = {id:id,fecha:vacuna_fecha,vacuna:vacuna_vacuna,dosis:vacuna_dosis}
      }
      //console.log(dataInfoPut)
        //  this.setState({dataVacunasTatic:dataInfoPut})
       //console.log(dataInfoPut)
        //this.setState({dataVacunasTatic:dataInfoPut})
      // var dataArray = this.state.dataVacunasTatic
      // dataArray.push(dataArray.splice(index.replace('vacunaSection',''), 1)[0]);
      //  const newArray = dataArray.filter((person,indexa) => person.idTwo !== id_vacuna );

         var data_vacuna_result = await set_vacuna_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)
         if (data_vacuna_result=='exito') {
          this.setState({dataVacunasTatic:[]})
          this.dataInitSet()
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
         }else{
          notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
         }
    }

const sumFieldpeso = () => {
  this.setState({sumFieldpeso:this.state.sumFieldpeso+1})
  this.setState({datapesoTatic:[...this.state.datapesoTatic,{id:'idNone',fecha:null,agregar_peso:null}]})
}
const restFieldpeso = async (index,id_peso) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_peso!="idNone") {
        var result = await delete_peso(id_peso,jsondata._id,this.state.codigo)
    if (result=="exito") {
      this.setState({datapesoTatic:[]})
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }else{
  //document.querySelector("."+index).remove()
        var globalSelector = jQuery('.SectionSpanPeso').children().last()
        var selectorEspecific = jQuery("."+index)

        var datapesoRowFecha = globalSelector.find('.datapesoRowFecha').val();
        var datapesoRowpeso = globalSelector.find('.datapesoRowpeso').val();
        var datapesoRowunidad = globalSelector.find('.datapesoRowunidad').val();

        selectorEspecific.find('.datapesoRowFecha').val(datapesoRowFecha)
        selectorEspecific.find('.datapesoRowpeso').val(datapesoRowpeso)
        selectorEspecific.find('.datapesoRowunidad').val(datapesoRowunidad)

        var dataArray = this.state.datapesoTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('pesoSection','')) );
        this.setState({datapesoTatic:newArray})
  }
}

    const pesoContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.datapesoTatic.length; i++) {
  //this.state.datapeso.map((value,i)=>{
  //document.querySelectorAll(".datapesoRowVacuna")[i].value = (this.state.datapeso[i]==undefined)?(null):(this.state.datapeso[i].vacuna)
             data[i] =
             <div className={"pesoSection"+i} key={i}> 
             <Row style={{marginBottom: '5px'}} >
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datapeso[i]==undefined)?(null):('Fecha: '+this.state.datapeso[i].fecha)}</span>)
                              :                                    
                              <Input
                                    autoComplete="none"
                                      style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                                      onChange={(e)=>{this.setState({datapeso: {[i]:{fecha:e.target.value}} })}}
                                      className="datapesoRowFecha"
                                      peso-id={(this.state.datapesoTatic[i]==undefined)?('idNone'):(this.state.datapesoTatic[i].id)}
                                      defaultValue={(this.state.datapeso[i]==undefined)?(completeDate):((this.state.datapeso[i].fecha==undefined)?(completeDate):(this.state.datapeso[i].fecha))}
                                      id="example-date-input"
                                      type="date"
                                    />
                            }
                        </Col> 
                         <Col md={4}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datapeso[i]==undefined)?(null):('Peso: '+this.state.datapeso[i].agregar_peso)}</span>)
                              :
                          <InputGroup>
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({datapeso:{[i]:{agregar_peso:e.target.value}} })} }
                              //value={(this.state.datapeso[i]==undefined)?(null):(this.state.datapeso[i].agregar_peso)}
                              onLoad={loadInfopeso(this.state.datapeso[i],i)}
                              className="datapesoRowpeso"
                              peso-id={(this.state.datapesoTatic[i]==undefined)?('idNone'):(this.state.datapesoTatic[i].id)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="peso"
                              id="example-date-input"
                              type="text"
                            />
                              <Input
                                      //value={this.state.unidad_peso}
                                      //onChange={(e)=>{ this.setState({unidad_peso:e.target.value}) }} 
                                      style={{padding: 0,fontSize:'12px',color: 'black'}}
                                      className="datapesoRowunidad"
                                      id="exampleFormControlSelect1" 
                                      peso-id={(this.state.datapesoTatic[i]==undefined)?('idNone'):(this.state.datapesoTatic[i].id)}
                                      type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="g">g</option>
                                      <option value="kg">Kg</option>
                                      <option value="libras">lb</option>
                                      <option value="tonelada">Tonelada</option>
                                    </Input>
                            </InputGroup>
                              }
                        </Col>
                        {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"pesoSection"+i} peso-id={(this.state.datapesoTatic[i]==undefined)?('idNone'):(this.state.datapesoTatic[i].id)} onClick={(e) => {restFieldpeso(e.target.id,e.target.getAttribute('peso-id'))} }>-</Button>
                        </Col>
                        }
                      </Row>
                      </div>
  //})
                    }
                return(data)
    }
    const loadInfopeso = (result,section) => {
    var content = document.querySelectorAll('.datapesoRowpeso')[section]
    var contentunidad = document.querySelectorAll('.datapesoRowunidad')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.agregar_peso==undefined)?(content.value):(result.agregar_peso)
    }
  }
  if (contentunidad!=null) {
    if (result!=undefined) {
    contentunidad.value=(result.unidad_peso==undefined)?(contentunidad.value):(result.unidad_peso)
    }
  }

}

  const saveInfopeso = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datapesoGetFecha = document.querySelectorAll(".datapesoRowFecha");
      var datapesoGetpeso = document.querySelectorAll(".datapesoRowpeso");
      var datapesoGetunidad = document.querySelectorAll(".datapesoRowunidad");
      for (var i = 0; i < datapesoGetFecha.length; i++) {
        let id = datapesoGetFecha[i].getAttribute("peso-id");
        let peso_fecha = datapesoGetFecha[i].value
        let peso_peso = datapesoGetpeso[i].value
        let peso_unidad = datapesoGetunidad[i].value

        dataInfo[i] = {id_peso:id,peso_fecha:peso_fecha,peso_peso:peso_peso,peso_unidad:peso_unidad}
      }
       var data_peso_result = await set_peso_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_peso_result=='exito') {
        this.setState({datapesoTatic:[]})
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldaftosa = () => {
  this.setState({sumFieldaftosa:this.state.sumFieldaftosa+1})
  //this.setState({dataaftosa:[this.state.sumFieldaftosa+1]})
  this.setState({dataaftosaTatic:[...this.state.dataaftosaTatic,{id:'idNone'}]})

}
const restFieldaftosa = async (index,id_aftosa) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_aftosa!="idNone") {
        var result = await delete_aftosa(id_aftosa,jsondata._id,this.state.codigo)
    if (result=="exito") {
      this.setState({dataaftosaTatic:[]})
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }else{
  //document.querySelector("."+index).remove()
        var globalSelector = jQuery('.SectionSpanAftosa').children().last()
        var selectorEspecific = jQuery("."+index)

        var dataaftosaRowFecha = globalSelector.find('.dataaftosaRowFecha').val();
        var dataaftosaRowaftosa = globalSelector.find('.dataaftosaRowaftosa').val();

        selectorEspecific.find('.dataaftosaRowFecha').val(dataaftosaRowFecha)
        selectorEspecific.find('.dataaftosaRowaftosa').val(dataaftosaRowaftosa)

        var dataArray = this.state.dataaftosaTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('aftosaSection','')) );
        this.setState({dataaftosaTatic:newArray})
  }
}
    const aftosaContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.dataaftosaTatic.length; i++) {
  //document.querySelectorAll(".dataaftosaRowVacuna")[i].value = (this.state.dataaftosa[i]==undefined)?(null):(this.state.dataaftosa[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"aftosaSection"+i}>
                        <Col md={3}>
                        {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.dataaftosa[i]==undefined)?(null):('Fecha: '+this.state.dataaftosa[i].fecha)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                              onChange={(e)=>{this.setState({dataaftosa: {[i]:{fecha:e.target.value}} })}}
                              className="dataaftosaRowFecha"
                              aftosa-id={(this.state.dataaftosaTatic[i]==undefined)?('idNone'):(this.state.dataaftosaTatic[i].id)}
                              defaultValue={(this.state.dataaftosa[i]==undefined)?(completeDate):((this.state.dataaftosa[i].fecha==undefined)?(completeDate):(this.state.dataaftosa[i].fecha))}
                              id="example-date-input"
                              type="date"
                            />
                          }
                        </Col> 
                         <Col md={3}>
                         {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.dataaftosa[i]==undefined)?(null):('Resultado: '+this.state.dataaftosa[i].resultado)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({dataaftosa:{[i]:{resultado:e.target.value}} })} }
                              //value={(this.state.dataaftosa[i]==undefined)?(null):(this.state.dataaftosa[i].resultado)}
                              className="dataaftosaRowaftosa"
                              aftosa-id={(this.state.dataaftosaTatic[i]==undefined)?('idNone'):(this.state.dataaftosaTatic[i].id)}
                              onLoad={loadInfoaftosa(this.state.dataaftosa[i],i)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="resultado"
                              id="example-date-input"
                              type="text"
                            />
                          }
                        </Col>
                            {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"aftosaSection"+i} aftosa-id={(this.state.dataaftosaTatic[i]==undefined)?('idNone'):(this.state.dataaftosaTatic[i].id)} onClick={(e) => {restFieldaftosa(e.target.id,e.target.getAttribute('aftosa-id'))} }>-</Button>
                        </Col>
                          }
                      </Row>

                    }
                return(data)
    }
        const loadInfoaftosa = (result,section) => {
    var content = document.querySelectorAll('.dataaftosaRowaftosa')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.resultado==undefined)?(content.value):(result.resultado)
    }
  }
}
  const saveInfoaftosa = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var dataaftosaGetFecha = document.querySelectorAll(".dataaftosaRowFecha");
      var dataaftosaGetaftosa = document.querySelectorAll(".dataaftosaRowaftosa");
      var dataaftosaGetDosis = document.querySelectorAll(".dataaftosaRowDosis");
      for (var i = 0; i < dataaftosaGetFecha.length; i++) {
        let id = dataaftosaGetFecha[i].getAttribute("aftosa-id");
        let aftosa_fecha = dataaftosaGetFecha[i].value
        let aftosa_resultado = dataaftosaGetaftosa[i].value

        dataInfo[i] = {id_aftosa:id,aftosa_fecha:aftosa_fecha,aftosa_resultado:aftosa_resultado}
      }
       var data_aftosa_result = await set_aftosa_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_aftosa_result=='exito') {
        this.setState({dataaftosaTatic:[]})
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
const sumFieldfattening = () => {
  this.setState({sumFieldfattening:this.state.sumFieldfattening+1})
  //this.setState({datafattening:[this.state.sumFieldfattening+1]})
  this.setState({datafatteningTatic:[...this.state.datafatteningTatic,{id:'idNone'}]})
}
const restFieldfattening = async (index,id_fattening) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_fattening!="idNone") {
        var result = await delete_fattening(id_fattening,jsondata._id,this.state.codigo  )
    if (result=="exito") {
    this.setState({datafatteningTatic:[]})
     this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }else{
  //document.querySelector("."+index).remove()
          var globalSelector = jQuery('.SectionSpanFattening').children().last()
        var selectorEspecific = jQuery("."+index)

        var datafatteningRowFecha = globalSelector.find('.datafatteningRowFecha').val();
        var datafatteningRowfattening = globalSelector.find('.datafatteningRowfattening').val();

        selectorEspecific.find('.datafatteningRowFecha').val(datafatteningRowFecha)
        selectorEspecific.find('.datafatteningRowfattening').val(datafatteningRowfattening)

        var dataArray = this.state.datafatteningTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('fatteningSection','')) );
        this.setState({datafatteningTatic:newArray})
  }
}
    const fatteningContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.datafatteningTatic.length; i++) {
  //document.querySelectorAll(".datafatteningRowVacuna")[i].value = (this.state.datafattening[i]==undefined)?(null):(this.state.datafattening[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"fatteningSection"+i}>
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datafattening[i]==undefined)?(null):('Fecha: '+this.state.datafattening[i].fecha)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                              onChange={(e)=>{this.setState({datafattening: {[i]:{fecha:e.target.value}} })}}
                              className="datafatteningRowFecha"
                              fattening-id={(this.state.datafatteningTatic[i]==undefined)?('idNone'):(this.state.datafatteningTatic[i].id)}
                              defaultValue={(this.state.datafattening[i]==undefined)?(completeDate):((this.state.datafattening[i].fecha==undefined)?(completeDate):(this.state.datafattening[i].fecha))}
                              id="example-date-input"
                              type="date"
                            />
                            }
                        </Col> 
                         <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datafattening[i]==undefined)?(null):('Tipo de Alimento: '+this.state.datafattening[i].tipo_alimento)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({datafattening:{[i]:{tipo_alimento:e.target.value}} })} }
                              //value={(this.state.datafattening[i]==undefined)?(null):(this.state.datafattening[i].tipo_alimento)}
                              className="datafatteningRowfattening"
                              fattening-id={(this.state.datafatteningTatic[i]==undefined)?('idNone'):(this.state.datafatteningTatic[i].id)}
                              onLoad={loadInfofattening(this.state.datafattening[i],i)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="Tipo de Alimento"
                              id="example-date-input"
                              type="text"
                            />
                            }
                        </Col>
                            {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"fatteningSection"+i} fattening-id={(this.state.datafatteningTatic[i]==undefined)?('idNone'):(this.state.datafatteningTatic[i].id)} onClick={(e) => {restFieldfattening(e.target.id,e.target.getAttribute('fattening-id'))} }>-</Button>
                        </Col>
                        }
                      </Row>

                    }
                return(data)
    }
const loadInfofattening = (result,section) => {
    var content = document.querySelectorAll('.datafatteningRowfattening')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.tipo_alimento==undefined)?(content.value):(result.tipo_alimento)
    }
  }
}
  const saveInfofattening = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datafatteningGetFecha = document.querySelectorAll(".datafatteningRowFecha");
      var datafatteningGetfattening = document.querySelectorAll(".datafatteningRowfattening");
      for (var i = 0; i < datafatteningGetFecha.length; i++) {
        let id = datafatteningGetFecha[i].getAttribute("fattening-id");
        let fattening_fecha = datafatteningGetFecha[i].value
        let fattening_alimento = datafatteningGetfattening[i].value

        dataInfo[i] = {id_fattening:id,fattening_fecha:fattening_fecha,fattening_alimento:fattening_alimento}
      }
       var data_fattening_result = await set_fattening_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_fattening_result=='exito') {
        this.setState({datafatteningTatic:[]})
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldmedicine = () => {
  this.setState({sumFieldmedicine:this.state.sumFieldmedicine+1})
  //this.setState({datamedicine:[this.state.sumFieldmedicine+1]})
  this.setState({datamedicineTatic:[...this.state.datamedicineTatic,{id:'idNone'}]})

}
const restFieldmedicine = async (index,id_medicine) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_medicine!="idNone") {
        var result = await delete_medicine(id_medicine,jsondata._id,this.state.codigo)
    if (result=="exito") {
    this.setState({datamedicineTatic:[]})
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }else{
  //document.querySelector("."+index).remove()
          var globalSelector = jQuery('.SectionSpanMedicine').children().last()
        var selectorEspecific = jQuery("."+index)

        var datamedicineRowFecha = globalSelector.find('.datamedicineRowFecha').val();
        var datamedicineRowmedicine = globalSelector.find('.datamedicineRowmedicine').val();
        var datamedicineRowDosis = globalSelector.find('.datamedicineRowDosis').val();

        selectorEspecific.find('.datamedicineRowFecha').val(datamedicineRowFecha)
        selectorEspecific.find('.datamedicineRowmedicine').val(datamedicineRowmedicine)
        selectorEspecific.find('.datamedicineRowDosis').val(datamedicineRowDosis)

        var dataArray = this.state.datamedicineTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('medicineSection','')) );
        this.setState({datamedicineTatic:newArray})
  }
}
    const medicineContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.datamedicineTatic.length; i++) {
  //document.querySelectorAll(".datamedicineRowVacuna")[i].value = (this.state.datamedicine[i]==undefined)?(null):(this.state.datamedicine[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"medicineSection"+i}>
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datamedicine[i]==undefined)?(null):('Fecha: '+this.state.datamedicine[i].fecha)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                              onChange={(e)=>{this.setState({datamedicine: {[i]:{fecha:e.target.value}} })}}
                              className="datamedicineRowFecha"
                              medicine-id={(this.state.datamedicineTatic[i]==undefined)?('idNone'):(this.state.datamedicineTatic[i].id)}
                              defaultValue={(this.state.datamedicine[i]==undefined)?(completeDate):((this.state.datamedicine[i].fecha==undefined)?(completeDate):(this.state.datamedicine[i].fecha))}
                              id="example-date-input"
                              type="date"
                            />
                            }
                        </Col> 
                         <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datamedicine[i]==undefined)?(null):('Medicamento: '+this.state.datamedicine[i].medicamento)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({datamedicine:{[i]:{medicamento:e.target.value}} })} }
                              //value={(this.state.datamedicine[i]==undefined)?(null):(this.state.datamedicine[i].medicamento)}
                              onLoad={loadInfomedicine(this.state.datamedicine[i],i)}
                              className="datamedicineRowmedicine"
                              medicine-id={(this.state.datamedicineTatic[i]==undefined)?('idNone'):(this.state.datamedicineTatic[i].id)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="Medicamento"
                              id="example-date-input"
                              type="text"
                            />
                            }
                        </Col>
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datamedicine[i]==undefined)?(null):('Dosis: '+this.state.datamedicine[i].dosis)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({datamedicine:{[i]:{dosis:e.target.value}} })} }
                              //value={(this.state.datamedicine[i]==undefined)?(null):(this.state.datamedicine[i].dosis)}
                              className="datamedicineRowDosis"
                              medicine-id={(this.state.datamedicineTatic[i]==undefined)?('idNone'):(this.state.datamedicineTatic[i].id)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="Dosis"
                              id="example-date-input"
                              type="text"
                            />
                            }
                        </Col>
                            {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"medicineSection"+i} medicine-id={(this.state.datamedicineTatic[i]==undefined)?('idNone'):(this.state.datamedicineTatic[i].id)} onClick={(e) => {restFieldmedicine(e.target.id,e.target.getAttribute('medicine-id'))} }>-</Button>
                        </Col>
                        }
                      </Row>

                    }
                return(data)
    }
const loadInfomedicine = (result,section) => {
    var content = document.querySelectorAll('.datamedicineRowmedicine')[section]
    var contentDosis = document.querySelectorAll('.datamedicineRowDosis')[section]
  if (content!=null) {
    if (result!=undefined) {
      content.value=(result.medicamento==undefined)?(content.value):(result.medicamento)
     contentDosis.value=(result.dosis==undefined)?(content.value):(result.dosis)

    }
  }
}
  const saveInfomedicine = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datamedicineGetFecha = document.querySelectorAll(".datamedicineRowFecha");
      var datamedicineGetmedicine = document.querySelectorAll(".datamedicineRowmedicine");
      var datamedicineGetDosis = document.querySelectorAll(".datamedicineRowDosis");
      for (var i = 0; i < datamedicineGetFecha.length; i++) {
        let id = datamedicineGetFecha[i].getAttribute("medicine-id");
        let medicine_fecha = datamedicineGetFecha[i].value
        let medicine_medicine = datamedicineGetmedicine[i].value
        let medicine_dosis = datamedicineGetDosis[i].value

        dataInfo[i] = {id_medicine:id,medicine_fecha:medicine_fecha,medicine_medicine:medicine_medicine,medicine_dosis:medicine_dosis}
      }
       var data_medicine_result = await set_medicine_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_medicine_result=='exito') {
        this.setState({datamedicineTatic:[]})
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldmoreinfo = () => {
  this.setState({sumFieldmoreinfo:this.state.sumFieldmoreinfo+1})
  //this.setState({datamoreinfo:[this.state.sumFieldmoreinfo+1]})
  this.setState({datamoreinfoTatic:[...this.state.datamoreinfoTatic,{id:'idNone'}]})
}
const restFieldmoreinfo = async (index,id_moreinfo) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_moreinfo!="idNone") {
        var result = await delete_moreinfo(id_moreinfo,jsondata._id,this.state.codigo)
    if (result=="exito") {
      this.setState({datamoreinfoTatic:[]})
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }else{
  //document.querySelector("."+index).remove()
          var globalSelector = jQuery('.SectionSpanMoreinfo').children().last()
        var selectorEspecific = jQuery("."+index)

        var datamoreinfoRowFecha = globalSelector.find('.datamoreinfoRowFecha').val();
        var datamoreinfoRowmoreinfo = globalSelector.find('.datamoreinfoRowmoreinfo').val();

        selectorEspecific.find('.datamoreinfoRowFecha').val(datamoreinfoRowFecha)
        selectorEspecific.find('.datamoreinfoRowmoreinfo').val(datamoreinfoRowmoreinfo)

        var dataArray = this.state.datamoreinfoTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('moreinfoSection','')) );
        this.setState({datamoreinfoTatic:newArray})
  }
}
    const moreinfoContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.datamoreinfoTatic.length; i++) {
  //document.querySelectorAll(".datamoreinfoRowVacuna")[i].value = (this.state.datamoreinfo[i]==undefined)?(null):(this.state.datamoreinfo[i].vacuna)
             data[i] = <div className={"moreinfoSection"+i}> 
             <Row style={{marginBottom: '5px'}}>
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datamoreinfo[i]==undefined)?(null):('Fecha: '+this.state.datamoreinfo[i].fecha)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                              onChange={(e)=>{this.setState({datamoreinfo: {[i]:{fecha:e.target.value}} })}}
                              className="datamoreinfoRowFecha"
                              moreinfo-id={(this.state.datamoreinfoTatic[i]==undefined)?('idNone'):(this.state.datamoreinfoTatic[i].id)}
                              defaultValue={(this.state.datamoreinfo[i]==undefined)?(completeDate):((this.state.datamoreinfo[i].fecha==undefined)?(completeDate):(this.state.datamoreinfo[i].fecha))}
                              id="example-date-input"
                              type="date"
                            />
                            }
                        </Col> 
                         <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datamoreinfo[i]==undefined)?(null):('Información: '+this.state.datamoreinfo[i].informacion_adicional)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({datamoreinfo:{[i]:{informacion_adicional:e.target.value}} })} }
                              //value={(this.state.datamoreinfo[i]==undefined)?(null):(this.state.datamoreinfo[i].informacion_adicional)}
                              className="datamoreinfoRowmoreinfo"
                              onLoad={loadInfomoreinfo(this.state.datamoreinfo[i],i)}
                              moreinfo-id={(this.state.datamoreinfoTatic[i]==undefined)?('idNone'):(this.state.datamoreinfoTatic[i].id)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="Información Adicional"
                              id="example-date-input"
                              type="text"
                            />
                            }
                        </Col>
                            {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"moreinfoSection"+i} moreinfo-id={(this.state.datamoreinfoTatic[i]==undefined)?('idNone'):(this.state.datamoreinfoTatic[i].id)} onClick={(e) => {restFieldmoreinfo(e.target.id,e.target.getAttribute('moreinfo-id'))} }>-</Button>
                        </Col>
                        } 
                      </Row>
                      </div>
                    }
                return(data)
    }
const loadInfomoreinfo = (result,section) => {
    var content = document.querySelectorAll('.datamoreinfoRowmoreinfo')[section]
  if (content!=null) {
    if (result!=undefined) {
      content.value=(result.informacion_adicional==undefined)?(content.value):(result.informacion_adicional)

    }
  }
}
  const saveInfomoreinfo = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datamoreinfoGetFecha = document.querySelectorAll(".datamoreinfoRowFecha");
      var datamoreinfoGetmoreinfo = document.querySelectorAll(".datamoreinfoRowmoreinfo");
      for (var i = 0; i < datamoreinfoGetFecha.length; i++) {
        let id = datamoreinfoGetFecha[i].getAttribute("moreinfo-id");
        let moreinfo_fecha = datamoreinfoGetFecha[i].value
        let moreinfo_moreinfo = datamoreinfoGetmoreinfo[i].value

        dataInfo[i] = {id_moreinfo:id,moreinfo_fecha:moreinfo_fecha,moreinfo_moreinfo:moreinfo_moreinfo}
      }
       var data_moreinfo_result = await set_moreinfo_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_moreinfo_result=='exito') {
        this.setState({datamoreinfoTatic:[]})
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldtask = () => {
  this.setState({sumFieldtask:this.state.sumFieldtask+1})
  //this.setState({datatask:[this.state.sumFieldtask+1]})
  this.setState({datataskTatic:[...this.state.datataskTatic,{id:'idNone'}]})
}
const restFieldtask = async (index,id_task) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_task!="idNone") {
        var result = await delete_task(id_task,jsondata._id,this.state.codigo)
    if (result=="exito") {
      this.setState({datataskTatic:[]})

      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  //document.querySelector("."+index).remove()
          var globalSelector = jQuery('.SectionSpanTask').children().last()
        var selectorEspecific = jQuery("."+index)

        var datataskRowFecha = globalSelector.find('.datataskRowFecha').val();
        var datataskRowtime = globalSelector.find('.datataskRowtime').val();

        selectorEspecific.find('.datataskRowFecha').val(datataskRowFecha)
        selectorEspecific.find('.datataskRowtime').val(datataskRowtime)

        var dataArray = this.state.datataskTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('taskSection','')) );
        this.setState({datataskTatic:newArray})
}
    const taskContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.datataskTatic.length; i++) {
  //document.querySelectorAll(".datataskRowVacuna")[i].value = (this.state.datatask[i]==undefined)?(null):(this.state.datatask[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"taskSection"+i}>
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datatask[i]==undefined)?(null):('Fecha: '+this.state.datatask[i].fecha)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                              onChange={(e)=>{this.setState({datatask: {[i]:{fecha:e.target.value}} })}}
                              className="datataskRowFecha"
                              task-id={(this.state.datataskTatic[i]==undefined)?('idNone'):(this.state.datataskTatic[i].id)}
                              defaultValue={(this.state.datatask[i]==undefined)?(completeDate):((this.state.datatask[i].fecha==undefined)?(completeDate):(this.state.datatask[i].fecha))}
                              id="example-date-input"
                              type="date"
                            />
                            }
                        </Col> 
                         <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.datatask[i]==undefined)?(null):('Hora: '+this.state.datatask[i].hora)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({datatask:{[i]:{hora:e.target.value}} })} }
                              //value={(this.state.datatask[i]==undefined)?(null):(this.state.datatask[i].hora)}
                              className="datataskRowtime"
                              task-id={(this.state.datataskTatic[i]==undefined)?('idNone'):(this.state.datataskTatic[i].id)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              onLoad={loadInfotask(this.state.datatask[i],i)}
                              placeholder="task"
                              id="example-date-input"
                              type="time"
                            />
                            } 
                        </Col>
                            {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"taskSection"+i} task-id={(this.state.datataskTatic[i]==undefined)?('idNone'):(this.state.datataskTatic[i].id)} onClick={(e) => {restFieldtask(e.target.id,e.target.getAttribute('task-id'))} }>-</Button>
                        </Col>
                        }
                      </Row>

                    }
                return(data)
    }

const loadInfotask = (result,section) => {
    var content = document.querySelectorAll('.datataskRowtime')[section]
  if (content!=null) {
    if (result!=undefined) {
      content.value=(result.hora==undefined)?(content.value):(result.hora)
    }
  }
}
  const saveInfotask = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datataskGetFecha = document.querySelectorAll(".datataskRowFecha");
      var datataskGetTime = document.querySelectorAll(".datataskRowtime");
      for (var i = 0; i < datataskGetFecha.length; i++) {
        let id = datataskGetFecha[i].getAttribute("task-id");
        let task_fecha = datataskGetFecha[i].value
        let task_time = datataskGetTime[i].value

        dataInfo[i] = {id_task:id,task_fecha:task_fecha,task_time:task_time}
      }
       var data_task_result = await set_task_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_task_result=='exito') {
        this.setState({datataskTatic:[]})
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const taskContentGlobal = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.sumFieldtaskAnimalGlobal; i++) {
  //document.querySelectorAll(".datataskRowVacuna")[i].value = (this.state.datatask[i]==undefined)?(null):(this.state.datatask[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"taskSection"+i}>
                        <Col md={4}>
                              <span>{(this.state.datataskAnimalGlobal[i]==undefined)?(null):('Fecha: '+this.state.datataskAnimalGlobal[i].fecha)}</span>
                        </Col> 
                         <Col md={4}>
                              <span>{(this.state.datataskAnimalGlobal[i]==undefined)?(null):('Hora Inicio: '+this.state.datataskAnimalGlobal[i].hora)}</span>                           
                        </Col>
                         <Col md={4}>
                              <span>{(this.state.datataskAnimalGlobal[i]==undefined)?(null):('Hora Final: '+this.state.datataskAnimalGlobal[i].hora_final)}</span>                           
                        </Col>
                      </Row>

                    }
                return(data)
    }
    const sumFieldarete = () => {
  this.setState({sumFieldarete:this.state.sumFieldarete+1})
  //this.setState({dataarete:[this.state.sumFieldarete+1]})
   this.setState({dataareteTatic:[...this.state.dataareteTatic,{id:'idNone'}]})

}
const restFieldarete = async (index,id_arete) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_arete!="idNone") {
        var result = await delete_arete(id_arete,jsondata._id,this.state.codigo)
    if (result=="exito") {
      this.setState({dataareteTatic:[]})
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }else{
  // document.querySelector("."+index).remove()
          var globalSelector = jQuery('.SectionSpanArete').children().last()
        var selectorEspecific = jQuery("."+index)

        var dataareteRowFecha = globalSelector.find('.dataareteRowFecha').val();
        var dataareteRowarete = globalSelector.find('.dataareteRowarete').val();

        selectorEspecific.find('.dataareteRowFecha').val(dataareteRowFecha)
        selectorEspecific.find('.dataareteRowarete').val(dataareteRowarete)

        var dataArray = this.state.dataareteTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('areteSection','')) );
        this.setState({dataareteTatic:newArray})
  }
}
    const areteContent = () => {
var date = new Date();
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

var data = []
for (var i = 0; i < this.state.dataareteTatic.length; i++) {
  //document.querySelectorAll(".dataareteRowVacuna")[i].value = (this.state.dataarete[i]==undefined)?(null):(this.state.dataarete[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"areteSection"+i}>
                        <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.dataarete[i]==undefined)?(null):(this.state.dataarete[i].fecha)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,border: 0,width: '90px',fontSize:'10px'}}
                              onChange={(e)=>{this.setState({dataarete: {[i]:{fecha:e.target.value}} })}}
                              className="dataareteRowFecha"
                              arete-id={(this.state.dataareteTatic[i]==undefined)?('idNone'):(this.state.dataareteTatic[i].id)}
                              defaultValue={(this.state.dataarete[i]==undefined)?(completeDate):((this.state.dataarete[i].fecha==undefined)?(completeDate):(this.state.dataarete[i].fecha))}
                              id="example-date-input"
                              type="date"
                            />
                          }
                        </Col> 
                         <Col md={3}>
                            {(this.state.confirmEdit==null)
                              ?
                              (<span>{(this.state.dataarete[i]==undefined)?(null):(this.state.dataarete[i].informacion_adicional)}</span>)
                              :
                            <Input
                            autoComplete="none"
                              style={{padding: 0,fontSize:'12px',color: 'black'}}
                              //onChange={(e)=>{this.setState({dataarete:{[i]:{tipo_alimento:e.target.value}} })} }
                              //value={(this.state.dataarete[i]==undefined)?(null):(this.state.dataarete[i].tipo_alimento)}
                              className="dataareteRowarete"
                              arete-id={(this.state.dataareteTatic[i]==undefined)?('idNone'):(this.state.dataareteTatic[i].id)}
                              onLoad={loadInfoarete(this.state.dataarete[i],i)}
                              //defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                              placeholder="Información y nuevo Nro de Arete"
                              id="example-date-input"
                              type="text"
                            />
                            }
                        </Col>
                           {(this.state.confirmEdit==null)
                              ?
                              (null)
                              :
                        <Col md={3}>
                          <Button style={style.titleBtnPlus} id={"areteSection"+i} arete-id={(this.state.dataareteTatic[i]==undefined)?('idNone'):(this.state.dataareteTatic[i].id)} onClick={(e) => {restFieldarete(e.target.id,e.target.getAttribute('arete-id'))} }>-</Button>
                        </Col>
                        }
                      </Row>

                    }
                return(data)
    }
const loadInfoarete = (result,section) => {
    var content = document.querySelectorAll('.dataareteRowarete')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.informacion_adicional==undefined)?(content.value):(result.informacion_adicional)
    }
  }
}
  const saveInfoarete = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var dataareteGetFecha = document.querySelectorAll(".dataareteRowFecha");
      var dataareteGetarete = document.querySelectorAll(".dataareteRowarete");
      for (var i = 0; i < dataareteGetFecha.length; i++) {
        let id = dataareteGetFecha[i].getAttribute("arete-id");
        let arete_fecha = dataareteGetFecha[i].value
        let arete_info = dataareteGetarete[i].value

        dataInfo[i] = {id_arete:id,arete_fecha:arete_fecha,arete_info:arete_info}
      }
       var data_arete_result = await set_arete_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_arete_result=='exito') {
        this.setState({dataareteTatic:[]})
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
// const deleteAnimal = async (id) => {
//     if (window.confirm("Deseas eliminar este registro?")) {
//     var dataReturn = await delete_traza_animal(id)
//             if (dataReturn=="exito") {
//             notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
//             this.props.history.push('/admin/meats/add-animal?code='+this.state.codigo)
//           }else{
//             notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
//           }
//   }
// }
  const deleteAnimal = async (selectMotive,fecha_muerte,motivo,lugar_siniestro) => {
    if (motivo=="") {
      notify("danger","¡Fallo de eliminación!", 'Por favor escriba un motivo.');
    }else{
      var selectMotiveChange;
      if (selectMotive=="2") {
        selectMotiveChange = selectMotive
      }else{
        selectMotiveChange =lugar_siniestro
      }
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("code");
    var gerParamLote = new URLSearchParams(this.props.location.search).get("lote");
    var result = this.state.data_traza_inventory.filter(val => val.lote_numero==gerParamLote)
    if (result[0]==undefined) {
      var cantidad_animales = []
          }else{
      var cantidad_animales = String(result[0].animal_register_id).split('*')
    }
       if (window.confirm("Deseas eliminar este registro?")) {
        var dataReturn = await delete_traza_animal(this.state.idDeleteAnimal,fecha_muerte,motivo,selectMotiveChange,cantidad_animales.length,jsondata._id,gerParam)
               if (dataReturn=="exito") {
                this.setState({
                  openModal:false,
                })

               notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
               //this.initData()
               this.props.history.push('/admin/meats/eliminate-animal?code='+this.state.codigo+'&lote='+gerParamLote)
             }else{
               notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
             }
     }
    }

}
  const funcOpenModal = (chan,idAnimal) =>{
    this.setState({
      openModal:chan,
      idDeleteAnimal:idAnimal
    })
  }
      const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  const uploadFile = async (file) => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var data = await upload_file_img(file,this.state.animal_id,jsondata._id)
      if (data=="exito") {
          notify("success", "¡Registrado exitosamente!", 'Se guardo la imagen.');
          this.dataInitSet()
      }
  }
const saveInfoGeneral = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({mess:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.location.search).get("code");
        const var_request = await create_traza_animal_edit(this.state,jsondata._id,gerParam);

        if (var_request.data=="exito") {
          this.setState({btnd:false})
          this.setState({mess:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          this.dataInitSet()
        }else{
          notify("danger", "¡Registrado Fallido!", var_request.data);
          this.setState({mess:''})
          this.setState({btnd:false})
        }
  }
    return(<>
      <LoteModalEliminate sendOpenModal={this.state.openModal} openModalFunc={funcOpenModal} deleteAnimalExtend={deleteAnimal}/>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
    <MeatsNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Animal Individual" parentName="Animal Individual" />
        <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="11">
                <h3 className="mb-0">Ingresar o Editar Datos por Animal Individual</h3>
              </Col>
            <a href="javascript:;" onClick={()=>{this.props.history.goBack()}}>
              <Col md="1" style={{fontSize: '30px',fontWeight: '500'}}>X</Col>
            </a>
            </Row>
          </CardHeader>
          <Row>
            <Col md={12}>
                <Col md={12} onClick={()=>{jQuery("#uploadFile").click()}}>
                {(this.state.img_animal==null)?('Subir Imagen'):((this.state.img_animal=='null')?('Subir imagen'):(
                  <img src={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.img_animal} />
                  ))}
                  <input
                  autoComplete="none" type="file" id="uploadFile" style={{display: 'none'}} accept="image/png, image/jpeg" onChange={(e)=>{uploadFile(e.target.files[0])}} />
                </Col>
                <Col md={12} className="text-center">
                      <span style={{color:"#32325d",fontWeight: '700',fontSize: '12px'}}>{(this.state.dataareteTatic[0]==undefined)?(null):('Nuevo arete registrado: '+this.state.dataareteTatic[this.state.dataareteTatic.length-1].informacion_adicional+'. Arete original: '+this.state.animal_arete_id)}</span>
                </Col>
                      <Col md="12">

                      <Button style={style.titleBtnPlus} onClick={() => {funcOpenModal(true,this.state.animal_id)}}>Eliminar Animal</Button>
                      </Col>
                <Col md={12}>
                <Row>
                    <Col md={6}>
                      {(this.state.confirmEdit == null)
                        ?
                        (<>
                      <span style={style.titleOne}>Foodchain ID: {this.state.codigo_traza}</span><br />
                      <span style={style.titleOne}>Animal ID: {this.state.animal_register_id}</span><br />
                      <span style={style.title}>Lote Nro: {this.state.type_lote}</span><br />
                      <span style={style.titleTwo}>Fecha de Registro: {this.state.fecha_registro}</span><br />
                      <span style={style.titleTwo}>Hora de Registro: {this.state.hora_registro}</span><br />
                      <span style={style.titleTwo}>Arete de la Madre: {this.state.madre_id}</span><br />
                      <span style={style.titleTwo}>Arete del Animal: {this.state.animal_arete_id}</span><br />
                      <span style={style.titleTwo}>Fecha de Nacimiento: {this.state.fecha_nacimiento}</span><br />
                      <span style={style.titleTwo}>Raza: {this.state.raza}</span><br />
                      <span style={style.titleTwo}>Edad: {this.state.edad+' '+this.state.unidadedad}</span><br />
                      <span style={style.titleTwo}>Sexo: {this.state.sexo}</span><br />
                          </>)
                        :
                        <>
                      <Col md="12">
                      <span style={style.titleOne}>Nombre de la trazabilidad actual: {this.state.nombre_traza}</span><br />
                      <span style={style.titleOne}>Foodchain ID: {this.state.codigo_traza}</span><br />
                      <span style={style.titleOne}>Animal ID: {this.state.animal_register_id}</span><br />
                      <span style={style.title}>Lote Nro: {this.state.type_lote}</span><br />
                       <span style={style.titleTwo}>Fecha de Registro: {this.state.fecha_registro}</span>
                      </Col>

                      <Col md="12">
                       <span style={style.titleTwo}>Hora de Registro: {this.state.hora_registro}</span>
                      </Col>

                      <Col md="12">
                       <span style={style.titleTwo}>Arete de la Madre: </span>
                       <input
                       autoComplete="none" style={style.inputStyle} value={this.state.madre_id} onChange={(e) => this.setState({madre_id:e.target.value})} type="text" />
                      </Col>

                      <Col md="12">
                       <span style={style.titleTwo}>Arete del Animal: {this.state.animal_arete_id}</span>
                      </Col>

                      <Col md="12">
                      <div style={{display:"flex"}}>
                         <span style={style.titleTwoOther}>Fecha de Nacimiento: </span>
                         <input
                         autoComplete="none" style={{border: 0,padding: 0,margin: 0,color: "#8e8e8e",fontWeight: '700', fontSize: '12px',width: 100}} value={this.state.fecha_nacimiento} onChange={(e) => this.setState({fecha_nacimiento:e.target.value})} type="date" />
                      </div>
                      </Col>
                      
                      <Col md="12">
                       <span style={style.titleTwo}>Raza: </span>
                       <input
                       autoComplete="none" style={style.inputStyle} value={this.state.raza} onChange={(e) => this.setState({raza:e.target.value})} type="text" />
                      </Col>
                      
                      <Col md="12">
                       <span style={style.titleTwo}>Edad: </span>
                       <input
                       autoComplete="none" style={style.inputStyle} value={this.state.edad+' '+this.state.unidadedad} onChange={(e) => this.setState({edad:e.target.value})} type="text" />
                      </Col>

                      <Col md="12">
                      <div style={{display:"flex"}}>
                       <span style={style.titleTwo}>Sexo: </span>
                        <select style={{border: 0,padding: 0,margin: 0,color: "#8e8e8e",fontWeight: '700', fontSize: '12px',width: '45px'}} value={this.state.sexo} onChange={(e) => this.setState({sexo: e.target.value})}>
                          <option value="0">Seleccione...</option>
                          <option value="F">F</option>
                          <option value="M">M</option>
                        </select>
                      </div>
                      </Col>
                        {this.state.mess ? (
                          <div className="text-muted font-italic">
                            <small>
                              <span className="text-red font-weight-700">
                                {this.state.mess}
                              </span>
                            </small>
                          </div>
                        ) : null}
                      <Col md="12">
                        <Button disabled={this.state.btnd} style={style.titleBtnPlus} onClick={() => {saveInfoGeneral()}}>Actualizar Información</Button>
                      </Col>
                        
                        </>
                      }
                    </Col>
                    <Col md={6}>
                      <span style={style.title}>Ingreso a Compartimento: {this.state.compartiment}</span><br /><br />
                      <span style={style.title}>Vacunas:</span><br />
                      <span className="SectionSpanVacuna" style={style.titleTwo}>{vacunasContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldVacuna()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfoVacuna()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Peso:</span><br />
                      <span style={style.titleTwo}>Peso inicial ingresado: {(this.state.datapesoTatic[0]==undefined)?("Sin peso"):(this.state.datapesoTatic[0].agregar_peso+' '+this.state.datapesoTatic[0].unidad_peso)}</span><br />
                      <span className="SectionSpanPeso" style={style.titleTwo}>{pesoContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldpeso()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfopeso()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Medicamento:</span><br />
                      <span className="SectionSpanMedicine" style={style.titleTwo}>{medicineContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldmedicine()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfomedicine()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Prueba de Aftosa:</span><br />
                      <span className="SectionSpanAftosa" style={style.titleTwo}>{aftosaContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldaftosa()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfoaftosa()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Faena:</span><br />
                      <span className="SectionSpanTask" style={style.titleTwo}>{taskContentGlobal()}</span>
                      <span style={style.titleTwo}>{taskContent()}</span>
                      {/*{(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldtask()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfotask()}}>Guardar</Button>
                        </>
                      }*/}
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Información:</span><br />
                      <span className="SectionSpanMoreinfo" style={style.titleTwo}>{moreinfoContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldmoreinfo()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfomoreinfo()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Engorde:</span><br />
                      <span className="SectionSpanFattening" style={style.titleTwo}>{fatteningContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldfattening()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfofattening()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Registrar nuevo arete por perdida del anterior:</span><br />
                      <span className="SectionSpanArete" style={style.titleTwo}>{areteContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldarete()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfoarete()}}>Guardar</Button>
                        </>
                      }
                    </Col>
{/*                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Peso en Carne:</span><br />
                      <span style={style.titleTwo}>Fecha: - info:</span><br />
                    </Col>*/}
                  </Row>
                </Col>
            </Col>
          </Row><br />
          <Col className="text-right pb-4" md="11">
            <Button style={style.titleBtnPlus} onClick={() => {this.props.history.goBack()}}>Volver</Button>
          </Col>
{/*              <Col md="12" className="text-center" style={{marginBottom: "20px"}}>
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfo}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar Información</span>
                </Button>
                </Col>*/}
        </Card>
      </Container>
     </> )
  }
}
export class LoteModalEliminate extends React.Component {
  state={
    fecha_muerte:'',
    motivo:'',
    lugar_siniestro:'',
    selectMotive:'2',
  }
  componentDidMount(){
      var date = new Date();
                  var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})

        this.setState({
          fecha_muerte:completeDate
        })
  }
  render(){
    const onchangeMotive = (value) => {
      this.setState({selectMotive:value})
    }
    const functionDelete = () => {
      this.props.deleteAnimalExtend(this.state.selectMotive,this.state.fecha_muerte,this.state.motivo,this.state.lugar_siniestro)
    }
    return(
      <Modal isOpen={this.props.sendOpenModal} size="xl">
        <ModalBody>
          <Card>
          <CardHeader className="border-0">
            <Row>
              <Col md="12">
                <h3 className="mb-0">Eliminar Animal</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <Row>
                  <Col md="12" style={{marginBottom: '10px'}}>
                  <label>Motivo:</label><br />
                  <Col md="6">
                    <span style={{marginRight: '30px'}}>Por muerte</span><Input
                    autoComplete="none" type="radio" className="selectmotivo" name="selectmotivo" onClick={(e)=>{onchangeMotive(e.target.value)}} value="1" />
                  </Col>
                    <Col md="6">
                    <span style={{marginRight: '30px'}}>Otro Motivo</span><Input
                    autoComplete="none" type="radio" className="selectmotivo" name="selectmotivo" onClick={(e)=>{onchangeMotive(e.target.value)}} value="2" />
                  </Col>
                  </Col>
                    <Col md="4">
                                    <label>Fecha de eliminación:</label><br />
                                    <Input
                                    autoComplete="none"
                                      defaultValue={this.state.fecha_muerte}
                                      onChange={(e)=>{this.setState({fecha_muerte:e.target.value})}}
                                      type="date"
                                    />
                                    </Col>
                  <Col md="4">
                  <label>Motivo:</label><br />
                  <Input
                  autoComplete="none" onChange={(e)=>{this.setState({motivo:e.target.value})}} id="example-date-input" type="textarea" />
                  </Col>
                  {(this.state.selectMotive=='2')?(null):
                  <Col md="4">
                  <label>Lugar de siniestro:</label><br />
                    <Input
                    autoComplete="none" onChange={(e)=>{this.setState({lugar_siniestro:e.target.value})}} id="example-date-input" type="textarea" />
                  </Col>}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <ModalFooter>
          <Button onClick={()=> {functionDelete(false)} }>
            Guardar
          </Button>
          <Button onClick={()=> {this.props.openModalFunc(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
        </ModalBody>
        </Modal>
    )
  }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    // var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    //var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    if (dataPermission.type_traza=='VF' || dataPermission.type_traza==null) {
      this.setState({get_permission:false})
      return;
    }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.lote_m==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }   
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <DetailsAnimal {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
const style = {
  titleOne:{
     color: "#204D74",
     fontWeight: '700',
     fontSize: '12px'
  },
  title:{
     color: "#9d0d0d",
     fontWeight: '700',
     fontSize: '12px'
  },
  titleBtnPlus:{
    boxShadow:'none'
  },
  titleTwo:{
     color: "#8e8e8e",
     fontWeight: '700',
     fontSize: '12px'
  },
  titleTwoOther:{
     color: "#8e8e8e",
     fontWeight: '700',
     fontSize: '12px',
     width:140
  },
  inputStyle:{border: 0,padding: 0,margin: 0,width: '50%',color: "#8e8e8e",fontWeight: '700', fontSize: '12px'}
}
export default SwiftComponent;
