import React, {useState} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter
} from "reactstrap";
import jQuery from 'jquery';
import { data_obv_mvr, save_report_mrv, get_permission, save_signature_mrv } from "network/ApiAxios";
import {UserContext} from "layouts/store";
import SignatureCanvas from 'react-signature-canvas';

export default class ModalObservation extends React.Component {
  state={
    register_id:'none',
    monitor_type:'',
    monitor_type_name:'',
    entidad:'',
    name_mvr:'',
    monitor_number:'',
    monitor_date:'',
    init_time:'',
    monitor_last_date:'',
    last_time:'',
    activity:{},
    activity_table:'',
    monitor_result:'',
    state_status:'',
    motive:'',
    priority:'',
    observation:'',
    recommendation:'',
    signature:'',
    nro_autorization:'',
    areawork:'',
    data_activity:[],
    select_dsbl:false,
    putContent:[],
    activity_selected:[],
    putContentObRe:[],
    collapse:{},
    global_data:[],
    date_init_state:'',
    time_init_state:'',
    get_data_user:null,
    sendOpenModal:false,
    sigDrawn:false,
    asigSigDrawn:false
  }
  async componentDidMount(){
      //this.setState({data_activity: activityGlobal})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
          this.setState({date_init_state:completeDate,time_init_state:timeString})
      this.putData(this.props.thisStates.form_prod,this.props.thisStates.code_prod,this.props.thisStates.db_prod)
      jQuery('.select-search__select').css('height','0')
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var get_data_user = await get_permission(jsondata._id)
      if (get_data_user[0].asigned_signature==1) {
        this.setState({asigSigDrawn:true})
        if (get_data_user[0].draw_digital_signature==null) {
          this.setState({sigDrawn:true})
        }
      }else{
        this.setState({asigSigDrawn:false})
      }
      this.setState({get_data_user:get_data_user[0]})
  }
  
  componentDidUpdate(prevPro,prevState){
    //console.log(this.props.thisStates.put_table)
    if (prevPro.thisStates.put_table!=this.props.thisStates.put_table) {
      //console.log(this.props.thisStates.put_table)
      this.content_activity_hide()
    }
    if (this.state.activity_selected.length!=prevState.activity_selected.length) {
      jQuery(".score_row").sort(this.sortDivs).appendTo(jQuery(".rowViewNumber"));
    }
  }
putData = async (form,code,db) =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var get_data_obv = await data_obv_mvr('general',code,db,jsondata.type_traza)
    if (get_data_obv.data==null) {
      this.dataClean()
    }else{
      var getName = null
      if (get_data_obv.data.monitor_type=="ON") {
        getName='Monitoreo'
      }
      if (get_data_obv.data.monitor_type=="RE") {
        getName='Revisión'
      }
      if (get_data_obv.data.monitor_type=="VA") {
        getName='Validación'
      }
     this.setState({ 
       register_id:get_data_obv.data.id,
       monitor_type_name:getName,
       monitor_type:get_data_obv.data.monitor_type,
       entidad:get_data_obv.data.entidad,
       name_mvr:get_data_obv.data.name_mvr,
       monitor_number:get_data_obv.data.monitor_number,
       monitor_date:get_data_obv.data.monitor_date,
       init_time:get_data_obv.data.init_time,
       monitor_last_date:get_data_obv.data.monitor_last_date,
       last_time:get_data_obv.data.last_time,
       activity:get_data_obv.data.activity,
       monitor_result:get_data_obv.data.monitor_result,
       state_status:get_data_obv.data.state,
       motive:get_data_obv.data.motive,
       priority:get_data_obv.data.priority,
       observation:get_data_obv.data.observation,
       recommendation:get_data_obv.data.recommendation,
       signature:get_data_obv.data.signature,
       nro_autorization:get_data_obv.data.nro_autorization,
      })
     if (JSON.parse(get_data_obv.data.data_adjunt)!=null) {
      var status_data = []
      JSON.parse(get_data_obv.data.data_adjunt).map((a,b)=>{
      var get_data_pr = []
        this.setState({activity:{...this.state.activity, [a.value.split('*')[0]]:true}})
           this.setContentVal(a,true)
            Object.entries(a.datastate).map((val,ind)=>{
              // if (val[0]=='init_date') {
              //   val[1]={[0]:this.state.date_init_state}
              // }
              // if (val[0]=='init_time_change') {
              //   val[1]={[0]:this.state.time_init_state}
              // }
              // console.log(val[1])
            this.setGlobalValuesRegister(val[1],val[0],a.value.split('*')[0],b,ind)
            get_data_pr[ind] = Object.keys(val[1]).length
           })
            var get_numb_max = Math.max(...get_data_pr)
            for (var i = 0; i < get_numb_max; i++) {
              this.setContentObRe(b,a.value.split('*')[0]+b,'none')
            }
      })
     }
    }
    this.content_activity_hide()
}
content_activity_hide = ()=>{
          var get_val_sel = []
        jQuery('.score_row').each(function(b,a){
          var get_selector = jQuery(a).find('.state_status_select')
          if (get_selector.length!=0) {
            get_selector.map((bTwo,aTwo)=>{
              if (jQuery(aTwo).val()!='Realizado Conforme') {
                get_val_sel[b] = jQuery(aTwo).val()
              }
            })
              if (get_val_sel[b]==undefined) {
                // if (window.confirm('Deseas dar por finalizado esta actividad?, al finalizar y guardar toda actividad de una tarea se desaparecerá y se visualizará en el historial.')) {
                // }
                    jQuery(a).hide()
              }
          }
          //jQuery(a).val()
        })
}
    setGlobalValuesRegister = (value,note_select,activity,indx,second_idx) =>{
      var next_data = {}
      var construc = { [activity]:{[indx]:{[note_select]:value }} }
      if (this.state.global_data[activity]==undefined) {
        this.setState({global_data: {...this.state.global_data, ...construc} })
        next_data = construc
      }else{
        var diffArr = {}
        if (this.state.global_data[activity][indx]==undefined) {
          diffArr = {}
        }else{
          diffArr = value
        }
        next_data = {[activity]: {...this.state.global_data[activity], [indx]:{...this.state.global_data[activity][indx], [note_select]:diffArr } } }
        this.setState({global_data: {...this.state.global_data,...next_data} })
      }

        let items = [...this.state.activity_selected];
        let item = {...items[indx]};
        item.datastate = next_data[activity][indx];
        items[indx] = item;
        this.setState({activity_selected:items});
    }
dataClean = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var dataPermission = this.props.thisStates.data_user
    var date = new Date()
    var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
    var completeDateBefore = completeDateAfter.split('/')
    var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
    var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var getName = null
      if (jsondata.type_traza=="ON") {
        getName='Monitoreo'
      }
      if (jsondata.type_traza=="RE") {
        getName='Revisión'
      }
      if (jsondata.type_traza=="VA") {
        getName='Validación'
      }
    this.setState({
      register_id:'none',
      monitor_type:jsondata.type_traza,
      monitor_type_name:getName,
      entidad:dataPermission.empresa,
      name_mvr:dataPermission.name,
      monitor_number:'',
      monitor_date:completeDate,
      init_time:timeString,
      monitor_last_date:completeDate,
      last_time:timeString,
      activity:[],
      monitor_result:'',
      state_status:'',
      motive:'',
      priority:'',
      observation:'',
      activity_table:'',
      recommendation:'',
      signature:'',
      nro_autorization:'',
     })
}
   toggleFunctionOb = (index,res,code) =>{
    if (res==undefined) {
    this.setState({collapse:{[code]:true}})
    }else{
      if (this.state.collapse[code]==true) {
        this.setState({collapse:{[code]:false}})
      }else{
        this.setState({collapse:{[code]:true}})
      }
    }
  }
   changeAreaWork = (val) => {
      var data = ''
      if (val.checked) {
        data = this.state.areawork+val.value
      }else{
        data = this.state.areawork.replace(val.value,'')
      }
      this.setState({areawork:data})
    }
    addActivity = () =>{
        if (jQuery('.select-search__select').attr('style')==undefined) {
         jQuery('.select-search__select').css('height','0')
        }else{
         jQuery('.select-search__select').removeAttr('style')
        }
    }
    setContentVal= async (data,type,clk) =>{
      window.scrollTo(0, document.body.scrollHeight);
      if (type) {
        this.setState({putContentObRe: {...this.state.putContentObRe, [this.state.activity_selected.length]:[]} })
        //console.log()
        var put_data_g = {}
        if (this.state.global_data.general==undefined) {
          put_data_g = {...this.state.global_data, general:{[this.state.activity_selected.length]:data.datastate}}
        }else{
          var data_g = this.state.global_data
          data_g.general={...data_g.general,[this.state.activity_selected.length]:data.datastate}
          put_data_g = data_g
        }
          this.setState({global_data: put_data_g })
          this.setState({activity_selected: [...this.state.activity_selected,data] })
      }else{
        if (window.confirm('Estas seguro de desmarcar esta actividad? se perderan los datos.')) {
          var get_data_update = this.state.activity_selected.filter((a,b)=>a.value!=data.value)
          this.setState({activity_selected: get_data_update })
        }else{ return }
      }
      if (clk) {
        if (this.state.activity==null) {
          this.setState({activity:{...this.state.activity, [data.value.split('*')[0]]:true }})
        }else{
          this.setState({activity:{...this.state.activity, [data.value.split('*')[0]]:!this.state.activity[data.value.split('*')[0]] }})
        }
        var counter_activity = await this.state.activity_selected.length
        //jQuery('.add_info_'+counter_activity).click()
         this.setContentObRe(counter_activity,'general'+counter_activity,'general')
      }
      // jQuery('.rowViewNumber').each(function(){
      //     var this_var = jQuery(this);
      //     this_var.append(this_var.find('.score_row').get().sort(function(a, b) {
      //         return jQuery(a).data('index') - jQuery(b).data('index');
      //     }));
      // });
    }
      sortDivs = (a,b) => {
          //console.log(jQuery(a).data("data-index"),jQuery(a).data("index"))
          return jQuery(a).data("index") < jQuery(b).data("index") ? 1 : -1;  
      };


    setGlobalValues = (value,note_select,activity,indx,second_idx) =>{
      var next_data = {}
      var construc = { [activity]:{[indx]:{[note_select]:{[second_idx]:value}}} }
      //console.log(this.state.global_data)
      if (this.state.global_data[activity]==undefined) {
        this.setState({global_data: {...this.state.global_data, ...construc} })
        next_data = construc
      }else{
        var diffArr = {}
        if (this.state.global_data[activity][indx]==undefined) {
          diffArr = {}
        }else{
          diffArr = {...this.state.global_data[activity][indx][note_select], [second_idx]:value}
        }
        next_data = {[activity]: {...this.state.global_data[activity], [indx]:{...this.state.global_data[activity][indx], [note_select]:diffArr,['init_date']:{[0]:this.state.date_init_state},['init_time_change']:{[0]:this.state.time_init_state} } } }
        this.setState({global_data: {...this.state.global_data,...next_data} })
      }
        let items = [...this.state.activity_selected];
        let item = {...items[indx]};
        item.datastate = next_data[activity][indx];
        items[indx] = item;
        this.setState({activity_selected:items});
        if (note_select=='state_status') {
          if (value=='Realizado Conforme') {
            this.setState({sendOpenModal:true,indx_one:indx,indx_two:second_idx})
          }
        }
        //sendOpenModal
    }
    deleteTask = (indx,data)=>{
      if (window.confirm('Estas seguro en eliminar esta información?')) {
       var select_erase = this.state.activity_selected.filter((a,b)=>b!=indx)
       var get_data = {}
       select_erase.map((sa,si)=>{
        get_data[si] = sa.datastate
       })
       this.setState({global_data: {general:get_data} })
       this.setState({activity_selected:select_erase})
       var getdata = Object.values(this.state.putContentObRe).filter((val,ind)=>ind!=indx)
       this.setState({putContentObRe: {...getdata}})
       this.functionSave(select_erase)
      }
    }
    setContentObRe = async(twoIdx,indx,typeact) =>{
       if (typeact!='none') {
      
          //await this.setGlobalValues(this.props.thisStates.put_title,'activity',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues(this.state.date_init_state,'init_date',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues(this.state.time_init_state,'init_time_change',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues('','observation',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues('','recommendation',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues('Sin prioridad-gray','priority',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues('none','signature_draw',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues('none','signature_digital',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
          await this.setGlobalValues('Por Hacer','state_status',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
         //this.setGlobalValues(this.props.thisStates.put_table,'activity_table',typeact,twoIdx,this.state.putContentObRe[twoIdx].length)
       }
        let items = this.state.putContentObRe;
        if (items.length!=0) {        
          let item = [...items[twoIdx]];
          item[this.state.putContentObRe[twoIdx].length] = {[twoIdx]:this.state.putContentObRe[twoIdx].length};
          items[twoIdx] = item;
          this.setState({putContentObRe:items});
          var getLenghtRe = this.state.putContentObRe[twoIdx].length-1
        }
      if (typeact!='none') {
        this.setState({collapse:{[getLenghtRe+indx]:true}})
      }
    }
    deleteInformation=(firs,sec,tir)=>{
      if (window.confirm('Estas seguro en eliminar esta observación?')) {
      var get_data = {}
      Object.values(this.state.putContentObRe).map((a,b)=>{
        var select_erase = []
        if (b==firs) {
          select_erase = a.filter((val,ind)=>ind!=tir)
        }else{
          select_erase = a
        }
        get_data[b] = select_erase
      })
       this.setState({putContentObRe:get_data})

       var get_data_general_glo = {}
       Object.entries(this.state.activity_selected[firs]['datastate']).map((a,b)=>{
        var get_data_general = {}
        if (a[0]!="activity") {
           get_data_general = {...Object.values(a[1]).filter((valg,indg)=>indg!=tir)}
        }else{
          get_data_general = a[1]
        }
        get_data_general_glo[a[0]]=get_data_general
       })
       var itemGlo = this.state.global_data
       var itemTwoGlo = itemGlo.general
       itemTwoGlo[firs] = get_data_general_glo

        let items = [...this.state.activity_selected];
        let item = {...items[firs]};
        item.datastate = get_data_general_glo;
        items[firs] = item;
        this.setState({activity_selected:items});
       this.functionSave(items)
      }
    }
    putContentObRe = (incx,twoIndx,typeact) =>{
      var content = []
      var counter = 0
      if (this.state.putContentObRe[incx]!=undefined) {
      this.state.putContentObRe[incx].map((a,b)=>{
        counter++
        content[b] =<>
            <div className={"col-md-12 contentGeneral"+twoIndx}>
              <Row>
                <Col md={11}>
                      <button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.toggleFunctionOb(b,this.state.collapse[b+twoIndx],b+twoIndx)} }>
                        Observación: {b+1} - {(this.state.activity_selected[incx]['datastate']['state_status']==undefined)?(''):(
                            (this.state.activity_selected[incx]['datastate']['state_status'][b]=='Revisar')?(
                              <span style={{color:'orange'}}>
                                {this.state.activity_selected[incx]['datastate']['state_status'][b]}
                              </span>
                              ):(this.state.activity_selected[incx]['datastate']['state_status'][b])
                          )} - <i className="fas fa-circle" style={{color:(this.state.activity_selected[incx]['datastate']['priority']==undefined)?('gray'):((this.state.activity_selected[incx]['datastate']['priority'][b]==undefined)?('gray'):(this.state.activity_selected[incx]['datastate']['priority'][b].split('-')[1]))}}></i> - <i className="fas fa-chevron-down"></i>
                      </button>
                </Col>
                <Col md={1}>
                    <button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.deleteInformation(incx,twoIndx+b,b)}}>
                      <i className="fas fa-eraser"></i>
                    </button>
                </Col>
              </Row>
                  <Collapse isOpen={this.state.collapse[b+twoIndx]}>
                    <div className="row">
                                    <Col md="6">
                                      <label className="form-control-label">Observaciones:</label><br />
                                      <Input
                                        //value={this.state.observation['observation_'+twoIndx+'_'+b]}
                                        value={(this.state.activity_selected[incx]['datastate']['observation']==undefined)?(''):(this.state.activity_selected[incx]['datastate']['observation'][b])}
                                        onChange={(e)=>{
                                          this.setGlobalValues(e.target.value,'observation',typeact,incx,b)
                                          //this.setState({observation:{...this.state.observation, ['observation_'+twoIndx+'_'+b]:e.target.value}})
                                        }}
                                        type="textarea"
                                        className="input_observation"
                                      />
                                    </Col>
                                    <Col md="6">
                                      <label className="form-control-label">Recomendación:</label><br />
                                      <Input
                                        value={(this.state.activity_selected[incx]['datastate']['recommendation']==undefined)?(''):(this.state.activity_selected[incx]['datastate']['recommendation'][b])}
                                        onChange={(e)=>{
                                          this.setGlobalValues(e.target.value,'recommendation',typeact,incx,b)
                                        }}
                                        // defaultValue={this.state.recommendation['recommendation_'+twoIndx+'_'+b]}
                                        // onChange={(e)=>{this.setState({recommendation:{...this.state.recommendation, ['recommendation_'+twoIndx+'_'+b]:e.target.value}})}}
                                        type="textarea"
                                      />
                                    </Col>
                                  <Col md="4">
                                  <label className="form-control-label">Estado de Cumplimiento:</label><br />
                                      <Input
                                        className="state_status_select input_state_status"
                                        data-index_two={incx}
                                        type="select"
                                        value={(this.state.activity_selected[incx]['datastate']['state_status']==undefined)?(''):(this.state.activity_selected[incx]['datastate']['state_status'][b])}
                                        onChange={(e)=>{
                                          this.setGlobalValues(e.target.value,'state_status',typeact,incx,b)
                                        }}
                                      // value={this.state.state_status['state_status_'+twoIndx+'_'+b]}
                                      // onChange={(e)=>{this.setState({state_status:{...this.state.state_status, ['state_status_'+twoIndx+'_'+b]:e.target.value}})}}
                                      >
                                        <option value="0">Seleccione</option>
                                        <option value="Por Hacer">Por Hacer</option>
                                        <option value="Revisar">Revisar</option>
                                        <option value="No Conforme">No Conforme</option>
                                        <option value="Realizado Conforme">Realizado Conforme (Al finalizar y guardar todas las actividades de una tarea se desaparecerá y se visualizará en el historial.)</option>
                                      </Input>
                                  </Col>
                                    {(this.state.activity_selected[incx]['datastate']['state_status']==undefined)?(null):((this.state.activity_selected[incx]['datastate']['state_status'][b]=='No Conforme')?(
                                      <Col md="4">
                                        <label className="form-control-label">Motivo:</label><br />
                                        <Input
                                          value={(this.state.activity_selected[incx]['datastate']['motive']==undefined)?(''):(this.state.activity_selected[incx]['datastate']['motive'][b])}
                                          onChange={(e)=>{
                                            this.setGlobalValues(e.target.value,'motive',typeact,incx,b)
                                          }}
                                          type="textarea"
                                        />
                                      </Col>
                                      ):(null))}
                                    <Col md="4">
                                    <label className="form-control-label">Prioridad:</label><br />
                                        <Input 
                                        type="select" 
                                        // value={this.state.activity_selected[incx]['datastate']['priority'][b]}
                                        // onChange={(e)=>{this.setState({priority:{...this.state.priority, ['priority_'+twoIndx+'_'+b]:e.target.value}})}}
                                        value={(this.state.activity_selected[incx]['datastate']['priority']==undefined)?(''):(this.state.activity_selected[incx]['datastate']['priority'][b])}
                                        onChange={(e)=>{
                                          this.setGlobalValues(e.target.value,'priority',typeact,incx,b)
                                        }}
                                        className="input_priority"
                                        >
                                          <option value="0">Seleccione</option>
                                          <option value="Muy Importante-red">Urgente</option>
                                          <option value="Importante-orange">Importante</option>
                                          <option value="No Tan Importante-yellow">No Tan Importante</option>
                                        </Input>
                                    </Col>
                                    <Col md="4">
                                    <label className="form-control-label">Observación realizada:</label><br />
                                        <Input 
                                        type="select" 
                                        // value={this.state.activity_selected[incx]['datastate']['priority'][b]}
                                        // onChange={(e)=>{this.setState({priority:{...this.state.priority, ['priority_'+twoIndx+'_'+b]:e.target.value}})}}
                                        value={(this.state.activity_selected[incx]['datastate']['observation_relizate']==undefined)?(''):(this.state.activity_selected[incx]['datastate']['observation_relizate'][b])}
                                        onChange={(e)=>{
                                          this.setGlobalValues(e.target.value,'observation_relizate',typeact,incx,b)
                                        }}
                                        className="input_observation_relizate"
                                        >
                                          <option value="0">Seleccione</option>
                                          <option value="Virtual">Virtual</option>
                                          <option value="Parcela">Parcela</option>
                                          <option value="Ambos lugares">Ambos lugares</option>
                                        </Input>
                                    </Col>

                    </div>
                  </Collapse>
            </div>
        </>
        //}
      })
}
      return content
    }
    putContent = (val)=>{
      var content = []
      //var getdataactivity=this.state.activity_selected.filter((a,b)=>a.datastate.activity_table[0]==this.props.thisStates.put_table)
      //console.log(getdataactivity)
      var array_data = this.state.activity_selected
        array_data.map((valor,index)=>{
          if (valor.value!='none') {
            if (valor.datastate.activity_table!=undefined) {
              if (valor.datastate.activity_table[0]==this.props.thisStates.put_table) {
                content[index]=<>
                <Col md={12} className="mt-2 pb-2 score_row" data-index={index}>
                  <Col md="12" style={{border: 'solid 1px #c1c1c1',borderRadius: '5px',paddingBottom: '15px'}}>
                    <Row>
                      <div className="col-md-11" style={{borderBottom:'solid 1px #c1c1c1'}}>
                        {(valor.value=='general')?(<>
                          <Input
                          value={(valor.datastate['activity']==undefined)?(valor.datastate['activity']):(valor.datastate['activity'][0])}
                          //value={this.state.activity[valor.value.split('*')[0]+index]}
                          //value={(this.state.global_data[valor.value.split('*')[0]]!=undefined)?(this.state.global_data[valor.value.split('*')[0]]['activity'][index]):(null)}
                          disabled
                          onChange={(e)=>{
                          this.setGlobalValues(e.target.value,'activity',valor.value.split('*')[0],index,0)
                          //this.setState({activity: {...this.state.activity, [valor.value.split('*')[0]+index]:e.target.value} })
                          }}
                          type="text"
                          placeholder="Escriba un Asunto o Actividad"
                          style={{height: '20px',border: 'none',padding:'0'}}
                          />
                          <Input
                          value={(valor.datastate['init_date']==undefined)?(valor.datastate['init_date']):(valor.datastate['init_date'][0])}
                          onChange={(e)=>{
                          this.setGlobalValues(e.target.value,'init_date',valor.value.split('*')[0],index,0)
                          }}
                          type="date"
                          style={{height: '20px',border: 'none',padding:'0',display:'none'}}
                          />
                          <Input
                          value={(valor.datastate['init_time_change']==undefined)?(valor.datastate['init_time_change']):(valor.datastate['init_time_change'][0])}
                          onChange={(e)=>{
                          this.setGlobalValues(e.target.value,'init_time_change',valor.value.split('*')[0],index,0)
                          }}
                          type="time"
                          style={{height: '20px',border: 'none',padding:'0',display:'none'}}
                          />
                        </>):(
                          <Input 
                            className={"form-control-label"}
                            type="select" 
                            onChange={(e)=>{
                            this.setGlobalValues(e.target.value,'activity',valor.value.split('*')[0],index,0)
                            //this.setState({activity: {...this.state.activity, [valor.value.split('*')[0]+index]:e.target.value} })
                            }}
                            value={(valor.datastate['activity']==undefined)?(valor.datastate['activity']):(valor.datastate['activity'][0])}
                            //value={this.state.activity[valor.value.split('*')[0]+index]}
                            style={{height: '20px',border: 'none',padding:'0'}}
                          >
                            <option value="0">Seleccione una Actividad</option>
                          {/*                            {activityGlobal.map((val,ind)=>{
                          return <><option selected={valor.value.split('*')[0]==val.value.split('*')[0]} value={val.value}>{val.name}</option></>
                          })}*/}
                          </Input>
                        )}
                      </div>
                      <div className="col-md-1" style={{borderBottom:'solid 1px #c1c1c1'}}>
                        <button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.deleteTask(index,valor)}}>
                          <i className="fas fa-eraser"></i>
                        </button>
                      </div>
                    </Row>

                    <Row>
                      {this.putContentObRe(index,valor.value.split('*')[0]+index,valor.value.split('*')[0])}
                      <Col md="12">
                      {/*<button className={"btn add_info_"+index} style={{padding: '0',boxShadow: 'none'}} onClick={()=>{this.setContentObRe(index,valor.value.split('*')[0]+index,valor.value.split('*')[0])}}>+ Añadir Información</button>*/}
                      </Col>
                    </Row>

                  </Col>
                </Col>
                </>
             }
             }
          }
                     })
                     return content

    }

    desGlos = (data) =>{
      var data_select = {}
      data.map((a,b)=>{
        data_select = a
        delete data_select[b];
      })
      return data_select
    }
  sigPad = {}
  clearSignature = () => {
    this.sigPad.clear()
  }
    functionSave = async (data_mvr) => {
       //this.props.getDataMVRUpdateS()
       //console.log(data_mvr)
         if (this.props.thisStates.form_prod=='validar') {
         var get_signature=this.sigPad.getTrimmedCanvas().toDataURL('image/png')
          if (get_signature.indexOf('iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC')!=-1) {
            alert('Es Necesario una Firma')
            return
          }
         }
         var counter_valid_obs = 0
         jQuery('.input_observation').map((b,a)=>{
            if (a.value=='') {
              counter_valid_obs=counter_valid_obs+1
            }
         })
         if (counter_valid_obs!=0) {
            alert('Es necesario la observacion, no se puede estar en blanco.')
            return
         }

         var counter_valid_status = 0
         jQuery('.input_state_status').map((b,a)=>{
            if (a.value=='0') {
              counter_valid_status=counter_valid_status+1
            }
         })
         if (counter_valid_status!=0) {
            alert('Es necesario el estado de cumplimiento.')
            return
         }

         var counter_valid_priority = 0
         jQuery('.input_priority').map((b,a)=>{
            if (a.value=='0') {
              counter_valid_priority=counter_valid_priority+1
            }
         })
         if (counter_valid_priority!=0) {
            alert('Es necesario la prioridad.')
            return
         }

         var counter_valid_relizate = 0
         jQuery('.input_observation_relizate').map((b,a)=>{
            if (a.value=='0') {
              counter_valid_relizate=counter_valid_relizate+1
            }
         })
         if (counter_valid_relizate!=0) {
            alert('Es necesario la observación realizada.')
            return
         }
          var data_result = await save_report_mrv(this.state,data_mvr,'general',this.props.thisStates.db_prod,this.props.thisStates.code_prod,get_signature,this.props.thisStates.form_prod)
          if (data_result.register_id) {
           this.setState({putContentObRe: [] })
           this.setState({activity_selected: [] })
           this.putData(this.props.thisStates.form_prod,this.props.thisStates.code_prod,this.props.thisStates.db_prod)
           this.props.insertDataObservation()
          }
    }
    changeSigDraw=(data)=>{
      this.setState({sigDrawn:data})
    }
    asignedSigDraw=async(data)=>{
          this.setState({asigSigDrawn:data})
          this.setState({sigDrawn:data})
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,data,1)
    }
    saveSignature= async()=>{
         var get_signature=this.sigPad.getTrimmedCanvas().toDataURL('image/png')
          if (get_signature.indexOf('iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC')!=-1) {
            alert('Es Necesario una Firma')
            return
          }
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,get_signature,0)
          if (data_result.success) {
            var get_data_user = await get_permission(jsondata._id)
            this.setState({get_data_user:get_data_user[0]})
            this.setState({sigDrawn:false})
          }
    }
    saveActivity=async(indx_one,indx_two,dataUser)=>{
      await this.setGlobalValues(dataUser.draw_digital_signature,'signature_draw','general',indx_one,indx_two)
      await this.setGlobalValues(dataUser.system_digital_signature,'signature_digital','general',indx_one,indx_two)
      await this.functionSave(this.state.activity_selected)
      this.setState({sendOpenModal:!this.state.sendOpenModal})
    }
    generateSigDraw=async()=>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var getDataUser = this.state.get_data_user
          getDataUser.system_digital_signature = 'Generando Código'
          var putDataUser = getDataUser
          this.setState({get_data_user:putDataUser})
          var data_result = await save_signature_mrv(jsondata._id,null,2)
          if (data_result.success) {
            var get_data_user = await get_permission(jsondata._id)
            this.setState({get_data_user:get_data_user[0]})
          }
    }
    closeModal=(data)=>{
      this.setState({sendOpenModal:data})
    }
  render(){
    const onchangeMotive = (value) => {
      this.setState({selectMotive:value})
    }
    return(<>
  <Modal isOpen={this.state.sendOpenModal} size="xl">
  <ModalHeader>
    <h4>Mi firma dibujo</h4>
  </ModalHeader>
        <ModalBody>
            <div style={{textAlign:'center'}}>
              <label className="form-control-label">Asignar dibujo firma</label> <input type="checkbox" onChange={()=>{this.asignedSigDraw(!this.state.asigSigDrawn)}} checked={this.state.asigSigDrawn}/>
            </div>
{(this.state.asigSigDrawn)?(
          (this.state.sigDrawn)?(<>
                      <div style={{border: '1px solid #dee2e6',width:"380px",height:"200px",margin:'auto',marginBottom:'40px'}}>
                        <SignatureCanvas 
                        penColor='black' 
                        canvasProps={{width: 380, height: 200, className: 'sigCanvas'}}
                        ref={(ref) => { this.sigPad = ref }}
                        />
                      <button className="btn" onClick={()=>{this.saveSignature()}}>Guardar Firma</button>
                      <button className="btn" onClick={()=>{this.clearSignature()}}>Borrar Firma</button>
                      </div>
            </>):(
                  (this.state.get_data_user?.draw_digital_signature==null)?(null):(<>
                    <div style={{border: '1px solid #dee2e6',width:"380px",height:"auto",margin:'auto'}}>
                      <div style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
                        <img 
                          style={{ backgroundSize: '200px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                          src={this.state.get_data_user?.draw_digital_signature} 
                          />
                      </div>
                        <br />
                    </div>
                    <div style={{width:"380px",margin:'auto'}}>
                      <button className="btn" onClick={()=>{this.changeSigDraw(!this.state.sigDrawn)}}>Cambiar Firma</button>
                    </div>
                  </>)
            )
  ):(null)}
          <h4>Mi firma digital</h4>
          <div style={{textAlign:'center'}}>
            {(this.state.get_data_user?.system_digital_signature==null)?(<>
                    <div style={{width:"380px",margin:'auto'}}>
                      <button className="btn" onClick={()=>{this.generateSigDraw()}}>Generar firma digital (Genere la firma digital para guardar)</button>
                    </div>
              </>):(<>
                  <h2>{this.state.get_data_user?.system_digital_signature}</h2>
              </>)}
          </div>
        <hr />
          <div style={{textAlign:'center'}}>
            <h2>{this.state.get_data_user?.email}</h2>
            <h3>DNI N# {this.state.get_data_user?.dni}</h3>
          </div>
        </ModalBody>
        <ModalFooter>
        {(this.state.get_data_user?.system_digital_signature==null)?(null):(<>
            {(this.state.get_data_user?.system_digital_signature=='Generando Código')?(null):(<>
              <Button 
              className="btn-icon" 
              color="traza-blue" 
              type="button"
              onClick={()=>{this.saveActivity(this.state.indx_one,this.state.indx_two,this.state.get_data_user)}}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-check-circle" />
                </span>
                <span className="btn-inner--text">Guardar</span>
              </Button>
            </>)}
          </>)}

          <Button onClick={()=> {this.closeModal(!this.state.sendOpenModal)} }>
            Luego
          </Button>
          <div style={{color: 'black',fontSize: '14px'}}><span style={{color:'red'}}>*</span>Si deseas firmar luego dirijase al historial.</div>
        </ModalFooter>
      </Modal>
      <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle">
          <CardHeader className="border-0 pb-0 pt-0">
            <Row>
              <Col md="12">

                <h3 className="mb-0">Formulario {this.state.monitor_type_name}</h3>
              </Col>
            </Row>
          </CardHeader>
              <Col md="12">
                <Row>
                  <Col md="4" style={{display:'none'}}>
                  <label className="form-control-label">Tipo:</label><br />
                      <Input 
                      type="select" 
                      disabled
                      value={this.state.monitor_type}>
                        <option value="0">Seleccione</option>
                        <option value="ON">Monitoreo</option>
                        <option value="RE">Revisión</option>
                        <option value="VA">Validación</option>
                      </Input>
                  </Col>
                  <Col md="4" style={{display:'none'}}>
                  <label className="form-control-label">Entidad:</label><br />
                    <Input value={this.state.entidad} disabled onChange={(e)=>{this.setState({entidad:e.target.value})}} id="example-date-input" type="text" />
                  </Col>
                  <Col md="4" style={{display:'none'}}>
                  <label className="form-control-label">Nombre del Responzable:</label><br />
                    <Input value={this.state.name_mvr} disabled onChange={(e)=>{this.setState({name_mvr:e.target.value})}} id="example-date-input" type="text" />
                  </Col>
                    <Col md="4" style={{display:'none'}}>
                      <label className="form-control-label">Fecha del monitoreo:</label><br />
                      <Input
                        defaultValue={this.state.monitor_date}
                        onChange={(e)=>{this.setState({monitor_date:e.target.value})}}
                        type="date"
                      />
                    </Col>
                    <Col md="4" style={{display:'none'}}>
                      <label className="form-control-label">Hora de Inicio:</label><br />
                      <Input
                        defaultValue={this.state.init_time}
                        onChange={(e)=>{this.setState({init_time:e.target.value})}}
                        type="time"
                      />
                    </Col>
                    <Col md="4" style={{display:'none'}}>
                      <label className="form-control-label">Fecha del ultimo cambio:</label><br />
                      <Input
                        defaultValue={this.state.monitor_last_date}
                        onChange={(e)=>{this.setState({monitor_last_date:e.target.value})}}
                        type="date"
                      />
                    </Col>
                    <Col md="4" style={{display:'none'}}>
                      <label className="form-control-label">Hora final:</label><br />
                      <Input
                        defaultValue={this.state.last_time}
                        onChange={(e)=>{this.setState({last_time:e.target.value})}}
                        type="time"
                      />
                    </Col>

                  {(this.props.thisStates.form_prod=='general')?(<>
{/*                  <Col md="4">
                    <label className="form-control-label">Actividad:</label><br />
                    {
                      this.state.data_activity.map((a,b)=>{
                      //console.log(this.state.activity[a.value.split('*')[0]])
                        //(this.state.activity!=null || this.state.activity[a.value.split('*')[0]]!=undefined)?(this.state.activity[a.value.split('*')[0]]):(null)
                        return <><div><input type="checkbox" checked={(this.state.activity!=null)?(this.state.activity[a.value.split('*')[0]]):(false)} value={a.value} onChange={(e)=>{this.setContentVal(a,e.target.checked,true)}}/> <span>{a.name}</span></div></>
                      })
                    }
                  </Col>*/}
                  <Col md={12}>
                    <Row>
                      <Col md="3">
                        <button className="btn" onClick={()=>{this.setContentVal({id:'none', name: 'Seleccione una Actividad', value: 'general',datastate:{
                         activity: {[0]: this.props.thisStates.put_title},
                         init_date: {[0]: this.state.date_init_state},
                         init_time_change: {[0]: this.state.time_init_state},
                         observation: {[0]:''},
                         recommendation: {[0]:''},
                         priority: {[0]:'Sin prioridad-gray'},
                         state_status: {[0]:'Por Hacer'},
                         observation_relizate:{[0]:'0'},
                         activity_table: {[0]:this.props.thisStates.put_table},
                         signature_draw: {[0]:'none'},
                         signature_digital: {[0]:'none'},
                      } },true,true)}}>+ Añadir Tarea</button>
                      </Col>
                    {(this.state.activity_selected.length!=0)?(
                      <Col md="2">
                        <button className="btn" onClick={()=> {this.functionSave(this.state.activity_selected)} }>Guardar</button>
                      </Col>
                      ):(null)}
                    </Row>
                  </Col>
                    </>):(null)}

                  <Col md="12" className="rowViewNumberGlobal">
                    <Row className="rowViewNumber">
                      {this.putContent()}
                    </Row>
                  </Col>

{/*{(this.state.monitor_type=='VA')?(
  (this.props.thisStates.form_prod=='validar')?(<>
                    <Col md="4">
                      <label className="form-control-label">Firma:</label><br />
                      {(this.state.signature!='')
                        ? (<><img 
                          style={{ backgroundSize: '200px 50px',width: '200px',height: '50px',backgroundColor: 'white'}} 
                          src={this.state.signature} 
                          /></>)
                        : (null)}
                      <div style={{border: '1px solid #dee2e6'}}>
                        <SignatureCanvas 
                        penColor='black' 
                        canvasProps={{width: 380, height: 200, className: 'sigCanvas'}}
                        ref={(ref) => { this.sigPad = ref }}
                        />
                      </div>
                      <button className="btn" style={{padding: '0'}} onClick={()=>{this.clearSignature()}}>
                        Borrar Firma
                      </button>
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">Nro de Autorización:</label><br />
                      <Input
                        value={this.state.nro_autorization}
                        onChange={(e)=>{this.setState({nro_autorization:e.target.value})}}
                        type="number"
                      />
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">Subir Imagen/Documento:</label><br />
                      <Input
                        onChange={(e)=>{this.setState({document:e.target.files[0]})}}
                        type="file"
                      />
                    </Col>
  </>):(null)
):(null)}*/}

                </Row>
              </Col>
            <div>
          {(this.props.thisStates.form_prod=='validar')?(
              <button className="btn" onClick={()=> {this.functionSave(this.state.activity_selected)} }>
               Guardar
            </button>
          ):(null)}
          {(this.state.activity_selected.length!=0)?(
              <button className="btn" onClick={()=> {this.functionSave(this.state.activity_selected)} }>
               Guardar
            </button>
          ):(null)}
    {/*          <button className="btn" onClick={()=> {this.props.openModalFunc(false)} }>
                Cerrar
              </button>*/}
            </div>
        </div>
    </>)
  }
}