import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table
} from "reactstrap";
import React from "react";
import IndexNavbar from "components/Navbars/NavbarTrace.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import { get_data_row_main, get_data_row_plant } from "network/ApiAxios";
import SectionProduction from "./SectionsViewTrace/SectionProductionView";
import SectionCosecha from "./SectionsViewTrace/SectionCosechaView";
//import SectionTransport from "./SectionsViewTrace/SectionTransportView";
import SectionPlanta from "./SectionsViewTrace/SectionPlantaView";
import SectionEmbarque from "./SectionsViewTrace/SectionLogisticView";
import SectionCommerce from "./SectionsViewTrace/SectionCommerceView";
import SectionDocuments from "./SectionsViewTrace/SectionDocumentsView";
import SectionTraza from "views/pages/examples/Trazability/SectionsViewTrace/SectionTrazaView";

import { NavLink as ComponentLink } from "react-router-dom";
import {GuideMessage} from "views/pages/components/GuideMessage";
import jQuery from "jquery";

import "./StyleCss.css";
export default class VistaTrace extends React.Component {
  state ={
    statusClass:[],
    statusColor:[],
    allDataTrza:null,
    DataRowProdcutionCreate:['Cargando...'],
    DataRowProdcutionFertilizer:['Cargando...'],
    commercialization_export:['Cargando...'],
    commercialization_market:['Cargando...'],
    create_cosecha:['Cargando...'],
    create_plant_cold:['Cargando...'],
    create_plant_information_empaque:['Cargando...'],
    create_plant_pre_cold:['Cargando...'],
    create_plant_product:['Cargando...'],
    create_plant_sample:['Cargando...'],
    create_plant_treatment:['Cargando...'],
    create_plant_weight_product:['Cargando...'],
    logistics_transport_shipment:['Cargando...'],
    logistics_transport_transporte:['Cargando...'],
    transport_cosecha:['Cargando...'],
    'fruit_vegetable':['Cargando...'],
    'transport_vehicule_cosecha':['Cargando...'],
    code_traza:'Cargando...',
    data_user:null,
    components:SectionTraza,
  }
  async componentDidMount(){
        var gerParam = null;
    if (gerParam==null) {
      this.setState({code_traza:'Sin Trazabilidad'});
      this.setState({create_plant_information_empaque:null});
      this.setState({DataRowProdcutionCreate:null});
      this.setState({DataRowProdcutionFertilizer:null});
      this.setState({commercialization_export:null});
      this.setState({commercialization_market:null});
      this.setState({create_cosecha:null});
      this.setState({create_plant_cold:null});
      this.setState({create_plant_pre_cold:null});
      this.setState({create_plant_product:null});
      this.setState({create_plant_sample:null});
      this.setState({create_plant_treatment:null});
      this.setState({create_plant_weight_product:null});
      this.setState({logistics_transport_shipment:null});
      this.setState({logistics_transport_transporte:null});
      this.setState({transport_cosecha:null});
      this.setState({fruit_vegetable:null});
      this.setState({transport_vehicule_cosecha:null});
    }else{
    this.setNameDB(gerParam)
    }
  }
  componentDidUpdate(devProps,devState){
    if (this.props.code!=devProps.code) {
     this.setNameDB(this.props.code)
    }
  }
  configAllDataStatus = (dataRow) => {
if (dataRow==undefined) {
  var data_array_get = []
}else{
  var data_array_get = Object.keys(dataRow)
}
data_array_get.map((a,b)=>{
       if (dataRow[a]['data_array'].length==0) {
        this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
        this.setState({statusColor:{...this.state.statusColor,[a]:'#7A7A7A'}});
       }else{
          dataRow[a]['data_array'].map((value,index)=>{
          if (value.status=='finalizado') {
            this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-check-circle'}});
            this.setState({statusColor:{...this.state.statusColor,[a]:'#58d999'}});
          }
          if (value.status=='desarrollo') {
            this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
            this.setState({statusColor:{...this.state.statusColor,[a]:'#f8d014'}});
          }
          })
       }
 })

  }
    setNameDB = async(data) =>{
    this.setState({code_traza:data+':'});
    var dataRow = await get_data_row_main(data)
    this.configAllDataStatus(dataRow.data.data_traza_row)
    this.setState({allDataTrza: dataRow.data.data_traza_row})
    this.setState({data_user:dataRow.data.data_user});

    if(dataRow.data.fruit_vegetable==null){
      this.setState({statusTrazaClass:'fas fa-minus-circle'});
      this.setState({statusTrazaColor:'#7A7A7A'});
      this.setState({fruit_vegetable:null});
    }else{
      this.setState({data_user:dataRow.data.data_user});
          if (dataRow.data.fruit_vegetable.status=='finalizado') {
            this.setState({statusTrazaClass:'fas fa-check-circle'});
            this.setState({statusTrazaColor:'#58d999'});
          }
          if (dataRow.data.fruit_vegetable.status=='desarrollo') {
            this.setState({statusTrazaClass:'fas fa-minus-circle'});
            this.setState({statusTrazaColor:'#f8d014'});
          }
      this.setState({fruit_vegetable:dataRow.data.fruit_vegetable});
    }
    if(dataRow.data.production_create==null){
      this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
      this.setState({statusProdcutionFertilizerColor:'#7A7A7A'});
      this.setState({DataRowProdcutionCreate:null});
    }else{
      if (dataRow.data.production_fertilizer[0]!=null) {
          if (dataRow.data.production_create.status=='finalizado') {
            this.setState({statusProdcutionFertilizerClass:'fas fa-check-circle'});
            this.setState({statusProdcutionFertilizerColor:'#58d999'});
          }
          if (dataRow.data.production_create.status=='desarrollo') {
            this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
            this.setState({statusProdcutionFertilizerColor:'#f8d014'});
          }
      }else{
          if (dataRow.data.production_create.status=='finalizado') {
            this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
            this.setState({statusProdcutionFertilizerColor:'#58d999'});
          }
          if (dataRow.data.production_create.status=='desarrollo') {
            this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
            this.setState({statusProdcutionFertilizerColor:'#f8d014'});
          }
      }
      this.setState({DataRowProdcutionCreate:dataRow.data.production_create});
    }
    if (dataRow.data=='Sin trazabilidad') {
      return
    }

    }
    pushSection = (components) => {
      this.setState({components:components})
      
    }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      data.split('|').map((a,b)=>{
        content[b]=<th>{a}</th>
      })
    }
    return content
  }
  setContent = (data,type_table) => {
    if (data.data_array!=undefined) {
     if (data.data_array.length!=0) {
       var content=[]
       var contetnTh=[]

       data.data_array.map((a,b)=>{
       var get_select = this.putForSelect(Object.entries(a))
       var content_one = Object.entries(a).map((val,ind)=>{
            var content_two = null
              if (val[0]!='status'  && val[0]!='id' && val[0]!='trazabilidad_id' && val[0]!='user_id' && val[0]!='codigo' && val[0]!='trazabilidade_ida' && val[0]!='created_at' && val[0]!='updated_at') {
                if (val[0].indexOf('select_')==-1) {
                  if (val[1]!=null) {
                       content_two = <><td><span className="text-muted">{val[1]+get_select}</span></td></>
                  }else{
                       content_two = <><td><span className="text-muted">Sin Dato</span></td></>
                  }
                  this.putTitle(data.title_label.split('|'),val[0],type_table)
                }
              }

                       return content_two
         })
         content[b] = <tr>{content_one}</tr>
       })
     }
    }
     return content
  }
putTitle = (data,title,type_table) => {
  var result = []
  data.map((a,b)=>{
     var the_same = a.replaceAll('ñ','n').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ','_').replaceAll('/','_').toLowerCase()
      if (title.indexOf(the_same)!=-1) {
        result[b] = '<th class="th_'+type_table+'_'+title+'">'+a+'</th>'
        // if (true) {
        // }
      }
  })  
    var result_two = []

         jQuery('.putContentTh'+type_table).map((indx,val)=>{
          var get_name_th = jQuery(val).find('.th_'+type_table+"_"+title).hasClass('th_'+type_table+"_"+title)
          //result_two[indx] = get_name_th
          if (!get_name_th) {
            jQuery('.putContentTh'+type_table).append(result)
          }
         })
      //console.log(result,type_table)
}
    putForSelect = (field) => {
                    var get_data_select = []
                    field.map((a,b)=> {
                      if (a[0].indexOf('select_')!=-1) {
                        get_data_select[a[0].replaceAll('select_','')] = ' '+a[1]
                      }else{
                        get_data_select[a[0].replaceAll('select_','')] = ''
                      }
                    })
                    return get_data_select
    }
    putContentCard =()=>{
      var data = [
      {title:'Datos Trazabilidad',table:'fruit_vegetable'},
      {title:'Datos Producción',table:'production_create'},
      {title:'Datos Fertilizantes/Agroquímicos',table:'production_fertilizer'},
      {title:'Datos Cosecha',table:'create_cosecha'},
      {title:'Datos Transporte',table:'transport_cosecha'},
      {title:'Datos Vehiculo',table:'transport_vehicule_cosecha'},

      {title:'Datos Ingreso del Producto',table:'create_plant_product'},
      {title:'Datos Muestra de Calidad',table:'create_plant_sample'},
      {title:'Datos Peso del Producto',table:'create_plant_weight_product'},
      {title:'Datos Tratamiento',table:'create_plant_treatment'},

      {title:'Datos Embarque',table:'logistics_transport_shipment'},
      {title:'Datos Transporte',table:'logistics_transport_transporte'},

      {title:'Datos Mercado Nacional',table:'commercialization_market'},
      {title:'Datos Exportación',table:'commercialization_export'},
      ]
      var content=[]
      data.map((val,ind)=>{
        content[ind] = <>
        <Card style={{display: (this.state.allDataTrza!=null)?((this.state.allDataTrza[val.table].data_array.length!=0)?('block'):('none')):('none') }}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">{val.title}</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table style={{width: '1px'}} className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr className={"putContentTh"+val.table}>
{/*              {
                (this.state.allDataTrza!=null)?(this.setContentTh(this.state.allDataTrza[val.table].title_label)):(null)
              }*/}
              </tr>
            </thead>
            <tbody>
              {
                (this.state.allDataTrza!=null)?(this.setContent(this.state.allDataTrza[val.table],val.table)):(null)
              }            
            </tbody>
          </Table>
        </Card>
        </>
      })
      return content
    }
  render(){
  return (
    <>
      <div className="main-content pt-0" style={{backgroundColor: 'white'}}>
{/*            <Col md="10" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                  <h3 className="mb-0" style={{color:'#ffffff'}}>Trazabilidad Frutas y Vegetales</h3>
                </CardHeader>
                <CardBody>
                <Row>
                  <Col md={11}>
                  <Row>
                    <h1 style={{margin:0}}>{this.state.code_traza}</h1>
                    <p style={{margin:0, paddingLeft: '8px',fontSize: '13px',fontWeight: 600}}>{(this.state.data_user==null)?(null):(' '+this.state.data_user.name+', '+this.state.data_user.email)}<br /> {(this.state.data_user==null)?(null):(' '+this.state.data_user.pais)}</p>
                  </Row>
                  </Col>
                  <Col md={1} style={{padding:0}}>
                  {(this.state.data_user==null)?(null):(<img alt={String(this.state.data_user.pais).toLocaleLowerCase()+" icon"} srcset={"https://img.icons8.com/office/2x/"+String(this.state.data_user.pais).toLocaleLowerCase()+".png 2x"} />)}
                  </Col>
                </Row>
                </CardBody>
              </Card>
            </Col>*/}
    <section className="elementor-section elementor-top-section elementor-element elementor-element-ddc63bc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddc63bc" data-element_type="section">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70ec96d" data-id="70ec96d" data-element_type="column">
          <div className="elementor-widget-wrap">
          </div>
        </div>
      </div>
      </section>
        {this.putContentCard()}
{/*          <section style={{backgroundColor: 'white'}} className="elementor-section elementor-top-section elementor-element elementor-element-9f9c7a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9f9c7a0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"><div className="elementor-container elementor-column-gap-extended">
              <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4d21c55" data-id="4d21c55" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-1a59632 elementor-widget elementor-widget-eael-adv-tabs" data-id="1a59632" data-element_type="widget" data-widget_type="eael-adv-tabs.default">
            <div className="elementor-widget-container">
                <div id="eael-advance-tabs-1a59632" className="eael-advance-tabs eael-tabs-horizontal" data-tabid="1a59632">
              <div className="eael-tabs-nav">
                <ul className="eael-tab-top-icon">
                <Col id="vegefru" aria-selected="false" data-tab="1" role="tab" aria-controls="vegefru-tab" aria-expanded="false" className='text-center'>
                  <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionTraza)}} className="eael-tab-item-trigger">
                  <i className="fas fa-carrot" ></i>
                  </li>
                  <Col>
                    <i style={{fontSize: '24px',color: (this.state.statusColor['fruit_vegetable']==undefined)?('#7A7A7A'):(this.state.statusColor['fruit_vegetable'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
                  </Col>
                  <span className="eael-tab-title title-after-icon">Trazabilidad</span>
                </Col>

                <Col id="produccion" aria-selected="false" data-tab="2" role="tab"  aria-controls="produccion-tab" aria-expanded="false" className='text-center'>
                  <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionProduction)}} className="eael-tab-item-trigger">
                  <i className="fas fa-seedling"></i>                                                            
                  </li>

                  <Col>
                  <i style={{fontSize: '24px',color: (this.state.statusColor['production_fertilizer']==undefined)?('#7A7A7A'):(this.state.statusColor['production_fertilizer'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
                  </Col>

                  <span className="eael-tab-title title-after-icon">Producción</span>
                </Col>

                <Col id="cosecha" aria-selected="false" data-tab="3" role="tab"  aria-controls="cosecha-tab" aria-expanded="false" className='text-center'>
                  <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionCosecha)}} className="eael-tab-item-trigger">
                  <i className="fas fa-apple-alt"></i>                                                            
                  </li>
                  <Col>
                    <i style={{fontSize: '24px',color: (this.state.statusColor['create_cosecha']==undefined)?('#7A7A7A'):(this.state.statusColor['create_cosecha'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
                  </Col>
                  <span className="eael-tab-title title-after-icon">Cosecha</span>
                </Col>

                <Col id="planta" aria-selected="false" data-tab="5" role="tab"  aria-controls="planta-tab" aria-expanded="false" className='text-center'>
                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionPlanta)}} className="eael-tab-item-trigger">
                  <i className="fas fa-boxes"></i>                                                            
                </li>
                  <Col>
                    <i style={{fontSize: '24px',color: (this.state.statusColor['create_plant_product']==undefined)?('#7A7A7A'):(this.state.statusColor['create_plant_product'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
                  </Col>
                  <span className="eael-tab-title title-after-icon">Planta</span>
                </Col>

                <Col id="logistica" aria-selected="false" data-tab="6" role="tab"  aria-controls="logistica-tab" aria-expanded="false" className='text-center'>
          <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionEmbarque)}} className="eael-tab-item-trigger">
                  <i className="fas fa-network-wired"></i>                                                            
                  </li>
                  <Col>
                    <i style={{fontSize: '24px',color: (this.state.statusColor['logistics_transport_shipment']==undefined)?('#7A7A7A'):(this.state.statusColor['logistics_transport_shipment'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
                  </Col>
                  <span className="eael-tab-title title-after-icon">Logistica</span>
                </Col>

                <Col id="documentos" aria-selected="false" data-tab="7" role="tab"  aria-controls="documentos-tab" aria-expanded="false" className='text-center'>
                  <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionDocuments)}} className="eael-tab-item-trigger">
                  <i className="fas fa-clipboard-check"></i>
          </li>
                  <span className="eael-tab-title title-after-icon">Documentos</span>
                </Col>

                <Col id="comercializacion" aria-selected="false" data-tab="8" role="tab"  aria-controls="comercializacion-tab" aria-expanded="false" className='text-center'>
                  <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionCommerce)}} className="eael-tab-item-trigger">
                  <i className="fas fa-handshake"></i>                                                            
                  </li>
                  <Col>
                    <i style={{fontSize: '24px',color: (this.state.statusColor['commercialization_market']==undefined)?('#7A7A7A'):(this.state.statusColor['commercialization_market'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
                  </Col>
                  <span className="eael-tab-title title-after-icon">Comercialización</span>
                </Col>

                </ul>
                </div>
            {(this.state.allDataTrza==null)?('Contenido.'):(< this.state.components DataRow={this.state} />)}
        </div>
    </div>
        </div>
          </div>
    </div>
              </div>
    </section>*/}

    </div>
    </>
  );
 }
}

