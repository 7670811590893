import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { get_data_transport_trace } from "network/ApiAxios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';

//import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentSubPlant} from './InfoLogistic'
export default class ContentLogisticKdes extends React.Component {
  state = {
    sizeOptions:[],
    data_table:'',
    set_lote_num:''
  }
  async componentDidMount(){
    //console.log(this.props.allDataTraza)
  }
   async componentDidUpdate(){
    //console.log(this.props)
    //this.props.putTokenData
   }

  render(){
    const contentFrom = (dataTr) =>{
      var regex = new RegExp('^1_lote_', 'g');
      var setData = 'none'
      if (dataTr[this.props.dataSelected[1]]!=undefined) {
        if (dataTr[this.props.dataSelected[2]]!=this.state.set_lote_num) {
          this.setState( {set_lote_num:dataTr[this.props.dataSelected[2]]} )
        }
        var getColumn = this.props.allDataTraza.filter((fd)=> fd.id.indexOf(dataTr[this.props.dataSelected[1]]?.split('1_lote_')[1])!=-1 && fd.data.token_id.indexOf(dataTr.token_id)!=-1)
        console.log(getColumn)
        if (getColumn.length!=0) {
          setData = getColumn[0].data.from
        }
      }
      return setData
    }
  return (
    <>
        <div className="card">
            <div className="flex justify-content-center mb-4">
                <SelectButton value={this.props.dataSelected[0]} onChange={(e) => {
                  // token_id
                  // table_column_change
                  // table_column
                  this.props.putTokenData({table_column:this.props.dataSelected[2],table_column_change:this.props.dataSelected[1],token_id:this.props.dataToken},this.props.dataSelect,e.value,this.state.set_lote_num)
                }} options={this.props.dataSelect} />
            </div>
      {(this.props.dataSelectTable.length>0)?(
            <DataTable value={this.props.dataSelectTable} stripedRows showGridlines size="small" tableStyle={{ minWidth: '50rem' }}>
            {(this.props.dataSelected[2]!='none')?(
              <Column style={{border: '1px solid gray'}} body={contentFrom} header='De'></Column>
            ):(null)}

            {this.props.dataSelectColumn.map((a,b)=>{
                return <Column style={{border: '1px solid gray'}} field={a.column} header={a.title}></Column>
            })}
            </DataTable>
        ):(null)}
        </div>
    </>
  );
}
}