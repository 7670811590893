import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { get_data_transport_trace } from "network/ApiAxios";
import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentSubPlant} from './InfoLogistic'
export default class ContentLogistic extends React.Component {
  state = {
    cross_transport:['cargando'],
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false]
  }
  async componentDidMount(){
  }
  contentRows = () =>{

    var content = []
    this.props.dataTable.map((val,indx)=>{
      content[indx] = <>
            <Col md={12}>
            <div className="elementor-widget-wrap elementor-element-populated" >
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
            <div className="elementor-widget-container">
            <div role="tablist">
            <div className="elementor-toggle-item" style={{zIndex: '99'}}>
            <h6 onClick={()=>{this.opened(this.state.openColapse[indx+1],indx+1)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
              <div style={{marginBottom:'10px'}} className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
              <div className="elementor-widget-container">
              <div className="elementor-image-box-wrapper">
              <figure className="elementor-image-box-img" style={{marginTop: '5px',marginBottom: '5px'}}>
              <div style={{width:30,height:30,backgroundColor:PutContentStatus(this.props.dataRow.allDataTrza,val.table).color,borderRadius: '100px', textAlign: 'center'}}>
              <span className={val.icon} style={{color:'#204d74',fontSize: '23px',position: 'relative',bottom: '0px',left: '0px'}}></span>
              </div>
              </figure>
              <div className="elementor-image-box-content">
              <span className="eael-tab-title title-after-icon" style={{fontWeight: 'normal',fontSize: '12px'}}>{val.title}</span>
              </div>
              </div>
              </div>
              </div>
            </h6>
                </div>
                </div>
                </div>
                </div>
                <div className="elementor-element elementor-element-81e561d elementor-widget elementor-widget-spacer" data-id="81e561d" data-element_type="widget" data-widget_type="spacer.default">
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
            </div>
            </div>
            </div>
            </div>
        </Col>
                  </>
    })
    return content
  }
  contentRowsCard = () =>{
    var content = []
    this.props.dataTable.map((val,indx)=>{
      content[indx] = <>
      {(this.props.dataRow.allDataTrza[val.table]==undefined)?(null):(
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
            <div className="elementor-toggle-item">
            <Collapse isOpen={this.state.openColapse[indx+1]} id="elementor-tab-content-6961" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-6961">
              <ul style={{padding: '0px'}}>
            {(val.multiple_tables==0)?(<>
              {(this.props.dataRow.allDataTrza[val.table]['data_array'].length>1)?(
                      <PutContentSub
                      data_function={this.props.dataRow} 
                      type_function={val.table}
                      states={this.state}
                      openedChild={this.openedChild}
                      chillSelector={"data"}
                      />
                ):(
                  <div style={{margin: 'auto'}}>
                    <Card className="p-0">
                      <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                      <Row>
                        <Col md={2}>
                          <h6 className="mb-0" style={{color: '#ffffff'}}>Estado:</h6>
                        </Col>
                        <div className="col-md-3">
                          <h6 className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.dataRow.allDataTrza,val.table).register_status}</h6>
                        </div>
                      </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                        <PutContent 
                        data_function={this.props.dataRow} 
                        type_function={val.table} />                  
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                )}
              </>):(null)}
            {(val.multiple_tables==1)?(<>
              <PutContentSubTwo
              data_function={this.props.dataRow} 
              type_function={val.table_one}
              type_function_two={val.table_two}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datat"}
              titleType={val.title_one}
              titleTypeTwo={val.title_two}
              />
              </>):(null)}
            {(val.multiple_tables==2)?(<>
              <PutContentSubPlant
              data_function={this.props.dataRow} 
              type_function={val.table_one}
              type_function_two={val.table_two}
              type_function_tree={val.table_three}
              type_function_four={val.table_fuor}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datadp"}
              titleType={val.title_one}
              titleTypeTwo={val.title_two}
              titleTypeTree={val.title_three}
              titleTypeFour={val.title_fuor}
              />
              </>):(null)}
              </ul>
              </Collapse>
            </div>
            </div>
            </div>
            </div>
            )}

                  </>
    })
    return content
  }
   opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
   openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
     openedNextChild = (isChange,ident) =>{
     if (isChange==undefined) {
       this.setState({openColapseNextChild:{[ident]: true }});
       }else{
       this.setState({openColapseNextChild:{[ident]: !isChange }});
       }
     }
     setTransport = async (value, index,ident) =>{ 
       this.setState({cross_transport:[{numero_de_guia:'Cargando'}]})
       var data_transport = await get_data_transport_trace(value)
       this.setState({cross_transport:data_transport})
     }
  render(){
  return (
    <>
                    <div id="planta-tab" className="clearfix eael-tab-content-item inactive" data-title-link="planta-tab">
                      <article data-elementor-type="section" data-elementor-id="5420" className="elementor elementor-5420">
                      <div className="elementor-section-wrap">
                      <Row>
                      <Col md={2} style={{left:'0%'}}>
                      {this.contentRows()}
                      </Col>
                      <Col md={10} className="pt-2">
                      {this.contentRowsCard()}
                      </Col>
                      </Row>
                      <section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
                      <div className="elementor-widget-wrap">
                      </div>
                      </div>
                      </div>
                      </section>
                      </div>
                      </article>
                      </div>
    </>
  );
}
}