import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import React from "react";
import IndexNavbar from "components/Navbars/NavbarTrace.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import { get_data_row_main_meat, get_data_row_plant } from "network/ApiAxios";
import SectionLote from "./SectionsViewTraceMeats/SectionLoteView";
import SectionTraza from "views/pages/examples/Trazability/SectionsViewTraceMeats/SectionTrazaMeat";
import SectionCompartiment from "./SectionsViewTraceMeats/SectionCompartimentView";
import SectionFridge from "./SectionsViewTraceMeats/SectionFridgeView";
import SectionLogistic from "./SectionsViewTraceMeats/SectionLogisticaTransporteView";
import SectionDocuments from "./SectionsViewTraceMeats/SectionDocumentsView";

import { NavLink as ComponentLink } from "react-router-dom";

import "./StyleCss.css";
export default class VistaTrace extends React.Component {
	state ={
		'traza_meat':['Cargando...'],
		'table_lote':[],
		'compartment_receipt_animal':['Cargando...'],
		'compartment_exit_animal':['Cargando...'],
		'fridge_info_fridge':['Cargando...'],
		'fridge_produc_received':['Cargando...'],
		'fridge_produc_task':['Cargando...'],
		'slaughterhouse_exploded_record':['Cargando...'],
		'slaughterhouse_packing':['Cargando...'],
		'slaughterhouse_freezing':['Cargando...'],
		'logistics_transport_from':['Cargando...'],
		'logistics_transport_export':['Cargando...'],
		'trazaDataAnimalEliminado':['Cargando...'],
		'trazaDataAnimal':['Cargando...'],
		code_traza:'Cargando...',
		data_user:null,
		components:SectionTraza,
	}
	async componentDidMount(){
		var gerParam = new URLSearchParams(this.props.location.search).get("code");
		if (gerParam==null) {
			this.setState({code_traza:'Sin Trazabilidad'});
			this.setState({traza_meat:null});
		}else{
			this.setNameDB(gerParam)
		}

	}
	componentDidUpdate(){
    this.props.history.listen((location, action) => {
    	if (action=='POP' && location.pathname=='/admin/vista-trace-meat-admin') {
			document.getElementById("lote").click()
    	}
	});
	}
		setNameDB = async(data) =>{
			this.setState({code_traza:data+':'});
		var dataRow = await get_data_row_main_meat(data)
		if(dataRow.create_traceability_meat==null){
			this.setState({statusTrazaClass:'fas fa-minus-circle'});
			this.setState({statusTrazaColor:'#7A7A7A'});
			this.setState({traza_meat:null});
		}else{
			this.setState({data_user:dataRow.data_user});
					if (dataRow.create_traceability_meat.status=='finalizado') {
		 				this.setState({statusTrazaClass:'fas fa-check-circle'});
		 				this.setState({statusTrazaColor:'#58d999'});
				 	}
				 	if (dataRow.create_traceability_meat.status=='desarrollo') {
		 				this.setState({statusTrazaClass:'fas fa-minus-circle'});
		 				this.setState({statusTrazaColor:'#f8d014'});
					}
			this.setState({traza_meat:dataRow.create_traceability_meat});
		}

		if(dataRow.lote_inventario[0]==undefined){
			this.setState({statusLoteClass:'fas fa-minus-circle'});
			this.setState({statusLoteColor:'#7A7A7A'});			
			this.setState({table_lote:[]});
		}else{
			this.setState({table_lote:dataRow.lote_inventario});
					if (dataRow.lote_inventario[0].status=='finalizado') {
		 				this.setState({statusLoteClass:'fas fa-check-circle'});
		 				this.setState({statusLoteColor:'#58d999'});
				 	}
				 	if (dataRow.lote_inventario[0].status=='desarrollo') {
		 				this.setState({statusLoteClass:'fas fa-minus-circle'});
		 				this.setState({statusLoteColor:'#f8d014'});
					}			
		}

		if(dataRow.compartment_receipt_animal[0]==undefined){
			this.setState({statusAnimalClass:'fas fa-minus-circle'});
			this.setState({statusAnimalColor:'#7A7A7A'});
			this.setState({compartment_receipt_animal:[]});
		}else{
			if (dataRow.compartment_exit_animal[0]!=null) {
				 	if (dataRow.compartment_receipt_animal[0].status=='finalizado') {
		 				this.setState({statusAnimalClass:'fas fa-check-circle'});
		 				this.setState({statusAnimalColor:'#58d999'});
				 	}
				 	if (dataRow.compartment_receipt_animal[0].status=='desarrollo') {
		 				this.setState({statusAnimalClass:'fas fa-minus-circle'});
		 				this.setState({statusAnimalColor:'#f8d014'});
					}
			}else{
					if (dataRow.compartment_receipt_animal[0].status=='finalizado') {
		 				this.setState({statusAnimalClass:'fas fa-minus-circle'});
		 				this.setState({statusAnimalColor:'#58d999'});
				 	}
				 	if (dataRow.compartment_receipt_animal[0].status=='desarrollo') {
		 				this.setState({statusAnimalClass:'fas fa-minus-circle'});
		 				this.setState({statusAnimalColor:'#f8d014'});
					}
			}

			this.setState({compartment_receipt_animal:dataRow.compartment_receipt_animal});
		}
		if(dataRow.compartment_exit_animal[0]==undefined){
			this.setState({compartment_exit_animal:[]});
		}else{
			this.setState({compartment_exit_animal:dataRow.compartment_exit_animal});
		}
		if(dataRow.trazaDataAnimal[0]==undefined){
			this.setState({trazaDataAnimal:[]});
		}else{
			this.setState({trazaDataAnimal:dataRow.trazaDataAnimal});
		}

		if(dataRow.fridge_info_fridge[0]==undefined){
			this.setState({statusFridgeClass:'fas fa-minus-circle'});
			this.setState({statusFridgeColor:'#7A7A7A'});
			this.setState({fridge_info_fridge:[]});
		}else{
			this.setState({fridge_info_fridge:dataRow.fridge_info_fridge});
					if ( dataRow.fridge_produc_received[0]!=undefined && dataRow.fridge_produc_task[0]!=undefined && dataRow.slaughterhouse_exploded_record[0]!=undefined ) {
						if (dataRow.fridge_info_fridge[0].status=='finalizado') {
							this.setState({statusFridgeClass:'fas fa-check-circle'});
							this.setState({statusFridgeColor:'#58d999'});
						}
						if (dataRow.fridge_info_fridge[0].status=='desarrollo') {
							this.setState({statusFridgeClass:'fas fa-minus-circle'});
							this.setState({statusFridgeColor:'#f8d014'});
						}
					}else{
							this.setState({statusFridgeClass:'fas fa-minus-circle'});
						if (dataRow.fridge_info_fridge[0].status=='finalizado') {
							this.setState({statusFridgeColor:'#58d999'});
						}
						if (dataRow.fridge_info_fridge[0].status=='desarrollo') {
							this.setState({statusFridgeColor:'#f8d014'});
						}
					}
		}

		if(dataRow.fridge_produc_received[0]==undefined){
			this.setState({fridge_produc_received:[]});
		}else{
			this.setState({fridge_produc_received:dataRow.fridge_produc_received});
		}

		if(dataRow.fridge_produc_task[0]==undefined){
			this.setState({fridge_produc_task:[]});
		}else{
			this.setState({fridge_produc_task:dataRow.fridge_produc_task});
		}

		if(dataRow.slaughterhouse_exploded_record[0]==undefined){
			this.setState({slaughterhouse_exploded_record:[]});
		}else{
			this.setState({slaughterhouse_exploded_record:dataRow.slaughterhouse_exploded_record});
		}
				if(dataRow.slaughterhouse_packing[0]==undefined){
			this.setState({slaughterhouse_packing:[]});
		}else{
			this.setState({slaughterhouse_packing:dataRow.slaughterhouse_packing});
		}
				if(dataRow.slaughterhouse_freezing[0]==undefined){
			this.setState({slaughterhouse_freezing:[]});
		}else{
			this.setState({slaughterhouse_freezing:dataRow.slaughterhouse_freezing});
		}
		
		if(dataRow.logistics_transport_from[0]==undefined){
			this.setState({statusLogisticClass:'fas fa-minus-circle'});
			this.setState({statusLogisticColor:'#7A7A7A'});
			this.setState({logistics_transport_from:[]});
		}else{
			if (dataRow.logistics_transport_export[0]!=null) {
				 	if (dataRow.logistics_transport_from[0].status=='finalizado') {
		 				this.setState({statusLogisticClass:'fas fa-check-circle'});
		 				this.setState({statusLogisticColor:'#58d999'});
				 	}
				 	if (dataRow.logistics_transport_from[0].status=='desarrollo') {
		 				this.setState({statusLogisticClass:'fas fa-minus-circle'});
		 				this.setState({statusLogisticColor:'#f8d014'});
					}
			}else{
					if (dataRow.logistics_transport_from[0].status=='finalizado') {
		 				this.setState({statusLogisticClass:'fas fa-minus-circle'});
		 				this.setState({statusLogisticColor:'#58d999'});
				 	}
				 	if (dataRow.logistics_transport_from[0].status=='desarrollo') {
		 				this.setState({statusLogisticClass:'fas fa-minus-circle'});
		 				this.setState({statusLogisticColor:'#f8d014'});
					}
			}
			this.setState({logistics_transport_from:dataRow.logistics_transport_from});
		}
		if(dataRow.logistics_transport_export[0]==undefined){
			this.setState({logistics_transport_export:[]});
		}else{
			this.setState({logistics_transport_export:dataRow.logistics_transport_export});
		}
		if(dataRow.trazaDataAnimalEliminado[0]==undefined){
			this.setState({trazaDataAnimalEliminado:[]});
		}else{
			this.setState({trazaDataAnimalEliminado:dataRow.trazaDataAnimalEliminado});
		}





		/*this.setState({
			DataRowProdcutionCreate:dataRow.data.production_create,
			DataRowProdcutionFertilizer:dataRow.data.production_fertilizer,
			commercialization_export:dataRow.data.commercialization_export,
			commercialization_market:dataRow.data.commercialization_market,
			create_cosecha:dataRow.data.create_cosecha,
			create_plant_cold:dataRow.data.create_plant_cold,
			create_plant_information_empaque:dataRow.data.create_plant_information_empaque,
			create_plant_pre_cold:dataRow.data.create_plant_pre_cold,
			create_plant_product:dataRow.data.create_plant_product,
			create_plant_sample:dataRow.data.create_plant_sample,
			create_plant_treatment:dataRow.data.create_plant_treatment,
			create_plant_weight_product:dataRow.data.create_plant_weight_product,
			logistics_transport_shipment:dataRow.data.logistics_transport_shipment,
			logistics_transport_transporte:dataRow.data.logistics_transport_transporte,
			transport_cosecha:dataRow.data.transport_cosecha,
			traza_meat:dataRow.data.traza_meat,
			transport_vehicule_cosecha:dataRow.data.transport_vehicule_cosecha,
		});*/
		}
		pushSection = (components) => {
			this.setState({components:components})
			
		}
	render(){
  return (
    <>
      <div className="main-content pt-8" style={{backgroundColor: 'white'}}>
                	<Col md="10" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                  <h3 className="mb-0" style={{color:'#ffffff'}}>Trazabilidad Carnico</h3>
                </CardHeader>
                <CardBody>
                <Row>
                	<Col md={11}>
                	<Row>
                		<h1 style={{margin:0}}>{this.state.code_traza}</h1>
                		<p style={{margin:0, paddingLeft: '8px',fontSize: '13px',fontWeight: 600}}>{(this.state.data_user==null)?(null):(' '+this.state.data_user.name+', '+this.state.data_user.email)}<br /> {(this.state.data_user==null)?(null):(' '+this.state.data_user.pais)}</p>
                	</Row>
                	</Col>
                	<Col md={1} style={{padding:0}}>
                	{(this.state.data_user==null)?(null):(<img alt={String(this.state.data_user.pais).toLocaleLowerCase()+" icon"} srcset={"https://img.icons8.com/office/2x/"+String(this.state.data_user.pais).toLocaleLowerCase()+".png 2x"} />)}
                	</Col>
                </Row>
                </CardBody>
              </Card>
            </Col>
		<section className="elementor-section elementor-top-section elementor-element elementor-element-ddc63bc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddc63bc" data-element_type="section">
		  <div className="elementor-container elementor-column-gap-default">
		    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70ec96d" data-id="70ec96d" data-element_type="column">
		      <div className="elementor-widget-wrap">
		  	  </div>
		    </div>
		  </div>
	    </section>

    			<section style={{backgroundColor: 'white'}} className="elementor-section elementor-top-section elementor-element elementor-element-9f9c7a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9f9c7a0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"><div className="elementor-container elementor-column-gap-extended">
          		<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4d21c55" data-id="4d21c55" data-element_type="column">
      			<div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-1a59632 elementor-widget elementor-widget-eael-adv-tabs" data-id="1a59632" data-element_type="widget" data-widget_type="eael-adv-tabs.default">
        		<div className="elementor-widget-container">
              	<div id="eael-advance-tabs-1a59632" className="eael-advance-tabs eael-tabs-horizontal" data-tabid="1a59632">
            	<div className="eael-tabs-nav">
                <ul className="eael-tab-top-icon">
	                {/*<li onClick={()=>{this.pushSection(SectionTraza)}} id="vegefru" className="eael-tab-item-trigger active" aria-selected="false" data-tab="1" role="tab" aria-controls="vegefru-tab" aria-expanded="false">
		                <i className="fas fa-folder-open"></i>                                                            
		                <span className="eael-tab-title title-after-icon">Trazabilidad Info</span>
	                </li>*/}
	            
	            <Col id="vegefru" className='text-center' aria-selected="false" data-tab="1" role="tab" aria-controls="vegefru-tab" aria-expanded="false">
	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionTraza)}} className="eael-tab-item-trigger">
	                <i className="fas fa-folder-open"></i>
	                </li>
	                <Col>
	                	<i style={{fontSize: '24px',color: this.state.statusTrazaColor}} className={this.state.statusTrazaClass}></i>
	                </Col>
	                <span className="eael-tab-title title-after-icon">Trazabilidad</span>
                </Col>

	            <Col id="lote" onClick={()=>{this.pushSection(SectionLote)}} className='text-center' aria-selected="false" data-tab="2" role="tab"  aria-controls="lote-tab" aria-expanded="false">
	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionLote)}} className="eael-tab-item-trigger">
	                <i className="fas fa-folder-open"></i>
	                </li>
	                <Col>
	                	<i style={{fontSize: '24px',color: this.state.statusLoteColor}} className={this.state.statusLoteClass}></i>
	                </Col>
		                <span className="eael-tab-title title-after-icon">Lote</span>
                </Col>

{/*	                <li id="lote" onClick={()=>{this.pushSection(SectionLote)}} className="eael-tab-item-trigger inactive" aria-selected="false" data-tab="2" role="tab"  aria-controls="lote-tab" aria-expanded="false">
		                <i className="fas fa-boxes"></i>                                                            
		                <span className="eael-tab-title title-after-icon">Lote</span>
	                </li>*/}

                <Col id="compartiment" aria-selected="false" data-tab="2" role="tab"  aria-controls="compartiment-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionCompartiment)}} className="eael-tab-item-trigger">
	                <i className="fas fa-network-wired"></i>
	                </li>

	                <Col>
	                	<i style={{fontSize: '24px',color: this.state.statusAnimalColor}} className={this.state.statusAnimalClass}></i>
	                </Col>

	                <span className="eael-tab-title title-after-icon">Compartimento</span>
                </Col>
{/*	                <li id="compartiment" onClick={()=>{this.pushSection(SectionCompartiment)}} className="eael-tab-item-trigger inactive" aria-selected="false" data-tab="3" role="tab"  aria-controls="compartiment-tab" aria-expanded="false">
		                <i className="fas fa-network-wired"></i>                                                            
		                <span className="eael-tab-title title-after-icon">Compartimento</span>
	                </li>*/}

                <Col id="fridge" aria-selected="false" data-tab="2" role="tab"  aria-controls="fridge-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionFridge)}} className="eael-tab-item-trigger">
	                <i className="fas fa-snowflake"></i>
	                </li>

	                <Col>
	                	<i style={{fontSize: '24px',color: this.state.statusFridgeColor}} className={this.state.statusFridgeClass}></i>
	                </Col>

	                <span className="eael-tab-title title-after-icon">Frigorifico</span>
                </Col>
{/*	                <li id="fridge" onClick={()=>{this.pushSection(SectionFridge)}} className="eael-tab-item-trigger inactive" aria-selected="false" data-tab="4" role="tab"  aria-controls="fridge-tab" aria-expanded="false">
		                <i className="fas fa-snowflake"></i>                                                            
		                <span className="eael-tab-title title-after-icon">Frigorifico</span>
	                </li>*/}

                <Col id="logistic" aria-selected="false" data-tab="2" role="tab"  aria-controls="logistic-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionLogistic)}} className="eael-tab-item-trigger">
	                <i className="fas fa-truck"></i>
	                </li>

	                <Col>
	                	<i style={{fontSize: '24px',color: this.state.statusLogisticColor}} className={this.state.statusLogisticClass}></i>
	                </Col>

	                <span className="eael-tab-title title-after-icon">Logistica</span>
                </Col>
{/*	                <li id="logistic" onClick={()=>{this.pushSection(SectionLogistic)}} className="eael-tab-item-trigger inactive" aria-selected="false" data-tab="5" role="tab"  aria-controls="logistic-tab" aria-expanded="false">
		                <i className="fas fa-truck"></i>                                                            
		                <span className="eael-tab-title title-after-icon">Logistica</span>
	                </li>*/}

                <Col id="documentos" onClick={()=>{this.pushSection(SectionDocuments)}} className='text-center' aria-selected="false" data-tab="6" role="tab"  aria-controls="documentos-tab" aria-expanded="false">
	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionDocuments)}} className="eael-tab-item-trigger">
	                <i className="fas fa-truck"></i>
	                </li>

	                <Col>
	                	<i style={{fontSize: '24px',color: this.state.statusTrazaColor}} className={this.state.statusTrazaClass}></i>
	                </Col>
		            <span className="eael-tab-title title-after-icon">Documentos</span>
                </Col>
{/*	                <li id="documentos" onClick={()=>{this.pushSection(SectionDocuments)}} className="inactive eael-tab-item-trigger" aria-selected="false" data-tab="6" role="tab"  aria-controls="documentos-tab" aria-expanded="false">
		                <i className="fas fa-clipboard-check"></i>                                                            
		                <span className="eael-tab-title title-after-icon">Documentos</span>
					</li>*/}

                </ul>
                </div>
            {< this.state.components DataRow={this.state} />}
               	{/*<SectionTraza DataRow={this.state} />
            	<SectionLote DataRow={this.state} />
            	<SectionCosecha DataRow={this.state}/>
                <SectionTransport DataRow={this.state}/>
                <SectionPlanta DataRow={this.state}/>
                <SectionEmbarque DataRow={this.state}/>
                <SectionDocuments DataRow={this.state}/>
                <SectionCommerce DataRow={this.state}/>

            
            
                    <div id="consumidor-tab" className="clearfix eael-tab-content-item inactive" data-title-link="consumidor-tab">
                                  <h4 ><a href="http://www.mercadeotrazable.com"><span >Mercadeo Trazable</span></a></h4><p><a href="http://www.mercadeotrazable.com"><img loading="lazy" className="size-medium wp-image-5636 aligncenter" src="https://www.food-chain.site/wp-content/uploads/2021/09/mappanama-300x127.jpg" alt="" width="300" height="127" srcset="https://www.food-chain.site/wp-content/uploads/2021/09/mappanama-300x127.jpg 300w, https://www.food-chain.site/wp-content/uploads/2021/09/mappanama-768x324.jpg 768w, https://www.food-chain.site/wp-content/uploads/2021/09/mappanama.jpg 1004w" sizes="(max-width: 300px) 100vw, 300px" /></a></p>                                   
                                  </div>
               */}
        </div>
    </div>
        </div>
          </div>
    </div>
              </div>
    </section>

    </div>
    </>
  );
 }
}

