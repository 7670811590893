import React, {useState} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import jQuery from 'jquery';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import StartMeats from "./FinalTraza/MeatsTrazabilidad";
import AnimalDetails from "./FinalTraza/AnimalDetails";
import ModalStartLogistics from "./FinalTraza/LogisticaTransporte";
import Compartiment from "./FinalTraza/CompartimentFinal";
import Frigorifico from "./FinalTraza/FrigorificoFinal";
import Slaughterhouse from "./FinalTraza/SlaughterhouseFinal";

import { generate_trazabilidad_meat, get_data_row_admin_meats, get_permission } from "network/ApiAxios";
import { generateChainMeats } from "network/GenerateTokenChainMeats";
import NotificationAlert from "react-notification-alert";
import { NavLink } from "react-router-dom";
import $ from 'jquery'
import QRCode from "react-qr-code";
import Web3 from 'web3';
import './style.css';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

var web3 = new Web3('https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/')
var tituloGlobal=[];
export  class FinalTraceabilityMeats extends React.Component {
  state={
    collapse:[true,true,true,true,true,true,true,true,true],
    notificationAlertRef: React.createRef(),
    displayCard:"none",
    dataQr:"Sin datos",
    loading:'Cargando...',
    collapsefuve:true,
    idTraza:null,
    codigoTrza:null,
    routeget: new URLSearchParams(this.props.location.search).get("code"),
    routegetlote: new URLSearchParams(this.props.location.search).get("lote"),
    routegetTwo: new URLSearchParams(window.location.search).get("up"),
    loadingupload:'none',
    btnend:'block',
    'createtraceabilitymeat':'none',
    fordisplay:{
    ['loteinventario']:'none',
    ['logisticstransportfrom']:'none',
    ['logisticstransportexport']:'none',
    ['individualanimalweight']:'none',
    ['individualanimalvaccines']:'none',
    ['individualanimaltask']:'none',
    ['individualanimalmoreinfo']:'none',
    ['individualanimalmedicine']:'none',
    ['individualanimalfattening']:'none',
    ['individualanimalaretelost']:'none',
    ['individualanimalaftosa']:'none',
    ['fridgeproductask']:'none',
    ['fridgeproducreceived']:'none',
    ['fridgeinfofridge']:'none',
    ['createanimalmeat']:'none',
    ['compartmentreceiptanimal']:'none',
    ['slaughterhouseexplodedrecord']:'none',
    ['slaughterhousefreezing']:'none',
    ['slaughterhousepacking']:'none',
    },
    walletData:null,
    openModal:false,
    disabledbtn:false,
  }
  async componentDidMount(){
    //console.log(this.props.history.push("/admin/vista-blockchain"))
   tituloGlobal = [
     {Titulos:'Inventario Lote',fordisplay: 'createanimalmeat', Componente:<AnimalDetails paramsCode={this.state.routeget} paramsCodLote={this.state.routegetlote} />},
     {Titulos:'Logistica y Transporte: Datos',fordisplay: 'logisticstransportfrom', Componente:<ModalStartLogistics paramsCode={this.state.routeget} paramsCodLote={this.state.routegetlote} />},
     {Titulos:'Compartimento',fordisplay: 'compartmentreceiptanimal', Componente:<Compartiment paramsCode={this.state.routeget} paramsCodLote={this.state.routegetlote} />},
     {Titulos:'Frigorifico',fordisplay: 'fridgeinfofridge', Componente:<Frigorifico paramsCode={this.state.routeget} paramsCodLote={this.state.routegetlote} />},
     {Titulos:'Matadero',fordisplay: 'slaughterhouseexplodedrecord', Componente:<Slaughterhouse paramsCode={this.state.routeget} paramsCodLote={this.state.routegetlote} />},

    ];
   /*
    */
    //const [searchParams, setSearchParams] = useSearchParams();
    //var route = new URLSearchParams(this.props.location.search).get("code");
    /*var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var data = await get_traza(jsondata._id);
    console.log(data)
    if (data.data=="Sin trazabilidad") {
      this.setState({
        loading:'Sin trazabilidad.'
      })
    }else{
      this.setState({
        displayCard:"flex",
        dataQr:"http://foodchaintrazabilidad.com/codigo=123",
        loading:null
      })
    }
    */
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);

    var walletdata = localStorage.getItem('user_wallet');
    var jsondatawallet = JSON.parse(walletdata);
    this.setState({walletData:jsondatawallet})

    var status='';
    if (this.state.routeget==null || this.state.routeget==undefined) {
     status='desarrollo';
    }else{
     status='desarrollo';
    }
    var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
      var dataRow = await get_data_row_admin_meats(jsondata._id,this.state.routeget,status,codigoTrazaTwo)
        this.setState({createtraceabilitymeat:(dataRow.create_traceability_meat==0)?'none':'flex'})
        this.setState({fordisplay:{...this.state.fordisplay,['loteinventario']:(dataRow.lote_inventario=0)?'none':'flex'} })
        this.setState({fordisplay:{...this.state.fordisplay,['logisticstransportfrom']:(dataRow.logistics_transport_from==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['logisticstransportexport']:(dataRow.logistics_transport_export==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimalweight']:(dataRow.individual_animal_weight==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimalvaccines']:(dataRow.individual_animal_vaccines==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimaltask']:(dataRow.individual_animal_task==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimalmoreinfo']:(dataRow.individual_animal_moreinfo==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimalmedicine']:(dataRow.individual_animal_medicine==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimalfattening']:(dataRow.individual_animal_fattening==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimalaretelost']:(dataRow.individual_animal_arete_lost==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['individualanimalaftosa']:(dataRow.individual_animal_aftosa==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['fridgeproductask']:(dataRow.fridge_produc_task==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['fridgeproducreceived']:(dataRow.fridge_produc_received==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['fridgeinfofridge']:(dataRow.fridge_info_fridge==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['createanimalmeat']:(dataRow.create_animal_meat==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['compartmentreceiptanimal']:(dataRow.compartment_receipt_animal==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['slaughterhouseexplodedrecord']:(dataRow.slaughterhouse_exploded_record==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['slaughterhouse_freezing']:(dataRow.slaughterhouse_freezing==0)?'none':'flex'}})
        this.setState({fordisplay:{...this.state.fordisplay,['slaughterhouse_packing']:(dataRow.slaughterhouse_packing==0)?'none':'flex'}})

  }
  componentWillMount(){
    tituloGlobal = []
  }
render(){
  const getRoutesComponents = () =>{

  }
  const getDataForqr = (data) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (data==null) {
      this.setState({
        loading:'Sin trazabilidad.'
      })
    }else{
      if (this.state.routeget==null || this.state.routeget==undefined) {
        var codigo_set = 'FCTM-'+jsondata._id+data.id
      }else{
        var codigo_set = data.codigo
      }
      this.setState({
        displayCard:"flex",
        dataQr:"http://foodchaintrazabilidad.com/home/vista-trace-meat?code="+codigo_set,
        loading:null,
        idTraza:data.id,
        codigoTrza:codigo_set,
      })
    }
  }
  const toggleFunctionFirst = () => {
      if (this.state.collapsefuve==true) {
        this.state.collapsefuve = false;
      }else{
        this.state.collapsefuve = true;
      }
  }
  const toggleFunction = (index,res) =>{
    if (res==undefined) {
    this.state.collapse[index] = true;
    }else{
      if (this.state.collapse[index]==true) {
        this.state.collapse[index] = false;
      }else{
        this.state.collapse[index] = true;
      }
    }
  }
  const saveInfoTraza = async() =>{
    if(window.confirm('Estas seguro en finalizar esta trazabilidad?')){
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var result = await generate_trazabilidad_meat(jsondata._id,this.state.idTraza,this.state.codigoTrza);

        if (result.data.message=="exito") {
          notify("success", "¡Registrado exitosamente!", 'Trazabilidad finalizada.');
              var histor = this.props
              setTimeout(function(){histor.history.push('/admin/process-trace')}, 3000);
        }else{
          notify("danger", "¡Registrado Fallido!", result.data);
          this.setState({btnd:false})
        }
    }
  }
  const saveInfoTrazaQr =  async () =>{
      
      if (this.state.walletData==null) {
        this.props.history.push("/admin/wallet")
    }else{
      if (this.state.walletData.crypto!=null) {
        optionModal(true)
      }else{
       if(window.confirm('Estas seguro en finalizar esta trazabilidad?')){
         jQuery("#loadingupload").show();
         jQuery(".btnend").hide()
         var userdata = localStorage.getItem('user');
         var jsondata = JSON.parse(userdata);
         var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
         var data = await generateChainMeats(jsondata._id,this.state.routeget,this.props.history,this.state.idTraza,codigoTrazaTwo,this.state.walletData)
       }
      }
    }
  }
    const optionModal = (type) => {
    this.setState({openModal:type})
  };
  const accessAccount = (password) => {
    this.setState({disabledbtn:true})
    try{
        this.setState({disabledbtn:false})
        var privateWalletEncrip = web3.eth.accounts.decrypt(this.state.walletData,password)
      console.log(privateWalletEncrip)
        this.setState({walletData:privateWalletEncrip})
        optionModal(false)
        notify("success", "Cambio exitoso", "Cuenta desbloqueada.");
    }catch(error){
        notify("danger", "No se pudo guardar", "La contraseña es incorrecta.");
        this.setState({disabledbtn:false})
    }


  };
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
    <ModalEditUserWallet
      sendOpenModal={this.state.openModal}
      setModal={optionModal}
      saveEncrypt={accessAccount} 
      disabledextend={this.state.disabledbtn} />
      <div className="rna-wrapper">
              <NotificationAlert ref={this.state.notificationAlertRef} />
            </div>
            {(this.state.routegetTwo=='uploadblock')?(null):(
            <MeatsNavBar getParams={this.props.location.search}/>  
            )}
            <SimpleHeader name="Vista trace" parentName="Finalizar Trazabilidad" />
            <Container className="mt--6" fluid>
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">Vista De Verificación y Confirmación para Finalizar</h3>
                </CardHeader>
              </Card>
<div className="contentBlockChain">
                <Card className="mb-4" style={{display:this.state.createtraceabilitymeat}}>
                        <CardHeader id={"sectionfuve"}>
                          <a style={{color: "#32325d",fontWeight:"600"}} href={"#sectionfuve"} onClick={() => {toggleFunctionFirst(this.state.collapsefuve)}} className="mb-0">Trazabilidad Carnico</a>
                        </CardHeader>
                          <Collapse isOpen={this.state.collapsefuve}>
                              <CardBody className="text-center">
                              <StartMeats getData={getDataForqr} paramsCode={this.state.routeget}/>
                              </CardBody>
                          </Collapse>
                      </Card>
              { tituloGlobal.map((value,index)=>{
              return (
                <Card className="mb-4" style={{display: this.state.fordisplay[value.fordisplay]}}>
                        <CardHeader id={"section"+index}>
                          <a style={{color: "#32325d",fontWeight:"600"}} href={"#section"+index} onClick={() => {toggleFunction(index,this.state.collapse[index])}} className="mb-0">{value.Titulos}</a>
                        </CardHeader>
                          <Collapse isOpen={this.state.collapse[index]}>
                              <CardBody className="text-center">
                              {value.Componente}
                              </CardBody>
                          </Collapse>
                      </Card>
      
                       )  
                     })
                    }
                    {(this.state.loading==null)?(null):(
                      <Card className="mb-4">
                        <CardHeader id={"sectionnull"}>
                          <a style={{color: "#32325d",fontWeight:"600"}} href={"#sectionnull"} className="mb-0">{this.state.loading}</a>
                        </CardHeader>
                      </Card>
                      )}
            </div>
                     <Card className="mb-4" style={{display:this.state.displayCard}}>
                      {(this.state.createtraceabilitymeat == 'none')?(null):(<>
                    <CardBody className="text-center">
                     <CardHeader className="text-center">
                     <QRCode value={this.state.dataQr} />
                     </CardHeader>
                     <div className="text-center" id='loadingupload' style={{display:this.state.loadingupload}}>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                     </div>
                            <div className="btnend" style={{display:this.state.btnend}}>
                                        {(this.state.routegetTwo=='uploadblock')?(null):(
                                                       <Button 
                                                         className="btn-icon" 
                                                         color="traza-green" 
                                                         type="button" 
                                                         disabled={this.state.btnd}
                                                         onClick={saveInfoTraza}>
                                                         <span className="btn-inner--icon mr-1">
                                                           <i className="fas fa-check-circle" />
                                                         </span>
                                                         <span className="btn-inner--text">Finalizar esta Trazabilidad</span>
                                                       </Button>
                                        )}
                                                       <Button 
                                                         className="btn-icon" 
                                                         color="traza-blue" 
                                                         type="button" 
                                                         disabled={this.state.btnd}
                                                         onClick={saveInfoTrazaQr}>
                                                         <span className="btn-inner--icon mr-1">
                                                           <i className="fas fa-check-circle" />
                                                         </span>
                                                        {(this.state.routegetTwo=='uploadblock')?(<span className="btn-inner--text">Guardar esta trazabilidad en la Blockchain</span>):(
                                                         <span className="btn-inner--text">Finalizar y Guardar esta trazabilidad en la Blockchain</span>
                                                        )}
                                                        </Button>
                            </div>
                                                        </CardBody>
                                                        </>)} 
                      </Card>
      
            </Container>
    </>
  );
 }
}

export class ModalEditUserWallet extends React.Component {
  state={
    password:''
      }
render(){
  return (
  <Modal isOpen={this.props.sendOpenModal} size="md">
        <ModalBody>
          <CardHeader>
            <Row>
            <Col md={11}>
            <h3 className="mb-0">Desbloquear Cuenta</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {this.props.setModal(false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>

        <Card className="mb-4">
                <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                    placeholder="Escribe una contraseña para encryptar"
                    type="password"
                  />
                </FormGroup>
              </Col>
          <CardBody>
            <Row>
              <Col md="5">
                <Button onClick={()=>{this.props.saveEncrypt(this.state.password)}} disabled={this.props.disabledextend} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Aceptar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.props.setModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    if (dataPermission.type_traza=='VF' || dataPermission.type_traza==null) {
      this.setState({get_permission:false})
      return;
    }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.trazabilidad_m==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }   
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <FinalTraceabilityMeats {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;