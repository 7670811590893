import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'
const datacontent = [
{title:'Datos Cosecha',table:'create_harvest_kdes',icon:'far fa-lemon','multiple_tables':0},
//{title:'Datos Cosecha',table:'create_harvest_kdes',title_one:'Cosecha',table_one:'create_harvest_kdes',icon:'fa fa-newspaper','multiple_tables':1},
]

export default class SectionCosecha extends React.Component {
  render(){
  return (
    <>
<ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
    </>
  );
}
}

//export default Elements;
