import React, {createRef, useEffect,useState} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_traza_animal_delete, register_lote, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import jQuery from "jquery";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class InventoryAnimal extends React.Component {
  state={
    data_traza_inventory:[],
    trazabilidad_meat:[],
    btnForSend:'none',
    sendOpenModalLote:false,
    codigo:null,
  }
 async componentDidMount(){
  this.initData()
  }
  initData = async () => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = new URLSearchParams(this.props.location.search).get("code");
      var dataAnimal = await row_traza_animal_delete(jsondata._id,gerParam)
      this.setState({
        codigo:gerParam,
        data_traza_inventory:dataAnimal.trazaDataAnimal,
        data_lote_count: dataAnimal.countLote + 1
      })
  }
render(){
  const checkSendLote = () =>{
    var _check = jQuery(".lote_check_send:checked").length;
    if (_check > 0) {
      this.setState({btnForSend:'block'})
    }else{
      this.setState({btnForSend:'none'})
    }
  }
  const sendLote = async (lote_numero) =>{
   if (window.confirm('Esta seguro con lo seleccionado?')) {
      jQuery(".btnDisabled").prop("disabled",true)
      var getValue = [];
      var documentTag = document.querySelectorAll(".lote_check_send:checked");
      if (documentTag.length==0) {
        alert('Seleccione un animal')
      }else{
      for (var i = 0; i < documentTag.length; i++) {
          getValue[i] = documentTag[i].value
        }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");

        var data = await register_lote(getValue.filter(Boolean),jsondata._id,gerParam,lote_numero)
        if (data=="exito") {
          openModalLote(false)
          checkAll(false)
          this.initData()
        }else{
          jQuery(".btnDisabled").removeAttr("disabled")
          alert('No se pudo actualizar')
        }
      }
    }
  }
  const checkAll = (type) =>{
    var checked;
    if (type==undefined) {
      checked = jQuery(".check_all").prop("checked");
    }else{
      checked = type
    }
    jQuery(".lote_check_send").prop("checked",checked);

    checkSendLote()
  }
  const openModalLote = (open) =>{
    this.setState({sendOpenModalLote:open})
  }
  const changeLoteVal = (values) =>{
    this.setState({data_lote_count:values})
  }

  return (
    <>
    <MeatsNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Inventario" parentName="Inventario" />
        <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="12">
                <h3 className="mb-0">Inventario de Animales Eliminados</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Foodchain ID</th>
                <th>Fecha registro</th>
                <th>Animal arete (ID)</th>
                <th>Lote Número</th>
                <th>Peso</th>
                <th>Fecha de eliminación</th>
                <th>Motivo</th>
                <th>Lugar de siniestro</th>
              </tr>
            </thead>
            <tbody>
            {this.state.data_traza_inventory.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                                      <ComponentLink
                                        className="font-weight-bold"
                                                              to={{
                                          pathname:"/admin/meats/details-animal",
                                          search: "?code="+this.state.codigo+"&id_animal="+value.id+'&lote='+value.type_lote,
                                          state:{
                                            codigoTraza:this.state.codigo
                                          }
                                          }}
                                        >
                                       Ver animal {value.id}
                                      </ComponentLink>

                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.animal_id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.type_lote}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.peso_inicial}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.fecha_muerte}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.motivo}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {(value.lugar_siniestro=="2")?('Sin lugar'):(value.lugar_siniestro)}
                    </span>
                   </td>
{/*                   <td>
                      <Col md="12">
                        <Input style={{width: '100%',margin: 0}} className="lote_check_send" name="lote_check_send" onClick={()=>{checkSendLote()}} type="checkbox" value={value.id}/>
                      </Col>
                   </td>
                   <td>
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/meats/details-animal",
                        search: "?code="+value.codigo+"&id_animal="+value.id,
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}
                      >
                     Editar
                     </ComponentLink>
                   </td>*/}
                 </tr>)
            })
          }

            </tbody>
          </Table>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}
export function ModalLote(props){
  const [value,setValue] = useState()
  const setInfoLote = () => {
    var numero_lote = jQuery("#lote_numero").val()
    props.setDataLote(numero_lote)
  }
  const changeLoteVal = (valor) => {
    props.changeValLote(valor)
    //setValue(valor)
  }
  useEffect(()=>{
    setValue(props.dataCountLote)
  });
return(
    <Modal isOpen={props.isOpenModal} size="md">
        <ModalBody>
          <Col md={12}>
          <label style={{fontWeight: '700'}}>Lote Número</label>
            <Input style={{width: '100%',margin: 0}} id="lote_numero" name="lote_numero" onChange={(e)=>{changeLoteVal(e.target.value)}} type="text" value={value}/>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button className="btnDisabled" onClick={()=> {setInfoLote()} }>
            Guardar
          </Button>
          <Button className="btnDisabled" onClick={()=> {props.changeEventModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
  )
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    // var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
          var dataPermission = this.context.valueGlobal
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
this.setInfoInit()  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <InventoryAnimal {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
