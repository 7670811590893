import React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";
import { NavLink as ComponentLink } from "react-router-dom";
import { data_obv_vr, save_report_mrv_status } from "network/ApiAxios";
import {GuideMessage} from "views/pages/components/GuideMessage";
import {UserContext} from "layouts/store";
import jQuery from 'jquery';
class DetailsObservationsRev extends React.Component {
  static contextType = UserContext
  state = {
    data_information:[],
    user_type:null,
    collapse:{['collapse'+0]:false}
  }
  async componentDidMount(){
    //var userdata = localStorage.getItem('user');
    //var jsondata = JSON.parse(userdata);
    this.putData()
  }
  componentDidUpdate(prevS,prevP){
    if (prevS.thisStates.set_type_content_onre!=this.props.thisStates.set_type_content_onre) {
      this.putData()
    }
  }
  putData = async (data,ind_two)=>{
    var type_user_p=null
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
    if (this.props.thisStates.data_user!=null) {
     type_user_p = jsondata.type_traza
    }
    var get_data_obv = await data_obv_vr(this.props.thisStates.set_type_content_onre,this.props.thisStates.code_prod,this.props.thisStates.db_prod,type_user_p)
     var get_data = get_data_obv.data
     if (get_data!='') {
       this.setState({data_information:get_data})
     }
  }
  putContentInformation =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = <><Col md={12} style={{color: 'black'}}>{a}</Col></>
    })
    return data_content
  }
  putContentInformationPrio =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = <><Col md={12} style={{color: 'black',borderRadius: '3px',textAlign: 'center', margin: '1px', backgroundColor: a.split('-')[1]}}>{a.split('-')[0]}</Col></>
    })
    return data_content
  }
  putContentInformationStatus =(data,ind_two,ind_three,ind_general)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = 
      <>
        <Col md={12} style={{color: 'black',borderRadius: '3px', margin: '1px', backgroundColor: a.split('-')[1]}}>
          {a.split('-')[0]}
        </Col>
      </>
    })
    return data_content
  }
  changeStatusObs= async (data,indx,ind_three,ind_general)=>{

    if (window.confirm('Estas Seguro en Cambiar el Estado?')) {
      let items = JSON.parse(this.state.data_information[ind_general]['data_adjunt'])
      let item = items[ind_three]
      var change_status = {...item.datastate.state_status,[indx]:'Revisar'}
      item.datastate.state_status = change_status
      items[ind_three] = item;

      let itemsTwo = this.state.data_information;
      let itemTwo = itemsTwo[ind_general];
      itemTwo.data_adjunt = JSON.stringify(items)
      itemsTwo[ind_general]=itemTwo
      await save_report_mrv_status(JSON.stringify(items),itemTwo.id,itemTwo.mvr_id)
      this.putData()
      //this.setState({data_information:itemsTwo})
    }
  }
  changeDetail=async(table_params,title_params)=>{
      if (table_params=='info_producer_g' || table_params=='info_crops_g' || table_params=='info_prev_g' || table_params=='info_financing_g') {
        await jQuery('#infprod_c').click()
        await jQuery('#'+table_params).click()
      }
      if (table_params=='production_land_preparation_g' ||
        table_params=='production_irrigation_g' ||
        table_params=='production_seed_g' ||
        table_params=='production_fertilizer_g' ||
        table_params=='production_sowing_g' ||
        table_params=='production_sowing_transplant' ||
        table_params=='production_producer_monitoring_g'){
        await jQuery('#produccion_c').click()
        await jQuery('#'+table_params).click()
      }
      if (table_params=='hading_fert_agro' ||
      table_params=='hading_info_weeds' ||
      table_params=='hading_inventory_weeds' ||
      table_params=='hading_info_plague' ||
      table_params=='hading_inventory_plague' ||
      table_params=='hading_info_diseases'){
        await jQuery('#hadinf_c').click()
        await jQuery('#'+table_params).click()
      }
      if (table_params=='harvest_info_harvest' ||
      table_params=='harvest_info_machinery' ||
      table_params=='harvest_info_transport' ||
      table_params=='harvest_info_pos_harvest'){
        await jQuery('#harvinf_c').click()
        await jQuery('#'+table_params).click()
      }
      var get_title = title_params?.split('-')
      if (get_title!=undefined) {      
        var title_trim = jQuery.trim(get_title[get_title.length-1].replaceAll(' ','').replaceAll('*',''))
        await jQuery('.'+title_trim).click()
      }
  }
  getContetnTwo =(data,ind_two,ind_general,codigo)=>{
                                       var data_content_td=[]
                                       var data_content_tr=[]
                                       var data_content_activity=[]
                                       var order_data = Object.entries(data).sort()
                                               order_data.map((valdata,inddata)=>{
                                                if (valdata[0]=='activity') {
                                                  data_content_activity=valdata[1][0]
                                                }
                                              if (valdata[0]!='activity') {
                                                if (valdata[0]=='init_date') {
                                                  data_content_td[0]=<>
                                                    <td style={{fontSize: '12px',padding: 0,width: '8%'}}>
                                                      {this.putContentInformation(valdata[1],inddata)}
                                                    </td>
                                                  </>
                                                }
                                                data_content_td[1]=<><td style={{fontSize: '12px',padding: 0,width: '7%',color:'black'}}>{codigo}</td></>
                                                if (valdata[0]=='observation') {
                                                  data_content_td[2]=<><td style={{fontSize: '12px',padding: 0,width: '20%'}}>{this.putContentInformation(valdata[1],inddata)}</td></>
                                                }
                                               if (valdata[0]=='recommendation') {
                                                  data_content_td[3]=<><td style={{fontSize: '12px',padding: 0,width: '20%'}}>{this.putContentInformation(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='priority') {
                                                 data_content_td[4]=<><td style={{fontSize: '12px',padding: 0,width: '13%'}}>{this.putContentInformationPrio(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='state_status') {
                                                 data_content_td[5]=<><td style={{fontSize: '12px',padding: 0,width: '20%'}}>{this.putContentInformationStatus(valdata[1],inddata,ind_two,ind_general)}</td></>
                                               }
                                               if (valdata[0]=='activity_table') {
                                                data_content_td[6]=<><td style={{padding: 0}}>
                                                {(window.location.pathname.indexOf('detail_monitoring')!=-1)?(
                                                  <button className="btn" onClick={()=>{this.changeDetail(valdata[1][0],data_content_activity)}}>
                                                    Ver esta actividad
                                                  </button>
                                                  ):(
                                                  <ComponentLink
                                                      className="font-weight-bold"
                                                                            to={{
                                                        pathname:"detail_monitoring",
                                                        search: "?db="+this.props.thisStates.db_prod+"&code="+this.props.thisStates.code_prod,
                                                        state:{
                                                          table_params:valdata[1][0],
                                                          title_params:data_content_activity
                                                        }
                                                        }}
                                                      >
                                                      Ver esta actividad
                                                     </ComponentLink>
                                                  )}
                                                </td></>
                                               }
                                               // if (valdata[0]=='state_status') {
                                               //   data_content_td[5]=<><td style={{padding: 0}}>{this.putContentInformationStatus(valdata[1],inddata,ind_two,ind_general)}</td></>
                                               // }
                                               data_content_tr=<tr>{data_content_td}</tr>
                                              }
                                              })
                                              return (data_content_tr)
  }
  setCounterObs =(data)=>{
var get_data_pr = []
  Object.values(data).map((val,ind)=>{
    get_data_pr[ind] = Object.keys(val).length
  })
    var get_numb_max = Math.max(...get_data_pr)
    return get_numb_max
  }

  getContetnHeader =(data,ind_two)=>{
                                      var data_content=[]
                                      var order_data = Object.entries(data).sort()
                                              order_data.map((valdata,inddata)=>{
                                                if (valdata[0]!='activity') {
                                                  if (valdata[0]=='observation') {
                                                  data_content[inddata+ind_two] = <>
                                                      <label className="form-control-label m-0" style={{color: 'black'}}> - Observaciones: {this.setCounterObs(data)}</label>
                                                    </>
                                                  }
                                                }
                                              })
                                                return (data_content)
  }
  toggleFunction = (index,res) =>{
    this.setState({collapse:{[index]:!this.state.collapse[index]}})
  }
sendToActivity=(data,code)=>{
if(data.split('*')[0]=='info_producer_g'){
  this.props.history.push('info_producer_g?code='+code)
}
if(data.split('*')[0]=='info_crops_g'){
  this.props.history.push('info_crops_g?code='+code)
}
if(data.split('*')[0]=='info_financing_g'){
  this.props.history.push('info_financing_g?code='+code)
}
if(data.split('*')[0]=='production_land_preparation_g'){
  this.props.history.push('production_land_preparation_g?code='+code)
}
if(data.split('*')[0]=='production_irrigation_g'){
  this.props.history.push('production_irrigation_g?code='+code)
}
if(data.split('*')[0]=='production_seed_g'){
  this.props.history.push('production_seed_g?code='+code)
}
if(data.split('*')[0]=='production_fertilizer_g'){
  this.props.history.push('production_fertilizer_g?code='+code)
}
if(data.split('*')[0]=='production_sowing_g'){
  this.props.history.push('production_sowing_g?code='+code)
}
if(data.split('*')[0]=='production_sowing_transplant'){
  this.props.history.push('production_sowing_transplant?code='+code)
}
if(data.split('*')[0]=='production_producer_monitoring_g'){
  this.props.history.push('production_producer_monitoring_g?code='+code)
}
if(data.split('*')[0]=='production_info_satelite'){
  this.props.history.push('production_info_satelite?code='+code)
}
if(data.split('*')[0]=='hading_fert_agro'){
  this.props.history.push('hading_fert_agro?code='+code)
}
if(data.split('*')[0]=='hading_info_weeds'){
  this.props.history.push('hading_info_weeds?code='+code)
}
if(data.split('*')[0]=='hading_inventory_weeds'){
  this.props.history.push('hading_inventory_weeds?code='+code)
}
if(data.split('*')[0]=='hading_info_plague'){
  this.props.history.push('hading_info_plague?code='+code)
}
if(data.split('*')[0]=='hading_inventory_plague'){
  this.props.history.push('hading_inventory_plague?code='+code)
}
if(data.split('*')[0]=='hading_info_diseases'){
  this.props.history.push('hading_info_diseases?code='+code)
}
if(data.split('*')[0]=='harvest_info_harvest'){
  this.props.history.push('harvest_info_harvest?code='+code)
}
if(data.split('*')[0]=='harvest_info_machinery'){
  this.props.history.push('harvest_info_machinery?code='+code)
}
if(data.split('*')[0]=='harvest_info_transport'){
  this.props.history.push('harvest_info_transport?code='+code)
}
if(data.split('*')[0]=='harvest_info_pos_harvest'){
  this.props.history.push('harvest_info_pos_harvest?code='+code)
}
}
render(){
    var userdata = this.context.valueGlobal;
  return (
    <>

                  <div className="card" role="alert" >
                  {
                    this.state.data_information.map((val,indx)=>{
                      return (<>
                                <Col md={12}>
                                  {(val.monitor_type=='ON')?('Monitoreo'):(null)}
                                  {(val.monitor_type=='RE')?('Revisión'):(null)}
                                  {(val.monitor_type=='VA')?('Validación'):(null)}
                                </Col>
                                {JSON.parse(val.data_adjunt).map((valadjun,ind)=>{
                                    return (<>
                                                  <Col md={12} className="card mb-2" style={{padding: '1px',backgroundColor: 'white', textAlign:'center'}}>
                                                      <label className="form-control-label m-0" style={{color: 'black'}}>Titulo/Actividad: {' '}
                                                      {(valadjun.value!='general')?(valadjun.name):((valadjun.datastate.activity==undefined)?(null):(valadjun.datastate.activity[0]))}
                                                      </label>
                                                      <Col md={12}>
                                                        <Row>
                                                        <table className="align-items-center table-bordered" style={{margin: 0,width: '100%'}} responsive>
                                                          <thead className="thead-light">
                                                            <tr>
                                                              <th style={{fontSize: '12px',padding: 0,color:'black'}}>Fecha de Inicio</th>
                                                              <th style={{fontSize: '12px',padding: 0,color:'black'}}>ID Foodchain</th>
                                                              <th style={{fontSize: '12px',padding: 0,color:'black'}}>Observaciones</th>
                                                              <th style={{fontSize: '12px',padding: 0,color:'black'}}>Recomendación</th>
                                                              <th style={{fontSize: '12px',padding: 0,color:'black'}}>Prioridad</th>
                                                              <th style={{fontSize: '12px',padding: 0,color:'black'}}>Estado de Cumplimiento</th>
                                                              <th style={{fontSize: '12px',padding: 0,color:'black'}}>Opciones</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {this.getContetnTwo(valadjun.datastate,ind,indx,val.codigo)}
                                                          </tbody>
                                                        </table>
                                                      </Row>
                                                    </Col>
                                                  </Col>

                                        </>)
                                  })
                                }
                                </>)
                    })
                  }
                  </div>
    </>
  );
 }
}

DetailsObservationsRev.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default DetailsObservationsRev;
