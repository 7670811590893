import React, { useCallback, useState, useEffect, useContext } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle, 
  Position,
  ReactFlowProvider,
  Panel,
  useReactFlow,
  ConnectionLineType,
} from 'reactflow';
 import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";
import 'reactflow/dist/style.css';
import ContentTypeProducer from './ContentTypeData'
import ContentTypeHarvest from './ContentTypeDataNext'
import {PutContentStatus} from 'views/pages/examples/Trazability/SectionsViewTrace/InfoLogistic'
import Dagre from '@dagrejs/dagre';
import ContentLogisticKdes from 'views/pages/examples/LogisticsForVistaTrace/ContentLogisticKdes'
import {put_arr_data_kdes, findCodeLoteGlobal, searhCodeLoteGlobal} from './DataConnectionsTablesKdes';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { export_excel_api } from "network/ApiAxios";
import {UserContext} from "layouts/store";
import { Button } from 'primereact/button';

const nodeTypes = { 
 producerData: ContentTypeProducer,
 harvestData: ContentTypeHarvest,
 // importData:contentTypeImport, 
 // distributorData:contentTypeDistributor, 
 // detailsData:contentTypeDetails 
};

export default function App(props) {
  const [dataProdPlant,setDataProdPlant] = useState({})


  const [dataSelectColumn,setDataSelectColumn] = useState([])
  const [dataSelectTable,setDataSelectTable] = useState([])
  const [dataSelect,setDataSelect] = useState([])
  const [dataSelected,setDataSelected] = useState('')
  const [dataToken,setDataToken] = useState('')

  const [dataProdProducer,setDataProdProducer] = useState([])
  
  const [initialNodesState,setInitialNodes] = useState([])
  const [initialNodesStateM,setInitialNodesM] = useState([])
  const [initialEdgesState,setInitialEdges] = useState([])

  const [dataArray,setDataArray] = useState(props.allDataTrza)
  const [dataArrayAll,setDataArrayAll] = useState(props.allDataTrza)
  const [selectedLote,setSelectedLote] = useState('0')
  const [expandedKeys,setExpandedKeys] = useState(null)

  const findCodeLote =(table, column, secondColumn, codeTraza, data)=>{
    const regex = new RegExp('^1_lote_', 'g');
    var getDataPlantKdes = dataArray[table].data_array.filter((a) => a[column]?.match(regex) && a.codigo==codeTraza )
    if (getDataPlantKdes[0]!=null) {
      return getDataPlantKdes[0][secondColumn]
    }else{
      return 'none'
    }
  }

    const disassembleArray = (data) => {
      return data;
    }
    const disassembleArrayTwo = (data)=>{
      var setData = []
      data.map((a,b)=>{
        if (a.length!=undefined) {
          setData.push(...a)
        }else{
          setData.push(a)
        }
      })
      return setData
    }
const setUserData = (dataArray) =>{
var dataProdVar = [[]]
if (Object.values(dataArray.fruit_vegetable_user.data_array_full).length>0) {
  dataProdVar = Object.values(dataArray.fruit_vegetable_user.data_array_full);
}else{
  if (dataArray.fruit_vegetable_user.data_array.length>0) {
  dataProdVar = [dataArray.fruit_vegetable_user.data_array];
  }
}

  //[...disassembleArrayTwo(...dataProdVar)]
  disassembleArrayTwo(dataProdVar).map((valValid,indValid)=>{
    var getDataValid = dataArray.fruit_vegetable_user.data_array.some((v)=>v.token_id==valValid.token_id && v.lote_numero==valValid.lote_numero)
    if (!getDataValid) {
      var dataArrOne=dataArray
      var dataArrTwo =  dataArrOne.fruit_vegetable_user
      dataArrTwo.data_array=[...dataArrTwo.data_array,valValid]
      dataArrOne.fruit_vegetable_user=dataArrTwo
    }
  })
}
    const filterPutLoteImport = (dataLote,fieldReceiber,field,columnChange,column,codeAsigned)=>{
      var regex = new RegExp('^1_lote_', 'g');
      if (Object.values(dataArray[fieldReceiber].data_array_full).length==0) {
        setUserData(dataArray)
      }
      dataArray[fieldReceiber].data_array_full.map((a,b)=>{
        a.map((aT,bT)=>{
          if (aT[columnChange]?.match(regex)) {
            if (aT[column]==codeAsigned) {
              setUserData(dataArray)
              //}
              var dataPlantReceiverVar = dataArray[fieldReceiber].data_array_full;
              var dataPlantReceiverVarOrigin=[...disassembleArray(...dataPlantReceiverVar)]
              dataProdPlant[fieldReceiber]={...dataPlantReceiverVarOrigin};
              var dataPlantVar = dataArray[fieldReceiber].data_array_full;
              dataArray[fieldReceiber].data_array.push(...disassembleArray(...dataPlantVar));
            }else{
              var dataProdVar = [[]]
              if (dataArray.fruit_vegetable_user.data_array_full.length>0) {
                dataProdVar = dataArray.fruit_vegetable_user.data_array_full;
              }else{
                if (dataArray.fruit_vegetable_user.data_array.length>0) {
                dataProdVar = dataArray.fruit_vegetable_user.data_array;
                }
              }
              [...disassembleArray(...dataProdVar)].map((valValid,indValid)=>{
                var getDataValid = dataArray.fruit_vegetable_user.data_array.filter((v)=>v.token_id==valValid.token_id && v.lote_numero==valValid.lote_numero)
                if (getDataValid.length!=0) {
                }
              })

            }
          }

        })
      })

    }
    const getDataVTConfig = (id_token,get_data_main,title_column,view_table,table_selected,idRow)=>{
      var put_title=''
      var dataUp=[]
      var dataDown=[]
      if (get_data_main!=null) {
        put_title=get_data_main[0][title_column]
        //Object.entries(dataArray).map((padVal,padIndx)=>{

          if (JSON.parse(get_data_main[0][view_table])!=null) {
            if (JSON.parse(get_data_main[0][view_table])[table_selected]!=undefined) {
              var data_array_first = dataArray[table_selected].data_array
              var data_array_all = dataArray[table_selected].data_array_full
              var getDataContent = []
              if (data_array_all.length!=0) {
                getDataContent = [...data_array_first,...disassembleArray(...data_array_all)].filter((a) => a.token_id==id_token && a.id==idRow);
              }else{
                getDataContent = [...data_array_first].filter((a) => a.token_id==id_token && a.id==idRow);
              }
              if (getDataContent.length!=0) {
                [getDataContent[getDataContent.length-1]].map((cVal,cInd)=>{
                  var getdatacolumnup = JSON.parse(get_data_main[0][view_table])[table_selected].map((tVal,iVal)=>{
                    if (tVal.position=='1') {
                      dataUp.push(cVal[tVal.column])
                    }
                  })
                  var getdatacolumndown = JSON.parse(get_data_main[0][view_table])[table_selected].map((tVal,iVal)=>{
                    if (tVal.position=='2') {
                      dataDown.push(cVal[tVal.column])
                    }
                  })
                })
              }
            }
          }

        //})
      }
            //console.log(dataDown,dataUp)
      //var getDataUp = dataUp.filter((a,b)=> a.number==lote_numero)
      //var getDataUp = dataDown.filter((a)=> console.log(a))
      //console.log(dataDown)
      return {title:put_title,data_up:dataUp,data_down:dataDown}
    }

    const getDataVTDetails = (id_token,title_column,view_table,idRow)=>{
      var get_data_detail=props.data_vt_config.filter((vtR)=> vtR.type_selected=='vt_data_important')
      var put_title=''
      var dataUp=[]
      var dataDown=[]
      if (get_data_detail!=null) {
        put_title=get_data_detail[0][title_column]
        Object.entries(dataArray).map((padVal,padIndx)=>{
          if (JSON.parse(get_data_detail[0][view_table])!=null) {
            if (JSON.parse(get_data_detail[0][view_table])[padVal[0]]!=undefined) {
              var data_array_first = dataArray[padVal[0]].data_array
              var data_array_all = dataArray[padVal[0]].data_array_full
              var getDataContent = []
              if (data_array_all.length!=0) {
                getDataContent = [...data_array_first,...disassembleArray(...data_array_all)].filter((a) => a.token_id==id_token && a.id==idRow);
              }else{
                getDataContent = [...data_array_first].filter((a) => a.token_id==id_token && a.id==idRow);
              }
              //var getDataContent = [...data_array_first,...disassembleArray(...data_array_all)].filter((a) => a.token_id==id_token);
              if (getDataContent.length!=0) {
                [getDataContent[getDataContent.length-1]].map((cVal,cInd)=>{
                  var getdatacolumnup = JSON.parse(get_data_detail[0][view_table])[padVal[0]].map((tVal,iVal)=>{
                      dataUp.push(cVal[tVal.column])
                  })
                })
              }
            }
          }
        })
      }
      return {data_up:dataUp}
    }
  const dataReducer =(data)=>{
    var returnData = [...new Set(data)]
    return returnData
  }
  const putTokenData = (id_token,tableArr,tableSelected) =>{
    var setTable = null
    if (typeof tableArr=='string') {
      setTable = tableArr
    }else{
      if (tableArr!=null) {
        setTable = tableSelected
      }
    }
    if (setTable!=null) {
      var data_order_columns = []
      var getDataContent = []

      var data_array_first = props.allDataTrza[setTable].data_array
      var data_array_all = props.allDataTrza[setTable].data_array_full

      dataArray[setTable].divider_title_select.split('|').map((a,b)=>{
        var get_valid_column = Object.keys(data_array_first[0]).some((col) => col==a.split('*')[0])
        if (data_array_all[0]!=undefined) {
          get_valid_column= Object.keys(data_array_all[0][0]).some((col) => col==a.split('*')[0])
        }
        if (get_valid_column) {
          data_order_columns[a.split('*')[1]] = {title: a.split('*')[2], column: a.split('*')[0]}
        }
      })
      if (data_array_all.length!=0) {
        getDataContent = dataReducer([...data_array_first,...disassembleArray(...data_array_all)].filter((a) => a.token_id==id_token));
      }else{
        getDataContent = dataReducer([...data_array_first].filter((a) => a.token_id==id_token));
      }
      
      if (typeof tableArr!='string') {
        setDataSelect(tableArr)
      }
      setDataSelected(setTable)
      setDataSelectTable(getDataContent.reverse())
      setDataSelectColumn(data_order_columns)
      setDataToken(id_token)
    }
  }
  useEffect(()=>{
    //setDataArrayAll(props.allDataTrza)
    initData()
  },[])

  const initData = ()=>{
  var regex = new RegExp('^1_lote_', 'g');

   var initialNodes = [];
   var initialEdges = [];

   var putDataLinkEdge = {}

   var get_data_main=props.data_vt_config.filter((vtR)=> vtR.type_selected=='vt_data_main')
   var release_kdes = put_arr_data_kdes.filter((fk)=> Object.keys(dataArray).join().indexOf(fk.table_receive.table)!=-1)

    release_kdes.map((data_arr,data_arr_idx)=>{
      var getDataPlantKdes = dataArray[data_arr.table_receive.table].data_array.filter((a) => a[data_arr.table_receive.change]?.match(regex) )
      var data_array_main = dataArray[data_arr.table_receive.table].data_array
      var data_array_all = dataArray[data_arr.table_receive.table].data_array_full
      var getDataPlantKdes = []

      if (data_array_main.length>0) {
        getDataPlantKdes = data_array_main.filter((a) => a[data_arr.table_receive.change]?.match(regex) )
      }else{
        if (data_array_all.length>0) {
          getDataPlantKdes = [...disassembleArray(...data_array_all)]
        }
      }
      // getDataPlantKdes.map((val,ind)=>{
      //   var getDataCodeLoteShipping = findCodeLote(data_arr.table_receive.table, data_arr.table_receive.change, data_arr.table_receive.codigo, val.codigo, props)
      //   var getDataVT = getDataVTConfig(val.token_id,get_data_main,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,data_arr.table_receive.table,val.id)
      //   var getDetailVT = getDataVTDetails(val.token_id,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,val.id)


      //     var dataContent = { 
      //                         lote_numero:data_arr.prefix+val[data_arr.table_receive.codigo], 
      //                         idTarget:data_arr.prefix+val[data_arr.table_receive.change].replace(regex,''), 
      //                         colorStatus:PutContentStatus(dataArray,data_arr.table_receive.table).color,
      //                         main_title:getDataVT.title,
      //                         data_top:getDataVT.data_up.reverse(),
      //                         data_bottom:getDataVT.data_down.reverse(),
      //                         icon:data_arr.icon,
      //                         data_details:getDetailVT.data_up,
      //                         token_id:val.token_id,
      //                         clkDataTraza:putTokenData,
      //                         table_selected_info:data_arr.table_receive.table,
      //                         data_select: data_arr.data_details_select
      //                       }

      //   initialNodes.push({ id: data_arr.prefix+val[data_arr.table_receive.codigo], type: 'harvestData', position: { x: 250, y: 0 }, data: dataContent })
      //   if (data_array_main.length>0) {
      //     filterPutLoteImport(val[data_arr.table_receive.change].replace(regex,''),
      //       data_arr.table_receive.table,
      //       data_arr.table_receive.table,
      //       data_arr.table_receive.change,
      //       data_arr.table_receive.codigo,
      //       val[data_arr.table_receive.codigo])
      //   }

      //     putDataLinkEdge[data_arr.prefix+ind]={
      //             'status':(val.status=='desarrollo')?(true):(false),
      //             'idTarget':data_arr.prefix+val[data_arr.table_receive.change].replace(regex,''),
      //             'idSource':data_arr.prefix+val[data_arr.table_receive.codigo],
      //             'token_id':val.token_id+val.user_id
      //             }

      // })
      getDataPlantKdes.map((val,ind)=>{
        var getDataCodeLoteShipping = findCodeLote(data_arr.table_receive.table, data_arr.table_receive.change, data_arr.table_receive.codigo, val.codigo, props)
        var getDataVT = getDataVTConfig(val.token_id,get_data_main,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,data_arr.table_receive.table,val.id)
        var getDetailVT = getDataVTDetails(val.token_id,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,val.id)
        var get_date_trans = Object.values(dataArray[data_arr.table_receive.table].data_date_share).find((a)=> a.token.indexOf(val.token_id)!=-1)
        if (get_date_trans==undefined) {
          get_date_trans = val.created_at
        }else{
         get_date_trans = get_date_trans.date 
        }
          var dataContent = { 
                              lote_numero:data_arr.prefix+val[data_arr.table_receive.codigo], 
                              lote_numero_table:val[data_arr.table_receive.codigo], 
                              date_transactions:get_date_trans,
                              idTarget:data_arr.prefix+val[data_arr.table_receive.change].replace(regex,''), 
                              colorStatus:PutContentStatus(dataArray,data_arr.table_receive.table).color,
                              main_title:getDataVT.title,
                              data_top:getDataVT.data_up.reverse(),
                              data_bottom:getDataVT.data_down.reverse(),
                              icon:data_arr.icon,
                              data_details:getDetailVT.data_up,
                              token_id:val.token_id,
                              clkDataTraza:putTokenData,
                              table_selected_info:data_arr.table_receive.table,
                              data_select: data_arr.data_details_select,
                              data_all: val,
                            }

        initialNodes.push({ key:ind, keyL: data_arr.prefix+val[data_arr.table_receive.codigo], type: 'harvestData', position: { x: 250, y: 0 }, data: dataContent })

        if (data_array_main.length>0) {
          filterPutLoteImport(val[data_arr.table_receive.change].replace(regex,''),
            data_arr.table_receive.table,
            data_arr.table_receive.table,
            data_arr.table_receive.change,
            data_arr.table_receive.codigo,
            val[data_arr.table_receive.codigo])
        }
          var fromProduct = [...disassembleArrayTwo(dataArray[data_arr.table_receive.table].data_array_full)].filter((hf)=> hf[data_arr.table_receive.change]?.match(regex) && val.token_id.indexOf(hf.token_id)!=-1 )
          var dataProdName = ''
          if (fromProduct[fromProduct.length-1]!=undefined) {
            dataProdName = fromProduct[fromProduct.length-1].alimento_o_producto
          }else{
            dataProdName = val.alimento_o_producto
          }
          putDataLinkEdge[data_arr.prefix+ind]={
                  'status':(val.status=='desarrollo')?(true):(false),
                  'idTarget':data_arr.prefix+val[data_arr.table_receive.change].replace(regex,''),
                  'idSource':data_arr.prefix+val[data_arr.table_receive.codigo],
                  'token_id':val.token_id,
                  'producto':dataProdName
                  }

      })
      if (dataProdPlant[data_arr.table_receive.table]!=undefined) {
        Object.values(dataProdPlant[data_arr.table_receive.table]).filter((a) => a[data_arr.table_receive.change]?.match(regex) ).map((valPlant,indPlant)=>{
          var getDataCodeLoteShipping = findCodeLote(data_arr.table_receive.table, data_arr.table_receive.change, data_arr.table_receive.codigo, valPlant.codigo, props)
          var getDataVT = getDataVTConfig(valPlant.token_id,get_data_main,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,data_arr.table_receive.table,valPlant.id)
          var getDetailVT = getDataVTDetails(valPlant.token_id,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,valPlant.id)
          var get_date_trans = Object.values(dataArray[data_arr.table_receive.table].data_date_share).find((a)=>a.token.indexOf(valPlant.token_id)!=-1)
        if (get_date_trans==undefined) {
          get_date_trans = valPlant.created_at
        }else{
         get_date_trans = get_date_trans.date 
        }
          var dataContent = { 
                              lote_numero:data_arr.prefix+valPlant[data_arr.table_receive.codigo], 
                              lote_numero_table:valPlant[data_arr.table_receive.codigo], 
                              date_transactions:get_date_trans,
                              idTarget:data_arr.prefix+valPlant[data_arr.table_receive.change].replace(regex,''), 
                              colorStatus:PutContentStatus(dataArray,data_arr.table_receive.table).color,
                              main_title:getDataVT.title,
                              data_top:getDataVT.data_up.reverse(),
                              data_bottom:getDataVT.data_down.reverse(),
                              icon:data_arr.icon,
                              data_details:getDetailVT.data_up,
                              token_id:valPlant.token_id,
                              clkDataTraza:putTokenData,
                              table_selected_info:data_arr.table_receive.table,
                              data_select: data_arr.data_details_select,
                              data_all: valPlant,
                            }
              //initialNodes.push({ id: data_arr.prefix+val[data_arr.table_receive.codigo], type: 'harvestData', position: { x: 250, y: 0 }, data: dataContent })
          // initialNodes.push({ id: data_arr.prefix+valPlant[data_arr.table_receive.codigo], type: 'harvestData', position: { x: 250, y: 0 }, data: dataContent })
          // filterPutLoteImport(valPlant[data_arr.table_receive.change].replace(regex,''),
          //   data_arr.table_receive.table,
          //   data_arr.table_receive.table,
          //   data_arr.table_receive.change,
          //   data_arr.table_receive.codigo,
          //   valPlant[data_arr.table_receive.codigo])
          var fromProduct = [...disassembleArrayTwo(dataArray[data_arr.table_receive.table].data_array_full)].filter((hf)=> hf[data_arr.table_receive.change]?.match(regex) && valPlant.token_id.indexOf(hf.token_id)!=-1 )
          var dataProdName = ''
          if (fromProduct[fromProduct.length-1]!=undefined) {
            dataProdName = fromProduct[fromProduct.length-1].alimento_o_producto
          }else{
            dataProdName = valPlant.alimento_o_producto
          }
          putDataLinkEdge[data_arr.prefix+indPlant+'_edge']={
                  'status':(valPlant.status=='desarrollo')?(true):(false),
                  'idTarget':data_arr.prefix+valPlant[data_arr.table_receive.change].replace(regex,''),
                  'idSource':data_arr.prefix+valPlant[data_arr.table_receive.codigo],
                  'token_id':valPlant.token_id,
                  'producto':dataProdName
                  }
          
        })
      }

    })
    var getDataProductorKdes = dataArray.fruit_vegetable_user?.data_array.filter((a) => a.lote_numero!=null)

      getDataProductorKdes.map((valProductor,indProductor)=>{
        var getDataVT = getDataVTConfig(valProductor.token_id,get_data_main,'title_prod_harvest','view_table_harvest','fruit_vegetable_user',valProductor.id)
        var getDetailVT = getDataVTDetails(valProductor.token_id,'title_prod_harvest','view_table_harvest',valProductor.id)
        var get_date_trans = Object.values(dataArray.fruit_vegetable_user?.data_date_share).find((a)=>a.token.indexOf(valProductor.token_id)!=-1)
        if (get_date_trans==undefined) {
          get_date_trans = valProductor.created_at
        }else{
         get_date_trans = get_date_trans.date 
        }
        var dataContent = { 
                            lote_numero:'producer-'+valProductor.lote_numero,
                            lote_numero_table:valProductor.lote_numero,
                            date_transactions:get_date_trans,
                            colorStatus:PutContentStatus(dataArray, 'fruit_vegetable_user').color,
                            main_title:getDataVT.title,
                            data_top:getDataVT.data_up.reverse(),
                            data_bottom:getDataVT.data_down.reverse(),
                            data_details:getDetailVT.data_up,
                            icon:'https://cdn-icons-png.flaticon.com/512/2674/2674482.png',
                            token_id:valProductor.token_id,
                            clkDataTraza:putTokenData,
                            idTarget:'none',
                            table_selected_info:'create_harvest_kdes',
                            data_select: [{value:'fruit_vegetable_user',label:'Datos Productor'},{value:'create_harvest_kdes',label:'Cosecha'}],
                            data_all:valProductor
                          }
        initialNodes.push({ key:indProductor, keyL: 'producer-'+valProductor.lote_numero, type: 'producerData', position: { x: 0, y: 0 }, data: dataContent })
        var fromProduct = [...disassembleArrayTwo(dataArray['create_harvest_kdes'].data_array_full)].filter((hf)=> valProductor.token_id.indexOf(hf.token_id)!=-1 )
        //hf['alimento_o_producto']?.match(regex) && valProductor.token_id.indexOf(hf.token_id)!=-1
          var dataProdName = ''
          if (fromProduct[fromProduct.length-1]!=undefined) {
            dataProdName = fromProduct[fromProduct.length-1].alimento_o_producto
          }
        putDataLinkEdge['producer-'+indProductor]={
        'status':(valProductor.status=='desarrollo')?(true):(false),
        //'idSource':'producer-'+valProductor.lote_numero,
        'idTarget':'producer-'+valProductor.lote_numero,
        'token_id':valProductor.token_id,
        'producto':dataProdName
      }
      })

  var getOnlyPerms = []
      release_kdes.map((valEdge,indEdge)=>{
        Object.entries(putDataLinkEdge).map((edVal,edIndx)=>{
           switch (true) {
            case (edVal[0].indexOf(valEdge.prefix) !=-1):
              var getDataCodePlant = searhCodeLoteGlobal(valEdge.prefix, Object.values(putDataLinkEdge), edVal[1].idTarget, edIndx)
                getOnlyPerms.push(getDataCodePlant+'-'+edVal[1].idSource)
                //console.log(edVal[1])
                initialEdges.push({
                  id: getDataCodePlant+'-'+edVal[1].idSource,
                  source: getDataCodePlant,
                  target: edVal[1].idSource,
                  animated: edVal[1].status,
                  token:edVal[1].token_id,
                  producto:edVal[1].producto
                  //label: 'edge label',
                })
              break;

            default:
              break;
           }
        })
      })
  //var filterSelected = [...new Map(initialNodes.map(v => [v.key, v])).values()]
  var filterSelected = initialNodes
  var filterSelectedEdge = initialEdges
  
  if (initialEdges.length>0) {
    var otherFilterSelected = initialNodes.filter((aft,bft)=> aft.data.token_id.indexOf(props.code_traza)!=-1 )
    var getafo = getOnlyPerms.filter((afo)=> afo.indexOf(otherFilterSelected[otherFilterSelected.length-1]?.data?.lote_numero?.split('-')[1])!=-1)
    filterSelectedEdge = initialEdges.filter((afg)=> getafo.join().indexOf(afg.source)!=-1 && getafo.join().indexOf(afg.target)!=-1 )
    filterSelected = initialNodes.filter((af,bf)=> getafo.join().indexOf(af.keyL)!=-1 )
  }
  //console.log(filterSelectedEdge,initialEdges)

  //var getData = initialEdges.map((a,b)=>{ return a.target })
  // [...new Map(initialNodes.map(v => [v.keyL, v])).values()]
  // [...new Set(getData)]
  //[...new Map(initialEdges.map(v => [v.target, v])).values()]
  var getDataReducer = filterSelected.map((ar,br)=>{
    return insetLoteArray(ar.data.idTarget?.split('-').slice(1,3).join('-'),ar.keyL,filterSelected,initialEdges,ar.data.data_all.token_id)
  })
  //console.log(filterSelected,initialEdges)
  // let _expandedKeys = { ...expandedKeys };
  // getDataReducer.map((a,b)=>{
  //   if (_expandedKeys[String(a.key)]) delete _expandedKeys[String(a.key)];
  //   else _expandedKeys[String(a.key)] = false;
  // })

  // setExpandedKeys(_expandedKeys);
  setInitialNodes(getDataReducer)
  //setInitialNodes(getDataReducer)
  setInitialEdges(filterSelectedEdge)
  }
const removeDuplicates =(arr)=> {

    // let unique = arr.reduce((acc, curr) => {
    //   console.log(acc,curr.target)

    //   // if (acc.source!=undefined) {
    //   //   if (!acc.source.includes(curr.source)){
    //   //       acc.push(curr);
    //   //       return acc;
    //   //   }
    //   // }
    // });
    //return unique;
}
    const templateProduct = (rowData) => {
      var data = 'Sin dato'
      if (rowData.data.table_selected_info=='create_harvest_kdes') {
        // if (dataArray[rowData.data.table_selected_info].data_array.length!=0) {
        // }
          var getDataFilter = []
          if (dataArray[rowData.data.table_selected_info].data_array_full.length!=0) {
            getDataFilter = [...dataArray[rowData.data.table_selected_info].data_array,...disassembleArrayTwo(dataArray[rowData.data.table_selected_info].data_array_full)].filter((a) => a.token_id==rowData.data.data_all.token_id && a.codigo==rowData.data.data_all.codigo);
          }else{
            getDataFilter = [...dataArray[rowData.data.table_selected_info].data_array].filter((a) => a.token_id==rowData.data.data_all.token_id && a.codigo==rowData.data.data_all.codigo);
          }
          if (getDataFilter[getDataFilter.length-1]!=undefined) {
            data = getDataFilter[getDataFilter.length-1].alimento_o_producto
          }
          //console.log(getDataFilter[getDataFilter.length-1].alimento_o_producto)
      }else{      
        if (rowData.data.data_all.alimento_o_producto!=null) {
          data = rowData.data.data_all.alimento_o_producto
        }
      }
      return data
    }
    const templateFrom = (rowData) => {
      return rowData.data.data_all.empresa
    }
    const funcDataNext = (find_lote,key_lote) => {
      var setData = 'Sin dato'
      var getDataNextFind = initialEdgesState.filter((a) => a.source==key_lote )
      if (getDataNextFind[0]!=undefined) {
        var getDataSelected = initialNodesState.filter((af) => af.keyL==getDataNextFind[0].target)
        if (getDataSelected.length!=0) {
          if (getDataSelected[getDataSelected.length-1].data.data_all.empresa!=null) {
            setData = getDataSelected[getDataSelected.length-1].data.data_all.empresa
          }
        }
      }
      return setData
    }
    const templateTo = (rowData) => {
      var getDataNext = funcDataNext(rowData.data.lote_numero_table,rowData.keyL)
      //console.log(initialEdgesState)
      return getDataNext
    }
    const optionsLotes = () =>{
      // var pushDataRelease=[...put_arr_data_kdes,{table_receive:{table:'fruit_vegetable_user',change:'change_lote_numero',codigo:'lote_numero'},prefix:'producer-',name_label:'Productor',data_f_config_vt:{title:'title_packing',view:'view_table_packing'},data_details_select:[{value:'fruit_vegetable_user',label:'Datos Productor'},{value:'create_harvest_kdes',label:'Cosecha'}],icon:'https://cdn-icons-png.flaticon.com/512/6384/6384822.png'}]
      // var getData = initialEdgesState.map((a,b)=>{
      //  var getFilter = pushDataRelease.find((af,bf)=> af.prefix.indexOf(a.source.split('-')[0])!=-1)
      //  if (getFilter!=undefined) {
      //   var getFilterTN = getFilter.data_details_select.find((aT,bT)=> aT.value.indexOf(getFilter.table_receive.table)!=-1)
      //   return <option value={a.source}>{getFilter.name_label+' '+getFilterTN.label+' - Lote: '+a.source.split('-')[1]}</option>
      //  }
      // })
      var getData = initialEdgesState.map((a,b)=>{ return a.source?.split('-')[1] }).filter(Boolean)
        //console.log([...new Map(initialEdgesState.map(v => [v.source, v])).values()])
        //[...new Set(getData)]
      var getDataReducer = [...new Set(getData)].map((ar,br)=>{
        return (<option value={ar}>{'Lote: '+ar}</option>)
      })
      getDataReducer.reverse()
      return getDataReducer
    }
    const insetLoteArray = (loteS,loteT,dataLineal,initialEdgesState,token_id)=>{
        //console.log(loteS,loteT,dataLineal,initialEdgesState,token_id)
      var getDataIndex = dataLineal.findIndex((element) => element.keyL.indexOf(loteT)!=-1 && token_id.indexOf(element.data.token_id)!=-1);
      var getDataFindDatas = []
      var getDataEdgeFind = initialEdgesState.filter((element) => element.target.indexOf(loteT)!=-1  );
      //initialEdgesState.filter((element) => console.log(element.target.indexOf(loteT)!=-1 && element.source.indexOf(loteS)!=-1) );
      //&& elementTwo.data.data_all?.alimento_o_producto?.indexOf(a.producto)!=-1
      var conster = 0
      getDataEdgeFind.map((a,b)=>{
        //console.log(a.source)
          getDataFindDatas[a.target+'_'+a.source] = dataLineal.find((elementTwo) => elementTwo.keyL.indexOf(a.source) != -1 );
          if (getDataFindDatas[a.target+'_'+a.source]!=undefined) {
            getDataFindDatas[a.target+'_'+a.source].key=conster+'-'+getDataFindDatas[a.target+'_'+a.source].key+'-'+b+'-'+getDataIndex
            conster++
          }

          //console.log(getDataFindDatas[a.target+'_'+a.source]?.key)
          //getDataFindDatas[b] = dataLineal.find((elementTwo) => console.log(elementTwo.keyL.indexOf(a.source) != -1 && elementTwo.data.token_id.indexOf(a.token)!=-1 ) );
        // && a.token.indexOf(token_id)!=-1
      })
       var setDataLineal = [...dataLineal]
       var getSubIndex = {...setDataLineal[getDataIndex]}
        //console.log(getDataFindDatas[loteT+'_'+loteS])
       if (Object.values(getDataFindDatas).length>1) {
         getSubIndex={ ...getSubIndex,'children':[getDataFindDatas[loteT+'_'+loteS]].filter(Boolean) }
         setDataLineal[getDataIndex]=getSubIndex
         insertLoteArrayChild([...setDataLineal[getDataIndex].children],dataLineal,initialEdgesState,loteT,token_id)
       }
       return setDataLineal[getDataIndex]

       //console.log(setDataLineal,dataLineal)
       //setDataLineal[getDataIndex]={...getSubIndex,children:getDataFindDatas}

       //getSubIndex['children']=getDataFindDatas
       //setDataLineal=[...setDataLineal]
       //setDataLineal=[...dataLineal,getSubIndex]
       //console.log(setDataLineal,dataLineal)
       //getDataFindDatas.map((a,b)=>{
        //console.log(a.keyL,dataLineal,initialEdgesState)
       //})
    }
    const insertLoteArrayChild = (data,dataLineal,initialEdgesState,loteT,token_id)=>{
      if (data!=undefined) {
        var conster = 0
        data.filter(Boolean).map((a,b)=>{
          var getDataIndex = dataLineal.findIndex((element) => element.keyL.indexOf(a.keyL)!=-1 && token_id.indexOf(element.data.token_id)!=-1);
          var getDataFindDatas = []
          var getDataEdgeFind = initialEdgesState.filter((element) => element.target.indexOf(a.keyL)!=-1);
          getDataEdgeFind.map((af,bf)=>{
              getDataFindDatas[bf] = dataLineal.find((elementTwo) => elementTwo.keyL.indexOf(af.source) !=-1 );
          })
            if (getDataFindDatas.length>0) {
              a['key']=conster+'-'+a['key']+'-'+b+'-'+getDataIndex
              a['children']=getDataFindDatas
              conster++
            }
        })
      }
    }
    const [data_get_lotes,data_set_lotes] = useState([])
    const loopFinder = (data,loteTarg) =>{
      var setData = []
      var finderData = data.find((ifd) => ifd.source?.indexOf(loteTarg)!=-1)
      if (finderData!=undefined) {

        if (finderData.source!=undefined) {          
          var validFinder = data.find((ifd) => ifd.source?.indexOf(finderData.target)!=-1)
          if (validFinder!=undefined) {
            if (validFinder.source!=undefined) {
              if (validFinder.source.indexOf(validFinder.target)==-1) {
                loopFinder(data,validFinder.target);
                setData.push(validFinder.source,validFinder.target,loteTarg)
              }
            }
          }
        }

      }
          //console.log(setData)
          if (setData.length>0) {
            data_get_lotes.push(...setData)
          }
    }
    const functChangeLote = async (lote) =>{
        var getDataIndex = initialNodesState.findIndex((element) => element.keyL.indexOf(lote)!=-1);
        var getDataIndexLast = initialNodesState.findLastIndex((element) => element.keyL.indexOf(lote)!=-1);
        var filterData = initialEdgesState.filter((ie)=> ie.source?.indexOf(lote)!=-1)
        filterData.map((afd,bfd)=>{
          loopFinder(initialEdgesState,afd.target)
        })
        //[...new Set(data_get_lotes)]
        var releaseData = [...initialNodesState]
        initialNodesState.map((a,b)=>{
          if (a.children!=undefined) {
            if (a.children.length>0) {
              a.children.map((ac,ic)=>{
                if (ac!=undefined) {                
                  var getDataIndexTwo = initialNodesState.findIndex((elementTwo) => elementTwo.keyL.indexOf(ac.data.lote_numero)!=-1);
                  delete releaseData[getDataIndexTwo]
                }
              })
            }
          }
        })
        //console.log(data_get_lotes,initialNodesState, releaseData.filter(Boolean) )
        if (lote=='0') {
          setInitialNodesM(releaseData.filter(Boolean))
        }else{
          var dataSecont = releaseData.filter(Boolean).filter((aff)=>aff.data.idTarget?.split('-').slice(1,3).join('-').indexOf(lote)!=-1 )
          //console.log(dataSecont,dataSecont.length)
          if (dataSecont.length==1) {
            //console.log(...dataSecont)
            setInitialNodesM([
              ...releaseData.filter(Boolean).filter((aff)=> data_get_lotes.indexOf(aff.data.idTarget?.split('-').slice(1,3).join('-'))!=-1),
              ...dataSecont
              ])

          }else{
            setInitialNodesM(dataSecont)            
          }
        }
          //setInitialNodesM(initialNodesState.slice(getDataIndex-1,getDataIndexLast+1))

        // if ((getDataIndex+1)==initialNodesState.length) {
        // }else{
        // console.log(getDataIndex)
        //   setInitialNodesM(initialNodesState.slice(0,getDataIndex+1))
        // }
        setSelectedLote(lote)
    }
  var context = useContext(UserContext)
  const [btnExport,setBtnExport] = useState(false)

  const export_excel= async()=>{
    setBtnExport(true)
    if (initialNodesStateM.length==0) {
      context.setToastMess('error', "¡Error!", 'Seleccione un lote.')
      setBtnExport(false)
    }else{
    var getDataRows = []
      initialNodesStateM.map((a,b)=>{
        getDataRows.push([
          templateProduct(a),
          a.data.date_transactions,
          a.data.lote_numero_table,
          templateFrom(a),
          templateTo(a)
          ])
          if (a.children!=undefined) {
            a.children.map((ac,bc)=>{
              getDataRows.push([
                templateProduct(ac),
                ac.data.date_transactions,
                ac.data.lote_numero_table,
                templateFrom(ac),
                templateTo(ac)
                ])
            })
            
          }
      })
      getDataRows.unshift(['Producto' ,'Fecha de transacción' ,'N# lote' ,'De' ,'Para'])
     await export_excel_api(getDataRows)
     setBtnExport(false)
    }
  }
  const onPage = (data) =>{
    if (data!=null) {
      //console.log(data.getElement())
    }
  }
  const toggleApplications = () => {
      let _expandedKeys = { ...expandedKeys };
      initialNodesStateM.map((a,b)=>{
        if (_expandedKeys[String(a.key)]){
          delete _expandedKeys[String(a.key)]
        }else{
          _expandedKeys[String(a.key)] = true
       };
      })

      setExpandedKeys(_expandedKeys);
  };
  const printer_pdf = async () => {
    setBtnExport(true)
    await toggleApplications()
    window.scrollTo({ top:0, left:0, behavior: "instant"})
    var component = await document.querySelector(".contentBlockChain").innerHTML
    var divContents = component
    window.html2pdf().from(divContents).toPdf().get('pdf').then(function (pdf) {
    window.open(pdf.output('bloburl'), '_blank');
    });
    setBtnExport(false)
  }
    return(<>
      <Col md={12}>
        <Row>
        <div className="col-md-3">
          <Input type="select" value={selectedLote} onChange={e => functChangeLote(e.target.value)}>
            <option value="0">Todos los lotes</option>
            {optionsLotes()}
          </Input>
        </div>
          <div className="col-md-2">
            <button className="btn buttonExcel" style={{border: 'solid 1px'}} disabled={btnExport} onClick={()=>{export_excel()}}>Exportar XLS</button>
          </div>
          <div className="col-md-3">
            <button className="btn buttonPdf" style={{border: 'solid 1px'}} disabled={btnExport} onClick={()=>{printer_pdf()}}>Exportar PDF</button>
          </div>
        </Row>
      </Col>
      <br />

            <div className="contentBlockChain">
              <TreeTable 
              value={initialNodesStateM}
              tableStyle={{ minWidth: '50rem' }}
              expandedKeys={expandedKeys}
              //ref={onPage}
              onToggle={(e) => setExpandedKeys(e.value)}
              >
                  <Column style={{border: '1px solid gray'}} body={templateProduct} header="Producto" expander></Column>
                  <Column style={{border: '1px solid gray'}} field="date_transactions" header="Fecha de transacción"></Column>
                  <Column style={{border: '1px solid gray'}} field="lote_numero_table" header="N# lote"></Column>
                  <Column style={{border: '1px solid gray'}} body={templateFrom} header="De"></Column>
                  <Column style={{border: '1px solid gray'}} body={templateTo} header="Para"></Column>
              </TreeTable>
            </div>

      {/*
      <ContentFlow propNode={initialNodesState} propEdge={initialEdgesState}/>
    */}
      <br />
      <br />
      <ContentLogisticKdes 
        dataSelectTable={dataSelectTable}
        dataSelectColumn={dataSelectColumn}
        dataSelect={dataSelect}
        dataSelected={dataSelected}
        putTokenData={putTokenData}
        dataToken={dataToken}
      />
    </>)
}