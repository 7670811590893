import React, {useState, useEffect, useRef} from 'react';
import { get_permission, get_permission_main, message_assistant } from "network/ApiAxios";
export const UserContext = React.createContext([]);

export const DataProvider =  ({children}) => {
	const [valueGlobal, setValueGlobal] = useState([])
	const [valueGuide, setValueGuide] = useState([])
    const [valueAssistant, setValueAssistant] = useState({})
    const [valueAssistantForm, setValueAssistantForm] = useState([])
	const [valuePanel, setValuePanel] = useState([])
	const [valueGlobalMain, setValueGlobalMain] = useState([])
	const [valueDataMain, setValueDataMain] = useState([])
	const [valueCountNotifi, setvalueCountNotifi] = useState(0)
    const toast = useRef(null);
    const [layoutConfig, setLayoutConfig] = useState({
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: 'lara-light-indigo',
        scale: 14
    });

    const [layoutState, setLayoutState] = useState({
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false
    });

	var userdata = localStorage.getItem('user');
	var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(window.location.search).get("company");
    var idcompany = null;
    if (jsondata!=null) {
        if (jsondata.userType==2) {
          if (gerParam!=null) {
            idcompany = gerParam
          }
        }
    }
  const getAffiliates = async ()=>{
    if (jsondata!=null) {        
	    var dataPermission = await get_permission(jsondata._id,idcompany)
	    var colab_id = String(dataPermission[0].user_id_connection)
	    if (dataPermission[0].link_user_global!=null) {
	    	colab_id = dataPermission[0].link_user_global
	    }
	    localStorage.setItem('colab_id', colab_id )
	    var dataPermissionMain = await get_permission_main(jsondata._id)
	    setValueDataMain(dataPermissionMain.datosPermission)
	    setValueGlobal(dataPermission[0])
	    setValueGuide(dataPermission[1])
	    setValuePanel(dataPermission[2])
	    setValueGlobalMain(dataPermissionMain.notifications)
	    setvalueCountNotifi(dataPermissionMain.notificationsCount)
    }
        var resultAssistant = await message_assistant()
        if (resultAssistant!=null) {        
            setValueAssistant({mess_data:resultAssistant[0],position_data:resultAssistant[2]})
            setValueAssistantForm(resultAssistant[1])
        }
  }


    const onMenuToggle = () => {
        if (isOverlay()) {
            setLayoutState((prevLayoutState) => ({ ...prevLayoutState, overlayMenuActive: !prevLayoutState.overlayMenuActive }));
        }

        if (isDesktop()) {
            setLayoutState((prevLayoutState) => ({ ...prevLayoutState, staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive }));
        } else {
            setLayoutState((prevLayoutState) => ({ ...prevLayoutState, staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive }));
        }
    };

    const showProfileSidebar = () => {
        setLayoutState((prevLayoutState) => ({ ...prevLayoutState, profileSidebarVisible: !prevLayoutState.profileSidebarVisible }));
    };

    const isOverlay = () => {
        return layoutConfig.menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };
    const setToastMess = (alert, summary, detail) => {
        toast.current?.show({
            severity: alert,
            summary: summary,
            detail: detail,
            life: 3000
        });
    }
  useEffect(()=> {
    getAffiliates()
  },[])
	return (

			    <UserContext.Provider value={{
			    	valueGlobal,
			    	setValueGlobal,
			    	valueDataMain,
			    	valueGlobalMain,
			    	setValueGlobalMain,
			    	valueCountNotifi, 
			    	setvalueCountNotifi,
			    	valueGuide,
                    valueAssistant,
                    valueAssistantForm,
			    	valuePanel,
			    	getAffiliates,

			        layoutConfig,
			        setLayoutConfig,
			        layoutState,
			        setLayoutState,
			        onMenuToggle,
			        showProfileSidebar,
                    toast,
                    setToastMess
			      }}>
				    {/*(valueGlobal.length==0)?('Buscando permisos...'):()*/}
			    	{children}
    			</UserContext.Provider>
		)
}