import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus} from './InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'
const datacontent = [
{title:'Datos Importador',table:'create_producer_food_kdes',icon:'fa fa-truck-moving','multiple_tables':0},
{title:'Datos Transformación',table:'create_producer_transformation_kdes',icon:'fa fa-truck-moving','multiple_tables':0},
{title:'Datos Recibido',table:'create_producer_receiving_kdes',icon:'fas fa-ship','multiple_tables':0},
{title:'Datos Envio',table:'create_producer_shipping_kdes',icon:'fa fa-truck-moving','multiple_tables':0},
]
export default class SectionEmbarque extends React.Component {
  render(){
  return (
    <>
<ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
    </>
  );
}
}

//export default Elements;
