import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { get_data_transport_trace, get_permission } from "network/ApiAxios";
import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentSubPlant, PutContentSubTablaSatelite} from 'views/pages/examples/LogisticsForVistaTrace/InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogisticMRV'
import ModalObservation from 'views/pages/examples/LogisticsForVistaTrace/ModalObservationMRV'
import DetailsObservationsRev from "views/pages/examples/Production/DetailProductionTime/DetailsObservationsRev";
import NotificationAlert from "react-notification-alert";
import { get_data_satelite_vt } from "network/ApiAxios";

import jQuery from 'jquery'
    const datacontent = [
    {table:'production_land_preparation_vf_gc',title:'Preparación de tierra',icon:'fas fa-chart-area','multiple_tables':0},
    {table:'production_irrigation_vf_gc',title:'Riego',icon:'fas fa-tint','multiple_tables':0},
    {table:'production_seed_vf_gc',title:'Semilla',icon:'fas fa-seedling','multiple_tables':0},
    {table:'production_fertilizer_vf_gc',title:'Fertilización/Agroquímicos',icon:'fas fa-spa','multiple_tables':0},
    {table:'production_sowing_vf_gc',title:'Siembra',icon:'fas fa-leaf','multiple_tables':0},
    {table:'production_sowing_transplant_vf_gc',title:'Transplante',icon:'fas fa-sign-language','multiple_tables':0},
    {table:'production_producer_monitoring_vf_gc',title:'Monitoreo Productor',icon:'fab fa-watchman-monitoring','multiple_tables':0},
    {title:'Satelite',table:'production_info_satelite_vf_gc',
     data_table: [{title:'NDVI',table:'production_info_satelite_vf_gc'},
        {title:'NDRE',table:'production_info_satelite_ndre'},
        {title:'NDMI',table:'production_info_satelite_ndmi'},
        {title:'HR Suelo',table:'production_info_satelite_ndhs'}],
    icon:'fas fa-satellite','multiple_tables':3},
    ]
    //,
    // {table:'production_info_satelite_ndre',title:'Satelite NDRE (Salud del Cultivo)',icon:'fas fa-satellite','multiple_tables':0},
    // {table:'production_info_satelite_ndmi',title:'Satelite NDMI (Humedad de la Vegetación)',icon:'fas fa-satellite','multiple_tables':0},
    // {table:'production_info_satelite_ndhs',title:'Satelite HR-SUELO (Humedad Relativa del Suelo)',icon:'fas fa-satellite','multiple_tables':0}
export default class SectionNamaG extends React.Component {
  state = {
    cross_transport:['cargando'],
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false],
    notificationAlertRef: React.createRef(),
    set_type_content:{},
    set_type_content_onre:null,
    data_user:null,
    db_prod:new URLSearchParams(window.location.search).get("db"),
    code_prod:new URLSearchParams(window.location.search).get("code"),
    form_prod:null,
    put_table:null,
    data_satelital:null,
    type_traza_data_user:this.props.DataRow.data_user_ad
  }
  async componentDidMount(){
    this.setState({data_user:this.props.DataRow.get_data_user})
    var dbOfUser = new URLSearchParams(window.location.search).get("db")
    var getCode = new URLSearchParams(window.location.search).get("code");
    var getData = await get_data_satelite_vt(dbOfUser,getCode)
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({data_satelital:getData.satelite,type_traza_data_user:this.props.DataRow.data_user_ad})
  }
  contentRows = () =>{

    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
            <Col md={12}>
            <div className="elementor-widget-wrap elementor-element-populated">
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
            <div className="elementor-widget-container">
            <div role="tablist">
            <div className="elementor-toggle-item" style={{zIndex: '99'}}>
            <h6 onClick={()=>{
              if (this.props.DataRow.get_data_user!=null) {
                if (this.state.type_traza_data_user=='VA') {
                  this.funcOpenModal(true,'validar',val.table,val.title)
                }
                if (this.state.type_traza_data_user=='ON' || this.state.type_traza_data_user=='RE') {
                  this.funcOpenModal(true,'general',val.table,val.title)
                }
              }
              this.opened(this.state.openColapse[indx+1],indx+1,val.table)
            }} id={val.table} className="elementor-tab-title clk_change_table" data-tab="1" role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
              <div style={{marginBottom:'10px'}} className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
              <div className="elementor-widget-container">
              <div className="elementor-image-box-wrapper">
              <figure className="elementor-image-box-img">
                {(val.multiple_tables==3)?(<>
                  <div style={{width:30,height:30,backgroundColor: PutContentStatus(this.props.DataRow.allDataTrza,(this.props.DataRow.allDataSatelite==null)?(val.table):((this.props.DataRow.allDataSatelite.data_satelital==null)?(val.table):('info_producer_vf_gc'))).color,borderRadius: '100px', textAlign: 'center'}}>
                    <span className={val.icon} style={{color:'#204d74',fontSize: '23px',position: 'relative',bottom: '0px',left: '0px'}}></span>
                  </div>
                </>):(<>
                  <div style={{width:30,height:30,backgroundColor: PutContentStatus(this.props.DataRow.allDataTrza,val.table).color,borderRadius: '100px', textAlign: 'center'}}>
                    <span className={val.icon} style={{color:'#204d74',fontSize: '23px',position: 'relative',bottom: '0px',left: '0px'}}></span>
                  </div>
                </>)}
              </figure>
              <div className="elementor-image-box-content">
              <span className="eael-tab-title title-after-icon" style={{fontWeight: 'normal',fontSize: '12px'}}>{val.title}</span>
              </div>
              </div>
              </div>
              </div>
            </h6>
                  <Row>
{/*                    {(this.props.DataRow.get_data_user!=null)?((this.state.type_traza_data_user=='VA')?(
                      <Col md={12}>
                        <button className="btn" onClick={()=>{this.funcOpenModal(true,'validar',val.table,val.title)}}>{(this.state.set_type_content[val.table]==undefined)?('Validar / Certificar Información'):(
                        (this.state.set_type_content[val.table]==1)?('Validar / Certificar Información'):('Volver a Ver Información')
                        )}</button>
                      </Col>
                    ):(null)):(null)}*/}
                  </Row>
                </div>
                </div>
                </div>
                </div>
                <div className="elementor-element elementor-element-81e561d elementor-widget elementor-widget-spacer" data-id="81e561d" data-element_type="widget" data-widget_type="spacer.default">
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
            </div>
            </div>
            </div>
            </div>
        </Col>
                  </>
    })
    return content
  }
  contentRowsCard = () =>{
    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
      {(this.props.DataRow.allDataTrza[val.table]==undefined)?(null):(
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
            <div className="elementor-toggle-item">
            <Collapse isOpen={this.state.openColapse[indx+1]} id="elementor-tab-content-6961" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-6961">
              <ul style={{padding: '0px'}}>
            {(val.multiple_tables==0)?(<>
              {(this.props.DataRow.allDataTrza[val.table]['data_array'].length>1)?(
                      <PutContentSub
                      data_function={this.props.DataRow} 
                      type_function={val.table}
                      states={this.state}
                      openedChild={this.openedChild}
                      chillSelector={"data"}
                      />
                ):(
                  <div style={{margin: 'auto'}}>
                    <Card className="p-0">
                      <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                      <Row>
                        <Col md={1}>
                          <h4 className="mb-0" style={{color: '#ffffff',fontSize: '11px'}}>Estado:</h4>
                        </Col>
                        <div>
                          {(val.multiple_tables==3)?(<>
                            <p className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.DataRow.allDataTrza,(this.props.DataRow.allDataSatelite==null)?(val.table):((this.props.DataRow.allDataSatelite.data_satelital==null)?(val.table):('info_producer_vf_gc'))).register_status}</p>
                          </>):(
                          <>
                            <p className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.DataRow.allDataTrza,val.table).register_status}</p>
                          </>
                          )}
                        </div>
                      </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                        <PutContent 
                        data_function={this.props.DataRow} 
                        type_function={val.table} />                  
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                )}
              </>):(null)}
            {(val.multiple_tables==1)?(<>
              <PutContentSubTwo
              data_function={this.props.DataRow} 
              type_function={val.table_one}
              type_function_two={val.table_two}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datat"}
              titleType={val.title_one}
              titleTypeTwo={val.title_two}
              />
              </>):(null)}
            {(val.multiple_tables==2)?(<>
              <PutContentSubPlant
              data_function={this.props.DataRow} 
              type_function={val.table_one}
              type_function_two={val.table_two}
              type_function_tree={val.table_three}
              type_function_four={val.table_fuor}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datadp"}
              titleType={val.title_one}
              titleTypeTwo={val.title_two}
              titleTypeTree={val.title_three}
              titleTypeFour={val.title_fuor}
              />
              </>):(null)}
            {(val.multiple_tables==3)?(<>
              <PutContentSubTablaSatelite
              data_function={this.props.DataRow} 
              data_f_satelite={this.state.data_satelital}
              first_data_table={val.table}
              data_table={val.data_table}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datadp"}
              />
              </>):(null)}
              </ul>
              </Collapse>
            </div>
            </div>
            </div>
            </div>
            )}

                  </>
    })
    return content
  }
     opened = (isChange,ident,element) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
        jQuery('.clk_change_table').css({'background-color':'transparent', 'color': '#204D74', 'border-radius': '5px'})
        var elementForStyleParent = jQuery('#'+element)
          elementForStyleParent.css({'background-color':'#2d5980', 'color': 'white', 'border-radius': '5px'})
    }
     openedChild = (isChange,ident,valuePut,id_register) =>{
  var put_title_activity
    if (this.state.type_traza_data_user=='ON') {
      put_title_activity = this.state.put_title_g+' - '+valuePut+' * '+id_register
    }
    if (this.state.type_traza_data_user=='RE') {
      put_title_activity = this.state.put_title_g+' - '+valuePut+' * '+id_register
    }
    if (this.state.type_traza_data_user=='VA') {
      put_title_activity = this.state.put_title_g+' - '+valuePut+' * '+id_register
    }
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
        this.setState({put_title:put_title_activity})
      }
 funcOpenModal = (chan,form,put_table,put_title) =>{
  var id_register = jQuery('.get_info_register_'+put_table).attr('id-register')
  var put_id_register = ''
  if (id_register!=undefined) {
    put_id_register = ' * '+id_register
  }
  var put_title_activity
    if (this.state.type_traza_data_user=='ON') {
      put_title_activity = "Monitoreo de "+put_title+put_id_register
    }
    if (this.state.type_traza_data_user=='RE') {
      put_title_activity = "Revisión de "+put_title+put_id_register
    }
    if (this.state.type_traza_data_user=='VA') {
      put_title_activity = "Validación de "+put_title+put_id_register
    }
    this.setState({
      openModal:chan,
      // db_prod:this.state.routegetThree,
      // code_prod:this.state.routeget,
      form_prod:form,
      put_table:put_table,
      put_title:put_title_activity,
      put_title_g:put_title_activity
    })
    if (this.state.set_type_content[put_table]==undefined) {
      this.setState({set_type_content: {[put_table]:2} })
    }else{    
      if (this.state.set_type_content[put_table]==1) {
        this.setState({set_type_content: {[put_table]:2} })
      }else{
        this.setState({set_type_content:{[put_table]:1}})
      }
    }
  }
funcObs = async (chan,type_user_role) =>{
    var type_user_p=null
    if (this.props.DataRow.get_data_user!=null) {
     type_user_p = this.state.type_traza_data_user
    }
    if (this.state.set_type_content_onre==type_user_role) {
      this.setState({set_type_content_onre:null})  
    }else{
      this.setState({set_type_content_onre:type_user_role})      
    }
}
  render(){
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  const insertDataObservation = async () =>{
       notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
  }
  return (
    <>
{/*<ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />*/}
          <div className="rna-wrapper">
            <NotificationAlert ref={this.state.notificationAlertRef} />
          </div>
          <Row className="mt-2">
            <Col md={2} style={{left:'0%'}}>
                    {(this.props.DataRow.get_data_user!=null)?(
                      (this.state.type_traza_data_user=='RE' || this.state.type_traza_data_user=='VA')?(
                                                  <Col md={12}>
                                                    <button className="btn" onClick={()=>{this.funcObs(true,'ON')}}>{(this.state.set_type_content_onre!="ON")?('Ver Observaciones de Monitoreo'):('Volver a Ver Información')}</button>
                                                  </Col>
                                              ):(null)
                      ):(null)}
                    {(this.props.DataRow.get_data_user!=null)?((this.state.type_traza_data_user=='VA')?(
                      <Col md={12}>
                        <button className="btn" onClick={()=>{this.funcObs(true,'RE')}}>{(this.state.set_type_content_onre!="RE")?('Ver Observaciones de Revisión'):('Volver a Ver Información')}</button>
                      </Col>
                    ):(null)):(null)}
              {this.contentRows()}
            </Col>
            <Col md={10} className="pt-2">
                      {(this.state.set_type_content_onre==null)?(null):(
                            (this.state.set_type_content_onre=='ON' || this.state.set_type_content_onre=='RE')?(
                              <DetailsObservationsRev thisStates={this.state} />
                            ):(null)
                          )}
              {this.contentRowsCard()}
                      {(this.state.set_type_content[this.state.put_table]==undefined)?(null):(
                        (this.state.set_type_content[this.state.put_table]==1)?(null):(
                          (jQuery(".verifyRegister_"+this.state.put_table).text().indexOf('Sin Registro')==-1)?(
                              <>
                                {(this.state.type_traza_data_user=='VA')?(null):(
                                  <ModalObservation thisStates={this.state} sendOpenModal={this.state.openModal} openModalFunc={this.funcOpenModal} insertDataObservation={insertDataObservation}/>
                                )}
                              </>
                            ):(null)
                          )
                        )}
            </Col>
          </Row>
    </>
  );
}
}

