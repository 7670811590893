import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";

export default class SectionLogistic extends React.Component {
  state = {
    cross_transport:['cargando'],
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false]
  }
  async componentDidMount(){
  }
  render(){
         const opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
    const openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
      const openedNextChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseNextChild:{[ident]: true }});
        }else{
        this.setState({openColapseNextChild:{[ident]: !isChange }});
        }
      }

  return (
    <>
                    <div id="logistic-tab" className="clearfix eael-tab-content-item inactive" data-title-link="logistic-tab">
                                  <div data-elementor-type="page" data-elementor-id="5541" className="elementor elementor-5541">
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-8903bf3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8ec3272" data-id="8ec3272" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-eed6dbe elementor-widget elementor-widget-spacer" data-id="eed6dbe" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-28d08b1 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="28d08b1" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
      <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:(this.props.DataRow.logistics_transport_from[0] == undefined)?('#f84d43'):((this.props.DataRow.logistics_transport_from[0].status=='finalizado')?'#58d999':'#f8d014'),borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-truck" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      {/*<img width="100" height="94" src="https://www.food-chain.site/wp-content/uploads/2021/09/5.-1.png" className="attachment-full size-full" alt="" loading="lazy" />*/}
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Datos Transporte</h3></div></div>   </div>
        </div>
        <div className="elementor-element elementor-element-42682be elementor-widget elementor-widget-toggle" data-id="42682be" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">

          <ul>
{ 
                  (this.props.DataRow.logistics_transport_from[0]==undefined)
                  ?
                  'Sin datos'
                  :
                 this.props.DataRow.logistics_transport_from.map((value,index)=>{
                                return (<li key={index}>
                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=>{openedChild(this.state.openColapseChild['datafrom'+index],'datafrom'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"datafrom"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">{(value.guia_numero=='null')?('Sin número de guía'):('Ruta: '+value.seleccionar_ruta+' Guía numero: '+value.guia_numero)}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['datafrom'+index]} style={{width: '300%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"datafrom"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                                    <Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.seleccionar_ruta==null)?(null):((valuetwo.seleccionar_ruta=='null')?(null):((valuetwo.seleccionar_ruta=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Ruta:</label><br /> {valuetwo.seleccionar_ruta}</Col>)))}
                                                     {(valuetwo.otra_ruta==null)?(null):((valuetwo.otra_ruta=='null')?(null):((valuetwo.otra_ruta=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Otra Ruta:</label><br /> {valuetwo.otra_ruta}</Col>)))}
                                                     {(valuetwo.fecha_salida==null)?(null):((valuetwo.fecha_salida=='null')?(null):((valuetwo.fecha_salida=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Salida:</label><br /> {valuetwo.fecha_salida}</Col>)))}
                                                     {(valuetwo.hora_salida==null)?(null):((valuetwo.hora_salida=='null')?(null):((valuetwo.hora_salida=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora de Salida:</label><br /> {valuetwo.hora_salida}</Col>)))}
                                                     {(valuetwo.guia_numero==null)?(null):((valuetwo.guia_numero=='null')?(null):((valuetwo.guia_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Guia Numero:</label><br /> {valuetwo.guia_numero}</Col>)))}
                                                     {(valuetwo.precinto_numero==null)?(null):((valuetwo.precinto_numero=='null')?(null):((valuetwo.precinto_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Precinto Numero:</label><br /> {valuetwo.precinto_numero}</Col>)))}
                                                     {(valuetwo.nombre_chofer==null)?(null):((valuetwo.nombre_chofer=='null')?(null):((valuetwo.nombre_chofer=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre del Chofer:</label><br /> {valuetwo.nombre_chofer}</Col>)))}
                                                     {(valuetwo.id_chofer==null)?(null):((valuetwo.id_chofer=='null')?(null):((valuetwo.id_chofer=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>ID del Chofer:</label><br /> {valuetwo.id_chofer}</Col>)))}
                                                     {(valuetwo.tipo_camion==null)?(null):((valuetwo.tipo_camion=='null')?(null):((valuetwo.tipo_camion=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Tipo de Camion:</label><br /> {valuetwo.tipo_camion}</Col>)))}
                                                     {(valuetwo.placa_camion==null)?(null):((valuetwo.placa_camion=='null')?(null):((valuetwo.placa_camion=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Placa del Camion:</label><br /> {valuetwo.placa_camion}</Col>)))}
                                                     {(valuetwo.vehiculo_desinfectado==null)?(null):((valuetwo.vehiculo_desinfectado=='null')?(null):((valuetwo.vehiculo_desinfectado=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Vehiculo desinfectado:</label><br /> {valuetwo.vehiculo_desinfectado}</Col>)))}
                                                     {(valuetwo.producto_utilizado==null)?(null):((valuetwo.producto_utilizado=='null')?(null):((valuetwo.producto_utilizado=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Producto Utilizado:</label><br /> {valuetwo.producto_utilizado}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                        {/*{Object.values(value).map((valuetwo,index)=>{
                                       return ((valuetwo==null)?(null):((valuetwo=='null')?(null):((valuetwo=='undefined')?(null):(<li key={index}>{valuetwo}</li>))))
                                      })}*/}
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li>)
                })
              }
              </ul>
                </div>
        </div>
        </div>

          </div>
    </div>
        <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
<div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
        <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7c3b6fa" data-id="7c3b6fa" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-7c54668 elementor-widget elementor-widget-spacer" data-id="7c54668" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
            <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:(this.props.DataRow.logistics_transport_export[0]==undefined)?('#f84d43'):((this.props.DataRow.logistics_transport_export[0].status=='finalizado')?'#58d999':'#f8d014'),borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-plane-departure" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      {/*<img width="100" height="94" src="https://www.food-chain.site/wp-content/uploads/2021/09/5.-1.png" className="attachment-full size-full" alt="" loading="lazy" />*/}
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Datos Transporte Exportación</h3></div></div>   </div>
        </div>
        <div className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
          <ul>
{ 
                  (this.props.DataRow.logistics_transport_export[0]==undefined)
                  ?
                  'Sin datos'
                  :
                 this.props.DataRow.logistics_transport_export.map((value,index)=>{
                                return (<li key={index}>
                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=>{openedChild(this.state.openColapseChild['data'+index],'data'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"data"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">{value.modo_transporte}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['data'+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"data"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                                    <Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.modo_transporte==null)?(null):((valuetwo.modo_transporte=='null')?(null):((valuetwo.modo_transporte=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Modo de Transtporte:</label><br /> {valuetwo.modo_transporte}</Col>)))}
                                                     {(valuetwo.fecha_carga==null)?(null):((valuetwo.fecha_carga=='null')?(null):((valuetwo.fecha_carga=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Carga:</label><br /> {valuetwo.fecha_carga}</Col>)))}
                                                     {(valuetwo.nro_documento==null)?(null):((valuetwo.nro_documento=='null')?(null):((valuetwo.nro_documento=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nro de Documento:</label><br /> {valuetwo.nro_documento}</Col>)))}
                                                     {(valuetwo.contenedor_numero==null)?(null):((valuetwo.contenedor_numero=='null')?(null):((valuetwo.contenedor_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Contenedor Numero:</label><br /> {valuetwo.contenedor_numero}</Col>)))}
                                                     {(valuetwo.sello_numero==null)?(null):((valuetwo.sello_numero=='null')?(null):((valuetwo.sello_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Sello Numero:</label><br /> {valuetwo.sello_numero}</Col>)))}
                                                     {(valuetwo.unidad_carga==null)?(null):((valuetwo.unidad_carga=='null')?(null):((valuetwo.unidad_carga=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Unidad de Carga:</label><br /> {valuetwo.unidad_carga}</Col>)))}
                                                     {(valuetwo.total_unidades==null)?(null):((valuetwo.total_unidades=='null')?(null):((valuetwo.total_unidades=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Total Unidades:</label><br /> {valuetwo.total_unidades}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                        {/*{Object.values(value).map((valuetwo,index)=>{
                                       return ((valuetwo==null)?(null):((valuetwo=='null')?(null):((valuetwo=='undefined')?(null):(<li key={index}>{valuetwo}</li>))))
                                      })}*/}
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li>)
                })
              }
              </ul>
                </div>
        </div>
        </div>

          </div>
    </div>

              </div>
    </section><section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section></div>
                                        </div>
    </>
  );
}
}

//export default Elements;
