import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";

import {register_send_kdes, data_list_users_kdes, send_email_repeat_kdes, delete_user_kdes, add_kdes_user} from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import { NavLink as ComponentLink } from "react-router-dom";
//import {UserContext} from "layouts/store";
//function Elements() {
class SellUser extends React.Component {
state = {
  nombre:'',
  second_name:'',
  entidad:'',
  email:'',
  mess:'',
  notificationAlertRef: React.createRef(),
  btndisab:false,
  data_users_kdes:[],
  selected_user_tkdes:{},
  setAreawork:"",
  select_mrv:0
}
async componentDidMount(){
    //this.setState({data_traza:data.trazabilidad,data_traza_m:data.trazabilidad_meat,data_production_user:data.data_production,data_traza_g:data.traza_grain})
    this.initData()
}
initData= async ()=>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var trazabilidadSelect = ''
    if (jsondata.type_traza.indexOf('VF')!=-1) {
      trazabilidadSelect='V1F'
    }
    if (jsondata.type_traza.indexOf('V1F')!=-1) {
      trazabilidadSelect='V2F'
    }
    if (jsondata.type_traza.indexOf('V2F')!=-1) {
      trazabilidadSelect='V3F'
    }
    if (jsondata.type_traza.indexOf('V3F')!=-1) {
      trazabilidadSelect='V4F'
    }
  this.setState({type_traza:trazabilidadSelect})
  const dataListKdes = await data_list_users_kdes(this.props.setCode,trazabilidadSelect)
  //console.log(dataListKdes)
  var splitData = this.props.setCode.split('|')
  var dataPut={}
  dataListKdes.map((a,b)=>{
    dataPut[a.user_id+'_'+splitData[1]]=a.token_id==splitData[1]
    //return {[a.token_id]:a.token_id==splitData[1]}
  })
  this.setState({selected_user_tkdes:dataPut})
  this.setState({data_users_kdes:dataListKdes})
}
contentList=()=>{
  var content = []
  content=<>
              <Col md="3">
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nombre
                  </label>
                  <Input
                  value={this.state.nombre}
                  onChange={(e)=>{this.setState({nombre:e.target.value})}}
                    placeholder="Escribe un nombre"
                    type="text"
                    autoComplete="none"
                  />
              </Col>
              <Col md="3">
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Apellido
                  </label>
                  <Input
                  value={this.state.second_name}
                  onChange={(e)=>{this.setState({second_name:e.target.value})}}
                    placeholder="Escriba un apellido"
                    type="text"
                    autoComplete="none"
                  />
              </Col>
              <Col md="3">
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Entidad/Empresa
                  </label>
                  <Input
                  value={this.state.entidad}
                  onChange={(e)=>{this.setState({entidad:e.target.value})}}
                    placeholder="Escribe un entidad"
                    type="text"
                    autoComplete="none"
                  />
              </Col>
              <Col md="3">
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Correo electrónico
                  </label>
                  <Input
                  value={this.state.email}
                  onChange={(e)=>{this.setState({email:e.target.value})}}
                    placeholder="Escribe un correo"
                    type="email"
                  />
              </Col>
  </>
  return content
}
sendEmailRepeat=async(htmlTarget,user_id,name,empresa,email,tokenuuid)=>{
//<button className="btn changeBtnSend fas fa-check"></button>
   if (!jQuery(htmlTarget).hasClass('fa-check')) {
   htmlTarget.outerHTML='<span class="fas fa-check"></span>'
   var getResult = await send_email_repeat_kdes(user_id,name,empresa,email,tokenuuid,this.state.type_traza)
   if (getResult.success) {
     this.notify("success", "¡Correo enviado!", "Invitación enviada.");
   }
  }
}
deleteUser=async(email,idUser)=>{
  var splitData = this.props.setCode.split('|')
  if (splitData[1]==null || splitData[1]=='null') {
    this.notify("danger", "No se puede guardar", "Token no existe.");
  }else{
    if (window.confirm('Deseas eliminar a este usuario?')) {
      var getResult = await delete_user_kdes(email,idUser)
      if (getResult.success) {
        this.notify("success", "¡Eliminado!", "Usuario eliminado exitosamente.");
        this.initData()
      }
    }
  }

}
changeAvtive=async(valued,data)=>{
     var splitData = this.props.setCode.split('|')

  if (splitData[1]==null || splitData[1]=='null') {
    this.notify("danger", "No se puede guardar", "Token no existe.");
  }else{

    var getData = this.state.selected_user_tkdes
    getData[data.user_id+'_'+splitData[1]]=valued
    this.setState({selected_user_tkdes:getData})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var dataResult = await add_kdes_user(valued,data.user_id,data.empresa,'options',data.type_traza+'*'+splitData[0],data.name,data.email,1,splitData[1],jsondata.userType)
    if (dataResult.success) {
      this.notify("success", "¡Modificado!", "Usuario modificado exitosamente.");
      this.initData()
    }
  }
}
contentDataKdes=()=>{
var content = []
  this.state.data_users_kdes.map((a,b)=>{ 
    content[b]=<>
                    <tr>

                     <td style={{border: 'solid 1px'}}>
                      <span className="text-muted">
                      <input type="checkbox" value={a.user_id} checked={this.state.selected_user_tkdes[a.user_id+'_'+a.token_id]} 
                      onChange={(e)=>{ 
                        this.changeAvtive(e.target.checked,a)
                      }}
                      />
                      </span>
                     </td>

                     <td style={{border: 'solid 1px'}}>
                      <span className="text-muted">
                      {a.name}
                      </span>
                     </td>
                     <td style={{border: 'solid 1px'}}>
                      <span className="text-muted">
                      {a.empresa}
                      </span>
                     </td>
                     <td style={{border: 'solid 1px'}}>
                      <span className="text-muted">
                      {a.email}
                      </span>
                     </td>

                     <td style={{border: 'solid 1px'}}>
                      <span className="text-muted">
                        {(a.active_validation==1)?(<span className="fas fa-check"></span>):(<>
                          <button className="btn changeBtnSend fas fa-share-square" title="Volver a enviar invitación" 
                          onClick={(contenHtml)=>{
                          var splitData = this.props.setCode.split('|')

                          if (splitData[1]==null || splitData[1]=='null') {
                            this.notify("danger", "No se pudo copiar", "Token no existe.");
                          }else{
                            this.sendEmailRepeat(contenHtml.target,a.user_id_collab,a.name,a.empresa,a.email,splitData[1])
                          }

                          }}> 
                          </button>
                        <button className="btn" title="Copiar dirección" 
                        onMouseOut={()=>{
                          if (jQuery('.changeIconClipModal').hasClass('fa-check')) {
                            jQuery('.changeIconClipModal').removeClass('fas fa-check')
                            jQuery('.changeIconClipModal').addClass('far fa-copy')
                          }
                        }}
                        onClick={(copyText)=>{
                          // copyText.target.select();
                          // copyText.target.setSelectionRange(0, 99999);
                          var splitData = this.props.setCode.split('|')
                          if (splitData[1]==null || splitData[1]=='null') {
                            this.notify("danger", "No se pudo copiar", "Token no existe.");
                          }else{
                            var strUrl = 'https://foodchaintrazabilidad.com/home/register-kdes?email='+a.email+'?token='+splitData[1]
                            navigator.clipboard.writeText(strUrl);
                            jQuery('.changeIconClipModal').removeClass('far fa-copy')
                            jQuery('.changeIconClipModal').addClass('fas fa-check')
                          }
                        }}> <span className="far fa-copy changeIconClipModal"></span> </button>

                          </>)}
                      </span>
                     </td>

                     <td style={{border: 'solid 1px'}}>
                      <span className="text-muted">
                        <a className="font-weight-bold" title="Eliminar Usuario" href="javascript:;" onClick={()=>{this.deleteUser(a.email,a.user_id_collab)}} >
                          <i class="fas fa-eraser" style={{color: 'black'}}></i>
                        </a>
                      </span>
                     </td>
                   </tr>
    </>
   })
return content
}
    notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
render(){
  const saveColab = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var globlalthis = this
            this.setState({btndisab: true})
    if ( this.state.nombre=='' || this.state.second_name=='' || this.state.email=='' || this.state.entidad=='') {
            this.setState({mess: 'Todos los campos son requeridos'})
            this.setState({btndisab: false})
    }else{
    var valid_email = String(this.state.email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    if (!valid_email) {
            this.setState({mess: 'Ingrese un correo valido'})
            this.setState({btndisab: false})
            return
    }

        this.setState({btndisab: true})

         const response = await register_send_kdes(this.state.nombre, this.state.second_name, this.state.email, this.state.entidad, 5,this.state.type_traza, this.props.setCode,jsondata.userType)
         .catch(function (message){
          if (message.response.status==500) {
           globlalthis.setState({mess: 'Ha ocurrido un error, intente mas tarde.'})
          }
          if (message.response.status==401) {
           globlalthis.setState({mess: message.response.data.errors.email[0]})
          }
          if (message.response.status==422) {
            globlalthis.setState({mess: 'No puedes registrar este correo.' })
          }
          if (message.response.status==403) {
            globlalthis.setState({mess: message.response.data.error })
          }
           globlalthis.setState({btndisab: false})
         })
           if (response==undefined) {
           return;
         }else{
           const {data} = response;
           if (!data.success) {
           globlalthis.setState({btndisab: false})
           globlalthis.setState({mess: data.message})
               return;
           }
         }
         this.initData()
         globlalthis.setState({
          nombre:'',
          second_name:'',
          entidad:'',
          email:'',
          btndisab: false
         })
         this.notify("success", "¡Registrado exitosamente!", "Invitación enviada.");
         //this.props.history.push('/admin/users')
    }
    //setTimeout(function(){history.push('/home/login')}, 3000);

  }


  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <Container className="mt--1" fluid>

            <Row>
              <Col md="12">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Usuario a enviar:
                  </label>
                  <select value={this.state.type_traza} style={{border: 'none'}}>
                    <option value="V1F">Planta</option>
                    <option value="V2F">Importador</option>
                    <option value="V3F">Distribuidor</option>
                    <option value="V4F">Detallista</option>
                  </select>
{/*                  {(this.state.type_traza=='V1F')?(<span style={{color: 'black',fontSize: '13px',fontWeight: 700}}> Planta</span>):(null)}
                  {(this.state.type_traza=='V2F')?(<span style={{color: 'black',fontSize: '13px',fontWeight: 700}}> Importador</span>):(null)}
                  {(this.state.type_traza=='V3F')?(<span style={{color: 'black',fontSize: '13px',fontWeight: 700}}> Distribuidor</span>):(null)}
                  {(this.state.type_traza=='V4F')?(<span style={{color: 'black',fontSize: '13px',fontWeight: 700}}> Detallista</span>):(null)}*/}
              <hr style={{marginTop: '10px',marginBottom: '10px'}} />
              </Col>

              {this.contentList()}
          <Col md="12">
        <hr style={{marginTop: '10px',marginBottom: '10px'}} />
            <table className="align-items-center table-flush" style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}}>
              <thead className="thead-light">
                <tr>
                  <th style={{border: 'solid 1px'}}>Asignar</th>
                  <th style={{border: 'solid 1px'}}>Nombre y apellido</th>
                  <th style={{border: 'solid 1px'}}>Entidad/Empresa</th>
                  <th style={{border: 'solid 1px'}}>Correo</th>
                  <th style={{border: 'solid 1px'}}>Validación</th>
                  <th style={{border: 'solid 1px'}}>Opciones</th>
                </tr>
              </thead>
              <tbody>

              {this.contentDataKdes()}

              </tbody>
            </table>
          </Col>

            </Row>
            <hr style={{marginTop: '10px',marginBottom: '10px'}} /><br />
                   {this.state.mess ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button onClick={()=>{saveColab()}} disabled={this.state.btndisab} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Invitar</span>
                </Button>
              </Col>
          </Row>
      </Container>
    </>
  );
 }
}
class ModalInviteUser extends React.Component {
    //static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false,
    dataUser:[]
  }
 async componentDidMount(){
    this.props.contexto.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    //var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.props.contexto.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.props.contexto.valueGlobal
    this.setState({dataUser:dataPermission})
    if (dataPermission.role==1) {
      this.setState({get_permission:true})
    }else{
      this.setState({get_permission:false})
    }
      }  
render(){
  this.setInfoInit()  
  return (
    <>
  <Modal isOpen={this.props.sendOpenModal} size="xl">
           <CardHeader>
            <Row>
                <Col md={11}>
                    <h3 className="mb-0">Invitar Usuarios</h3>
                </Col>
                <Col md={1}>
                    <a href="#" onClick={()=> {this.props.setInfoInvoice(null,false)} }>
                    <h3 class="mb-0">X</h3>
                    </a>
                </Col>
            </Row>
          </CardHeader>
        <ModalBody>
            <CardBody>          
                  <SellUser {...this.props} dataUser={this.state.dataUser} />
              </CardBody>
            </ModalBody>
        <ModalFooter>
        <Button onClick={()=> {this.props.setInfoInvoice(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
 }
}

export default ModalInviteUser;
