import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
//filter: hue-rotate(28deg);
import config from "config";
import {PutContentFileG} from 'views/pages/examples/Trazability/SectionsViewTrace/InfoLogistic'

export default class SectionDocuments extends React.Component {
  async componentDidMount(){
  }
  contentRows = () =>{
    const datacontent = [
    {table:'info_producer_g',title:'Info Productor'},
    {table:'info_crops_g',title:'Info del Cultivo'},
    {table:'info_prev_g',title:'Info Previa'},
    {table:'info_financing_g',title:'Financiamiento'},
    {table:'production_land_preparation_g',title:'Preparación de tierra'},
    {table:'production_irrigation_g',title:'Riego'},
    {table:'production_seed_g',title:'Semilla'},
    {table:'production_fertilizer_g',title:'Fertilización'},
    {table:'production_producer_monitoring_g',title:'Monitoreo Productor'},
    {table:'production_sowing_g',title:'Siembra'},
    {table:'production_sowing_transplant',title:'Transplante'},
    // {table:'production_info_satelite',title:'Satelite NDVI (Vigor del Cultivo)'},
    // {table:'production_info_satelite_ndre',title:'Satelite NDRE (Salud del Cultivo)'},
    // {table:'production_info_satelite_ndmi',title:'Satelite NDMI (Humedad de la Vegetación)'},
    // {table:'production_info_satelite_ndhs',title:'Satelite HR-SUELO (Humedad Relativa del Suelo)'},
    {table:'hading_info_weeds',title:'Info de malezas'},
    {table:'hading_inventory_weeds',title:'Inventario de malezas'},
    {table:'hading_info_plague',title:'Info de Plagas'},
    {table:'hading_inventory_plague',title:'Inventario de Plagas'},
    {table:'hading_info_diseases',title:'Info de enferedades'},
    {table:'harvest_info_harvest',title:'Info de cosecha'},
    {table:'harvest_info_machinery',title:'Calibración de maquinaria'},
    {table:'harvest_info_transport',title:'Info de transporte'},
    {table:'harvest_info_pos_harvest',title:'Info de Pos-Cosecha'},
    ]
    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
                      <Card key={indx} style={{width: '100%'}}>
                      <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- {val.title} </span></CardHeader>
                      <Col md={12}>
                      <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="row">
                                    <PutContentFileG
                                    data_function={this.props.DataRow} 
                                    type_function={val.table}
                                    />
                      </div>
                      </CardBody>
                      </Col>
                      </Card>
                  </>
    })
    return content
  }
  render(){
  return (
    <>
    <div id="documentos-tab" className="clearfix eael-tab-content-item active" data-title-link="documentos-tab">
    <div data-elementor-type="section" data-elementor-id="5570" className="elementor elementor-5570">
    <div className="elementor-section-wrap">
    <section className="elementor-section elementor-top-section elementor-element elementor-element-1fac86c8 elementor-section-content-middle animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="1fac86c8" data-element_type="section" id="services" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-497571a6" data-id="497571a6" data-element_type="column">
    <div className="elementor-widget-wrap elementor-element-populated">

    {this.contentRows()}

{/*    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PRODUCCIÓN: Datos </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="production_create"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PRODUCCIÓN: Fertilizante/Agroquímico </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="production_fertilizer"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COSECHA: Datos </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_cosecha"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COSECHA: Transporte Campo </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="transport_cosecha"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COSECHA: Vehiculo </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="transport_vehicule_cosecha"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: producto </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_product"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Muestra de calidad </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_sample"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Peso del producto </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_weight_product"
                  />

    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Tratamiento </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_treatment"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Información de empaque </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_information_empaque"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Pre-enfriamiento </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_pre_cold"
                  />

    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Enfriamiento </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_cold"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- LOGISTICA Y TRANSPORTE: Embarque </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="logistics_transport_shipment"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- LOGISTICA Y TRANSPORTE: Transporte </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="logistics_transport_transporte"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>
 

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COMERCIALIZACIÓN: Mercado nacional </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="commercialization_market"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COMERCIALIZACIÓN: Exportación </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="commercialization_export"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>
*/}
    </div>

    </div>
    </div>
    </section>
    </div>
    </div>
    </div>
    </>
  );
}
}

//export default Elements;
