import AppMenu from './AppMenu';

const AppSidebar = () => {
    return (
        <div >
            <AppMenu />
        </div>
        
    );
};

export default AppSidebar;
