import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import {PutContent, PutContentSub, PutContentStatus} from 'views/pages/examples/Trazability/SectionsViewTrace/InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogisticMRV'
import jQuery from "jquery"
import { upload_irgi, get_info_irgi } from "network/ApiAxios";
import config from "config";
const datacontent = [
{table:'info_producer_vf_gc',title:'Info Productor',icon:'far fa-newspaper','multiple_tables':0},
{table:'info_crops_vf_gc',title:'Info del Cultivo',icon:'fas fa-info','multiple_tables':0},
{table:'info_prev_vf_gc',title:'Gestión de cultivos previa',icon:'fas fa-undo-alt','multiple_tables':0},
{table:'info_financing_g',title:'Financiamiento',icon:'fas fa-dollar-sign','multiple_tables':0},
]
export default class SectionGasCo extends React.Component {
  state={
    openColapse:[false],
    openColapseChild:[false],
    putFile:null,
    dataFile:null
  }
  async componentDidMount(){
    this.initData()
  }
   initData = async () => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var db = new URLSearchParams(window.location.search).get("db")
    var codigo = new URLSearchParams(window.location.search).get("code")
    var get_data = await get_info_irgi(db,codigo,jsondata._id,this.props.DataRow.data_user_ad)
    this.setState({dataFile:get_data})
  }
      saveUploadFile=async()=>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var db = new URLSearchParams(window.location.search).get("db")
        var codigo = new URLSearchParams(window.location.search).get("code")
        var getresult = await upload_irgi(db,codigo,this.state.putFile,jsondata._id,this.props.DataRow.data_user_ad)
        if (getresult) {
          this.initData()
          this.setState({putFile:null})
        }
      }
  render(){
  return (
    <>
            <div>
            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
            <div className="elementor-widget-wrap"></div></div>

            <div className="elementor-widget-wrap elementor-element-populated">
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item" style={{zIndex: '99'}}>
                  <h6 data-tab="1" role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                    <div style={{marginBottom:'10px'}} className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
                    <div className="elementor-widget-container">

                    <div className="btn" onClick={()=>{ jQuery("#inputUploadFile").click() }}>
                      <span className="eael-tab-title title-after-icon" style={{fontWeight: 'normal',fontSize: '12px'}}>Subir documento indices de reducción de gases invernaderos <span className="fas fa-upload"></span></span>
                      <input onChange={(e)=>{this.setState({putFile:e.target.files[0]})}} style={{display:"none"}} type="file" id="inputUploadFile" />
                    </div>

                    {(this.state.putFile==null)?(null):(<>
                      <div className="col-md-12">{this.state.putFile.name}</div>
                      <button className="btn" onClick={()=>{this.saveUploadFile()}}>Subir documento</button>
                      </>)}

                    </div>
                    </div>
                  </h6>
                <div className="col-md-12">
                  {(this.state.dataFile==null)?(null):(<>
                      <Button style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={()=> {window.open(config.URL_PRODUCTION+"/storage/file_documents/"+this.state.dataFile )}}>{String(this.state.dataFile)?.split('/')[1]}</Button>
                    </>)}
                </div>
                </div>
                <div className="percent_load"></div>
                </div>
                </div>
                </div>
                <div className="elementor-element elementor-element-81e561d elementor-widget elementor-widget-spacer" data-id="81e561d" data-element_type="widget" data-widget_type="spacer.default">
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
            </div>
            </div>
            </div>
            </div>
        </div>
    </>
  );
}
}