import React, {createRef} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import { get_training } from "network/ApiAxios";
import config from "config";
import jQuery from 'jquery';
import {UserContext} from "layouts/store";
import SignatureCanvas from 'react-signature-canvas';

class Training extends React.Component {
  static contextType = UserContext
  constructor(props){
    super(props);
  }
state={
  trainng_data:[]
}
async componentDidMount(){
this.setData()
}
setData = async () => {
  var data = await get_training()
  this.setState({trainng_data:data})
}
putUrl=(link,title)=>{
  var get_link = null
  if (link.indexOf('www')!=-1) {
    get_link = <iframe src={link.replaceAll(' ','').replaceAll('watch?v=','embed/')+'?rel=0&autoplay=1'} frameborder="0" allowFullScreen title={title} />
  }
  return get_link
  //console.log()
}
render(){

  return (
    <>

      <div className="mt-3">
        <Row>
          <div>
            <Card className="p-0">
              <CardHeader>
                <Row className="align-items-center">
                  <Col md="12">
                    <h5 className="m-0" style={{ color: '#002a5c' }}>
                        Videos de Capacitacion
                    </h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="col-md-12">
                  <Row>
                    {this.state.trainng_data.map((a,b)=>{
                      return (<><Col md={4} style={{borderBottom: 'solid 1px #c1c1c1',padding: 0,textAlign: 'center',marginBottom: '5px',borderRight: 'solid 1px #c1c1c1'}}>
                          <div className="col-md-12">
                            <span className="block" style={{fontSize: '15px', fontWeight: 'bold', color: "#002A5C"}}>{a.title}</span>
                            {this.putUrl(a.link,a.title)}
                            {/*<p style={{color: '#6b7280'}}>{a.description}</p>*/}
                            <p className="text-500" style={{textAlign: 'justify',fontWeight: '600'}}>{a.description}</p>

                          </div>
                        </Col></>)
                    })}
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
 }
}

export default Training;
