import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import React, {createRef} from "react";
import { save_vf, data_trazabilidad,get_permission, data_production, main_content } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAran } from "network/CodigoArancelario";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import config from "config";
import {getContent,PutFormContentFinal} from 'views/pages/forms/LogisticsForm';
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import { stateData } from "network/DataState";

export default class ProduccionDatos extends React.Component {

  state={
    field_params:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],
    dataStatesS:0,
    content_render:null
  }
  async componentDidMount(){
    var getData = await main_content('production_create')
    this.setState({field_content_log:getData})
    this.initData()
  }
  initData = async ()=>{
  this.setState({field_content: getContent(this.state.field_content_log).s_data})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataTrazabilidadvf = await data_trazabilidad(jsondata._id,gerParam,null,'production_create',JSON.stringify(getContent(this.state.field_content_log).f_data))
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }
    setDataCampos = (data)=>{
      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })
    }
    cleanData = () =>{
          this.setState({idT:'noind'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })          
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
     setFieldValue = (value, field, isrequired) =>{
       this.setState({field_params: {...this.state.field_params, [field]:value }})
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default==null) {
          var for_put_data=this.contentForSelect(value_option,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]: for_put_data }})

        }else{
          if (list_default=='ldp') {
            var var_data = []
            var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})

          }
          if (list_default=='cafv') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})

          }
          if (list_default=='cac') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})

          }
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
  render(){

  return (
    <>
<Row>
            <PutFormContentFinal 
            content={this.state.field_content}
            field_params={this.state.field_params}
            optionsSelect={this.state.optionsSelect}
            setFieldValue={this.setFieldValue}
            setFieldValueSelect={this.setFieldValueSelect}
            geolocacion={this.geolocacion}

             />
            </Row>
    </>
  );
}


}

//export default Elements;

// import React, {createRef} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import { data_production } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import jQuery from 'jquery';
// import config from "config";

// export default class ProduccionDatos extends React.Component {
//   state={
//     codigo:'',
//     user_id:'',
//     btnd:false,
//     notificationAlertRef: React.createRef()
//   }
//     async componentDidMount(){
//     if(this.props.paramsCode==undefined || this.props.paramsCode==null){
//       var codigoTraza = 'nocode';
//     }else{
//       var codigoTraza = this.props.paramsCode;
//     }
//         var userdata = localStorage.getItem('user');
//         var jsondata = JSON.parse(userdata);
//         var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
//         var dataTrazabilidadvf = await data_production(jsondata._id,codigoTraza,codigoTrazaTwo)
//           var documentForm = jQuery("#insertDocumentShow").val()
//           if (documentForm=='none') {
//             this.setState({documentForm:documentForm})
//           }
//         if (dataTrazabilidadvf==null) {
//           this.setState({
//               production_id:'noind',
//               nombre_finca:'',
//               area_total:'',
//               cantidad_lotes:'',
//               tipo_semilla:'',
//               variedad:'',
//               marca:'',
//               fecha_siembra:new Date().getFullYear() + "-11-23",
//               lote_numero:'',
//               georeferencia:'',
//               informacion_adicional:'',
//           })
//         }else{
//           this.setDataCampos(dataTrazabilidadvf)
//         }
//   }
//     setDataCampos = (data)=>{
//       this.setState({
//               production_id:data.id,
//               nombre_finca:data.nombre_finca,
//               area_total:data.area_total,
//               cantidad_lotes:data.cantidad_lotes,
//               tipo_semilla:data.tipo_semilla,
//               variedad:data.variedad,
//               marca:data.marca,
//               fecha_siembra:data.fecha_siembra,
//               lote_numero:data.lote_numero,
//               georeferencia:data.georeferencia,
//               informacion_adicional:data.informacion_adicional,
//               file_document:data.archivo,

//       })
//     }
//   render(){
//   return (
//     <>
//             <Row>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre de la finca
//                   </label>
//                   <br />
//                  <span className="alert-title" data-notify="title">{this.state.nombre_finca}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Área Total
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.area_total}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Cantidad Total de Lotes
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.cantidad_lotes}</span>
//                 </FormGroup>
//               </Col>
//               </Row>
//               <Row>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Tipo de Semilla
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.tipo_semilla}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Variedad
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.variedad}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Marca
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.marca}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha Siembra
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.fecha_siembra}</span>
//                 </FormGroup>
//               </Col>                                
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Numero
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.lote_numero}</span>
//                 </FormGroup>
//               </Col>
//             <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Georeferencia
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.georeferencia}</span>
//                 </FormGroup>
//               </Col>  
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}} sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                                    <br />
//                  <span className="alert-title" data-notify="title">{this.state.informacion_adicional}</span>
//                 </FormGroup>
//               </Col>              
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}} sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento/Imagen
//                 </label>
//                                      <br />
//                <span className="alert-title" data-notify="title">{(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>Ver archivo</a>)}</span>
//               </Col> )}
//             </Row>

//     </>
//   );
// }
// }

// //export default Elements;
