import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production, data_trazabilidad, main_content, data_fertilizer_production, fertilizer_production, delete_fertilizer } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import FormCosechaTransport from "./Subs/FormCosecha/FormCosechaTransport";
import FormCosechaVehicule from "./Subs/FormCosecha/FormCosechaVehicule";
export class SeptionCosecha extends React.Component {
  state={
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    link_id:null,
    delete_register:null,
    get_content:[],
    get_first_info:''
  }
async componentDidMount(){
  var getData = await main_content('transport_cosecha')
  getData.map((a,b)=>{
    if (a.include_info==1) {
      this.setState({get_first_info:a.name_field})
    }
  })
  this.setState({get_content:getData})
  this.initData(this.props.getIdCosecha)
}
  initData = async (id_set,verify)=>{
    this.setState({sendOpenModal:verify,link_id:id_set})
  }
deleteRegister = async (id_set) => {
  await this.initData(id_set,true)
   if (window.confirm("Deseas eliminar este registro?")) {
    this.setState({delete_register:'delete_register'})
//     var field_data = Object.entries(this.state.field_params)
//     var field_data_name = Object.entries(this.state.field_params_title)
// console.log(field_data)
    // var dataReturn = await delete_fertilizer(id_set,jsondata.user_id_origin,field_data,field_data_name,gerParam,'transport_cosecha','Datos Fertilizantes / Agroquímicos')
    //         if (dataReturn=="exito") {
    //         this.setState({mess:'Se eliminó exitosamente.'})
    //         this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    //         this.props.refreshData()
    //       }else{
    //         this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
    //         this.setState({btnd:false})
    //       }
  }
  this.initData(id_set,false)
 }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }
  deleteRegisterListen = async (id_set,user_id_origin,field_data,field_data_name,gerParam,field_name,label_name) => {
      var dataReturn = await delete_fertilizer(id_set,user_id_origin,field_data,field_data_name,gerParam,field_name,label_name)
      this.setState({delete_register:null})
              if (dataReturn=="exito") {
              this.setState({mess:'Se eliminó exitosamente.'})
              this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            }else{
              this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
              this.setState({btnd:false})
            }    
  }
  render(){
  return (
    <>
{(this.state.link_id==null)?(null):(<>
        <CardHeader style={{padding: 0}}>
          <h3 className="mb-0">Datos Transporte de Campo {this.props.getIndex+1}</h3>
        </CardHeader>    
        <FormCosechaTransport {...this.props} get_content={this.state.get_content} deleteRegisterListen={this.deleteRegisterListen} for_delete={this.state.delete_register} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal}/>
        <FormCosechaVehicule {...this.props} deleteRegisterListen={this.deleteRegisterListen} for_delete={this.state.delete_register} putLinkId={this.state.link_id}/>
</>)}    
    </>
  );
 }
}
 export default class ModalFieldTransport extends React.Component {
    
   render(){
   return (
     <>
             <Row>
             {
                   this.props.sendInfoProps.dataTransport.map((value,index)=>{
                             return (
                                      <Col md="12" className="text-center">
                                      <SeptionCosecha getIdCosecha={value.id} getIndex={index} />
                                      </Col>
                                     ) 
                      })
            }
             </Row>
     </>
   );
  }
 }


// import React, {createRef, useEffect} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { create_trasport, create_vehicule, data_transport_vehicule_cosecha, delete_trans_vehicule } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import jQuery from 'jquery';
// import config from "config";

// export default class ModalFieldTransport extends React.Component {
    
//   render(){
//   return (
//     <>
//           <CardBody>
//             <Row>
//             {
//                   this.props.sendInfoProps.dataTransport.map((value,index)=>{
//                             return (
//                                      <Col md="12" className="text-center">
//                                      <SeptionCosecha getIdCosecha={value.id} getIndex={index} />
//                                      </Col>
//                                     ) 
//                      })
//            }
//             </Row>
//          </CardBody>
//     </>
//   );
//  }
// }

// export class SeptionCosecha extends React.Component {
//   state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//   }
//     async componentDidMount(){
//         this.setInfoTransVehi(this.props.getIdCosecha)
//   }
//        setInfoTransVehi = async (id_set) =>{
//               var documentForm = jQuery("#insertDocumentShow").val()
//           if (documentForm=='none') {
//             this.setState({documentForm:documentForm})
//           }
//       if (id_set!=null) {      
//               var dataTrazabilidadvf = await data_transport_vehicule_cosecha(id_set)
//           if (dataTrazabilidadvf==null) {
//             this.setDataCamposEmpty()
//           }else{
//             this.setDataCampos(dataTrazabilidadvf)
//           }
//       }else{
//             this.setDataCamposEmpty()
//       }
//     }
//       setDataCamposEmpty = ()=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//             this.setState({
//                 transport_id:'noind',
//                 fecha_salida:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 hora_salida:timeString,
//                 nombre_finca:'',
//                 lote_numero:'',
//                 nombre_producto:'',
//                 variedad_producto:'',
//                 tipo_empaque:'',
//                 total_unidades:'',
//                 peso_unidad:'',
//                 peso_total:'',
//                 informacion_adicional_transport:'',
//                 file_document_trasport:undefined,

//                 vehicule_id:'noind',
//                 tipo_vehiculo:'',
//                 capacidad:'',
//                 placa:'',
//                 nombre_chofer:'',
//                 vehiculo_desinfectado:'',
//                 informacion_adicional_vehicule:'',
//                 file_document_vehicule:undefined,
//             })
//       }

//     setDataCampos = (data)=>{
//      if (data.transport!=null) {
//         this.setState({
//                 transport_id:data.transport.id,
//                 fecha_salida:data.transport.fecha_salida,
//                 hora_salida:data.transport.hora_salida,
//                 nombre_finca:data.transport.nombre_finca,
//                 lote_numero:data.transport.lote_numero,
//                 nombre_producto: data.transport.nombre_producto,
//                 variedad_producto: data.transport.variedad_producto,
//                 tipo_empaque:data.transport.tipo_empaque,
//                 total_unidades:data.transport.total_unidades,
//                 peso_unidad:data.transport.peso_unidad,
//                 peso_total:data.transport.peso_total,
//                 informacion_adicional_transport:data.transport.informacion_adicional,
//                 file_document_trasport:data.transport.archivo,


//         })
//       }
//       if (data.vehicule!=null) {
//         this.setState({
//                 vehicule_id:data.vehicule.id,
//                 tipo_vehiculo:data.vehicule.tipo_vehiculo,
//                 capacidad:data.vehicule.capacidad,
//                 placa:data.vehicule.placa,
//                 nombre_chofer:data.vehicule.nombre_chofer,
//                 vehiculo_desinfectado:data.vehicule.vehiculo_desinfectado,
//                 informacion_adicional_vehicule:data.vehicule.informacion_adicional,
//                 file_document_vehicule:data.transport.archivo,
//         })
//       }
//     }
//   render(){
//     return(
//       <>
//               <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Datos Transporte de Campo {this.props.getIndex+1}</h3>
//           </CardHeader>
//            <CardBody className="text-center">
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha Salida
//                   </label>
//                   <br />
//                   <span>{this.state.fecha_salida}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora Salida
//                   </label>
//                   <br />
//                   <span>{this.state.hora_salida}</span>
//                   </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre de la finca
//                   </label>
//                   <br />
//                   <span>{this.state.nombre_finca}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Número
//                   </label>
//                   <br />
//                   <span>{this.state.lote_numero}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre del Producto
//                   </label>
//                   <br />
//                   <span>{this.state.nombre_producto}</span>

//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Variedad del producto
//                   </label>
//                   <br />
//                   <span>{this.state.variedad_producto}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Tipo de Empaque
//                   </label>
//                   <br />
//                   <span>{this.state.tipo_empaque}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades
//                   </label>
//                   <br />
//                   <span>{this.state.total_unidades}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso por Unidad
//                   </label>
//                   <br />
//                   <span>{this.state.peso_unidad}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso total
//                   </label>
//                   <br />
//                   <span>{this.state.peso_total}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_transport}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_trasport==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_trasport}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>

//           </CardBody>
//         </Card>

//         <Card className="mb-4" style={{display:(this.state.tipo_vehiculo==null)?'none':'flex'}}>
//           <CardHeader>
//             <h3 className="mb-0">Vehiculo</h3>
//           </CardHeader>
//            <CardBody className="text-center">
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Tipo de Vehiculo
//                   </label>
//                   <br />
//                   <span>{this.state.tipo_vehiculo}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Capacidad
//                   </label>
//                   <br />
//                   <span>{this.state.capacidad}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Placa
//                   </label>
//                   <br />
//                   <span>{this.state.placa}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre del Chofer
//                   </label>
//                   <br />
//                   <span>{this.state.nombre_chofer}</span>

//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Vehiculo desinfectado
//                   </label>
//                   <br />
//                   <span>{this.state.vehiculo_desinfectado}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_vehicule}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_vehicule==null) ? "Sin archivo": (<a target="_blank" href={"http://44.201.19.226/storage/file_documents/"+this.state.file_document_vehicule}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//           </CardBody>
//         </Card>
//       </>
//       )
//   }
// }