import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport_export,data_logstic_transport,data_logstic_export, deleteTransExport } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";

 export default class ModalStartLogistics extends React.Component {
    async componentDidMount(){
    }
  render(){
  return (
    <>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataTransporte.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                      <SectionLogis getIdLogis={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataTransportExtend={this.props.sendInfoProps.dataTransportAll} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataTransportExport.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                      <SectionLogisExport getIdLogis={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataTransportExtend={this.props.sendInfoProps.dataTransportAll} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

export class SectionLogis extends React.Component {
  state={
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
  }
  async componentDidMount(){
        this.setInfoTransExport(this.props.getIdLogis,this.props.dataTransportExtend)
  }
      setDataCamposEmpty = ()=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
              var gerParam = this.props.setParamsUrl
            this.setState({
                transport_id:'noind',
                seleccionar_ruta:'',
                otra_ruta:'',
                fecha_salida:completeDate,
                hora_salida:timeString,
                guia_numero:'',
                precinto_numero:'',
                nombre_chofer:'',
                id_chofer:'',
                tipo_camion:'',
                placa_camion:'',
                vehiculo_desinfectado:'',
                producto_utilizado:'',
                informacion_adicional_transport:'',
                file_document_trasport:undefined,
            })
      }
          setDataCampos = (data)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
     if (data.transport!=undefined) {
        this.setState({
                transport_id:(data.transport.id=="null")?(null):(data.transport.id),
                seleccionar_ruta:(data.transport.seleccionar_ruta=="null")?(null):(data.transport.seleccionar_ruta),
                otra_ruta:(data.transport.otra_ruta=="null")?(null):(data.transport.otra_ruta),
                fecha_salida:(data.transport.fecha_salida=="null")?(null):(data.transport.fecha_salida),
                hora_salida:(data.transport.hora_salida=="null")?(null):(data.transport.hora_salida),
                guia_numero:(data.transport.guia_numero=="null")?(null):(data.transport.guia_numero),
                precinto_numero:(data.transport.precinto_numero=="null")?(null):(data.transport.precinto_numero),
                nombre_chofer:( data.transport.nombre_chofer=="null")?(null):( data.transport.nombre_chofer),
                id_chofer:(data.transport.id_chofer=="null")?(null):(data.transport.id_chofer),
                tipo_camion:(data.transport.tipo_camion=="null")?(null):(data.transport.tipo_camion),
                placa_camion:(data.transport.placa_camion=="null")?(null):(data.transport.placa_camion),
                vehiculo_desinfectado:(data.transport.vehiculo_desinfectado=="null")?(null):(data.transport.vehiculo_desinfectado),
                producto_utilizado:(data.transport.producto_utilizado=="null")?(null):(data.transport.producto_utilizado),
                informacion_adicional_transport:(data.transport.informacion_adicional=="null")?(null):(data.transport.informacion_adicional),
                file_document_trasport:(data.transport.archivo=="null")?(null):(data.transport.archivo),
        })
      }

    }
  setInfoTransExport = async (id_set,dataAll) =>{

          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = this.props.setParamsUrl

              if (id_set!=null) {      
                      var dataTransExportLogisc = await data_logstic_transport(dataAll,id_set)

                  if (dataTransExportLogisc==null) {
                    this.setDataCamposEmpty()
                  }else{
                    this.setDataCampos(dataTransExportLogisc)

                  }
              }else{
                    this.setDataCamposEmpty()
              }
          
    }
  render(){
    return(
      <>
              <Card className="mb-4">
          <CardHeader>
          <Row>
            <Col md={12}>
              <h3 className="mb-0">Transporte {this.props.getIndex+1}</h3>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                  Seleccionar Ruta:
                  </label>
                  <br /><span>
                    {(this.state.seleccionar_ruta=="undefined")?(null):(this.state.seleccionar_ruta)}
                  </span>
                </FormGroup>
              </Col>
              {(this.state.seleccionar_ruta!='otro')?(null):(
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Otra ruta
                  </label>
                  <br /><span>
                    {(this.state.otra_ruta=="undefined")?(null):(this.state.otra_ruta)}
                    </span>
                </FormGroup>
              </Col>
                )}
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Salida
                  </label>
                  <br /><span>
                    {(this.state.fecha_salida=="undefined")?(null):(this.state.fecha_salida)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Salida
                  </label>
                  <br /><span>
                    {(this.state.hora_salida=="undefined")?(null):(this.state.hora_salida)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Guia Numero
                  </label>
                  <br /><span>
                    {(this.state.guia_numero=="undefined")?(null):(this.state.guia_numero)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Precinto Numero
                  </label>
                  <br /><span>
                    {(this.state.precinto_numero=="undefined")?(null):(this.state.precinto_numero)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nombre del Chofer
                  </label>
                  <br /><span>
                    {(this.state.nombre_chofer=="undefined")?(null):(this.state.nombre_chofer)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    ID del Chofer
                  </label>
                  <br /><span>
                    {(this.state.id_chofer=="undefined")?(null):(this.state.id_chofer)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Tipo de Camion
                  </label>
                  <br /><span>
                    {(this.state.tipo_camion=="undefined")?(null):(this.state.tipo_camion)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Placa del Camion
                  </label>
                  <br /><span>
                    {(this.state.placa_camion=="undefined")?(null):(this.state.placa_camion)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                  Vehiculo desinfectado
                  </label>
                  <br /><span>
                    {(this.state.vehiculo_desinfectado=="undefined")?(null):(this.state.vehiculo_desinfectado)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Producto Utilizado
                  </label>
                  <br /><span>
                    {(this.state.producto_utilizado=="undefined")?(null):(this.state.producto_utilizado)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <br /><span>
                    {(this.state.informacion_adicional_transport=="undefined")?(null):(this.state.informacion_adicional_transport)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Documento
                </label><br />
                {(this.state.file_document_trasport==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_trasport}>{String(this.state.file_document_trasport).split('/').pop()}</a>)}
              </Col>
            </Row>
          </CardBody>
        </Card>
        </>
      )
  }
}

export class SectionLogisExport extends React.Component {
  state={
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
  }
  async componentDidMount(){
        this.setInfoTransExport(this.props.getIdLogis,this.props.dataTransportExtend)
  }
      setDataCamposEmpty = ()=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
              var gerParam = this.props.setParamsUrl
            this.setState({

                export_id:'noind',
                modo_transporte:'',
                fecha_carga:completeDate,
                nro_documento:'',
                contenedor_numero:'',
                sello_numero:'',
                unidad_carga:'',
                total_unidades:'',
                informacion_adicional_export:'',
                file_document_export:undefined,
            })
      }
          setDataCampos = (data)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
      if (data.export!=undefined) {
        this.setState({
                export_id:(data.export.id=="null")?(null):(data.export.id),
                modo_transporte:(data.export.modo_transporte=="null")?(null):(data.export.modo_transporte),
                fecha_carga:(data.export.fecha_carga=="null")?(completeDate):(data.export.fecha_carga),
                nro_documento:(data.export.nro_documento=="null")?(null):(data.export.nro_documento),
                contenedor_numero:(data.export.contenedor_numero=="null")?(null):(data.export.contenedor_numero),
                sello_numero:(data.export.sello_numero=="null")?(null):(data.export.sello_numero),
                unidad_carga:(data.export.unidad_carga=="null")?(null):(data.export.unidad_carga),
                total_unidades:(data.export.total_unidades=="null")?(null):(data.export.total_unidades),
                informacion_adicional_export:(data.export.informacion_adicional=="null")?(null):(data.export.informacion_adicional),
                file_document_export:(data.export.archivo=="null")?(null):(data.export.archivo),
        })
      }else{
            this.setState({
                fecha_carga:completeDate,
            })

      }

    }
  setInfoTransExport = async (id_set,dataAll) =>{

          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = this.props.setParamsUrl

              if (id_set!=null) {      
                      var dataTransExportLogisc = await data_logstic_export(dataAll,id_set)

                  if (dataTransExportLogisc==null) {
                    this.setDataCamposEmpty()
                  }else{
                    this.setDataCampos(dataTransExportLogisc)

                  }
              }else{
                    this.setDataCamposEmpty()
              }
          
    }
  render(){
    return(
      <>

        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Transporte Exportación {this.props.getIndex+1}</h3>
          </CardHeader>
          <CardBody>
            <Row>
            <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Modo de Transtporte
                  </label>
                  <br /><span>
                    {(this.state.modo_transporte=="undefined")?(null):(this.state.modo_transporte)}  
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Carga
                  </label>
                  <br /><span>
                    {(this.state.fecha_carga=="undefined")?(null):(this.state.fecha_carga)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nro de Documento
                  </label>
                  <br /><span>
                    {(this.state.nro_documento=="undefined")?(null):(this.state.nro_documento)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contenedor Numero
                  </label>
                  <br /><span>
                    {(this.state.contenedor_numero=="undefined")?(null):(this.state.contenedor_numero)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Sello Numero
                  </label>
                  <br /><span>
                    {(this.state.sello_numero=="undefined")?(null):(this.state.sello_numero)}
                    </span>
                </FormGroup>
              </Col>
            <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Unidad de Carga
                  </label>
                  <br /><span>
                    {(this.state.unidad_carga=="undefined")?(null):(this.state.unidad_carga)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Total Unidades
                  </label>
                  <br /><span>
                    {(this.state.total_unidades=="undefined")?(null):(this.state.total_unidades)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <br /><span>
                    {(this.state.informacion_adicional_export=="undefined")?(null):(this.state.informacion_adicional_export)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                 Documento
                </label><br />
                {(this.state.file_document_export==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_export}>{String(this.state.file_document_export).split('/').pop()}</a>)}
              </Col>
            </Row>
          </CardBody>
        </Card>
        </>
      )
  }
}