import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
 class DetailTransport extends React.Component {
  state={
    propiedad_transport:'',
    seleccionar_trans:'0'
  }
    async componentDidMount(){
        
  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }
    const setDataSelect = (data) =>{
      this.setState({seleccionar_trans:data})
      var data_trans = JSON.parse(data)
      this.props.setValFieldTransport('propiedad_transport','Camión')
      this.props.setValFieldTransport('itnetatio_establecido',data_trans.seleccionar_ruta)

      this.props.setValFieldTransport('nombre_transporte',data_trans.nombre_chofer)
      this.props.setValFieldTransport('ced_identidad',data_trans.cedula)
      this.props.setValFieldTransport('marca_vehiculo',(data_trans.tipo_camion=='null')?(null):(data_trans.tipo_camion))
      this.props.setValFieldTransport('matricula_trans',(data_trans.placa_camion=='null')?(null):(data_trans.placa_camion))
    }
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
              <Col md="12" className="pb-2">
              <Col md="12">
                <label className="form-control-label" style={styles.labelStyle}>
                Seleccionar a un transportista.
                </label>
                  <select
                    value={this.state.seleccionar_trans}
                    onChange={(e)=>{setDataSelect(e.target.value)}} 
                    id="exampleFormControlSelect1"
                    //className="col-4"
                    style={{border:'none',padding: 0,height: 'auto',width: '130px'}}
                    //type="select"
                    >
                    <option value="0">Seleccione...</option>
                    {this.props.validSwith.data_for_transport.map((value,index)=>{
                      return <option value={JSON.stringify(value)}>{value.nombre_chofer}-{value.guia_numero}-{value.precinto_numero}</option>
                    })}
                  </select>
              </Col>              
                <div style={styles.cardStyleSectionTwo}>
                <Row>
                  <div style={{paddingLeft: '12px'}}>
                    <label className="form-control-label" style={styles.labelStyleTree}>
                      9- TRANSPORTE:
                    </label>
                  </div>
                    <Col md="10">
                    <div className="row">
                      <div className="col-md-3" style={styles.labelStyleTwoNextMessageReson}>
                        Forma de transporte <br />
                        MARCAR SOLO LA PRINCIPAL
                      </div>
                          <Col md="5" style={styles.cardStyleSection}>
                              <div className="pl-2 pr-2">
                                <label className="form-control-label mb-0" style={{color: '#000000',fontSize: '11px',width: '5px',fontWeight: '600',marginRight: '25px'}}>
                                {String('1').toUpperCase()}
                                </label>
                                <Input
                                // value={this.state.propiedad_transport}
                                // onChange={(e)=>{this.setState({propiedad_transport:'Tropa'})}}
                                 value={(this.props.validSwith.propiedad_transport==undefined)?(null):(this.props.validSwith.propiedad_transport)}
                                 onChange={(e)=>{
                                  this.props.setValFieldTransport('propiedad_transport','Tropa')
                                }}
                                checked={this.props.validSwith.propiedad_transport=='Tropa'}
                                style={{height: '0px'}}
                                className="radioStyle"
                                type="radio">
                                </Input>
                                <div className="form-control-label" style={{color: '#000000',fontSize: '11px',fontWeight: '600'}}>
                                Tropa
                                </div>
                              </div>

                              <div className="pl-2 pr-2">
                                <label className="form-control-label mb-0" style={{color: '#000000',fontSize: '11px',width: '5px',fontWeight: '600',marginRight: '25px'}}>
                                {String('2').toUpperCase()}
                                </label>
                                <Input
                                // value={this.state.propiedad_transport}
                                // onChange={(e)=>{this.setState({propiedad_transport:'Camión'})}}
                                value={(this.props.validSwith.propiedad_transport==undefined)?(null):(this.props.validSwith.propiedad_transport)}
                                 onChange={(e)=>{
                                  this.props.setValFieldTransport('propiedad_transport','Camión')
                                }}
                                checked={
                                  //(this.props.validSwith.type_change_prod==1)?(true):((this.props.validSwith.seleccionar_operacion_prop=='1')?(false):(null))
                                  this.props.validSwith.propiedad_transport=='Camión'
                                }
                                style={{height: '0px'}}
                                className="radioStyle"
                                type="radio">
                                </Input>
                                <div className="form-control-label" style={{color: '#000000',fontSize: '11px',fontWeight: '600'}}>
                                Camión
                                </div>
                              </div>
                              <div className="pl-2 pr-2">
                                <label className="form-control-label mb-0" style={{color: '#000000',fontSize: '11px',width: '5px',fontWeight: '600',marginRight: '25px'}}>
                                {String('3').toUpperCase()}
                                </label>
                                <Input
                                // value={this.state.propiedad_transport}
                                // onChange={(e)=>{this.setState({propiedad_transport:'Ferrocarril'})}}
                                value={(this.props.validSwith.propiedad_transport==undefined)?(null):(this.props.validSwith.propiedad_transport)}
                                 onChange={(e)=>{
                                  this.props.setValFieldTransport('propiedad_transport','Ferrocarril')
                                }}
                                checked={this.props.validSwith.propiedad_transport=='Ferrocarril'}
                                style={{height: '0px'}}
                                className="radioStyle"
                                type="radio">
                                </Input>
                                <div className="form-control-label" style={{color: '#000000',fontSize: '11px',fontWeight: '600'}}>
                                Ferrocarril
                                </div>
                              </div>
                              <div className="pl-2 pr-2">
                                <label className="form-control-label mb-0" style={{color: '#000000',fontSize: '11px',width: '5px',fontWeight: '600',marginRight: '25px'}}>
                                {String('4').toUpperCase()}
                                </label>
                                <Input
                                // value={this.state.propiedad_transport}
                                // onChange={(e)=>{this.setState({propiedad_transport:'Otros medios'})}}
                                value={(this.props.validSwith.propiedad_transport==undefined)?(null):(this.props.validSwith.propiedad_transport)}
                                 onChange={(e)=>{
                                  this.props.setValFieldTransport('propiedad_transport','Otros medios')
                                }}
                                checked={this.props.validSwith.propiedad_transport=='Otros medios'}
                                style={{height: '0px'}}
                                className="radioStyle"
                                type="radio">
                                </Input>
                                <div className="form-control-label" style={{color: '#000000',fontSize: '11px',fontWeight: '600'}}>
                                Otros medios
                                </div>
                              </div>                                    
                          </Col>

                    </div>
                  </Col>
                    <Col md="12" className="pt-4">
                    <div style={{display:'flex'}}>
                      <div className="form-control-label mr-1" style={{color: '#000000',fontSize: '11px',width: '150px',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                        Itinerario establecido
                      </div>
                      <input
                        style={{height: '12px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.itnetatio_establecido==undefined)?((this.props.validSwith.type_change_prod==1)?('Coy Vee R9 Destino'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('Sin Nacimiento'):(null))):(this.state.itnetatio_establecido)}
                         // onChange={(e)=>{this.setState({itnetatio_establecido:e.target.value})}}
                         value={(this.props.validSwith.itnetatio_establecido==undefined)?(null):(this.props.validSwith.itnetatio_establecido)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('itnetatio_establecido',e.target.value)
                        }}                         
                         type="text"                  
                        />
                    </div>
                  </Col>
                    <Col md="4">
                    <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '70%',fontWeight: '600',marginRight: '9px'}}>Hora</div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '120px',fontWeight: '600',marginRight: '9px'}}>Hora de embarque</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '0px'}}                    
                         // value={(this.state.hora_embarque==undefined)?(null):(this.state.hora_embarque)}
                         // onChange={(e)=>{this.setState({hora_embarque:e.target.value})}}
                         value={(this.props.validSwith.hora_embarque==undefined)?(null):(this.props.validSwith.hora_embarque)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('hora_embarque',e.target.value)
                        }}                         
                         type="time"                  
                        />
                        </div>
                      </div>
                    </div>
                  </Col>
                    <Col md="3">
                    <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                        <div className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '100%',fontWeight: '600',marginRight: '9px'}}>Dia/Mes/Año</div>                        
                        <div style={{display:'flex'}}>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '0px'}}                    
                         // value={(this.state.date_embarque==undefined)?((this.props.validSwith.type_change_prod==1)?('2021-12-06'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.date_embarque)}
                         // onChange={(e)=>{this.setState({date_embarque:e.target.value})}}
                         value={(this.props.validSwith.date_embarque==undefined)?(null):(this.props.validSwith.date_embarque)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('date_embarque',e.target.value)
                        }}                         
                         type="date"                  
                        />
                        </div>
                    </div>
                  </Col>

                    <Col md="5" style={{alignSelf: 'end'}}>
                      <input
                        style={{height: '12px',textAlign: 'justify',fontSize: '12px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.firma_del_transportista==undefined)?(null):(this.state.firma_del_transportista)}
                         // onChange={(e)=>{this.setState({firma_del_transportista:e.target.value})}}
                         value={(this.props.validSwith.firma_del_transportista==undefined)?(null):(this.props.validSwith.firma_del_transportista)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('firma_del_transportista',e.target.value)
                        }}                         
                         type="text"                  
                        />
                      <div className="form-control-label mr-1" style={{textAlign:'end',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                        Firma del transportista
                      </div>
                  </Col>

                    <Col md="12">
                    <Row>
                    <Col md="8">
                      <div style={{display:'flex'}}>
                        <div className="form-control-label mr-1" style={{color: '#000000',fontSize: '11px',width: '100px',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                          Nombre transportador
                        </div>
                        <input
                          style={{height: 'auto',textAlign: 'justify',fontSize: '12px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                           // value={(this.state.nombre_transporte==undefined)?((this.props.validSwith.type_change_prod==1)?('Daniel'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.nombre_transporte)}
                           // onChange={(e)=>{this.setState({nombre_transporte:e.target.value})}}
                         value={(this.props.validSwith.nombre_transporte==undefined)?(null):(this.props.validSwith.nombre_transporte)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('nombre_transporte',e.target.value)
                        }}                           
                           type="text"                  
                          />
                      </div>
                    </Col>
                    <Col md="4">
                    <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{alignSelf: 'center',color: '#000000',fontSize: '12px', width: '240px',fontWeight: '600',marginRight: '0px'}}>Céd. de identidad</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '5px'}}                    
                         // value={(this.state.ced_identidad==undefined)?((this.props.validSwith.type_change_prod==1)?('3785835'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.ced_identidad)}
                         // onChange={(e)=>{this.setState({ced_identidad:e.target.value})}}
                         value={(this.props.validSwith.ced_identidad==undefined)?(null):(this.props.validSwith.ced_identidad)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('ced_identidad',e.target.value)
                        }}                         
                         type="text"                  
                        />
                        </div>
                      </div>
                    </div>
                    </Col>
                    </Row>
                  </Col>

                    <Col md="12">
                    <Row>
                    <Col md="4">
                      <div style={{display:'flex'}}>
                        <div className="form-control-label mr-1" style={{color: '#000000',fontSize: '11px',width: '100px',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                          Marca vehículo
                        </div>
                        <input
                          style={{height: 'auto',textAlign: 'justify',fontSize: '12px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                           // value={(this.state.marca_vehiculo==undefined)?((this.props.validSwith.type_change_prod==1)?('VW'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.marca_vehiculo)}
                           // onChange={(e)=>{this.setState({marca_vehiculo:e.target.value})}}
                         value={(this.props.validSwith.marca_vehiculo==undefined)?(null):(this.props.validSwith.marca_vehiculo)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('marca_vehiculo',e.target.value)
                        }}                           
                           type="text"                  
                          />
                      </div>
                    </Col>
                    <Col md="4">
                    <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{alignSelf: 'center',color: '#000000',fontSize: '12px', width: '240px',fontWeight: '600',marginRight: '0px'}}>Matrícula</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '5px'}}                    
                         // value={(this.state.matricula_trans==undefined)?((this.props.validSwith.type_change_prod==1)?('ETP 1064'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.matricula_trans)}
                         // onChange={(e)=>{this.setState({matricula_trans:e.target.value})}}
                         value={(this.props.validSwith.matricula_trans==undefined)?(null):(this.props.validSwith.matricula_trans)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('matricula_trans',e.target.value)
                        }}                         
                         type="text"                  
                        />
                        </div>
                      </div>
                    </div>
                    </Col>
                    <Col md="4">
                    <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{alignSelf: 'center',color: '#000000',fontSize: '12px', width: '240px',fontWeight: '600',marginRight: '0px'}}>Matrícula remolque</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '5px'}}                    
                         // value={(this.state.remolque_matri==undefined)?((this.props.validSwith.type_change_prod==1)?('EE 21521'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.remolque_matri)}
                         // onChange={(e)=>{this.setState({remolque_matri:e.target.value})}}
                         value={(this.props.validSwith.remolque_matri==undefined)?(null):(this.props.validSwith.remolque_matri)}
                         onChange={(e)=>{
                          this.props.setValFieldTransport('remolque_matri',e.target.value)
                        }}                         
                         type="text"                  
                        />
                        </div>
                      </div>
                    </div>
                    </Col>                    
                    </Row>
                  </Col>                  

                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailTransport;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '230px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  //width: '300px',
  fontWeight: '500',
  marginRight: '0px',
  top: '4px',
  position: 'relative',
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  cardStyleSectionTwo:{
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: '10px',
    border: 'solid 2px rgb(201, 237, 253)',
  }
}