import React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  //Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Modal
} from "reactstrap";
import { Button } from 'primereact/button';

import { NavLink as ComponentLink } from "react-router-dom";
import { get_permission_main,get_permission } from "network/ApiAxios";
import {GuideMessage} from "views/pages/components/GuideMessage";
import {UserContext} from "layouts/store";
import AlertMRV from "components/Headers/AlertMRVOR.js";
import { view_content } from "network/ApiAxios";
class CardsHeader extends React.Component {
  static contextType = UserContext
  state = {
    data_permission_company:[],
    data_service:[],
    show_tool:false,
    type_traza_user:null,
    type_traza_user_t:null,
  }
  async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
     var gerParam = new URLSearchParams(this.props.location.search).get("company");
     var data = await get_permission_main(jsondata._id)
     
          data.datosPermission.forEach(el => {
            if (el.type_traza_permission=="VF-M") {
              el.type_traza = "Trazabilidad carnicos"
            }
            if (el.type_traza_permission=="VF") {
              el.type_traza = "Trazabilidad de frutas y vegetales"
            }

        if (el.type=='admin'||el.type=="admin\n") {
          el.type_other = 'Admin';
        }
        if (el.type=='options'||el.type=="options\n") {
          el.type_other='Perfil para editar solo secciones'
        }
        if (el.type=='view'||el.type=="view\n") {
          el.type_other='Perfil para que vea el proceso solamente'
        }
      });
      this.setState({
       data_permission_company:data.datosPermission,
       //data_permission_main:dataPermission[0].role,
       data_service:data.dataService,
       //type_traza_user:dataPermission[0].type_traza,
       type_traza_user_t:jsondata.type_traza
     })
    if (jsondata.userType==2) {
      if (gerParam==null) {
          var dataPermission = await get_permission(jsondata._id,gerParam)
          this.setState({
           data_permission_main:dataPermission[0].role,
           type_traza_user:dataPermission[0].type_traza,
         })
        this.changeCompany("/admin/home?company="+data.datosPermission[0].user_id_connection,2)
      }
    }else{
          var dataPermission = await get_permission(jsondata._id,null)
          this.setState({
           data_permission_main:dataPermission[0].role,
           type_traza_user:dataPermission[0].type_traza,
         })
    }
  }
  changeCompany=async(valueUrl,typeU)=>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    jsondata.userType=typeU
    localStorage.setItem("user", JSON.stringify(jsondata));
    this.props.history.push(valueUrl);
    //this.props.closeModal(false,'modalPerfile')
    window.location.href=valueUrl
  }
  changeRoleMrv=(typeChange)=>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    jsondata.type_traza=typeChange
    localStorage.setItem("user", JSON.stringify(jsondata));
    this.props.history.push('/admin/report-production-users');
    //window.location.reload()
  }
render(){
  const sendService = (data) => {
    // console.log(data[0])
    var getDataJson = data[0]
    this.props.history.push('/admin/payment',{getDataJson});
  }
  const contentSectionService = (data) => {
    var content = []
    for (var i = 0; i < data.length; i++) {
      let date_1 = new Date(data[i].fecha_limite);
      let date_2 = new Date();      
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      var typeTraza = ''
      var fullnfo = data[i].full_info.split('>')
      var infoData = null ;
      if (fullnfo.length>0) {
        infoData = fullnfo[0]
      }
      if (data[i].type_traza.indexOf('VF')!=-1) {
          typeTraza = typeTraza+' Frutas y Vegetales';
      }
      if (data[i].type_traza.indexOf('M')!=-1) {
          typeTraza = typeTraza+' Carnicos';
      }
      if (data[i].type_traza.indexOf('G')!=-1) {
          typeTraza = typeTraza+' Granos';
      }
      // if (data[i].type_traza=='VF-M') {
      //     typeTraza = infoData+', Vegetales y frutas - Carnicos';
      // }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
      content = <>
                  <div className="col-md-12" style={{fontWeight: '600', color:(TotalDays>5)?('gray'):('red')}}>*Servicio: {typeTraza}.
                  <br />
                    Costo del Servicio: {(data[i].precio=='0')?('Gratis por '+data[i].dias+' dias'):('$'+data[i].precio_total+' por '+data[i].dias+' dias')}
                  <br /> 
                    Fecha Vencimiento: {data[i].fecha_limite}. 
                  <br />
                    {(TotalDays>0)?'Faltan: '+TotalDays+' Dia(s)':'Servicio Finalizado'}
                  <br />
                    {(jsondata.userType!=1)?(null):((TotalDays>5)?(null):((data[i].precio=='0')?(null):(<a href="javascript:void(0);" onClick={()=>{sendService(data)}}>Volver a adquirir este sevicio</a>)))}
                  </div>
                </>
    }
    return content
  }
  const componentOne=(typeC)=>{
    var component = []
if (typeC==1) {
    component = <>
                           <Col xl="12">
                                  <Row>
                                    <div className="col-md-11">
                                      <span className="h2 font-weight-bold mb-0">Perfil del usuario</span>
                                    </div>
                                        <Col md={1}>
                                        <a href="#" onClick={()=> {this.props.closeModal(false,'modalPerfile')} }>
                                          <h3 class="mb-0">X</h3>
                                        </a>
                                        </Col>
{/*                                    <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-traza-green text-white rounded-circle shadow">
                                        <i className="fas fa-building" />
                                      </div>
                                    </Col>*/}
                                  </Row>
                                  <p className="mb-0 text-sm">
                                    <span className="text-nowrap">Seleccione la empresa con la que desea trabajar.</span>
                                  </p>
                                  <Row>
                                    <Col md={4}>
                                       <Card className="p-0" style={{height: '135px'}}>
                                        <CardBody>
                                        <span>Perfil Productor</span><hr style={{margin: '10px'}} />
                                       <button style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#2d5980'}} onClick={()=>{this.changeCompany("/admin/home",1)}}> Mi empresa</button>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    {this.state.data_permission_company.map((value,index)=>{
                                    return(<><Col md={4}>
                                               <Card className="p-0" style={{height: '135px'}}>
                                                <CardBody>
                                                <span>{value.type_other}</span><hr style={{margin: '10px'}} />
                                               <button style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#2d5980'}} onClick={()=>{this.changeCompany("/admin/home?company="+value.user_id_connection,2)}}> Invitación como colaborador para la empresa {value.empresa_collab} </button>
                                                </CardBody>
                                              </Card>
                                            </Col></>)
                                    })}
                                  </Row>
                            </Col>
    </>
}
if (typeC==2) {
  component = <>

        <Col xl="12">
          <Card className="card-stats p-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <span className="h2 font-weight-bold mb-0">Perfil del usuario</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-traza-green text-white rounded-circle shadow">
                      <i className="fas fa-building" />
                    </div>
                  </Col>
{/*            <Col md={1}>
            <a href="#" onClick={()=> {this.initData(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>*/}
                </Row>
                <p className="mb-0 text-sm">
                  <span className="text-nowrap">Seleccione la empresa con la que desea trabajar.</span>
                </p>
                <Row>
                {this.state.data_permission_company.map((value,index)=>{
                  return(<><Col md={4}>
                                           <Card className="p-0">
                                            <CardBody>
                                            <hr />
                                            <span>{value.type_other}</span><hr />
                                           <a className="font-weight-bold" href={"/admin/home?company="+value.user_id_connection}>Empresa {value.empresa_collab}</a>
                                            </CardBody>
                                          </Card>
                                        </Col></>)
                })}
                </Row>
              </CardBody>
            </Card>
          </Col>


  </>
}
    return component
  }
const componentTwo=()=>{
  var component=[]
  component = <>
                <Row>

                  <div className="col-12 col-lg-6 col-xl-3">
                      <div className="card mb-0" >
                          <div className="flex justify-content-between mb-1">
                              <div>
                                  <span className="block" style={{fontSize: '12px', fontWeight: 'bold',textAlign: 'left', color: "#002A5C"}}>Monitoreador</span>
                              </div>
                              <div className="flex align-items-center justify-content-center" style={{ width: '2.5rem', height: '2.5rem' }}>
                                  {/*<i className="pi pi-shopping-cart text-blue-500 text-xl" />*/}
                                  <Button 
                                  //tooltip={val.description} 
                                  style={{padding: '5px',backgroundColor: '#7ac142',border: '#7ac142'}}
                                  onClick={()=>{this.changeRoleMrv("ON")}}
                                  icon="pi pi-desktop"
                                  size='small' />
                              </div>
                          </div>
                          {(this.state.type_traza_user_t=="ON")?(<span className="text-500" style={{textAlign: 'left',fontWeight: '600'}}> - Rol actualmente seleccionado</span>):(null)}
                      </div>
                  </div>

                  <div className="col-12 col-lg-6 col-xl-3">
                      <div className="card mb-0" >
                          <div className="flex justify-content-between mb-1">
                              <div>
                                  <span className="block" style={{fontSize: '12px', fontWeight: 'bold',textAlign: 'left', color: "#002A5C"}}>Revisor</span>
                              </div>
                              <div className="flex align-items-center justify-content-center" style={{ width: '2.5rem', height: '2.5rem' }}>
                                  {/*<i className="pi pi-shopping-cart text-blue-500 text-xl" />*/}
                                  <Button 
                                  //tooltip={val.description} 
                                  style={{padding: '5px',backgroundColor: '#7ac142',border: '#7ac142'}}
                                  onClick={()=>{this.changeRoleMrv("RE")}}
                                  icon="pi pi-search"
                                  size='small' />
                              </div>
                          </div>
                          {(this.state.type_traza_user_t=="RE")?(<span className="text-500" style={{textAlign: 'left',fontWeight: '600'}}> - Rol actualmente seleccionado</span>):(null)}
                      </div>
                  </div>

                  <div className="col-12 col-lg-6 col-xl-3">
                      <div className="card mb-0" >
                          <div className="flex justify-content-between mb-1">
                              <div>
                                  <span className="block" style={{fontSize: '12px', fontWeight: 'bold',textAlign: 'left', color: "#002A5C"}}>Validador</span>
                              </div>
                              <div className="flex align-items-center justify-content-center" style={{ width: '2.5rem', height: '2.5rem' }}>
                                  {/*<i className="pi pi-shopping-cart text-blue-500 text-xl" />*/}
                                  <Button 
                                  //tooltip={val.description} 
                                  style={{padding: '5px',backgroundColor: '#7ac142',border: '#7ac142'}}
                                  onClick={()=>{this.changeRoleMrv("VA")}}
                                  icon="pi pi-check"
                                  size='small' />
                              </div>
                          </div>
                          {(this.state.type_traza_user_t=="VA")?(<span className="text-500" style={{textAlign: 'left',fontWeight: '600'}}> - Rol actualmente seleccionado</span>):(null)}
                      </div>
                  </div>

                </Row>
  </>
  return component  
}

  return (
    <>
        <div>
          <div className="header-body">
            <Row>
{(this.props.name=='Pagos por servicios')?(<Col xl="12">
              <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Bienvenido
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">Foodchain Trace</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-traza-green text-white rounded-circle shadow">
                          <i className="fas fa-tasks" />
                        </div>
                      </Col>
                    </Row>

                    <hr />
                    <Row>
                      {contentSectionService(this.state.data_service)}
                    </Row>
                  </CardBody>
                </Card>
              </Col>):(null)}
{/*<AlertMRV {...this.props} content_area="0"/>*/}
{(this.state.data_permission_company[0]==null)?(
  <Modal isOpen={this.props.forModalProp} size="xl">
    <ModalBody>
      {componentOne(this.state.data_permission_main)}
    </ModalBody>
    <ModalFooter>
      <Button onClick={()=> {this.props.closeModal(false,'modalPerfile')} }>
      Cerrar
      </Button>
    </ModalFooter>
  </Modal>
  ):(null)}
              {(this.state.data_permission_company[0]==null)?(null):(<>
                {(this.state.data_permission_company[0].role==1)?(<>
                  {(this.state.data_permission_company.length>0)?(<>
                    <Modal isOpen={this.props.forModalProp} size="xl">
                      <ModalBody>
                        {componentOne(this.state.data_permission_company[0].role)}
                          </ModalBody>
                          <ModalFooter>
                            <Button onClick={()=> {this.props.closeModal(false,'modalPerfile')} }>
                              Cerrar
                            </Button>
                          </ModalFooter>
                        </Modal>

                    </>):(null)}
                </>):(null)}
                {(this.state.data_permission_company[0].role==2)?(<>

                    <Modal isOpen={this.props.forModalProp} size="xl">
                      <ModalBody>
                        {componentOne(this.state.data_permission_company[0].role)}
                          </ModalBody>
                          <ModalFooter>
                            <Button onClick={()=> {this.props.closeModal(false,'modalPerfile')} }>
                              Cerrar
                            </Button>
                          </ModalFooter>
                        </Modal>

                  </>):(null)}
              </>)}
              {(this.state.type_traza_user=='ON-RE-VA')?(componentTwo()):(null)}
            </Row>
          </div>
        </div>
    </>
  );
 }
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
