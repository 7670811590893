import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import React, {createRef} from "react";
import { data_fertilizer_production, data_trazabilidad,get_permission, data_production, main_content } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAran } from "network/CodigoArancelario";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import config from "config";
import {getContent,PutFormContentFinal} from 'views/pages/forms/LogisticsForm';
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import { stateData } from "network/DataState";
import {
 PutFormContentFinalDuplicate
} from 'views/pages/forms/LogisticsFormDuplicate';
import { Accordion, AccordionTab } from 'primereact/accordion';

export class SectionFinalNama extends React.Component {

  state={
    field_params:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],
    dataStatesS:0,
    content_render:null,
    verify_if_duplicate:false,
    field_content_duplicate:[],
    field_params_duplicate:[],
    get_first_info:'',
    data_table: [
    {title:'NDVI',table:'production_info_satelite'},
        {title:'NDRE',table:'production_info_satelite_ndre'},
        {title:'NDMI',table:'production_info_satelite_ndmi'},
        {title:'HR Suelo',table:'production_info_satelite_ndhs'}
    ]
  }
  async componentDidMount(){
    var getData = await this.props.main_content
   // getData.map((a,b)=>{
   //   if (a.include_info==1) {
   //     this.setState({get_first_info:a.name_field, get_first_info_column:a.label_title})
   //   }
   // })
    this.setState({field_content_log:getData})
    this.initData(this.props.getIdPlantProd)
  }
  initData = async (id_set)=>{
  this.setState({field_content: getContent(this.state.field_content_log).s_data})

  var get_valid = Object.values(getContent(this.state.field_content_log).s_data).some((element)=>element.name_field=='field_duplicate')
  this.setState({verify_if_duplicate:get_valid})

        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var gerParamDB = new URLSearchParams(window.location.search).get("db");
        var dataTrazabilidadvf = await data_fertilizer_production(id_set,this.props.tableCall,JSON.stringify(getContent(this.state.field_content_log).f_data),gerParam,gerParamDB)
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }
    setDataCampos = (data)=>{
      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
       if (a.name_field=='field_duplicate') {
        this.setFieldDuplicate(data.field_duplicate)
       }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })
    }
    cleanData = () =>{
          this.setState({idT:'noind'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })          
    }
   setFieldDuplicate=(data)=>{
    if (data!='' && data!=null) {      
      var field_params_duplicate = JSON.parse(data)

      var getDataTwo = []
      var getDataTwoValue = []

      var getData = {}
      var getDataValue = {}
      var get_content_duplicate = this.state.field_content.filter(a=>a.duplicate_field==1)
        for (var i = 0; i < parseInt(field_params_duplicate.length); i++) {
          get_content_duplicate.map((a,b)=>{
            getData[b]={content:a,table:a.name_field}
          })
              getDataTwo[i]=getData
              getDataTwoValue[i]=field_params_duplicate[i]
        }
      this.setState({
        field_content_duplicate:getDataTwo,
        field_params_duplicate:getDataTwoValue
      })
    }
   }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
     setFieldValue = (value, field, isrequired) =>{
       this.setState({field_params: {...this.state.field_params, [field]:value }})
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default==null) {
          var for_put_data=this.contentForSelect(value_option,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]: for_put_data }})

        }else{
          if (list_default=='ldp') {
            var var_data = []
            var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})

          }
          if (list_default=='cafv') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})

          }
          if (list_default=='cac') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})

          }
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
  headerAccord = (indexing)=>{
return  (<>
        <Row>
          <Col md={12} style={{alignContent: 'center'}}>
          Producto aplicado {indexing+1}
          </Col>
        </Row>
          </>)
  }
  setContentDuplicate=(rowContent)=>{
    var putContent = []
    rowContent.map((a,b)=>{
      var get_content=Object.values(a).map((ac,bc)=>{ return ac.content })
      putContent[b]=<>
      <Accordion multiple activeIndex={Object.keys(rowContent).map(Number)}>
          <AccordionTab key={b} header={this.headerAccord(b)}>
            <Row>
              <PutFormContentFinalDuplicate
              content={get_content}
              field_params={this.state.field_params_duplicate[b]}
               />
            </Row>
          </AccordionTab>
        </Accordion>
{/*        <Row>
          <Col md={2} style={{display:'flex'}}>
             
             <h4 style={{margin:'auto'}}><span className="fab fa-diaspora"></span> Productos aplicados {b+1}</h4>
          </Col>
          <Col md={10}>
            <Row>
              <PutFormContentFinalDuplicate
              content={get_content}
              field_params={this.state.field_params_duplicate[b]}
               />
            </Row>
          </Col>
        </Row>*/}
      <hr style={{margin: 0}} />
      <br />
      </>
    })
      return putContent
  }
  render(){
 const getInfomationsTh = (infor,idxCon)=>{
   if (infor.length!=0) {
     return infor[0][idxCon]
   }
 }
const PutFormContentSelectTh = (props,idxCon,tablest) => {
 var contenido = []
 var contenidoData = []
 tablest.map((val,inx)=>{
    contenido[inx] = <>
  <td style={{border: '1px solid',padding: '3px'}}>
    <span style={{color: 'black', fontSize: '13px'}}>
    {getInfomationsTh(props[val.table],idxCon)}
    </span>
  </td></>
 })
 return (contenido)
}
const getContentSelectTr=(prps,tables)=>{
 var content = []
 content[0] =<>
                   <tr>
                    <td style={{border: '1px solid',padding: '3px'}}>
                     <span style={{color: 'black', fontSize: '13px'}}>
                      Descripción
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Vigor del Cultivo<br/>
                     C*(0,6-0,8) M*(0,3-0,5)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Salud del Cultivo<br/>
                     C(0,3-0,5) M(0,2-0,4)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Humedad de la Vegetación<br/>
                     C(0,4-0,6) M(0,2-0,4)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Humedad del Suelo<br/>
                     C(0,7-0,8) M(0,6-0,7)
                     </span>
                    </td>
                  </tr>
 </>
    this.state.field_content.map((val,indx)=>{
      content[indx+1] = <>
                    <tr>
                     <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                      <span style={{color: 'black', fontSize: '13px'}}>
                       {val.label_title}
                      </span>
                     </td>
                        {PutFormContentSelectTh(prps,val.name_field,tables)}
                   </tr>
      </>
    })
   return content
}
    const viewImgTiff=(dataUrl)=>{
      var contents = []
      if (dataUrl!=null || dataUrl!='') {
      var getReplace = dataUrl.replace('http://app.yttrium.farm:9000','https://app.itrio.ai')
        contents[0]= <>
        <a data-url={getReplace} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} href={getReplace}>{getReplace}</a>
        </>
      }
      return contents
    }
const getElementTr=(data)=>{
 var content = []
    if (data!=null) {
   content[0] =<>
                     <tr>
                      <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                       <span style={{color: 'black', fontSize: '13px'}}>
                        Descripción
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Vigor del Cultivo<br/>
                       C*(0,6-0,8) M*(0,3-0,5)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Salud del Cultivo<br/>
                       C(0,3-0,5) M(0,2-0,4)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Humedad de la Vegetación<br/>
                       C(0,4-0,6) M(0,2-0,4)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Humedad del Suelo<br/>
                       C(0,7-0,8) M(0,6-0,7)
                       </span>
                      </td>
                    </tr>
   </>
      Object.values(data['Descripción']).map((val,indx)=>{
        if (val!='Índice del Cultivo') {
          content[indx+1] = <>
                        <tr>
                         <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                          <span style={{color: 'black', fontSize: '13px'}}>
                           {val}
                          </span>
                         </td>
                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px'}}>
                          <span style={{color: 'black', fontSize: '13px'}}>
                         {(val=='URL Imagen')?(
                          viewImgTiff(data['Vigor del cultivo'][indx])
                          ):(data['Vigor del cultivo'][indx])}
                          </span>
                         </td>
                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px'}}>
                          <span style={{color: 'black', fontSize: '13px'}}>
                         {(val=='URL Imagen')?(
                          viewImgTiff(data['Salud del cultivo'][indx])
                          ):(data['Salud del cultivo'][indx])}
                          </span>
                         </td>
                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px'}}>
                          <span style={{color: 'black', fontSize: '13px'}}>
                         {(val=='URL Imagen')?(
                          viewImgTiff(data['Humedad de la Vegetacion'][indx])
                          ):(data['Humedad de la Vegetacion'][indx])}
                          </span>
                         </td>
                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px'}}>
                          <span style={{color: 'black', fontSize: '13px'}}>
                         {(val=='URL Imagen')?(
                          viewImgTiff(data['Humedad del Suelo'][indx])
                          ):(data['Humedad del Suelo'][indx])}
                          </span>
                         </td>
                       </tr>
          </>
        }

      })
    }
   return content
}
  return (
    <>
{(this.props.tableCall=='production_info_satelite_dni_code')?(
<>
{
  (this.props.fullDataSatelite==null)?('Sin datos'):(
    (this.props.fullDataSatelite.data_satelital==null)?('Sin datos'):(
      (typeof this.props.fullDataSatelite.data_satelital=='string')?(this.props.fullDataSatelite.data_satelital):(
                    Object.entries(this.props.fullDataSatelite.data_satelital).map((val,index)=>{
                                   return <>
                                   <Col md={12}>
                                      <label
                                        className="form-control-label mb-0 mt-1"
                                        htmlFor="example3cols2Input"
                                      >
                                      {val[0]}
                                      </label>
                                   </Col>
                                     <table className="align-items-center table-flush" responsive style={{border: '1px solid',width: '100%',color: 'black', fontSize: '13px'}}>
                                        <thead className="thead-light">
                                          <tr>
                                            <th style={{border: '1px solid',padding: '3px',width: '265px'}}>Ínidice del Cultivo</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDVI</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDRE</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDMI</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>HR Suelo</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {getElementTr(val[1])}
                                        </tbody>
                                      </table>
                                      <div style={{color: 'black'}}>
                                         <span style={{fontSize: '12px'}}>* C: Crecimiento. M: Maduración.</span><br/>
                                       </div>
                                    </>
                                  })
      )
    )
  )
}
        </>
  ):(

            <>
{/*                  <Col md="12" className="text-center">
                    <label>{this.props.getIndex}</label>
                  </Col>*/}
                  <Row>
                    <PutFormContentFinal 
                    content={this.state.field_content}
                    field_params={this.state.field_params}
                    optionsSelect={this.state.optionsSelect}
                    setFieldValue={this.setFieldValue}
                    setFieldValueSelect={this.setFieldValueSelect}
                    geolocacion={this.geolocacion}
                     />
                  </Row>
            {(this.state.verify_if_duplicate)?(<>
              <hr style={{margin: 0}} />
              <Col md={12}>
                {this.setContentDuplicate(this.state.field_content_duplicate)}
              </Col>
              </>):(null)}
            </>
    )}
    </>
  );
}


}
 export default class ModalNFinal extends React.Component {
  state={
    main_content:[],
  }
  async componentDidMount(){
        var getData = await main_content(this.props.tableCall)
       getData.map((a,b)=>{
         if (a.include_info==1) {
           this.setState({get_first_info:a.name_field, get_first_info_column:a.label_title})
         }
       })
       this.setState({main_content:getData})
  }
  async componentDidUpdate(){
  }
   render(){
    const content = []
if (this.state.main_content.length!=0) {  
    if (this.props.sendInfoProps.length!=0) {
      if (this.props.sendInfoProps.length==1) {
    content[0]= <>
                 {this.props.sendInfoProps.map((value,index)=>{
                          return (
                                     <Col md="12" className="text-center">
                                      <SectionFinalNama 
                                        {...this.props}
                                        titleCall={this.props.titleCall}
                                        getIdPlantProd={value.id}
                                        getIndex={index+1}
                                        main_content={this.state.main_content}
                                      />
                                     </Col>
                                  )
                  })}
                </>
      }else{
    content[0]= <Accordion multiple activeIndex={Object.keys(this.props.sendInfoProps).map(Number)}>
                     {this.props.sendInfoProps.map((value,index)=>{
                              return (
                                      <AccordionTab key={index} header={value[this.state.get_first_info]}>
                                         <Col md="12" className="text-center">
                                          <SectionFinalNama 
                                            {...this.props}
                                            titleCall={this.props.titleCall}
                                            getIdPlantProd={value.id}
                                            getIndex={index+1}
                                            main_content={this.state.main_content}
                                          />
                                         </Col>
                                      </AccordionTab>
                                      )
                      })}
                </Accordion>
      }
    }
}

  return (content);
  }
 }
