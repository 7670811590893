import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_traza_lote, set_data_compartiment, create_compartiment, delete_compartiment, data_trazabilidad_meat, row_transport_logistics_for_valid, set_data_compartiment_exit, create_compartiment_exit, delete_compartiment_exit } from "network/ApiAxios";

import NotificationAlert from "react-notification-alert";
import config from "config";

export default class ModalCompartiment extends React.Component {

  state={
    nombre_productor:'',
    nombre_finca:'',
    ciudad:'',
    estado:'',
    lote_numero:'',
    guia_numero:'',
    precinto_numero:'',
    fecha_recibido:new Date().getFullYear() + "-11-23",
    hora_recibido:new Date().getFullYear() + "-11-23",
    cantidad_animales:'',
    recibido_por:'',
    nro_identificacion:'',
    informacion_adicional:'',
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    sendOpenModalExit:false,
    data_lote:[],
    dsableBtn:false,
    dsableBtnTrans:false,
    data_trnsport:[],
    urlLoteCode:'0',
    arrayNameList:[]
  }
    async componentDidMount(){
        
  }
    setDataCampos = async (data,datatraza,dataLote,dataTransport,estado_province)=>{

      this.setState({
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              compartiment_id:(data.id=="null")?(null):(data.id),
              nombre_compartimento_recive:(data.nombre_compartimento=="null")?(null):(data.nombre_compartimento),
              nombre_productor:(data.nombre_productor=="null")?(null):(data.nombre_productor),
              nombre_finca:(data.nombre_finca=="null")?(datatraza.nombre_finca):(data.nombre_finca),
              georeferencia:(data.georeferencia=="null")?(datatraza.georeferencia):(data.georeferencia),
              ciudad:(data.ciudad=="null")?(datatraza.ciudad):(data.ciudad),
              estado:(data.estado=="null")?(estado_province):(data.estado),
              fecha_recibido:( data.fecha_recibido=="null")?(null):( data.fecha_recibido),
              hora_recibido:( data.hora_recibido=="null")?(null):( data.hora_recibido),
              lote_numero:(data.lote_numero=="null")?(null):(data.lote_numero),
              guia_numero:(data.guia_numero=="null")?(null):(data.guia_numero),
              precinto_numero:(data.precinto_numero=="null")?(null):(data.precinto_numero),
              //cantidad_animales:(data.cantidad_animales=="null")?(null):(data.cantidad_animales),
              recibido_por:(data.recibido_por=="null")?(null):(data.recibido_por),
              nro_identificacion:(data.nro_identificacion=="null")?(null):(data.nro_identificacion),
              informacion_adicional:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
              file_document:(data.archivo=="null")?(null):(data.archivo),
      })
      if (data.lote_numero=="null" || data.lote_numero==null || data.lote_numero=="0") {
        this.setState({cantidad_animales:data.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales:data.cantidad_animales,
          dsableBtn:true
        })
      }
      if (data.guia_numero=="null" || data.guia_numero==null || data.guia_numero=="0") {
                this.setState({precinto_numero:data.precinto_numero})
      }else{
                this.setState({
                  precinto_numero:data.precinto_numero,
                  dsableBtnTrans:true
                })
      }

    }
    setDataCamposExit = async (data,datatraza,dataLote,dataTransport,compartiment_recp)=>{

      this.setState({
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              compartiment_exit_id:(data.id=="null")?(null):(data.id),
              nombre_finca:(datatraza.nombre_finca=="null")?(null):(datatraza.nombre_finca),
              nombre_compartimento:(data.nombre_compartimento=="null")?(compartiment_recp.nombre_compartimento):(data.nombre_compartimento),
              lote_numero:(data.lote_numero=="null")?(null):(data.lote_numero),
              guia_numero:(data.guia_numero=="null")?(null):(data.guia_numero),
              precinto_numero:(data.precinto_numero=="null")?(null):(data.precinto_numero),
              fecha_salida:( data.fecha_salida=="null")?(null):( data.fecha_salida),
              hora_salida:( data.hora_salida=="null")?(null):( data.hora_salida),
              enviado_por:(data.enviado_por=="null")?(null):(data.enviado_por),
              nro_identificacion:(data.nro_identificacion=="null")?(null):(data.nro_identificacion),
              informacion_adicional:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
              file_document:(data.archivo=="null")?(null):(data.archivo),
      })
      if (data.lote_numero=="null" || data.lote_numero==null || data.lote_numero=="0") {
        this.setState({cantidad_animales:data.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales:data.cantidad_animales,
          dsableBtn:true
        })
      }
      // if (data.guia_numero=="null" || data.guia_numero==null || data.guia_numero=="0") {
      //           this.setState({precinto_numero:data.precinto_numero})
      // }else{
      //           this.setState({
      //             precinto_numero:data.precinto_numero,
      //             dsableBtnTrans:true
      //           })
      // }

    }
    async componentDidUpdate(){
    }
   onChangeLoteValue = (id) => {
  this.setState({lote_numero:id})
  if (id=="0") {
    this.setState({
      dsableBtn:false
    })
  }else{
    var result = this.state.data_lote.filter(val => val.lote_numero==id)
    if (result[0]==undefined) {
    this.setState({
      cantidad_animales:'0',
      dsableBtn:false
    })
              }else{
    var cantidad_animales = String(result[0].animal_register_id).split('*')
    this.setState({
      cantidad_animales:cantidad_animales.length,
      dsableBtn:true
    })
    }
  }

}
  render(){

    const setInfoCompart = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
          var dataTrazabilidadvf = await set_data_compartiment(this.props.sendInfoProps.compartiment,id_set)
          var dataTrazabilidadAnimal = await data_trazabilidad_meat(jsondata._id,gerParam)
          var estado_province = null
          if (dataTrazabilidadAnimal.provincia!=null) {
            var thisData = dataTrazabilidadAnimal.provincia.split('-')
            if (thisData.length > 0) {
             estado_province = thisData[0]
             //this.changeState(thisData[1])
            } 
          }
            if (gerParam==null) {
              var proddata = ''
            }else{
              var proddata = (dataTrazabilidadAnimal==null)? '' : dataTrazabilidadAnimal.lote_numero
            }
              var dataLote = await row_traza_lote(jsondata._id,gerParam)
              var dataTransport = await row_transport_logistics_for_valid(jsondata._id,gerParam,'Transporte de Campo a Compartimento')
        if (dataTrazabilidadvf==null) {
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
          this.setState({
              compartiment_id:'noind',
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              nombre_compartimento_recive:'',
              nombre_productor:'',
              nombre_finca:dataTrazabilidadAnimal.nombre_finca,
              georeferencia:dataTrazabilidadAnimal.georeferencia,
              ciudad:dataTrazabilidadAnimal.ciudad,
              estado:estado_province,
              lote_numero:'',
              guia_numero:'',
              precinto_numero:'',
              fecha_recibido:completeDate,
              hora_recibido:timeString,
              cantidad_animales:'',
              recibido_por:'',
              nro_identificacion:'',
              file_document:undefined,
              informacion_adicional:'',
          })
        this.setState({
          dsableBtn:false,
          dsableBtnTrans:false
        })
      // if (dataTrazabilidadvf.lote_numero=="null" || dataTrazabilidadvf.lote_numero==null || dataTrazabilidadvf.lote_numero=="0") {
      //         this.setState({cantidad_animales:dataTrazabilidadvf.cantidad_animales})
      //       }else{
      //         this.setState({
      //           cantidad_animales:dataTrazabilidadvf.cantidad_animales,
      //           dsableBtn:true
      //         })
      //       }
      this.onChangeLoteValue(getParamLote)
        }else{
          this.setDataCampos(dataTrazabilidadvf,dataTrazabilidadAnimal,dataLote,dataTransport,estado_province)
        }
          this.setState({
        sendOpenModal:verify,
      })

    }
  const saveInfo = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({messr:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (this.state.guia_numero=='' || this.state.guia_numero==null || this.state.guia_numero=='0') {
      this.setState({btnd:false})
      this.setState({messr:''})
      notify("danger", "¡Guía Número Inexistente!", 'Es necesario que en seleccione una gía o en crear en logistica un Transporte para Compartimento.');
      return
    }
    if (this.state.recibido_por=="") {
      this.setState({messr:'El campo Recibido Por es necesario.'})
      this.setState({btnd:false})
    }else{
        const var_request = await create_compartiment(this.state,jsondata._id,gerParam);

        if (var_request.message=="exito") {
          this.setState({messr:'Se guardo exitosamente.'})
          this.setState({messr:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          this.props.refreshData()
          this.setState({compartiment_id:var_request.compartiment_id})
          //setInfoCompart(var_request.compartiment_id,true)
          this.setState({btnd:false})
        }else{
          notify("danger", "¡Registrado Fallido!", var_request.message);
          this.setState({btnd:false})
        }
    }
  }

const deleteCompar = async (id) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
  if (window.confirm("Deseas eliminar este registro?")) {
  var dataReturn = await delete_compartiment(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
          this.setState({mess:'Se eliminó exitosamente.'})
          notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
          this.props.refreshData()
        }else{
          notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
          this.setState({btnd:false})
        }
}
}

    const setInfoCompartExit = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
          var compartiment_ext = await set_data_compartiment_exit(this.props.sendInfoProps.compartiment_exit,id_set)
          var compartiment_recp = null
          if (this.props.sendInfoProps.compartiment.length!=0) {
          compartiment_recp = await set_data_compartiment(this.props.sendInfoProps.compartiment,this.props.sendInfoProps.compartiment[this.props.sendInfoProps.compartiment.length-1].id)
          }
          var dataTrazabilidadAnimal = await data_trazabilidad_meat(jsondata._id,gerParam)
            if (gerParam==null) {
              var proddata = ''
            }else{
              var proddata = (dataTrazabilidadAnimal==null)? '' : dataTrazabilidadAnimal.lote_numero
            }
              var dataLote = await row_traza_lote(jsondata._id,gerParam)
              var dataTransport = await row_transport_logistics_for_valid(jsondata._id,gerParam,'Transporte de Campo a Compartimento')
        if (compartiment_ext==null) {
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
          this.setState({
              compartiment_exit_id:'noind',
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              nombre_finca:dataTrazabilidadAnimal.nombre_finca,
              nombre_compartimento:compartiment_recp?.nombre_compartimento,
              lote_numero:'',
              guia_numero:'',
              precinto_numero:'',
              fecha_salida:completeDate,
              hora_salida:timeString,
              enviado_por:'',
              nro_identificacion:'',
              cantidad_animales:'',
              file_document:undefined,
              informacion_adicional:'',
          })
        this.setState({
          dsableBtn:false,
          dsableBtnTrans:false
        })
        this.onChangeLoteValue(getParamLote)
        }else{
          this.setDataCamposExit(compartiment_ext,dataTrazabilidadAnimal,dataLote,dataTransport,compartiment_recp)
        }
          this.setState({
        sendOpenModalExit:verify,
      })

    }
  const saveInfoExit = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({mess:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (this.state.guia_numero=='' || this.state.guia_numero==null || this.state.guia_numero=='0') {
      this.setState({btnd:false})
      this.setState({mess:''})
      notify("danger", "¡Guía Número Inexistente!", 'Es necesario que en seleccione una gía o en crear en logistica un Transporte para Compartimento.');
      return
    }
    if (this.state.nombre_compartimento=="") {
      this.setState({mess:'El Nombre del Compartimento es necesario.'})
      this.setState({btnd:false})
    }else{
        const var_request = await create_compartiment_exit(this.state,jsondata._id,gerParam);

        if (var_request.message=="exito") {
          this.setState({mess:'Se guardo exitosamente.'})
          this.setState({mess:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          this.props.refreshData()
          this.setState({compartiment_id:var_request.compartiment_id})
          //setInfoCompart(var_request.compartiment_id,true)
          this.setState({btnd:false})
        }else{
          notify("danger", "¡Registrado Fallido!", var_request.message);
          this.setState({btnd:false})
        }
    }
  }

const deleteComparExit = async (id) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
  if (window.confirm("Deseas eliminar este registro?")) {
  var dataReturn = await delete_compartiment_exit(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
          this.setState({mess:'Se eliminó exitosamente.'})
          notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
          this.props.refreshData()
        }else{
          notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
          this.setState({btnd:false})
        }
}
}


const onChangeTransportValue = async (id) => {
    this.setState({guia_numero:id})
  if (id=="0") {
    this.setState({
      dsableBtnTrans:false
    })
  }else{
    var result = this.state.data_trnsport.filter(val => val.guia_numero==id)
    var precinto = result[0].precinto_numero
    this.setState({
      precinto_numero:precinto,
      dsableBtnTrans:true
    })
  }
}
const geolocacion = () => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(VerPosicion,noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    function VerPosicion(posicion){
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              setinfolocation(lat,lon,data)
            }

        })
        .catch(err => console.log(err.message)));
    }
        const setinfolocation = (lat,lon,data) => {
              this.setState({loadlocali:null})
              this.setState({ciudad:String(data.results[0].components.city)})
              //this.changeState(data.results[0].components.country_code.toUpperCase())
              var dataClean = data.results[0].components.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('Estado','').trim()
              this.setState({estado:dataClean})
              this.setState({georeferencia:lat+','+lon+'-'+data.results[0].formatted})
    }

    function noPermitir(argument) {
      this.setState({loadlocali:null})
    // $('.cargadores').hide();
    }
    const searhRecentName = (value) => {
      var filterName = this.props.sendInfoProps.compartiment.filter((a,b)=> a.recibido_por.indexOf(value) > -1)
      this.setState({arrayNameList:filterName})
      this.setState({enviado_por:value})
    };
    const setInfoName = (value) => {
      var infoSplit = value.split('-')
      this.setState({enviado_por:infoSplit[0],nro_identificacion:infoSplit[1]})
      //this.setState({arrayNameList:[]})
    };

    const searhRecentNameReceive = (value) => {
      var filterName = this.props.sendInfoProps.compartiment.filter((a,b)=> a.recibido_por.indexOf(value) > -1)
      this.setState({arrayNameList:filterName})
      this.setState({recibido_por:value})
    };
    const setInfoNameReceive = (value) => {
      var infoSplit = value.split('-')
      this.setState({recibido_por:infoSplit[0],nro_identificacion:infoSplit[1]})
      //this.setState({arrayNameList:[]})
    };

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
  <Modal isOpen={this.state.sendOpenModal} size="xl">
    <ModalBody>
       <CardHeader>
                      <Row>
            <Col md={11}>
              <h3 className="mb-0">Recibo de Animales</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoCompart(null,false)} }>
              <h3 className="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <Card>
          <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre del Compartimento
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nombre_compartimento_recive:e.target.value})}}
                    value={this.state.nombre_compartimento_recive}
                      id="example3cols2Input"
                      placeholder="Escribe el Nombre"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre del productor
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nombre_productor:e.target.value})}}
                    value={this.state.nombre_productor}
                      id="example3cols2Input"
                      placeholder="Escribe el nombre del productor"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre de la finca
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nombre_finca:e.target.value})}}
                    disabled
                    value={this.state.nombre_finca}
                      id="example3cols2Input"
                      placeholder="Escribe una Finca"
                      type="text"
                    />
                  </FormGroup>
                </Col>
<Col md="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Georeferencia
                                      </label>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                          value={this.state.georeferencia}
                                          id="example3cols2Input"
                                          className="ubicacionex"
                                          placeholder="Escribe la referencia"
                                          type="textarea"
                                          onChange={(e) => this.setState({georeferencia:e.target.value})}
                                          disabled
                                        />
                                          {this.state.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <a href="javascript:void(0)" style={{color: '#002a5c'}} onClick={()=> {geolocacion()}}>
                                          <span style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </a>
                                        </Col>  
                                      </Row>
                                      </FormGroup>
                                  </Col> 
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Ciudad
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({ciudad:e.target.value})}}
                    value={this.state.ciudad}
                    disabled
                      id="example3cols2Input"
                      placeholder="Escribe el Ciudad"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Estado
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({estado:e.target.value})}}
                    value={this.state.estado}
                      id="example3cols2Input"
                      placeholder="Escribe la Estado"
                      type="text"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Número
                    </label>
                    <Input
                    onChange={(e)=>{this.onChangeLoteValue(e.target.value)}}
                    value={this.state.lote_numero} id="exampleFormControlSelect1" type="select">
                      <option value={this.state.urlLoteCode}>{this.state.urlLoteCode}</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Guía Numero
                    </label>
                    <Input
                    onChange={(e)=>{onChangeTransportValue(e.target.value)}}
                    value={this.state.guia_numero} id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      {this.state.data_trnsport.map((value,index)=>{
                        return (<option value={value.guia_numero}>{value.guia_numero}</option>)
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Precinto Numero
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({precinto_numero:e.target.value})}}
                    value={this.state.precinto_numero}
                    disabled={this.state.dsableBtnTrans}
                      id="example3cols2Input"
                      placeholder="Escribe el precinto"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Recibido
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_recibido:e.target.value})}}
                    defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Recibido
                  </label>
                  <Input
                    value={(this.state.hora_recibido=="undefined")?(null):(this.state.hora_recibido)}
                    onChange={(e)=>{this.setState({hora_recibido:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({cantidad_animales:e.target.value})}}
                    value={this.state.cantidad_animales}
                    disabled={this.state.dsableBtn}
                      id="example3cols2Input"
                      placeholder="Escribe la cantidad de animales"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Recibido Por
                    </label>
                    <Input
                    //onChange={(e)=>{this.setState({recibido_por:e.target.value})}}
                    onChange={(e)=>{ searhRecentNameReceive(e.target.value) }}

                      onBlur={()=>{
                                    setTimeout(() => {
                                      this.setState({arrayNameList:[]})
                                    }, "500")
                      }}
                      onFocus={(e)=>{ searhRecentNameReceive(e.target.value) }}
                      autoComplete="off"                    
                      value={this.state.recibido_por}
                      id="example3cols2Input"
                      placeholder="Recibido Por"
                      type="text"
                    />
                    <div style={{position: 'absolute',zIndex: 5}}>
                      {this.state.arrayNameList.map((a,b)=>{
                        return <Button className="mb-1 mt-1" onClick={(e)=>{setInfoNameReceive(e.target.textContent||e.target.innerText)}}>{a.recibido_por+'-'+a.nro_identificacion}</Button>
                      })}
                      {/*<Button className="mb-1 mt-1" onClick={()=>{}}>Name 2-0000001</Button>*/}
                    </div>                    
                  </FormGroup>
                </Col>                
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nro. de Identificación
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nro_identificacion:e.target.value})}}
                    value={this.state.nro_identificacion}
                      id="example3cols2Input"
                      placeholder="Escribe un número de identificación"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({informacion_adicional:e.target.value})}}
                    value={this.state.informacion_adicional}
                      id="exampleFormControlTextarea1"
                      rows="1"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Subir Documento
                  </label>
                  <Form>
                    <div className="custom-file">
                      <input
                      onChange={(e)=>{this.setState({file_document:e.target.files[0]})}}
                        className="custom-file-input"
                        id="customFileLang"
                        lang="es"
                        type="file"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileLang"
                      >
                        Elegir archivo
                      </label>
                    </div>
                  </Form>
                {(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('_').pop()}</a>)}

                </Col>
              </Row>
                   {this.state.messr ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messr}
                        </span>
                      </small>
                    </div>
                  ) : null}
              <Row>
                <Col md="4">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfo}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoCompart(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
       <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Recibo de Animales
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoCompart(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.compartiment.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                       <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.nombre_productor}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoCompart(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteCompar(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
<Modal isOpen={this.state.sendOpenModalExit} size="xl">
    <ModalBody>
       <CardHeader>
                      <Row>
            <Col md={11}>
              <h3 className="mb-0">Salida de Animales</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoCompartExit(null,false)} }>
              <h3 className="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <Card>
          <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre del Compartimento
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nombre_compartimento:e.target.value})}}
                    value={this.state.nombre_compartimento}
                      id="example3cols2Input"
                      placeholder="Escribe el Nombre"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre de la finca
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nombre_finca:e.target.value})}}
                    disabled
                    value={this.state.nombre_finca}
                      id="example3cols2Input"
                      placeholder="Escribe una Finca"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label>
                    <Input
                    onChange={(e)=>{this.onChangeLoteValue(e.target.value)}}
                    value={this.state.lote_numero} id="exampleFormControlSelect1" type="select">
                      <option value={this.state.urlLoteCode}>{this.state.urlLoteCode}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Guía Numero
                    </label>
                    <Input
                    onChange={(e)=>{onChangeTransportValue(e.target.value)}}
                    value={this.state.guia_numero} id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      {this.state.data_trnsport.map((value,index)=>{
                        return (<option value={value.guia_numero}>{value.guia_numero}</option>)
                      })}
                    </Input>
                  </FormGroup>
{/*                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Guía Numero
                  </label>
                  <Input
                    value={(this.state.guia_numero=="undefined")?(null):(this.state.guia_numero)}
                    onChange={(e)=>{this.setState({guia_numero:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un Numero"
                    type="text"
                  />
                </FormGroup>*/}
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Precinto Numero
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({precinto_numero:e.target.value})}}
                    value={this.state.precinto_numero}
                    disabled={this.state.dsableBtnTrans}
                      id="example3cols2Input"
                      placeholder="Escribe el precinto"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Salida
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_salida:e.target.value})}}
                    defaultValue={(this.state.fecha_salida=="undefined")?(null):(this.state.fecha_salida)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Salida
                  </label>
                  <Input
                    value={(this.state.hora_salida=="undefined")?(null):(this.state.hora_salida)}
                    onChange={(e)=>{this.setState({hora_salida:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({cantidad_animales:e.target.value})}}
                    value={this.state.cantidad_animales}
                    disabled={this.state.dsableBtn}
                      id="example3cols2Input"
                      placeholder="Escribe la cantidad de animales"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Enviado Por
                    </label>
                    <Input
                    onChange={(e)=>{ searhRecentName(e.target.value) }}
                    value={this.state.enviado_por}
                      id="example3cols2Input"
                      placeholder="Enviando Por"
                      type="text"
                      onBlur={()=>{
                                    setTimeout(() => {
                                      this.setState({arrayNameList:[]})
                                    }, "500")
                      }}
                      onFocus={(e)=>{ searhRecentName(e.target.value) }}
                      autoComplete="off"
                    />
                    <div style={{position: 'absolute',zIndex: 5}}>
                      {this.state.arrayNameList.map((a,b)=>{
                        return <Button className="mb-1 mt-1" onClick={(e)=>{setInfoName(e.target.textContent||e.target.innerText)}}>{a.recibido_por+'-'+a.nro_identificacion}</Button>
                      })}
                      {/*<Button className="mb-1 mt-1" onClick={()=>{}}>Name 2-0000001</Button>*/}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nro. de Identificación
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nro_identificacion:e.target.value})}}
                    value={this.state.nro_identificacion}
                      id="example3cols2Input"
                      placeholder="Escribe un número de identificación"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({informacion_adicional:e.target.value})}}
                    value={this.state.informacion_adicional}
                      id="exampleFormControlTextarea1"
                      rows="1"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Subir Documento
                  </label>
                  <Form>
                    <div className="custom-file">
                      <input
                      onChange={(e)=>{this.setState({file_document:e.target.files[0]})}}
                        className="custom-file-input"
                        id="customFileLang"
                        lang="es"
                        type="file"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileLang"
                      >
                        Elegir archivo
                      </label>
                    </div>
                  </Form>
                {(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('_').pop()}</a>)}

                </Col>
              </Row>
                   {this.state.mess ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess}
                        </span>
                      </small>
                    </div>
                  ) : null}
              <Row>
                <Col md="4">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfoExit}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoCompartExit(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
       <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Salida de Animales
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoCompartExit(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.compartiment_exit.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                       <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.nombre_compartimento}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoCompartExit(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteComparExit(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

