import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse
} from "reactstrap";
import config from "config";

export const PutContentStatus = (props,type,status) => {
   var data_status_color = '#7a7a7a78'
   var data_status_status = 'Cargando...'
   //console.log(type,status)
   if (props[type]!=undefined) {
     data_status_status = status
     if (status=='finalizado') {
      data_status_color = '#7ac142'
     }
     if (status=='desarrollo') {
      data_status_color = '#002a5c'
     }
   }
        return {color:data_status_color,register_status:data_status_status}
}

export function PutContent(props) {

var contenido = []
var contenidoData = []
if (props.data_function.allDataTrza[props.type_function]!=undefined) {
        if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
           props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{

            var get_select =putForSelect(Object.entries(value))
            Object.entries(value).map((a,b)=>{
                  //contenidoData[b]=a[1]
              if (a[0]!='status'  && a[0]!='id' && a[0]!='trazabilidad_id' && a[0]!='user_id' && a[0]!='codigo' && a[0]!='trazabilidade_ida' && a[0]!='created_at' && a[0]!='updated_at') {
                if (a[0].indexOf('select_')==-1) {
                  if (a[1]!=null) {
                    if (a[0].indexOf('file_')!=-1) {
                      var file_data = a[1]
                      if (file_data!=null) {
                        var val_file_data = file_data.split('/')
                        contenido[b] = <><Col key={b} md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function.allDataTrza[props.type_function]['title_label'].split('|'),a[0])}:</label><br /> {val_file_data[1].split('.')[1]+get_select[a[0]]}</Col></>
                      }
                    }else{
                      contenido[b] = <><Col key={b} md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function.allDataTrza[props.type_function]['title_label'].split('|'),a[0])}:</label><br /> {a[1]+get_select[a[0]]}</Col></>
                    }
                  }
                }
              
              }
            })
           })
        }
}
        return (contenido)
}
const putForSelect = (field) => {
                var get_data_select = []
                field.map((a,b)=> {
                  if (a[0].indexOf('select_')!=-1) {
                    get_data_select[a[0].replaceAll('select_','')] = ' '+a[1]
                  }else{
                    get_data_select[a[0].replaceAll('select_','')] = ''
                  }
                })
                // if (field[0].indexOf('select_')!=-1) {
                //   get_data_select[field[0]] = field[1]
                // }
                return get_data_select
}
export function PutContentSub(props) {
 var contenido = []
 if (props.data_function.allDataTrza[props.type_function]!=undefined) {

         if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
            props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
       contenido[index] = <li key={index} style={{listStyle: 'none'}}>
                                  <div className="elementor-toggle-item" style={{zIndex: '99'}} >
                                      <h6 onClick={()=>{props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">Datos {index+1}</div>
                                      </h6>
                                    <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                                    <Col md="12">
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{value.status}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                      <PutContentDesglos 
                                                      data_function={[value]} 
                                                      type_function={props.type_function} 
                                                      data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
                                                      />
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li>              
            })
         }
}
         return (contenido)
}
// export function PutContentSub(props) {
//  var contenido = []
//  if (props.data_function.allDataTrza[props.type_function]!=undefined) {

//          if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
//             props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
//        contenido[index] = <li key={index}>
//                                   <div className="elementor-toggle-item">
//                                       <h6 onClick={()=>{props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
//                                         <div className="elementor-toggle-title">Datos {index+1}</div>
//                                       </h6>
//                                     <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} style={{width: '250%',position: 'relative',right: '100%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
//                                     <ul>
//                                     <Col md="12" style={{margin: 'auto'}}>
//                                                   <Card>
//                                                     <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
//                                                     <Row>
//                                                       <Col md={9}>
//                                                         <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
//                                                       </Col>
//                                                       <Col md={3}>
//                                                         <h4 className="mb-0" style={{color: '#ffffff'}}>{value.status}</h4>
//                                                       </Col>
//                                                     </Row>
//                                                     </CardHeader>
//                                                     <CardBody>
//                                                       <Row>
//                                                       <PutContentDesglos 
//                                                       data_function={[value]} 
//                                                       type_function={props.type_function} 
//                                                       data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
//                                                       />
//                                                       </Row>
//                                                     </CardBody>
//                                                   </Card>
//                                                 </Col>
//                                     </ul>
//                                     </Collapse>
//                                   </div>
//                                 </li>              
//             })
//          }
// }
//          return (contenido)
// }
function forSelectTitleMain(data) {
  var title_main=''
  data.split('|').map((a,b)=>{
    var get_g = a.split('*')
      if (get_g[1]==1) {
        title_main = get_g[0]
      }
  })
    return title_main
}
export function PutContentSubTwo(props) {

 var contenido = []
 if (props.data_function.allDataTrza[props.type_function]!=undefined) {
         if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
          var get_title_main = forSelectTitleMain(props.data_function.allDataTrza[props.type_function]['divider_title_select'])
            props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
       contenido[index] = <li key={index}>
                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=> {props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">Guía: {(value[get_title_main]=='null')?('Sin número de guía'):(value[get_title_main])}- Finca: {value.nombre_de_la_finca} - {value.fecha_salida+' '+value.hora_salida}</div>
                                      </h6>
                                    <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                  <PutContentSubSunTree
                  data_function={[value]}
                  data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
                  type_function={props.type_function}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datatc"}
                  titleType={props.titleType}
                  />

                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_two]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_two]['title_label']}
                  type_function={props.type_function_two}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datav"}
                  titleType={props.titleTypeTwo}
                  />
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li>
            })
         }
}

         return (contenido)
}
export function PutContentSubPlant(props) {

 var contenido = []
  if (props.data_function.allDataTrza[props.type_function]!=undefined) {
         if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
            var get_title_main = forSelectTitleMain(props.data_function.allDataTrza[props.type_function]['divider_title_select'])
            props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{

       contenido[index] = <div className="elementor-toggle-item" key={index}>
                                      <h6 onClick={()=> {props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">Guía: {(value[get_title_main]=='null')?('Sin número de guía'):(value[get_title_main])}</div>
                                      </h6>
                                    <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                  <PutContentSubSunTree
                  data_function={[value]}
                  data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
                  type_function={props.type_function}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datadp"}
                  titleType={props.titleType}
                  />
                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_two]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_two]['title_label']}
                  type_function={props.type_function_two}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datasp"}
                  titleType={props.titleTypeTwo}
                  />
                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_tree]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_tree]['title_label']}
                  type_function={props.type_function_tree}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"dataw"}
                  titleType={props.titleTypeTree}
                  />
                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_four]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_four]['title_label']}
                  type_function={props.type_function_four}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datapt"}
                  titleType={props.titleTypeFour}
                  />
                                    </ul>
                                    </Collapse>
                                  </div>
            })
         }
}

         return (contenido)
}
export function PutContentSubSunTree(props) {
 var contenido = []
         if (props.data_function.length!=0) {
            props.data_function.map((value,index)=>{
              if (value==undefined) {
         contenido[index] = <li key={index}>
                                    <div className="elementor-toggle-item">
                                        <h6 onClick={()=>{props.openedNextChild(props.states.openColapseNextChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                                <div className="elementor-toggle-title">{props.titleType}</div>

                                        </h6>
                                        {/*style={{width: '280%',position: 'relative',right: '115%'}}*/}
                                      <Collapse isOpen={props.states.openColapseNextChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                      <ul>
                                      <Col md="12" style={{margin: 'auto'}}>
                                                    <Card>
                                                      <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                                                      <Row>
                                                        <Col md={9}>
                                                          {/*<h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>*/}
                                                        </Col>
                                                        <Col md={3}>
                                                          {/*<h4 className="mb-0" style={{color: '#ffffff'}}>{value.status}</h4>*/}
                                                        </Col>
                                                      </Row>
                                                      </CardHeader>
                                                      <CardBody>
                                                        <Row>
    <Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Sin datos</label></Col>
                                                        </Row>
                                                      </CardBody>
                                                    </Card>
                                                  </Col>
                                      </ul>
                                      </Collapse>
                                    </div>
                                  </li>
              }else{
         contenido[index] = <li key={index}>
                                    <div className="elementor-toggle-item">
                                        <h6 onClick={()=>{props.openedNextChild(props.states.openColapseNextChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                                <div className="elementor-toggle-title">{props.titleType}</div>

                                        </h6>
                                        {/*style={{width: '280%',position: 'relative',right: '115%'}}*/}
                                      <Collapse isOpen={props.states.openColapseNextChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                      <ul>
                                      <Col md="12" style={{margin: 'auto'}}>
                                                    <Card>
                                                      <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                                                      <Row>
                                                        <Col md={9}>
                                                          <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                        </Col>
                                                        <Col md={3}>
                                                          <h4 className="mb-0" style={{color: '#ffffff'}}>{value.status}</h4>
                                                        </Col>
                                                      </Row>
                                                      </CardHeader>
                                                      <CardBody>
                                                        <Row>
                                                       <PutContentDesglos
                                                        data_function={[value]} 
                                                        type_function={props.type_function} 
                                                        data_function_label={props.data_function_label}/>
                                                        </Row>
                                                      </CardBody>
                                                    </Card>
                                                  </Col>
                                      </ul>
                                      </Collapse>
                                    </div>
                                  </li>
              }
            })
         }
         return (contenido)
}
export function PutContentDesglos(props) {
var contenido = []
if (props.data_function!=undefined) {
        if (props.data_function.length!=0) {
           props.data_function.map((value,index)=>{
            // delete value.id
            // delete value.trazabilidad_id
            // delete value.user_id
            // delete value.codigo
            // delete value.trazabilidade_ida
            // delete value.created_at
            // delete value.updated_at
            // delete value.register_link_id
            
            Object.entries(value).map((a,b)=>{
              if (a[0]!='status'  && a[0]!='id' && a[0]!='register_link_id' && a[0]!='trazabilidad_id' && a[0]!='user_id' && a[0]!='codigo' && a[0]!='trazabilidade_ida' && a[0]!='created_at' && a[0]!='updated_at') {
               if (a[1]!=null) {
                if (a[0].indexOf('file_')!=-1) {
                  var file_data = a[1]
                  if (file_data!=null) {
                    var val_file_data = file_data.split('/')
                    contenido[b] = <><Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> {val_file_data[1].split('.')[1]}</Col></>
                  }
                }else{
                  contenido[b] = <><Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> {a[1]}</Col></>
                }
                //contenido[b] = <><Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> {a[1]}</Col></>
               }
              }
            })
           })
        }
}
        return (contenido)
}
const getContentInfoFile = (data,tabled) =>{
  var content=[]
  data.map((a,b)=>{
if (a!='') {

  if (a.indexOf(tabled)!=-1) {  
content[b] =             <Col md={3}>
                          <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-box-wrapper">
                                 <div className="elementor-icon-box-icon">
                                   <span className="elementor-icon elementor-animation-">
                                    <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                                   </span>
                                 </div>
                                   <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+a} className="elementor-icon-box-content">
                                     <h3 className="elementor-icon-box-title">
                                       <span>
                                        {a.split('/')[1]}
                                       </span>
                                     </h3>
                                   </a>
                               </div>
                            </div>
                          </div>
                        </div>
                        </Col>
  }
}
  })
  return content
}
const getContentInfo=(data,tabled)=>{

         var data_logi=[]
         if (data!=null) {
          if (data!='') {
             // if (data.indexOf('|')!=-1) {
             //   data_logi=data.split('|')
             //  console.log(data)
             // }else{
             //  //data_logi = Object.values(data).join("|").split("|").filter((a,b)=>a!='|')
             //   data_logi = [data]
             // }
             if (typeof data == 'string') {
               data_logi=data.split('|')
             }else{
               data_logi = Object.values(data).join("|").split("|").filter((a,b)=>a!='|')
             }

          }
         }
         return getContentInfoFile(data_logi,tabled)

}
export function PutContentFileG(props) {
               var contenido = []
               if (props.data_function.allDataTrza[props.type_function]!=undefined) {
          if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
                    props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
                      Object.entries(value).map((a,b)=>{
                        if (a[0].indexOf('file_')!=-1) {
                        var documents_data = a[1]
                        if (props.data_function.dataDocuments[props.type_function]!=undefined) {
                          documents_data = props.data_function.dataDocuments[props.type_function]
                        }

                        contenido[b] = <>{getContentInfo(documents_data,props.type_function)}</>
                        }
                      })
                    })
                 }
               }
               if (contenido.length==0) {
                return 'Sin archivo'
               }else{
                return (contenido)
               }
}
export function PutContentFile(props) {
               var contenido = []
               if (props.data_function.allDataTrza[props.type_function]!=undefined) {
          if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
                    props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
                      Object.entries(value).map((a,b)=>{
                        if (a[0].indexOf('file_')!=-1) {
                              contenido[b] = <>
            <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
            <div className="elementor-widget-container">
                              <div className="elementor-icon-box-wrapper">
                                 <div className="elementor-icon-box-icon">
                                 <span className="elementor-icon elementor-animation-">
                                 <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                                 </span>
                                 </div>
                                 <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+a[1]} className="elementor-icon-box-content">
                                 <h3 className="elementor-icon-box-title">
                                 <span>
                                 {a[1]}         
                                 </span>
                                 </h3>
                                 </a>
                               </div>
            </div>
            </div>
            </div>
                               </>
                        }
                      })
                    })
                 }
               }
               if (contenido.length==0) {
                return 'Sin archivo'
               }else{
                return (contenido)
               }
}
const putTitle = (data,title) => {
  var result = ''
  data.map((a,b)=>{
    var the_same = a.replaceAll('ñ','n').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ','_').replaceAll('/','_').toLowerCase()
      if (title.indexOf(the_same)!=-1) {
        result = a
      }
  })
      return result
}