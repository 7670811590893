import React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  //Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";
import { NavLink as ComponentLink } from "react-router-dom";
import { get_data_mrv, save_report_mrv_status, row_fertilizer_production } from "network/ApiAxios";
import {GuideMessage} from "views/pages/components/GuideMessage";
import {UserContext} from "layouts/store";
import jQuery from "jquery";
import ModalPLPreparationFormT from "views/pages/forms/Production/Grains/GrainNama/Modal/ModalPLPreparationFormT";
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import GNamaCultive from "views/pages/forms/Production/Grains/GrainNama/StartGrainNamaFormRelease.js";

class AlertMRV extends React.Component {
  static contextType = UserContext
  state = {
    data_information:[],
    data_information_filter:[],
    user_type:null,
    collapse:{['collapseON']:false},
    typeCollap:'collapseON',
    put_code:null,
    visibleRight:false,
    visibleRightTwo:false,
    put_index_selector:null,
    codeSave:null
  }
  async componentDidMount(){
    this.putData()
  }
  putData = async (data,ind_two)=>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(window.location.search).get("code");
    var get_data = await get_data_mrv(gerParam,jsondata.type_traza)
    if (get_data!=null) {
      if (get_data!='') {
        if (get_data.data_information==undefined) {
          window.$('#notification_mrv').css('display','none')
          return
        }
        this.setState({data_information:get_data.data_information})
      }else{
        window.$('#notification_mrv').css('display','none')
      }
    }else{
      window.$('#notification_mrv').css('display','none')
    }
  }
  putContentInformation =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
        data_content[b+ind_two] = <><Col md={12} style={{color: 'black'}}>{a}</Col></>
    })
    return data_content
  }
  putContentInformationPrio =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = <><Col md={12} style={{color: 'black',borderRadius: '3px',textAlign: 'center', margin: '1px', backgroundColor: a.split('-')[1]}}>{a.split('-')[0]}</Col></>
    })
    return data_content
  }
  putContentInformationPrioMain =(data,ind_two,ind_two_main)=>{
    var data_content=[]
    var data_content_two=[]
    Object.values(data).map((a,b)=>{
      if (a=='Muy Importante-red') {
        data_content[b+ind_two]='a'
      }
      if (a=='Importante-orange') {
        data_content[b+ind_two]='b'
      }
      if (a=='No Tan Importante-yellow') {
        data_content[b+ind_two]='c'
      }
    })
    var list_data_content = data_content.filter(Boolean).sort(this.listaArray).filter(Boolean)[0]
    var color_card={[ind_two_main]:'white'}
    if (list_data_content=='a') {
      color_card[ind_two_main]='#88211a'
    }
    if (list_data_content=='b') {
      color_card[ind_two_main]='#FF9900'
    }
    if (list_data_content=='c') {
      color_card[ind_two_main]='#f0c946'
    }
    return {title_status:list_data_content,color_card_status:color_card}
  }
   listaArray =(a,b)=>{
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
  }
    rejectFunc = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Cancelado', life: 3000 });
    }
    confirm1 = (data,indx,ind_three,ind_general,activity_title,code_head,data_for_filter) => {
        confirmDialog({
            message: 'Estas seguro en guardar esta información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => this.changeStatusObs(data,indx,ind_three,ind_general,activity_title,code_head,data_for_filter),
            reject: () => this.rejectFunc()
        });
    };
  putContentInformationStatus =(data,ind_two,ind_three,ind_general,activityVar,activityTableVar,codigo,monitor_type,data_for_filter)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = 
      <>
        <Col md={12} style={{color: 'black',borderRadius: '3px', margin: '1px', backgroundColor: a.split('-')[1]}}>
          {/*{a.split('-')[0]}*/}
          {(a.split('-')[0]=='Revisar')?('Realizado, esperando por conformidad'):(null)}
          {(a.split('-')[0]=='No Conforme')?('No Conforme | '):(null)}
          {(a.split('-')[0]=='No Conforme')?(<>
            {(window.location.pathname.indexOf('info_producer_g')==-1 && window.location.pathname.indexOf('info_crops_g')==-1 && window.location.pathname.indexOf('info_previous_g')==-1)?(<>
              <button 
              className="btn p-0 view_detail_panel" 
              onClick={(targ)=>{
                this.onpenModal(a,b,ind_three,ind_general,activityVar,activityTableVar,codigo,monitor_type,targ.target)
              }}
              >Ver detalle</button>-
            </>):(null)}
            <button 
            className="btn p-0 btn_change_state" 
            id={"btn_change_state_"+activityVar?.split("*")[activityVar?.split("*").length-1]?.trim()} 
            onClick={()=>{
              this.confirm1(a,b,ind_three,ind_general,activityVar,codigo,data_for_filter)
            }}
            >Cambiar Estado a Realizado</button>
            </>):(null)}
          {(a.split('-')[0]=='Por Hacer')?('Por Hacer | '):(null)}
          {(a.split('-')[0]=='Por Hacer')?(<>
            {(window.location.pathname.indexOf('info_producer_g')==-1 && window.location.pathname.indexOf('info_crops_g')==-1 && window.location.pathname.indexOf('info_previous_g')==-1)?(<>
              <button 
              className="btn p-0 view_detail_panel" 
              onClick={(targ)=>{
                this.onpenModal(a,b,ind_three,ind_general,activityVar,activityTableVar,codigo,monitor_type,targ.target)
              }}
              >Ver detalle</button>-
              </>):(null)}
              <button 
              className="btn p-0 btn_change_state" 
              id={"btn_change_state_"+activityVar?.split("*")[activityVar?.split("*").length-1]?.trim()} 
              onClick={()=>{
                this.confirm1(a,b,ind_three,ind_general,activityVar,codigo,data_for_filter)
              }}
              >Cambiar Estado a Realizado</button>
            </>):(null)}
        </Col>
      </>
    })
    return data_content
  }
  onpenModal= async (data,indx,ind_three,ind_general,activity_title,activityTableVar,codigo,monitor_type,targTag)=>{
    if (this.props.name!=null) {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      if (activityTableVar=='info_producer_g' || activityTableVar=='info_crops_g' || activityTableVar=='info_previous_g') {
        var info_select = null
        if (activityTableVar=='info_producer_g') {
          info_select = { name: 'Información Productor', code: 'IO', table: 'info_producer_g' }
        }
        if (activityTableVar=='info_crops_g') {
          info_select = { name: 'Información Cultivo', code: 'IC', table: 'info_crops_g' }
        }
        if (activityTableVar=='info_prev_g') {
          info_select = { name: 'Producción Previa', code: 'PP', table: 'info_prev_g' }
        }
        
        this.setState({
          visibleRightTwo:true,
          info_select:info_select,
          codeSave:codigo
        })
        //this.props.history.push(activityTableVar+'?code='+codigo,{verify_mrv:monitor_type})
        return;
      }
      if (activityTableVar==undefined) {
        alert('Sin actividad.')
        return
      }
      var dataFertilizer = await row_fertilizer_production(jsondata._id,codigo,null,activityTableVar)
      var get_id_modal_register=activity_title?.split("*")
      var id_clean = get_id_modal_register[get_id_modal_register.length-1]?.trim()
      var tittle_clean = get_id_modal_register[0]?.trim()
      var get_data_check = dataFertilizer.trazaData.filter((a,b)=> a.id == id_clean)
      if (get_data_check.length!=0) {
        var getIndex = null
        if (jQuery(targTag).hasClass('view_detail_panel')) {
          var listItem = jQuery(targTag);
          getIndex = jQuery( ".view_detail_panel" ).index( listItem )
        }
        this.setState({dataFertilizer:dataFertilizer,tableCall:activityTableVar,titleCall:tittle_clean,data_selected:get_data_check[0],put_code:codigo,put_index_selector:getIndex})
        this.setState({visibleRight:true})
      }else{
        alert('Este registro no se puede editar, vaya a su producción para editarlo desde esa actividad.')
        //console.log(activityTableVar+'?code='+codigo,{verify_mrv:monitor_type})
        this.props.history.push('/admin/gdc-panel?code='+codigo,{verify_mrv:monitor_type})
        //check_valid_activity(activity_title)
      }
    }else{
      var get_id_modal_register=activity_title?.split("*")
      var id_clean = get_id_modal_register[get_id_modal_register.length-1]?.trim()
      jQuery('#btn_click_'+id_clean).click()
    }
  }
  change_title=()=>{
    this.setState({titleCall:null })
  }
  changeStatusObs= async (data,indx,ind_three,ind_general,activity_title,code_head,data_for_filter)=>{
    var thisState= this
    // if (!window.confirm('Estas Seguro en Cambiar el Estado?')) {
    //   return
    // }

       var getParam = new URLSearchParams(window.location.search).get("code");
       if (getParam==null) {
          getParam = code_head
       }
       let items = JSON.parse(this.state.data_information[ind_general]['data_adjunt'])
       let item = items.filter((a,b)=> JSON.stringify(a).indexOf(JSON.stringify(data_for_filter))!=-1)
       let itemFirst = item[0]
       var change_status = {...itemFirst.datastate.state_status,[indx]:'Revisar'}
       itemFirst.datastate.state_status = change_status
       item[0] = itemFirst;
       let itemsTwo = this.state.data_information;
       let itemTwo = itemsTwo[ind_general];
       itemTwo.data_adjunt = JSON.stringify(items)
       itemsTwo[ind_general]=itemTwo
       await save_report_mrv_status(JSON.stringify(items),itemTwo.id,itemTwo.mvr_id,getParam,activity_title)
       this.putData()
  }
  getContetnTwo =(data,ind_two,ind_general,codigo,monitor_type,data_for_filter)=>{
                                       var data_content_td=[]
                                       var data_content_tr=[]
                                       var order_data = Object.entries(data).sort()
                                       var activityVar = []
                                       var activityTableVar = []
                                       var get_date_time = []
                                               order_data.map((valdata,inddata)=>{
                                              if (valdata[0]=='activity') {
                                                activityVar=valdata[1][0]
                                              }

                                              if (valdata[0]=='activity_table') {
                                                activityTableVar=valdata[1][0]
                                              }
                                              if (valdata[0]!='activity') {
                                                if (valdata[0]=='init_date') {
                                                  data_content_td[0]=<>
                                                    <td style={{padding: 0,width: '8%'}}>
                                                      {this.putContentInformation(valdata[1],inddata)}
                                                    </td>
                                                  </>
                                                }
                                                  data_content_td[1]=<><td style={{padding: 0,width: '7%',color:'black'}}>{codigo}</td></>
                                                if (valdata[0]=='observation') {
                                                  data_content_td[2]=<><td style={{padding: 0,width: '20%'}}>{this.putContentInformation(valdata[1],inddata)}</td></>
                                                }
                                               if (valdata[0]=='recommendation') {
                                                  data_content_td[3]=<><td style={{padding: 0,width: '20%'}}>{this.putContentInformation(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='priority') {
                                                 data_content_td[4]=<><td style={{padding: 0,width: '13%'}}>{this.putContentInformationPrio(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='state_status') {
                                                 data_content_td[5]=<><td style={{padding: 0,width: '25%'}}>{this.putContentInformationStatus(valdata[1],inddata,ind_two,ind_general,activityVar,activityTableVar,codigo,monitor_type,data_for_filter)}</td></>
                                               }
                                               if (valdata[0]=='motive') {
                                                 data_content_td[6]=<><td style={{padding: 0,width: '20%'}}>{this.putContentInformationPrio(valdata[1],inddata)}</td></>
                                               }
                                               data_content_tr=<tr>{data_content_td}</tr>
                                              }
                                              })
                                              return (data_content_tr)
  }

  ksort = (a,b) => {
    //console.log(a,b)
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
}
  setCounterObs =(data)=>{
var get_data_pr = []
  Object.values(data).map((val,ind)=>{
    get_data_pr[ind] = Object.keys(val).length
  })
    var get_numb_max = Math.max(...get_data_pr)
    return get_numb_max
  }

  getContetnHeader =(data,ind_two,ind_general)=>{
                                      var data_content=[]
                                      var data_content_two=[]
                                      var order_data = Object.entries(data).sort()
                                              order_data.map((valdata,inddata)=>{
                                                if (valdata[0]!='activity') {
                                                  if (valdata[0]=='observation') {
                                                  data_content[inddata+ind_two] = <>
                                                      <label className="form-control-label m-0" style={{color: '#002a5c'}}> - Observaciones: {this.setCounterObs(data)} </label>
                                                    </>
                                                  }
                                                  if (valdata[0]=='priority') {
                                                     data_content[inddata+ind_two]=<><p className="form-control-label" style={{padding: 0,margin:0,color:'#002a5c'}}> {
                                                       (this.putContentInformationPrioMain(valdata[1],inddata,ind_two).title_status=='a')?(' Muy Importante '):(
                                                         (this.putContentInformationPrioMain(valdata[1],inddata,ind_two).title_status=='b')?(' Importante '):(
                                                           (this.putContentInformationPrioMain(valdata[1],inddata,ind_two).title_status=='c')?(' No Tan Importante '):(null)
                                                           )
                                                         )
                                                     } </p></>
                                                    data_content_two= (this.putContentInformationPrioMain(valdata[1],inddata,ind_two).title_status=='a')?('#88211a'):((this.putContentInformationPrioMain(valdata[1],inddata,ind_two).title_status=='b')?('#FF9900'):((this.putContentInformationPrioMain(valdata[1],inddata,ind_two).title_status=='c')?('#ead62a'):(null)))
                                                  }
                                                }
                                              })
                                                return ({data_content_one:data_content.filter(Boolean),data_content_two:data_content_two})
  }
  toggleFunction = async (index,res,monitor_type) =>{
    var getInfoFilter=[]
    if (monitor_type=='MAIN') {
      getInfoFilter = await this.state.data_information
      this.setState({data_information_filter:getInfoFilter})
      if (jQuery(".putDataListON").length==0) {
        this.setState({view_result_list_ON:' - Sin Observaciones'})
      }else{
        this.setState({view_result_list_ON:''})
      }
      if (jQuery(".putDataListRE").length==0) {
        this.setState({view_result_list_RE:' - Sin Observaciones'})
      }else{
        this.setState({view_result_list_RE:''})
      }
      if (jQuery(".putDataListVA").length==0) {
        this.setState({view_result_list_VA:' - Sin Observaciones'})
      }else{
        this.setState({view_result_list_VA:''})
      }
    }else{
      getInfoFilter = await this.state.data_information.filter((a,b)=>a.monitor_type==monitor_type)
      this.setState({data_information_filter:getInfoFilter})
      jQuery(".score_row").sort(this.sortDivs).appendTo(jQuery(".rowViewNumber"));
      if (jQuery(".putDataList").length==0) {
        this.setState({view_result_list:' - Sin Observaciones'})
      }else{
        this.setState({view_result_list:''})
      }
    }
      this.setState({collapse:{[index]:!this.state.collapse[index]}, typeCollap:index})
  }
sendToActivity=(data,code,fullData,verify_mrv)=>{
  // if (data=='general') {
  //   this.props.history.push('info_producer_g?code='+code)  
  // }
  if (fullData.datastate.activity_table==undefined) {
    alert('Ruta sin definición');
  }else{
    this.props.history.push('/admin/gdc-panel?code='+code,{verify_mrv:verify_mrv})
  }
}
check_valid_activity = (data)=>{
  if (data=='production_sowing_transplant' && window.location.pathname.indexOf('production_sowing_g')!=-1) {
    return true
  }
  if (data=='hading_info_weeds' && window.location.pathname.indexOf('hading_inventory_weeds')!=-1) {
    return true
  }
  if (data=='hading_info_plague' && window.location.pathname.indexOf('hading_inventory_plague')!=-1) {
    return true
  }
  if (data=='harvest_info_harvest' && window.location.pathname.indexOf('harvest_info_machinery')!=-1) {
    return true
  }
    return window.location.pathname.indexOf(data)!=-1
}
putContentNone=(data)=>{
   var content = []
   content = null
return content
}
sortDivs = (a,b) => {
    //console.log(jQuery(a).data("data-index"),jQuery(a).data("index"))
    return jQuery(a).data("index") < jQuery(b).data("index") ? 1 : -1;  
};
contentDataAdjuntOne=(val,indx)=>{
var content = []
var getDataVal = JSON.parse(val.data_adjunt)

getDataVal.map((valadjun,ind)=>{
                      if (JSON.stringify(valadjun).split('Por Hacer').length-1!=0 || JSON.stringify(valadjun).split('Revisar').length-1!=0 || JSON.stringify(valadjun).split('No Conforme').length-1!=0) {
                          content[ind]= (<>
                                  {
                                    (valadjun.value.split('*')[0]==this.props.table || valadjun.value.split('*')[0]=='general')?(
                                      (this.check_valid_activity(valadjun.datastate.activity_table[0]))?(<>
                                          <Col md={12} className="alert alert-light putDataList score_row" data-index={ind} style={{padding: '1px',backgroundColor: 'white', textAlign:'center',marginBottom: '2px'}}>
                                              <label className="form-control-label m-0" style={{color: 'black'}}>Titulo/Actividad: {' '}
                                              {
                                                (valadjun.value!='general')?(valadjun.name):((valadjun.datastate.activity==undefined)?(null):(
                                                  valadjun.datastate.activity[0].split("*")[0]
                                                  ))
                                              }
                                              </label>
                                              <Col md={12}>
                                                <Row>
                                                          <table className="align-items-center table-bordered" style={{margin: 0,width: '100%'}} responsive>
                                                            <thead className="thead-light">
                                                              <tr>
                                                                <th style={{padding: 0,color:'black'}}>Fecha de Inicio</th>
                                                                <th style={{padding: 0,color:'black'}}>ID Foodchain</th>
                                                                <th style={{padding: 0,color:'black'}}>Observaciones</th>
                                                                <th style={{padding: 0,color:'black'}}>Recomendación</th>
                                                                <th style={{padding: 0,color:'black'}}>Prioridad</th>
                                                                <th style={{padding: 0,color:'black'}}>Estado de Cumplimiento</th>
                                                                {(JSON.stringify(valadjun.datastate).split('motive').length-1!=0)?(
                                                                  <th style={{padding: 0}}>Motivo</th>
                                                                ):(null)}
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {this.getContetnTwo(valadjun.datastate,ind,indx,val.codigo,val.monitor_type,valadjun)}
                                                            </tbody>
                                                          </table>
                                              </Row>
                                            </Col>
                                          </Col>
                                        </>):(this.putContentNone(valadjun.datastate.activity_table[0]))
                                      ):(null)
                                  }

                              </>)
                      }
                    })
return content
}
contentDataAdjunt=(getDataVal,val,indx,monitor_type)=>{
  var content = []
  var content_type = []
                      //console.log(this.filter_data_all(val.monitor_type),val.monitor_type)
                      getDataVal.map((valadjun,ind)=>{
                        if (JSON.stringify(valadjun).split('Por Hacer').length-1!=0 || JSON.stringify(valadjun).split('Revisar').length-1!=0 || JSON.stringify(valadjun).split('No Conforme').length-1!=0) {
                          content[ind] = <>
                                  {
                                    (valadjun.value.split('*')[0]==this.props.table || valadjun.value.split('*')[0]=='general')?(
                                          <Col md={12} className={"alert alert-light putDataList"+val.monitor_type+" score_row"} data-index={ind} style={{padding: '1px',backgroundColor: 'white', textAlign:'center',marginBottom: '2px'}}>
                                              <label className="form-control-label m-0" style={{color: 'black'}}>Titulo/Actividad: {' '}
                                              {
                                                (valadjun.value!='general')?(valadjun.name):((valadjun.datastate.activity==undefined)?(null):(
                                                  valadjun.datastate.activity[0].split("*")[0]
                                                  ))
                                              }
                                              </label>
                                              <Col md={12}>
                                                <Row>
                                                          <table className="align-items-center table-bordered" style={{margin: 0,width: '100%'}} responsive>
                                                            <thead className="thead-light">
                                                              <tr>
                                                                <th style={{padding: 0,color:'black'}}>Fecha de Inicio</th>
                                                                <th style={{padding: 0,color:'black'}}>ID Foodchain</th>
                                                                <th style={{padding: 0,color:'black'}}>Observaciones</th>
                                                                <th style={{padding: 0,color:'black'}}>Recomendación</th>
                                                                <th style={{padding: 0,color:'black'}}>Prioridad</th>
                                                                <th style={{padding: 0,color:'black'}}>Estado de Cumplimiento</th>
                                                                {(JSON.stringify(valadjun.datastate).split('motive').length-1!=0)?(
                                                                  <th style={{padding: 0,color:'black'}}>Motivo</th>
                                                                ):(null)}
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {this.getContetnTwo(valadjun.datastate,ind,indx,val.codigo,val.monitor_type,valadjun)}
                                                            </tbody>
                                                          </table>
                                              </Row>
                                            </Col>
                                          </Col>
                                      ):(null)
                                  }

                              </>
                                        }
                                      })
                            return content

}
content_information = (dataInfo)=>{
  var content = []
  var monitor_type_var=[]
                    dataInfo.map((val,indx)=>{
                      if (val.data_adjunt!=null) {                        
                        if (JSON.parse(val.data_adjunt).length!=0) {
                        monitor_type_var[val.monitor_type]=JSON.parse(val.data_adjunt)
                          if (JSON.stringify(val.data_adjunt).split('Por Hacer').length-1!=0 || JSON.stringify(val.data_adjunt).split('Revisar').length-1!=0 || JSON.stringify(val.data_adjunt).split('No Conforme').length-1!=0) {
                           content[indx]=<><Col md={12}>
                                  <Col md={12}>
                                      {(val.monitor_type=='ON')?('Monitoreado por '+val.name_mvr+' ('+val.entidad+') '+this.state.view_result_list_ON):(null)}
                                      {(val.monitor_type=='RE')?('Revisión por '+val.name_mvr+' ('+val.entidad+') '+this.state.view_result_list_RE):(null)}
                                      {(val.monitor_type=='VA')?('Validación por '+val.name_mvr+' ('+val.entidad+') '+this.state.view_result_list_VA):(null)}
                                    </Col>
                                    <Col md={12}>
                                      <Row className={"rowViewNumber"}>
                                      {this.contentDataAdjunt(JSON.parse(val.data_adjunt),val,indx,monitor_type_var)}
                                      {/*{this.contentDataAdjunt(JSON.parse(val.data_adjunt),val,indx,monitor_type_var)}*/}
                                      </Row>
                                    </Col>
                                    </Col></>
                          }
                        }
                      }
                    })
                    return content
}
render(){
    var userdata = this.context.valueGlobal;
    const filterTypeTraza =(type_traza)=>{
      // Muy Importante-red
      // Importante-orange
      // No Tan Importante-yellow
      var validType = this.state.data_information.filter((a,b)=>a.monitor_type==type_traza)
      if (validType.length!=0) {
        return true
      }else{
        return false
      }
    }
  return (
    <>
    <ConfirmDialog />
      <SidebarCustom 
          onVisible={this.state.visibleRight} 
          onHide={() => this.setState({visibleRight:false})} 
          onWidth={ 650 }
          position="right" 
          title={'Información'}>
            <ModalPLPreparationFormT 
              hide={ () => this.setState({visibleRight:false}) } 
              sendInfoProps={this.state.dataFertilizer} 
              data_selected={this.state.data_selected} 
              refreshData={this.putData} 
              setParamsUrl={this.props.location} 
              tableCall={this.state.tableCall} 
              titleCall={this.state.titleCall} 
              change_title={this.change_title} 
              put_code={this.state.put_code} 
              put_index_selector={this.state.put_index_selector}
              />
      </SidebarCustom>

      <SidebarCustom 
          onVisible={this.state.visibleRightTwo} 
          onHide={() => this.setState({visibleRightTwo:false})} 
          onWidth={ 650 }
          position="right" 
          title={'Información general'}>
            <GNamaCultive
                {...this.props}
                info_select={this.state.info_select}
                codeSave={this.state.codeSave}
                hide={ () => this.setState({visibleRightTwo:false}) }
                mrv={true}
            />
            <br />
            <br />
            <br />
      </SidebarCustom>
{(this.state.data_information?.length!=0)?(<Col md={12} style={{marginTop: '-22px'}}>
              {(userdata.type_traza?.indexOf('P')!=-1)?(<>
                          <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                              <li className="flex align-items-center py-2">
                                  <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
                                      <i className="pi pi-bell text-xl text-orange-500" />
                                  </div>
                                  <span className="text-700 line-height-3">

                                  <div style={{alignSelf: 'center'}}>
                                    <Row>

                                    <button style={{backgroundColor: 'transparent', border: 'none', textAlign: 'left'}} onClick={() => {this.toggleFunction('collapseMAIN',this.state.collapse['collapseMAIN'],'MAIN')}}>
                                      Usted tiene Observaciones pendientes, presione {' aquí '}
                                      {(this.props.content_area=='0')?(<>
                                          para mas información.
                                        </>):('sobre MRV para mas informacion.')}
                                    </button>
                                    </Row>
                                  </div>

                                  </span>
                              </li>
                          </ul>
{(this.props.content_area!='0')?(
                <Row style={{justifyContent:'center'}}>
                {(filterTypeTraza('ON'))?(<>
                <Col md={2} style={{textAlign:'center'}}>
                 <button id="btnMrvON" onClick={() => {this.toggleFunction('collapseON',this.state.collapse['collapseON'],'ON')}} className="btn">
                   <span style={{color: "white",fontSize: "15px",fontWeight: "700"}}>
                    Monitoreo
                   </span>
                  <i class="fas fa-chevron-down" style={{color:'white'}}></i>
                 </button>
                </Col>
                </>):(null)}
                {(filterTypeTraza('RE'))?(<>
                  <Col md={2} style={{textAlign:'center'}}>
                   <button id="btnMrvRE" onClick={() => {this.toggleFunction('collapseRE',this.state.collapse['collapseRE'],'RE')}} className="btn">
                     <span style={{color: "white",fontSize: "15px",fontWeight: "700"}}>
                      Revisión
                     </span>
                    <i class="fas fa-chevron-down" style={{color:'white'}}></i>
                   </button>
                  </Col>
                </>):(null)}
                {(filterTypeTraza('VA'))?(<>
                  <Col md={2} style={{textAlign:'center'}}>
                   <button id="btnMrvVA" onClick={() => {this.toggleFunction('collapseVA',this.state.collapse['collapseVA'],'VA')}} className="btn">
                     <span style={{color: "white",fontSize: "15px",fontWeight: "700"}}>
                      Validación
                     </span>
                    <i class="fas fa-chevron-down" style={{color:'white'}}></i>
                   </button>
                  </Col>
                  </>):(null)}
                </Row>
):(null)}
{/*                <Collapse isOpen={this.state.collapse[this.state.typeCollap]}>
                </Collapse>*/}
<SidebarCustom
    onVisible={ this.state.collapse[this.state.typeCollap] }
    onHide={ () => this.toggleFunction('collapseMAIN',this.state.collapse['collapseMAIN'],'MAIN') }
    onWidth={ 800 }
    position="right" 
    title="Datos MRV"
>
  <div className="alert alert-light" role="alert" style={{padding: '5px', backgroundColor:'white',borderRadius: '5px', color:'#002a5c',fontWeight: 'bold'}}>
    <Row>
    {(this.props.content_area=='0')?(this.content_information(this.state.data_information_filter)):(null)}
    </Row>


      {(this.props.content_area!='0')?( 
        this.state.data_information_filter.map((val,indx)=>{
          if (JSON.parse(val.data_adjunt).length!=0) {
            return (<>
                      <Col md={12} className="forFindNext">
                        {(val.monitor_type=='ON')?('Monitoreado por '+val.name_mvr+' ('+val.entidad+')'):(null)}
                        {(val.monitor_type=='RE')?('Revisión por '+val.name_mvr+' ('+val.entidad+')'):(null)}
                        {(val.monitor_type=='VA')?('Validación por '+val.name_mvr+' ('+val.entidad+')'):(null)}
                        {this.state.view_result_list}
                      </Col>
                      <Col md={12} className="rowViewNumber">
                        {this.contentDataAdjuntOne(val,indx)}
                      </Col>
                      </>)
          }

          })
      ):(null)}
  </div>
</SidebarCustom>


                </>):(null)}
              </Col>):(null)}
    </>
  );
 }
}

AlertMRV.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default AlertMRV;
