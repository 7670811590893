import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "reactstrap";
import React from "react";
import IndexNavbar from "components/Navbars/NavbarTrace.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import { get_data_row_main_kdes, export_excel_traza } from "network/ApiAxios";
import SectionHarvestKdes from "./SectionsViewTraceKdes/SectionHarvestKdesView";
import SectionPlantaKdes from "./SectionsViewTraceKdes/SectionPlantaKdesView";
import SectionImportKdes from "./SectionsViewTraceKdes/SectionImportKdesView";
import SectionDistKdes from "./SectionsViewTraceKdes/SectionDistKdesView";
import SectionDetailKdes from "./SectionsViewTraceKdes/SectionDetailKdesView";
import SectionDocuments from "./SectionsViewTraceKdes/SectionDocumentsView";
import SectionTrazaDataProd from "views/pages/examples/Trazability/SectionsViewTraceKdes/SectionTrazaDataProdView";

import { NavLink as ComponentLink } from "react-router-dom";
import {GuideMessage} from "views/pages/components/GuideMessage";

import ComplementFlow from './Flow/ComplementFlow';
import ComplementTable from './Flow/ComplementTable';
//import {put_arr_data_kdes, findCodeLoteGlobal, searhCodeLoteGlobal} from './Flow/DataConnectionsTablesKdes';

import {put_arr_data_kdes} from './Flow/DataTablesKdes';

import "./StyleCss.css";
export default class VistaTrace extends React.Component {
	state ={
		statusClass:[],
		statusColor:[],
		allDataTrza:null,
		allDataTrzaTwo:null,
		DataRowProdcutionCreate:['Cargando...'],
		DataRowProdcutionFertilizer:['Cargando...'],
		commercialization_export:['Cargando...'],
		commercialization_market:['Cargando...'],
		create_cosecha:['Cargando...'],
		create_plant_cold:['Cargando...'],
		create_plant_information_empaque:['Cargando...'],
		create_plant_pre_cold:['Cargando...'],
		create_plant_product:['Cargando...'],
		create_plant_sample:['Cargando...'],
		create_plant_treatment:['Cargando...'],
		create_plant_weight_product:['Cargando...'],
		logistics_transport_shipment:['Cargando...'],
		logistics_transport_transporte:['Cargando...'],
		transport_cosecha:['Cargando...'],
		'fruit_vegetable':['Cargando...'],
		'transport_vehicule_cosecha':['Cargando...'],
		code_traza:'Cargando...',
		data_user:null,
		components:null,
		data_vt_config:[],
		change_complent:0,
		set_data_excel:[],
		getMaxLeng:0
	}
	async componentDidMount(){
		//console.log(gerParam)
		if (this.props.type!=undefined) {
			var gerParamTwo = new URLSearchParams(window.location.search).get("token");
			this.setNameDB(gerParamTwo,1)
		}else{
			var gerParam = new URLSearchParams(this.props.location.search).get("code");
			if (gerParam==null) {
				this.setState({code_traza:'Sin Trazabilidad'});
				this.setState({create_plant_information_empaque:null});
				this.setState({DataRowProdcutionCreate:null});
				this.setState({DataRowProdcutionFertilizer:null});
				this.setState({commercialization_export:null});
				this.setState({commercialization_market:null});
				this.setState({create_cosecha:null});
				this.setState({create_plant_cold:null});
				this.setState({create_plant_pre_cold:null});
				this.setState({create_plant_product:null});
				this.setState({create_plant_sample:null});
				this.setState({create_plant_treatment:null});
				this.setState({create_plant_weight_product:null});
				this.setState({logistics_transport_shipment:null});
				this.setState({logistics_transport_transporte:null});
				this.setState({transport_cosecha:null});
				this.setState({fruit_vegetable:null});
				this.setState({transport_vehicule_cosecha:null});
			}else{
				this.setNameDB(gerParam,2)
			}
		}
	}
	configAllDataStatus = (dataRow) => {
//['create_plant_cold','create_plant_information_empaque','create_plant_pre_cold','create_plant_product','create_plant_sample','create_plant_treatment','create_plant_weight_product','fruit_vegetable','production_create','production_fertilizer','transport_cosecha','transport_vehicule_cosecha','commercialization_export','commercialization_market','logistics_transport_shipment','logistics_transport_transporte','create_cosecha'];
if (dataRow==undefined) {
	var data_array_get = []
}else{
	var data_array_get = Object.keys(dataRow)
}
data_array_get.map((a,b)=>{
 			 if (dataRow[a]['data_array'].length==0) {
 			 	this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
 				this.setState({statusColor:{...this.state.statusColor,[a]:'#7A7A7A'}});
 			 }else{
 			 	  dataRow[a]['data_array'].map((value,index)=>{
				 	if (value.status=='finalizado') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-check-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#58d999'}});
				 	}
				 	if (value.status=='desarrollo') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#f8d014'}});
					}
 			 	  })
 			 }
 })

	}
    disassembleArray = (data) => {
    	var data_dessamble = {}
    	if (data!=undefined) {		
	      Object.values(data).map((a,b)=>{
	      	Object.entries(a).map((ea,eb)=>{
	      		data_dessamble[ea[0]]=ea[1]
	      	})
	      })
    	}
      return data_dessamble
    }
		setNameDB = async(data,typeR) =>{
		this.setState({code_traza:data});
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var dataRow = null
        if (typeR==1) {
        	var get_type_traza = new URLSearchParams(window.location.search).get("section");
        	dataRow = await get_data_row_main_kdes(data,
        		'KDES',
        		get_type_traza,
        		5,
        		this.props.dataUser[0].user_id,
        		this.props.dataUser[0].user_id_connection)
        }else{
			dataRow = await get_data_row_main_kdes(data,
				'KDES',
				jsondata.type_traza,
				jsondata.userType,
				null,
				null)
        }
		
		this.configAllDataStatus( this.disassembleArray(dataRow.data.data_traza_row) )
		this.setState({data_vt_config: dataRow.data.data_vt_config})
		this.setState({data_user:dataRow.data.data_user});
		this.setState({allDataTrza: this.disassembleArray(dataRow.data.data_traza_row) })
		this.setState({allDataTrzaTwo: this.disassembleArray(dataRow.data.data_traza_row_for_excel) })
		//console.log(dataRow.data.data_vt_config)
		//console.log(dataRow.data['fruit_vegetable']['data_array'][0])
		if(dataRow.data.fruit_vegetable==null){
			this.setState({statusTrazaClass:'fas fa-minus-circle'});
			this.setState({statusTrazaColor:'#7A7A7A'});
			this.setState({fruit_vegetable:null});
		}else{
			this.setState({data_user:dataRow.data.data_user});
				 	if (dataRow.data.fruit_vegetable.status=='finalizado') {
		 				this.setState({statusTrazaClass:'fas fa-check-circle'});
		 				this.setState({statusTrazaColor:'#58d999'});
				 	}
				 	if (dataRow.data.fruit_vegetable.status=='desarrollo') {
		 				this.setState({statusTrazaClass:'fas fa-minus-circle'});
		 				this.setState({statusTrazaColor:'#f8d014'});
					}
			this.setState({fruit_vegetable:dataRow.data.fruit_vegetable});
		}
		if(dataRow.data.production_create==null){
			this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
			this.setState({statusProdcutionFertilizerColor:'#7A7A7A'});
			this.setState({DataRowProdcutionCreate:null});
		}else{
			if (dataRow.data.production_fertilizer[0]!=null) {
				 	if (dataRow.data.production_create.status=='finalizado') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-check-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#58d999'});
				 	}
				 	if (dataRow.data.production_create.status=='desarrollo') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#f8d014'});
					}
			}else{
					if (dataRow.data.production_create.status=='finalizado') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#58d999'});
				 	}
				 	if (dataRow.data.production_create.status=='desarrollo') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#f8d014'});
					}
			}
			this.setState({DataRowProdcutionCreate:dataRow.data.production_create});
		}
		if (dataRow.data=='Sin trazabilidad') {
			return
		}

		}
	pushSection = (components) => {
		this.setState({components:components})
	}
	getLengMaxFunct=(set_data_excel)=>{
		var setDataL = []
		var setDataLT = []
		if (set_data_excel.length>0) {
			setDataLT = set_data_excel.map((a,b)=>{
				return this.putTokenDataLeng(a.data.table_selected_info)
				if (a.data.table_selected_info=='create_harvest_kdes') {
					return this.putTokenDataLeng('fruit_vegetable_user')
				}
			})
			var getMaxLeng = Math.max(...setDataLT)
			this.setState({getMaxLeng:getMaxLeng})
		}
		//return getMaxLeng
	}
	putTokenDataLeng=(setTable)=>{
	    var getDataLeng = []
	 	if (this.state.allDataTrzaTwo[setTable]!=undefined) {	 		
	      this.state.allDataTrzaTwo[setTable].divider_title_select.split('|').map((a,b)=>{
			 getDataLeng.push(a.split('*')[0])
	      })
	 	}
	 return getDataLeng.length+1
	}
	exportExcel=async()=>{
		//await this.setState({allDataTrza: this.state.allDataTrzaTwo})
		var setData = []
		var get_tokens = this.state.set_data_excel[1].map((a)=> {return a.data.token_id} )
		var reduce_token = [...new Set(get_tokens)]
		this.state.set_data_excel[1].map((a,b)=>{
			var get_id_lot = a.id.split('-')
			setData.push(this.putTokenData(reduce_token,a.data.table_selected_info,a.data.table_selected_info,get_id_lot[get_id_lot.length-1],a.data.token_id))
			if (a.data.table_selected_info=='create_harvest_kdes') {
				setData.push(this.putTokenData(reduce_token,'fruit_vegetable_user','fruit_vegetable_user',get_id_lot[get_id_lot.length-1],a.data.token_id))
			}
		})
		 setData.reverse()
		 var reducerData = [...new Map(setData.map(v => [v.table, v])).values()]
		//console.log(reducerData)
		 //console.log(reducerData)
		 export_excel_traza(reducerData)
	}
	setDataForExcel=(dataEdge,dataNode)=>{
		//console.log(dataEdge,dataNode)
		this.setState({set_data_excel:[dataEdge,dataNode]})
		this.getLengMaxFunct(dataNode)
	}
	dataReducer =(data)=>{
	    var returnData = [...new Set(data)]
	    return returnData
	}
	disassembleArrayTwo = (data)=>{
	  var setData = []
	  data.map((a,b)=>{
	    if (a.length!=undefined) {
	      setData.push(...a)
	    }else{
	      setData.push(a)
	    }
	  })
	  return setData
	}
 convertToTitle = (columnNumber) => {
    const numberToCharacterMap = {};
    for (let i = 1; i <= 26; i++) {
        const character = String.fromCharCode(i + 64);
        numberToCharacterMap[i] = character;
    }
    let resp = "";
    while(columnNumber > 0) {
        let remainder = columnNumber % 26;
        if (remainder === 0) {
            resp = 'Z' + resp;
            columnNumber = Math.floor(columnNumber / 26) - 1;
        } else {
            resp = numberToCharacterMap[remainder] + resp;
            columnNumber = Math.floor(columnNumber / 26);
        }
    }
    return resp;
 };
  putTokenData = (token_id,tableArr,tableSelected,num_lot,token_id_two) =>{
    var getDataTable = put_arr_data_kdes.filter((st)=> st.table_receive.table==tableSelected)
    var table_column='none'
    var table_label = []
    if (getDataTable[0]!=undefined) {
      //table_label=[]
      if (getDataTable[0].table_receive.codigo!=undefined) {
      	table_column=getDataTable[0].table_receive.codigo
      }
    }

    var setTable = null
    if (typeof tableArr=='string') {
      setTable = tableArr
    }else{
      if (tableArr!=null) {
        setTable = tableSelected
      }
    }
    if (setTable!=null) {
      var data_order_columns = []
      var getDataContent = []
      if (this.state.allDataTrzaTwo[setTable]!=undefined) {
	      var data_array_first = Object.values(this.state.allDataTrzaTwo[setTable].data_array)
	      var data_array_all = Object.values(this.state.allDataTrzaTwo[setTable].data_array_full)
	      if (data_array_all.length!=0) {
	        if (table_column=='none') {
	          getDataContent = this.dataReducer([...data_array_first,...this.disassembleArrayTwo(data_array_all)].filter((a) => token_id.indexOf(a.token_id)!=-1 ));
	        }else{
	          getDataContent = this.dataReducer([...data_array_first,...this.disassembleArrayTwo(data_array_all)].filter((a) => a[table_column].indexOf(num_lot)!=-1 && token_id.indexOf(a.token_id)!=-1  ));
	        }
	      }else{
	        if (table_column=='none') {
	          getDataContent = this.dataReducer([...data_array_first].filter((a) => token_id.indexOf(a.token_id)!=-1));
	        }else{
	          getDataContent = this.dataReducer([...data_array_first].filter((a) => a[table_column].indexOf(num_lot)!=-1 && token_id.indexOf(a.token_id)!=-1  ));
	        }
	      }
      }

	// console.log(getMaxLeng)
	var release_data = []
	var title_data = []
	var set_column_cell_label = []
	var set_column_cell_title = []
	getDataContent.map((at,bt)=>{
		if (this.state.allDataTrzaTwo[setTable]!=undefined) {			
      	 table_label.push({cell:this.convertToTitle(set_column_cell_label.length+2)+'-label',value:getDataTable[0].data_details_select.filter((a)=> a.value.indexOf(tableSelected)!=-1)[0].label+' '+(parseInt(bt)+1)})
	    var getDataLabel = this.state.allDataTrzaTwo[setTable].divider_title_select.split('|').map((a,b)=>{

		    	set_column_cell_title.push(this.convertToTitle(set_column_cell_title.length+1))

	    		var dataCell = { cell: this.convertToTitle(set_column_cell_title.length+1)+'-data', value:at[a.split('*')[0]] }

	    		if (at[a.split('*')[0]]!=undefined) {
	      			title_data.push({ cell: this.convertToTitle(set_column_cell_title.length+1)+'-title', value:a.split('*')[2] });
	    		}else{
	    			title_data.push({ cell: this.convertToTitle(set_column_cell_title.length+1)+'-title', value:"No data" });
	      			dataCell = { cell: this.convertToTitle(set_column_cell_title.length+1)+'-data', value:"--" }
	    		}


	      		return dataCell
          	//return at[a.split('*')[0]];
	      })
      		release_data.push(...getDataLabel);
			for (var il = 0; il < (this.state.getMaxLeng-getDataLabel.length); il++) {
				//set_column_cell_title.push('')
				set_column_cell_title.push('')
		  		title_data.push('');
		      	release_data.push('');
			}
			for (var ilTwo = 0; ilTwo < this.state.getMaxLeng; ilTwo++) {
				set_column_cell_label.push('')
			}
			for (var ilThree = 0; ilThree < this.state.getMaxLeng-1; ilThree++) {
		      	table_label.push('');
			}
		}
  	 })

return {
	   	token:token_id_two,
	   	table:setTable,
	   	data:[table_label,title_data,release_data]
	   }
    }
  }
	render(){
  return (
    <>
      <div className="main-content pt-1" style={{backgroundColor: 'white'}}>
          	<div style={{margin: 'auto'}}>
              <Card style={{padding: '5px',borderRadius: '5px'}}>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                  <h5 className="mb-0" style={{color:'#ffffff'}}>Trazabilidad Frutas y Vegetales KDEs</h5>
                  <Col md={1}>
             	  	<GuideMessage section="fuits_vegetable" section_two="Trace" section_table_form="null" />
                  </Col>
                </CardHeader>
                <CardBody>
                <Row>
                	<Col md={11}>
                	<Row>
                		{/*<h1 style={{margin:0}}>{this.state.code_traza}</h1>*/}
                		<p style={{margin:0, paddingLeft: '8px',fontSize: '13px',fontWeight: 600}}>{(this.state.data_user==null)?(null):(' '+this.state.data_user.name+', '+this.state.data_user.email)}<br /> {(this.state.data_user==null)?(null):(' '+this.state.data_user.pais)}</p>
                	</Row>
                	</Col>
                	<Col md={1} style={{padding:0}}>
                	{(this.state.data_user==null)?(null):(<img alt={String(this.state.data_user.pais).toLocaleLowerCase()+" icon"} srcset={"https://img.icons8.com/office/2x/"+String(this.state.data_user.pais).toLocaleLowerCase()+".png 2x"} />)}
                	</Col>
                </Row>
                </CardBody>
                <CardFooter style={{backgroundColor: "rgba(0, 0, 0, .03)"}}>
                	<Row>
                		<Col md={2}><input type="radio" name="select_type" checked={this.state.change_complent==0} onClick={()=>{this.setState({change_complent:0})}}/><small class="text-muted"> Diagrama de flujo </small></Col>
                		<Col md={2}><input type="radio" name="select_type" checked={this.state.change_complent==1} onClick={()=>{this.setState({change_complent:1})}}/><small class="text-muted"> Tabla de información </small></Col>
                		<Col md={4}>
                			<button className="btn p-0" onClick={()=>{this.exportExcel()}}>Exportar data completa de la trazabilidad</button>
                		</Col>
                	</Row>
                </CardFooter>
              </Card>
            </div>
		<section className="elementor-section elementor-top-section elementor-element elementor-element-ddc63bc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddc63bc" data-element_type="section">
		  <div className="elementor-container elementor-column-gap-default">
		    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70ec96d" data-id="70ec96d" data-element_type="column">
		      <div className="elementor-widget-wrap">
		  	  </div>
		    </div>
		  </div>
	    </section>
    			<section style={{backgroundColor: 'white'}} data-id="9f9c7a0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"><div className="elementor-container elementor-column-gap-extended">
          		<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4d21c55" data-id="4d21c55" data-element_type="column">
      			<div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-1a59632 elementor-widget elementor-widget-eael-adv-tabs" data-id="1a59632" data-element_type="widget" data-widget_type="eael-adv-tabs.default">
        		<div className="elementor-widget-container">
        		<br />
        		{(this.state.allDataTrzaTwo!=null)?(<>
        			{(this.state.change_complent==0)?(<ComplementFlow {...this.state} setDataForExcel={this.setDataForExcel} />):(null)}
        			{(this.state.change_complent==1)?(<ComplementTable {...this.state} setDataForExcel={this.setDataForExcel} />):(null)}
        		</>):(null)}

<div id="eael-advance-tabs-1a59632" className="eael-advance-tabs eael-tabs-horizontal" data-tabid="1a59632">
{/*              	
{(this.state.components==null)?('Tabla contenido.'):(< this.state.components DataRow={this.state} />)}
            	<div className="eael-tabs-nav">
                <ul className="eael-tab-top-icon">
                <Col id="vegefru" aria-selected="false" data-tab="1" role="tab" aria-controls="vegefru-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionTrazaDataProd)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-user" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Datos productor</span>
                </Col>

                <Col id="produccion" aria-selected="false" data-tab="2" role="tab"  aria-controls="produccion-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionHarvestKdes)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-apple-alt" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Datos cosecha</span>
                </Col>

                <Col id="planta" aria-selected="false" data-tab="5" role="tab"  aria-controls="planta-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionPlantaKdes)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-boxes" ></i>
	                </li>
                	<span className="eael-tab-title title-after-icon">Empaque</span>
                </Col>

                <Col id="logistica" aria-selected="false" data-tab="6" role="tab"  aria-controls="logistica-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionImportKdes)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-network-wired" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Importador</span>
                </Col>

                <Col id="logistica" aria-selected="false" data-tab="6" role="tab"  aria-controls="logistica-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionDistKdes)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-network-wired" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Distribuidor</span>
                </Col>

                <Col id="comercializacion" aria-selected="false" data-tab="8" role="tab"  aria-controls="comercializacion-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionDetailKdes)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-handshake" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Detallista</span>
                </Col>

                <Col id="documentos" aria-selected="false" data-tab="7" role="tab"  aria-controls="documentos-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionDocuments)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-clipboard-check" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Documentos</span>
                </Col>

                </ul>
                </div>
                <hr />
    */}
            
        </div>

    </div>
        </div>
          </div>
    </div>
              </div>
    </section>

    </div>
    </>
  );
 }
}

