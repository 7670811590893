import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeaderProd from "components/Headers/SimpleHeaderProd.js";
import GrainNamaNavBar from "views/pages/components/GrainNamaNavBar.js";
import ModalPLPreparationForm from "./Modal/ModalPLPreparationForm";
import { row_fertilizer_production } from "network/ApiAxios";
import jQuery from 'jquery';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class GNamaPLPreparation extends React.Component {
    state={
    dataFertilizer:[],
  }
async componentDidMount(){
  this.initData()
  jQuery(".documentFormAll").remove()
}

initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");
        var dataFertilizer = await row_fertilizer_production(jsondata._id,gerParam,null,this.props.itemProd.table)
        this.setState({dataFertilizer:dataFertilizer})
}


  render(){

  return (
    <>
{/*      <GrainNamaNavBar getParams={window.location.search}/>
      <SimpleHeaderProd name={this.props.itemProd.name} parentName="Gestión de cultivos" section="namas" section_two="Produccion" section_table_form="production_land_preparation_g" mrvtable="production_land_preparation_g"/>*/}
      <div className="mt-6" id={"insideTable-"+this.props.itemProd.table+"-"+this.props.itemProd.name.replaceAll(' ','_') }>
      {(this.state.dataFertilizer.dataMainContent!=undefined)?(
            <ModalPLPreparationForm {...this.props} sendInfoProps={this.state.dataFertilizer} refreshData={this.initData} setParamsUrl={this.props.location} tableCall={this.props.itemProd.table} titleCall={this.props.itemProd.name} />
        ):(null)}
      </div>
    </>
  );
 }
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    // var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
    //console.log(this.context.valueGlobal)
    this.setInfoInit()
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('VCF')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission[this.props.itemProd.permission_column]==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  // {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <GNamaPLPreparation {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

//export default Elements;
