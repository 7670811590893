import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  InputGroup
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_transport_logistics_for_valid, fridge_create_task, fridge_create_fridge, fridge_create_received, set_data_fridge_task ,data_fridge_task_received, deleteFridgeFunct,deleteFridgeFunctTask, data_trazabilidad_meat, row_traza_lote } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import { CountrysData } from "network/DataCountry";
import { stateData } from "network/DataState";
import config from "config";

 class ModalFridge extends React.Component {
      state={
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    data_lote:[],
    dsableBtn:false,
    dsableBtnTrans:false,
    dsableBtnTask:false,
    data_trnsport:[],
    urlParams:'',
    displayOne:'none',
    displayTwo:'none',
    sendOpenModalTask:false,
    urlLoteCode:'0',
    dataStateList:[]
  }
    async componentDidMount(){
        
  }
      setDataCamposEmpty = ()=>{

      }
          setDataCampos = (data,dataTrazabilidadAnimal,dataLote,dataTransport,getParamLote)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
     if (data.fridge!=undefined) {
        this.setState({
                fridge_id:(data.fridge.id=="null")?(null):(data.fridge.id),
                nombre_frigorifico:(data.fridge.nombre_frigorifico=="null")?(null):(data.fridge.nombre_frigorifico),
                registro_numero:(data.fridge.registro_numero=="null")?(null):(data.fridge.registro_numero),
                pais:(data.fridge.pais=="null")?(dataTrazabilidadAnimal.pais_origen):(data.fridge.pais),
                ciudad:(data.fridge.ciudad=="null")?(dataTrazabilidadAnimal.ciudad):(data.fridge.ciudad),
                estado:( data.fridge.estado=="null")?(dataTrazabilidadAnimal.provincia):( data.fridge.estado),
                georeferencia:(data.fridge.georeferencia=="null")?(dataTrazabilidadAnimal.georeferencia):(data.fridge.georeferencia),
                informacion_adicional_fridge:(data.fridge.informacion_adicional=="null")?(null):(data.fridge.informacion_adicional),
                file_document_fridge:(data.fridge.archivo=="null")?(null):(data.fridge.archivo),
        })
      }else{
              this.setState({
                fridge_id:'noind',
                nombre_frigorifico:'',
                registro_numero:'',
                pais:dataTrazabilidadAnimal.pais_origen,
                ciudad:dataTrazabilidadAnimal.ciudad,
                estado:dataTrazabilidadAnimal.provincia,
                georeferencia:dataTrazabilidadAnimal.georeferencia,
                informacion_adicional_fridge:'',
                file_document_fridge:undefined,
            })

      }
      if (dataTrazabilidadAnimal.provincia!=null) {
        var thisData = dataTrazabilidadAnimal.provincia.split('-')
        if (thisData.length > 1) {
         this.changeState(thisData[1])
        } 
      }
      if (data.received!=undefined) {
        this.setState({
                data_lote:dataLote.trazaDataLote,
                data_trnsport:dataTransport.dataLogisticsTransport,
                fridge_received_id:(data.received.id=="null")?(null):(data.received.id),
                nombre_finca:dataTrazabilidadAnimal.nombre_finca,
                lote_numero_received:(data.received.lote_numero=="null")?(null):(data.received.lote_numero),
                guia_numero:(data.received.guia_numero=="null")?(null):(data.received.guia_numero),
                precinto_numero:(data.received.precinto_numero=="null")?(null):(data.received.precinto_numero),
                cert_inspeccion_nro:(data.received.cert_inspeccion_nro=="null")?(null):(data.received.cert_inspeccion_nro),
                fecha_recibido:(data.received.fecha_recibido=="null")?(completeDate):(data.received.fecha_recibido),
                hora_recibido:(data.received.hora_recibido=="null")?(null):(data.received.hora_recibido),
                cantidad_animales_received:(data.received.cantidad_animales=="null")?(null):(data.received.cantidad_animales),
                peso_total:(data.received.peso_total=="null")?(null):(data.received.peso_total),
                peso_unidad:(data.received.peso_unidad=="null")?(null):(data.received.peso_unidad),
                lavado_desinfeccion:(data.received.lavado_desinfeccion=="null")?(null):(data.received.lavado_desinfeccion),
                tratamiento_aplicado_received:(data.received.tratamiento_aplicado=="null")?(null):(data.received.tratamiento_aplicado),
                informacion_adicional_received:(data.received.informacion_adicional=="null")?(null):(data.received.informacion_adicional),
                file_document_received:(data.received.archivo=="null")?(null):(data.received.archivo),
        })
      if (data.received.lote_numero=="null" || data.received.lote_numero==null || data.received.lote_numero=="0") {
        this.setState({cantidad_animales_received:data.received.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales_received:data.received.cantidad_animales,
          dsableBtn:true
        })
      }
      if (data.received.guia_numero=="null" || data.received.guia_numero==null || data.received.guia_numero=="0") {
                this.setState({precinto_numero:data.received.precinto_numero})
      }else{
                this.setState({
                  precinto_numero:data.received.precinto_numero,
                  dsableBtnTrans:true
                })
      }
      }else{
            this.setState({
                data_lote:dataLote.trazaDataLote,
                data_trnsport:dataTransport.dataLogisticsTransport,
                fridge_received_id:'noind',
                nombre_finca:dataTrazabilidadAnimal.nombre_finca,
                lote_numero_received:'',
                guia_numero:'',
                precinto_numero:'',
                cert_inspeccion_nro:'',
                fecha_recibido:completeDate,
                hora_recibido:timeString,
                cantidad_animales_received:'',
                peso_total:'',
                peso_unidad:'',
                lavado_desinfeccion:'',
                tratamiento_aplicado_received:'Agua',
                informacion_adicional_received:'',
                file_document_received:undefined,
            })

            this.setState({
                fecha_carga:completeDate,
            })
            this.onChangeLoteValue(getParamLote)
      }


    }
      setDataCamposTask = (data,dataLote,getParamLote)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1]; 
            if (data.task!=undefined) {
        this.setState({
                data_lote:dataLote.trazaDataLote,
                fridge_task_id:(data.task.id=="null")?(null):(data.task.id),
                lote_numero_task:(data.task.lote_numero=="null")?(null):(data.task.lote_numero),
                fecha_faena:(data.task.fecha_faena=="null")?(completeDate):(data.task.fecha_faena),
                hora_faena:(data.task.hora_faena=="null")?(null):(data.task.hora_faena),
                hora_final:(data.task.hora_final=="null")?(null):(data.task.hora_final),
                peso_pie:(data.task.peso_pie=="null")?(null):(data.task.peso_pie),
                peso_unidad_pie:(data.task.peso_unidad_pie=="null")?(null):(data.task.peso_unidad_pie),
                peso_canales:(data.task.peso_canales=="null")?(null):(data.task.peso_canales),
                peso_unidad_canales:(data.task.peso_unidad_canales=="null")?(null):(data.task.peso_unidad_canales),
                cantidad_animales_task:(data.task.cantidad_animales=="null")?(null):(data.task.cantidad_animales),
                lavado_faena:(data.task.lavado_faena=="null")?(null):(data.task.lavado_faena),
                tratamiento_aplicado_task:(data.task.tratamiento_aplicado=="null")?(null):(data.task.tratamiento_aplicado),
                informacion_adicional_task:(data.task.informacion_adicional=="null")?(null):(data.task.informacion_adicional),
                file_document_task:(data.task.file_document=="null")?(null):(data.task.file_document),
        })
      if (data.task.lote_numero=="null" || data.task.lote_numero==null || data.task.lote_numero=="0") {
        this.setState({cantidad_animales_task:data.task.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales_task:data.task.cantidad_animales,
          dsableBtnTask:true
        })
      }
      }else{
            this.setState({
                data_lote:dataLote.trazaDataLote,
                fridge_task_id:'noind',
                lote_numero_task:'0',
                fecha_faena:completeDate,
                hora_faena:timeString,
                hora_final:timeString,
                peso_pie:'',
                peso_unidad_pie:'',
                peso_canales:'',
                peso_unidad_canales:'',
                cantidad_animales_task:'',
                lavado_faena:'',
                tratamiento_aplicado_task:'Agua',
                informacion_adicional_task:'',
                file_document_task:undefined,
            })
            this.onChangeLoteValueTask(getParamLote)
      }
        }
    changeState = async (data) => {
      // if (typeof data == 'string') {
      // }else{
      // var setDataStateOptionTwo = data.options[data.options.selectedIndex].id
      // var dataState = await stateData.filter((a,b) => a.alpha2Code==setDataStateOptionTwo )
      // this.setState({dataStateList:dataState[0].states})
      // }
      //var setDataStateOptionOne = String(data.replace('Estado','')).trim() 
      var dataState = await stateData.filter((a,b) => a.alpha2Code==data )
      this.setState({dataStateList:dataState[0].states})
      this.setState({code_country:data})


    };
 onChangeLoteValue = async (id) => {
  this.setState({lote_numero_received:id})
  if (id=="0") {
    this.setState({
      dsableBtn:false,
      displayOne:'none'
    })
  }else{
    var result = this.state.data_lote.filter(val => val.lote_numero==id)
    if (result[0]==undefined) {
this.setState({
        cantidad_animales_received:'0',
        dsableBtn:false,
        displayOne:'none'
      })
          }else{
      var cantidad_animales_received = String(result[0].animal_register_id).split('*')
      this.setState({
        cantidad_animales_received:cantidad_animales_received.length,
        dsableBtn:true,
        displayOne:'block'
      })
    }
  }

}
onChangeLoteValueTask = async (id) => {
  this.setState({lote_numero_task:id})
  if (id=="0") {
    this.setState({
      dsableBtnTask:false,
      displayTwo:'none'
    })
  }else{
    var result = this.state.data_lote.filter(val => val.lote_numero==id)
    if (result[0]==undefined) {
this.setState({
      cantidad_animales_task:'0',
      dsableBtnTask:false,
      displayTwo:'none'
    })
              }else{
    var cantidad_animales_task = String(result[0].animal_register_id).split('*')
    this.setState({
      cantidad_animales_task:cantidad_animales_task.length,
      dsableBtnTask:true,
      displayTwo:'block'
    })
    }
  }

}
  render(){
      const saveInfo = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({mess:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
    
if (this.state.guia_numero=='' || this.state.guia_numero==null || this.state.guia_numero=='0') {
      this.setState({btnd:false})
      this.setState({mess:''})
      notify("danger", "¡Guía Número Inexistente!", 'Es necesario que en seleccione una gía o en crear en logistica un Transporte para Frigorifico.');
      return
    }
      if (this.state.nombre_frigorifico=="") {
      this.setState({mess:'El Nombre del Frigorifico es necesario.'})
      this.setState({btnd:false})
      }else{
        const var_request_fridge = await fridge_create_fridge(this.state,jsondata._id,gerParam);
        const var_request_received = await fridge_create_received(this.state,jsondata._id,var_request_fridge.fridge_id,gerParam);
        //const var_request_task = await fridge_create_task(this.state,jsondata._id,var_request_fridge.fridge_id,gerParam)
        if (var_request_received.message=="exito") {
          this.setState({mess:'Se guardo exitosamente.'})
          this.setState({mess:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          /*if (this.props.setParamsUrl==null) {
            setTimeout(function(){window.location.reload()}, 3000);
          }*/
          this.setState({btnd:false})
            this.props.refreshData()
            this.setState({fridge_id:var_request_fridge.fridge_id})
            this.setState({fridge_received_id:var_request_received.fridge_received_id})
            //this.setState({fridge_task_id:var_request_task.fridge_task_id})
            //setInfoFridge(this.props.sendInfoProps.dataTransportAll,true)
        }else{
          notify("danger", "¡Registrado Fallido!", var_request_received.message);
          this.setState({btnd:false})
        }
      }
  }
       const setInfoFridge = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
          this.setState({urlParams:gerParam})
              var dataTransExportLogisc = await data_fridge_task_received(id_set)
              var dataLote = await row_traza_lote(jsondata._id,gerParam)
              var dataTrazabilidadAnimal = await data_trazabilidad_meat(jsondata._id,gerParam)
              var dataTransport = await row_transport_logistics_for_valid(jsondata._id,gerParam,'Transporte de Campo a Frigorífico')
            this.setDataCampos(dataTransExportLogisc,dataTrazabilidadAnimal,dataLote,dataTransport,getParamLote)
          /*if (dataTransExportLogisc==null) {
            this.setDataCamposEmpty()
          }else{

          }
      }else{
            this.setDataCamposEmpty()
      }*/

          this.setState({
        sendOpenModal:verify,
      })
  }
const deleteFridge = async (id) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (window.confirm("Deseas eliminar este registro?")) {
    var dataReturn = await deleteFridgeFunct(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
            notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
}
       const setInfoFridgeTask = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
          this.setState({urlParams:gerParam})
              var dataTransExportLogisc = await set_data_fridge_task(id_set)
              var dataLote = await row_traza_lote(jsondata._id,gerParam)

            this.setDataCamposTask(dataTransExportLogisc,dataLote,getParamLote)
          /*if (dataTransExportLogisc==null) {
            this.setDataCamposEmpty()
          }else{

          }
      }else{
            this.setDataCamposEmpty()
      }*/

          this.setState({
        sendOpenModalTask:verify,
      })
  }
const deleteFridgeTask = async (id) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");  
    if (window.confirm("Deseas eliminar este registro?")) {
    var dataReturn = await deleteFridgeFunctTask(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
            notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
}
      const saveInfoTask = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({messt:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    

      if (this.state.lote_numero_task=="0") {
      this.setState({messt:'Seleccione el lote.'})
      this.setState({btnd:false})
      }else{
        const var_request_task = await fridge_create_task(this.state,jsondata._id,gerParam)
        if (var_request_task.message=="exito") {
          this.setState({messt:'Se guardo exitosamente.'})
          this.setState({messt:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          /*if (this.props.setParamsUrl==null) {
            setTimeout(function(){window.location.reload()}, 3000);
          }*/
          this.setState({btnd:false})
            this.props.refreshData()
            this.setState({fridge_task_id:var_request_task.fridge_task_id})
            //setInfoFridge(this.props.sendInfoProps.dataTransportAll,true)
        }else{
          notify("danger", "¡Registrado Fallido!", var_request_task.message);
          this.setState({btnd:false})
        }
      }
  }

const onChangeTransportValue = async (id) => {
    this.setState({guia_numero:id})
  console.log(this.state.data_trnsport)
  if (id=="0") {
    this.setState({
      dsableBtnTrans:false
    })
  }else{
    var result = this.state.data_trnsport.filter(val => val.guia_numero==id)
    var precinto = result[0].precinto_numero
    this.setState({
      precinto_numero:precinto,
      dsableBtnTrans:true
    })
  }
}
      const geolocacion = () => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(VerPosicion,noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    function VerPosicion(posicion){
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              setinfolocation(lat,lon,data)
            }

        })
        .catch(err => console.log(err.message)));
    }
        const setinfolocation = (lat,lon,data) => {
              this.setState({loadlocali:null})
              this.setState({pais: String(data.results[0].components.country) })
              this.setState({ciudad:String(data.results[0].components.city)})
              this.changeState(data.results[0].components.country_code.toUpperCase())
              var dataClean = data.results[0].components.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('Estado','').trim()+'-'+data.results[0].components.country_code.toUpperCase()
              this.setState({estado:dataClean})
              this.setState({georeferencia:lat+','+lon+'-'+data.results[0].formatted})
    }

    function noPermitir(argument) {
      this.setState({loadlocali:null})
    // $('.cargadores').hide();
    }
    const changeCountryState = async (value,data) => {
      this.changeState(data.options[data.options.selectedIndex].id)
      this.setState({pais:value})
    };
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    const listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
    const listCountryCode = CountrysData.sort(listaArray)
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>

    <Modal isOpen={this.state.sendOpenModal} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
          <Row>
            <Col md={11}>
              <h3 className="mb-0">Información de Frigorífico</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoFridge(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Nombre del Frigorifico
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({nombre_frigorifico:e.target.value})}}
                    defaultValue={(this.state.nombre_frigorifico=="undefined")?(null):(this.state.nombre_frigorifico)}
                    id="example-date-input"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Registro Numero
                  </label>
                  <Input
                    value={(this.state.registro_numero=="undefined")?(null):(this.state.registro_numero)}
                    onChange={(e)=>{this.setState({registro_numero:e.target.value})}} id="example-date-input" type="text" />
                </FormGroup>
              </Col>
                          <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Georeferencia
                  </label>
                  <Row>
                    <Col md="10">
                    <Input
                      value={this.state.georeferencia}
                      id="example3cols2Input"
                      className="ubicacionex"
                      placeholder="Escribe la referencia"
                      type="textarea"
                      onChange={(e) => this.setState({georeferencia:e.target.value})}
                    />
                      {this.state.loadlocali}
                    </Col>  
                    <Col md="2">
                    <a href="javascript:void(0)" style={{color: '#002a5c'}} onClick={()=> {geolocacion()}}>
                      <span style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                    </a>
                    </Col>  
                  </Row>
                  </FormGroup>
              </Col> 
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      País
                                    </label>
                                    <Input 
                                    value={this.state.pais} 
                                    id="exampleFormControlSelect1" 
                                    type="select" 
                                    onChange={(e) => changeCountryState(e.target.value,e.target) }>
                                      <option value="0">Seleccione...</option>
                                      {  listCountryCode.map((value,index)=>{
                                         return <option id={value.Code} value={value.Name} key={index}>{value.Name}</option>
                                        })
                                      }
            
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      Estado
                                    </label>
                                    <Input 
                                    value={this.state.estado} 
                                    id="exampleFormControlSelect1" 
                                    type="select" 
                                    onChange={(e) => { this.setState({estado:e.target.value}) } }>
                                      <option value="0">Seleccione...</option>
                                      {  this.state.dataStateList.map((value,index)=>{
                                         return <option value={value+'-'+this.state.code_country} key={index}>{value}</option>
                                        })
                                      }
            
                                    </Input>
                                  </FormGroup>
                                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Ciudad
                  </label>
                  <Input
                    value={(this.state.ciudad=="undefined")?(null):(this.state.ciudad)}
                    onChange={(e)=>{this.setState({ciudad:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe una ciudad"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <Input
                    value={(this.state.informacion_adicional_fridge=="undefined")?(null):(this.state.informacion_adicional_fridge)}
                    onChange={(e)=>{this.setState({informacion_adicional_fridge:e.target.value})}}
                    id="exampleFormControlTextarea1"
                    rows="1"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      onChange={(e)=>{this.setState({file_document_fridge:e.target.files[0]})}}
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                {(this.state.file_document_fridge==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_fridge}>{String(this.state.file_document_fridge).split('_').pop()}</a>)}

              </Col>
            </Row>
          </CardBody>
        </Card>

<Card>
          <CardHeader>
          <Row>
            <Col md={12}>
              <h3 className="mb-0">Información del Producto Recibido</h3>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre de la finca
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({nombre_finca:e.target.value})}}
                    disabled
                    value={this.state.nombre_finca}
                      id="example3cols2Input"
                      placeholder="Escribe una Finca"
                      type="text"
                    />
                  </FormGroup>
                </Col>
<Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Número
                    </label>
                    <Input
                    onChange={(e)=>{this.onChangeLoteValue(e.target.value)}}
                    value={this.state.lote_numero_received} id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      <option value={this.state.urlLoteCode}>{this.state.urlLoteCode}</option>
                    </Input>
                    <a style={{display:this.state.displayOne}} href={"/admin/meats/job-lote?code="+this.state.urlParams+"&lote="+this.state.urlLoteCode} target="_blank">Ver el lote</a>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Guía Numero
                    </label>
                    <Input
                    onChange={(e)=>{onChangeTransportValue(e.target.value)}}
                    value={this.state.guia_numero} 
                    id="exampleFormControlSelect1" 
                    type="select">
                      <option value="0">Seleccione...</option>
                      {this.state.data_trnsport.map((value,index)=>{
                        return (<option value={value.guia_numero}>{value.guia_numero}</option>)
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Precinto Numero
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({precinto_numero:e.target.value})}}
                    value={this.state.precinto_numero}
                    disabled={this.state.dsableBtnTrans}
                      id="example3cols2Input"
                      placeholder="Escribe el precinto"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cert. Inspeccion Nro
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({cert_inspeccion_nro:e.target.value})}}
                    value={this.state.cert_inspeccion_nro}
                      id="example3cols2Input"
                      placeholder="Escribe un numero de certificado"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Recibido
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_recibido:e.target.value})}}
                    defaultValue={(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Recibido
                  </label>
                  <Input
                    value={(this.state.hora_recibido=="undefined")?(null):(this.state.hora_recibido)}
                    onChange={(e)=>{this.setState({hora_recibido:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({cantidad_animales_received:e.target.value})}}
                    value={this.state.cantidad_animales_received}
                    disabled={this.state.dsableBtn}
                      id="example3cols2Input"
                      placeholder="Escribe la cantidad de animales"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
{/*                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Peso Total Est.
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({peso_total:e.target.value})}}
                    value={this.state.peso_total}
                      id="example3cols2Input"
                      placeholder="Escribe el peso total"
                      type="text"
                    />
                  </FormGroup>*/}
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Peso Total Est.
                                    </label>
                              <InputGroup>
                                    <Input
                                      autoComplete='none'
                                        onChange={(e)=>{this.setState({peso_total:e.target.value})}}
                                        value={this.state.peso_total}
                                        id="example3cols2Input"
                                        placeholder="Escribe el peso inicial"
                                        type="number"
                                      />
                                    <Input
                                      value={this.state.peso_unidad}
                                      onChange={(e)=>{ this.setState({peso_unidad:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="g">g</option>
                                      <option value="kg">Kg</option>
                                      <option value="libras">lb</option>
                                      <option value="tonelada">Tonelada</option>
                                    </Input>
                                  </InputGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                 Lavado y desinfeccion
                  </label>
                  <Input
                    value={(this.state.lavado_desinfeccion=="undefined")?(null):(this.state.lavado_desinfeccion)}
                    onChange={(e)=>{this.setState({lavado_desinfeccion:e.target.value})}} id="exampleFormControlSelect1" type="select">
                    <option value="0">No Aplica</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Tratamiento aplicado
                  </label>
                  {/*<Input
                    value={(this.state.tratamiento_aplicado_received=="undefined")?(null):(this.state.tratamiento_aplicado_received)}
                    onChange={(e)=>{this.setState({tratamiento_aplicado_received:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un tratamiento"
                    type="text"
                  />*/}
                  <Input
                    value={(this.state.tratamiento_aplicado_received=="undefined")?(null):(this.state.tratamiento_aplicado_received)}
                    onChange={(e)=>{this.setState({tratamiento_aplicado_received:e.target.value})}} id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="Agua">Agua</option>
                    <option value="No">No</option>
                  </Input>
                </FormGroup>
              </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({informacion_adicional_received:e.target.value})}}
                    value={this.state.informacion_adicional_received}
                      id="exampleFormControlTextarea1"
                      rows="1"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Subir Documento
                  </label>
                  <Form>
                    <div className="custom-file">
                      <input
                      onChange={(e)=>{this.setState({file_document_received:e.target.files[0]})}}
                        className="custom-file-input"
                        id="customFileLang"
                        lang="es"
                        type="file"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileLang"
                      >
                        Elegir archivo
                      </label>
                    </div>
                  </Form>
                {(this.state.file_document_received==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_received}>{String(this.state.file_document_received).split('_').pop()}</a>)}

                </Col>
              </Row>
                   {this.state.mess ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfo}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
            </CardBody>
          </Card>
       </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoFridge(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>



        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Ingresar Datos Frigorifico
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoFridge(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataFridge.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.nombre_frigorifico=="null") ? ("Sin nombre") : (value.nombre_frigorifico)}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoFridge(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteFridge(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>

    <Modal isOpen={this.state.sendOpenModalTask} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
          <Row>
            <Col md={11}>
              <h3 className="mb-0">Información de la Faena</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoFridgeTask(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Número
                    </label>
                    <Input
                    onChange={(e)=>{this.onChangeLoteValueTask(e.target.value)}}
                    value={this.state.lote_numero_task} id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      <option value={this.state.urlLoteCode}>{this.state.urlLoteCode}</option>
                    </Input>
                    <a style={{display:this.state.displayTwo}} href={"/admin/meats/job-lote?code="+this.state.urlParams+"&lote="+this.state.urlLoteCode} target="_blank">Ver el lote</a>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de la Faena
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_faena:e.target.value})}}
                    defaultValue={(this.state.fecha_faena=="undefined")?(null):(this.state.fecha_faena)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de inicio
                  </label>
                  <Input
                    value={(this.state.hora_faena=="undefined")?(null):(this.state.hora_faena)}
                    onChange={(e)=>{this.setState({hora_faena:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
<Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora final
                  </label>
                  <Input
                    value={(this.state.hora_final=="undefined")?(null):(this.state.hora_final)}
                    onChange={(e)=>{this.setState({hora_final:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({cantidad_animales_task:e.target.value})}}
                    value={this.state.cantidad_animales_task}
                    disabled={this.state.dsableBtnTask}
                      id="example3cols2Input"
                      placeholder="Escribe la cantidad de animales"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                 Lavado Pos Faena
                  </label>
                  <Input
                    value={(this.state.lavado_faena=="undefined")?(null):(this.state.lavado_faena)}
                    onChange={(e)=>{this.setState({lavado_faena:e.target.value})}} id="exampleFormControlSelect1" type="select">
                    <option value="0">No Aplica</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </FormGroup>
              </Col>
                <Col md="4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Peso en pie
                                    </label>
                              <InputGroup>
                                    <Input
                                      autoComplete='none'
                                        onChange={(e)=>{this.setState({peso_pie:e.target.value})}}
                                        value={this.state.peso_pie}
                                        id="example3cols2Input"
                                        placeholder="Escribe el peso inicial"
                                        type="number"
                                      />
                                    <Input
                                      value={this.state.peso_unidad_pie}
                                      onChange={(e)=>{ this.setState({peso_unidad_pie:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="g">g</option>
                                      <option value="kg">Kg</option>
                                      <option value="libras">lb</option>
                                      <option value="tonelada">Tonelada</option>
                                    </Input>
                                  </InputGroup>
                </Col>
                <Col md="4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Peso en canales
                                    </label>
                              <InputGroup>
                                    <Input
                                      autoComplete='none'
                                        onChange={(e)=>{this.setState({peso_canales:e.target.value})}}
                                        value={this.state.peso_canales}
                                        id="example3cols2Input"
                                        placeholder="Escribe el peso inicial"
                                        type="number"
                                      />
                                    <Input
                                      value={this.state.peso_unidad_canales}
                                      onChange={(e)=>{ this.setState({peso_unidad_canales:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="g">g</option>
                                      <option value="kg">Kg</option>
                                      <option value="libras">lb</option>
                                      <option value="tonelada">Tonelada</option>
                                    </Input>
                                  </InputGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Tratamiento aplicado
                  </label>
                  {/*<Input
                    value={(this.state.tratamiento_aplicado_task=="undefined")?(null):(this.state.tratamiento_aplicado_task)}
                    onChange={(e)=>{this.setState({tratamiento_aplicado_task:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un tratamiento"
                    type="text"
                  />*/}
                  <Input
                    value={(this.state.tratamiento_aplicado_task=="undefined")?(null):(this.state.tratamiento_aplicado_task)}
                    onChange={(e)=>{this.setState({tratamiento_aplicado_task:e.target.value})}} id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="Agua">Agua</option>
                    <option value="No">No</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <Input
                    value={(this.state.informacion_adicional_task=="undefined")?(null):(this.state.informacion_adicional_task)}
                    onChange={(e)=>{this.setState({informacion_adicional_task:e.target.value})}}
                    id="exampleFormControlTextarea1"
                    rows="1"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      onChange={(e)=>{this.setState({file_document_task:e.target.files[0]})}}
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                {(this.state.file_document_task==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_task}>{String(this.state.file_document_task).split('_').pop()}</a>)}

              </Col>
            </Row>
                   {this.state.messt ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messt}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfoTask}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoFridgeTask(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Información de la Faena
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoFridgeTask(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataFridgeFaena.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.lote_numero=="null") ? ("Sin número de lote") : (value.lote_numero)}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoFridgeTask(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteFridgeTask(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>

    </>
  );
 }
}

 export default ModalFridge;
