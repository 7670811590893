import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production_nama_g, data_trazabilidad, main_content, data_fertilizer_production, fertilizer_production, delete_prodction_nama_g, delete_file_prod } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {getContent, PutFormContentPro, PutFormContentProTable, PutFormContentTableProd, forNumeric, PutFormContentFinal} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";
import ModalTableForFrom from "./ModalTableForForm";
import jQuery from 'jquery';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default class GNamaTable extends React.Component {
  static contextType = UserContext
  state={
    notificationAlertRef: React.createRef(),
    field_params:{},
    field_params_ftable:[],
    field_params_view:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],    
    dataStatesS:0,
    content_render:null,
    get_content:[],
    get_first_info:[],
    get_first_info_column:'',
    btnd:false,
    change_mrv_data:0,
    urlpathname:null,
    sendOpenModal:false,
    set_data_modal:{},
    idTModal:null,
    globalFilter:'',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    visibleRight:false
  }
  async componentDidMount(){
     var getData = await Object.values(this.props.sendInfoProps.dataMainContent)
   // getData.map((a,b)=>{
   //   if (a.include_info==1) {
   //     this.setState({get_first_info:a.name_field, get_first_info_column:a.label_title})
   //   }
   // })
   var getDataColumn = []
   getData.map((a,b)=>{
     if (a.include_info==1) {
      getDataColumn[b]={column:a.name_field,title:a.label_title}
      //getDataColumnTitle[b]=a.label_title
     }
   })
       this.setState({get_first_info:getDataColumn})
   const urlParam = window.location.pathname
   this.setState({urlpathname:urlParam})
    if (this.props.setParamsUrl.state!=null) {
      if (this.props.setParamsUrl.state.verify_mrv=='ON') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvON').click()
        })
      }
      if (this.props.setParamsUrl.state.verify_mrv=='RE') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvRE').click()
        })
      }
      if (this.props.setParamsUrl.state.verify_mrv=='VA') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvVA').click()
        })
      }
    }
      this.initData()
    if (this.props.sendInfoProps.trazaData.length==0) {
      this.initDataTwo(null,true)
      this.setState({visibleRight:true})
    }
  }
  initData = async ()=>{
    this.props.sendInfoProps.trazaData.map((value,index)=>{
                this.initDataTwo(value,true)
              })
  }
  initDataTwo = async (set_data,verify,typeListener)=>{

    var data_select = []
      if (this.props.sendInfoProps.dataMainContent!=null) {
        this.setState({field_content: Object.values(this.props.sendInfoProps.dataMainContent) })
      }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataTrazabilidadvf = set_data
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
            this.cleanData(Object.values(this.props.sendInfoProps.dataMainContent),verify,typeListener)
        }else{
            this.setDataCampos(dataTrazabilidadvf,Object.values(this.props.sendInfoProps.dataMainContent),verify)
        }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {
      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        const full_data = this.state.field_params
        full_data[return_result.field_product]=parseInt(return_result.product_result)
        this.setState({field_params: full_data })
        
      }
        if (prevState.field_params !== this.state.field_params) {
        }
      } 
     setDataCampos = (data,dataMainContent,pass_select)=>{
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
       //delete data.id
       delete data.count_table
       var field_params_title={}
       var field_params_required={}
       var field_params_select={}
       var field_params_params={}
       var field_params_detail_mrv={}
       var new_content=[]
       dataMainContent.map((a,b)=>{
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field

         if (data[a.name_field]==null) {
          field_params_params[a.name_field]=''
          field_params_detail_mrv[a.name_field]=''
         }else{
          field_params_params[a.name_field]=data[a.name_field]
          field_params_detail_mrv[a.name_field]=""
            if (a.type_field=='date') {
              field_params_params[a.name_field]=data[a.name_field]
            }
            if (a.type_field=='time') {
              field_params_params[a.name_field]=data[a.name_field]
            }
          if (a.type_field=='file') {
            if (typeof data[a.name_field]=='string') {
             if (data[a.name_field].split('|').length>0) {
              var multi_fil = []
               data[a.name_field].split('|').map((a,b)=>{
                 multi_fil[b] = {name:a,filed:null}
               })
                var item = []
                item = [...multi_fil]
              field_params_params[a.name_field]=item
             }
            }
          }
          if (a.include_info==1) {
            field_params_detail_mrv[a.name_field]=data[a.name_field]
          }
         }
          if (a.type_field=='date') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=completeDate
            }
          }
          if (a.type_field=='time') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=timeString
            }
          }
          field_params_params['created_at']=data['created_at']
         if (a.type_field=='select') {
            var get_data_select = this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
       })
       if (pass_select) {
            var getFullOption = this.state.optionsSelect
            getFullOption[data?.id]=field_params_select
         this.setState({optionsSelect: getFullOption})
         this.setState({selectForFilter: getFullOption})
       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
       var thisState= this
      jQuery(document).ready(function(e) {
          if (jQuery(e.target).hasClass('view_detail_panel')) {
            thisState.setState({idT:'null'})
            thisState.setState({idTP:data?.id})
            thisState.setState({change_mrv_data:1})
            thisState.setState({field_params_view:field_params_params })
            var getFull = thisState.state.field_params
            getFull[data?.id]=field_params_detail_mrv
            thisState.setState({field_params: getFull,field_params_view:[] })

            dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=1}})
            var listItem = jQuery(e.target);
            var getIndex = jQuery( ".view_detail_panel" ).index( listItem )
            thisState.setState({putPositionBtn:getIndex})
          }else{
            thisState.setState({change_mrv_data:0})
            if (jQuery(e.target).hasClass('newRegister')) {
              thisState.setState({idT:'null'})
              thisState.setState({field_params_view:[] })
            }else{
              if (!jQuery(e.target).hasClass('upload_files')) {
                thisState.setState({idT:data?.id})
                var getFull = thisState.state.field_params
                getFull[data?.id]=field_params_params
                thisState.setState({field_params: getFull,field_params_view:[] })
                thisState.setState({field_params_view:[] })
              }
            }
             dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=0}})
          }
      });
         this.setState({field_params_title: field_params_title})
         this.setState({field_params_required: field_params_required})
     }
     cleanData = (dataMainContent,pass_select,typeListener) =>{
           this.setState({idT:'null'})
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
           var field_params_title={}
           var field_params_required={}
           var field_params_select={}
           var field_params_params={}
       dataMainContent.map((a,b)=>{
         field_params_params[a.name_field]=''
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field
         if (pass_select) {
           if (a.type_field=='date') {
            field_params_params[a.name_field]=completeDate
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=timeString
           }
         }else{
           if (a.type_field=='date') {
            field_params_params[a.name_field]=null
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=null
           }
         }
          field_params_params['created_at']=completeDate+' '+timeString
         if (a.type_field=='select') {
            var get_data_select =this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')

            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
       })
       if (pass_select) {
          var get_arr_options = Object.keys(this.state.optionsSelect)
          var get_sec_arr_opt = get_arr_options[get_arr_options.length-1]
          if (get_sec_arr_opt==undefined) {
            get_sec_arr_opt=-1
          }else{          
            if (get_sec_arr_opt.indexOf('null_')!=-1) {
              get_sec_arr_opt=get_sec_arr_opt.split('null_')[1]
            }
          }
          var getFullOption = this.state.optionsSelect
          getFullOption['null_'+(parseInt(get_sec_arr_opt)+1)]=field_params_select

         this.setState({optionsSelect: getFullOption})
         this.setState({selectForFilter: getFullOption})
       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
          var get_arr_element = Object.keys(this.state.field_params)
          var get_sec_arr = get_arr_element[get_arr_element.length-1]
          if (get_sec_arr==undefined) {
            get_sec_arr=-1
          }else{          
            if (get_sec_arr.indexOf('null_')!=-1) {
              get_sec_arr=get_sec_arr.split('null_')[1]
            }
          }
          var getFull = this.state.field_params
          getFull['null_'+(parseInt(get_sec_arr)+1)]=field_params_params
          this.setState({field_params: getFull })
         this.setState({field_params_title: field_params_title })
         this.setState({field_params_required: field_params_required })
         if (typeListener!='putCounter') {
          this.modalInfoProp('null_'+(parseInt(get_sec_arr)+1),true,'data_table')
         }
     }

  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async (dataId) =>{

      this.setState({btnd:true})
      this.setState({mess:['Enviando...']})
      if (this.state.field_params_required==undefined) {
        this.setState({btnd:false})
        return
      }
    
    var field_data = Object.entries(this.state.field_params[dataId])
     var field_data_remove=[]
     var counter= 0
     field_data.map((c,i)=>{
       if (c[0]!='created_at' && c[0]!='id') {
         field_data_remove[counter]=c
         counter++
       }
     })
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {
          if (field_data[b][0]==a[0]) {
           if (a[1]==1 && (field_data[b][1]=='' || field_data[b][1]==null)) {
            counter++
            var get_t = field_data_name.filter(af=>af[0]==a[0])
            if (get_t[0]!=null) {
              data_mess[counter] = 'El campo '+get_t[0][1]+' es requerido';
            }else{
              data_mess[counter] = 'El campo '+a[0]+' es requerido';
            }
           }
          }
        }
    })
    if (data_mess.length>0) {
      this.context.setToastMess('error', 'Validación', data_mess.filter(Boolean)[0])
      //this.setState({mess: data_mess.filter(Boolean) })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

       var gerParam = new URLSearchParams(window.location.search).get("code");
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
         this.setState({btnd:true})
          if (dataId.indexOf('null')!=-1) {
            var idRegister = 'null'
          }else{
            var idRegister = dataId
          }
          jQuery('.putLoadingIcons_'+dataId).html('<span style="font-size: 20px;" class="fas fa-spinner checkedVerifyLoading loadingIcon"></span>')
          const var_vf = await create_production_nama_g(jsondata._id,field_data_remove.sort(),field_data_name.sort(),gerParam,this.props.tableCall,idRegister,data_for_file,this.props.titleCall);
          if (var_vf==null) {
            jQuery('.putLoadingIcons_'+dataId).html('')
            this.setState({btnd:false})
            return
          }
              if (var_vf.message=="exito") {
                jQuery('.putLoadingIcons_'+dataId).html('<span style="font-size: 20px;" class="fas fa-check-circle checkedLoadingVerifySuccess checkedSuccessIcon"></span>')
                if(jQuery('[class*="checkedVerifyLoading"]').length==0){
                  this.context.setToastMess("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.')
                  //this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
                  this.setState({mess:''})
                  this.setState({btnd:false})
                  this.setState({field_params: [] })
                  this.setState({optionsSelect: []})
                  this.setState({selectForFilter: []})
                  await this.props.refreshData()
                  this.initData()
                }
               if (this.state.change_mrv_data==1) {
                jQuery(jQuery('.btn_change_state')[this.state.putPositionBtn]).click()
               }
              }else{
                jQuery('.putLoadingIcons_'+dataId).html('')
              }
  }
  saveInfoAll=()=>{
    var getDataKeys = Object.keys(this.state.field_params)
    if (getDataKeys==0) {
      this.context.setToastMess("danger", "Sin registro", 'Por favor ingrese un registro nuevo')
      //this.notify("danger", "Sin registro", 'Por favor ingrese un registro nuevo');
    }else{
      Object.keys(this.state.field_params).map((a,b)=>{
        this.saveInfo(a)
      })
    }
  }
  setForBack=(data,id)=>{
    //console.log(data,id)
      var full_data_one = this.state.field_params
    if (id=='null') {
      full_data_one[this.state.idTModal]=data
    }else{
      full_data_one[id]=data
    }
    this.setState({field_params:full_data_one,idTModal:null})
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
 geolocacion = (dataId,options,indx_position) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(dataId,e,options,indx_position)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (dataId,posicion,optcon,indx_position)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    this.setFieldValue(dataId,lat+','+lon+'-(Información restante)',optcon.id,optcon)
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon,dataId,indx_position)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon,idRow) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                  var full_data_one = this.state.field_params

                  var itemsData = full_data_one[idRow]
                  itemsData[val.name_field]=String(data.results[0].components.country)
                  full_data_one[idRow]=itemsData

                  this.setState({field_params: full_data_one})
                  }
                  if (get_csc=='vge') {
                    var full_data_two = this.state.field_params
                    var itemsDataTwo = full_data_two[idRow]
                    itemsDataTwo[val.name_field]=String(data.results[0].components.state)
                    full_data_two[idRow]=itemsDataTwo
                    this.setState({field_params: full_data_two})
                  }
                  if (get_csc=='vgc') {
                    var full_data_three = this.state.field_params
                    var itemsDataThree = full_data_three[idRow]
                    itemsDataThree[val.name_field]=String(data.results[0].components.city)
                    full_data_three[idRow]=itemsDataThree
                    this.setState({field_params: full_data_three})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(idRow,String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(idRow,String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(idRow,lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }
    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    }
     contentForSelect = (data,field_name,isrequerid,list_default)=>{
      
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (idRow,value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
                  const full_data_one = this.state.field_params
                  var itemsData = full_data_one[idRow]
                  itemsData[field]=value
                  full_data_one[idRow]=itemsData

                    this.setState({field_params: full_data_one})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            var full_data_two = this.state.field_params
            full_data_two[field]=value_for_states[0].value
            this.setState({field_params: full_data_two})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }

     setFieldValue = (idRow,value, field, isrequired) =>{
      const full_data = this.state.field_params
      var itemsData = full_data[idRow]
      itemsData[field]=value
      full_data[idRow]=itemsData
       this.setState({field_params: full_data })
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     setFieldValueFiles = (idRow,value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       var itemsData = item[idRow]
       itemsData[field] = [...itemsData[field],...multi_fil]
       item[idRow]=itemsData
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod(this.state.idT,this.props.tableCall,field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
            this.context.setToastMess("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.')
            //this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
             var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
          }
         }
      this.setState({field_params: item })
       }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          var dataSelect = {}
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        dataSelect = this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default)
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
          return dataSelect
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      var dataSelect = {}
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          dataSelect = this.contentForSelect(CodigoAranF,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          dataSelect = this.contentForSelect(CodigoAranM,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      //this.setState({selectForFilter: this.state.optionsSelect})
      return dataSelect
          //console.log(list_default,value_option,name_field,req_field,for_values,this.state.optionsSelect)
    }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
deleteFer = async (id_set) => {
      //await this.initData(set_val,false)
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = new URLSearchParams(window.location.search).get("code");
    var field_data = Object.entries(this.state.field_params[id_set])
    var field_data_remove=[]
    var counter= -1
    field_data.map((c,i)=>{
      if (c[0]!='created_at') {
        field_data_remove[counter]=c
      }
      counter++
    })
       var field_data_name = Object.entries(this.state.field_params_title)
          // if (id_set.indexOf('null')!=-1) {
          //   delete this.state.field_params[id_set]
          //   this.setState({field_params: this.state.field_params })
          // }else{
          // }
          var dataReturn = await delete_prodction_nama_g(id_set,jsondata._id,field_data_remove,field_data_name,gerParam,this.props.tableCall,this.props.titleCall)
               if (dataReturn=="exito") {
               this.setState({mess:'Se eliminó exitosamente.'})
               this.context.setToastMess("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.')
               //this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
               //this.cleanData(Object.values(this.props.sendInfoProps.dataMainContent),false)
               this.setState({field_params: [] })
               this.setState({optionsSelect: []})
               this.setState({selectForFilter: []})
               await this.props.refreshData()
               this.initData()
               //window.location.reload()
             }else{
               this.context.setToastMess("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!')
               //this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
               this.setState({btnd:false})
             }
 }
 insertNewRegister =()=>{
  var date = new Date()
  var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
  var completeDateBefore = completeDateAfter.split('/')
  var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
  var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
  //if (this.state.field_params[this.state.field_params.length-1]!=undefined) {
  // var push_shift = [...this.state.field_params,{id:this.state.field_params[this.state.field_params.length-1].id+1}]
  // }else{
  // }
  var push_shift = [...this.state.field_params,{id:null}]
  this.setState({field_params: push_shift })
 }
     listCountryCode = CountrysData.sort(this.listaArray)
 addRowsNews=(valCount)=>{
    var getData = {}
    Object.entries(this.state.field_params).map((a,b)=>{
      if (a[0].indexOf('null_')==-1) {
        getData[a[0]]=a[1]
      }
    })
    this.setState({field_params:getData})
  if (valCount!='') {
    for (var i = 0; i < parseInt(valCount); i++) {
      this.initDataTwo(null,true,'putCounter')
    }
  }
 }
 modalInfoProp=(idRow,verify,typem)=>{
  const full_data_one = this.state.field_params
  var get_data_params = {}
            if (typem=='data_table') {

              if (idRow.indexOf('null_')!=-1) {
                full_data_one[idRow].id='null'
                get_data_params=full_data_one[idRow]
              }else{
                full_data_one[idRow].id=parseInt(idRow)
                get_data_params=full_data_one[idRow]
              }
              this.setState({idTModal:idRow})
            }else{
              get_data_params=idRow
            }
            //console.log(get_data_params)
           this.setState({set_data_modal:get_data_params})
           this.setState({sendOpenModal:verify})
 }
 copyInfoData=async(idRow)=>{
    var getData = this.state.field_params
    var getDataObj = getData[idRow]
    getData["null_"+idRow]=getDataObj
    this.setState({ field_params: getData })
    
    //this.setForBack(getDataObj,"null_"+idRow)
    
    var getFullOption = this.state.optionsSelect
    var getDataObjOption = getFullOption[idRow]
    getFullOption["null_"+idRow]=getDataObjOption

    this.setState({optionsSelect: getFullOption})
    this.setState({selectForFilter: getFullOption})
    //await this.state.field_params
    this.modalInfoProp("null_"+idRow,true,'data_table')
 }
refreshDataFunc=async()=>{
this.setState({field_params: {} })
this.setState({optionsSelect: []})
this.setState({selectForFilter: []})
await this.props.refreshData()
await this.initData()
}
  render(){
    const header = (
        <div className="flex flex-column flex-md-row md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Gestionar Producción
            </h5>
            {(this.props.sendInfoProps.trazaData.length==0)?(null):(<>
              <span className="block mt-2 md:mt-0 p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText 
                  type="search" 
                  value={this.state.globalFilter}
                  //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                  onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                  placeholder="Buscar..." 
                  />
              </span>
              </>)}


            <Button 
                label="Nueva" 
                icon="pi pi-plus" 
                severity="success" 
                className="btn-fod-green mr-2" 
                onClick={() => {
                  this.initDataTwo(null,true)
                  this.setState({visibleRight:true})
                  //this.initData(null,true)
                }}
                />
        </div>
    );
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
    const accept = () => {
      //this.saveInfo()
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Cancelado', life: 3000 });
    }
    const confirm1 = (data,bool,mess) => {
        confirmDialog({
            message: 'Estas seguro en '+mess+' esta información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept:()=>{
              this.deleteFer(data.id,bool)
            },
            reject
        });
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button 
                    icon="pi pi-pencil" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    tooltip="Editar Información" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                    onClick={()=>{
                      this.setState({visibleRight:true})
                      //this.initData(rowData,true)
                      this.modalInfoProp(String(rowData.id),true,'data_table')
                    }}
                />
                 <Button 
                    icon="pi pi-trash" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    tooltip="Borrar Información" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                    onClick={() => {
                      //console.log('eliminar')
                      //this.deleteFer(rowData,true)
                      confirm1(rowData,true,'eliminar')
                    }}
                />
            </>
        );
    };
    const actionBodyText = (column,rowData) => {
      if (rowData[column]!=null) {
        if (rowData[column].length>20) {
          return `${rowData[column].substring(0, 20)}...`
        }else{
          return rowData[column]
        }
      }else{
        return rowData[column]
      }
    }
  return (
    <>
    <ConfirmDialog />
      <SidebarCustom 
          onVisible={this.state.visibleRight} 
          onHide={() => this.setState({visibleRight:false})}
          onWidth={ 650 }
          position="right" 
          title={this.props.titleCall}>

      <ModalTableForFrom 
        sendOpenModal={this.state.sendOpenModal}
        modalInfoProp={this.modalInfoProp}
        dataMainContent={this.props.sendInfoProps.dataMainContent}
        set_data_modal={this.state.set_data_modal}
        tableCall={this.props.tableCall}
        titleCall={this.props.titleCall}
        refreshDataModal={this.refreshDataFunc}
        trazaData={this.props.sendInfoProps.trazaData}
        setForBackModal={this.setForBack}
        hide={ () => this.setState({visibleRight:false}) } 
        //refreshData={this.initData}
       />
            <br />
            <br />
            <br />
      </SidebarCustom>
{/*       <Row>
        <Card className="mb-1 col-md-12" style={{backgroundColor:'transparent',boxShadow: 'none'}}>
        <div className="p-0" style={{borderRadius: '20px',backgroundColor:'transparent'}}>
        <Row>
          <Col md={3}>
            <Button className="btn-icon ml-3 newRegister" color="traza-blue" type="button" onClick={()=>{ this.initDataTwo(null,true) }}>
            <h3 className="mb-0 newRegister" style={{color:'white'}}>
             Ingresar {this.props.titleCall} +
            </h3>
            </Button>
          </Col>
          <Col md={2}>
                    <Input
                      onChange={(e) => {this.addRowsNews(e.target.value)}}
                      placeholder="Cantidad de registros"
                      type="number"
                    />
          </Col>
          <Col md={7} style={{textAlign: 'end'}}>
            <Button className="btn-icon ml-3 newRegisterSave" style={{border: 'solid 1px'}} type="button" onClick={()=>{ this.saveInfoAll() }}>
             <span style={{fontSize: '20px',color: '#002a5c'}} className="fas fa-save"></span>
            </Button>
          </Col>
        </Row>
          </div>
        </Card>
        </Row>*/}
        <Container className="mt-0" id={"insideTable-"+this.props.tableCall+'-'+this.props.titleCall.replaceAll(' ','_')}>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card card-table">
                    <DataTable
                        value={this.props.sendInfoProps.trazaData}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        <Column field="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>
                        {/*<Column field="created_at" header="Fecha" sortable></Column>*/}
{/*                        <Column field={this.state.get_first_info} header={this.state.get_first_info_column} sortable></Column>*/}
                        {this.state.get_first_info.map((a,b)=>{
                          return (<Column 
                            field={a.column}
                            body={actionBodyText.bind(this,a.column)}
                            header={a.title} 
                            sortable
                            ></Column>)
                        })}
                        <Column body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
      </Container>
    </>
  );
 }
}

