import React, {useState,useEffect,useContext} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup
} from "reactstrap";
import Tooltip from 'react-power-tooltip'
import {UserContext} from "layouts/store";

export function GuideMessage(props) {
	var alldatacontext = useContext(UserContext)
	const [title, setTitle] = useState(null)
	const [description, setDescription] = useState(null)
	const [countRow, setCountRow] = useState(0)
	const [countRowTotal, setCountRowTotal] = useState(0)
	const [showTool, setShowTool] = useState(false)
	var content = null
	var data_user=0
	if (alldatacontext.valueGlobal!=undefined) {
		data_user = alldatacontext.valueGlobal.guide_users
	}
	const getInfoGuide = (prop,nb) =>{
		var data=[]
		if (alldatacontext.valueGuide!=undefined) {
			data=alldatacontext.valueGuide
		}
		var section=prop.section
		var section_two=prop.section_two
		var section_three=prop.section_table_form
		var page_row = countRow
		if (data.length!=0) {
			var get_f_data = data.filter((a,b)=> a.section==section && a.section_two==section_two && String(a.section_table_form)==section_three)
			if (get_f_data[page_row]!=undefined) {
				if (title==null) {
					if (data_user==1) {
						if (get_f_data[page_row].message!=null) {
							setTitle(get_f_data[page_row].title)
							setDescription(get_f_data[page_row].message)
							setCountRowTotal(get_f_data.length)
						}
					}
				}
			}
		}
	}
	const setInfoGuide = (type) =>{
		setTitle(null)
		setDescription(null)
		if (type==0) {
			if (countRow>0) {
				setCountRow(countRow-1)
			}
		}else{
			const countMore = countRow+1
			if (countMore<countRowTotal) {
				setCountRow(countRow+1)
			}
		}
	}
	  const showTooltip =(bol)=>{
	    setShowTool(bol)
	  }
  useEffect(()=> {
    //getInfoGuide(props)
  },[])
	getInfoGuide(props)
	if (description!=null) {
		if (alldatacontext.valueGuide.length!=0) {
	  	 content =<> <Col md={12}>
	                       <div 
	                           style={{ position: 'relative', color:'black' }}
	                           onMouseOver={() => showTooltip(true)} 
	                           onMouseLeave={() => showTooltip(false)}
	                       >
	                       <span style={{ color:'white' }} className="fas fa-exclamation-circle"></span>
	                           <Tooltip 
	                           show={showTool}
	                           arrowAlign="center"
	                           fontFamily="arial"
	                           textBoxWidth="300px"
	                           hoverBackground="white"
	                           >
	                               <span>{title}
	                               <hr/>
	                                 <p style={{textAlign:'justify'}}>{description}</p>
	                               </span>
	                           {(countRowTotal>1)?(<span>
	                                               <Row style={{marginTop: '-20px'}}>
	                                                 <Col md={2}>
	                                                   <button style={{background:'white',border: '0px'}} onClick={()=>{setInfoGuide(0)}}>Back</button>
	                                                 </Col>
	                                                 <Col md={2}>
	                                                   <button style={{background:'white',border: '0px'}} onClick={()=>{setInfoGuide(1)}}>Next</button>
	                                                 </Col>
	                                               </Row>
	                                             </span>):(<span></span>)}
	                           </Tooltip>
	                       </div>
	                     </Col></>;
		}
	}
	return(content)
}