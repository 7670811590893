import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { create_info_guide, get_info_guide, update_info_guide, row_traza_lote,row_transport_logistics } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import DetailSection from "./FormsGuide/DetailTransportScreen";
import DetailOrigin from "./FormsGuide/DetailOriginScreen";
import DetailDiscrimination from "./FormsGuide/DetailDiscriminationScreen";
import DetailDiscriminationOvino from "./FormsGuide/DetailDiscriminationOvinoScreen";
import DetailControl from "./FormsGuide/DetailControl";
import DetailSignature from "./FormsGuide/DetailSignatureScreen";
import FirstFooter from "./FormsGuide/FirstFooterScreen";
import DetailTransport from "./FormsGuide/DetailTransportFormScreen";
import DetailAutorization from "./FormsGuide/DetailAutorizationFormScreen";
import DetailObservation from "./FormsGuide/DetailObservationScreen";
import DetailObservationTrans from "./FormsGuide/DetailObservationTransScreen";
import DetailInspect from "./FormsGuide/DetailInspectScreen";
import DetailDestine from "./FormsGuide/DetailDestineScreen";
import DetailRecive from "./FormsGuide/DetailReciveScreen";
import FirstFooterTwo from "./FormsGuide/FirstFooterTwoScreen";
import axios from 'axios';

import ModalListconstancy from "./FormsGuide/ModalListconstancyScreen";
import ModalListconstancyTwo from "./FormsGuide/ModalListconstancyTwoScreen";
import jQuery from 'jquery';
import "./StyleCss.css";
 class ModalStartLogistics extends React.Component {
  refCreate={
    notificationAlertRef: React.createRef(),
  }
      state={
    check_field_pre:0,
    data_for_transport:[],
    // verifyModal:false,
    // btnd:false,
    // sendOpenModalExport:false,
    id_desc_vacuno:'',
    set_id:null,
    establecimiento_a:'',
    razon_social_a:'',
    domicilio_a:'',

    establecimiento_b:'',
    razon_social_b:'',
    domicilio_b:'',

    establecimiento_c:'',
    rezon_social_c:'',
    paraje_c:'',

    establecimiento_d:'',
    rezon_social_d:'',
    paraje_d:'',

    detail_carnero:'',
    detail_ovejos:'',
    detail_capones:'',
    detail_borregas:'',
    detail_corderas:'',
    detail_corderos:'',
    detail_mamones:'',
    resultSum:'',

    detail_toros:'',
    detail_vacas:'',
    detail_movillosbueyes:'',
    only_movillos_2a3:'',
    only_movillos_1a2:'',
    detail_vaquillonas:'',
    detail_vaquillonas_1a2:'',
    detail_terneros:'',
    resultSumVacuno:'',

    total_vavino:'',
    other_ovino_letter:'',
    
    desc_tittle_one:'',
    desc_tittle_two:'',
    desc_tittle_tree:'',
    discrip_other_num_one:'',
    discrip_other_num_two:'',
    discrip_other_num_tree:'',

    firmante_del_ganado:'',
    codigo_autorizacion:'',
    control_detail:'',
    control_detail_continue:'',
    text_oficina:'',
    text_name:'',
    text_signature:'',

    fecha_emision_guia:'',
    cedula_autorizada:'',
    rut_vendedor:'',
    signature_auto:'',

    propiedad_transport:'',
    itnetatio_establecido:'',
    hora_embarque:'',
    date_embarque:'',
    firma_del_transportista:'',
    nombre_transporte:'',
    ced_identidad:'',
    marca_vehiculo:'',
    matricula_trans:'',
    remolque_matri:'',

    date_autoriza:'',
    hora_autoriza:'',
    cargo_nombre_autoriza:'',
    firma_autoriza:'',

    observacion_text:'',
    firma_auto_obser:'',

    firma_obs_trans:'',
    date_inspect:'',
    inspect_lugar:'',
    inspect_cargo:'',
    inspect_firma:'',
    date_destine:'',
    destine_firma:'',
    date_receive:'',
    receive_reparti:'',
    receive_cargo:'',
    receive_firma:'',

    propiedad:'',

    input_text_reg:'',
    numero_cabezas:'',
    imagen_upload:'',
    file_for_send:'',

    input_text_reg_two:'',
    numero_cabezas_two:'',
    imagen_upload_two:'',
    file_for_send_two:'',

    list_obj_dtils:[],
    propiedad_change_propiedad:'',
    seleccionar_operacion:'',
    seleccionar_operacion_prop:'',
    sendOpenModalFrom:false,
  }
    async componentDidMount(){
        // localStorage.removeItem('dataItem')
        // localStorage.removeItem('dataItemTwo')
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);    
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var getParamLote = new URLSearchParams(window.location.search).get("lote");
        var dataLote = await row_traza_lote(jsondata._id,gerParam,getParamLote)
        this.setState({list_obj_dtils:dataLote.traza_data_lote_guide_animals})
  }
   notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refCreate.notificationAlertRef.current.notificationAlert(options);
  };
  render(){
       const setInfoGuideFromOnlyOpen = async (id_set,type_change,verify,back) =>{
        var getData = JSON.parse(localStorage.getItem('dataItem'))
          this.setState({ set_id:null })
          if (getData==null) {
            setInfoGuideFrom(null,1,true)
          }else{
            setInfoGuideFrom(3,2,true)
            //  if (type_change && id_set==null && verify==undefined) {
            //               this.setState({sendOpenModalFrom:type_change})
            //   }
          }

         }

    const setInfoGuideFrom = async (id_set,type_change,verify,back) =>{
        this.setState({ type_change_prod:type_change, set_id:id_set })
        this.setState({sendOpenModalFrom:verify})
        if (id_set!=null) {
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data = await get_info_guide(id_set,jsondata._id)
          onChangeProd(data)
        }else{
          onChangeProdEmpty()
        }
    }
    const deleteGuide = async () => {

    }

      const saveInfoNextGuia = async (id_set,type_change,verify)=> {
         if ((this.state.numberguia=='' || this.state.numberguia==undefined) || this.state.seleccionar_operacion=='' || this.state.propiedad=='' || this.state.establecimiento_a=='' || this.state.codigo_autorizacion=='') {
           if ((this.state.numberguia=='' || this.state.numberguia==undefined)) {
             this.notify('danger','¡Campos necesario!','Es necesario un número de guía')
           }
           if (this.state.seleccionar_operacion=='') {
             this.notify('danger','¡Campos necesario!','Es necesario Que seleccione un tipo de operación')
           }
           if (this.state.propiedad=='') {
             this.notify('danger','¡Campos necesario!','Es necesario Que seleccione una propiedad')
           }
           if (this.state.establecimiento_a=='') {
             this.notify('danger','¡Campos necesario!','Es necesario que asigne un código de establecimiento')
           }
           if (this.state.codigo_autorizacion=='') {
             this.notify('danger','¡Campos necesario!','Es necesario que asigne un código de autorización')
           }
           return
         }
    var dataInfoOrigin = []
    var contentSerie = document.querySelectorAll('.dataFieldSerie')
    var contentGuide = document.querySelectorAll('.dataFieldGuide')
    var contentHead = document.querySelectorAll('.dataFieldHead')
    var contentTroop = document.querySelectorAll('.dataFieldTroop')

      for (var i = 0; i < contentSerie.length; i++) {
        let id = contentSerie[i].getAttribute("data-id");
        let value_serie = contentSerie[i].value
        let value_guide = contentGuide[i].value
        let value_head = contentHead[i].value
        let value_troop = contentTroop[i].value

        dataInfoOrigin[i] = {id_origin:id,value_serie:value_serie,value_guide:value_guide,value_head:value_head,value_troop:value_troop}
      }

         var gerParam = new URLSearchParams(window.location.search).get("code");
         var userdata = localStorage.getItem('user');
         var jsondata = JSON.parse(userdata);
         var data = false
         if (this.state.set_id==null) {
          data = await create_info_guide(this.state,jsondata._id,gerParam,dataInfoOrigin,this.state.file_for_send,this.state.file_for_send_two)
          if (data==true) {
            this.notify('success','¡¡Mensaje!!','Se ha guardado exitosamente')
            this.setState({sendOpenModalFrom:false})
          }else{
            this.notify('danger','¡¡Mensaje!!','No se pudo guardar')
          }         
         }else{
          data = await update_info_guide(this.state,jsondata._id,gerParam,dataInfoOrigin,this.state.file_for_send,this.state.file_for_send_two,this.state.set_id)
          if (data.message==true) {
            this.setState({set_id:data.guide_id})
            this.notify('success','¡¡Mensaje!!','Se ha actualizó exitosamente')
            this.setState({sendOpenModalFrom:false})
          }else{
            this.notify('danger','¡¡Mensaje!!','No se pudo guardar')
          }
         }
      }

      const setInfoGuideFromList = (id_set,verifyModal)=> {
        // if (id_set==1) {
        // }else{
        //   this.setState({sendOpenModalFromListTwo:verifyModal})
        // }
          this.setState({sendOpenModalFromList:verifyModal})
      }
      const setInfoGuideFromListTwo = (id_set,verifyModal)=> {
        this.setState({sendOpenModalFromListTwo:verifyModal})
      }
      const onChangeProdEmpty = () =>{
        this.setState({
          numberguia:'',
          id_desc_vacuno:'',
          set_id:null,
          establecimiento_a:'',
          razon_social_a:'',
          domicilio_a:'',

          establecimiento_b:'',
          razon_social_b:'',
          domicilio_b:'',

          establecimiento_c:'',
          rezon_social_c:'',
          paraje_c:'',

          establecimiento_d:'',
          rezon_social_d:'',
          paraje_d:'',

          detail_carnero:'',
          detail_ovejos:'',
          detail_capones:'',
          detail_borregas:'',
          detail_corderas:'',
          detail_corderos:'',
          detail_mamones:'',
          resultSum:'',

          detail_toros:'',
          detail_vacas:'',
          detail_movillosbueyes:'',
          only_movillos_2a3:'',
          only_movillos_1a2:'',
          detail_vaquillonas:'',
          detail_vaquillonas_1a2:'',
          detail_terneros:'',
          resultSumVacuno:'',

          total_vavino:'',
          other_ovino_letter:'',
          
          desc_tittle_one:'',
          desc_tittle_two:'',
          desc_tittle_tree:'',
          discrip_other_num_one:'',
          discrip_other_num_two:'',
          discrip_other_num_tree:'',

          firmante_del_ganado:'',
          codigo_autorizacion:'',
          control_detail:'',
          control_detail_continue:'',
          text_oficina:'',
          text_name:'',
          text_signature:'',

          fecha_emision_guia:'',
          cedula_autorizada:'',
          rut_vendedor:'',
          signature_auto:'',

          propiedad_transport:'',
          itnetatio_establecido:'',
          hora_embarque:'',
          date_embarque:'',
          firma_del_transportista:'',
          nombre_transporte:'',
          ced_identidad:'',
          marca_vehiculo:'',
          matricula_trans:'',
          remolque_matri:'',

          date_autoriza:'',
          hora_autoriza:'',
          cargo_nombre_autoriza:'',
          firma_autoriza:'',

          observacion_text:'',
          firma_auto_obser:'',

          firma_obs_trans:'',
          date_inspect:'',
          inspect_lugar:'',
          inspect_cargo:'',
          inspect_firma:'',
          date_destine:'',
          destine_firma:'',
          date_receive:'',
          receive_reparti:'',
          receive_cargo:'',
          receive_firma:'',

          propiedad:'',

          input_text_reg:'',
          numero_cabezas:'',
          imagen_upload:'',
          file_for_send:'',

          input_text_reg_two:'',
          numero_cabezas_two:'',
          imagen_upload_two:'',
          file_for_send_two:'',

          propiedad_change_propiedad:'',
          seleccionar_operacion:'',
          seleccionar_operacion_prop:'',
        })
      }
      const onChangeProd = (getData) =>{
        var fullData = getData.guide[0]
            this.setState({
              numberguia:fullData.numb_guia,
              propiedad:fullData.cambio_propiedad,
              seleccionar_operacion:fullData.tipo_operacion,
              establecimiento_a:fullData.info_a,
              razon_social_a:fullData.razon_a,
              domicilio_a:fullData.domicilio_a,
              establecimiento_b:fullData.info_b,
              razon_social_b:fullData.razon_b,
              domicilio_b:fullData.domicilio_b,
              establecimiento_c:fullData.info_c,
              rezon_social_c:fullData.razon_c,
              paraje_c:fullData.paraje_c,
              establecimiento_d:fullData.info_d,
              rezon_social_d:fullData.razon_d,        
              paraje_d:fullData.paraje_d,
              detail_toros: fullData.vacuno_toros,
              detail_vacas: fullData.vacuno_vaca,
              detail_movillosbueyes: fullData.vacuno_novillo_mta_bueyes,
              only_movillos_2a3: fullData.vacuno_novillo_dat,
              only_movillos_1a2: fullData.vacuno_novillo_uad,
              detail_vaquillonas: fullData.vacuno_vaquillona_mda,
              detail_vaquillonas_1a2: fullData.vacuno_vaquillona_uad,
              detail_terneros: fullData.vacuno_terner,
              resultSumVacuno: fullData.vacuno_total,
              detail_carnero:fullData.ovinos_carnero,
              detail_ovejos:fullData.ovinos_oveja,
              detail_capones:fullData.ovinos_capone,
              detail_borregas:fullData.ovinos_borrega,
              detail_corderas:fullData.ovinos_cordera,
              detail_corderos:fullData.ovinos_cordero,
              detail_mamones:fullData.ovinos_corder_mamome,
              resultSum:fullData.ovinos_total,
              other_ovino_letter:fullData.ovinos_total_letra,
              total_vavino:fullData.vacuno_total_letra,
              input_text_reg: fullData.num_reg_gral_1,
              imagen_upload: config.URL_PRODUCTION+"/storage/file_documents/"+fullData.dibujo_1,
              numero_cabezas: fullData.cabezas_1,
              input_text_reg_two: fullData.num_reg_gral_2,
              imagen_upload_two: config.URL_PRODUCTION+"/storage/file_documents/"+fullData.dibujo_2,
              numero_cabezas_two: fullData.cabezas_2,

              desc_tittle_one:fullData.num_ov_reg_gral_1,
              desc_tittle_two:fullData.num_ov_reg_gral_2,
              desc_tittle_tree:fullData.num_ov_reg_gral_3,
              discrip_other_num_one:fullData.cabezas_ov_1,
              discrip_other_num_two:fullData.cabezas_ov_2,
              discrip_other_num_tree:fullData.cabezas_ov_3,

              firmante_del_ganado:fullData.declarar,
              codigo_autorizacion:fullData.codigo_autorizacion,
              control_detail:fullData.description_one,
              control_detail_continue:fullData.description_two,
              text_oficina:fullData.oficina,
              text_name:fullData.nombre,
              text_signature:fullData.firma_sanitario,

              fecha_emision_guia:fullData.fecha_emision_guia,
              cedula_autorizada:fullData.ci_firma,
              rut_vendedor:fullData.rut_vendedor,
              signature_auto:fullData.firma_autorizada,

              propiedad_transport: fullData.forma_transporte,
              itnetatio_establecido: fullData.itinerario,
              hora_embarque: fullData.hora_transport,
              date_embarque: fullData.fecha_transport,

              firma_del_transportista: fullData.firma_transportista,
              nombre_transporte: fullData.nombre_transportador,
              ced_identidad: fullData.cdi,
              marca_vehiculo: fullData.marca_vehi,
              matricula_trans: fullData.matricula,
              remolque_matri: fullData.matri_remolque,

              date_autoriza: fullData.fecha_police,
              hora_autoriza: fullData.hora_police,
              cargo_nombre_autoriza: fullData.cargo_nombre,
              firma_autoriza: fullData.firma_police,

              observacion_text: fullData.informacion,
              firma_auto_obser: fullData.firma_remitente,
              firma_obs_trans: fullData.firma_obs_transporte,

              date_inspect: fullData.fecha_route,
              inspect_lugar: fullData.lugar,
              inspect_cargo: fullData.cargo_nombre_funcionario_route,
              inspect_firma: fullData.firma_route,
              date_destine: fullData.fecha_destine,
              destine_firma: fullData.firma_destine,

              date_receive: fullData.fecha_dicose,
              receive_reparti: fullData.reparticion,
              receive_cargo: fullData.cargo_nombre_funcionario_dicose,
              receive_firma: fullData.firma_dicose,
              id_desc_vacuno:fullData.id_desc_vacuno,
              })
      }
      const onChangePropiedad = (value) => {
        this.setState({propiedad:value})
        // if (this.state.propiedad_change_propiedad=='') {
        //   this.setState({propiedad_change_propiedad:value})
        // }else{
        //   if (this.state.propiedad_change_propiedad==value) {
        //     setInfoGuideFrom(1,1,true,1)
        //   }else{
        //     setInfoGuideFrom(3,2,true)
        //   }
        // }
      };

  const getDataValidFuncOne = () => {
    var getDataValid = JSON.parse(localStorage.getItem('dataItem'))
    return getDataValid
  };  
  const getDataValidFunc = () => {
    var getDataValid = JSON.parse(localStorage.getItem('dataItemTwo'))
    return getDataValid
  };
  const getFieldValAll = (valOne,valTwo) => {

      //   if (this.state.type_change_prod==1) {
      //   if (localStorage.getItem(valTwo)==null) {
      //     this.setState({detail_carnero:localStorage.getItem(valOne)})
      //   }else{
      //     this.setState({detail_carnero:localStorage.getItem(valTwo)})
      //   }
      // }

      // if (this.state.seleccionar_operacion_prop=='1') {
      //   if (localStorage.getItem(valTwo)==null) {
      //     this.setState({detail_carnero:localStorage.getItem(valOne)})
      //   }else{
      //     this.setState({detail_carnero:localStorage.getItem(valTwo)})
      //       }
      //   }
  };
  const setFieldValTransEction = (valOne,inputVal) => {
    if (valOne == 'detail_carnero') {
      this.setState({detail_carnero: inputVal })
    }
    if (valOne == 'detail_ovejos') {
      this.setState({detail_ovejos: inputVal })
    }
    if (valOne == 'detail_capones') {
      this.setState({detail_capones: inputVal })
    }
    if (valOne == 'detail_borregas') {
      this.setState({detail_borregas: inputVal })
    }
    if (valOne == 'detail_corderas') {
      this.setState({detail_corderas: inputVal })
    }
    if (valOne == 'detail_corderos') {
      this.setState({detail_corderos: inputVal })
    }
    if (valOne == 'detail_mamones') {
      this.setState({detail_mamones: inputVal })
    }
    if (valOne == 'resultSum') {
      this.setState({resultSum: inputVal })
    }

    if (valOne == 'detail_toros') {
      this.setState({detail_toros: inputVal })
    }
    if (valOne == 'detail_vacas') {
      this.setState({detail_vacas: inputVal })
    }
    if (valOne == 'detail_movillosbueyes') {
      this.setState({detail_movillosbueyes: inputVal })
    }
    if (valOne == 'only_movillos_2a3') {
      this.setState({only_movillos_2a3: inputVal })
    }
    if (valOne == 'only_movillos_1a2') {
      this.setState({only_movillos_1a2: inputVal })
    }
    if (valOne == 'detail_vaquillonas') {
      this.setState({detail_vaquillonas: inputVal })
    }
    if (valOne == 'detail_vaquillonas_1a2') {
      this.setState({detail_vaquillonas_1a2: inputVal })
    }
    if (valOne == 'detail_terneros') {
      this.setState({detail_terneros: inputVal })
    }

    if (valOne == 'resultSumVacuno') {
      this.setState({resultSumVacuno: inputVal })
    }

  };
  const setFieldValTransEctionOrigin = (valOne,inputVal,indx) => {
     if (valOne == 'field_serie') {
      var dataArray = []
      for (var i = 0; i < 6; i++) {
        if (indx==i) {
          dataArray[indx] = inputVal
        }else{
          dataArray[i] = (this.state.field_serie==null)?(null):(this.state.field_serie[i])
        }
      }
       this.setState({field_serie:dataArray})
     }
     if (valOne == 'field_guide') {
      var dataArray = []
      for (var i = 0; i < 6; i++) {
        if (indx==i) {
          dataArray[indx] = inputVal
        }else{
          dataArray[i] = (this.state.field_guide==null)?(null):(this.state.field_guide[i])
        }
      }
       this.setState({field_guide:dataArray})
     }

     if (valOne == 'field_head') {
      var dataArray = []
      for (var i = 0; i < 6; i++) {
        if (indx==i) {
          dataArray[indx] = inputVal
        }else{
          dataArray[i] = (this.state.field_head==null)?(null):(this.state.field_head[i])
        }
      }
       this.setState({field_head:dataArray})
     }
     if (valOne == 'field_troop') {
      var dataArray = []
      for (var i = 0; i < 6; i++) {
        if (indx==i) {
          dataArray[indx] = inputVal
        }else{
          dataArray[i] = (this.state.field_troop==null)?(null):(this.state.field_troop[i])
        }
      }
       this.setState({field_troop:dataArray})
     }     
          
    //this.setState({field_serie: this.state.field_serie[indx],inputVal})
  };
  const setFieldValTransEctionDiscrimination = (valOne,inputVal) => {
    if (valOne == 'total_vavino') {
      this.setState({total_vavino: inputVal })
    }
      if (valOne == 'input_text_reg') {
        this.setState({input_text_reg: inputVal })
      }
        if (valOne == 'input_text_reg_two') {
          this.setState({input_text_reg_two: inputVal })
        }
          if (valOne == 'numero_cabezas') {
            this.setState({numero_cabezas: inputVal })
          }
            if (valOne == 'numero_cabezas_two') {
              this.setState({numero_cabezas_two: inputVal })
            }
            if (valOne == 'imagen_upload') {
              this.setState({imagen_upload: inputVal })
            }
            if (valOne == 'imagen_upload_two') {
              this.setState({imagen_upload_two: inputVal })
            }
            if (valOne == 'file_for_send') {
              this.setState({file_for_send: inputVal })
            }
            if (valOne == 'file_for_send_two') {
              this.setState({file_for_send_two: inputVal })
            }            
  };
  const setFieldValTransEctionDiscriminationOvino = (valOne,inputVal) => {
    if (valOne == 'other_ovino_letter') {
      this.setState({other_ovino_letter: inputVal })
    }
      if (valOne == 'desc_tittle_one') {
        this.setState({desc_tittle_one: inputVal })
      }
        if (valOne == 'desc_tittle_two') {
          this.setState({desc_tittle_two: inputVal })
        }
          if (valOne == 'desc_tittle_tree') {
            this.setState({desc_tittle_tree: inputVal })
          }
            if (valOne == 'discrip_other_num_one') {
              this.setState({discrip_other_num_one: inputVal })
            }
            if (valOne == 'discrip_other_num_two') {
              this.setState({discrip_other_num_two: inputVal })
            }
            if (valOne == 'discrip_other_num_tree') {
              this.setState({discrip_other_num_tree: inputVal })
            }
  };
  const setFieldValTransEctionControl = (valOne,inputVal) => {
    if (valOne == 'firmante_del_ganado') {
      this.setState({firmante_del_ganado: inputVal })
    }
    if (valOne == 'codigo_autorizacion') {
      this.setState({codigo_autorizacion: inputVal })
    }
    if (valOne == 'control_detail') {
      this.setState({control_detail: inputVal })
    }
    if (valOne == 'control_detail_continue') {
      this.setState({control_detail_continue: inputVal })
    }
    if (valOne == 'text_oficina') {
      this.setState({text_oficina: inputVal })
    }
    if (valOne == 'text_name') {
      this.setState({text_name: inputVal })
    }
    if (valOne == 'text_signature') {
      this.setState({text_signature: inputVal })
    }
  };
  const setFieldValTransEctionSignature = (valOne,inputVal) => {
    if (valOne == 'fecha_emision_guia') {
      this.setState({fecha_emision_guia: inputVal })
    }
    if (valOne == 'cedula_autorizada') {
      this.setState({cedula_autorizada: inputVal })
    }
    if (valOne == 'rut_vendedor') {
      this.setState({rut_vendedor: inputVal })
    }
    if (valOne == 'signature_auto') {
      this.setState({signature_auto: inputVal })
    }
  };

  const setFieldValTransEctionTransport = (valOne,inputVal) => {
    switch (valOne) {
      case 'propiedad_transport':
      this.setState({propiedad_transport: inputVal })
        break;
      case 'itnetatio_establecido':
      this.setState({itnetatio_establecido: inputVal })
        break;
      case 'hora_embarque':
      this.setState({hora_embarque: inputVal })
        break;
      case 'date_embarque':
      this.setState({date_embarque: inputVal })
        break;
      case 'firma_del_transportista':
      this.setState({firma_del_transportista: inputVal })
        break;
      case 'nombre_transporte':
      this.setState({nombre_transporte: inputVal })
        break; 
      case 'ced_identidad':
      this.setState({ced_identidad: inputVal })
        break; 
      case 'marca_vehiculo':
      this.setState({marca_vehiculo: inputVal })
        break; 
      case 'matricula_trans':
      this.setState({matricula_trans: inputVal })
        break;
      case 'remolque_matri':
      this.setState({remolque_matri: inputVal })
        break;
    }
  };
  const setFieldValTransEctionAutorization = (valOne,inputVal) => {
    switch (valOne) {
      case 'date_autoriza':
      this.setState({date_autoriza: inputVal })
        break;
      case 'hora_autoriza':
      this.setState({hora_autoriza: inputVal })
        break;
      case 'cargo_nombre_autoriza':
      this.setState({cargo_nombre_autoriza: inputVal })
        break;
      case 'firma_autoriza':
      this.setState({firma_autoriza: inputVal })
        break;
    }    
  }; 
  const setFieldValTransEctionObservation = (valOne,inputVal) => {
    switch (valOne) {
      case 'observacion_text':
      this.setState({observacion_text: inputVal })
        break;
      case 'firma_auto_obser':
      this.setState({firma_auto_obser: inputVal })
        break;
    }      
  };
  const setFieldValTransEctionObservationTrans = (valOne,inputVal) => {
    switch (valOne) {
      case 'firma_obs_trans':
      this.setState({firma_obs_trans: inputVal })
        break;
    }  
  };
  const setFieldValTransEctionInspect = (valOne,inputVal) => {
    switch (valOne) {
      case 'date_inspect':
      this.setState({date_inspect: inputVal })
        break;
      case 'inspect_lugar':
      this.setState({inspect_lugar: inputVal })
        break;
      case 'inspect_cargo':
      this.setState({inspect_cargo: inputVal })
        break;
      case 'inspect_firma':
      this.setState({inspect_firma: inputVal })
        break;
    }      
  };
  const setFieldValTransEctionDestine = (valOne,inputVal) => {
    switch (valOne) {
      case 'date_destine':
      this.setState({date_destine: inputVal })
        break;
      case 'destine_firma':
      this.setState({destine_firma: inputVal })
        break;
    }
  };
  const setFieldValTransEctionRecibe = (valOne,inputVal) => {
    switch (valOne) {
      case 'date_receive':
      this.setState({date_receive: inputVal })
        break;
      case 'receive_reparti':
      this.setState({receive_reparti: inputVal })
        break;
      case 'receive_cargo':
      this.setState({receive_cargo: inputVal })
        break;
      case 'receive_firma':
      this.setState({receive_firma: inputVal })
        break;
    }
  };
  const putNumber = (property) => {
    if(jQuery(property).is(':checked')){
      this.setState({establecimiento_a:this.props?.infoData?.guide_incription_m})
    }else{
      this.setState({establecimiento_a:''})
    }
    //console.log(this.props.infoData.guide_incription_m)
  }
  const setValCategoDtl = (categ,espe) => {
    var data = this.state.list_obj_dtils.filter(a => a.categoria==categ)
    if (data.length>0) {
      return data[0]?.count_animal
    }else{
      return ''
    }
  }
  const setValSumTotal = (espe) => {
    var sum_ovinos = 0
    var sum_vacuno = 0
    var data_sum = this.state.list_obj_dtils.map((a,b)=>{
      if ((a.categoria=='Carneros' || a.categoria=='Ovejas' || a.categoria=='Capones' || a.categoria=='Borregas 2-4D' || a.categoria=='Corderas DL' || a.categoria=='Corderos DL' || a.categoria=='Corderos/as Mamones' ) && espe=='Ovinos') {
        
        sum_ovinos+=a.count_animal
      }
      if ((a.categoria=='Toros' || a.categoria=='Vacas' || a.categoria=='Novillos + de 3 años y Bueyes' || a.categoria=='Novillos 2 a 3 años' || a.categoria=='Novillos 1 a 2 años' || a.categoria=='Vaquillonas + de 2 años' || a.categoria=='Vaquillonas 1 a 2 años' || a.categoria=='Terneros/as') && espe=='Vacunos') {

        sum_vacuno+=a.count_animal
      }
    })
    if (sum_ovinos!=0 && espe=='Ovinos') {
      return sum_ovinos
    }
    if (sum_vacuno!=0 && espe=='Vacunos') {
      return sum_vacuno
    }
  }

const putNumberLetter = async (numbers) =>{
  if (numbers!='' || numbers!=undefined) {
     var result = await axios.get("https://numeros-a-letras1.p.rapidapi.com/api/NAL/", {
          params: {'num': numbers},
  headers: {
    'X-RapidAPI-Key': '5519047977mshb50d5759193081bp1a4ea4jsn0171917d654f',
    'X-RapidAPI-Host': 'numeros-a-letras1.p.rapidapi.com'
  }
  }).catch(err => { 
      console.log(err)
    });
  //console.log(result,result?.data?.letras)
      return result?.data?.letras
    }else{
      return ''
    }
}
  const onChangeProdCompilerData = async () => {
    var sum_one =  setValSumTotal('Vacunos')
    var sum_two = setValSumTotal('Ovinos')
    var cabeza_sum_one = 0
    var cabeza_sum_two = 0
    if (sum_one!=undefined) {
      cabeza_sum_one = sum_one
    }
    if (sum_two!=undefined) {
      cabeza_sum_two = sum_two
    }
    var letter_one = await putNumberLetter(sum_one);
    var letter_two = await putNumberLetter(sum_two);
    var date = new Date();
    var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
    var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
        this.setState({
          id_desc_vacuno:'',

          establecimiento_a:this.props?.infoData?.guide_incription_m,
          razon_social_a:'',
          domicilio_a:'',

          establecimiento_b:'',
          razon_social_b:'',
          domicilio_b:'',

          establecimiento_c:'',
          rezon_social_c:'',
          paraje_c:'',

          establecimiento_d:'',
          rezon_social_d:'',
          paraje_d:'',

          detail_toros: setValCategoDtl('Toros','Vacunos'),
          detail_vacas: setValCategoDtl('Vacas','Vacunos'),
          detail_movillosbueyes: setValCategoDtl('Novillos + de 3 años y Bueyes','Vacunos'),
          only_movillos_2a3: setValCategoDtl('Novillos 2 a 3 años','Vacunos'),
          only_movillos_1a2: setValCategoDtl('Novillos 1 a 2 años','Vacunos'),
          detail_vaquillonas: setValCategoDtl('Vaquillonas + de 2 años','Vacunos'),
          detail_vaquillonas_1a2: setValCategoDtl('Vaquillonas 1 a 2 años','Vacunos'),
          detail_terneros: setValCategoDtl('Terneros/as','Vacunos'),
          resultSumVacuno:sum_one,

          detail_carnero: setValCategoDtl('Carneros','Ovinos'),
          detail_ovejos: setValCategoDtl('Ovejas','Ovinos'),
          detail_capones: setValCategoDtl('Capones','Ovinos'),
          detail_borregas: setValCategoDtl('Borregas 2-4D','Ovinos'),
          detail_corderas: setValCategoDtl('Corderas DL','Ovinos'),
          detail_corderos: setValCategoDtl('Corderos DL','Ovinos'),
          detail_mamones: setValCategoDtl('Corderos/as Mamones','Ovinos'),
          resultSum: sum_two,

          other_ovino_letter:letter_two,
          total_vavino:letter_one,
          
          desc_tittle_one:'',
          desc_tittle_two:'',
          desc_tittle_tree:'',
          discrip_other_num_one:'',
          discrip_other_num_two:'',
          discrip_other_num_tree:'',

          firmante_del_ganado:'',
          codigo_autorizacion:'',
          control_detail:'',
          control_detail_continue:'',
          text_oficina:'',
          text_name:'',
          text_signature:'',

          fecha_emision_guia:completeDate,
          cedula_autorizada:'',
          rut_vendedor:'',
          signature_auto:'',

          propiedad_transport:'',
          itnetatio_establecido:'',
          hora_embarque:timeString,
          date_embarque:completeDate,
          firma_del_transportista:'',
          nombre_transporte:'',
          ced_identidad:'',
          marca_vehiculo:'',
          matricula_trans:'',
          remolque_matri:'',

          date_autoriza:completeDate,
          hora_autoriza:timeString,
          cargo_nombre_autoriza:'',
          firma_autoriza:'',

          observacion_text:'',
          firma_auto_obser:'',

          firma_obs_trans:'',
          date_inspect:completeDate,
          inspect_lugar:'',
          inspect_cargo:'',
          inspect_firma:'',
          date_destine:'',
          destine_firma:'',
          date_receive:'',
          receive_reparti:'',
          receive_cargo:'',
          receive_firma:'',

          propiedad:'',

          input_text_reg:'',
          numero_cabezas:cabeza_sum_one,
          imagen_upload:'',
          file_for_send:'',

          input_text_reg_two:'',
          numero_cabezas_two:cabeza_sum_two,
          imagen_upload_two:'',
          file_for_send_two:'',

          propiedad_change_propiedad:'',
          seleccionar_operacion:'',
          seleccionar_operacion_prop:'',
        })
  }
   
  const putChangeField = async (type) =>{
    this.setState({check_field_pre:type})
    if (type==1) {
      onChangeProdCompilerData()
    }

    if (type==2) {
        if (this.props.sendInfoProps.dataGuide[this.props.sendInfoProps.dataGuide.length - 1]!=null) {
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data = await get_info_guide(this.props.sendInfoProps.dataGuide[this.props.sendInfoProps.dataGuide.length - 1]?.id,jsondata._id)
          onChangeProd(data)
        }else{
          onChangeProdEmpty()
        }
    }
  }
  const selectOperations = async (value) => {
    this.setState({seleccionar_operacion: value})
    var gerParam = new URLSearchParams(window.location.search).get("code");
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var dataTrans=[]
    var dataTransport = await row_transport_logistics(jsondata._id,gerParam)
        if (dataTransport.dataLogisticsTransport.length>0) {
            dataTrans = dataTransport.dataLogisticsTransport
        }
    this.setState({data_for_transport:dataTrans})
  }
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.refCreate.notificationAlertRef} />
      </div>


<ModalListconstancyTwo {...this.state} setInfoGuideFromList={setInfoGuideFromListTwo} />
<ModalListconstancy {...this.state} setInfoGuideFromList={setInfoGuideFromList} />
    <Modal isOpen={this.state.sendOpenModalFrom} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
          <Row>
{(this.state.set_id!=null)?(null):(<><Col md="12">
                                                        <label style={{marginRight: '5px'}} className="form-control-label">Recopilar datos</label>
                                                        <input
                                                        value={1}
                                                        checked={this.state.check_field_pre==1}
                                                        onChange={(e)=>{ putChangeField(e.target.value) }}
                                                        //checked={this.state.propiedad=='Con Cambio de Propiedad 1'}
                                                        style={{height: '0px'}}
                                                        className="radioStyleOne"
                                                        type="radio"
                                                        />
                                                      </Col>
                                                      <Col md="12">
                                                        <label style={{marginRight: '5px'}} className="form-control-label">Recopilar datos de la ultima guía</label>
                                                        <input
                                                        value={2}
                                                        checked={this.state.check_field_pre==2}
                                                        onChange={(e)=>{ putChangeField(e.target.value) }}
                                                        //checked={this.state.propiedad=='Con Cambio de Propiedad 1'}
                                                        style={{height: '0px'}}
                                                        className="radioStyleOne"
                                                        type="radio"
                                                        />
                                                      </Col></>)  }
            <Col md={4}>
              <h3 className="mb-0 mt-2">
              Guía de propiedad y tránsito (B1)-Z
              </h3>
            </Col>
            <Col md={2}>
                   <Input
                    style={{textAlign: 'left',fontSize: '17px',fontWeight: '600',color: '#32325d',height: '41px',borderRadius: '5px',width: '100%',color: 'black',border: 'solid 1px #c9edfd',letterSpacing: '0px'}}                    
                     value={(this.state.numberguia==undefined)?(null):(this.state.numberguia)}
                     onChange={(e)=>{this.setState({numberguia:e.target.value})}}
                     type="number"
                     id="numberguia"
                     placeholder="N# Guía"
                    />            
            </Col>
            <Col md={6} className="text-right">
{/*{(this.state.type_change_prod==1)?(
            <a href="#" onClick={()=> {setInfoGuideFrom(3,2,true)} }>
              <h3 class="mb-0">Guardar y siguiente guía</h3>
            </a>):(
            <a href="#" onClick={()=> {setInfoGuideFrom(1,1,true,1)} }>
              <h3 class="mb-0">Regresar</h3>
            </a>)}*/}
            <a href="#" onClick={()=> {saveInfoNextGuia(1,1,false)} }>
              <h3 class="mb-0">Guardar y cerrar</h3>
            </a>
            <a href="#" onClick={()=> {setInfoGuideFrom(null,false)} }>
              <h3 class="mb-0">Cerrar</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <hr style={styles.hrStyle} />
          <p style={{textAlign: 'center',fontWeight: '600',fontSize: '12px',color: 'black',margin: 0}}>La informacion enunciada en este documento tiene caracter de declaración jurada</p>
          <p style={{textAlign: 'center',fontWeight: '600',fontSize: '12px'}}>Advertencia: Este formulario debe ser llenado de forma clara y legible con letra de imprenta mayuscula, usando preferidamente lapicera negra. No se admiten tachaduras ni enmiendas.</p>
            <Row>
              <Col md="12">
                <label className="form-control-label" style={styles.labelStyle}>
                1- TIPO DE OPERACIÓN:
                </label>
                  <select
                    value={this.state.seleccionar_operacion}
                    onChange={(e)=>{selectOperations(e.target.value)}} 
                    id="exampleFormControlSelect1"
                    //className="col-4"
                    style={{border:'none',padding: 0,height: 'auto',width: '130px'}}
                    //type="select"
                    >
                    <option value="0">Seleccione...</option>
                    <option value="A Consignación">A Consignación</option>
                    <option value="Venta de consignación">Venta de consignación</option>
                    <option value="Venta Nacional">Venta Nacional</option>
                    <option value="Exportación">Exportación</option>
                    <option value="Otro">Otro</option>
                  </select>
              </Col>

              <Col md="12" style={styles.cardStyleSection}>
              <Row>
                <div className="col" style={{width: '8px',maxWidth: '240px'}}>
                  <div>
                    <label className="form-control-label" style={styles.labelStyleTwo}>
                    {String('Con Cambio de Propiedad 1').toUpperCase()}
                    </label>
                    <Input
                    value={this.state.propiedad}
                    onChange={(e)=>{ onChangePropiedad('Con Cambio de Propiedad 1') }}
                    checked={this.state.propiedad=='Con Cambio de Propiedad 1'}
                    style={{height: '0px'}}
                    className="radioStyle"
                    type="radio">
                    </Input>
                  </div>
                  <div>
                    <label className="form-control-label" style={styles.labelStyleTwo}>
                    {String('Sin Cambio de Propiedad 2').toUpperCase()}
                    </label>
                    <Input
                    value={this.state.propiedad}
                    onChange={(e)=>{onChangePropiedad('Sin Cambio de Propiedad 2')}}
                    checked={this.state.propiedad=='Sin Cambio de Propiedad 2'}
                    style={{height: '0px'}}
                    className="radioStyle"
                    type="radio">
                    </Input>
                  </div>
                </div>
                <div className="col-md-8" style={{alignSelf: 'center'}}>
                    <label className="form-control-label" style={{color: '#000000',fontSize: '11px',fontWeight: '600',marginRight: '25px',margin: 0}}>
                      Marque Solo Uno (en cada guia solo se puede documentar un solo tipo de movimiento). <br/>
                      Envios a remate y a consignacion no representan cambio de propiedad.
                    </label>
                </div>
               </Row>
              </Col>
              <Col md="12">
                <label className="form-control-label" style={styles.labelStyleTree}>
                  2- ESTABLECIMIENTOS INTERVINIENTES:
                </label>
                <label className="form-control-label" style={styles.labelStyleFour}>
                  En todos los casos deberán llenarse los casilleros A, B, C, D, segun corresponda.
                </label>
              </Col>

{/*{(this.state.type_id_set_form!=2)?(null):(
  (this.state.type_change_prod==1)?(null):(
                            <Col md="12">
                              <select
                                value={this.state.seleccionar_operacion_prop}
                                onChange={(e)=>{onChangeProd(e.target.value)}} 
                                id="exampleFormControlSelect1"
                                //className="col-4"
                                style={{border:'none',padding: 0,height: 'auto',width: '130px'}}
                                //type="select"
                                >
                                <option value="0">Seleccione...</option>
                                <option value="2">Propietario</option>
                                <option value="1">Consignatario</option>
                                <option value="3">Otro</option>
                              </select>
                            </Col>
                              ))  }*/}
              <Col md="6" className="pb-2">
               <div style={styles.cardStyleSectionTwo}>
                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNext}>
                    CAMBIO DE PROPIEDAD
                  </div>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    Nro del propietario actual (vendedor).<br/>
                    Venta de Remate - Nro del Rematador.<br/>
                    Venta a Consignacion - Nro del Consignatario.<br/>
                  </div>
                </div>

                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNext}>
                    SIN CAMBIO DE PROPIEDAD
                  </div>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    Nro del propietario de los ganados.<br/>
                  </div>
                </div>
                            <Col md="12">
                              <label className="form-control-label">Asignar numero</label>
                              <input
                              value={this.state.propiedad}
                              onChange={(e)=>{ putNumber(e.target) }}
                              //checked={this.state.propiedad=='Con Cambio de Propiedad 1'}
                              className="radioStyle"
                              type="checkbox"
                              />
                            </Col>
                <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                  <div style={{margin:'auto',marginLeft: '37px'}}>
                    <div style={{display:'flex'}}>
                    <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>A)</label>
                    <Input
                    style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '19px'}}                    
                     value={(this.state.establecimiento_a==undefined)?(null):(this.state.establecimiento_a)}
                     onChange={(e)=>{this.setState({establecimiento_a:e.target.value})}}
                     type="text"
                     id="establecimiento_a"
                    />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{display:'flex'}}>
                  <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageReson}>
                    Razón social
                  </div>
                  <input
                    style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                     value={(this.state.razon_social_a==undefined)?(null):(this.state.razon_social_a)}
                     onChange={(e)=>{this.setState({razon_social_a:e.target.value})}}
                     type="text"
                     id="razon_social_a"
                    />
                </div>
                <div style={{display:'flex',marginTop: '5px'}}>
                  <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageResonTwo}>
                    Domicilio Constituido
                  </div>
                  <input
                    style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                     value={(this.state.domicilio_a==undefined)?(null):(this.state.domicilio_a)}
                     onChange={(e)=>{this.setState({domicilio_a:e.target.value})}}
                     type="text"      
                     id="domicilio_a"            
                    />
                </div>                
                </div>
              </Col>

              <Col md="6" className="pb-2">
              <div style={styles.cardStyleSectionTwo}>
                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNext}>
                    CAMBIO DE PROPIEDAD
                  </div>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    Nro del nuevo propietario Comprador.<br/>
                  </div>
                </div>

                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNext}>
                    SIN CAMBIO DE PROPIEDAD
                  </div>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    Nro del propietario de los ganados.<br/>
                    Envio  a Remate - Nro del Rematador.<br/>
                    Envio a Consignación - Nro del Consignatario.<br/>
                  </div>
                </div>

                <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                  <div style={{margin:'auto',marginLeft: '37px'}}>
                    <div style={{display:'flex'}}>
                    <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>B)</label>
                    <Input
                    style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '19px'}}                    
                     value={(this.state.establecimiento_b==undefined)?(null):(this.state.establecimiento_b)}
                     onChange={(e)=>{this.setState({establecimiento_b:e.target.value})}}
                     type="text"
                     id="establecimiento_b"
                    />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{display:'flex'}}>
                  <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageReson}>
                    Razón social
                  </div>
                  <input
                    style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                     value={(this.state.razon_social_b==undefined)?(null):(this.state.razon_social_b)}
                     onChange={(e)=>{this.setState({razon_social_b:e.target.value})}}
                     type="text"
                     id="razon_social_b"
                    />
                </div>
                <div style={{display:'flex',marginTop: '5px'}}>
                  <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageResonTwo}>
                    Domicilio Constituido
                  </div>
                  <input
                    style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                     value={(this.state.domicilio_b==undefined)?(null):(this.state.domicilio_b)}
                     onChange={(e)=>{this.setState({domicilio_b:e.target.value})}}
                     type="text"
                     id="domicilio_b"
                    />
                </div>                
               </div>
              </Col>

              <Col md="6" className="pb-2">
                <div style={styles.cardStyleSectionTwo}>

                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    LUGAR FISICO DE LA <b>SALIDA</b> DE LOS GANADOS
                  </div>
                </div>

                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    En ventas de consignación: ver numeral 11.<br/>
                  </div>
                </div>

                <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                  <div style={{margin:'auto',marginLeft: '37px'}}>
                    <div style={{display:'flex'}}>
                    <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>C)</label>
                    <Input
                    style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '19px'}}                    
                     value={(this.state.establecimiento_c==undefined)?(null):(this.state.establecimiento_c)}
                     onChange={(e)=>{this.setState({establecimiento_c:e.target.value})}}
                     type="text"
                     id="establecimiento_c"
                    />
                    </div>
                  </div>
                </div>
                <br />
                <div style={{display:'flex'}}>
                  <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageReson}>
                    Razón social
                  </div>
                  <input
                    style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                     value={(this.state.rezon_social_c==undefined)?(null):(this.state.rezon_social_c)}
                     onChange={(e)=>{this.setState({rezon_social_c:e.target.value})}}
                     type="text"
                     id="rezon_social_c"
                    />
                </div>
                <div style={{display:'flex',marginTop: '5px'}}>
                  <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageResonTwo}>
                    Paraje Ubicación
                  </div>
                  <input
                    style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                     value={(this.state.paraje_c==undefined)?(null):(this.state.paraje_c)}
                     onChange={(e)=>{this.setState({paraje_c:e.target.value})}}
                     type="text"
                     id="paraje_c"
                    />
                </div>                
               </div>
              </Col>

              <Col md="6" className="pb-2">
                <div style={styles.cardStyleSectionTwo}>
                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    LUGAR FISICO DE <b>LLEGADA</b> DE LOS GANADOS
                  </div>
                </div>

                <div style={{display:'flex'}}>
                  <div className="form-control-label" style={styles.labelStyleTwoNextMessage}>
                    Envio a remate: Nro de Local Feria.<br/>
                  </div>
                </div>

                  <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto',marginLeft: '37px'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>D)</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '19px'}}                    
                       value={(this.state.establecimiento_d==undefined)?(null):(this.state.establecimiento_d)}
                       onChange={(e)=>{this.setState({establecimiento_d:e.target.value})}}
                       type="text"
                       id="establecimiento_d"
                      />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{display:'flex'}}>
                    <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageReson}>
                      Razón social
                    </div>
                    <input
                      style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                       value={(this.state.rezon_social_d==undefined)?(null):(this.state.rezon_social_d)}
                       onChange={(e)=>{this.setState({rezon_social_d:e.target.value})}}
                       type="text"
                       id="rezon_social_d"
                      />
                  </div>
                  <div style={{display:'flex',marginTop: '5px'}}>
                    <div className="form-control-label mr-1 mt-2" style={styles.labelStyleTwoNextMessageResonTwo}>
                      Paraje Ubicación
                    </div>
                    <input
                      style={{height: '20px',textAlign: 'justify',fontSize: '14px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                       value={(this.state.paraje_d==undefined)?(null):(this.state.paraje_d)}
                       onChange={(e)=>{this.setState({paraje_d:e.target.value})}}
                       type="text"
                       id="paraje_d"
                      />
                  </div>                
                </div>
              </Col>

            <DetailSection validSwith={this.state} setValField={setFieldValTransEction} />
            <DetailOrigin validSwith={this.state} setValFieldOrigin={setFieldValTransEctionOrigin} />
            <DetailDiscrimination validSwith={this.state} setValFieldDiscrimination={setFieldValTransEctionDiscrimination} />
            <DetailDiscriminationOvino validSwith={this.state} setValFieldDiscriminationOvino={setFieldValTransEctionDiscriminationOvino}/>
            <DetailControl validSwith={this.state} setValFieldControl={setFieldValTransEctionControl} />
            <DetailSignature validSwith={this.state} setValFieldSignature={setFieldValTransEctionSignature} />
            <FirstFooter validSwith={this.state} />
            <DetailDiscrimination validSwith={this.state} setValFieldDiscrimination={setFieldValTransEctionDiscrimination} />
            <DetailDiscriminationOvino validSwith={this.state} setValFieldDiscriminationOvino={setFieldValTransEctionDiscriminationOvino} />
            <DetailTransport validSwith={this.state} setValFieldTransport={setFieldValTransEctionTransport} />
            <DetailAutorization validSwith={this.state} setValFieldAutorization={setFieldValTransEctionAutorization} />
            <DetailObservation validSwith={this.state} setValFieldObservation={setFieldValTransEctionObservation}/>
            <DetailObservationTrans validSwith={this.state} setValFieldObservationTrans={setFieldValTransEctionObservationTrans}/>
            <DetailInspect validSwith={this.state} setValFieldInspect={setFieldValTransEctionInspect}/>
            <DetailDestine validSwith={this.state} setValFieldDestine={setFieldValTransEctionDestine}/>
            <DetailRecive validSwith={this.state} setValFieldRecibe={setFieldValTransEctionRecibe}/>
            <FirstFooterTwo validSwith={this.state} />
            </Row>
            {this.state.messf ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messf}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row className="mt-3">
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={()=> {saveInfoNextGuia(1,1,false)}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar y cerrar</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoGuideFrom(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Nueva Guía
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFrom(null,1,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
        <Row>

{/*{(getDataValidFuncOne()==null)?(null):(
        <Col md="4">
          <Card className="mb-4" md="3">
          <CardHeader style={{borderRadius: '20px'}}>
              <h3 className="mb-0">
               Guía productor
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFrom(1,1,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
            </CardHeader>
          </Card>
        </Col>
)}*/}
            {
                  this.props.sendInfoProps.dataGuide.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.numb_guia=="null") ? ("Sin número de guía") : ('N# Guía: '+value.numb_guia)}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoGuideFrom(value.id,1,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteGuide(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
        {(getDataValidFunc()==null)?(null):(
        <Col md="4">
          <Card className="mb-4">
          <CardHeader style={{borderRadius: '20px'}}>
              <h3 className="mb-0">
               Guía consignatario
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFrom(3,2,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
            </CardHeader>
          </Card>        
        </Col> 
          )}

{/*        {(getDataValidFunc()==null)?(null):(
*/}        <Col md="4">
          <Card className="mb-4">
          <CardHeader style={{borderRadius: '20px'}}>
        <Col md="12">
              <h3 className="mb-0">
               Corresponde a la guía y transito (Certificación a faena - Exportación)
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFromList(1,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
        </Col> 
        <Col md="12">
              <h3 className="mb-0">
               Corresponde a la guía y transito
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFromListTwo(1,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
        </Col>
            </CardHeader>
          </Card>        
        </Col>  
{/*          )}
*/}

      {/*------Segundo ejemplo------*/}

{/*        <Col md="12">
          <Card className="mb-4">
          <CardHeader style={{borderRadius: '20px'}}>
          <Row>
              <h3 className="mb-0">
               Ejemplo CON CAMBIO DE PROPIEDAD
              </h3>
          </Row>
            </CardHeader>
          </Card>        
        </Col>

        <Col md="3">
          <Card className="mb-4" md="3">
          <CardHeader style={{borderRadius: '20px'}}>
              <h3 className="mb-0">
               Ingresar guía de propiedad y transito
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFrom(1,2,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
            </CardHeader>
          </Card>
        </Col>

        <Col md="3">
          <Card className="mb-4">
          <CardHeader style={{borderRadius: '20px'}}>
              <h3 className="mb-0">
               Guía consignatario
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFrom(2,2,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
            </CardHeader>
          </Card>        
        </Col>

        <Col md="3">
          <Card className="mb-4">
          <CardHeader style={{borderRadius: '20px'}}>
              <h3 className="mb-0">
               Segunda guía
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFrom(3,2,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
            </CardHeader>
          </Card>        
        </Col> 

        <Col md="3">
          <Card className="mb-4">
          <CardHeader style={{borderRadius: '20px'}}>
              <h3 className="mb-0">
               Constancia de propiedad y transito de animales registrados (Lista frigorifico)
              </h3>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoGuideFromList(2,true) }}>
                <span className="btn-inner--text">+</span>
              </Button>
            </CardHeader>
          </Card>        
        </Col>*/}

        </Row>
    </>
  );
 }
}

export default ModalStartLogistics;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  width: '90px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  },
  cardStyleSectionTwo:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  }
}