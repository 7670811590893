import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";

const CardProductionEnd = () => {

    const router = useHistory();
    const header = (
        <img 
            alt="production" 
            src="/layout/images/icons/check.png"  
            className='w-2'
        />
    );

    return (
        <div>
            <Card title="Finalizar Producción" header={ header } >
                <Button 
                    label="Finalizar" 
                    size='small'
                    className='button-color-fod-blue'
                    style={{width: '100%'}}
                    onClick={ () => {
                     router.push('/admin/grains-nama-end'+router.location.search)
                    } }
                    
                />
                <p className="mt-4">
                    {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                </p>
            </Card>
        </div>
    )
}

export default CardProductionEnd