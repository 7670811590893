import React, {createRef, useEffect} from "react";
import {
//  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import QRCode from "react-qr-code";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import SidebarCustom from './SidebarCustom';
import GNama from "views/pages/forms/Production_FV/FV/GrainNama/StartGrainNamaForm.js";
import GNamaCultive from "views/pages/forms/Production_FV/FV/GrainNama/StartGrainNamaFormRelease.js";

import ViewObservationMVR from "views/pages/tables/Production/ViewObservationMVR.js";
import { Panel } from 'primereact/panel';

export class BuildingProduction extends React.Component {
  state={
    data_gc:[],
    data_gc_th:[],
    company_id:null,
    jsondataBase:null,
    globalFilter:'',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    visibleRight:false,
    visibleRightTwo:false,
    code:null,
    codeSave:null,
    validatePermitions:true
  }
 async componentDidMount(){
    this.initData()
  }
  initData =async()=>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (this.props.sendDataCompany.type_traza==null) {
      jQuery('#tg').remove()
      return
    }else{
      if (this.props.sendDataCompany.type_traza.indexOf("P")==-1) {
        jQuery('#tg').remove()
      }
    }
    var data = await data_list_processes(jsondata._id,this.props.sendDataCompany.user_id_connection,this.props.sendDataCompany.type,'ttp',jsondata.userType)
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (data.FV_GC[1].length==0) {
      this.setState({visibleRight:true})
    }
    this.setState({
      company_id:gerParam,
      data_gc:data.FV_GC[1],
      data_gc_th:data.FV_GC[0],
      jsondataBase:jsondata.database
    })
  }
  setContent = (data) => {
    var routeParamName = 'info_crops_vf_gc'
    if (this.props.sendDataCompany.role==2) {
      switch (1) {
        case this.props.sendDataCompany.information_nama_g:
          routeParamName='info_crops_vf_gc';
          break;
        case this.props.sendDataCompany.production_nama_g:
          routeParamName='production_land_preparation_vf_gc';
          break;
        case this.props.sendDataCompany.hading_nama_g:
          routeParamName='hading_info_weeds_vf_gc';
          break;
        case this.props.sendDataCompany.harvest_nama_g:
          routeParamName='harvest_info_harvest_vf_gc';
          break;
        case parseInt(this.props.sendDataCompany.document_nama_g):
          routeParamName='grains-nama-documents';
          break;
        default:
          routeParamName='info_crops_vf_gc';
      }
    }
    if (data==undefined) {
      return
    }
    if (data!=undefined) {
      if (data.length!=0) {
      var content=[]
        data.map((a,b)=>{
           var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/"+routeParamName
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/fruits-vegetables/production"
              }
            }
   var content_two = <>
    
    {(val[0]!='codigo')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
    {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td><td>
                  <ComponentLink
                   className="font-weight-bold"
                                         to={{
                     pathname:url_selected,
                     search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                     state:{
                       codigoTraza:val[1]
                     }
                     }}
                   >
                  Ver Este Proceso
                  </ComponentLink>
                </td>
                <td>
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/prouction-observations-mvr",
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver Todas las Observaciones
                     </ComponentLink>
                </td>
                {/*<td><QRCode style={{ width: "65px", height: 'auto' }} value={"http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code="+val[1]+"&db="+this.state.jsondataBase}  /></td>*/}
                </>):(null)}
                        </>
                        return content_two
          })
          content[b] = <tr>{content_one}</tr>
        })
      }
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]= {title:a.split('*')[0],table:a.split('*')[1]}
      })
    }
    return content
  }

render(){
    const header = (
        <div className="flex flex-column flex-md-row md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Gestionar Producción
            </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <Input
                type="search" 
                value={this.state.globalFilter}
                //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>

            <Button 
                label="Nueva" 
                icon="pi pi-plus" 
                severity="success" 
                className="btn-fod-green mr-2" 
                onClick={() => this.setState({visibleRight:true})} 
                />
        </div>
    );
    const actionBodyTemplate = (rowData) => {
        var url_selected = null
        var routeParamName = 'info_crops_vf_gc'
        if (this.props.sendDataCompany.role==2) {
          switch (1) {
            case this.props.sendDataCompany.information_nama_g:
              routeParamName='info_crops_vf_gc';
              break;
            case this.props.sendDataCompany.production_nama_g:
              routeParamName='production_land_preparation_vf_gc';
              break;
            case this.props.sendDataCompany.hading_nama_g:
              routeParamName='hading_info_weeds_vf_gc';
              break;
            case this.props.sendDataCompany.harvest_nama_g:
              routeParamName='harvest_info_harvest_vf_gc';
              break;
            case parseInt(this.props.sendDataCompany.document_nama_g):
              routeParamName='grains-nama-documents';
              break;
            default:
              routeParamName='info_crops_vf_gc';
          }
        }
        if (rowData.codigo?.split('-')[0]=='FCTVFGC') {
          //url_selected = "/admin/"+routeParamName
          url_selected = "/admin/panel-fv"
        }
        if (rowData.codigo?.split('-')[0]=='FCT') {
         url_selected = "/admin/fruits-vegetables/production"
        }
        return (
            <>
                <Button 
                    icon="pi pi-cog" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    title={url_selected+( (this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo) )}
                    onClick={ () => {
                      this.props.history.push( url_selected+( (this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo) ) )
                    }}
                    tooltip="Ver este proceso" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                />

                <Button 
                    icon="pi pi-eye" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    onClick={ () => {
                      this.setState({visibleRightTwo:true, code:rowData.codigo})
                      //this.props.history.push( '/admin/prouction-observations-mvr'+((this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo)) )
                    }}
                    tooltip="Ver observaciones" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />
{/*                <Button 
                    icon="pi pi-pencil" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    //onClick={() => editProduct(rowData)} 
                    tooltip="Editar Información" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                />*/}

            </>
        );
    };
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
    const putSaveCode = (code) =>{
      this.setState({codeSave:code})
    }
    const changeValidate=(bolean)=>{
      this.setState({validatePermitions:bolean})
    }
  return (
    <>
      {/*<SimpleHeader name="Gestión de cultivos en proceso" parentName="Gestión de cultivos en proceso" section="fuits_vegetable" section_two="Tabla" section_table_form="table_traza_proccess" />*/}
        <div className="mt-3">
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    {/*<Toast ref={toast} />*/}

                    <DataTable
                         value={this.state.data_gc}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        <Column field="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>
                        {this.setContentTh(this.state.data_gc_th).map((a,b)=>{
                          return <Column body={(data)=>{
                            if (data[a.table]!=null) {
                              if (data[a.table].length>10) {
                                return `${data[a.table].substring(0, 10)}...`
                              }else{
                                return data[a.table]
                              }
                            }else{
                              return "Sin datos"
                            }
                          }} field={a.table} header={a.title} ></Column>
                        })}
                        <Column field="created_at" header="Creado" sortable></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>

    
                </div>
            </div>
        </div>
      </div>
      <SidebarCustom 
          onVisible={this.state.visibleRight} 
          onHide={() => this.setState({visibleRight:false})} 
          onWidth={ 650 }
          position="right" 
          title={'Información general'}>

            <GNama 
            {...this.props} 
            hide={ () => this.setState({visibleRight:false}) }
            putSaveCodeProp={putSaveCode}
            refreshData={this.initData}
            linkValidatePermitions={this.state.validatePermitions}
            />
            <GNamaCultive
                {...this.props}
                info_select={{ name: 'Información Cultivo', table: 'info_crops_vf_gc' }}
                putSaveCodeProp={putSaveCode}
                codeSave={this.state.codeSave}
                refreshData={this.initData}
                validatePermitions={changeValidate}
            />
            <br />
            <br />
            <br />
            
{/*          <Panel header="Información del Productor" toggleable collapsed={true}>
          </Panel>*/}
      </SidebarCustom>

      <SidebarCustom 
          onVisible={this.state.visibleRightTwo} 
          onHide={() => this.setState({visibleRightTwo:false})} 
          onWidth={ 800 }
          position="right" 
          title={'Observación'}>
          <ViewObservationMVR {...this.props} hide={ () => this.setState({visibleRightTwo:false}) } code={this.state.code} />
      </SidebarCustom>

    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
    var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('VCF')

          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
          this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <BuildingProduction {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
