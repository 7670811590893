import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { get_data_transport_trace } from "network/ApiAxios";
//import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentSubPlant} from './InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'

const datacontent = [
// {title:'Datos Planta',table:'transport_cosecha',
// title_one:'Ingreso del Producto',table_one:'create_plant_product',
// title_two:'Muestra de Calidad',table_two:'create_plant_sample',
// title_three:'Peso del Producto',table_three:'create_plant_weight_product',
// title_fuor:'Tratamiento',table_fuor:'create_plant_treatment',
// icon:'far fa-newspaper','multiple_tables':2},
{title:'Enfriamiento',table:'create_plant_cooling_kdes',icon:'fas fa-thermometer-full','multiple_tables':0},
{title:'Empaque Inicial',table:'create_plant_product_kdes',icon:'fas fa-thermometer','multiple_tables':0},
{title:'Recibido',table:'create_plant_receiver_kdes',icon:'fa fa-box','multiple_tables':0},
{title:'Envio',table:'create_plant_shipping_kdes',icon:'fa fa-box','multiple_tables':0},
]

export default class SectionPlanta extends React.Component {
  render(){
  return (
    <ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
  );
}
}

//export default Elements;
