import React, {useState} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter
} from "reactstrap";
import jQuery from 'jquery';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import FinalNama from "./DetailProductionTime/FinalNamaGrain";
import { generate_trazabilidad_grain, get_data_row_admin_grain, get_permission, data_obv_vr, data_obv_mvr, save_report_mrv } from "network/ApiAxios";
import { generateChain } from "network/GenerateTokenChain";
import NotificationAlert from "react-notification-alert";
import { NavLink } from "react-router-dom";
import $ from 'jquery'
import QRCode from "react-qr-code";
import {UserContext} from "layouts/store";
import './style.css';
import Web3 from 'web3';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import SelectSearch from 'react-select-search';
import DetailsObservationsRev from "./DetailProductionTime/DetailsObservationsRev";
import SignatureCanvas from 'react-signature-canvas';
import VistaTraceNGMRV from "views/pages/examples/Production/VistaTraceNamaGViewMRV";
var web3 = new Web3('https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/')

var tituloGlobal=[];
var activityGlobal=[];
export  class ViewDetailMRV extends React.Component {
  static contextType = UserContext
  state={
    collapse:[],
    notificationAlertRef: React.createRef(),
    displayCard:"none",
    dataQr:"Sin datos",
    loading:'Cargando...',
    collapsefuve:true,
    idTraza:null,
    codigoTrza:null,
    routeget: new URLSearchParams(window.location.search).get("code"),
    routegetTwo: new URLSearchParams(window.location.search).get("up"),
    routegetThree: new URLSearchParams(window.location.search).get("db"),
    loadingupload:'none',
    btnend:'block',
    info_producer_g:'none',
    fordisplay:{},
    walletData:null,
    openModal:false,
    disabledbtn:false,
    db_prod:null,
    code_prod:null,
    form_prod:null,
    set_type_content:1,
    set_type_content_onre:null,
    getDataMVR:[],
    all_data_info:{},
    data_user:null,
    data_information:[],
    type_traza_data_user:null
  }
  async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    //console.log(jsondata)
    var status='';
    var walletdata = localStorage.getItem('user_wallet');
    var jsondatawallet = JSON.parse(walletdata);
    var dataPermission = await get_permission(jsondata._id)
    if (jsondata.type_traza=='ON-RE-VA') {
      alert("Antes de hacer alguna modificación es necesario que elija un rol en el panel de inicio")
      this.props.history.push('/admin/home')
      return
    }
    this.setState({walletData:jsondatawallet,data_user:dataPermission,type_traza_data_user:jsondata.type_traza})

    this.getDataMVRUpdate()
      var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
      var getParamDb = new URLSearchParams(window.location.search).get("db");

  }
  componentWillMount(){
    tituloGlobal = []
  }
  getDataForqr = (data) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (data==null) {
      this.setState({
        loading:'Sin Gestión de cultivos.'
      })
    }else{
      if (this.state.routeget==null || this.state.routeget==undefined) {
        var codigo_set = 'FCTGN-'+jsondata._id+data.id
      }else{
        var codigo_set = this.state.routeget
      }
      this.setState({
        displayCard:"flex",
        dataQr:"http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code="+codigo_set+"&db="+jsondata.database,
        loading:null,
        idTraza:data.id,
        codigoTrza:codigo_set,
      })
    }
  }
  getDataMVRUpdate = async () =>{
    var get_data_obv = await data_obv_mvr('general',this.state.routeget,this.state.routegetThree)
    //this.setState({getDataMVR:get_data_obv.data})
   if (get_data_obv.data!=null) {
     if (JSON.parse(get_data_obv.data.data_adjunt)!=null) {
      var status_data = []
      var get_data_pr = []
      var get_state = []
      var all_data={}
      JSON.parse(get_data_obv.data.data_adjunt).map((a,b)=>{
              var groupByCategory =[]
              if (a.value.split('*')[0]!='general') {
                    Object.entries(a.datastate).map((val,ind)=>{
                    if (val[0]=='state_status') {
                      groupByCategory = Object.values(val[1]).reduce((group, product) => {
                         const category = product;
                         group[category] = group[category] ?? [];
                          group[category].push(product);
                          return group;
                      }, {});
                    }
                    get_data_pr[ind] = Object.keys(val[1]).length
                   })
              var get_numb_max = Math.max(...get_data_pr)
              var cr = ''
              if (groupByCategory['Realizado']!=undefined) {
                cr = ' - Realizado: '+groupByCategory['Realizado'].length
              }
              var rv = ''
              if (groupByCategory['Revisar']!=undefined) {
                rv = <button style={{color:'orange',padding: 0, border: '0px', background: 'transparent'}} onClick={()=>{this.funcOpenModal(true,'general')}}>{' - Por Revisar: '+groupByCategory['Revisar'].length}</button>
              }
              var ph = ''
              if (groupByCategory['Por Hacer']!=undefined) {
                ph = ' - Por Hacer: '+groupByCategory['Por Hacer'].length
              }
              all_data[a.value.split('*')[0]]= <><span>Observaciones: </span><span>{get_numb_max}</span> {ph} {rv} {cr}</>
              }
          })
      this.setState({all_data_info:all_data})
     }
   }
  }
 funcOpenModal = (chan,form) =>{
    this.setState({
      openModal:chan,
      db_prod:this.state.routegetThree,
      code_prod:this.state.routeget,
      form_prod:form
    })
    if (this.state.set_type_content==1) {
      this.setState({set_type_content:2})
    }else{
      this.setState({set_type_content:1})
    }
  }
funcObs = async (chan,type_user_role) =>{
    var type_user_p=null
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (this.state.data_user!=null) {
     type_user_p = jsondata.type_traza
     //type_user_p = this.state.data_user[0].type_traza
    }
    if (this.state.set_type_content_onre==type_user_role) {
      this.setState({set_type_content_onre:null})  
    }else{
      this.setState({set_type_content_onre:type_user_role})      
    }
}
render(){

  const toggleFunctionFirst = () => {
      if (this.state.collapsefuve==true) {
        this.state.collapsefuve = false;
      }else{
        this.state.collapsefuve = true;
      }
  }
  const toggleFunction = (index,res) =>{
    this.setState({collapse:{[index]:!this.state.collapse[index]}})
  }

    const optionModal = (type) => {
    this.setState({openModal:type})
  };
  const accessAccount = (password) => {
    this.setState({disabledbtn:true})
    try{
        this.setState({disabledbtn:false})
        var privateWalletEncrip = web3.eth.accounts.decrypt(this.state.walletData,password)
        this.setState({walletData:privateWalletEncrip})
        optionModal(false)
        notify("success", "Cambio exitoso", "Cuenta desbloqueada.");
    }catch(error){
        notify("danger", "No se pudo guardar", "La contraseña es incorrecta.");
        this.setState({disabledbtn:false})
    }


  };
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };

  const insertDataObservation = async () =>{
       notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
  }
  return (
    <>

      <div className="rna-wrapper">
              <NotificationAlert ref={this.state.notificationAlertRef} />
            </div>

            {/*<SimpleHeader name="Vista trace" parentName="Detalles y Observaciones" section="namas" section_two="Finalizar_Nama" section_table_form="null" />*/}
            <div className="mt-0">
              <VistaTraceNGMRV {...this.props} code={this.state.routeget} db_user={this.state.routegetThree} />
            </div>
    </>
  );
 }
}
export default ViewDetailMRV;