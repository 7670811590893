import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
import jQuery from 'jquery';

 class DetailControl extends React.Component {
  state={
  }
    async componentDidMount(){

  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
              <Col md="12" className="pb-2">
                <div style={styles.cardStyleSectionTwo}>
                <Row>
                  <div style={{paddingLeft: '12px'}}>
                    <label className="form-control-label" style={styles.labelStyleTree}>
                      7- CONTROL SANITARIO:
                    </label>
                  </div>
                    <Col md="10">
                    <div style={{display:'flex'}}>
                      <div className="form-control-label mr-1" style={styles.labelStyleTwoNextMessageReson}>
                        El firmante declara que este ganado
                      </div>
                      <input
                        style={{height: '12px',textAlign: 'justify',fontSize: '12px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.firmante_del_ganado==undefined)?(null):(this.state.firmante_del_ganado)}
                         // onChange={(e)=>{this.setState({firmante_del_ganado:e.target.value})}}
                       value={(this.props.validSwith.firmante_del_ganado==undefined)?(null):(this.props.validSwith.firmante_del_ganado)}
                       onChange={(e)=>{
                        this.props.setValFieldControl('firmante_del_ganado',e.target.value)
                      }}
                         type="text"                  
                        />
                    </div>
                  </Col>

                    <Col md="6">
                    <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{alignSelf: 'center',color: '#000000',fontSize: '12px', width: '240px',fontWeight: '600',marginRight: '0px'}}>Código de autorización</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '19px'}}                    
                         // value={(this.state.codigo_autorizacion==undefined)?((this.props.validSwith.type_change_prod==1)?('48242026'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('48242026'):(null))):(this.state.codigo_autorizacion)}
                         // onChange={(e)=>{this.setState({codigo_autorizacion:e.target.value})}}
                         value={(this.props.validSwith.codigo_autorizacion==undefined)?(null):(this.props.validSwith.codigo_autorizacion)}
                         onChange={(e)=>{
                          this.props.setValFieldControl('codigo_autorizacion',e.target.value)
                        }}
                         type="text"                  
                        />
                        </div>
                      </div>
                    </div>
                  </Col>

                    <Col md="6">
                        <span style={{fontSize:'12px',textAlign: 'justify',display:'none'}} id="inpt" ref={(ref) => this.ref=ref}>{this.props.validSwith.control_detail}</span>
                      <input
                      className="inputsOne"
                        style={{height: '12px',textAlign: 'justify',fontSize: '12px',borderRadius: '0px',width: '98%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.control_detail==undefined)?(null):(this.state.control_detail)}
                         // onChange={(e)=>{this.setState({control_detail:e.target.value})}}
                         value={(this.props.validSwith.control_detail==undefined)?(null):(this.props.validSwith.control_detail)}
                         onChange={(e)=>{
                          if (jQuery(this.ref).width()>=jQuery(e.target).width()) {
                            jQuery(e.target).next('.inputsTwo').focus();
                          }
                          this.props.setValFieldControl('control_detail',e.target.value)
                        }}
                        />
                      <input
                      className="inputsTwo"
                        style={{height: '12px',textAlign: 'justify',fontSize: '12px',borderRadius: '0px',width: '47%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.control_detail_continue==undefined)?(null):(this.state.control_detail_continue)}
                         // onChange={(e)=>{this.setState({control_detail_continue:e.target.value})}}
                         value={(this.props.validSwith.control_detail_continue==undefined)?(null):(this.props.validSwith.control_detail_continue)}
                         onChange={(e)=>{
                          
                          if (e.target.value.length==0) {
                            this.props.setValFieldControl('control_detail',this.props.validSwith.control_detail.slice(0, -2))
                            jQuery(e.target).prev('.inputsOne').focus();
                          }
                          this.props.setValFieldControl('control_detail_continue',e.target.value)
                        }}
                         type="text"                  
                        />                        
                  </Col>

                    <Col md="12">
                    <Row>
                    <Col md="3">
                      <input
                        style={{height: '12px',textAlign: 'center',fontSize: '12px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.text_oficina==undefined)?(null):(this.state.text_oficina)}
                         // onChange={(e)=>{this.setState({text_oficina:e.target.value})}}
                         value={(this.props.validSwith.text_oficina==undefined)?(null):(this.props.validSwith.text_oficina)}
                         onChange={(e)=>{
                          this.props.setValFieldControl('text_oficina',e.target.value)
                        }}                         
                         type="text"                  
                        />
                      <div className="form-control-label" style={{textAlign:'center',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px'}}>
                        Oficina
                      </div>                        
                  </Col>                  
                    <Col md="3">
                      <input
                        style={{height: '12px',textAlign: 'center',fontSize: '12px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.text_name==undefined)?(null):(this.state.text_name)}
                         // onChange={(e)=>{this.setState({text_name:e.target.value})}}
                         value={(this.props.validSwith.text_name==undefined)?(null):(this.props.validSwith.text_name)}
                         onChange={(e)=>{
                          this.props.setValFieldControl('text_name',e.target.value)
                        }}                            
                         type="text"                  
                        />
                      <div className="form-control-label" style={{textAlign:'center',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px'}}>
                        Nombre
                      </div>                         
                  </Col> 
                    <Col md="3">
                      <input
                        style={{height: '12px',textAlign: 'center',fontSize: '12px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.text_signature==undefined)?(null):(this.state.text_signature)}
                         // onChange={(e)=>{this.setState({text_signature:e.target.value})}}
                         value={(this.props.validSwith.text_signature==undefined)?(null):(this.props.validSwith.text_signature)}
                         onChange={(e)=>{
                          this.props.setValFieldControl('text_signature',e.target.value)
                        }}                            
                         type="text"                  
                        />
                      <div className="form-control-label" style={{textAlign:'center',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px'}}>
                        Firma
                      </div>                         
                  </Col> 
                    <Col md="3">
                      <div style={{border: 'solid 2px #c9edfd', textAlign: 'center',padding: '15px', borderRadius: '5px', backgroundColor: 'white'}}>Sello de la oficina</div>
                  </Col>                                     
                    </Row>
                  </Col>

                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailControl;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px',
  top: '4px',
  position: 'relative',
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  },
  cardStyleSectionTwo:{
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: '10px',
    border: 'solid 2px rgb(201, 237, 253)',
    paddingBottom: '0px'
  }
}