import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
//import { useRouter } from 'next/navigation';
import { Sidebar } from 'primereact/sidebar';
// import EditAdd from '../EditAdd';
// import InfoCultivation from '../InfoCultivation';
//import SidebarCustom from '../SidebarCustom';
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";
import { useLocation } from "react-router-dom";

import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';

import GNama from "views/pages/forms/Production/Grains/GrainNama/StartGrainNamaFormRelease.js";

const CardInfo = (props) => {
    //const router = useRouter();

    const [selectedOptions, setSelectedOptions] = useState(null);
    const [visibleRight, setVisibleRightOne] = useState(false);

    const [visibleRightCultivation, setVisibleRightCultivation] = useState(false);

    const options = [
        { name: 'Información Productor', code: 'IO', table: 'info_producer_g' },
        { name: 'Info del Cultivo', code: 'IC', table: 'info_crops_g' },
        { name: 'Información Cultivos Previos', code: 'PP', table: 'info_prev_g' }
    ];

    const header = (
        <img 
            alt="production" 
            src="/layout/images/icons/task-list.png"  
            className='w-2'
        />
    );

    const openSidebar = (code) => {
            setVisibleRightOne(true)
        // if (code === 'IO') {
        // }

        // if (code === 'IC') {
        //     setVisibleRightCultivation(true)
        // }
    }

    const selectedOptionTemplate = (option, props) => {
        if (option) {
            return (
                <div onClick={ openSidebar(option.code) }>{option.name}</div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const itemOptionTemplate = (option) => {
 
        return (
            <div className="flex align-items-center">
                <div>
                    {option.name}
                </div>
            </div>
        );
    };

    const clearDropdpwn = (visible) => {
        setSelectedOptions(null);
        visible(false);
    }
    const changeSelectOption = (val) =>{
        setVisibleRightOne(true)
        // if (window.get_chenge().set_origin=='https://api.foodchaintrazabilidad.com') {
        //     if (window.get_chenge().set_chenge==1) {
        //         console.log(val.target)
        //      //window.event_clk(val)
        //     }
        // }else{
        // }
        setSelectedOptions(val.value)
    }
    return (
        <div>
            <Card title="Información General" header={ header } >
            <Dropdown 
                style={{fontSize: '1rem',color: '#4b5563'}}
                value={selectedOptions} 
                onChange={(e) => changeSelectOption(e)} 
                options={options} 
                optionLabel="name" 
                showClear 
                placeholder="Selecciona" 
                itemTemplate={itemOptionTemplate} 
                //valueTemplate={ selectedOptionTemplate }
                dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                    }}
                className="w-full p-inputtext-sm" />
                <p className="mt-4">
                    {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                </p>
            </Card>
            <SidebarCustom
                onVisible={ visibleRight }
                onHide={ () => clearDropdpwn(setVisibleRightOne) }
                onWidth={ 650 }
                position="right"
                title={selectedOptions?.name}
            >
                <GNama
                    {...props}
                    location={useLocation()}
                    info_select={selectedOptions}
                    hide={ () => clearDropdpwn(setVisibleRightOne) }
                />
            </SidebarCustom>

        </div>
    )
}

export default CardInfo;