import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeaderKdes from "components/Headers/SimpleHeaderKdes.js";
import VegetablesNavBarKdes from "views/pages/components/VegetablesNavBarKdes.js";
import ModalLogistic from "./Modal/ModalLogisticRelease";
import { row_data_kdes } from "network/ApiAxios";
import jQuery from 'jquery';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class Elements extends React.Component {
    state={
    dataFertilizer:[],
    data_content:[]
  }
async componentDidMount(){
        this.initData()
  jQuery(".documentFormAll").remove()
}

// initData = async () =>{
//         var userdata = localStorage.getItem('user');
//         var jsondata = JSON.parse(userdata);
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         var dataFertilizer = await row_fertilizer_production(jsondata._id,gerParam,null,'create_harvest_kdes')
//         this.setState({dataFertilizer:dataFertilizer.trazaData,data_content:dataFertilizer.dataMainContent})
// }
initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");
        var dataFertilizer = await row_data_kdes(jsondata._id,gerParam,null,this.props.info_select.table)
        this.setState({dataFertilizer:dataFertilizer.trazaData,data_content:dataFertilizer.dataMainContent})
}


 //  render(){

 //  return (
 //    <>
 //      <VegetablesNavBarKdes getParams={this.props.location.search} type_traza={this.props.putTraza}/>
 //      <SimpleHeaderKdes name="Ingresar datos Cosecha" parentName="Cosecha" section="fuits_vegetable" section_two="Cosecha" section_table_form="create_harvest_kdes" />
 //      <Container className="mt-1" fluid>
 //        <ModalLogistic 
 //        sendInfoProps={this.state} 
 //        refreshData={this.initData} 
 //        setParamsUrl={this.props.location.search} 
 //        tableCall="create_harvest_kdes" 
 //        titleCall="Cosecha Kdes" 
 //        />
 //      </Container>
 //    </>
 //  );
 // }
  render(){

  return (
    <>
      <div className="mt-6 container-fluid">
      {(this.state.data_content.length!=0)?(
            <ModalLogistic 
              {...this.props}
              sendInfoProps={this.state}
              refreshData={this.initData}
              setParamsUrl={this.props.location}
              tableCall={this.props.info_select.table}
              titleCall={this.props.info_select.name} 
            />
        ):(null)}
      </div>
    </>
  );
 }
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()

  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    this.setState({set_traza:dataPermission.type_traza})
    var data_type_t = dataPermission.type_traza.indexOf(this.props.info_select.typeRole)
        // if (data_type_area==-1) {
        //   this.setState({get_permission:false})
        //   return;
        // }
        if (data_type_t==-1 || dataPermission.type_traza==null) {
          this.setState({get_permission:false})
          return;
        }
        if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
          this.setState({get_permission:false})
          return;
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission[this.props.info_select.permission_column]==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} putTraza={this.state.set_traza}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

//export default Elements;
