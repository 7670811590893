import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
 class DetailAutorization extends React.Component {
  state={
  }
    async componentDidMount(){
        
  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
              <Col md="12" className="pb-2">
                <div style={styles.cardStyleSectionTwo}>
                <Row>
                  <Col md="12">
                    <label className="form-control-label" style={styles.labelStyleTree}>
                      10- AUTORIZACIÓN POLICIAL
                    </label>
                  </Col>
                    <Col md="2" className="mt-3">
                      <div style={{border: 'solid 2px #c9edfd', textAlign: 'center',padding: '50px', borderRadius: '5px', backgroundColor: 'white', width: '140px', height: '140px',margin: 'auto'}}></div>
                  </Col>
                    <Col md="4">                    
                    <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                        <div className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '100%',fontWeight: '600',marginRight: '9px'}}>Dia/Mes/Año</div>                        
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '120px',fontWeight: '600',marginRight: '9px'}}>Fecha</label>

                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '0px'}}                    
                         // value={(this.state.date_autoriza==undefined)?((this.props.validSwith.type_change_prod==1)?('2021-12-06'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('2021-12-08'):(null))):(this.state.date_autoriza)}
                         // onChange={(e)=>{this.setState({date_autoriza:e.target.value})}}
                                value={(this.props.validSwith.date_autoriza==undefined)?(null):(this.props.validSwith.date_autoriza)}
                                 onChange={(e)=>{
                                  this.props.setValFieldAutorization('date_autoriza',e.target.value)
                                }}                         
                         type="date"                  
                        />
                        </div>
                    </div>
                    <div style={{alignSelf:'center',marginLeft: '42%',width:'58%',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '50%',fontWeight: '600',marginRight: '9px'}}>Hora</div>
                        <div style={{display:'flex'}}>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '0px'}}                    
                         // value={(this.state.hora_autoriza==undefined)?((this.props.validSwith.type_change_prod==1)?('19:30:00'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.hora_autoriza)}
                         // onChange={(e)=>{this.setState({hora_autoriza:e.target.value})}}
                                value={(this.props.validSwith.hora_autoriza==undefined)?(null):(this.props.validSwith.hora_autoriza)}
                                 onChange={(e)=>{
                                  this.props.setValFieldAutorization('hora_autoriza',e.target.value)
                                }}                             
                         type="time"                  
                        />
                        </div>
                      </div>
                    </div>                    
                  </Col>

                    <Col md="6">
                    <Row>
                      <Col md="12" className="pt-3">
                        <input
                          style={{height: '15px',textAlign: 'justify',fontSize: '15px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                           // value={(this.state.cargo_nombre_autoriza==undefined)?(null):(this.state.cargo_nombre_autoriza)}
                           // onChange={(e)=>{this.setState({cargo_nombre_autoriza:e.target.value})}}
                                value={(this.props.validSwith.cargo_nombre_autoriza==undefined)?(null):(this.props.validSwith.cargo_nombre_autoriza)}
                                 onChange={(e)=>{
                                  this.props.setValFieldAutorization('cargo_nombre_autoriza',e.target.value)
                                }}
                           type="text"                  
                          />
                        <div className="form-control-label mr-1" style={{textAlign:'end',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                          Cargo y nombre del funcionario autorizante
                        </div>
                    </Col>
                    <Col md="12" className="pt-5">
                      <input
                        style={{height: '15px',textAlign: 'justify',fontSize: '15px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.firma_autoriza==undefined)?(null):(this.state.firma_autoriza)}
                         // onChange={(e)=>{this.setState({firma_autoriza:e.target.value})}}
                                value={(this.props.validSwith.firma_autoriza==undefined)?(null):(this.props.validSwith.firma_autoriza)}
                                 onChange={(e)=>{
                                  this.props.setValFieldAutorization('firma_autoriza',e.target.value)
                                }}
                         type="text"                  
                        />
                      <div className="form-control-label mr-1" style={{textAlign:'end',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                        Firma
                      </div>
                  </Col>                    
                    </Row>
                  </Col>                

                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailAutorization;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  //width: '300px',
  fontWeight: '500',
  marginRight: '0px',
  top: '4px',
  position: 'relative',
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  cardStyleSectionTwo:{
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: '10px',
    border: 'solid 2px rgb(201, 237, 253)',
  }
}