import React, {useState} from 'react';
import {
  Navbar,
  NavbarBrand,
  NavLink,
  NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { NavLink as ComponentLink } from "react-router-dom";
import {UserContext} from "layouts/store";
import jQuery from "jquery";

  class GrainNamaNavBar extends React.Component {
    static contextType = UserContext
    state ={
      modal:false,
      color:'transparent',
      paramUrlLote:false,
      information_nama_g:'none',
      production_nama_g:'none',
      hading_nama_g:'none',
      harvest_nama_g:'none',
      information_nama_end:'none',
      document_nama_g:'none',
      paramUrlCode:null
    }
    componentDidMount(){
      jQuery(".documentFormAll").hide()
      jQuery("#insertDocumentShow").val('none')
      if (this.props.getParams.indexOf('lote=')!=-1) {
      this.setState({paramUrlLote:true})
      }
       var gerParam = new URLSearchParams(this.props.getParams).get("code");
      this.setState({paramUrlCode:gerParam})
    }
    componentDidUpdate(){
      if (jQuery('.section_header_prod').text()=='Información General') {
        jQuery(".section_general").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Gestión de cultivos') {
        jQuery(".section_production").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Manejo Fitosanitario') {
        jQuery(".section_fitosanitario").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Cosecha') {
        jQuery(".section_cosecha").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Documentos') {
        jQuery(".section_documentos").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Finalizar gestión de cultivos') {
        jQuery(".section_prod_end").css('color','#012b5d')
      }

    }
  render(){
      const setinfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    var getPermission = this.context.valueGlobal
    if (getPermission.user_id_connection==null) {
      jQuery("#insertIdUser").val(getPermission.user_id)
    }else{
      jQuery("#insertIdUser").val(getPermission.user_id_connection)
    }
      if (getPermission.role==1) {
        jQuery("#insertDocumentShow").val('block')
        jQuery(".documentFormAll").show()
            this.setState({
              information_nama_g:'block',
              production_nama_g:'block',
              hading_nama_g:'block',
              harvest_nama_g:'block',
              information_nama_end:'block',
              document_nama_g:'block'
            })
      }else{
        if (getPermission.type=='options') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              information_nama_g:(getPermission.information_nama_g==0)?'none':'block',
              production_nama_g:(getPermission.production_nama_g==0)?'none':'block',
              hading_nama_g:(getPermission.hading_nama_g==0)?'none':'block',
              harvest_nama_g:(getPermission.harvest_nama_g==0)?'none':'block',
              document_nama_g:(getPermission.document_nama_g==0)?'none':'block',
              
              information_nama_end:'none',
            })
        }
        if (getPermission.type=='admin') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              information_nama_g:'block',
              production_nama_g:'block',
              hading_nama_g:'block',
              harvest_nama_g:'block',
              information_nama_end:'none',
            })
        }

      }
        if (this.context.valueGlobal.length!=0) {
        this.setState({setinfoState:true})
        }
      }    
    const openToggler = (valOpen) =>{
      if (this.state.modal==true) {
       this.setState({modal:false,color:'transparent'})
      }else{
       this.setState({modal:true,color:'#2d597f'})
      }
    }
  return (
    <>
      <input type="hidden" id="insertIdUser" />
      <input type="hidden" id="insertDocumentShow" />
      <div>
      {setinfoInit()}
          <Navbar style={{backgroundColor:'#90c226', height: '50px'}} expand="lg" light>
            <NavbarToggler onClick={() => {openToggler(true)}} />
            <Collapse isOpen={this.state.modal} navbar style={{zIndex: '10', backgroundColor:this.state.color}}>
              <Nav className="me-auto" navbar style={{fontSize: '14px'}}>

                  {(this.props.getParams=="")
                  ?
              (
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700' }}
                  to="/admin/info_producer_g"
                >
                  Información General
                </ComponentLink>
              )
                  :
                  (<>
              <UncontrolledDropdown inNavbar nav style={{display: this.state.information_nama_g}}>
                <DropdownToggle className="section_general" style={{ fontWeight:'700', color:"white" }} caret nav>
                  Información General
                </DropdownToggle>
                <DropdownMenu right style={{left: '0px'}}>
                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink  style={{ fontWeight:'700' }} to={"/admin/info_producer_g"+this.props.getParams}>
                        Información Productor
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/info_crops_g"+this.props.getParams}>
                        Info del Cultivo
                      </ComponentLink>
                    </div>
                  </DropdownItem>

                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/info_previous_g"+this.props.getParams}>
                        Gestión de cultivos previa
                      </ComponentLink>
                    </div>
                  </DropdownItem>

{/*                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/info_financing_g"+this.props.getParams}>
                        Financiamiento
                      </ComponentLink>
                    </div>
                  </DropdownItem>*/}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown inNavbar nav style={{display: this.state.production_nama_g}}>
                <DropdownToggle className="section_production" style={{ fontWeight:'700', color:"white" }} caret nav>
                  Producción
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/production_land_preparation_g"+this.props.getParams}>
                        Preparación de tierra
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/production_irrigation_g"+this.props.getParams}>
                        Riego
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/production_seed_g"+this.props.getParams}>
                        Semilla
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/production_fertilizer_g"+this.props.getParams}>
                        Fertilización/Agroquímicos
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/production_sowing_g"+this.props.getParams}>
                        Siembra
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/production_producer_monitoring_g"+this.props.getParams}>
                        Monitoreo productor
                      </ComponentLink>
                    </div>
                  </DropdownItem>

                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/production_info_weather"+this.props.getParams}>
                        Clima
                      </ComponentLink>
                    </div>
                  </DropdownItem>

                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/production_info_satelite"+this.props.getParams}>
                        Satelite
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown inNavbar nav style={{display: this.state.hading_nama_g}}>
                <DropdownToggle className="section_fitosanitario" style={{ fontWeight:'700', color:"white" }} caret nav>
                  Manejo Fitosanitario
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/hading_fert_agro"+this.props.getParams}>
                        Agroquimicos
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/hading_info_weeds"+this.props.getParams}>
                        Malezas
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/hading_info_plague"+this.props.getParams}>
                        Plagas
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/hading_info_diseases"+this.props.getParams}>
                        Enfermedades
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown inNavbar nav style={{display: this.state.harvest_nama_g}}>
                <DropdownToggle className="section_cosecha" style={{ fontWeight:'700', color:"white" }} caret nav>
                  Cosecha
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/harvest_info_harvest"+this.props.getParams}>
                        Cosecha
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/harvest_info_transport"+this.props.getParams}>
                        Transporte
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/harvest_info_pos_harvest"+this.props.getParams}>
                        Pos-Cosecha
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

{/*              <UncontrolledDropdown inNavbar nav style={{display: this.state.fridge_m}}>
                <DropdownToggle style={{ fontWeight:'700', color:"white" }} caret nav>
                  MRV
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/grains-nama-harvest"+this.props.getParams}>
                        Reporte tecnicos
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grains-nama-transport"+this.props.getParams}>
                        Reporte Tecnico MIDA
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/nama-reporte-gei"+this.props.getParams}>
                        Reporte GEI
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grains-nama-postharvest"+this.props.getParams}>
                        Reporte Mi Ambiente
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grains-nama-postharvest"+this.props.getParams}>
                        Reporte Verificadora
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grains-nama-postharvest"+this.props.getParams}>
                        Reporte FLAR
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown inNavbar nav style={{display: this.state.fridge_m}}>
                <DropdownToggle style={{ fontWeight:'700', color:"white" }} caret nav>
                  Informes
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/grains-nama-harvest"+this.props.getParams}>
                        Informe tecnico IICA
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grains-nama-transport"+this.props.getParams}>
                        Informe Foodchain
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/nama-reporte-gei"+this.props.getParams}>
                        Informe Itrium
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grains-nama-postharvest"+this.props.getParams}>
                        Informe Univ Chile
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              */}
                <ComponentLink className="nav-link section_documentos"
                  style={{ color:"white", fontWeight:'700',display: this.state.document_nama_g }}
                  to={"/admin/grains-nama-documents"+this.props.getParams}
                >
                  Documentos
                </ComponentLink>
              
              
                <ComponentLink className="nav-link section_prod_end"
                  style={{ color:"white", fontWeight:'700',display: this.state.information_nama_end }}
                  to={"/admin/grains-nama-end"+this.props.getParams}
                >
                  Finalizar gestión de cultivos
                </ComponentLink>
        </>)}

            </Nav>
          </Collapse>
        </Navbar>
      </div>        
    </>
  );
 }
}

export default GrainNamaNavBar;
