import React, {Component} from "react";
import {
  //Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeaderProd from "components/Headers/SimpleHeaderProd.js";
import GrainNamaNavBar from "views/pages/components/GrainNamaNavBar.js";
import { row_documents_list_grains, printPdf, get_permission, save_document_production } from "network/ApiAxios";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
//import print from 'print-js'
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import PropTypes from 'prop-types';
import jQuery from 'jquery'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';

import { DataTable } from 'primereact/datatable';
import { Panel } from 'primereact/panel';

class Documents extends Component {
state={
  data:[],
  create_plant_product:[],
  create_plant_sample:[],
  create_plant_treatment:[],
  create_plant_weight_product:[],
  production_fertilizer:[],
  transport_cosecha:[],
  transport_vehicule_cosecha:[],
  set_content:[],
  field_params:{'file_field':[{name:'',filed:null}]},
  data_select:[],
  data_select_selected:'none',
  btnd:false,
  data_doc:[]
}
async componentDidMount(){
  this.initData()
}
initData=async()=>{
   var dataArr = 
  [  {'Información General':{'info_producer_g':'Información Productor','info_crops_g':'Info del Cultivo','info_prev_g':'Información Previa','info_financing_g':'Financiamiento'}},
     {'Producción':{'production_land_preparation_g':'Preparación de tierra','production_irrigation_g':'Riego','production_seed_g':'Semilla','production_fertilizer_g':'Fertilización','production_sowing_g':'Siembra','production_sowing_transplant':'Transplante','production_producer_monitoring_g':'Monitoreo Productor','production_info_satelite':'Información Satelite'}},
     {'Manejo Fitosanitario':{'hading_fert_agro':'Agroquimico','hading_info_weeds':'Información de malezas','hading_inventory_weeds':'Inventario de malezas','hading_info_plague':'Información de Plagas','hading_inventory_plague':'Inventario de Plagas','hading_info_diseases':'Información de enferedades'}},
     {'Cosecha':{'harvest_info_harvest':'Información de cosecha','harvest_info_machinery':'Calibración de maquinaria','harvest_info_transport':'Información de transporte','harvest_info_pos_harvest':'Información de Pos-Cosecha'}},
  ];
  this.setState({set_content:dataArr})
  this.setState({data_select:['info_producer_g*Información Productor','info_crops_g*Info del Cultivo','info_prev_g*Información Previa','production_land_preparation_g*Preparación de tierra','production_irrigation_g*Riego','production_seed_g*Semilla','production_fertilizer_g*Fertilización','production_sowing_g*Siembra','production_sowing_transplant*Transplante','production_producer_monitoring_g*Monitoreo Productor','production_info_satelite*Información Satelite','hading_fert_agro*Agroquimico','hading_info_weeds*Información de malezas','hading_inventory_weeds*Inventario de malezas','hading_info_plague*Información de Plagas','hading_inventory_plague*Inventario de Plagas','hading_info_diseases*Información de enferedades','harvest_info_harvest*Información de cosecha','harvest_info_machinery*Calibración de maquinaria','harvest_info_transport*Información de transporte','harvest_info_pos_harvest*Información de Pos-Cosecha']})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("code");
    var data = await row_documents_list_grains(jsondata._id,gerParam,'.info_producer_g')
    
    //console.log()
    this.setState({data_doc:this.getInfo(dataArr,data)})
}
returnDataIter = (data) =>{
  return data
  // var setData = []
  // data.map((a,b)=>{
  //   setData.push(a)
  //   //return a
  // })
  // //console.log()
  // return {...setData}
}

getContentInfo = (table_name, data, tabled, title_tabled) =>{
  var content=[]
  data.map((a,b)=>{
  if (a.indexOf('||')==-1) {
    if (a!='') {
      if (a.indexOf(tabled)!=-1) {
          content.push({
                                              name: table_name,
                                              view: config.URL_PRODUCTION+"/storage/file_documents/"+a,
                                              options: "/storage/file_documents/"+a,
                                              last: String(a).split('/')[String(a).split('/').length-1],
                                              last_two: String(a).split('.').pop()
                                            })
      }
    }
  }

  })
      //console.log(this.returnDataIter(content))
  return {[title_tabled]: [...content] }
}

getInfo = (info_content,data_promise) =>{
  var full_data = []
  var full_data_two = []
  if (data_promise!=undefined) {
    info_content.map((aIn,iIn)=>{
      var getData = Object.keys(data_promise).map((a,b)=>{
         if (Object.values(aIn)[0][a]!=undefined) {
           var get_table = Object.keys(Object.values(aIn)[0]).find((element) => element == a)
           var data_logi=[]
           if (typeof data_promise[a] == 'string') {
             data_logi=data_promise[a].split('|')
           }else{
             data_logi = Object.values(data_promise[a]).join("|").split("|").filter((a,b)=>a!='|')
           }
           if ( this.getContentInfo(Object.values(aIn)[0][a],data_logi,get_table, Object.keys(aIn)[0])[Object.keys(aIn)[0]].length!=0) {
            return this.getContentInfo(Object.values(aIn)[0][a],data_logi,get_table, Object.keys(aIn)[0])[Object.keys(aIn)[0]]
           }
            //full_data.push(this.getContentInfo(Object.values(aIn)[0][a],data_logi,a))
            //full_data_two.push(this.getContentInfo(Object.values(aIn)[0][a],data_logi,a))
         }
      })
        full_data.push({title:Object.keys(aIn)[0],data: getData.filter(Boolean) })
    })
  }
  //console.log( full_data )
  return full_data
}
    actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button 
                    icon="pi pi-eye" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    tooltip="Ver archivo" 
                    onClick={()=>{
                      window.open(rowData.view, '_blank');
                      //window.location.href=config.URL_PRODUCTION+"/storage/file_documents/"+a
                      //console.log(rowData)
                    }}
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />

                <Button 
                    icon="pi pi-cloud-download" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    tooltip="Descargar archivo"
                    onClick={()=>{
                      //window.open(rowData.view, '_blank');
                      this.download_file(rowData.options,rowData.last )
                    }}
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                />

                <Button 
                    icon="pi pi-print" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    tooltip="Imprimir" 
                    onClick={()=>{
                      //window.open(rowData.view, '_blank');
                      this.printer_pdf(rowData.options,rowData.last_two )
                    }}
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                    
                />
            </>
        );
    };
    header = (data)=> {
     return <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 className="m-0" style={{ color: '#002a5c' }}>
                    {data}
                </h5>
            </div>
        }
setContent = (content) => {
  var put_content = []
  if (content!=undefined) {

    for (var i = 0; i < content.length; i++) {
      for (var j = 0; j < content.length; j++) {
        if (content[i].data[j]!=undefined) {
            put_content.push(
             <div className="grid crud-demo">
                <div className="col-12">
                  <Panel header={content[i].title+' - '+content[i].data[j][0].name} toggleable collapsed={true}>
                      <DataTable
                          value={content[i].data[j]}
                          dataKey="id"
                          paginator
                          rows={10}
                          rowsPerPageOptions={[5, 10, 25]}
                          className="datatable-responsive"
                          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                          //globalFilter={globalFilter}
                          emptyMessage="Sin registros encontrados."
                          //header={this.header(content[i].title)}
                          responsiveLayout="scroll"
                      >
                          <Column field="name" header="Actividad" ></Column>
                          <Column field="last" header="Documento" sortable ></Column>
                          <Column body={this.actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                        {/*                      
                          <Column field="code" header="actividad" sortable></Column>
                          <Column field="create" header="Documento" sortable ></Column>
                          <Column field="create" header=" Opciones" sortable></Column>
                          <Column body={this.getInfo} headerStyle={{ minWidth: '10rem' }}></Column>
                        */}
                      </DataTable>
                  </Panel>
                </div>
              </div>
              )
        }
      }
                    

          // <Card key={i}>
          //   <CardHeader className="border-0">
          //     <Row>
          //       <Col xs="6">
          //         <h3 className="mb-0">{Object.keys(content[i])}</h3>
          //       </Col>
          //     </Row>
          //   </CardHeader>
          //   <Table className="align-items-center table-flush" responsive>
          //     <thead className="thead-light">
          //       <tr>
          //         <th>Creación</th>
          //         <th>Formulario</th>
          //         <th>Documento</th>
          //         <th>Opciones</th>
          //       </tr>
          //     </thead>
          //     <tbody>
          //     {this.getInfo(Object.values(content[i]))}
          //     </tbody>
          //   </Table>
          // </Card>
    }
  }
  return put_content
}

 printFile = (urlFile) => {
       //var Pagelink = urlFile;
        var Pagelink = "about:blank";
        var pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(this.ImagetoPrint(urlFile));
        pwa.document.close();
  };
   ImagetoPrint = (source)=>{
           return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<img src='" + source + "' /></body></html>";
  }
   convertBase64ToFile = (base64String, fileName) => {
     let arr = base64String.split(',');
     let mime = arr[0].match(/:(.*?);/)[1];
     let bstr = atob(arr[1]);
     let n = bstr.length;
     let uint8Array = new Uint8Array(n);
     while (n--) {
        uint8Array[n] = bstr.charCodeAt(n);
     }
     let file = new File([uint8Array], fileName, { type: mime });
     return file;
}
 printer_pdf = async (url,fileExtend) => {
   var printer = await printPdf(url)
  if (printer) {
    if (url.indexOf(".pdf") == -1) {
      this.printFile('data:image/'+fileExtend+';base64,'+printer)
    }else{

        let byteCharacters = atob(printer);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], {type: 'application/pdf;base64'});
    let fileURL = URL.createObjectURL(file);
    const newWindow = window.open(fileURL);
    newWindow.print();
    //newWindow.close();
     }
  }
}

   download_file = async (url,fileName)=>{
   var printer = await printPdf(url)
   if (printer) {
    if (fileName.indexOf(".pdf") == -1) {
      var baseUrl = 'data:image/'+fileName+';base64,'+printer;
    }else{
      var baseUrl = 'data:application/'+fileName+';base64,'+printer;
  }

   var file = this.convertBase64ToFile(baseUrl, fileName);
   saveAs(file, fileName);
   
   }

  }
    setFieldValueFiles = (value, field) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
    }
 contentFilesShow = (data,fields) => {
  var content = []
  if (data!='') {
    if (data!=undefined) {
      if (data.length>0) {
        if (typeof data=='object') {
          data.map((a,b)=>{
           if (a.name!='') {      
             if (a.filed==null) {
               var url_selected = config.URL_PRODUCTION+"/storage/file_documents/"+a.name
               var url_selected_two = "/storage/file_documents/"+a.name
                     content[b]=<Row> <Col md="1" style={{textAlign: 'center'}}><button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.deleteImgState(b,fields,a,url_selected_two)}}>
                     <i className="fas fa-eraser" style={{color:'#00234d'}}></i>
                     </button></Col>
                     <Col md="11"><Button data-url={url_selected} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(a.name).split('/')[1]}</Button></Col></Row>
             }else{
                     content[b]=<Row> <Col md="1" style={{textAlign: 'center'}}><button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.deleteImgState(b,fields,a,url_selected_two)}}>
                     <i className="fas fa-eraser" style={{color:'#00234d'}}></i>
                     </button></Col>
                     <Col md="11"><label className="form-control-label" htmlFor="example3cols2Input">{a.name}</label></Col></Row>
             }
           }
          })
        }
       }
    }

  }
  return content
}
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          // var data_s = await delete_file_prod(this.state.idT,'grain_info_crops_t',field,data_clean.join('|'),url_selected)
          // if (data_s.message=='exito') {
          //   this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
          //    //var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
          // }
         }
      this.setState({field_params: item })
       }
    }
    saveDocuments= async ()=>{
      this.setState({btnd:true})
      if (this.state.data_select_selected=='none') {
        alert('Seleccione una actividad')
        this.setState({btnd:false})
      }else{
        //await save_document_production()
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var paramCode=new URLSearchParams(window.location.search).get('code')
        var dataReturn = await save_document_production(paramCode,this.state.data_select_selected,this.state.field_params.file_field,jsondata._id)
        if (dataReturn=='exito') {
          this.setState({btnd:false})
          this.setState({field_params:{'file_field':[{name:'',filed:null}]}})
          this.initData()
        }
      }
    }
  render(){
  return (
    <>
{/*      <GrainNamaNavBar getParams={this.props.location.search}/>  
      <SimpleHeaderProd name="Documentos" parentName="Documentos" section="namas" section_two="Documentos" section_table_form="null" />*/}
      <div className="mt-1 container-fluid">

      <Col md={12}>
      <Card style={{padding: 5,margin: 0, borderRadius: '5px'}}><Row>
        <Col md={3} 
        //style={{textAlign:'center'}}
        >
          <FormGroup style={{marginBottom: '0px'}}>
              <Input
                onChange={(e) => {this.setFieldValueFiles(e.target.files, 'file_field')}}
                type="file" 
                multiple
                id="file_field"
                style={{display:'none'}} />
              <button 
                //label=""
                size="small"
                className='button-color-fod-blue'
                style={{width: '100%'}}
                style={{borderRadius: '5px',padding: '8px'}}
                data-id={'file_field'}
                onClick={(e)=>{
                  console.log(e.target)
                  jQuery('#'+e.target.getAttribute('data-id')).click()
                }} >
                + Añadir Documentos
              </button>
          </FormGroup>
        </Col>
        <Col md={3} style={{textAlign:'center'}}>
                  <select 
                  className="form-control"
                  value={this.state.data_select_selected} 
                  onChange={e => this.setState({data_select_selected:e.target.value}) }
                  style={{fontSize: '1rem',color: '#4b5563'}}
                  //style={{border: '1px solid', marginLeft: '5px', borderRadius: '2px', padding: '5px',backgroundColor: 'transparent'}} 
                  >
                    <option value="none">Seleccione</option>
                   {  this.state.data_select.map((value,index)=>{
                       return <option value={value.split('*')[0]}>{value.split('*')[1]}</option>
                      })
                    }
                  </select>
        </Col>
        <Col md={2} style={{textAlign:'center'}}>
                {(this.state.field_params['file_field'].length>1)?(
                    <button 
                      className="btn" 
                      style={{border: 'solid 0px',marginLeft: '5px',borderRadius: '2px',padding: '5px'}}
                      data-id={'file_field'}
                      disabled={this.state.btnd}
                      onClick={(e)=>{
                        this.saveDocuments()
                      }}>Guardar archivo(s)
                    </button>
                  ):(null)}
        </Col>
      </Row></Card>
        {this.contentFilesShow(this.state.field_params['file_field'],'file_field')}
        <Col md={12}><div className="percent_load"></div></Col>
      </Col>
      <br />
      {this.setContent(this.state.data_doc)}
      </div>
    </>
  );
 }
}
Documents.propTypes = {
  name: PropTypes.string
};
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
      this.context.getAffiliates()
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('P')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.document_nama_g==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }

render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Documents {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

//export default Documents;
