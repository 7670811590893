import React, {useState} from 'react';
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Button } from 'primereact/button';
import jQuery from 'jquery';
import SimpleHeaderProd from "components/Headers/SimpleHeaderProd.js";
import GrainNamaNavBar from "views/pages/components/GrainNamaNavBar.js";
import FinalNama from "views/pages/forms/Production_FV/FV/GrainNama/FinalNama/FinalNamaGrain";
import { get_data_row_admin_grain_vt,generate_trazabilidad_grain, get_data_row_admin_grain,save_signature_mrv,get_permission,save_signature_production } from "network/ApiAxios";
import { generateChain } from "network/GenerateTokenChain";
import { NavLink } from "react-router-dom";
import $ from 'jquery'
import QRCode from "react-qr-code";
import {UserContext} from "layouts/store";
import './style.css';
import Web3 from 'web3';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import buffer from 'buffer';
import jsPDF from 'jspdf';
import Check from "assets/img/icons/check.png";
import SignatureCanvas from 'react-signature-canvas';
import { Panel } from 'primereact/panel';

var web3 = new Web3('https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/')

var tituloGlobal=[];
export  class ReportProductionFinal extends React.Component {
  state={
    collapse:[],
    displayCard:"none",
    dataQr:"Sin datos",
    loading:'Cargando...',
    data_user_ad:null,
    collapsefuve:true,
    idTraza:null,
    codigoTrza:null,
    sendOpenModal:false,
    routeget: new URLSearchParams(window.location.search).get("code"),
    routegetTwo: new URLSearchParams(window.location.search).get("up"),
    loadingupload:'none',
    sigDrawn:false,
    asigSigDrawn:false,
    btnend:'block',
    'info_producer_vf_gc':'none',
    fordisplay:{},
    walletData:null,
    openModal:false,
    disabledbtn:false,
    title_end:'valid'
  }
  async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var dataPermission = await get_permission(jsondata._id)
    var codigoDB =  new URLSearchParams(window.location.search).get("db")
    var getParamCode = new URLSearchParams(window.location.search).get("code");
    if (dataPermission[0].asigned_signature==1) {
      this.setState({asigSigDrawn:true})
    }else{
      this.setState({asigSigDrawn:false})
    }
    var dataRow = await get_data_row_admin_grain_vt(getParamCode,codigoDB,'.info_producer_vf_gc')
    var get_data_saved = dataRow.data.data_mvr.filter((a,b)=> a.monitor_type=='VA' && a.mvr_id==jsondata._id)
    if (get_data_saved[0]==undefined) {
      this.setState({title_end:'valid'})
    }else{    
      if (get_data_saved[0].nro_autorization!=null || get_data_saved[0].nro_autorization!='' || get_data_saved[0].nro_autorization!='none') {
        this.setState({title_end:'checked'})
      }else{
        this.setState({title_end:'valid'})
      }
    }
    this.setState({data_user_ad:jsondata.type_traza})
    this.setState({get_data_user:dataPermission[0]})
    var status='';
    var walletdata = localStorage.getItem('user_wallet');
    var jsondatawallet = JSON.parse(walletdata);
    this.setState({walletData:jsondatawallet})
      var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
      var dataRow = await get_data_row_admin_grain(jsondata._id,this.state.routeget,codigoTrazaTwo,codigoDB,'prod_fv_gc')
      Object.entries(dataRow['end_data_grain']).map((a,b)=>{
        var name_nama = null
        if(a[0]=='info_producer_vf_gc'){
          this.setState({info_producer_vf_gc:(a[1].length==0)?'none':'flex'})
          name_nama = 'Información Productor'
        }
        if(a[0]=='info_crops_vf_gc'){
          name_nama = 'Info del Cultivo'
        }
        if(a[0]=='info_prev_vf_gc'){
          name_nama = 'Gestión de cultivos Previa'
        }
        if(a[0]=='production_land_preparation_vf_gc'){
          name_nama = 'Preparación de tierra'
        }
        if(a[0]=='production_irrigation_vf_gc'){
          name_nama = 'Riego'
        }
        if(a[0]=='production_seed_vf_gc'){
          name_nama = 'Semilla'
        }
        if(a[0]=='production_fertilizer_vf_gc'){
          name_nama = 'Fertilización/Agroquímicos'
        }
        if(a[0]=='production_sowing_vf_gc'){
          name_nama = 'Siembra'
        }
        if(a[0]=='production_sowing_transplant_vf_gc'){
          name_nama = 'Transplante'
        }
        if(a[0]=='production_producer_monitoring_vf_gc'){
          name_nama = 'Monitoreo Productor'
        }
        if(a[0]=='production_info_satelite_vf_gc'){
          name_nama = 'Satelite'
        }
        if(a[0]=='hading_fert_agro_vf_gc'){
          name_nama = 'Información de Agroquimicos'
        }
        if(a[0]=='hading_info_weeds_vf_gc'){
          name_nama = 'Información de malezas'
        }
        if(a[0]=='hading_inventory_weeds_vf_gc'){
          name_nama = 'Inventario de malezas'
        }
        if(a[0]=='hading_info_plague_vf_gc'){
          name_nama = 'Información de Plagas'
        }
        if(a[0]=='hading_inventory_plague_vf_gc'){
          name_nama = 'Inventario de Plagas'
        }
        if(a[0]=='hading_info_diseases_vf_gc'){
          name_nama = 'Información de enferedades'
        }
        if(a[0]=='harvest_info_harvest_vf_gc'){
          name_nama = 'Información de cosecha'
        }
        if(a[0]=='harvest_info_machinery_vf_gc'){
          name_nama = 'Calibración de maquinaria'
        }
        if(a[0]=='harvest_info_transport_vf_gc'){
          name_nama = 'Información de transporte'
        }
        if(a[0]=='harvest_info_pos_harvest_vf_gc'){
          name_nama = 'Información de Pos-Cosecha'
        }

        if(a[0]=='commercialization_market_vf_gc'){
          name_nama = 'Mercado Nacional'
        }
        if(a[0]=='commercialization_export_vf_gc'){
          name_nama = 'Exportación'
        }
        if (a[0]!='production_info_satelite_ndre' && a[0]!='production_info_satelite_ndmi' && a[0]!='production_info_satelite_ndhs' && a[0]!='production_info_satelite') {
          tituloGlobal[b] = {Titulos:name_nama,fordisplay: a[0], Componente:<FinalNama 
            title={name_nama} 
            table_ex={a[0]} 
            getData={this.getDataForqr} 
            putDataAll={a[1]} 
            dbCode={this.props.location?.state} 
            fullData={dataRow.end_data_grain} 
            fullDataSatelite={dataRow.satelite} />}
        }

        this.setState({fordisplay:{...this.state.fordisplay,[a[0]]:(a[1].length==0)?'none':'block'} })
      })

  }
  componentWillMount(){
    tituloGlobal = []
  }
  getDataForqr = (data) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (data==null) {
      this.setState({
        loading:'Sin Gestión de cultivos.'
      })
    }else{
      if (this.state.routeget==null || this.state.routeget==undefined) {
        var codigo_set = 'FCTGN-'+jsondata._id+data.id
      }else{
        var codigo_set = this.state.routeget
      }
      this.setState({
        displayCard:"block",
        dataQr:"http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code="+codigo_set+"&db="+jsondata.database,
        loading:null,
        idTraza:data.id,
        codigoTrza:codigo_set,
      })
    }
  }
  printer_pdf = async () => {
    window.scrollTo({ top:0, left:0, behavior: "instant"})
    var component = await document.querySelector(".contentBlockChain").innerHTML
    var divContents = component
    window.html2pdf().from(divContents).toPdf().get('pdf').then(function (pdf) {
    window.open(pdf.output('bloburl'), '_blank');
    });
  }
//  printer_pdf = async () => {
//              await jQuery('.collapseIdewntifier').removeClass('collapse')
//              var counterCollapse=0
//              jQuery('.collapseIdewntifier').map((a,b)=>{
//               if (jQuery(b).hasClass('collapse')) {
//                 counterCollapse=counterCollapse
//                 counterCollapse++
//               }
//              })

//              var component = document.querySelector(".contentBlockChain").innerHTML
//              var divContents = component

//             var doc = new jsPDF({
//               orientation: 'portrait' ,
//               unit: 'px',
//               format: 'A3',
//             });
//             doc.setFont('Inter-Regular', 'normal');
//             this.setState({btnd:true})
//             var thisState = this
//             doc.html(divContents, {
//               async callback(doc) {
//                 var get_blob = await doc.output('bloburl')
//                 window.open(get_blob,'_blank')
//                 thisState.setState({btnd:false})
//                 jQuery('.collapseIdewntifier').addClass('collapse')
//               },
//                 x: 0,
//                 y: 0,
//                 width: 640,
//                 windowWidth: 1100
//             });
// }

    pushSection = (components,element) => {
      console.log(this.state.get_data_user)
        var dataPermission = this.state.get_data_user
        var date = new Date()
        var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
        var completeDateBefore = completeDateAfter.split('/')
        var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
        var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        this.setState({
          monitor_type:jsondata.type_traza,
          // entidad:dataPermission.empresa,
          // name_mvr:dataPermission.name,
          monitor_number:'',
          monitor_date:completeDate,
          init_time:timeString,
          monitor_last_date:completeDate,
          last_time:timeString,
          signature:'',
          nro_autorization:'',
         })
        this.setState({sendOpenModal:!this.state.sendOpenModal})
    }
    asignedSigDraw=async(data)=>{
          this.setState({asigSigDrawn:data})
          this.setState({sigDrawn:data})
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,data,1)
    }
    saveSignature= async()=>{
         var get_signature=this.sigPad.getTrimmedCanvas().toDataURL('image/png')
          if (get_signature.indexOf('iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC')!=-1) {
            alert('Es Necesario una Firma')
            return
          }
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,get_signature,0)
          if (data_result.success) {
            var get_data_user = await get_permission(jsondata._id)
            this.setState({get_data_user:get_data_user[0]})
            this.setState({sigDrawn:false})
          }
    }
    closeModal=(data)=>{
      this.setState({sendOpenModal:data})
    }
    saveActivity=async(dataUser,typeSave)=>{
      if (typeSave==1) {
        if (!window.confirm('Deseas eliminar?')) {
          return
        }
      }
          var getParamDB = new URLSearchParams(window.location.search).get("db");
          var getParamCode = new URLSearchParams(window.location.search).get("code");
          this.setState({btndis:true})
          var data_result = await save_signature_production(getParamDB,getParamCode,dataUser.draw_digital_signature,dataUser.system_digital_signature,this.state,typeSave)
          if (data_result.success) {
            //this.setNameDB(this.props.code)
            this.props.history.push("/admin/history-mrv")
            
            this.setState({btndis:false})
          }
          this.setState({sendOpenModal:!this.state.sendOpenModal})
    }
render(){
  const getRoutesComponents = () =>{

  }
  const toggleFunctionFirst = () => {
      if (this.state.collapsefuve==true) {
        this.state.collapsefuve = false;
      }else{
        this.state.collapsefuve = true;
      }
  }
  const toggleFunction = (index,res) =>{
    if (res==undefined) {
    this.state.collapse[index] = true;
    }else{
      if (this.state.collapse[index]==true) {
        this.state.collapse[index] = false;
      }else{
        this.state.collapse[index] = true;
      }
    }
  }

    const optionModal = (type) => {
    this.setState({openModal:type})
  };
  const accessAccount = (password) => {
    this.setState({disabledbtn:true})
    try{
        this.setState({disabledbtn:false})
        var privateWalletEncrip = web3.eth.accounts.decrypt(this.state.walletData,password)
        this.setState({walletData:privateWalletEncrip})
        optionModal(false)
        
    }catch(error){
        
        this.setState({disabledbtn:false})
    }


  };
    const headerTemplate = (title,options) => {
        const className = `${options.className} justify-content-space-between text-white`;

        return (
            <div className={className} style={{ background: '#7ac142', border: '1px', borderStyle: 'solid', borderColor: '#002a5c', padding: '0px', paddingLeft: '5px' }}>
                <div className="flex align-items-center gap-2">
                    <span className="font-bold">{title}</span>
                </div>
                <div>
                    {options.togglerElement}
                </div>
            </div>
        );
    };
const contentBtn = []
if (this.state.info_producer_vf_gc != 'none') {
    contentBtn[0] = <>
      <div className='col-md-1 flex justify-content-end'>
          <span className="p-buttonset">
              <Button tooltip="Generar PDF" onClick={this.printer_pdf} className='button-color-fod-blue' icon="pi pi-file-pdf" size='small' />
          </span>
      </div>
    </>
}
  return (
    <>

  <Modal isOpen={this.state.sendOpenModal} size="xl">
  <ModalHeader>
    <h3>Mi Firma</h3>
  </ModalHeader>
        <ModalBody>
            <div style={{textAlign:'center'}}>
              <label className="form-control-label">Asignar dibujo firma</label> <input type="checkbox" onChange={()=>{this.asignedSigDraw(!this.state.asigSigDrawn)}} checked={this.state.asigSigDrawn}/>
            </div>
{(this.state.asigSigDrawn)?(
          (this.state.sigDrawn)?(<>
                      <div style={{border: '1px solid #dee2e6',width:"380px",height:"200px",margin:'auto',marginBottom:'40px'}}>
                        <SignatureCanvas 
                        penColor='black' 
                        canvasProps={{width: 380, height: 200, className: 'sigCanvas'}}
                        ref={(ref) => { this.sigPad = ref }}
                        />
                      <button className="btn" onClick={()=>{this.saveSignature()}}>Guardar Firma</button>
                      </div>
            </>):(
                  (this.state.get_data_user?.draw_digital_signature==null)?(null):(<>
                    <div style={{border: '1px solid #dee2e6',width:"380px",height:"auto",margin:'auto'}}>
                      <div style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
                        <img 
                          style={{ backgroundSize: '200px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                          src={this.state.get_data_user?.draw_digital_signature} 
                          />
                      </div>
                        <br />
                    </div>
                    <div style={{width:"380px",margin:'auto'}}>
                      <button className="btn" onClick={()=>{this.changeSigDraw(!this.state.sigDrawn)}}>Cambiar Firma</button>
                    </div>
                  </>)
            )
  ):(null)}

          <div style={{textAlign:'center'}}>
            {(this.state.get_data_user?.system_digital_signature==null)?(<>
                    <div style={{width:"380px",margin:'auto'}}>
                      <button className="btn" onClick={()=>{this.generateSigDraw()}}>Generar firma digital (Genere la firma digital para guardar)</button>
                    </div>
              </>):(<>
                  <h2>{this.state.get_data_user?.system_digital_signature}</h2>
              </>)}
          </div>
        <hr />
          <div style={{textAlign:'center'}}>
            <h2>{this.state.get_data_user?.email}</h2>
            <h3>DNI N# {this.state.get_data_user?.dni}</h3>
          </div>
        </ModalBody>
        <ModalFooter>
        {(this.state.get_data_user?.system_digital_signature==null)?(null):(<>
            {(this.state.get_data_user?.system_digital_signature=='Generando Código')?(null):(<>
              <Button 
              className="btn-icon" 
              color="traza-blue" 
              type="button"
              disabled={this.state.btndis}
              onClick={()=>{this.saveActivity(this.state.get_data_user,0)}}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-check-circle" />
                </span>
                <span className="btn-inner--text">Guardar</span>
              </Button>
            </>)}
          </>)}
          {(this.state.title_end=='checked')?(<>
          <Button disabled={this.state.btndis} onClick={()=> {this.saveActivity(this.state.get_data_user,1)} }>
            Borrar este registro
          </Button>
            </>):(null)}
          <Button onClick={()=> {this.closeModal(!this.state.sendOpenModal)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

{/*            <div className="mt-1 container-fluid">
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">Vista Reporte</h3>
                </CardHeader>
              </Card>
<div className="contentBlockChain">
              { tituloGlobal.map((value,index)=>{
              return (
                <Card className="mb-4" style={{display: this.state.fordisplay[value.fordisplay]}}>
                        <CardHeader id={"section"+index} style={{padding: '5px'}}>
                          <a style={{color: "#32325d",fontWeight:"600"}} href={"#section"+index} onClick={() => {toggleFunction(index,this.state.collapse[index])}} className="mb-0">{value.Titulos}</a>
                        </CardHeader>
                          <Collapse className="collapseIdewntifier" isOpen={this.state.collapse[index]}>
                              <div className="text-center">
                             {value.Componente}
                              </div>
                          </Collapse>
                      </Card>
      
                       )  
                     })
                    }
                    {(this.state.loading==null)?(null):(
                      <Card className="mb-4">
                        <CardHeader id={"sectionnull"}>
                          <a style={{color: "#32325d",fontWeight:"600"}} href={"#sectionnull"} className="mb-0">{this.state.loading}</a>
                        </CardHeader>
                      </Card>
                      )}
            </div>
                     <Card className="mb-4" style={{display:this.state.displayCard}}>
                      {(this.state.info_producer_vf_gc == 'none')?(null):(<>
                    <CardBody className="text-center">
                     <div className="text-center" id='loadingupload' style={{display:this.state.loadingupload}}>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                     </div>
                            <div className="btnend" style={{display:this.state.btnend}}>
                                                  {(this.state.data_user_ad==null)?(null):(
                                                    (this.state.data_user_ad=='VA')?(<>
                                                      {(this.state.title_end=='valid')?(<>
                                                         <Button 
                                                           className="btn-icon" 
                                                           color="traza-blue" 
                                                           type="button" 
                                                           onClick={()=>{this.pushSection('signature_validate')}}>
                                                           <span className="btn-inner--icon mr-1">
                                                             <i className="fas fa-check" />
                                                           </span>
                                                            <span className="btn-inner--text">Validar</span>
                                                         </Button>
                                                         </>):(<>
                                                           <span className="btn-inner--icon mr-1">
                                                             <i className="fas fa-check" style={{color:'green'}}/>
                                                           </span>
                                                           <span className="btn-inner--text" style={{color:'green'}}>Validado </span>
                                                           <br />
                                                         </>)}
                                                      </>):(null)
                                                    )}
                                                       <Button 
                                                         className="btn-icon" 
                                                         color="traza-blue" 
                                                         type="button" 
                                                         disabled={this.state.btnd}
                                                         onClick={this.printer_pdf}>
                                                         <span className="btn-inner--icon mr-1">
                                                           <i className="fas fa-file-pdf" />
                                                         </span>
                                                          <span className="btn-inner--text">Ver en PDF</span>
                                                       </Button>
                            </div>
                                                        </CardBody>
                                                        </>)} 
                      </Card>
      
            </div>*/}

          <div className="mt-1">
              <div className="card">
                <div className="grid">
                    <div className='col-8'>
                        <h5 className='font-semibold'>
                            Vista Reporte
                        </h5>
                    </div>
                    {contentBtn}
                </div>
              <div className="contentBlockChain">
                  { tituloGlobal.map((value,index)=>{
                  return (
                            <div className="field col-12" style={{display: this.state.fordisplay[value.fordisplay]}}>
                                <Panel headerTemplate={headerTemplate.bind(this,value.Titulos)} toggleable={true} collapsed={false}>
                                            <div className="text-center">
                                              {value.Componente}
                                            </div>
                                </Panel>
                            </div>
                           )  
                         })
                        }
                      {(this.state.loading==null)?(null):(
                        <Card className="mb-4">
                          <CardHeader id={"sectionnull"}>
                            <a style={{color: "#32325d",fontWeight:"600"}} href={"#sectionnull"} className="mb-0">{this.state.loading}</a>
                          </CardHeader>
                        </Card>
                        )}
              </div>
              <div className="grid">
              {contentBtn}
              </div>
            </div>
      
            </div>

    </>
  );
 }
}

export class ModalEditUserWallet extends React.Component {
  state={
    password:''
      }
render(){
  return (
  <Modal isOpen={this.props.sendOpenModal} size="md">
        <ModalBody>
          <CardHeader>
            <Row>
            <Col md={11}>
            <h3 className="mb-0">Desbloquear Cuenta</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {this.props.setModal(false)} }>
              <h3 className="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>

        <Card className="mb-4">
                <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                    placeholder="Escribe una contraseña para encryptar"
                    type="password"
                  />
                </FormGroup>
              </Col>
          <CardBody>
            <Row>
              <Col md="5">
                <Button onClick={()=>{this.props.saveEncrypt(this.state.password)}} disabled={this.props.disabledextend} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Aceptar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.props.setModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
  );
 }
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 // setInfoInit = () =>{
 //    if (this.state.setinfoState) {
 //    return;  
 //    }
 //    if (this.context.valueGlobal.length!=0) {
 //          this.setState({setinfoState:true})
 //        }else{
 //          return
 //        }
 //    var dataPermission = this.context.valueGlobal
 //    if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
 //      this.setState({get_permission:false})
 //      return;
 //    }
 //        var getTypeAreaWork = localStorage.getItem('type_area_work')
 //         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
 //          this.setState({get_permission:false})
 //          return 
 //         }
 //        if (dataPermission.role==1) {
 //          this.setState({get_permission:true})
 //        }else{
 //            if (dataPermission.type=='admin') {
 //              this.setState({get_permission:true})
 //            }
 //          if (dataPermission.type=='options') {
 //            if (dataPermission.information_nama_g==1) {
 //              this.setState({get_permission:true})
 //            }else{
 //              this.setState({get_permission:false})
 //            }
 //          }
 //          if (dataPermission.type=='view') {
 //            this.setState({get_permission:false})
 //          }
 //        }
 //      }  
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }

    var dataPermission = this.context.valueGlobal
          var data_type_area_on = dataPermission.type_traza.indexOf('ON')
          var data_type_area_re = dataPermission.type_traza.indexOf('RE')
          var data_type_area_va = dataPermission.type_traza.indexOf('VA')
          this.setState({data_user_traza:dataPermission.type_traza})
          if (data_type_area_on==-1 || data_type_area_re==-1 || data_type_area_va==-1) {
            this.setState({get_permission:false})
          }
          // if (data_type_area_on==-1) {
          //   this.setState({get_permission:false})
          // }
          // if (data_type_area_re==-1) {
          //   this.setState({get_permission:false})
          // }
          // if (data_type_area_va==-1) {
          //   this.setState({get_permission:false})
          // }
        this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
          if (this.context.valueDataMain[0]==undefined) {
            this.setState({get_permission:false})
          }
        }else{
          if (dataPermission.role==4) {
            this.setState({get_permission:true})
          }
        }
      }
render(){
  {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <ReportProductionFinal {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
