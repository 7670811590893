import React from "react";
import classnames from "classnames";
import { Chart } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import HomeCardsHeader from "components/Headers/HomeCardsHeader.js";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import {UserContext} from "layouts/store";

class NotificationScreen extends React.Component {
  static contextType = UserContext
  state={
    info:[]
  }
  async componentDidMount(){
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    
  }
  render(){
    const breakDown = (data) => {
      var dataBreak = data.replaceAll('Detalles de los campos actualizados: ','').split('*')
      var dataContent = []
      if (dataBreak.length>0) {
        for (var i = 0; i < dataBreak.length; i++) {
          dataContent[i] = <>
                          <p className="mb-0" style={{fontWeight: '500',color: 'black'}}>{dataBreak[i]}.</p>
                        </>
        }
      }
      return dataContent
    }
    const openInfo = (inde) => {
      if (this.state.info[inde]=='show') {
        this.setState({info:[]})
      }else{
        this.setState({info:{[inde]:'show'}})
      }
    }

    return (
      <>
        <HomeCardsHeader name={"Notificaciones"} parentName={null} />
        <Container className="mt--6" fluid>
          <Row>
{this.context.valueGlobalMain.map((value,index) => { return (<Col xl="12">
              <Card>
                <CardHeader>
                  <Row id={"sectioncont"+index}>
                    <Col md="6">
                      <h5 className="h4 mb-0">{value.descripcion}</h5>
                      <a href={"#sectioncont"+index} onClick={()=>{openInfo(index)}}>
                        Mas Información
                      </a>                      
                    </Col>
                    <Col md="6">
                      <p className="mb-0 text-right" style={{fontWeight: '600'}}>{value.fecha_subida}</p>
                    </Col>                    
                  </Row>                
                </CardHeader>
                <CardBody className={(this.state.info[index]==undefined)?('hide'):(this.state.info[index])}>
                  {(value.descripcion_detalles=="null")?(null):(breakDown(value.descripcion_detalles))}
                </CardBody>
              </Card>
            </Col>)})}
          </Row>
          <br/><br/><br/><br/><br/><br/><br/>
        </Container>
      </>
    );
  }
}

export default NotificationScreen;
