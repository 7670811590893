import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
import jQuery from 'jquery';
import axios from 'axios';

 class DetailDiscrimination extends React.Component {
  state={
  }
    async componentDidMount(){
        
  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }

  const openUploadImg = () => {
    document.getElementById('file_upload').click()
  };
    const openUploadImgTwo = () => {
    document.getElementById('file_upload_two').click()
  };
    const getFileBase = (fles) => {
      if (fles.length>0) {
            const FR = new FileReader();
            var forProps = this
            FR.addEventListener("loadend", function(evt) {
            document.querySelector("#sectionimg").src = evt.target.result;
            forProps.props.setValFieldDiscrimination('imagen_upload', evt.target.result)
            //console.log(evt.target.result)
            //document.querySelector("#b64").textContent = evt.target.result;
          }); 
          //this.setState({file_for_send:fles[0]})
          forProps.props.setValFieldDiscrimination('file_for_send', fles[0])
          FR.readAsDataURL(fles[0]);
      }
  };

      const getFileBaseTwo = (fles) => {
      if (fles.length>0) {
            const FR = new FileReader();
            var forProps = this
            FR.addEventListener("loadend", function(evt) {
            document.querySelector("#sectionimgtwo").src = evt.target.result;
            forProps.props.setValFieldDiscrimination('imagen_upload_two', evt.target.result)
            //console.log(evt.target.result)
            //document.querySelector("#b64").textContent = evt.target.result;
          }); 
          forProps.props.setValFieldDiscrimination('file_for_send_two', fles[0])
          FR.readAsDataURL(fles[0]);
      }
  };
  
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  const eliminarImg = (type) => {
    if (type=='imagen_one') {
      this.props.setValFieldDiscrimination('imagen_upload', '')
    }
    if (type=='imagen_two') {
      this.props.setValFieldDiscrimination('imagen_upload_two', '')
    }


  };
const putNumber = async (type) =>{
  if (jQuery(type).is(':checked')) {
    if (this.props.validSwith.resultSumVacuno!='') {
     var result = await axios.get("https://numeros-a-letras1.p.rapidapi.com/api/NAL/", {
          params: {'num': this.props.validSwith.resultSumVacuno},
  headers: {
    'X-RapidAPI-Key': '5519047977mshb50d5759193081bp1a4ea4jsn0171917d654f',
    'X-RapidAPI-Host': 'numeros-a-letras1.p.rapidapi.com'
  }
  }).catch(err => { 
      console.log(err)
    });
      this.props.setValFieldDiscrimination('total_vavino',result.data.letras)
     //console.log(result.data.letras)
    }
  }else{
    this.props.setValFieldDiscrimination('total_vavino','')
  }
}
  return (
    <>
              <Col md="6" className="pb-2">
                            <Col md="12">
                              <label className="form-control-label">Establecer Total en letras-</label>
                              <input
                              onChange={(e)=>{ putNumber(e.target) }}
                              //checked={this.state.propiedad=='Con Cambio de Propiedad 1'}
                              className="radioStyle"
                              type="checkbox"
                              />
                            </Col>
              <Col md="12">
                <div style={{display:'flex'}}>
                  <div className="form-control-label mr-1" style={styles.labelStyleTwoNextMessageReson}>
                    TOTAL VACUNOS (en letras)
                  </div>
                  <input
                    style={{height: '15px',textAlign: 'justify',fontSize: '13.5px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                     //value={(this.state.total_vavino==undefined)?((this.props.validSwith.seleccionar_operacion_prop=='1')?('Cuarenta y uno'):(null)):(this.state.total_vavino)}
                     value={(this.props.validSwith.total_vavino==undefined)?(null):(this.props.validSwith.total_vavino)}
                     onChange={(e)=>{
                      this.props.setValFieldDiscrimination('total_vavino',e.target.value)
                      //this.setState({total_vavino:e.target.value})
                    }}
                     type="text"                  
                    />
                </div>              
                <label className="form-control-label" style={styles.labelStyleTree}>
                  5- DISCRIMINACIÓN DEL GANADO VACUNO POR MARCAS
                </label>
              </Col>
                <div style={styles.cardStyleSectionTwo}>               
               
                <Row>
                <Col md={12}>
                  <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '0px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '85px',textAlign:'justify',fontWeight: '600',marginRight: '0px'}}>Número Reg. Gral.</label>
                <Row>
                <Col md={6} style={{paddingLeft: '10px',paddingRight:'10px'}}>

                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '32px',borderRadius: '0px',width: '100%',color: 'black',letterSpacing: '10px'}}
                       //value={(this.state.input_text_reg==undefined)?((this.props.validSwith.type_change_prod==1)?('624373'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('624373'):(null))):(this.state.input_text_reg)}
                       value={(this.props.validSwith.input_text_reg==undefined)?(null):(this.props.validSwith.input_text_reg)}
                       onChange={(e)=>{
                        //this.setState({input_text_reg:e.target.value})
                        this.props.setValFieldDiscrimination('input_text_reg',e.target.value)
                      }}
                       type="text"                  
                      />
                  </Col>
                <Col md={6} style={{paddingLeft: '10px',paddingRight:'10px'}}>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '32px',borderRadius: '0px',width: '100%',color: 'black',letterSpacing: '10px'}}
                       value={(this.props.validSwith.input_text_reg_two==undefined)?(null):(this.props.validSwith.input_text_reg_two)}
                       onChange={(e)=>{
                        this.props.setValFieldDiscrimination('input_text_reg_two',e.target.value)
                        //this.setState({input_text_reg_two:e.target.value})
                      }}
                       type="text"                  
                    />
                  </Col>
                </Row>

                      </div>
                    </div>
                  </div>                                                        
                  </Col>

                <Col md={12}>
                  <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px'}}>
                  <div style={{display:'flex'}}>
                  <label className="form-control-label" style={{color: '#000000', alignSelf: 'center',fontSize: '10px', width: 'auto',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Dibujo</label>
                    <div style={{margin:'auto'}}>
                      <Row>
                        <div style={{paddingLeft: '65px',paddingRight:'65px',paddingTop: '5px'}}>
                        <input style={{display:'none'}} type="file" id="file_upload" onChange={(e)=>{getFileBase(e.target.files)}} />
                          <div onClick={()=>{openUploadImg()}} style={{width:'100px', height:'100px',backgroundColor: 'white'}}>
                            <img
                                alt="..."
                                id="sectionimg"
                                src={this.props.validSwith.imagen_upload}
                                style={{height: '100px',width:'100px',backgroundColor: 'black'}}
                              />
                          </div>
                          <button style={{fontSize:'10px',color:'black',fontWeight:'700',background: 'transparent',border: 'none'}} onClick={()=>{eliminarImg('imagen_one')}}>Eliminar imagen</button>                          
                        </div>

                        <input style={{display:'none'}} type="file" id="file_upload_two" onChange={(e)=>{getFileBaseTwo(e.target.files)}} />
                        <div style={{paddingLeft: '65px',paddingRight:'65px',paddingTop: '5px'}}>
                         <div onClick={()=>{openUploadImgTwo()}} style={{width:'100px', height:'100px',backgroundColor: 'white'}}>
                            <img
                                alt="..."
                                id="sectionimgtwo"
                                src={this.props.validSwith.imagen_upload_two}                                
                                style={{height: '100px',width:'100px',backgroundColor: 'black'}}
                              />
                          </div>
                          <button style={{fontSize:'10px',color:'black',fontWeight:'700',background: 'transparent',border: 'none'}} onClick={()=>{eliminarImg('imagen_two')}}>Eliminar imagen</button>
                          </div>
                        </Row>
                    </div>
                   </div>
                  </div>                                                        
                  </Col>

                <Col md={12} style={{marginTop: '15px'}}>
                  <div style={{alignSelf:'center',textAlign: 'center',display:'flex'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '80px',textAlign:'justify',fontWeight: '600',marginRight: '0px'}}>Cabezas</label>
                <Row>
                <Col md={6} style={{paddingLeft: '10px',paddingRight:'10px'}}>

                      <Input
                      style={{textAlign: 'center',fontSize: '22px',margin: 'auto',height: '32px',borderRadius: '0px',width: '60%',color: 'black',letterSpacing: '10px'}}
                       //value={(this.state.numero_cabezas==undefined)?((this.props.validSwith.type_change_prod==1)?('41'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('41'):(null))):(this.state.numero_cabezas)}
                       value={(this.props.validSwith.numero_cabezas==undefined)?(this.props.validSwith.resultSumVacuno):(this.props.validSwith.numero_cabezas)}
                       onChange={(e)=>{
                        this.props.setValFieldDiscrimination('numero_cabezas',e.target.value)
                        //this.setState({numero_cabezas:e.target.value})
                      }}
                       type="text"                  
                      />
                  </Col>
                <Col md={6} style={{paddingLeft: '10px',paddingRight:'10px'}}>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',margin: 'auto',height: '32px',borderRadius: '0px',width: '60%',color: 'black',letterSpacing: '10px'}}
                       value={(this.props.validSwith.numero_cabezas_two==undefined)?(this.props.validSwith.resultSum):(this.props.validSwith.numero_cabezas_two)}
                       onChange={(e)=>{
                        this.props.setValFieldDiscrimination('numero_cabezas_two',e.target.value)
                        //this.setState({numero_cabezas_two:e.target.value})
                      }}
                       type="text"                  
                    />
                  </Col>
                </Row>

                      </div>
                    </div>
                  </div>                                                        
                  </Col>

                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailDiscrimination;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  width: '350px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  },
  cardStyleSectionTwo:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  }
}