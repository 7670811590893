import React, {useState} from 'react';
import {
    Navbar,
    NavbarBrand,
    NavLink,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
  import { NavLink as ComponentLink } from "react-router-dom";
  //import { get_permission } from "network/ApiAxios";
  import {UserContext} from "layouts/store";
  import jQuery from "jquery";
  class VegetablesNavBarKdes extends React.Component {
    static contextType = UserContext
    state ={
      modal:false,
      color:'transparent',
      trazabilidad:'none',
      cosecha:'none',
      planta:'none',
      documento:'none',
      comercializa:'none',
      trazabilidad_end:'none',
      company_id:false,
      setinfoState:false
    }
   async componentDidMount(){
    jQuery(".documentFormAll").hide()
    jQuery("#insertDocumentShow").val('none')
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.getParams).get("company");
    var idcompany = null;
    // if (jsondata.userType==1) {
    //   var dataPermission = await get_permission(jsondata._id,null)
    // }

    if (jsondata.userType==2) {
      if (gerParam!=null) {
        this.setState({company_id:true})
        idcompany = gerParam
      }
    }
    //await get_permission(jsondata._id,idcompany)
    this.setinfoInit()
    }
    componentDidUpdate(){
      this.setinfoInit()
    }    
  setinfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    var getPermission = this.context.valueGlobal
    if (getPermission.user_id_connection==null) {
      jQuery("#insertIdUser").val(getPermission.user_id)
    }else{
      jQuery("#insertIdUser").val(getPermission.user_id_connection)
    }
      if (getPermission.role==1) {
        jQuery("#insertDocumentShow").val('block')
        jQuery(".documentFormAll").show()
            this.setState({
              trazabilidad:'block',
              cosecha:'block',
              planta:'block',
              documento:'block',
              comercializa:'block',
              trazabilidad_end:'block',
            })
      }else{
        if (getPermission.type=='options') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              trazabilidad:(getPermission.trazabilidad==0)?'none':'block',
              cosecha:(getPermission.cosecha==0)?'none':'block',
              planta:(getPermission.planta==0)?'none':'block',
              documento:(getPermission.documento==0)?'none':'block',
              comercializa:(getPermission.comercializa==0)?'none':'block',
              trazabilidad_end:'none',
            })
        }
        if (getPermission.type=='admin') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              trazabilidad:'block',
              cosecha:'block',
              planta:'block',
              documento:'block',
              comercializa:'block',
              trazabilidad_end:'none',
            })
        }

      }
        if (this.context.valueGlobal.length!=0) {
        this.setState({setinfoState:true})
        }
      }

    render(){
    const openToggler = (valOpen) =>{
      if (this.state.modal==true) {
       this.setState({modal:false,color:'transparent'})
      }else{
       this.setState({modal:true,color:'#2d597f'})
      }
    }
    return (
      <>
      <input type="hidden" id="insertIdUser" />
      <input type="hidden" id="insertDocumentShow" />
        <div>

          <Navbar style={{backgroundColor:'#7AC142', height: '50px'}} expand="lg" light>
            {/*<NavbarBrand href="/" style={{display:this.state., color:'white', fontWeight:'bold'}}>Vegetales y frutas</NavbarBrand>*/}
            <NavbarToggler onClick={() => {openToggler(true)}} />
            <Collapse isOpen={this.state.modal} navbar style={{zIndex: '10', backgroundColor:this.state.color}}>
              <Nav className="me-auto" navbar style={{fontSize: '14px'}}>
                <NavItem nav="true" style={{display: this.state.trazabilidad, padding: '0.25rem 0.75rem'}}>
                    {(this.props.type_traza.indexOf('VF')!=-1 )?(
                      <ComponentLink
                        style={{color:'white', fontWeight:'bold'}}
                        to={"/admin/fruit_vegetable_user"+this.props.getParams}
                      >
                        Datos productor
                      </ComponentLink>
                    ):(null)}
                </NavItem>
                {

                  (this.props.getParams=="")
                  ?
                  (null)
                  :
                  (<>
                                          {(this.props.type_traza.indexOf('VF')!=-1 )?(
                                                  <NavLink style={{display: this.state.cosecha}}>
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/create_harvest_kdes"+this.props.getParams}
                                                    >
                                                      Cosecha
                                                    </ComponentLink>
                                                  </NavLink>
                                          ):(null)}
                                          {(this.props.type_traza.indexOf('V1F')!=-1 )?(
                                                  <UncontrolledDropdown style={{display: this.state.planta}} inNavbar nav>
                                                    <DropdownToggle caret nav style={{color:'white', fontWeight:'bold'}}>
                                                      Empaque
                                                    </DropdownToggle>
                                                    <DropdownMenu>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_plant_receiver_kdes"+this.props.getParams}>
                                                            Recibido
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_plant_cooling_kdes"+this.props.getParams}>
                                                            Enfriamiento
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_plant_product_kdes"+this.props.getParams}>
                                                            Empaque Inicial
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_plant_shipping_kdes"+this.props.getParams}>
                                                            Envío
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                          ):(null)}
                                          {(this.props.type_traza.indexOf('V2F')!=-1 )?(
                                                  <UncontrolledDropdown inNavbar nav>
                                                    <DropdownToggle caret nav style={{color:'white', fontWeight:'bold'}}>
                                                      Importador
                                                    </DropdownToggle>
                                                    <DropdownMenu>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_producer_receiving_kdes"+this.props.getParams}>
                                                            Recibido
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_producer_transformation_kdes"+this.props.getParams}>
                                                            Transformación
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_producer_food_kdes"+this.props.getParams}>
                                                            Nueva comida
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_producer_shipping_kdes"+this.props.getParams}>
                                                            Envio
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                          ):(null)}

                                          {(this.props.type_traza.indexOf('V3F')!=-1 )?(
                                                  <UncontrolledDropdown inNavbar nav>
                                                    <DropdownToggle caret nav style={{color:'white', fontWeight:'bold'}}>
                                                      Distribuidor
                                                    </DropdownToggle>
                                                    <DropdownMenu>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_distributor_receiving_kdes"+this.props.getParams}>
                                                            Recibido
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>

                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/create_distributor_shipping_kdes"+this.props.getParams}>
                                                            Envio
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                          ):(null)}

                                          {(this.props.type_traza.indexOf('V4F')!=-1 )?(
                                                  <NavLink style={{display: this.state.comercializa}}>
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/create_establishment_receiving_kdes"+this.props.getParams}
                                                    >
                                                      Detallista
                                                    </ComponentLink>
                                                  </NavLink>
                                          ):(null)}


                                                  <NavLink style={{display: this.state.documento}}>
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/documents_kdes"+this.props.getParams}
                                                    >
                                                      Documentos
                                                    </ComponentLink>
                                                  </NavLink>

                                                  <NavLink >
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/finalice_kdes"+this.props.getParams}
                                                    >
                                                      Finalizar
                                                    </ComponentLink>
                                                  </NavLink>

                                                  </>
                                                  )
}
              </Nav>
            </Collapse>
          </Navbar>
        </div>        
      </>
    );
   }
  }
  
  export default VegetablesNavBarKdes;
  