import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'

const datacontent = [
{title:'Mercado Nacional',table:'commercialization_market',icon:'fa fa-map-marker','multiple_tables':0},
{title:'Datos Exportación',table:'commercialization_export',icon:'fa fa-star','multiple_tables':0},
]

export default class SectionCommerce extends React.Component {
  async componentDidMount(){
  }
  render(){
  return (
    <>
      <ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
{/*                    <div id="comercializacion-tab" className="clearfix eael-tab-content-item inactive" data-title-link="comercializacion-tab">
                                  <div data-elementor-type="page" data-elementor-id="5649" className="elementor elementor-5649">
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-8903bf3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8ec3272" data-id="8ec3272" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-eed6dbe elementor-widget elementor-widget-spacer" data-id="eed6dbe" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-28d08b1 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="28d08b1" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
      <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:PutContentStatus(this.props.DataRow.allDataTrza,'commercialization_market').color,borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-map-marker" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      </figure>      <div className="elementor-image-box-content">
      <h3 className="elementor-image-box-title">Datos Mercado</h3></div></div>    </div>
        </div>
        <div className="elementor-element elementor-element-42682be elementor-widget elementor-widget-toggle" data-id="42682be" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item">
          <h6 onClick={()=>{opened(this.state.openColapse[0],0)}} id="elementor-tab-title-6961" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-6961" aria-expanded="false">
                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                              <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                          </span>
                        <div className="elementor-toggle-title">Mercado Nacional</div>
          </h6>

          <Collapse isOpen={this.state.openColapse[0]} style={{width: '250%'}} id="elementor-tab-content-6961" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-6961"><ul>
                                                      <Col md="12" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                <Row>
                  <Col md={9}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                  </Col>
                  <Col md={3}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.DataRow.allDataTrza,'commercialization_market').register_status}</h4>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                  <PutContent 
                  data_function={this.props.DataRow} 
                  type_function="commercialization_market" />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </ul><div>&nbsp;</div></Collapse>
        </div>
                </div>
        </div>
        </div>
          </div>
    </div>
        <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
        <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7c3b6fa" data-id="7c3b6fa" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-7c54668 elementor-widget elementor-widget-spacer" data-id="7c54668" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:PutContentStatus(this.props.DataRow.allDataTrza,'commercialization_export').color,borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-star" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      </figure>      <div className="elementor-image-box-content">
      <h3 className="elementor-image-box-title">Exportación</h3></div></div>    </div>
        </div>
        <div className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item">
          <h6 onClick={()=>{opened(this.state.openColapse[1],1)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                              <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                          </span>
                        <div className="elementor-toggle-title">Datos Exportación</div>
          </h6>

          <Collapse isOpen={this.state.openColapse[1]} id="elementor-tab-content-8621" style={{width: '265%',position: 'relative',right: '100%'}} className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-8621"><ul>
                <Col md="12" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                <Row>
                  <Col md={9}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                  </Col>
                  <Col md={3}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.DataRow.allDataTrza,'commercialization_export').register_status}</h4>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                <PutContent 
                  data_function={this.props.DataRow} 
                  type_function="commercialization_export" />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </ul></Collapse>
        </div>
                </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-81e561d elementor-widget elementor-widget-spacer" data-id="81e561d" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
          </div>
    </div>
              </div>
    </section><section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section></div>
           </div>*/}
    </>
  );
}
}

//export default Elements;
