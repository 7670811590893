import React, {createRef, useEffect} from "react";
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import QRCode from "react-qr-code";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';

export class Elements extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    trazabilidad_meat:[],
    company_id:null,
    jsondataBase:null,
    sendOpenModal:false,
    coder:'none',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (this.props.sendDataCompany.type_traza==null) {
    jQuery('#tm').remove()
    jQuery('#tvf').remove()
    jQuery('#tvfkdes').remove()
    return
  }else{
    // if (this.props.sendDataCompany.type_traza=="VF") {
    //   jQuery('#tm').remove()
    // }
    // if (this.props.sendDataCompany.type_traza=="M") {
    //   jQuery('#tvf').remove()
    // }
    if (this.props.sendDataCompany.type_traza.indexOf('VF')==-1) {
      jQuery('#tvf').remove()
      jQuery('#tvfkdes').remove()
    }
    if (this.props.sendDataCompany.type_traza.indexOf('M')==-1) {
      jQuery('#tm').remove()
    }

  }
  // || this.props.sendDataCompany.type_traza=="VF-M"
  var data = await data_list_processes(jsondata._id,this.props.sendDataCompany.user_id_connection,this.props.sendDataCompany.type,'ttp',jsondata.userType)
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  // if (data.trazabilidad[1].length!=0) {
  //   data.trazabilidad[1].map((a,b)=>{
  //     data.trazabilidad[0].map((val,ind)=>{
  //     console.log(b)

  //       //console.log(a[val.split('*')[1]])
  //     })
  //   })
  // }
  this.setState({
    company_id:gerParam,

    data_traza:data.trazabilidad[1],
    data_traza_th:data.trazabilidad[0],

    data_traza_kdes:data.trazabilidad_kdes[1],
    data_traza_kdes_th:data.trazabilidad_kdes[0],

    data_traza_g:data.traza_grain[1],
    data_traza_g_th:data.traza_grain[0],
    trazabilidad_meat:data.trazabilidad_meat,
    jsondataBase:jsondata.database
  })
  }
  logictRouteParam=()=>{
      var routeParamNameFV = 'start-trace/vegetables-fruits'
      if (this.props.sendDataCompany.role==2) {
        switch (1) {
          case this.props.sendDataCompany.trazabilidad:
            routeParamNameFV='start-trace/vegetables-fruits';
            break;
          case this.props.sendDataCompany.produccion:
            routeParamNameFV='fruits-vegetables/production';
            break;
          case this.props.sendDataCompany.cosecha:
            routeParamNameFV='fruits-vegetables/harvest';
            break;
          case this.props.sendDataCompany.planta:
            routeParamNameFV='fruits-vegetables/plant';
            break;
          case this.props.sendDataCompany.logistica:
            routeParamNameFV='fruits-vegetables/logistics';
            break;
          case this.props.sendDataCompany.documento:
            routeParamNameFV='docs';
            break;
          case this.props.sendDataCompany.comercializa:
            routeParamNameFV='fruits-vegetables/Comercialization';
            break;
          default:
            routeParamNameFV='start-trace/vegetables-fruits';
        }
      }
      var routeParamNameGrain = 'info_crops_t'
      if (this.props.sendDataCompany.role==2) {
        switch (1) {
          case this.props.sendDataCompany.information_grain_t:
            routeParamNameGrain='info_crops_t';
            break;
          case this.props.sendDataCompany.production_grain_t:
            routeParamNameGrain='grain_seed_t';
            break;
          case this.props.sendDataCompany.harvest_grain_t:
            routeParamNameGrain='grain_harvest_info_harvest_t';
            break;
          case this.props.sendDataCompany.document_grain_t:
            routeParamNameGrain='grains_traza_documents';
            break;
          default:
            routeParamNameGrain='info_crops_t';
        }
      }
    return {routeParamNameGrain,routeParamNameFV}
  }
  setContent = (data,typeT) => {
    if (data==undefined) {
      return
    }
    if (data!=undefined) {
      if (data.length!=0) {
      var content=[]
        data.map((a,b)=>{
           var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            var name_title = ''
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/grains-nama-info_crop"
              }
              if (val[1]?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/"+this.logictRouteParam().routeParamNameGrain
              }
              if (val[1]?.split('-')[0]=='FCT') {
               url_selected = "/admin/"+this.logictRouteParam().routeParamNameFV
              }
              if (val[1]?.split('-')[0]=='FCTK') {
               url_selected = "/admin/fruit_vegetable_user"
               name_title='Frutas y Vegetales KDEs'
               //+this.logictRouteParam().routeParamNameFVK
              }
            }
            // var getCodeToken = ''
            // if (val[0]=='token_id') {
            //     getCodeToken=Object.entries(a)[Object.entries(a).length-1][1]
            // }
   var content_two = <>
    
    {(val[0]!='codigo' && val[0]!='token_id')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
    {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td><td>
                  <ComponentLink
                   className="font-weight-bold"
                                         to={{
                     pathname:url_selected,
                     search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                     state:{
                       codigoTraza:val[1],
                       title_name: name_title
                     }
                     }}
                   >
                  Ver Esta Trazabilidad
                  </ComponentLink>
                </td>
                <td><QRCode style={{ width: "65px", height: 'auto' }} value={"http://foodchaintrazabilidad.com/home/vista-trace?code="+val[1]+"&db="+this.state.jsondataBase}  /></td>
                <td><button className="btn" title="Compartir con usuario" onClick={()=>{this.setInfoInvoice(true, val[1],  Object.entries(a)[Object.entries(a).length-1][1])}}> <span className="fas fa-share-alt"></span> </button></td>
                </>):(null)}

                {(val[0]=='token_id')?(
                  (val[1]!=null)?(<>
                      <td>
                        <button className="btn" title="Click para copiar el token generado para esta trazabilidad" 
                        onMouseOut={()=>{
                          if (jQuery('.changeIconClip').hasClass('fa-check')) {
                            jQuery('.changeIconClip').removeClass('fas fa-check')
                            jQuery('.changeIconClip').addClass('far fa-copy')
                          }
                        }}
                        onClick={(copyText)=>{
                          // copyText.target.select();
                          // copyText.target.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(val[1]);
                          jQuery('.changeIconClip').removeClass('far fa-copy')
                          jQuery('.changeIconClip').addClass('fas fa-check')
                        }}> <span className="far fa-copy changeIconClip"></span> </button>
                      </td>
                      {(typeT=='KDES')?(<>
                          <td>
                            <button className="btn" title="Click para ver vista trace" 
                            onClick={()=>{
                              this.props.history.push({
                                                      pathname: "/admin/vista-trace-kdes-admin",
                                                      search: '?code='+val[1]
                                                    })
                            }}> <span className="far fa-eye"></span> </button>
                          </td>
                        </>):(null)}
                    </>):(<td><span className="text-muted">Sin token</span></td>)
                  ):(null)}
                        </>
                        return content_two
          })
          content[b] = <tr>{content_one}</tr>
        })
      }
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      Object.values(data).map((a,b)=>{
        content[b]=<th>{a.split('*')[0]}</th>
      })
    }
    return content
  }
  setInfoInvoice=(changeModal,code,token)=>{
    this.setState({sendOpenModal:changeModal,'coder':code+'|'+token})
  }
render(){
    const actionBodyTemplate = (rowData) => {
          var url_selected = null
          var name_title = ''
            if (rowData.codigo?.split('-')[0]=='FCTGN') {
              url_selected = "/admin/grains-nama-info_crop"
            }
            if (rowData.codigo?.split('-')[0]=='FCTGT') {
              url_selected = "/admin/"+this.logictRouteParam().routeParamNameGrain
            }
            if (rowData.codigo?.split('-')[0]=='FCT') {
             url_selected = "/admin/"+this.logictRouteParam().routeParamNameFV
            }
            if (rowData.codigo?.split('-')[0]=='FCTK') {
             url_selected = "/admin/fruit_vegetable_user"
             name_title='Frutas y Vegetales KDEs'
             //+this.logictRouteParam().routeParamNameFVK
            }

        return (
            <>
              <Button icon="pi pi-search" text onClick={()=>{
                this.props.history.push( url_selected+( (this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo) ) )
              }} />
            </>
        );
    };
  return (
    <>
      <DataTable
          //tableStyle={{ padding: '0px' }}
          value={this.state.data_traza}
          dataKey="id"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
          filters={this.state.filters}
          className="datatable-responsive"
          emptyMessage="Sin registros encontrados."
          responsiveLayout="scroll"
          //header={header}
      >
          <Column field="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>
          {this.setContentTh(this.state.data_traza_th).map((a,b)=>{
            return <Column body={(data)=>{
                            if (data[a.table]!=null) {
                              if (data[a.table].length>10) {
                                return `${data[a.table].substring(0, 10)}...`
                              }else{
                                return data[a.table]
                              }
                            }else{
                              return "Sin datos"
                            }
                          }} field={a.table} header={a.title} ></Column>
          })}
          <Column field="created_at" header="Creado" sortable></Column>
          <Column body={actionBodyTemplate} headerStyle={{ minWidth: '0rem' }}></Column>
      </DataTable>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false,
  }
 async componentDidMount(){
    this.context.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
          this.setState({company_id_act:dataPermission})
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
