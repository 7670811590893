import React,{useEffect} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-pro-react.scss";

import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'layouts/styles/layout/layout.scss';
import 'layouts/styles/demo/Demos.scss';
import 'layouts/styles/custom.css';

import "primereact/resources/themes/lara-light-cyan/theme.css";

//import "assets/vendor/nucleo/css/nucleo.css";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import AuthRoute from "components/PrivateRoute/AuthRoute";
import HomeRoute from "components/PrivateRoute/HomeRoute";

ReactDOM.render(
	<React.StrictMode>
	  <BrowserRouter>
	    <Switch>
	      <PrivateRoute path="/admin" component={AdminLayout} />
	      <AuthRoute path="/home" component={AuthLayout} />
	      <Route path="/" render={(props) => <IndexView {...props} />} />
	      <Redirect from="*" to="/" />
	    </Switch>
	  </BrowserRouter>
	</React.StrictMode>,
  document.getElementById("root")
);
document.getElementById('imgLoad').style.display='none'
