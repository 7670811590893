import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold, data_production, get_permission } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import FormLogisticShipment from "./ModalExtend/Subs/FormLogistics/FormLogisticShipment";
import FormLogisticsTransporte from "./ModalExtend/Subs/FormLogistics/FormLogisticsTransporte";
export default class LogisticaTransporte extends React.Component {
  state={
    link_id:null
  }
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }  
  render(){
  return (
    <>
      <FormLogisticShipment {...this.props} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal} />
      <FormLogisticsTransporte {...this.props} putLinkId={this.state.link_id} />
    </>
  );
 }
}

// import React, {createRef, useEffect} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { create_transport_shipment, create_transport_transport, data_transport_shipment } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import jQuery from 'jquery';
// import config from "config";

// export default class LogisticaTransporte extends React.Component {
//     state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//   }
//     async componentDidMount(){
//             var documentForm = jQuery("#insertDocumentShow").val()
//           if (documentForm=='none') {
//             this.setState({documentForm:documentForm})
//           }
//           if(this.props.paramsCode==undefined || this.props.paramsCode==null){
//             var codigoTraza = 'nocode';
//           }else{
//             var codigoTraza = this.props.paramsCode;
//           }
//           var userdata = localStorage.getItem('user');
//           var jsondata = JSON.parse(userdata);
//           var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
//           var dataTrazabilidadvf = await data_transport_shipment(jsondata._id,codigoTraza,codigoTrazaTwo)
//           if (dataTrazabilidadvf.shipment==null) {
//             this.setDataCamposEmpty()
//           }else{
//             this.setDataCampos(dataTrazabilidadvf)
//           }
//   }
//       setDataCamposEmpty = ()=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//             this.setState({
//                 transport_shipment_id:'noind',
//                 modo_transporte:'',
//                 fecha_carga:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 contenedor_numero:'',
//                 sello_numero:'',
//                 unidad_carga:'',
//                 total_unidades:'',
//                 informacion_adicional_shipment:'',
//                 file_document_shipment:undefined,

//                 transport_transport_id:'noind',
//                 fecha_embarque:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 envio_desde:'',
//                 guia_numero:'',
//                 georeferencia:'',
//                 puerto_embarque:'',
//                 informacion_adicional_transport:'',
//                 file_document_transport:undefined,
//             })
//       }

//     setDataCampos = (data)=>{
//      if (data.shipment!=null) {
//         this.setState({
//                 transport_shipment_id:data.shipment.id,
//                 modo_transporte:data.shipment.modo_transporte,
//                 fecha_carga:data.shipment.fecha_carga,
//                 contenedor_numero:data.shipment.contenedor_numero,
//                 sello_numero:data.shipment.sello_numero,
//                 unidad_carga: data.shipment.unidad_carga,
//                 total_unidades: data.shipment.total_unidades,
//                 informacion_adicional_shipment:data.shipment.informacion_adicional,
//                 file_document_shipment:data.shipment.archivo
//         })
//       }
//       if (data.transport!=null) {
//         this.setState({
//                 transport_transport_id:data.transport.id,
//                 fecha_embarque:data.transport.fecha_embarque,
//                 envio_desde:data.transport.envio_desde,
//                 guia_numero:data.transport.guia_numero,
//                 georeferencia:data.transport.georeferencia,
//                 puerto_embarque: data.transport.puerto_embarque,
//                 informacion_adicional_transport: data.transport.informacion_adicional,
//                 file_document_transport:data.transport.archivo
//         })
//       }
//     }
//   render(){

//   return (
//     <>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Embarque</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Modo de transporte
//                   </label>
//                   <br />
//                   <span>{this.state.modo_transporte}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Carga
//                   </label>
//                   <br />
//                   <span>{this.state.fecha_carga}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Contenedor Numero
//                   </label>
//                   <br />
//                   <span>{this.state.contenedor_numero}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Sello Numero
//                   </label>
//                   <br />
//                   <span>{this.state.sello_numero}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Unidad de carga
//                   </label>
//                   <br />
//                   <span>{this.state.unidad_carga}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades
//                   </label>
//                   <br />
//                   <span>{this.state.total_unidades}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_shipment}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_shipment==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_shipment}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4" style={{display:(this.state.envio_desde==undefined)?('none'):('block')}}>
//           <CardHeader>
//             <h3 className="mb-0">Transporte</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Embarque
//                   </label>
//                   <br />
//                   <span>{this.state.fecha_embarque}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Envio desde
//                   </label>
//                   <br />
//                   <span>{this.state.envio_desde}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Guia Numero
//                   </label>
//                   <br />
//                   <span>{this.state.guia_numero}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Georeferencia
//                   </label>
//                   <br />
//                   <span>{this.state.georeferencia}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Puerto de Embarque
//                   </label>
//                   <br />
//                   <span>{this.state.puerto_embarque}</span>
//                 </FormGroup>
//               </Col>
//                <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_transport}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_transport==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_transport}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//           </CardBody>
//         </Card>
//     </>
//   );
//  }
// }

// //export default Elements;
