import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import Logo from "../../../assets/img/brand/foodchain-logo.png";
import Register from "../examples/Register";
import NotificationAlert from "react-notification-alert";
import { register_valid_kdes, get_data_user_kdes } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { useHistory } from "react-router-dom";
import config from "config";
import VistaTraceKdes from "views/pages/examples/Trazability/VistaTraceViewKdes";
import { Panel } from 'primereact/panel';

const LandingInvitation = () => {
  const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedDni, setfocusedDni] = useState(false);
  const [focusedEmpresa, setfocusedEmpresa] = useState(false);
  const [focusedPais, setfocusedPais] = useState(false);
  const [focusedTrazabilidad, setfocusedTrazabilidad] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedConfirmPassword, setfocusedConfirmPassword] = useState(false);

  const [name, setName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [focusedSecondName, setfocusedSecondName] = useState(false);

  const [pais, setPais] = useState("");
  const [trazabilidad, setTrazabilidad] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [areawork, setAreawork] = useState("");
  const [email, setEmail] = useState("");
  const [dni, setDni] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [checkbox, setCheckbox] = useState(false);
  const [btnD, setBtnD] = useState(true);
  const [error, setError] = useState("");
  const [toastMessage, setToastMessage] = useState("Verifique su correo.");
  const [alert, setalert] = useState(false);
  const { DEMO } = config;
  const [demo, setDemo] = useState(DEMO);
  const notificationAlertRef = useRef(null);
  const history = useHistory();

  const [tokenCode, setTokenCode] = useState("");
  const [dataUser, setDataUser] = useState([]);

  const [validInput, setValidInput] = useState(false);
  const [forMess, setforMess] = useState(null);

  useEffect(async () => {
    var get_params_email = new URLSearchParams(window.location.search).get("email");
    var get_params_token = new URLSearchParams(window.location.search).get("token");
    var get_params_section = new URLSearchParams(window.location.search).get("section");
    var content = null
    if (get_params_email==null) {
      content = <>Bienvenido, por favor registrase como entidad para poder comenzar a trabajar de forma eficiente y rapida.</>
    }else{
      content = <>Bienvenido, fuiste invitado para participar en el proceso de trazabilidad FDA con el token: <br/><br/> {get_params_token}.<br/><br/> registrate pra poder comenzar a trabajar de forma eficiente y rapida.</>
    }
      setforMess(content)
    setTokenCode(get_params_token)
    var getr_data_user = await get_data_user_kdes(get_params_email,get_params_token,get_params_section,5)
    if (getr_data_user.get_data_user.length>0) {
      if (getr_data_user.countToken==0) {
        notify("danger", "¡Token no existente!", 'Usuario con token no encontrado');
      }
      setName(getr_data_user.get_data_user[0].name)
      setSecondName(getr_data_user.get_data_user[0].second_name)
      setEmail(getr_data_user.get_data_user[0].email)
      setEmpresa(getr_data_user.get_data_user[0].empresa)
      setTrazabilidad(getr_data_user.get_data_user[0].type_traza)
      setDataUser(getr_data_user.get_data_user)
      setValidInput(true)
    }else{
      if (get_params_token!=null && getr_data_user.get_data_user.length==0) {
        notify("danger", "¡Cuenta no existente!", 'Usuario con token no encontrado');
      }
    }
  }, []);
  const notify = (type, title, message, userID) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const registerUser = async () => {
    setError("Cargando...");
    setBtnD(false);
    if (
      name == "" ||
      secondName == "" ||
      email == "" ||
      dni == "" ||
      empresa == "" ||
      //areawork == "" ||
      pais == "0"
    ) {
      setError("Todos los campos son necesarios.");
      setBtnD(true);
      return;
    }
    // if (
    //   areawork.indexOf("T") != -1 &&
    //   (trazabilidad == "0" || trazabilidad == "")
    // ) {
    //   setError("Seleccione una trazabilidad.");
    //   setBtnD(true);
    //   return;
    // }
    if (password !== confirmPassword) {
      setError("las contraseñas no coinciden.");
      setBtnD(true);
      return;
    }
    setError("Espere mientras su cuenta está siendo creada.");
    const response = await register_valid_kdes(
      name,
      secondName,
      email,
      empresa,
      pais,
      trazabilidad,
      password,
      5,
      0,
      "",
      dni
    ).catch(function (message) {
      setError(JSON.stringify(message.response.data.errors));
    });

    if (response == undefined) {
      setBtnD(true);
      return;
    } else {
      const { data } = response;
      if (!data.success) {
        setBtnD(true);
        setError(data.msg);
        return;
      }
    }

    setToastMessage("Verifique su correo.");
    setError("");
    setName("");
    setEmail("");
    setDni("");

    setPais("0");
    setEmpresa("");

    setPassword("");
    setConfirmPassword("");
    setBtnD(false);
    notify("success", "¡Cuenta registrado exitosamente!", toastMessage);
    setTimeout(function () {
      history.push("/home/validation", { email: email });
    }, 3000);
  };

  const listaArray = (a, b) => {
    if (a.Code < b.Code) {
      return -1;
    }
    if (a.Code > b.Code) {
      return 1;
    }
    return 0;
  };

  const listCountryCode = CountrysData.sort(listaArray);
  const changeAreaWork = (val) => {
    var data = "";
    if (val.checked) {
      data = areawork + val.value;
      //areawork
    } else {
      data = areawork.replace(val.value, "");
    }
    setAreawork(data);
  };

  return (
    <Container className="min-vh-100" fluid>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
{/*      <Row className="fixed-top p-2" style={{ background:"#fff" }}>
        <Col md="12">
          <img src={Logo} style={{ width: "250px" }} alt="Logo" />
        </Col>
      </Row>*/}
      {(dataUser.length!=0)?(
        <div className="pt-9">
          <Panel header="Vista Trace FDA" toggleable collapsed={true}>
            <VistaTraceKdes type="register" dataUser={dataUser} />
          </Panel>
        </div>
      ):(null)}
      <Row className="pt-1" style={{ minHeight: "100vh" }}>
        <Col md="6" className="pt-7 d-flex justify-content-center align-items-center"  style={{ background: "#69bb28" }}>
          <Card>
            <CardBody>
              <h4 style={{textAlign: 'justify'}}>
              {forMess}
              </h4>
            </CardBody>
          </Card>
        </Col>

        <Col md="6" className="pt-8 d-flex justify-content-center align-items-center" style={{ background: "#002a5c" }}>
          <Card>
            <CardBody>
              <h1 className="text-center">
                Crea tu cuenta
              </h1>
              <div className="text-center  mb-4">
                Escriba sus datos para crear una cuenta y poder utilizar la plataforma.
              </div>

              <Form role="form">
                <FormGroup
                  className={classnames({
                    focused: focusedName,
                  })}
                >
                  <Row>
                    <Col md="6">
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          placeholder="Nombre"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onFocus={() => setfocusedName(true)}
                          onBlur={() => setfocusedName(false)}
                        />
                      </InputGroup>
                    </Col>

                    <Col md="6">
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          placeholder="Apellido"
                          type="text"
                          value={secondName}
                          onChange={(e) => setSecondName(e.target.value)}
                          onFocus={() => setfocusedSecondName(true)}
                          onBlur={() => setfocusedSecondName(false)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          placeholder="Correo electrónico"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(false)}
                          disabled={validInput}
                          name="email"
                        />
                      </InputGroup>
                    </Col>

                    <Col md="6">
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          placeholder="Cédula"
                          type="text"
                          value={dni}
                          onChange={(e) => setDni(e.target.value)}
                          onFocus={() => setfocusedDni(true)}
                          onBlur={() => setfocusedDni(false)}
                          name="dni"
                        />
                      </InputGroup>
                    </Col>
                  </Row>

{/*                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Seleccione Área de Trabajo</label>
                        <br />
                        <input
                          type="checkbox"
                          value="P"
                          onChange={(e) => {
                            changeAreaWork(e.target);
                          }}
                        />{" "}
                        <span>Producción: </span>
                        <input
                          type="checkbox"
                          value="T"
                          onChange={(e) => {
                            changeAreaWork(e.target);
                          }}
                        />{" "}
                        <span>Trazabilidad: </span>
                        <input
                          type="checkbox"
                          value="C"
                          onChange={(e) => {
                            changeAreaWork(e.target);
                          }}
                        />{" "}
                        <span>Comercialización: </span>
                      </FormGroup>
                      {areawork.indexOf("T") != -1 ? (
                        <FormGroup
                          className={classnames({
                            focused: focusedTrazabilidad,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-ellipsis-h" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="select"
                              value={trazabilidad}
                              onChange={(e) => setTrazabilidad(e.target.value)}
                              onFocus={() => setfocusedTrazabilidad(true)}
                              onBlur={() => setfocusedTrazabilidad(false)}
                            >
                              <option value="0">Trazabilidad</option>
                              <option value="VF">Vegetales y frutas</option>
                              <option value="M">Carnicos</option>
                              <option value="G">Granos</option>
                              <option value="VF-M">
                                Vegetales y frutas - Carnicos
                              </option>
                              <option value="VF-G">
                                Vegetales y frutas - Granos
                              </option>
                              <option value="M-G">Carnicos - Granos</option>
                              <option value="VF-M-G">
                                Vegetales y frutas - Carnicos - Granos
                              </option>
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      ) : null}
                    </Col>
                  </Row>*/}

                  <Row>
                    <Col>
                        <FormGroup
                          className={classnames({
                            focused: focusedTrazabilidad,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-ellipsis-h" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="select"
                              value={trazabilidad}
                              onChange={(e) => setTrazabilidad(e.target.value)}
                              onFocus={() => setfocusedTrazabilidad(true)}
                              onBlur={() => setfocusedTrazabilidad(false)}
                              disabled={validInput}
                            >
                              <option value="0">Role</option>
                              <option value="V1F">Planta-Empaque</option>
                              <option value="V2F">Importador</option>
                              <option value="V3F">Distribuidor</option>
                              <option value="V4F">Detallista</option>
                            </Input>
                          </InputGroup>
                        </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          placeholder="Empresa"
                          type="text"
                          value={empresa}
                          onChange={(e) => setEmpresa(e.target.value)}
                          onFocus={() => setfocusedEmpresa(true)}
                          onBlur={() => setfocusedEmpresa(false)}
                          name="empresa"
                        />
                      </InputGroup>
                    </Col>

                    <Col>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          type="select"
                          value={pais}
                          onChange={(e) => setPais(e.target.value)}
                          onFocus={() => setfocusedPais(true)}
                          onBlur={() => setfocusedPais(false)}
                        >
                          <option value="0">Pais</option>
                          {listCountryCode.map((value, index) => {
                            return (
                              <option value={value.Name}>{value.Name}</option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          placeholder="Contraseña"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(false)}
                        />
                      </InputGroup>
                    </Col>

                    <Col>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <Input
                          placeholder="Confirmar contraseña"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          onFocus={() => setfocusedConfirmPassword(true)}
                          onBlur={() => setfocusedConfirmPassword(false)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                        {error ? (
                          <div className="text-muted font-italic">
                            <small>
                              Mensaje:{" "}
                              <span className="text-red font-weight-700">
                                {error}
                              </span>
                            </small>
                          </div>
                        ) : null}

                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                          checked={checkbox}
                          onChange={() => setCheckbox(!checkbox)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            He leído y acepto los{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              términos y condiciones
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-center">
                      <Button 
                        className="mt-4" 
                        color="traza-green" 
                        type="button" 
                        disabled={!email || !password || !confirmPassword || !checkbox || !btnD}
                        onClick={registerUser}>
                        Crear cuenta
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

{/*      <Row className="fixed-bottom p-3 " style={{ background:"#fff" }}>
        <Col xl="6">
          <div
            className="copyright text-center text-xl-left"
            style={{ color: "#002a5c" }}
          >
            © Foodchain 2019 - {new Date().getFullYear()}. Todos los derechos
            reservados.
          </div>
        </Col>
      </Row>*/}
    </Container>
  );
};

export default LandingInvitation;
