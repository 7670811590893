import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_traza_lote, set_data_compartiment, create_compartiment, delete_compartiment, data_trazabilidad_meat, row_transport_logistics } from "network/ApiAxios";

import NotificationAlert from "react-notification-alert";
import config from "config";

export default class ModalCompartiment extends React.Component {

 
  render(){

  return (
    <>
            <Row>
            {
                  this.props.sendInfoProps.compartiment.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                        <SectionCompartiment getIdCompartiment={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataCompartimentExtend={this.props.sendInfoProps.compartiment} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>
            <Row>
            {
                  this.props.sendInfoProps.compartiment_exit.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                        <SectionCompartimentExit getIdCompartimentExit={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataCompartimentExitExtend={this.props.sendInfoProps.compartiment_exit} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>
    </>
  );
 }
}

export class SectionCompartiment extends React.Component {
   state={
    nombre_productor:'',
    nombre_finca:'',
    ciudad:'',
    estado:'',
    lote_numero:'',
    guia_numero:'',
    precinto_numero:'',
    fecha_recibido:new Date().getFullYear() + "-11-23",
    hora_recibido:new Date().getFullYear() + "-11-23",
    cantidad_animales:'',
    recibido_por:'',
    nro_identificacion:'',
    informacion_adicional:'',
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    data_lote:[],
    dsableBtn:false,
    dsableBtnTrans:false,
    data_trnsport:[]
  }
    async componentDidMount(){
            this.setInfoCompart(this.props.getIdCompartiment)    
    }
    setDataCampos = async (data,datatraza,dataLote,dataTransport)=>{

      this.setState({
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              compartiment_id:(data.id=="null")?(null):(data.id),
              nombre_productor:(data.nombre_productor=="null")?(datatraza.nombre_producto):(data.nombre_productor),
              nombre_finca:(datatraza.nombre_finca=="null")?(null):(datatraza.nombre_finca),
              ciudad:(datatraza.ciudad=="null")?(null):(datatraza.ciudad),
              estado:(data.estado=="null")?(null):(data.estado),
              fecha_recibido:( data.fecha_recibido=="null")?(null):( data.fecha_recibido),
              hora_recibido:( data.hora_recibido=="null")?(null):( data.hora_recibido),
              lote_numero:(data.lote_numero=="null")?(null):(data.lote_numero),
              guia_numero:(data.guia_numero=="null")?(null):(data.guia_numero),
              precinto_numero:(data.precinto_numero=="null")?(null):(data.precinto_numero),
              //cantidad_animales:(data.cantidad_animales=="null")?(null):(data.cantidad_animales),
              recibido_por:(data.recibido_por=="null")?(null):(data.recibido_por),
              nro_identificacion:(data.nro_identificacion=="null")?(null):(data.nro_identificacion),
              informacion_adicional:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
              file_document:(data.archivo=="null")?(null):(data.archivo),
      })
      if (data.lote_numero=="null" || data.lote_numero==null || data.lote_numero=="0") {
        this.setState({cantidad_animales:data.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales:data.cantidad_animales,
          dsableBtn:true
        })
      }
      if (data.guia_numero=="null" || data.guia_numero==null || data.guia_numero=="0") {
                this.setState({precinto_numero:data.precinto_numero})
      }else{
                this.setState({
                  precinto_numero:data.precinto_numero,
                  dsableBtnTrans:true
                })
      }

    }
       setInfoCompart = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = this.props.setParamsUrl
          var dataTrazabilidadvf = await set_data_compartiment(this.props.dataCompartimentExtend,id_set)
            var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
          var dataTrazabilidadAnimal = await data_trazabilidad_meat(jsondata._id,gerParam,codigoTrazaTwo)
            if (gerParam==null) {
              var proddata = ''
            }else{
              var proddata = (dataTrazabilidadAnimal==null)? '' : dataTrazabilidadAnimal.lote_numero
            }
              var dataLote = await row_traza_lote(jsondata._id,gerParam,codigoTrazaTwo)
              var dataTransport = await row_transport_logistics(jsondata._id,gerParam,codigoTrazaTwo)
        if (dataTrazabilidadvf==null) {
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
          this.setState({
              compartiment_id:'noind',
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              nombre_productor:dataTrazabilidadAnimal.nombre_producto,
              nombre_finca:dataTrazabilidadAnimal.nombre_finca,
              ciudad:'',
              estado:'',
              lote_numero:'',
              guia_numero:'',
              precinto_numero:'',
              fecha_recibido:completeDate,
              hora_recibido:timeString,
              cantidad_animales:'',
              recibido_por:'',
              nro_identificacion:'',
              file_document:undefined,
              informacion_adicional:'',
          })
        this.setState({
          dsableBtn:false,
          dsableBtnTrans:false
        })

        }else{
          this.setDataCampos(dataTrazabilidadvf,dataTrazabilidadAnimal,dataLote,dataTransport)
        }
          this.setState({
        sendOpenModal:verify,
      })

    }
  render(){
    return(<>
            <Row className="mb-2 mt-4">
              <Col md={12}>
                <h3 className="mb-0">Recibo de Animales {this.props.getIndex+1}</h3>
              </Col>
            </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre del productor
                    </label>
                    <br /><span>
                    {this.state.nombre_productor}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre de la finca
                    </label>
                    <br /><span>
                        {this.state.nombre_finca}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Ciudad
                    </label>
                    <br /><span>
                      {this.state.ciudad}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Estado
                    </label>
                    <br /><span>
                      {this.state.estado}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label>
                    <br /><span>
                      {this.state.lote_numero}
                    </span>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Guía Numero
                    </label>
                    <br /><span>
                      {this.state.guia_numero}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Precinto Numero
                    </label>
                    <br /><span>
                      {this.state.precinto_numero}
                    </span>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Recibido
                  </label>
                  <br /><span>
                    {(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Recibido
                  </label>
                  <br /><span>
                    {(this.state.hora_recibido=="undefined")?(null):(this.state.hora_recibido)}
                  </span>  

                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <br /><span>
                      {this.state.cantidad_animales}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Recibido Por
                    </label>
                    <br /><span>
                      {this.state.recibido_por}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nro. de Identificación
                    </label>
                    <br /><span>
                      {this.state.nro_identificacion}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <br /><span>
                      {this.state.informacion_adicional}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Documento
                  </label>
                <br />
                {(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('/').pop()}</a>)}
                </Col>
              </Row>

          </>
      )
  }
}

export class SectionCompartimentExit extends React.Component {
   state={
    compartiment_exit_id:'',
    nombre_finca:'',
    nombre_compartimento:'',
    lote_numero:'',
    guia_numero:'',
    precinto_numero:'',
    fecha_salida:'',
    hora_salida:new Date().getFullYear() + "-11-23",
    enviado_por:new Date().getFullYear() + "-11-23",
    nro_identificacion:'',
    informacion_adicional:'',
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    data_lote:[],
    dsableBtn:false,
    dsableBtnTrans:false,
    data_trnsport:[]
  }
    async componentDidMount(){
            this.setInfoCompart(this.props.getIdCompartimentExit)    
    }
    setDataCampos = async (data,datatraza,dataLote,dataTransport)=>{

      this.setState({
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              compartiment_exit_id:(data.id=="null")?(null):(data.id),
              nombre_finca:(datatraza.nombre_finca=="null")?(null):(datatraza.nombre_finca),
              nombre_compartimento:(data.nombre_compartimento=="null")?(null):(data.nombre_compartimento),
              lote_numero:(data.lote_numero=="null")?(null):(data.lote_numero),
              guia_numero:(data.guia_numero=="null")?(null):(data.guia_numero),
              precinto_numero:(data.precinto_numero=="null")?(null):(data.precinto_numero),
              fecha_salida:( data.fecha_salida=="null")?(null):( data.fecha_salida),
              hora_salida:( data.hora_salida=="null")?(null):( data.hora_salida),
              enviado_por:(data.enviado_por=="null")?(null):(data.enviado_por),
              nro_identificacion:(data.nro_identificacion=="null")?(null):(data.nro_identificacion),
              informacion_adicional:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
              file_document:(data.archivo=="null")?(null):(data.archivo),
      })
      if (data.lote_numero=="null" || data.lote_numero==null || data.lote_numero=="0") {
        this.setState({cantidad_animales:data.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales:data.cantidad_animales,
          dsableBtn:true
        })
      }
      if (data.guia_numero=="null" || data.guia_numero==null || data.guia_numero=="0") {
                this.setState({precinto_numero:data.precinto_numero})
      }else{
                this.setState({
                  precinto_numero:data.precinto_numero,
                  dsableBtnTrans:true
                })
      }

    }
       setInfoCompart = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = this.props.setParamsUrl
          var dataTrazabilidadvf = await set_data_compartiment(this.props.dataCompartimentExitExtend,id_set)
            var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
          var dataTrazabilidadAnimal = await data_trazabilidad_meat(jsondata._id,gerParam,codigoTrazaTwo)
            if (gerParam==null) {
              var proddata = ''
            }else{
              var proddata = (dataTrazabilidadAnimal==null)? '' : dataTrazabilidadAnimal.lote_numero
            }
              var dataLote = await row_traza_lote(jsondata._id,gerParam,codigoTrazaTwo)
              var dataTransport = await row_transport_logistics(jsondata._id,gerParam,codigoTrazaTwo)
        if (dataTrazabilidadvf==null) {
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
          this.setState({
              compartiment_exit_id:'noind',
              data_lote:dataLote.trazaDataLote,
              data_trnsport:dataTransport.dataLogisticsTransport,
              nombre_finca:dataTrazabilidadAnimal.nombre_finca,
              nombre_compartimento:'',
              lote_numero:'',
              guia_numero:'',
              precinto_numero:'',
              fecha_salida:completeDate,
              hora_salida:timeString,
              enviado_por:'',
              nro_identificacion:'',
              cantidad_animales:'',
              file_document:undefined,
              informacion_adicional:'',
          })
        this.setState({
          dsableBtn:false,
          dsableBtnTrans:false
        })

        }else{
          this.setDataCampos(dataTrazabilidadvf,dataTrazabilidadAnimal,dataLote,dataTransport)
        }
          this.setState({
        sendOpenModal:verify,
      })

    }
  render(){
    return(<>
            <Row className="mb-2 mt-4">
              <Col md={12}>
                <h3 className="mb-0">Salida de Animales {this.props.getIndex+1}</h3>
              </Col>
            </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre de la finca
                    </label>
                    <br /><span>
                        {this.state.nombre_finca}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre del Compartimento
                    </label>
                    <br /><span>
                      {this.state.nombre_compartimento}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label>
                    <br /><span>
                      {this.state.lote_numero}
                    </span>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Guía Numero
                    </label>
                    <br /><span>
                      {this.state.guia_numero}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Precinto Numero
                    </label>
                    <br /><span>
                      {this.state.precinto_numero}
                    </span>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Salida
                  </label>
                  <br /><span>
                    {(this.state.fecha_salida=="undefined")?(null):(this.state.fecha_salida)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Salida
                  </label>
                  <br /><span>
                    {(this.state.hora_salida=="undefined")?(null):(this.state.hora_salida)}
                  </span>  

                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <br /><span>
                      {this.state.cantidad_animales}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Enviado Por
                    </label>
                    <br /><span>
                      {this.state.enviado_por}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nro. de Identificación
                    </label>
                    <br /><span>
                      {this.state.nro_identificacion}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <br /><span>
                      {this.state.informacion_adicional}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Documento
                  </label>
                <br />
                {(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('/').pop()}</a>)}
                </Col>
              </Row>

          </>
      )
  }
}