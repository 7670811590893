import React, {createRef, useEffect} from "react";
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { list_register_kdes, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import QRCode from "react-qr-code";
import ModalInviteUser from "views/pages/forms/Modal/ModalInviteUser";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Panel } from 'primereact/panel';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

export class Elements extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    trazabilidad_meat:[],
    company_id:null,
    jsondataBase:null,
    sendOpenModal:false,
    coder:'none',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    visibleRight:false,
    visibleRightTwo:false,
    code:null,
    codeSave:null,
    inputswitch:false,
    inputswitchStatus:false,
    titleSwitch:'compartidos',
    statusSwitch:'finalizado',
  }
 async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (this.props.sendDataCompany.type_traza==null) {
      jQuery('#tm').remove()
      jQuery('#tvf').remove()
      jQuery('#tvfkdes').remove()
      return
    }else{
      if (this.props.sendDataCompany.type_traza.indexOf('VF')==-1) {
        jQuery('#tvf').remove()
      }
      if (
          this.props.sendDataCompany.type_traza.indexOf('V1F')==-1 &&
          this.props.sendDataCompany.type_traza.indexOf('V2F')==-1 &&
          this.props.sendDataCompany.type_traza.indexOf('V3F')==-1 &&
          this.props.sendDataCompany.type_traza.indexOf('V4F')==-1
        ) {
        jQuery('#tvfkdes').remove()
      }
      if (this.props.sendDataCompany.type_traza.indexOf('M')==-1) {
        jQuery('#tm').remove()
      }

    }
    this.initData(this.state.titleSwitch,this.state.statusSwitch)
  }
  initData=async(titleSwitch,statusSwitch)=>{
    this.setState({
      data_traza_kdes:[],
      data_traza_kdes_th:null
    })
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var colab_id = String(this.props.sendDataCompany.user_id_connection)
    if (this.props.sendDataCompany.link_user_global!=null) {
      colab_id = this.props.sendDataCompany.link_user_global
    }
    var setRole = 1
    if (jsondata.userType==1) {
      setRole = jsondata.userType
    }else{
      if (titleSwitch=='personales') {
        setRole = 1
      }else{
        setRole = jsondata.userType
      }
    }
    var data = await list_register_kdes(jsondata._id,colab_id,
      this.props.sendDataCompany.type,
      'ttp',
      jsondata.userType,
      titleSwitch,
      statusSwitch)
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    var putDataTrazaTwo = {}
    var putDataTraza = []
    var counter = 0
    if (data.trazabilidad_kdes[1]!=undefined) {  
      data.trazabilidad_kdes[1].map((valArr,indxArr)=>{
           valArr.map((dArr,iArr)=> {
            putDataTrazaTwo[dArr.created_at]=dArr
          })
           counter++
      })
    }
    this.setState({
      company_id:gerParam,
      data_traza_kdes:Object.values(putDataTrazaTwo),
      data_traza_kdes_th:data.trazabilidad_kdes[0],
      jsondataBase:jsondata.database
    })
  }
  logictRouteParam=()=>{

    var routeParamNameFVK = 'fruit_vegetable_user'

      var routeParamNameFV = 'start-trace/vegetables-fruits'
      if (this.props.sendDataCompany.role==2) {
        switch (this.props.sendDataCompany.type_traza) {
          case 'VF':
            routeParamNameFVK='create_harvest_kdes';
            break;
          case 'V1F':
            routeParamNameFVK='create_plant_cooling_kdes';
            break;
          case 'V2F':
            routeParamNameFVK='create_producer_receiving_kdes';
            break;
          case 'V3F':
            routeParamNameFVK='create_distributor_receiving_kdes';
            break;
          case 'V4F':
            routeParamNameFVK='create_establishment_receiving_kdes';
            break;
          default:
            routeParamNameFVK='fruit_vegetable_user';
        }
        switch (1) {
          case this.props.sendDataCompany.trazabilidad:
            routeParamNameFV='start-trace/vegetables-fruits';
            break;
          case this.props.sendDataCompany.produccion:
            routeParamNameFV='fruits-vegetables/production';
            break;
          case this.props.sendDataCompany.cosecha:
            routeParamNameFV='fruits-vegetables/harvest';
            break;
          case this.props.sendDataCompany.planta:
            routeParamNameFV='fruits-vegetables/plant';
            break;
          case this.props.sendDataCompany.logistica:
            routeParamNameFV='fruits-vegetables/logistics';
            break;
          case this.props.sendDataCompany.documento:
            routeParamNameFV='docs';
            break;
          case this.props.sendDataCompany.comercializa:
            routeParamNameFV='fruits-vegetables/Comercialization';
            break;
          default:
            routeParamNameFV='start-trace/vegetables-fruits';
        }
      }
      var routeParamNameGrain = 'info_crops_t'
      if (this.props.sendDataCompany.role==2) {
        switch (1) {
          case this.props.sendDataCompany.information_grain_t:
            routeParamNameGrain='info_crops_t';
            break;
          case this.props.sendDataCompany.production_grain_t:
            routeParamNameGrain='grain_seed_t';
            break;
          case this.props.sendDataCompany.harvest_grain_t:
            routeParamNameGrain='grain_harvest_info_harvest_t';
            break;
          case this.props.sendDataCompany.document_grain_t:
            routeParamNameGrain='grains_traza_documents';
            break;
          default:
            routeParamNameGrain='info_crops_t';
        }
      }
    return {routeParamNameGrain,routeParamNameFV,routeParamNameFVK}
  }
  listaArray =(a,b)=>{
      //console.log(a,b)
      return new Date(a.created_at) - new Date(b.created_at);
  }

  setContent = (data,typeT) => {
    if (data==undefined) {
      return
    }
    if (data!=undefined) {
      if (data.length!=0) {
      var content=[]
      var listCompleteRows = data.sort(this.listaArray)
        listCompleteRows.reverse().map((a,b)=>{
      //console.log(a)
           var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            var name_title = ''
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/grains-nama-info_crop"
              }
              if (val[1]?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/"+this.logictRouteParam().routeParamNameGrain
              }
              if (val[1]?.split('-')[0]=='FCT') {
               url_selected = "/admin/"+this.logictRouteParam().routeParamNameFV
              }
            }
            if (val[0]=='token_id') {
               url_selected = "/admin/"+this.logictRouteParam().routeParamNameFVK
               name_title='Frutas y Vegetales FDA'
            }
   var content_two = <>
    
    {(val[0]!='codigo' && val[0]!='token_id')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
    {(val[0]=='token_id')?(<><td><span className="text-muted">{val[1]}</span></td><td>
                  <ComponentLink
                   className="font-weight-bold"
                                         to={{
                     pathname:url_selected,
                     search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                     state:{
                       codigoTraza:val[1],
                       title_name: name_title
                     }
                     }}
                   >
                  Ver Esta Trazabilidad
                  </ComponentLink>
                </td>
                <td><QRCode style={{ width: "65px", height: 'auto' }} value={"http://foodchaintrazabilidad.com/home/vista-trace?code="+val[1]+"&db="+this.state.jsondataBase}  /></td>
                <td><button className="btn" title="Compartir con usuario" onClick={()=>{
                  this.setInfoInvoice(true, val[1],  Object.entries(a)[Object.entries(a).length-1][1])
                }}> <span className="fas fa-share-alt"></span> </button></td>
                </>):(null)}

                {(val[0]=='token_id')?(
                  (val[1]!=null)?(<>
                      <td>
                        <button className="btn" title="Click para copiar el token generado para esta trazabilidad" 
                        onMouseOut={()=>{
                          if (jQuery('.changeIconClip').hasClass('fa-check')) {
                            jQuery('.changeIconClip').removeClass('fas fa-check')
                            jQuery('.changeIconClip').addClass('far fa-copy')
                          }
                        }}
                        onClick={(copyText)=>{
                          // copyText.target.select();
                          // copyText.target.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(val[1]);
                          jQuery('.changeIconClip').removeClass('far fa-copy')
                          jQuery('.changeIconClip').addClass('fas fa-check')
                        }}> <span className="far fa-copy changeIconClip"></span> </button>
                      </td>
                      {(typeT=='KDES')?(<>
                          <td>
                            <button className="btn" title="Click para ver vista trace" 
                            onClick={()=>{
                              this.props.history.push({
                                                      pathname: "/admin/vista-trace-kdes-admin",
                                                      search: '?code='+val[1]
                                                    })
                            }}> <span className="far fa-eye"></span> </button>
                          </td>
                        </>):(null)}
                    </>):(<td><span className="text-muted">Sin token</span></td>)
                  ):(null)}
                        </>
                        return content_two
          })
          content[b] = <tr>{content_one}</tr>
        })
      }
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]= {title:a.split('*')[0],table:a.split('*')[1]}
      })
    }
    return content
  }
  setInfoInvoice=(changeModal,code,token)=>{
    this.setState({sendOpenModal:changeModal,'coder':code+'|'+token})
  }
render(){
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
    const header = (
        <div className="flex flex-column md:flex-row flex-md-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Gestionar trazabilidad
            </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                type="search" 
                value={this.state.globalFilter}
                //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>

            <Button 
                label="Nueva" 
                icon="pi pi-plus" 
                severity="success" 
                className="btn-fod-green mr-2" 
                onClick={
                  () => {
                    this.props.history.push( '/admin/kdes-panel' )
                    //this.setState({visibleRight:true})
                }
              } 
                />
        </div>
    );
    const valuesType = [
        { name: 'Trazabilidades Compartidas', code: 'compartidos' },
        { name: 'Trazabilidades Internas', code: 'personales' },
    ];
    const headerTwo = (<>
            <h5 className="mb-3" style={{ color: '#002a5c' }}>
                Procesos {this.state.titleSwitch}
            </h5>
        <div className="flex flex-column md:flex-row flex-md-row md:justify-content-between md:align-items-center">
        <Dropdown value={this.state.inputswitch} onChange={(e) => setRowClick(e.value)} options={valuesType} optionLabel="name" placeholder="Trazabilidad Compartidos o Internas" className="w-full md:w-14rem" />

{/*            <div className="flex justify-content-center align-items-center gap-2">
                <InputSwitch inputId="input-rowclick" checked={this.state.inputswitch} onChange={(e) => { setRowClick(e.value)} } />
                <label htmlFor="input-rowclick">Procesos Compartidos/Personales</label>
            </div>*/}
{/*            <div className="flex justify-content-center align-items-center gap-2">
                <InputSwitch inputId="input-rowclick" checked={this.state.inputswitchStatus} onChange={(e) => { setRowClickStatus(e.value)} } />
                <label htmlFor="input-rowclick">Status Desarrollo/Finalizado</label>
            </div>*/}
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                type="search" 
                value={this.state.globalFilter}
                //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>
            {(this.state.titleSwitch=='personales')?(
              <Button 
                  label="Nueva" 
                  icon="pi pi-plus" 
                  severity="success" 
                  className="btn-fod-green mr-2" 
                  onClick={() => {
                      this.props.history.push( '/admin/kdes-panel' )
                      //this.setState({visibleRight:true})
                  }}
              />
            ):(null)}
        </div>
    </>);
    const actionBodyTemplate = (rowData) => {

            var url_selected = null
            var name_title = ''

              if (rowData.codigo?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/grains-nama-info_crop"
              }
              if (rowData.codigo?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/"+this.logictRouteParam().routeParamNameGrain
              }
              if (rowData.codigo?.split('-')[0]=='FCT') {
               url_selected = "/admin/"+this.logictRouteParam().routeParamNameFV
              }
              if (rowData.codigo?.split('-')[0]=='FCTK') {
               //url_selected = "/admin/fruit_vegetable_user"
               //url_selected = "/admin/"+this.logictRouteParam().routeParamNameFVK
               url_selected = "/admin/kdes-panel"
               name_title='Frutas y Vegetales FDA'
               //+this.logictRouteParam().routeParamNameFVK
              }

        return (
            <>

                <Button 
                    icon="pi pi-share-alt" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    onClick={ () => {
                      this.setInfoInvoice(true, rowData.codigo,  rowData.token_id)
                      //this.setState({visibleRightTwo:true, code:rowData.codigo})
                      //onClick={()=>{this.setInfoInvoice(true, val[1],  Object.entries(a)[Object.entries(a).length-1][1])}}
                      //this.props.history.push( '/admin/prouction-observations-mvr'+((this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo)) )
                    }}
                    tooltip="Invitar/Enviar" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />

                <Button 
                    icon="pi pi-copy" 
                    rounded 
                    outlined 
                    className={"mr-2 button-fod changeIconClip"+rowData.id}
                    onMouseOut={()=>{
                      //pi-check
                      if (jQuery('.changeIconClip'+rowData.id).hasClass('pi-check')) {
                        jQuery('.changeIconClip'+rowData.id).removeClass('pi pi-check')
                        jQuery('.changeIconClip'+rowData.id).addClass('pi pi-copy')
                      }
                    }}
                    onClick={ () => {
                          navigator.clipboard.writeText(rowData.token_id);
                          jQuery('.changeIconClip').removeClass('pi pi-copy')
                          jQuery('.changeIconClip').addClass('pi pi-check')
                    }}
                    tooltip="Copiar token" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />

                <Button 
                    icon="pi pi-eye" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    onClick={ () => {
                      this.props.history.push({
                                              pathname: "/admin/vista-trace-kdes-admin",
                                              search: '?code='+rowData.token_id
                                            })
                    }}
                    tooltip="Vista trace" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />

            </>
        );
    };
    const setRowClick=(data)=>{
      //console.log(data)
      // var setSwitch = 'compartidos'
      // if (data.code) {
      //   this.setState({titleSwitch:"personales"})
      //   setSwitch = 'personales'
      // }else{
      //   this.setState({titleSwitch:'compartidos'})
      // }
        this.setState({inputswitch:data})
        this.setState({titleSwitch:data.code})
        this.initData(data.code,this.state.statusSwitch)
    }
    const setRowClickStatus=(data)=>{
      var setSwitch = 'desarrollo'
      if (data) {
        setSwitch = 'finalizado'
        this.setState({statusSwitch:"finalizado"})
      }else{
        this.setState({statusSwitch:'desarrollo'})
      }
        this.setState({inputswitchStatus:data})
        this.initData(this.state.titleSwitch,setSwitch)

    }
  return (
    <>
<ModalInviteUser sendOpenModal={this.state.sendOpenModal} setInfoInvoice={this.setInfoInvoice} setCode={this.state.coder} contexto={this.context}/>
{/* <SimpleHeader name="Trazabilidad en proceso" parentName="Trazabilidad en proceso" section="fuits_vegetable" section_two="Tabla" section_table_form="table_traza_proccess" />*/}

        <Container className="mt-2" fluid>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card card-table">
                    {/*<Toast ref={toast} />*/}

                    <DataTable
                         value={this.state.data_traza_kdes}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={headerTwo}
                    >
                        {this.setContentTh(this.state.data_traza_kdes_th).map((a,b)=>{
                          return <Column field={a.table} header={a.title} ></Column>
                        })}
                        {/*<Column field="token" header="Código" sortable></Column>*/}
                        <Column field="created_at" header="Creado" sortable></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>

    
                </div>
            </div>
        </div>
      </Container>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false,
  }
 async componentDidMount(){
    this.context.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var regexStr = /V1F|V2F|V3F|V4F/gi
    var data_type_area = dataPermission.type_traza.search(regexStr)
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
          this.setState({company_id_act:dataPermission})
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
