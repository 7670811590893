 import { initializeApp } from "firebase/app";
 import { getFirestore } from "@firebase/firestore";
 import { getDatabase } from "firebase/database";
 import { getMessaging } from "firebase/messaging";
 const firebaseConfig = {
   apiKey: "AIzaSyBY9TdynPKTkqppJlNu2Lnbj2xEMTjXy5g",
   authDomain: "foodchain-96f4b.firebaseapp.com",
   projectId: "foodchain-96f4b",
   storageBucket: "foodchain-96f4b.appspot.com",
   messagingSenderId: "767380306883",
   appId: "1:767380306883:web:e01c9c77b01477b653db76"
 };
 const app = initializeApp(firebaseConfig);
 export const db = getDatabase(app)
 export const messaging = getMessaging(app);
