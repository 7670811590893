import React, {useState} from 'react';
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import jQuery from 'jquery';
import SimpleHeaderKdes from "components/Headers/SimpleHeaderKdes.js";
import VegetablesNavBarKdes from "views/pages/components/VegetablesNavBarKdes.js";
import FinalKDES from "./FinalKDES/FinalSectionKDES";
import { generate_trazabilidad_kdes, get_data_row_admin_kdes } from "network/ApiAxios";
import { generateChain } from "network/GenerateTokenChain";
import NotificationAlert from "react-notification-alert";
import { NavLink } from "react-router-dom";
import $ from 'jquery'
import QRCode from "react-qr-code";
import {UserContext} from "layouts/store";
import './style.css';
import Web3 from 'web3';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import buffer from 'buffer';
import jsPDF from 'jspdf';
import { Panel } from 'primereact/panel';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

var web3 = new Web3('https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/')

var tituloGlobal=[];
export  class GNamaFinal extends React.Component {
  static contextType = UserContext
  state={
    collapse:[],
    notificationAlertRef: React.createRef(),
    displayCard:"none",
    dataQr:"Sin datos",
    loading:'Cargando...',
    collapsefuve:true,
    idTraza:null,
    codigoTrza:null,
    routeget: new URLSearchParams(window.location.search).get("code"),
    routegetTwo: new URLSearchParams(window.location.search).get("up"),
    loadingupload:'none',
    btnend:'block',
    'fruit_vegetable_user':'none',
    fordisplay:{},
    walletData:null,
    openModal:false,
    disabledbtn:false,
  }
  async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var status='';
    
    var walletdata = localStorage.getItem('user_wallet');
    var jsondatawallet = JSON.parse(walletdata);
    this.setState({walletData:jsondatawallet})
      var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
      var dataRow = await get_data_row_admin_kdes(jsondata._id,this.state.routeget,codigoTrazaTwo,null,jsondata.type_traza)
      Object.entries(dataRow['end_data_kdes']).map((a,b)=>{
        var name_nama = null
          if (jsondata.type_traza.indexOf('VF')!=-1) {
                  if(a[0]=='fruit_vegetable_user'){
                    this.setState({fruit_vegetable_user:(a[1].length==0)?'none':'flex'})
                    name_nama = 'Productor'
                  }
                  if(a[0]=='create_harvest_kdes'){
                    name_nama = 'Cosecha FDA'
                  }
          }

          if (jsondata.type_traza.indexOf('V1F')!=-1) {
                  if(a[0]=='create_plant_cooling_kdes'){
                    name_nama = 'Enfriamiento'
                  }

                  if(a[0]=='create_plant_product_kdes'){
                    name_nama = 'Producto inicial'
                  }
                  if(a[0]=='create_plant_receiver_kdes'){
                    name_nama = 'Recibir'
                  }
                  if(a[0]=='create_plant_shipping_kdes'){
                    name_nama = 'Enviar'
                  }
          }

          if (jsondata.type_traza.indexOf('V2F')!=-1) {
                  if(a[0]=='create_producer_receiving_kdes'){
                    name_nama = 'Recibir'
                  }
                  if(a[0]=='create_producer_transformation_kdes'){
                    name_nama = 'Transformacion'
                  }
                  if(a[0]=='create_producer_food_kdes'){
                    name_nama = 'Comida'
                  }
                  if(a[0]=='create_producer_shipping_kdes'){
                    name_nama = 'Enviar'
                  }
          }

          if (jsondata.type_traza.indexOf('V3F')!=-1) {
                  if(a[0]=='create_distributor_receiving_kdes'){
                    name_nama = 'Recibir'
                  }
                  if(a[0]=='create_distributor_shipping_kdes'){
                    name_nama = 'Enviar'
                  }
          }

          if (jsondata.type_traza.indexOf('V4F')!=-1) {
                  if(a[0]=='create_establishment_receiving_kdes'){
                    name_nama = 'Recibir'
                  }
          }




        tituloGlobal[b] = {Titulos:name_nama,fordisplay: a[0], Componente:<FinalKDES 
          title={name_nama} 
          table_ex={a[0]} 
          getData={this.getDataForqr} 
          putDataAll={a[1]} 
          dbCode={this.props.location?.state} 
          fullData={dataRow.end_data_kdes} />}
        

        this.setState({fordisplay:{...this.state.fordisplay,[a[0]]:(a[1].length==0)?'none':'flex'} })
      })

        //this.setState({frutasvegetales:(dataRow.fruit_vegetable==0)?'none':'flex'})
        // this.setState({fordisplay:{...this.state.fordisplay,['produccionagroquimicoview']:(dataRow.production_fertilizer==0)?'none':'flex'}})
        // this.setState({fordisplay:{...this.state.fordisplay,['cosechadatos']:(dataRow.create_cosecha==0)?'none':'flex'}})
        // this.setState({fordisplay:{...this.state.fordisplay,['cosechatransportecampoview']:(dataRow.transport_cosecha==0)?'none':'flex'}})
        // this.setState({fordisplay:{...this.state.fordisplay,['plantadatosview']:(dataRow.create_plant_product==0)?'none':'flex'}})
        // this.setState({fordisplay:{...this.state.fordisplay,['plantaempaqueview']:(dataRow.create_plant_information_empaque==0)?'none':'flex'}})
        // this.setState({fordisplay:{...this.state.fordisplay,['plantacamarafrio']:(dataRow.create_plant_pre_cold==0)?'none':'flex'}})
        // this.setState({fordisplay:{...this.state.fordisplay,['logisticatransporte']:(dataRow.logistics_transport_shipment==0)?'none':'flex'}})
        // this.setState({fordisplay:{...this.state.fordisplay,['comercializa']:(dataRow.commercialization_market==0)?'none':'flex'}})
  }
  componentWillMount(){
    tituloGlobal = []
  }
  getDataForqr = (data) => {
    //console.log(data)
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (data==null) {
      this.setState({
        loading:'Sin traza.'
      })
    }else{
      if (this.state.routeget==null || this.state.routeget==undefined) {
        var codigo_set = data.token_id
      }else{
        var codigo_set = this.state.routeget
      }
      this.setState({
        displayCard:"flex",
        dataQr:"http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code="+codigo_set+"&db="+jsondata.database,
        loading:null,
        idTraza:data.id,
        codigoTrza:codigo_set,
      })
    }
  }
//  printer_pdf = async () => {
//              await jQuery('.collapseIdewntifier').removeClass('collapse')
//              var counterCollapse=0
//              jQuery('.collapseIdewntifier').map((a,b)=>{
//               if (jQuery(b).hasClass('collapse')) {
//                 counterCollapse=counterCollapse
//                 counterCollapse++
//               }
//              })

//              var component = document.querySelector(".contentBlockChain").innerHTML
//              var divContents = component

//             var doc = new jsPDF({
//               orientation: 'portrait' ,
//               unit: 'px',
//               format: 'A3',
//             });
//             doc.setFont('Inter-Regular', 'normal');
//             this.setState({btnd:true})
//             var thisState = this
//             doc.html(divContents, {
//               async callback(doc) {
//                 var get_blob = await doc.output('bloburl')
//                 window.open(get_blob,'_blank')
//                 thisState.setState({btnd:false})
//                 jQuery('.collapseIdewntifier').addClass('collapse')
//               },
//                 x: 0,
//                 y: 0,
//                 width: 640,
//                 windowWidth: 1100
//             });
// }
 printer_pdf = async () => {
  window.scrollTo({ top:0, left:0, behavior: "instant"})
  var component = await document.querySelector(".contentBlockChain").innerHTML
  var divContents = component
  window.html2pdf().from(divContents).toPdf().get('pdf').then(function (pdf) {
  window.open(pdf.output('bloburl'), '_blank');
  });
}
render(){
  const getRoutesComponents = () =>{

  }
  const toggleFunctionFirst = () => {
      if (this.state.collapsefuve==true) {
        this.state.collapsefuve = false;
      }else{
        this.state.collapsefuve = true;
      }
  }
  const toggleFunction = (index,res) =>{
    if (res==undefined) {
    this.state.collapse[index] = true;
    }else{
      if (this.state.collapse[index]==true) {
        this.state.collapse[index] = false;
      }else{
        this.state.collapse[index] = true;
      }
    }
  }
  const saveInfoTraza = async() =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
        var result = await generate_trazabilidad_kdes(jsondata._id,this.state.idTraza,this.state.codigoTrza,jsondata.type_traza);
        if (result==null) {
          return
        }
        if (result.data.message=="exito") {
          this.context.setToastMess("success", "¡Registrado exitosamente!", 'Proceso finalizada.')          
          var thisFVar = this
          setTimeout(function(){thisFVar.props.history.push("/admin/process-kdes")}, 2000);
        }else{
          this.context.setToastMess("danger", "¡Registrado Fallido!", result.data)
          this.setState({btnd:false})
        }
  }

  const saveInfoTrazaQr =  async () =>{
      if (this.state.walletData==null) {
        this.props.history.push("/admin/wallet")
    }else{
      if (this.state.walletData.crypto!=null) {
        optionModal(true)
      }else{
       if(window.confirm('Estas seguro en finalizar este proceso?')){
         jQuery("#loadingupload").show();
         jQuery(".btnend").hide()
         var userdata = localStorage.getItem('user');
         var jsondata = JSON.parse(userdata);
         var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
         jQuery('.collapseIdewntifier').removeClass('collapse')
         var data = await generateChain(jsondata._id,this.state.routeget,this.props.history,this.state.idTraza,codigoTrazaTwo,this.state.walletData,jsondata.type_traza)
       }
      }
    }
  }
    const optionModal = (type) => {
    this.setState({openModal:type})
  };
  const accessAccount = (password) => {
    this.setState({disabledbtn:true})
    try{
        this.setState({disabledbtn:false})
        var privateWalletEncrip = web3.eth.accounts.decrypt(this.state.walletData,password)
        this.setState({walletData:privateWalletEncrip})
        optionModal(false)
        notify("success", "Cambio exitoso", "Cuenta desbloqueada.");
    }catch(error){
        notify("danger", "No se pudo guardar", "La contraseña es incorrecta.");
        this.setState({disabledbtn:false})
    }


  };
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    const accept = () => {
      saveInfoTraza()
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Cancelado', life: 3000 });
    }
    const confirm1 = () => {
        confirmDialog({
            message: 'Estas seguro en guardar esta información?, Tenga en cuenta que al momento de finalizar esta trazabilidad cualquier otra trazabilidad que este relecionado a un numero de lote de este proceso el estado pasara a tambien "finalizado".',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept,
            reject
        });
    };
    const confirmBlock = () => {
        confirmDialog({
            message: 'Estas seguro en guardar esta información en la blockchain?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => saveInfoTrazaQr(),
            reject
        });
    };
    
    const headerTemplate = (title,options) => {
        const className = `${options.className} justify-content-space-between text-white`;

        return (
            <div className={className} style={{ background: '#7ac142', border: '1px', borderStyle: 'solid', borderColor: '#002a5c', padding: '0px', paddingLeft: '5px' }}>
                <div className="flex align-items-center gap-2">
                    <span className="font-bold">{title}</span>
                </div>
                <div>
                    {options.togglerElement}
                </div>
            </div>
        );
    };
const contentBtn = []
if (this.state.info_producer_g != 'none') {
  if (this.state.routegetTwo!='uploadblock') {
    contentBtn[0] = <>
      <div className='col-md-1 flex justify-content-end'>
          <span className="p-buttonset">
              <Button 
              tooltip="Finalizar este proceso" 
              onClick={confirm1} 
              className='button-color-fod-blue' 
              icon="pi pi-save" 
              size='small' />
          </span>
      </div>
    </>
  }
    contentBtn[1] = <>
      <div className='col-md-1 flex justify-content-end'>
          <span className="p-buttonset">
              <Button 
              tooltip={(this.state.routegetTwo=='uploadblock')?('Guardar este proceso en la Blockchain'):('Finalizar y Guardar este proceso en la Blockchain')} 
              onClick={confirmBlock} 
              className='button-color-fod-blue' 
              icon="pi pi-cloud-upload" size='small' />
          </span>
      </div>
    </>
    contentBtn[2] = <>
      <div className='col-md-1 flex justify-content-end'>
          <span className="p-buttonset">
              <Button tooltip="Generar PDF" onClick={this.printer_pdf} className='button-color-fod-blue' icon="pi pi-file-pdf" size='small' />
          </span>
      </div>
    </>
}
  return (
    <>
    <ConfirmDialog />
    <ModalEditUserWallet
      sendOpenModal={this.state.openModal}
      setModal={optionModal}
      saveEncrypt={accessAccount} 
      disabledextend={this.state.disabledbtn} />

          <div className="mt-1">
              <div className="card">
                <div className="grid">
                    <div className='col-8'>
                        <h5 className='font-semibold'>
                            Vista de Verificación y Confirmación para Finalizar
                        </h5>
                    </div>
                    {contentBtn}
                </div>
              <div className="contentBlockChain">
                  { tituloGlobal.map((value,index)=>{
                  return (
                            <div className="field col-12" style={{display: this.state.fordisplay[value.fordisplay]}}>
                                <Panel headerTemplate={headerTemplate.bind(this,value.Titulos)} toggleable={true} collapsed={false}>
                                            <div className="text-center">
                                              {value.Componente}
                                            </div>
                                </Panel>
                            </div>
                           )  
                         })
                        }
                      {(this.state.loading==null)?(null):(
                        <Card className="mb-4">
                          <CardHeader id={"sectionnull"}>
                            <a style={{color: "#32325d",fontWeight:"600"}} href={"#sectionnull"} className="mb-0">{this.state.loading}</a>
                          </CardHeader>
                        </Card>
                        )}
              </div>
              <div className="grid">
              {contentBtn}
              </div>
            </div>
      
            </div>
    </>
  );

 }
}

export class ModalEditUserWallet extends React.Component {
  state={
    password:''
      }
render(){
  return (
  <Modal isOpen={this.props.sendOpenModal} size="md">
        <ModalBody>
          <CardHeader>
            <Row>
            <Col md={11}>
            <h3 className="mb-0">Desbloquear Cuenta</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {this.props.setModal(false)} }>
              <h3 className="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>

        <Card className="mb-4">
                <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                    placeholder="Escribe una contraseña para encryptar"
                    type="password"
                  />
                </FormGroup>
              </Col>
          <CardBody>
            <Row>
              <Col md="5">
                <Button onClick={()=>{this.props.saveEncrypt(this.state.password)}} disabled={this.props.disabledextend} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Aceptar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.props.setModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
  );
 }
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false,
    set_traza:''
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    this.setState({set_traza:dataPermission.type_traza})
    if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
      this.setState({get_permission:false})
      return;
    }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
              this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <GNamaFinal {...this.props} putTraza={this.state.set_traza}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
