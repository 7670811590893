export const put_arr_data_kdes = [
      {
        table_receive:{table:'create_establishment_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_establishment_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        prefix:'detailsReceiving-',
        name_label:'Detallista-Tienda',
        data_f_config_vt:{title:'title_establish',view:'view_table_establish'},
        data_details_select:[
                              {label:'Datos Recibido',value:'create_establishment_receiving_kdes'}
                             ],
		icon:'https://cdn-icons-png.flaticon.com/512/862/862893.png'
        //icon:'fas fa-store-alt'
      },
      {
        table_receive:{table:'create_distributor_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_distributor_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'distributorShipp-',
        name_label:'Distributor',
        data_f_config_vt:{title:'title_distributor',view:'view_table_distributor'},
        data_details_select:[
                              {value:'create_distributor_receiving_kdes',label:'Datos Recibido'},
                              {value:'create_distributor_shipping_kdes',label:'Datos Enviado'}
                             ],
		icon:'https://cdn-icons-png.flaticon.com/512/4363/4363531.png'
        //icon:'fas fa-shipping-fast'
      },
      {
        table_receive:{table:'create_distributor_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_distributor_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'distributorReceiv-',
        name_label:'Distributor',
        data_f_config_vt:{title:'title_distributor',view:'view_table_distributor'},
        data_details_select:[
                              {value:'create_distributor_receiving_kdes',label:'Datos Recibido'},
                              {value:'create_distributor_shipping_kdes',label:'Datos Enviado'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/1524/1524714.png'
        //icon:'fab fa-get-pocket'
      },

      {
        table_receive:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importShipp-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
		icon:'https://cdn-icons-png.flaticon.com/512/10485/10485926.png'
        //icon:'fas fa-shipping-fast'
      },

      {
        table_receive:{table:'create_producer_food_kdes',change:'change_nuevo_codigo_de_lote_del_alimento',codigo:'nuevo_codigo_de_lote_del_alimento'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importFood-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
        icon:'https://cdn-icons-png.flaticon.com/512/562/562678.png'
        //icon:'fas fa-fish'
      },

      {
        table_receive:{table:'create_producer_transformation_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importTrans-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
        icon:'https://cdn-icons-png.flaticon.com/512/2211/2211642.png'
        //icon:'fas fa-cogs'
      },

      {
        table_receive:{table:'create_producer_receiving_kdes',change:'change_codigo_de_lote_de_trazabilidad_del_alimento',codigo:'codigo_de_lote_de_trazabilidad_del_alimento'},
        //table_shipping:{table:'create_producer_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'importReceiv-',
        name_label:'Importador',
        data_f_config_vt:{title:'title_produce',view:'view_table_produce'},
        data_details_select:[
                              {label:'Datos Recibido',value:'create_producer_receiving_kdes'},
                              {label:'Datos Comida',value:'create_producer_food_kdes'},
                              {label:'Datos Transformación',value:'create_producer_transformation_kdes'},
                              {label:'Datos Envio',value:'create_producer_shipping_kdes'},
                            ],
		icon:'https://cdn-icons-png.flaticon.com/512/7046/7046452.png'
        //icon:'fab fa-get-pocket'
      },

      {
        table_receive:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantShipp-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Inicial',value:'create_plant_product_kdes'},
                              {label:'Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/1585/1585176.png'
        //icon:'fas fa-shipping-fast'
      },

      {
        table_receive:{table:'create_plant_product_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantProduct-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Inicial',value:'create_plant_product_kdes'},
                              {label:'Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/15453/15453574.png'
        //icon:'fas fa-cog'
      },

      {
        table_receive:{table:'create_plant_cooling_kdes',change:'change_codigo_de_lote_que_asignado',codigo:'codigo_de_lote_que_asignado'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantCooling-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Inicial',value:'create_plant_product_kdes'},
                              {label:'Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/9644/9644745.png'
        //icon:'fas fa-temperature-high'
      },

      {
        table_receive:{table:'create_plant_receiver_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        //table_shipping:{table:'create_plant_shipping_kdes',change:'change_codigo_de_lote_de_trazabilidad_que_asignaste',codigo:'codigo_de_lote_de_trazabilidad_que_asignaste'},
        prefix:'plantReceiver-',
        name_label:'Empaque',
        data_f_config_vt:{title:'title_packing',view:'view_table_packing'},
        data_details_select:[
                              {label:'Recibido',value:'create_plant_receiver_kdes'},
                              {label:'Enfriamiento',value:'create_plant_cooling_kdes'},
                              {label:'Inicial',value:'create_plant_product_kdes'},
                              {label:'Envio',value:'create_plant_shipping_kdes'}
                             ],
        icon:'https://cdn-icons-png.flaticon.com/512/6384/6384822.png'
        //icon:'fab fa-get-pocket'
      }

    ]

export const findCodeLoteGlobal =(prefix, code, data)=>{
      var dataReturn = 'none'
      var getCode = code.split(prefix)
      var getDataLoteFVU = data.allDataTrza.fruit_vegetable_user.data_array.filter((a) => a.lote_numero==getCode[1])
      var getDataLotePCS = data.allDataTrza.create_plant_receiver_kdes.data_array.filter((a) => a.codigo_de_lote_de_trazabilidad_que_asignaste==getCode[1])
      var getDataLoteCPS = data.allDataTrza.create_producer_receiving_kdes.data_array.filter((a) => a.codigo_de_lote_de_trazabilidad_del_alimento==getCode[1])
      var getDataLoteCDS = data.allDataTrza.create_distributor_shipping_kdes.data_array.filter((a) => a.codigo_de_lote_de_trazabilidad_que_asignaste==getCode[1])

    if (prefix=='plantReceiver-') {
      var getCode = code.split(prefix)
      if (getDataLoteFVU.length!=0) {
        dataReturn = 'producer-'+getDataLoteFVU[0].lote_numero
      }
    }

    if (prefix=='import-') {
      if (getDataLoteFVU.length!=0) {
        dataReturn = 'producer-'+getDataLoteFVU[0].lote_numero
      }
      if (getDataLotePCS.length!=0) {
        dataReturn = 'plantReceiver-'+getDataLotePCS[0].codigo_de_lote_de_trazabilidad_que_asignaste
      }
    }

    if (prefix=='distributor-') {
      if (getDataLoteFVU.length!=0) {
        dataReturn = 'producer-'+getDataLoteFVU[0].lote_numero
      }
      if (getDataLotePCS.length!=0) {
        dataReturn = 'plantReceiver-'+getDataLotePCS[0].codigo_de_lote_de_trazabilidad_que_asignaste
      }
      if (getDataLoteCPS.length!=0) {
        dataReturn = 'import-'+getDataLoteCPS[0].codigo_de_lote_de_trazabilidad_del_alimento
      }
    }

    if (prefix=='details-') {
      if (getDataLoteFVU.length!=0) {
        dataReturn = 'producer-'+getDataLoteFVU[0].lote_numero
      }
      if (getDataLotePCS.length!=0) {
        dataReturn = 'plantReceiver-'+getDataLotePCS[0].codigo_de_lote_de_trazabilidad_que_asignaste
      }
      if (getDataLoteCPS.length!=0) {
        dataReturn = 'import-'+getDataLoteCPS[0].codigo_de_lote_de_trazabilidad_que_asignaste
      }
      if (getDataLoteCDS.length!=0) {
        dataReturn = 'distributor-'+getDataLoteCDS[0].codigo_de_lote_de_trazabilidad_que_asignaste
      }
    }
      return dataReturn
  }

export const searhCodeLoteGlobal =(prefix, data, code, edIndx)=>{
  var getCode = code.split(prefix)
  //console.log(prefix, code, edIndx,getCode[1])
  var filterData = {}
  //var counter = 0
  var getDataMap = data.map((a,b)=>{
    if (a.idSource==undefined) {
      if (a.idTarget.indexOf(getCode[1])!=-1) {
        //return a
        filterData[edIndx]=a
      }
    }else{    
  //console.log(getCode[1],a.idSource,a.idSource.indexOf(getCode[1]))
      if (a.idSource.indexOf(getCode[1])!=-1) {
        if (a.idSource!=code) {
          //return a
          filterData[b-1]=a
        }else{
          a['idSource']=a['idSource']+'-'+1
          filterData[b-1]=a
        }
      }
    }
  })

  if (Object.values(filterData).length==1) {
    if (filterData[edIndx]!=undefined) {
      if (filterData[edIndx].idSource==undefined) {
        return filterData[edIndx].idTarget
      }else{
        return filterData[edIndx].idSource
      }
    }else{
        var getId = 0
        Object.entries(filterData).map((af,bf)=>{
          //console.log()
          getId=af[0]
        })
          return filterData[getId].idSource
    }
  }else{
    if (filterData[edIndx]!=undefined) {
      if (filterData[edIndx].idSource==undefined) {
        return filterData[edIndx].idTarget
      }else{
        return filterData[edIndx].idSource
      }
    }
  }
}