import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold, data_production, get_permission } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import FormPlantFreezPreCold from "./ModalExtend/Subs/FormPlant/FormPlantFreezPreCold";
import FormPlantFreezCold from "./ModalExtend/Subs/FormPlant/FormPlantFreezCold";
export default class PlantaCamaraFrio extends React.Component {
  state={
    link_id:null
  }
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }  
  render(){
  return (
    <>
      <FormPlantFreezPreCold {...this.props} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal} />
      <FormPlantFreezCold {...this.props} putLinkId={this.state.link_id} />

    </>
  );
 }
}

// import React, {createRef, useEffect} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import jQuery from 'jquery';
// import config from "config";

// export default class PlantaCamaraFrio extends React.Component {
//     state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//   }
//     async componentDidMount(){
//             var documentForm = jQuery("#insertDocumentShow").val()
//           if (documentForm=='none') {
//             this.setState({documentForm:documentForm})
//           }
//           if(this.props.paramsCode==undefined || this.props.paramsCode==null){
//             var codigoTraza = 'nocode';
//           }else{
//             var codigoTraza = this.props.paramsCode;
//           }
//           var userdata = localStorage.getItem('user');
//           var jsondata = JSON.parse(userdata);
//           var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
//           var dataTrazabilidadvf = await data_cold_pre_cold(jsondata._id,codigoTraza,codigoTrazaTwo)
//           if (dataTrazabilidadvf.pre_cold==null) {
//             this.setDataCamposEmpty()
//           }else{
//             this.setDataCampos(dataTrazabilidadvf)
//           }
//   }
//       setDataCamposEmpty = ()=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//             this.setState({
//                 plant_pre_cold_id:'noind',
//                 lote_numero:'',
//                 fecha_inicio:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 hora_inicio:timeString,
//                 temperatura_inicio:'',
//                 temperatura_final:'',
//                 informacion_adicional_pre_cold:'',
//                 file_document_pre_cold:undefined,

//                 plant_cold_id:'noind',
//                 lote_numero_cold:'',
//                 fecha_inicio_cold:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 hora_inicio_cold:timeString,
//                 temperatura_inicio_cold:'',
//                 temperatura_final_cold:'',
//                 unidad_refrigerar:'',
//                 total_unidades_refrigerar:'',
//                 informacion_adicional_cold:'',
//                 file_document_cold:undefined,
//             })
//       }

//     setDataCampos = (data)=>{
//      if (data.pre_cold!=null) {
//         this.setState({
//                 plant_pre_cold_id:data.pre_cold.id,
//                 lote_numero:data.pre_cold.lote_numero,
//                 fecha_inicio:data.pre_cold.fecha_inicio,
//                 hora_inicio:data.pre_cold.hora_inicio,
//                 temperatura_inicio:data.pre_cold.temperatura_inicio,
//                 temperatura_final: data.pre_cold.temperatura_final,
//                 informacion_adicional_pre_cold:data.pre_cold.informacion_adicional,
//                 file_document_pre_cold:data.pre_cold.archivo,
//         })
//       }
//       if (data.cold!=null) {
//         this.setState({
//                 plant_cold_id:data.cold.id,
//                 lote_numero_cold:data.cold.lote_numero,
//                 fecha_inicio_cold:data.cold.fecha_inicio,
//                 hora_inicio_cold:data.cold.hora_inicio,
//                 temperatura_inicio_cold:data.cold.temperatura_inicio,
//                 temperatura_final_cold: data.cold.temperatura_final,
//                 unidad_refrigerar: data.cold.unidad_refrigerar,
//                 total_unidades_refrigerar: data.cold.total_unidades_refrigerar,
//                 informacion_adicional_cold: data.cold.informacion_adicional,
//                 file_document_cold: data.cold.archivo,
//         })
//       }
//     }
//   render(){

//   return (
//     <>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Pre-Enfriamiento</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Numero
//                   </label>
//                   <br />
//                   <span>{this.state.lote_numero}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Inicio
//                   </label>
//                   <br />
//                   <span>{this.state.fecha_inicio}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora de Inicio
//                   </label>
//                   <br />
//                   <span>{this.state.hora_inicio}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Inicio
//                   </label>
//                   <br />
//                   <span>{this.state.temperatura_inicio}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Final
//                   </label>
//                   <br />
//                   <span>{this.state.temperatura_final}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}} sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_pre_cold}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}} sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label> <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_pre_cold==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_pre_cold}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4" style={{display:(this.state.lote_numero_cold==undefined)?('none'):('block')}}>
//           <CardHeader>
//             <h3 className="mb-0">Enfriamiento</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Numero
//                   </label>
//                   <br />
//                   <span>{this.state.lote_numero_cold}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Inicio
//                   </label>
//                   <br />
//                   <span>{this.state.fecha_inicio_colda}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora de Inicio
//                   </label>
//                   <br />
//                   <span>{this.state.hora_inicio_cold}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Inicial
//                   </label>
//                   <br />
//                   <span>{this.state.temperatura_inicio_cold}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Final
//                   </label>
//                   <br />
//                   <span>{this.state.temperatura_final_cold}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Unidad a Refrigerar
//                   </label>
//                   <br />
//                   <span>{this.state.unidad_refrigerar}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}}>
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades a Refrigerar
//                   </label>
//                   <br />
//                   <span>{this.state.total_unidades_refrigerar}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}} sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_cold}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor: "initial"}} sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_cold==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_cold}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//           </CardBody>
//         </Card>
//     </>
//   );
//  }
// }

