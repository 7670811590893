import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeaderProd from "components/Headers/SimpleHeaderProd.js";
import React, {createRef} from "react";
import { save_global_g, data_trazabilidad, main_content, delete_file_prod } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import GrainNamaNavBar from "views/pages/components/GrainNamaNavBar.js";
import NotificationAlert from "react-notification-alert";
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import axios from 'axios';
import {getContent, PutFormContent, PutFormContentPro, forNumeric} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";
import jQuery from 'jquery'
import GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob } from 'geotiff';
import * as plotty from 'plotty';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

 export class GNama extends React.Component {
  static contextType = UserContext
  state={
    notificationAlertRef: React.createRef(),
    field_params:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],
    dataStatesS:0,
    content_render:null
  }
  async componentDidMount(){
    var someUrl='https://api.foodchaintrazabilidad.com/foodchain_developer/foodchain-back/public/api/test_fast_satelite'
    //const tiff = await fromArrayBuffer('https://api.foodchaintrazabilidad.com/foodchain_developer/foodchain-back/public/api/test_fast_satelite');
//  const response = await fetch(someUrl,{method:'POST',body: JSON.stringify({getUrl:'https://app.itrio.ai/download/image?d=2023-09-09&s=sentinel2&f=farm-0000000001'})});
//  const arrayBuffer = await response.arrayBuffer();
// // const tiff = await fromArrayBuffer(arrayBuffer);
//  console.log(arrayBuffer)


    var getData = await main_content('info_producer_g')
    this.setState({field_content_log:getData})
    //var dateTim = date.getDate() + 3;
    this.initData()
    jQuery('.btnRemoveRegisterProd').remove()
  }
  initData = async ()=>{
  this.setState({field_content: getContent(this.state.field_content_log).s_data})

    //this.setState({codigoAran:CodigoAran})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");
        var dataTrazabilidadvf = await data_trazabilidad(jsondata._id,gerParam,null,'info_producer_g',JSON.stringify(getContent(this.state.field_content_log).f_data))
        var dataRestore = localStorage.getItem('data_register_storage_info_producer_g')
        if (dataRestore!=null) {
          dataTrazabilidadvf=JSON.parse(dataRestore)
        }
        
        //this.state.myVarTime = setInterval(() => this.setDataCode(), 500);
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
    if (this.props.location.state!=null) {
      if (this.props.location.state.verify_mrv=='ON') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvON').click()
        })
      }
      if (this.props.location.state.verify_mrv=='RE') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvRE').click()
        })
      }
      if (this.props.location.state.verify_mrv=='VA') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvVA').click()
        })
      }
    }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {

      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
      }
        if (prevState.field_params !== this.state.field_params) {
        }
      }
    setDataCampos = (data)=>{
      var date = new Date()
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      if (data?.id==undefined) {
        this.setState({idT:null})
      }else{
        this.setState({idT:data?.id})
      }
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      var field_params_select={}
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
         if (a.type_field=='date') {
          if (data[a.name_field]==null) {
           this.setState({field_params: {...this.state.field_params, [a.name_field]:date }})
          }
         }
         if (a.type_field=='time') {
          if (data[a.name_field]==null) {
             this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
         }
          if (a.type_field=='file') {
              if (data[a.name_field]==null) {
                var multi_fil = [{name:'',filed:null}]
                this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
              }else{
                if (typeof data[a.name_field]=='string') {
                 if (data[a.name_field].split('|').length>0) {
                  var multi_fil = []
                   data[a.name_field].split('|').map((a,b)=>{
                     multi_fil[b] = {name:a,filed:null}
                   })
                    var item = []
                    item = [...multi_fil]
                  //field_params_params[a.name_field]=item
                  this.setState({field_params: {...this.state.field_params, [a.name_field]:item }})
                 }
                }
              }
          }
        // if (a.type_field=='select') {
        //   this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
        //   if (a.list_default=='ldp') {
        //     this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
        //   }
        // }

         if (a.type_field=='select') {
            var get_data_select = this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            console.log(Object.values(get_data_select))
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            //field_params_select[a.name_field] = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            //field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }

      })
         this.setState({optionsSelect: field_params_select})
         this.setState({selectForFilter: field_params_select})
    }
    cleanData = () =>{
          this.setState({idT:'null'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
          var field_params_select={}
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        if (a.type_field=='file') {
              var multi_fil = [{name:'',filed:null}]
              this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        // if (a.type_field=='select') {
        //   this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
        //   if (a.list_default=='ldp') {
        //     this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
        //   }
        // }

         if (a.type_field=='select') {
            var get_data_select =this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')

            //field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }

      })
         this.setState({optionsSelect: field_params_select})
         this.setState({selectForFilter: field_params_select})
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async () =>{
      //this.setState({btnd:true})
      //this.setState({mess:'Enviando...'})
      if (this.state.field_params_required==undefined) {
        //this.setState({btnd:false})
        return
      }
    var field_data = Object.entries(this.state.field_params)
    var field_data_remove=[]
    var counter_f= 0
    field_data.map((c,i)=>{
      if (c[0]!='id') {
        field_data_remove[counter_f]=c
      }
      counter_f++
    })
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess_asis = []
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {
          if (field_data[b][0]==a[0]) {
           if (a[1]==1 && (field_data[b][1]=='' || field_data[b][1]==null)) {
            counter++
            var get_t = field_data_name.filter(af=>af[0]==a[0])
            if (get_t[0]!=null) {
              data_mess_asis[counter]=get_t[0][1]
              data_mess[counter] = 'El campo '+get_t[0][1]+' es requerido';
            }else{
              data_mess_asis[counter]=a[0]
              data_mess[counter] = 'El campo '+a[0]+' es requerido';
            }
           }
          }
        }
    })
    if (data_mess.length>0) {
      window.setAssistantValidate(data_mess_asis.filter(Boolean)[0],'fields_validations')
      this.context.setToastMess('error', 'Validación', data_mess.filter(Boolean)[0])
      //this.setState({mess: data_mess.filter(Boolean) })
      this.setState({btnd:false})
      return
    }else{
      if (this.props.linkValidatePermitions!=undefined) {
        if (this.props.linkValidatePermitions) {
          //console.log(this.props.linkValidatePermitions)
          this.props.putSaveCodeProp("validate")
          return
        }
      }
      this.setState({btnd:false})
      this.setState({mess:''})
    }

      var gerParam = new URLSearchParams(this.props.location.search).get("code");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      this.setState({btnd:true})
       const var_vf = await save_global_g(jsondata._id,field_data_remove.sort(),field_data_name.sort(),gerParam,'info_producer_g',this.state.idT,data_for_file,'Información productor','FCTGN-');
      if (var_vf==null) {
        this.setState({btnd:false})
        return
      }
           if (var_vf.data.message=="exito") {
             this.context.setToastMess('success', 'Guardado', 'Informacion actualizado')
             this.setState({mess:''})
             localStorage.removeItem('data_register_storage_info_producer_g')
             window.setAssistantValidate(null,'save_success')
             //this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
             await this.props.refreshData()
             //await this.initData()
             this.setState({btnd:false})
              if (this.props.putSaveCodeProp!==undefined) {
                 this.props.putSaveCodeProp(var_vf.data.codigo)
              }
             if (this.props.location.search=='') {
             this.props.hide()
              //this.props.history.push('/admin/list-trace-production')
             }
           }
      
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
 geolocacion = (options) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(e,options)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (posicion,optcon)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    this.setFieldValue(lat+','+lon+'-(Cargando otros datos)',optcon.id,optcon)
    //this.setFieldValue(lat+','+lon,optcon.id,optcon)
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }

    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    // $('.cargadores').hide();
    }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
              //<option value={data_obj[i]}>{data_obj[i]}</option>
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
      // if (this.state.field_params[field]!=undefined) {
      //       return this.state.field_params[field]
      // }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
      var thisSet = this
      setTimeout(function() {
        thisSet.setSessionTimer()
      },500)
    }
     setFieldValue = (value, field, isrequired, type_field) =>{
      this.setState({field_params: {...this.state.field_params, [field]:value }})
      if (isrequired!=null) {      
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
        if (isrequired.getAttribute('data_filter')!=null) {
          isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
            if (a!='upf') {
              Object.entries(this.state.selectForFilter).map((val,indx)=>{
                if (val[0]==a) {
                  var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                  this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
                }
              })
            }
          })
        }
      }
      var thisSet = this
      setTimeout(function() {
        thisSet.setSessionTimer()
      },500)
    }
    setSessionTimer =()=>{
      localStorage.setItem('data_register_storage_info_producer_g', JSON.stringify(this.state.field_params) )
    }
     setFieldValueFiles = (value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod(this.state.idT,'info_producer_g',field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
            //this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
             //var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
          }
         }
      this.setState({field_params: item })
       }
    }
    //  selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
    //       var dataState=[]
    //       var puData={}
    //       if (list_default=='ldp') {
    //         if (for_values!=undefined && for_values!='') {
    //            if (this.state.field_content.length>0) {
    //              for (var i = 0; i < this.state.field_content.length; i++) {
    //                if (this.state.field_content[i].list_default!=null) {
    //                  if (this.state.field_content[i].list_default=='lde') {
    //                   if (type=='name') {
    //                     dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
    //                   }
    //                   if (type=='code') {
    //                     dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
    //                   }
    //                    if (dataState.length>0) {
    //                     if (this.state.field_content[i].type_field=="select") {
    //                       puData[this.state.field_content[i].name_field] = this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default)
    //                     //this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
    //                     }
    //                    }
    //                  }
    //                }
    //              }
    //              this.setState({optionsSelect: {...this.state.optionsSelect, ...puData }})
    //            }
    //         }
    //       }
    // }

     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          var dataSelect = {}
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        dataSelect = this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default)
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
          return dataSelect
    }

    //  selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
        
    //   if (this.state.optionsSelect[name_field]==undefined) {
    //       if (list_default=='ldp') {
    //         var var_data = this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
    //         // this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
    //       }
    //       if (list_default=='cafv') {
    //         // this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
    //       }
    //       if (list_default=='cac') {
    //         // this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
    //       }
    //       if (list_default==null || list_default=='0') {
    //         var for_put_data=this.contentForSelect(value_option,name_field,req_field,list_default)
    //         // this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]: for_put_data }})
    //       }
    //   }
    //   this.setState({selectForFilter: this.state.optionsSelect})
    // }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      var dataSelect = {}
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          dataSelect = this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          //var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          dataSelect = this.contentForSelect(CodigoAranF,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          dataSelect = this.contentForSelect(CodigoAranM,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
          //console.log(dataSelect,list_default,value_option,name_field,req_field,for_values)
      //this.setState({selectForFilter: this.state.optionsSelect})
      return dataSelect
          //console.log(list_default,value_option,name_field,req_field,for_values,this.state.optionsSelect)
    }

    //  selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
    //   if (this.state.optionsSelect[name_field]==undefined) {
    //       var dataSlectOne = {}
    //       var dataSlectTwo = {}
    //       var dataSlectThree = {}
    //       var dataSlectFor = {}
    //       if (list_default=='ldp') {
    //         var var_data = []
    //         var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
    //         dataSlectOne={[name_field]:var_data}
    //         //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
    //       }
    //       if (list_default=='cafv') {
    //         dataSlectTwo={[name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default)}
    //         //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
    //       }
    //       if (list_default=='cac') {
    //         dataSlectThree={[name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default)}
    //         //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
    //       }
    //       if (list_default==null || list_default=='0') {
    //         var for_put_data=this.contentForSelect(value_option,name_field,req_field,list_default)
    //         dataSlectFor={[name_field]:for_put_data}
    //         //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]: for_put_data }})
    //       }
    //       console.log(list_default,value_option,name_field,req_field,for_values)
    //   }
    //   this.setState({selectForFilter: this.state.optionsSelect})
    // }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
     listCountryCode = CountrysData.sort(this.listaArray)
  render(){
    const accept = () => {
      this.saveInfo()
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Cancelado', life: 3000 });
    }
    const confirm1 = () => {
        confirmDialog({
            message: 'Estas seguro en guardar esta información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept,
            reject
        });
    };
  return (
    <>
{/*      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <GrainNamaNavBar getParams={this.props.location.search}/>
      <SimpleHeaderProd name="Iniciar NAMA" parentName="Información General" section="namas" section_two="Informacion_General" section_table_form="info_producer_g" mrvtable="info_producer_g" />*/}
        <ConfirmDialog />
        <div id="insideModal-info_producer_g-Información_Productor">
            <div className="p-fluid mt-5">
              <div className="formgrid grid">
                <PutFormContentPro 
                content={this.state.field_content}
                field_params={this.state.field_params}
                optionsSelect={this.state.optionsSelect}
                setFieldValueFiles={this.setFieldValueFiles}
                deleteImgState={this.deleteImgState}
                setFieldValue={this.setFieldValue}
                setFieldValueSelect={this.setFieldValueSelect}
                forSearhData={this.forSearhData}
                geolocacion={this.geolocacion}
                 />
              </div>
            </div>

            <div className="absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400">
                <div className="flex justify-content-end pt-3 pr-3 pl-3">
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button 
                                label="Cerrar"
                                 severity="secondary" 
                                 onClick={() => this.props.hide()}
                                 id="closeModal"
                                 size="small" />
                        </div>

                        <div className="field col">
                            <Button 
                            disabled={this.state.btnd}
                            id="saveModal"
                            onClick={confirm1} label="Guardar" size="small" className='btn-fod-green' />
                        </div>
                    </div>
                </div>
            </div>
{/*                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}*/}
            
        </div>
{
/*      <div className="mt-1 container-fluid">
        <Card className="mb-4">
          <CardBody className="pt-0">
            <Row>
            </Row>
              <div className="percent_load"></div>
                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}
              <Col md="12" style={{textAlign:'end'}}>
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button"
                disabled={this.state.btnd}
                onClick={()=>{this.saveInfo()}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-save" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
          </CardBody>
        </Card>
      </div>*/}
    </>
  );
}
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    this.setInfoInit()
  }
componentDidUpdate(){
  //this.setInfoInit()
}

 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }

    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('P')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
//     var data_type_t = dataPermission.type_traza.indexOf('G')
// if (data_type_t==-1 || dataPermission.type_traza==null) {
//   this.setState({get_permission:false})
//   return;
// }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.information_nama_g==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
        
      }
render(){
  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <GNama {...this.props} />
        ):(<>
        {/*<GrainNamaNavBar getParams={window.location.search}/>*/}
        <ViewPermision />
        </>)
      )}
    </>
  );
 }
}
export default SwiftComponent;
