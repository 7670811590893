import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
//import { useRouter } from 'next/navigation';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";

import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";

import GNamaPLPreparation from "views/pages/forms/Production/Grains/GrainNama/GestionOfCultiveExtend.js";
import GestionOfCultiveSelectedFieldExtend from "views/pages/forms/Production/Grains/GrainNama/GestionOfCultiveSelectedFieldExtend.js";
// import GNamaPLIrrigation from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLIrrigationForm.js";
// import GNamaPLSeed from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLSeedForm.js";
 import GNamaPLFertilitation from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLFertilitationForm.js";
// import GNamaPLsowing from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLsowingForm.js";
// import GNamaPLMonitoring from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLMonitoringForm.js";
 import GNamaWeather from "views/pages/forms/Production/Grains/GrainNama/GrainNamaWeather.js";
 import GNamaPLSatelite from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLSateliteForm.js";

export function FunctionNameTest(argument) {
     const router = useHistory()
    // console.log(router,argument,router.location.search)
    return router
}
class CardProduction extends React.Component {
  state={
    selectedProdItems:null,
    contentActivity:null,
    visibleRight:false
  }
//const CardProduction = () => {
    render () {
    //const router = useHistory()
    
    const itemsProd = [
        { name: 'Preparación de Tierra', table: 'production_land_preparation_g', link: '/admin/production_land_preparation_g', content: GNamaPLPreparation, permission_column: 'production_nama_g' },
        { name: 'Riego', table: 'production_irrigation_g', link: '/admin/production_irrigation_g', content: GNamaPLPreparation, permission_column: 'production_nama_g' },
        { name: 'Semilla', table: 'production_seed_g', link: '/admin/production_seed_g', content: GNamaPLPreparation, permission_column: 'production_nama_g' },
        { name: 'Fertilización/Agroquímicos', table: 'production_fertilizer_g', link: '/admin/production_fertilizer_g', content: GNamaPLFertilitation, permission_column: 'production_nama_g' },
        { name: 'Siembra', table: 'production_sowing_g', link: '/admin/production_sowing_g', content: GestionOfCultiveSelectedFieldExtend, permission_column: 'production_nama_g' },
        { name: 'Transplante', table: 'production_sowing_transplant', link: '/admin/production_sowing_g', content: GNamaPLPreparation, permission_column: 'production_nama_g' },
        { name: 'Monitoreo Productor', table: 'production_producer_monitoring_g', link: '/admin/production_producer_monitoring_g', content: GNamaPLPreparation, permission_column: 'production_nama_g' },
        { name: 'Clima', table: 'production_producer_monitoring_g', link: '/admin/production_info_weather', content: GNamaWeather, permission_column: 'production_nama_g' },
        { name: 'Satélite', table: 'production_info_satelite_dni_code', link: '/admin/production_info_satelite', content: GNamaPLSatelite, permission_column: 'production_nama_g' },
    ];

    const header = (
        <img 
            alt="production" 
            src="/layout/images/icons/tractor.png"  
            className='w-2'
        />
    );

    const selectedItemTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const itemOptionTemplate = (option) => {
        return (
            <div 
                    // onClick={ () => {
                    //     this.setState({contentActivity: option.content })
                    //     this.setState({visibleRight:true}) 
                    // } } 
                    className="flex align-items-center">
                <div>
                    {option.name}
                </div>
            </div>
        );
    };
    return (
        <div>
            <Card title="Producción" header={ header } >

                <Dropdown 
                    value={this.state.selectedProdItems} 
                    onChange={(e) => { 
                        this.setState({selectedProdItems:e.value})
                        if (e.value!=undefined) {
                            this.setState({contentActivity: e.value.content })
                            this.setState({visibleRight:true}) 
                        }
                    }}
                    options={ itemsProd } 
                    optionLabel="name" 
                    showClear 
                    placeholder="Selecciona" 
                    className="w-full p-inputtext-sm" 
                    itemTemplate={ itemOptionTemplate } 
                    dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                    }}
                />
                    <p className="mt-4">
                        {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                    </p> 
            </Card>

              <SidebarCustom 
                  onVisible={this.state.visibleRight}
                  onHide={() => {
                        this.setState({
                            contentActivity:null,
                            visibleRight:false,
                            selectedProdItems:null
                        })
                    }}
                  onWidth={ this.state.selectedProdItems?.table=='production_info_satelite_dni_code' ? ((document.body.offsetWidth<=991)?("100%"):(800))  : ((document.body.offsetWidth<=991)?("100%"):(800)) }
                  position="right" 
                  title={this.state.selectedProdItems?.name}
                  >
                    {
                        (this.state.contentActivity==null)
                        ?
                        ('Contenido.')
                        :
                        (<this.state.contentActivity {...this.props} itemProd={this.state.selectedProdItems} hide={ () => {this.setState({visibleRight:false})} } />)
                    }
              </SidebarCustom>

        </div>
    )
  }
}

export default CardProduction;