import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table
} from "reactstrap";
import React from "react";
import IndexNavbar from "components/Navbars/NavbarTrace.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import { get_data_row_admin_grain_vt } from "network/ApiAxios";
import SectionNamaG from "views/pages/examples/Production/SectionViewTraceGN/SectionNamaGView";
import SectionNamaGP from "views/pages/examples/Production/SectionViewTraceGN/SectionNamaGPView";
import SectionNamaGM from "views/pages/examples/Production/SectionViewTraceGN/SectionNamaMPView";
import SectionNamaGH from "views/pages/examples/Production/SectionViewTraceGN/SectionNamaGHView";
import SectionDocuments from "views/pages/examples/Production/SectionViewTraceGN/SectionNamaDocumentView";

import { NavLink as ComponentLink } from "react-router-dom";
import {GuideMessage} from "views/pages/components/GuideMessage";

import "./StyleCss.css";
import informacion from "assets/img/icons/Informacion.jpg";
import Produccion from "assets/img/icons/Produccion.jpg";
import Fitosanitario from "assets/img/icons/Fitosanitario.jpg";
import Cosecha from "assets/img/icons/Cosecha.jpg";
import Folder from "assets/img/icons/Folder.jpg";
import jQuery from "jquery";
export default class VistaTraceProd extends React.Component {
	state ={
		statusClass:[],
		statusColor:[],
		allDataTrza:null,
		code_traza:'Cargando...',
		data_user:null,
		table_nama_section:[],
		components:SectionNamaG,
	}
	async componentDidMount(){
		var gerParam = null
		if (gerParam==null) {
			this.setState({code_traza:'Sin Trazabilidad'});
		}else{
		this.setNameDB(gerParam)
		}
	}
  componentDidUpdate(devProps,devState){
    if (this.props.code!=devProps.code) {
     this.setNameDB(this.props.code)
    }
  }
	configAllDataStatus = (dataRow) => {
//['create_plant_cold','create_plant_information_empaque','create_plant_pre_cold','create_plant_product','create_plant_sample','create_plant_treatment','create_plant_weight_product','fruit_vegetable','production_create','production_fertilizer','transport_cosecha','transport_vehicule_cosecha','commercialization_export','commercialization_market','logistics_transport_shipment','logistics_transport_transporte','create_cosecha'];
if (dataRow==undefined) {
	var data_array_get = []
}else{
	var data_array_get = Object.keys(dataRow)
}
data_array_get.map((a,b)=>{
 			 if (dataRow[a]['data_array'].length==0) {
 			 	this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
 				this.setState({statusColor:{...this.state.statusColor,[a]:'#7a7a7a78'}});
 			 }else{
 			 	  dataRow[a]['data_array'].map((value,index)=>{
				 	if (value.status=='finalizado') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-check-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#58d99978'}});
				 	}
				 	if (value.status=='desarrollo') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#f8d01478'}});
					}
 			 	  })
 			 }
 })

	}
	setNameDB = async(data) =>{
		this.setState({code_traza:data+':'});
		var dataRow = await get_data_row_admin_grain_vt(data)
		this.configAllDataStatus(dataRow.data.data_traza_row)
		this.setState({allDataTrza: dataRow.data.data_traza_row})
		this.setState({data_user:dataRow.data.data_user});
		if (dataRow.data=='Sin trazabilidad') {
			return
		}
	}
		pushSection = (components) => {
			this.setState({components:components})
			
		}
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      data.split('|').map((a,b)=>{
        content[b]=<th>{a}</th>
      })
    }
    return content
  }
  setContent = (data,type_table) => {
    if (data.data_array!=undefined) {
     if (data.data_array.length!=0) {
       var content=[]
       var contetnTh=[]

       data.data_array.map((a,b)=>{
       var get_select = this.putForSelect(Object.entries(a))
       var content_one = Object.entries(a).map((val,ind)=>{
            var content_two = null
              if (val[0]!='status'  && val[0]!='id' && val[0]!='trazabilidad_id' && val[0]!='user_id' && val[0]!='codigo' && val[0]!='trazabilidade_ida' && val[0]!='created_at' && val[0]!='updated_at') {
                if (val[0].indexOf('select_')==-1) {
                  if (val[1]!=null) {
                       content_two = <><td><span className="text-muted">{val[1]+get_select}</span></td></>
                  }else{
                       content_two = <><td><span className="text-muted">Sin Dato</span></td></>
                  }
                	this.putTitle(data.title_label.split('|'),val[0],type_table)
                }
              }

                       return content_two
         })
         content[b] = <tr>{content_one}</tr>
       })
	    // if (data.title_label!=undefined) {
	    //   data.title_label.split('|').map((aTh,bTh)=>{
	    //   	//contetnTh[bTh] = '<th>'+aTh+'</th>'
	    //   	jQuery('.putContentTh').append(aTh)
	    //   })
	    // }
     }
    }
     return content
  }
putTitle = (data,title,type_table) => {
  var result = []
  data.map((a,b)=>{
     var the_same = a.replaceAll('ñ','n').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ','_').replaceAll('/','_').toLowerCase()
      if (title.indexOf(the_same)!=-1) {
        result[b] = '<th class="th_'+type_table+'_'+title+'">'+a+'</th>'
  	  	// if (true) {
  	  	// }
      }
  })	
  	var result_two = []

         jQuery('.putContentTh'+type_table).map((indx,val)=>{
         	var get_name_th = jQuery(val).find('.th_'+type_table+"_"+title).hasClass('th_'+type_table+"_"+title)
         	//result_two[indx] = get_name_th
         	if (!get_name_th) {
      			jQuery('.putContentTh'+type_table).append(result)
         	}
         })
  	  //console.log(result,type_table)
}
    putForSelect = (field) => {
                    var get_data_select = []
                    field.map((a,b)=> {
                      if (a[0].indexOf('select_')!=-1) {
                        get_data_select[a[0].replaceAll('select_','')] = ' '+a[1]
                      }else{
                        get_data_select[a[0].replaceAll('select_','')] = ''
                      }
                    })
                    return get_data_select
    }
    putContentCard =()=>{
      var data = [
	    {table:'info_producer_g',title:'Información Productor'},
	    {table:'info_crops_g',title:'Info del Cultivo'},
	    {table:'info_financing_g',title:'Financiamiento'},
	    {table:'production_land_preparation_g',title:'Preparación de tierra'},
	    {table:'production_irrigation_g',title:'Riego'},
	    {table:'production_seed_g',title:'Semilla'},
	    {table:'production_fertilizer_g',title:'Fertilización'},
	    {table:'production_producer_monitoring_g',title:'Monitoreo Productor'},
	    {table:'production_info_satelite',title:'Satelite NDVI (Vigor del Cultivo)'},
	    {table:'production_info_satelite_ndre',title:'Satelite NDRE (Salud del Cultivo)'},
	    {table:'production_info_satelite_ndmi',title:'Satelite NDMI (Humedad de la Vegetación)'},
	    {table:'production_info_satelite_ndhs',title:'Satelite HR-SUELO (Humedad Relativa del Suelo)'},
	    {table:'hading_info_weeds',title:'Información de malezas'},
	    {table:'hading_inventory_weeds',title:'Inventario de malezas'},
	    {table:'hading_info_plague',title:'Información de Plagas'},
	    {table:'hading_inventory_plague',title:'Inventario de Plagas'},
	    {table:'hading_info_diseases',title:'Información de enferedades'},
	    {table:'harvest_info_harvest',title:'Información de cosecha'},
	    {table:'harvest_info_machinery',title:'Calibración de maquinaria'},
	    {table:'harvest_info_transport',title:'Información de transporte'},
	    {table:'harvest_info_pos_harvest',title:'Información de Pos-Cosecha'},
      ]
      var content=[]
      data.map((val,ind)=>{
        content[ind] = <>
        <Card style={{display: (this.state.allDataTrza!=null)?((this.state.allDataTrza[val.table].data_array.length!=0)?('block'):('none')):('none') }}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">{val.title}</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table style={{width: '1px'}} className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr className={"putContentTh"+val.table}>
{/*              {
                (this.state.allDataTrza!=null)?(this.setContentTh(this.state.allDataTrza[val.table].title_label)):(null)
              }*/}
              </tr>
            </thead>
            <tbody>
              {
                (this.state.allDataTrza!=null)?(this.setContent(this.state.allDataTrza[val.table],val.table)):(null)
              }            
            </tbody>
          </Table>
        </Card>
        </>
      })
      return content
    }
	render(){
  return (
    <>
      <div className="main-content" style={{backgroundColor: 'white'}}>
 {/*         	<Col md="10" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                  <h3 className="mb-0" style={{color:'#ffffff'}}>NAMA Arroz</h3>
                  <Col md={1}>
             	  	<GuideMessage section="fuits_vegetable" section_two="Trace" section_table_form="null" />
                  </Col>
                </CardHeader>
                <CardBody>
                <Row>
                	<Col md={11}>
                	<Row>
                		<h1 style={{margin:0}}>{this.state.code_traza}</h1>
                		<p style={{margin:0, paddingLeft: '8px',fontSize: '13px',fontWeight: 600}}>{(this.state.data_user==null)?(null):(' '+this.state.data_user.name+', '+this.state.data_user.email)}<br /> {(this.state.data_user==null)?(null):(' '+this.state.data_user.pais)}</p>
                	</Row>
                	</Col>
                	<Col md={1} style={{padding:0}}>
                	{(this.state.data_user==null)?(null):(<img alt={String(this.state.data_user.pais).toLocaleLowerCase()+" icon"} srcset={"https://img.icons8.com/office/2x/"+String(this.state.data_user.pais).toLocaleLowerCase()+".png 2x"} />)}
                	</Col>
                </Row>
                </CardBody>
              </Card>
            </Col>*/}
		<section className="elementor-section elementor-top-section elementor-element elementor-element-ddc63bc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddc63bc" data-element_type="section">
		  <div className="elementor-container elementor-column-gap-default">
		    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70ec96d" data-id="70ec96d" data-element_type="column">
		      <div className="elementor-widget-wrap">
		  	  </div>
		    </div>
		  </div>
	    </section>
	    {this.putContentCard()}

    </div>
    </>
  );
 }
}

