import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'
const datacontent = [
{title:'Datos Cosecha',table:'create_cosecha',icon:'far fa-lemon','multiple_tables':0},
{title:'Datos Transporte',table:'transport_cosecha',title_one:'Transporte de Campo',table_one:'transport_cosecha',title_two:'Vehiculo',table_two:'transport_vehicule_cosecha',icon:'fa fa-truck-moving','multiple_tables':1},
]

export default class SectionCosecha extends React.Component {
  render(){
  return (
    <>
<ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
{/*
 <div id="cosecha-tab" className="clearfix eael-tab-content-item inactive" data-title-link="cosecha-tab">
    <article data-elementor-type="section" data-elementor-id="5420" className="elementor elementor-5420">
    <div className="elementor-section-wrap">
    <Row>
    <Col md={3} style={{left:'4%'}}>
    {this.contentRows()}
    </Col>
    <Col md={9} className="pt-2">
    {this.contentRowsCard()}
    </Col>
    </Row>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
    <div className="elementor-widget-wrap">
    </div>
    </div>
    </div>
    </section>
    </div>
    </article>
                                  <div data-elementor-type="page" data-elementor-id="5520" className="elementor elementor-5520">
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-8903bf3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">
               <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7d2af1d" data-id="7d2af1d" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-94d79b2 elementor-widget elementor-widget-spacer" data-id="94d79b2" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-eb1f800 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="eb1f800" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
      <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:PutContentStatus(this.props.DataRow.allDataTrza,'create_cosecha').color,borderRadius: '100px', textAlign: 'end'}}>
      <span className="far fa-lemon" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Datos Cosecha</h3></div>
      </div> 
      </div>
        </div>
        <div className="elementor-element elementor-element-b563099 elementor-widget elementor-widget-toggle" data-id="b563099" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item">
          <h6 onClick={()=>{opened(this.state.openColapse[0],0)}} id="elementor-tab-title-1901" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-1901" aria-expanded="false">
                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                              <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                          </span>
                        <div className="elementor-toggle-title">Cosecha</div>
          </h6>

          <Collapse isOpen={this.state.openColapse[0]} id="elementor-tab-content-1901" style={{width: '250%'}} className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-1901">
          <ul>
          <Col md="12" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                <Row>
                  <Col md={9}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                  </Col>
                  <Col md={3}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.DataRow.allDataTrza,'create_cosecha').register_status}</h4>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                  <PutContent 
                  data_function={this.props.DataRow} 
                  type_function="create_cosecha" />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </ul>
          </Collapse>
        </div>
                </div>
        </div>
        </div>
    

        <div className="elementor-element elementor-element-5726141 elementor-widget elementor-widget-spacer" data-id="5726141" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
          </div>
    </div>

    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column"><div className="elementor-widget-wrap"></div></div>
    
    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7d2af1d" data-id="fead0bd" data-element_type="column">
    <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-94d79b2 elementor-widget elementor-widget-spacer" data-id="94d79b2" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-eb1f800 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="eb1f800" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
            <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:PutContentStatus(this.props.DataRow.allDataTrza,'transport_cosecha').color,borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-truck-moving" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Datos Transporte</h3></div>
      </div> 
      </div>
        </div>
        <div className="elementor-element elementor-element-b563099 elementor-widget elementor-widget-toggle" data-id="b563099" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item">
          <h6 onClick={()=>{opened(this.state.openColapse[1],1)}} id="elementor-tab-title-1901" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-1901" aria-expanded="false">
                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                              <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                          </span>
                        <div className="elementor-toggle-title">Transporte</div>
          </h6>

          <Collapse isOpen={this.state.openColapse[1]} id="elementor-tab-content-1901" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-1901">
          <ul>
                  <PutContentSubTwo
                  data_function={this.props.DataRow} 
                  type_function="transport_cosecha"
                  type_function_two="transport_vehicule_cosecha"
                  states={this.state}
                  openedChild={openedChild}
                  openedNextChild={openedNextChild}
                  chillSelector={"datat"}
                  titleType={'Transporte de Campo'}
                  titleTypeTwo={'Vehiculo'}
                  />
          </ul>
          </Collapse>
        </div>
                </div>
        </div>
        </div>

        <div className="elementor-element elementor-element-5726141 elementor-widget elementor-widget-spacer" data-id="5726141" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
    </div>
    </div>
              </div>
    </section><section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section></div>
                                        </div>
  */}
    </>
  );
}
}

//export default Elements;
