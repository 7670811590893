import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_invoice, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import ModalInvoice from './Modal/ModalInvoice';
export class ServiceInvoice extends React.Component {

  state={
    data_invoice:[],
    trazabilidad_meat:[],
    company_id:null,
    sendOpenModal:false,
    dataPermissionUser:null,
    data_service:null
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  // || this.props.sendDataCompany.type_traza=="VF-M"
  var data = await data_list_invoice(jsondata._id)
  this.setState({
    data_invoice:data,
  })
  }
render(){
  const setInfoServices = (dataValue,type) => {
     var dataPermission = this.props.sendDataCompany
     if (dataPermission) {
       this.setState({dataPermissionUser:dataPermission,data_service:dataValue,sendOpenModal:type,ubicacionProp:dataValue?.full_info.split('>')[2]})
     }
  }
  return (
    <>
      {/*<SimpleHeader name="Facturas" parentName="Facturas" section="bought_realize" section_two="none" section_table_form="null" />*/}
      <ModalInvoice 
        sendOpenModal={this.state.sendOpenModal}
        dataPermissionUser={this.state.dataPermissionUser}
        dataService={this.state.data_service}
        setInfoServices={setInfoServices}
      />
        <div className="mt-3">
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Factura de compras realizadas</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Trazabilidad</th>
                <th>Titulo</th>
                <th>Precio Total</th>
                <th>Fecha limite</th>
                <th>Opción</th>
              </tr>
            </thead>
            <tbody>
            {this.state.data_invoice?.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                     {value.type_traza}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.title}
                    </span>
                   </td>                   
                   <td>
                    <span className="text-muted">
                     {(value.precio=='0')?('Gratis'):('$'+value.precio_total)}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.fecha_limite}
                    </span>
                   </td>
                   <td>
                     <a href="#" onClick={()=>{setInfoServices(value,true)}}>
                     Ver factura
                     </a>
                   </td>
                 </tr>)
            })
          }

            </tbody>
          </Table>
        </Card>
      </div>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
          this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:false})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <ServiceInvoice {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default ServiceInvoice;
