import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import React, {createRef} from "react";
import { data_fertilizer_production, data_trazabilidad,get_permission, data_production, main_content } from "network/ApiAxios";
import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentSubPlant, PutContentSubTablaSatelite} from 'views/pages/examples/LogisticsForVistaTrace/InfoLogistic'
import { CountrysData } from "network/DataCountry";
import { CodigoAran } from "network/CodigoArancelario";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import config from "config";
import {getContent,PutFormContentFinal} from 'views/pages/forms/LogisticsForm';
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import { stateData } from "network/DataState";
import moment from "moment";
import "./styles.css";
export class SectionFinalNama extends React.Component {

  state={
    field_params:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],
    dataStatesS:0,
    content_render:null,
    data_table: [
    {title:'NDVI',table:'production_info_satelite'},
        {title:'NDRE',table:'production_info_satelite_ndre'},
        {title:'NDMI',table:'production_info_satelite_ndmi'},
        {title:'HR Suelo',table:'production_info_satelite_ndhs'}
    ]
  }
  async componentDidMount(){
    var getData = await main_content(this.props.tableCall)
    this.setState({field_content_log:getData})
    this.initData(this.props.getIdPlantProd.id)
  }
  initData = async (id_set)=>{
  this.setState({field_content: getContent(this.state.field_content_log).s_data})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var getParamDb = new URLSearchParams(window.location.search).get("db_code");
        var dataTrazabilidadvf = await data_fertilizer_production(id_set,this.props.tableCall,JSON.stringify(getContent(this.state.field_content_log).f_data),gerParam,getParamDb)
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }
    setDataCampos = (data)=>{
      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })
    }
    cleanData = () =>{
          this.setState({idT:'noind'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })          
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
     setFieldValue = (value, field, isrequired) =>{
       this.setState({field_params: {...this.state.field_params, [field]:value }})
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default==null) {
          var for_put_data=this.contentForSelect(value_option,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]: for_put_data }})

        }else{
          if (list_default=='ldp') {
            var var_data = []
            var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})

          }
          if (list_default=='cafv') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})

          }
          if (list_default=='cac') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})

          }
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
    putContentCard =(identifer)=>{
         var content= <>
           <div className="table-responsive editresponsive">
             <Table className="align-items-center table-flush">
               <thead className="thead-light">
                 <tr className={"putContentTh"+identifer}>
                {
                  (this.state.field_content!=null)?(this.setContentTh(this.state.field_content,this.state.field_params)):(null)
                }
                 </tr>
               </thead>
               <tbody>
                 {
                   (this.state.field_params!=null)?(this.setContent(this.state.field_params,this.state.field_content,identifer)):(null)
                 }            
               </tbody>
             </Table>
           </div>
         </>
       return content
    }
 setContentTh = (data,data_ind) => {
   var content = []
      if (data!=undefined) {
        data.map((a,b)=>{
         if (a.name_field.indexOf('select_')==-1) {
           Object.entries(data_ind).map((val,ind)=>{
              if (a.name_field.indexOf(val[0])!=-1) {
                //console.log(val[0],a.label_title)
                content[a.order_field]=<th style={{paddingTop: 0,paddingBottom: 0}}>{a.label_title}</th>
              }
           })
         }
        })
      }
      return content
   }
  setContent = (data,content_table,identifer) => {
        var content=[]
        var contetnTh=[]

        var get_select = this.putForSelect(Object.entries(data))
             var content_two = []
        var content_one = Object.entries(data).map((val,ind)=>{
               if (val[0]!='status'  && val[0]!='id' && val[0]!='trazabilidad_id' && val[0]!='user_id' && val[0]!='trazabilidade_ida' && val[0]!='created_at' && val[0]!='updated_at') {
                    //content_table.filter((fv,fi)=> fv.name_field==val[0] )
                  //var content_arr=[]
                    if (content_table[ind]['name_field']==val[0]) {
                   if (val[0].indexOf('select_')==-1) {
                     if (val[1]!=null && val[1]!='') {
                          content_two[content_table[ind]['order_field']] = <><td style={{paddingTop: 0,paddingBottom: 0}}><span className="text-muted">{val[1]+' '+get_select[val[0]]}</span></td></>
                     }else{
                          content_two[content_table[ind]['order_field']] = <><td style={{paddingTop: 0,paddingBottom: 0}}><span className="text-muted">Sin Dato</span></td></>
                     }
                   }
                   //this.putTitle(content_table,val[0],identifer)
               }
                        //content_arr[content_table[ind]['order_field']]=content_two
                    }
                        //return content_arr
          })
      return <tr>{content_two}</tr>
  }
 putTitle = (data,title,type_table) => {
    var result = []
    data.map((a,b)=>{
        if (a.name_field.indexOf(title)!=-1) {
          result[b] = '<th class="th_'+type_table+'_'+title+'">'+a.label_title+'</th>'
        }
    })  
      var result_two = []
          jQuery('.putContentTh'+type_table).map((indx,val)=>{
            var get_name_th = jQuery(val).find('.th_'+type_table+"_"+title).hasClass('th_'+type_table+"_"+title)
              if (!get_name_th) {
            if (jQuery(val).find('.th_'+type_table+"_"+title).length==0) {
                jQuery('.putContentTh'+type_table).append(result)
            }
              }
           })
 }
    putForSelect = (field) => {
                    var get_data_select = []
                    field.map((a,b)=> {
                      if (a[0].indexOf('select_')!=-1) {
                        get_data_select[a[0].replaceAll('select_','')] = ' '+a[1]
                      }else{
                        get_data_select[a[0].replaceAll('select_','')] = ''
                      }
                    })
                    return get_data_select
    }
  render(){
 const getInfomationsTh = (infor,idxCon)=>{
   if (infor.length!=0) {
     return infor[0][idxCon]
   }
 }
const PutFormContentSelectTh = (props,idxCon,tablest) => {
 var contenido = []
 var contenidoData = []
 tablest.map((val,inx)=>{
    contenido[inx] = <>
  <td style={{border: '1px solid',padding: '3px'}}>
    <span style={{color: 'black', fontSize: '13px'}}>
    {getInfomationsTh(props[val.table],idxCon)}
    </span>
  </td></>
 })
 return (contenido)
}
const getContentSelectTr=(prps,tables)=>{
 var content = []
 content[0] =<>
                   <tr>
                    <td style={{border: '1px solid',padding: '3px'}}>
                     <span style={{color: 'black', fontSize: '13px'}}>
                      Descripción
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Vigor del Cultivo<br/>
                     C*(0,6-0,8) M*(0,3-0,5)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Salud del Cultivo<br/>
                     C(0,3-0,5) M(0,2-0,4)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Humedad de la Vegetación<br/>
                     C(0,4-0,6) M(0,2-0,4)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Humedad del Suelo<br/>
                     C(0,7-0,8) M(0,6-0,7)
                     </span>
                    </td>
                  </tr>
 </>
    this.state.field_content.map((val,indx)=>{
      content[indx+1] = <>
                    <tr>
                     <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                      <span style={{color: 'black', fontSize: '13px'}}>
                       {val.label_title}
                      </span>
                     </td>
                        {/*<PutFormContentSelectTh {...} idxCon={} />*/}
                        {PutFormContentSelectTh(prps,val.name_field,tables)}
                   </tr>
      </>
    })
   return content
}

  return (
    <>
{(this.props.tableCall=='production_info_satelite')?(
<CardBody>
              <div style={{color: 'black',textAlign: 'center'}}>
                <h3 style={{color: 'black',margin:'0px'}}>
                Reporte Satelital del Cultivo
                </h3>
                <span style={{fontSize: '13px'}}>Finca identificada como </span><br/>
                <span style={{fontSize: '13px'}}>Proyecto IICA-BidLab. Plataforma Satelital Yttrium.</span>
              </div>
        <Row>
         <table className="align-items-center table-flush" responsive style={{border: '1px solid',width: '100%',color: 'black', fontSize: '13px'}}>
            <thead className="thead-light">
              <tr>
                <th style={{border: '1px solid',padding: '3px'}}>Ínidice del Cultivo</th>
              {this.state.data_table.map((val,inx)=>{
                return <th style={{border: '1px solid',padding: '3px', textAlign:'center'}}>{val.title}</th>
              })}
              </tr>
            </thead>
            <tbody>
            {getContentSelectTr(this.props.fullData,this.state.data_table)}
            </tbody>
          </table>
        </Row>
              <div style={{color: 'black'}}>
                <span style={{fontSize: '12px'}}>* C: Crecimiento. M: Maduración.</span><br/>
              </div>
        </CardBody>
  ):(
                    <div className="timeline-block">
                       <span className="timeline-step badge-success">
                         <i className={(this.props.getIndex%2==0)?("ni ni-bold-right"):("ni ni-bold-left")} />
                       </span>
                       <div className={(this.props.getIndex%2==0)?("timeline-content timeline-content-par"):("timeline-content timeline-content-impar")}>
                          <Card style={{margin:0}}>
                         <small className="text-muted font-weight-bold">
                           {moment(this.props.getIdPlantProd.created_at).format(
                             "MMMM Do yyyy HH:mm"
                           )}
                         </small>
                         <h4 className="mt-1 mb-0">
                            {this.putContentCard(this.props.getIdPlantProd.codigo+'_'+this.props.tableCall+'_'+this.props.getIndex)}
                         </h4>
                          </Card>
                       </div>
                     </div>
                     )}
    </>
  );
}


}
 export default class ModalNFinal extends React.Component {

     async componentDidUpdate(){
     }
   render(){
  return (
     <>
              <div
                className={(this.props.tableCall=='production_info_satelite')?(""):("timeline")}
                data-timeline-axis-style="dashed"
                data-timeline-content="axis"
                style={{width: '100%',margin: 'auto'}}
              >
               {(this.props.sendInfoProps==0)?(null):
                     (this.props.sendInfoProps.map((value,index)=>{
                                                  return (
                                                           <Col md="12" className="text-center">
                                                            <SectionFinalNama {...this.props} titleCall={this.props.titleCall} getIdPlantProd={value} getIndex={index+1} />
                                                           </Col>
                                                          ) 
                                           }))
              }
            </div>
     </>
   );
  }
 }
