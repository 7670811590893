import React, {useState, useRef} from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import NotificationAlert from "react-notification-alert";
import {register_collab_main} from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import {useHistory} from "react-router-dom";
import config from "config";

const RegisterCollab = () => {
  const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedEmpresa, setfocusedEmpresa] = useState(false);
  const [focusedPais, setfocusedPais] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedConfirmPassword, setfocusedConfirmPassword] = useState(false);

  const [name, setName] = useState("");
  const [pais, setPais] = useState("");
  const [empresa, setEmpresa] = useState("");
  const history = useHistory();
  var emailVar = new URLSearchParams(history.location.search).get("email");
  //console.log(history)
  const [email, setEmail] = useState(emailVar);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [checkbox, setCheckbox] = useState(false);
  const [error, setError] = useState("");
  const [toastMessage, setToastMessage] = useState("Puede ingresar.");
  const [alert, setalert] = useState(false);
  const { DEMO } = config;
  const [demo, setDemo] = useState(DEMO);
  const notificationAlertRef = useRef(null);

  const notify = (type, title, message, userID) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const registerUser = async () => {
    setError("Cargando...");
    if (email=='') {
      setError("Todos los campos son necesarios.");
      return;
    }
    if (password !== confirmPassword) {
      setError("las contraseñas no coinciden.");
      return;
    }
    const response = await register_collab_main(email, password)
    .catch(function (message){
      setError(message.response.data.errors.email[0])
    })
    if (response==undefined) {
      return;
    }else{
      const {data} = response;
      if (!data.success) {
          setError(data.msg);
          return;
      }
    }

    if (demo) {
        setToastMessage("Puede ingresar.");
    }
    setError("");
    setName("");
    setEmail("");

    setPais("0");
    setEmpresa("");

    setPassword("");
    setConfirmPassword("");
    setCheckbox(false);
    notify("success", "¡Registrado exitosamente!", toastMessage);
    setTimeout(function(){history.push('/home/login')}, 3000);
  };
  const listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
    const listCountryCode = CountrysData.sort(listaArray)

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader
        title="Crea tu cuenta"
        lead="Usa este formulario para crear tu cuenta y poder ingresar al sistema como colaborador."
      />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb--4">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  Ingresa los siguientes datos:
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Correo electrónico"
                        type="email"
                        value={email} onChange={e => setEmail(e.target.value)}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                        disabled={true}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        value={password} onChange={e => setPassword(e.target.value)}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedConfirmPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirmar contraseña"
                        type="password"
                        value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                        onFocus={() => setfocusedConfirmPassword(true)}
                        onBlur={() => setfocusedConfirmPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {error ?
                    <div className="text-muted font-italic">
                      <small>
                        Mensaje:{" "}
                        <span className="text-red font-weight-700">{error}</span>
                      </small>
                    </div> : null }
                  {/*<Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                          checked={checkbox}
                          onChange={() => setCheckbox(!checkbox)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            He leído y acepto los{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              términos y condiciones
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>*/}
                  <div className="text-center">
                    <Button 
                      className="mt-4" 
                      color="traza-green" 
                      type="button" 
                      disabled={!email || !password || !confirmPassword}
                      onClick={registerUser}>
                      Validar cuenta
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RegisterCollab;
