import React, {useEffect} from 'react';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import Assistant from 'components/Assistant/ElementAssistant';
import {DataProvider} from 'layouts/store';

function Index() {

  return (
    <>
      <IndexNavbar />
      <div className="main-content">
        <IndexHeader />
        <section className="py-6 pb-9 bg-traza-grayblue">
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md="6">
                <h2 className="display-4 text-white">
                  {/*Una solución inteligente para el sector de alimentos que utiliza el Blockchain para transparencia y confianza.*/}
                  Una solución revolucionaria para el sector alimentario, que utiliza el poder del Blockchain para garantizar eficiencia, transparencia y confianza en cada transacción.
                </h2>
{/*                <h2 className="display-4 text-white">
                 
                </h2>*/}
                {/*<p className="lead text-white">
                                  Integración de un escritorio de trabajo donde puede iniciarse una
                                  nueva trazabilidad, revisar trazabilidad en proceso y agregar
                                  colaboradores.
                                </p>*/}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <IndexFooter />
      <Assistant />
    </>
  );
}

class SwiftComponent extends React.Component {
  state={
    get_permission:'load',
    setinfoState:false
  }
async componentDidMount(){
  this.setInfoInit()
}
componentDidUpdate(){
}

setInfoInit = () =>{
this.setState({get_permission:true})
            var getParam = new URLSearchParams(window.location.search).get("appResponse");
            if (getParam=='yes') {
            var getUrl = new URLSearchParams(window.location.search).get("url_redict");
             var getUserData = new URLSearchParams(window.location.search).get("dataUser");
             var typeAreaVar = new URLSearchParams(window.location.search).get("typeArea");
             var dataUser = JSON.parse(getUserData)
             var getdata = localStorage.getItem("user");
             localStorage.setItem("android_permission", getParam);
               this.setState({get_permission:false})
               if (getdata==null) {
                  localStorage.setItem("token", dataUser.token);
                  localStorage.setItem("photo_profile", dataUser.namephoto);
                  localStorage.setItem("user_wallet", dataUser.wallet);
                  localStorage.setItem("user", getUserData);
               }
                if (typeAreaVar!=null) {
                  localStorage.setItem('type_area_work', typeAreaVar)
                }
               this.props.history.push(getUrl);
             }
}
render(){
  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
      <DataProvider>
        <Index />
      </DataProvider>
        ):(null)
      )}
    </>
  );
 }
}
export default SwiftComponent;
