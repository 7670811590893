
import { Link, useHistory, useLocation } from "react-router-dom";
import { classNames } from 'primereact/utils';
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState, useEffect } from 'react';
import {UserContext} from "layouts/store";
import config from "config";
import { Sidebar } from 'primereact/sidebar';
import { StyleClass } from 'primereact/styleclass';
import { Ripple } from 'primereact/ripple';
import { Avatar } from 'primereact/avatar';
import { logout } from "network/ApiAxios";
import * as firedatabase from "firebase/database";
import { db } from "network/ConfigFirebase";

const AppTopbar = forwardRef ((props, ref) => {
    const [userPermission, setUserPermission] = useState(null)
    const [typeTraza, setTypeTraza] = useState(null)
    const [dataUser, setDataUser] = useState(null)
    
    const [visibleRight, setVisibleRight] = useState(false)
    const { layoutConfig, layoutState, onMenuToggle, showProfileSidebar } = useContext(UserContext);
    const menubuttonRef = useRef(null);
    const backbuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);
    let history = useHistory();
    const uselocation = useLocation();
    
    const photo_profile = localStorage.getItem("photo_profile");
    useImperativeHandle(ref, () => ({
        buttonback: backbuttonRef.current,
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));
    useEffect(() => {
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        setUserPermission(jsondata.userType)
        setTypeTraza(jsondata.type_traza)
        setDataUser(jsondata)
    }, []);
    const goFrom = (url)=>{
        history.push(url);
    }
    const redirectAreaWork =(type)=>{
      if (type=='global') {
        localStorage.removeItem('type_area_work')
      }else{    
        localStorage.setItem('type_area_work', type)
      }
        window.location.reload()
    }

const logOut = async () => {
const token = localStorage.getItem("token");
    if (token) {
    const response = await logout(token);
    const { data } = response;
        if (data.success) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            history.push("/");
            return true
        }
    }
}
const logOutSessionFire = () => {
           firedatabase.get(getDBFire()).then((snapshot) => {
            if (snapshot.exists()) {
               var data = snapshot.val();
               var dataKey = Object.keys(data);
               var getData = Object.values(data)
               if (getData[getData.length-1].active==1) {
                 var startDate = new Date(getData[getData.length-1].close_sessions_date)
                 var endDate = new Date()
                 var difference = endDate.getTime() - startDate.getTime();
                 var resultInMinutes = Math.round(difference / 60000);
                   functionFBEdit(String(dataKey[dataKey.length-1]),getData[getData.length-1],0)
               }else{
              functionFBCreate()
              }
            }else{
              functionFBCreate()
            }
        });
       var dataGlo = {userId:null,location:'logout'}
       if (window.ReactNativeWebView!=undefined) {
        window.ReactNativeWebView.postMessage(JSON.stringify(dataGlo));
       }
  }
const getUserId = () => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  return jsondata._id
}
const getDBFire = () => {
const productsCollection = firedatabase.ref(db,'user/'+getUserId())
return productsCollection
};
const getDateString = () => {
      var date = new Date();
      var completeDate = date.toLocaleDateString("en-CA")
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1]+':'+String(date).split(":")[2].split(" ")[0];
      var globalTimeDate = completeDate+' '+timeString
      return globalTimeDate
};
const functionFBCreate = () => {
             const newPostRef = firedatabase.push(getDBFire());
             firedatabase.set(newPostRef,{
                user_id: getUserId(),
                close_sessions_date: String(getDateString()),
                active:0,
              }).catch(alert);
             logOut()
  };
const functionFBEdit = async (indexKey,data,activ) => {
  const postData = {
    user_id: getUserId(),
    close_sessions_date: (activ==1)?(getDateString()):(data.close_sessions_date),
    active: activ
  };
    var updates = {};
    updates[indexKey] = postData;    
    firedatabase.update(getDBFire(), updates)
    logOut()
  };
  const onMenuBack = () => {
    history.goBack()
  }
    return (<>
        <div className="layout-topbar">
            <Link to="/admin/home" className="layout-topbar-logo">
                <img src={require("assets/img/brand/foodchain-logo.png").default} alt="logo" />
                
            </Link>
            {(uselocation.pathname=='/admin/home')?(null):(
                <button 
                    ref={backbuttonRef}
                    type="button" 
                    style={{backgroundColor: 'transparent',border: 'none'}}
                    className="p-link layout-topbar-button"
                    onClick={onMenuBack}
                >
                    <i className="pi pi-chevron-left" />
                </button>
            )}

            <button ref={menubuttonRef} type="button" style={{backgroundColor: 'transparent',border: 'none'}} className="p-link layout-topbar-button" onClick={()=> {onMenuToggle()}}>
                <i className="pi pi-bars" />
            </button>

            <button ref={topbarmenubuttonRef} type="button" style={{backgroundColor: 'transparent',border: 'none'}} className="p-link layout-topbar-menu-button layout-topbar-button" onClick={showProfileSidebar}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState?.profileSidebarVisible })}>
                {/*<button type="button" style={{backgroundColor: 'transparent',border: 'none'}} className="p-link layout-topbar-button">
                    <i className="pi pi-calendar"></i>
                    <span>Calendar</span>
                </button>*/}
                <div style={{backgroundColor: 'transparent',border: 'none',display: 'flex'}} className="p-link">
                    <span style={{alignContent: 'center',fontWeight: '600'}}>{dataUser?.name}</span>
                </div>

                <button type="button" onClick={()=>{goFrom("/admin/profile")}} style={{backgroundColor: 'transparent',border: 'none',marginLeft: '1rem'}}>
                    <span style={{backgroundColor:'white'}} className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={(photo_profile=='null')?(require("assets/img/theme/profile.jpg").default):(config.URL_PRODUCTION+"/storage/file_documents/"+photo_profile)}
                      />
                    </span>
{/*                    <i className="pi pi-user"></i>
                    <span>Profile</span>*/}
                </button>

{/*                <Link to="/documentation">
                </Link>*/}
                    <button onClick={() => setVisibleRight(true)} type="button" style={{backgroundColor: 'transparent',border: 'none'}} className="p-link layout-topbar-button">
                        <i className="pi pi-cog"></i>
                        <span>Settings</span>
                    </button>


            </div>
        </div>
        <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
            <div className="overflow-y-auto">
                <ul className="list-none p-3 m-0">
                    <li>
                        <StyleClass selector="@next" enterClassName="hidden" leaveToClassName="hidden" leaveActiveClassName="slideup">
                            <div className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer" style={{justifyContent: 'left'}}>
                                <span className="font-medium">OPCIONES</span>
                                {/*<i className="pi pi-chevron-down"></i>*/}
                                <Ripple />
                            </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a onClick={()=>{goFrom("/admin/profile")}} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" style={{justifyContent: 'left'}}>
                                    <i className="fas fa-user mr-2"></i>
                                    <span className="font-medium">Mi perfil</span>
                                    <Ripple />
                                </a>
                            </li>
                              {(userPermission==1)?(
                                    <li>
                                        <a onClick={()=>{goFrom("/admin/wallet")}} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" style={{justifyContent: 'left'}}>
                                            <i className="fas fa-wallet mr-2"></i>
                                            <span className="font-medium">Mi Billetera</span>
                                            <Ripple />
                                        </a>
                                    </li>
                                ):(null)}
                        </ul>
                    </li>

                    <li>
                        <StyleClass selector="@next" enterClassName="hidden" leaveToClassName="hidden" leaveActiveClassName="slideup">
                            <div className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer" style={{justifyContent: 'left'}}>
                                <span className="font-medium">AREA DE TRABAJO</span>
                                {/*<i className="pi pi-chevron-down"></i>*/}
                                <Ripple />
                            </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 overflow-hidden">
                              {(userPermission==1)?(<>
                                    <li>
                                        <a onClick={()=>{ redirectAreaWork('global') }} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" style={{justifyContent: 'left'}}>
                                            <i className="fas fa-star mr-2"></i>
                                            <span className="font-medium">Dashboard</span>
                                            <Ripple />
                                        </a>
                                    </li>
                                  {(typeTraza?.indexOf('P')!=-1)?(
                                        <li>
                                            <a onClick={()=>{ redirectAreaWork('production') }} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" style={{justifyContent: 'left'}}>
                                                <i className="fas fa-tractor mr-2"></i>
                                                <span className="font-medium">Producción</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                    ):(null)}
                                  {(typeTraza?.indexOf('T')!=-1)?(
                                        <li>
                                            <a onClick={()=>{ redirectAreaWork('trazability') }} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" style={{justifyContent: 'left'}}>
                                                <i className="fas fa-map mr-2"></i>
                                                <span className="font-medium">Trazabilidad</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                    ):(null)}
                                  {(typeTraza?.indexOf('C')!=-1)?(
                                    <li>
                                        <a onClick={()=>{ redirectAreaWork('commerce') }} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" style={{justifyContent: 'left'}}>
                                            <i className="fas fa-store mr-2"></i>
                                            <span className="font-medium">Comercialización</span>
                                            <Ripple />
                                        </a>
                                    </li>
                                    ):(null)}
                                </>):(null)}
                        </ul>
                    </li>

                    <li>
                        <StyleClass selector="@next" enterClassName="hidden" leaveToClassName="hidden" leaveActiveClassName="slideup">
                            <div className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer" style={{justifyContent: 'left'}}>
                                <span className="font-medium">SESIÓN</span>
                                {/*<i className="pi pi-chevron-down"></i>*/}
                                <Ripple />
                            </div>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 overflow-hidden">
                                    <li>
                                        <a 
                                        onClick={()=>{ 
                                            logOutSessionFire()
                                        }} 
                                    className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" style={{justifyContent: 'left'}}>
                                            <i className="fas fa-sign-out-alt mr-2"></i>
                                            <span className="font-medium">Cerrar sesión</span>
                                            <Ripple />
                                        </a>
                                    </li>
                        </ul>
                    </li>

                </ul>
            </div>
        </Sidebar>
    </>);
});

AppTopbar.displayName = 'AppTopbar';

export default AppTopbar;
