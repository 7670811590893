import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";

const containerStyle = {
  width: '400px',
  height: '400px',
};

const center = {
  lat: 0, // Latitud del centro del mapa
  lng: 0, // Longitud del centro del mapa
};

const App = () => {
  const [qrData, setQrData] = useState(''); // Datos del código QR
  const [clicks, setClicks] = useState([]); // Lista de clics
  const [mapCenter, setMapCenter] = useState(center); // Centro del mapa

  // Generar un código QR con los datos proporcionados
  const generateQRCode = (data) => {
    setQrData(data);
  };

  // Manejar el clic en el código QR
  const handleClick = (event) => {
    const { latLng } = event;
    const click = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    setClicks([...clicks, click]);
  };

  // Actualizar el centro del mapa según la última posición de clic
  useEffect(() => {
    const lastClick = clicks[clicks.length - 1];
    if (lastClick) {
      setMapCenter(lastClick);
    }
  }, [clicks]);

  return (<>
      <AuthHeader
        title="¡Bienvenido!"
      />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb--4">
              <CardBody className="px-lg-5 py-lg-3">
                <div>
                  <h5>Generador de Código QR Dinámico</h5>
                  <input
                    type="text"
                    value={qrData}
                    onChange={(e) => generateQRCode(e.target.value)}
                  />
                  {qrData && <QRCode value={qrData} />}
                  <LoadScript googleMapsApiKey="AIzaSyCm2H8R5DR0_S4L_h9vDOYW2B37lk5fRls">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={mapCenter}
                      zoom={10}
                      onClick={handleClick}
                    >
                      {clicks.map((click, index) => (
                        <Marker key={index} position={click} />
                      ))}
                    </GoogleMap>
                  </LoadScript>
                  <h2>Estadísticas de clics:</h2>
                  <ul>
                    {clicks.map((click, index) => (
                      <li key={index}>{`Lat: ${click.lat}, Lng: ${click.lng}`}</li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
  </>);
};

export default App;