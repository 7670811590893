import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  //Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_traza_animal, create_traza_animal, delete_register_traza_animal } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import { Divider, Row, Table } from 'antd';
export default class ModalInvoice extends React.Component {
  state={
  }
    async componentDidMount(){
  }
    setDataCampos = (data)=>{
    }
    async componentDidUpdate(){
    }
  render(){
    const setInfoInvoice = async (data,type) =>{
      this.props.setInfoServices(data,type)
    }
    const off_date = (datelimit) =>{
        var result = new Date(datelimit);
        //result.setDate(result.getDate() + 31);
        return String(result.toLocaleDateString("en-CA"));
    }
    const contentServiceFunction = (data) =>{
      var dataFunction = [];
      if (data!=undefined) {
       dataFunction = data?.split('_')
     }
       var content = []
       for (var i = 0; i < dataFunction.length; i++) {
       content[i] = <div>{dataFunction[i]}</div>
       }
       return content
    }    
  return (
    <>
  <Modal isOpen={this.props.sendOpenModal} size="xl">
    <ModalBody>
       <CardHeader>
                      <Row>
            <Col md={11}>
              <h3 className="mb-0">Factura</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoInvoice(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>

            <div style={{ padding: 20 }}>

        <Col md={12} >
          <Row gutter={24} style={{ marginTop: 32 }}>
              <Col md={8} >
                <h3>{this.props.dataPermissionUser?.name}</h3>
                <div>{(this.props.dataService?.full_info.split('>')[2]==null)?(null):(this.props.dataService?.full_info.split('>')[2]?.split(',').map((valu,ind)=>{
                                  return <>{valu}<br /></>
                                }))}</div>
                {this.props.loadlocali}
              </Col>
              <Col md={4} >
                <div><b>Empresa:</b> {this.props.dataPermissionUser?.empresa}</div>
                <div><b>Pais:</b> {this.props.dataPermissionUser?.pais}</div>
                <div><b>Correo:</b> {this.props.dataPermissionUser?.email}</div>
              </Col>
          </Row>
        </Col>
      <Row style={{ marginTop: 48 }}>
        <Table dataSource={[{
            id: 1,
            name:this.props.dataService?.type_traza,
            description: contentServiceFunction(this.props.dataService?.description),
            date_final: off_date(this.props.dataService?.fecha_limite),
            price: parseInt(this.props.dataService?.precio)*parseInt(this.props.dataService?.user_quantity),
            quantity: parseInt(this.props.dataService?.user_quantity)
        }]}
        style={{ width: '100%' }}
        pagination={false}
        >
          <Table.Column title="Trazabilidad" dataIndex='name' />
          <Table.Column title="Funciones" dataIndex='description' />
          <Table.Column title="Cantidad Usuarios" dataIndex='quantity' />
          <Table.Column title="Fecha corte" dataIndex='date_final' />
          <Table.Column title="Precio" dataIndex='price' />
        </Table>
      </Row>

      <Row style={{ marginTop: 48 }}>
        <Col span={8} offset={16}>
          <table style={{ textAlign: 'end', width: '100%' }}>
            <tr>
              <th>Nett Total :</th>
              <td style={{ width: '0' }}>${parseInt(this.props.dataService?.precio)*parseInt(this.props.dataService?.user_quantity)*1}</td>
            </tr>
          </table>
        </Col>
      </Row>
      </div>
          </CardBody>
        </ModalBody>
        <ModalFooter>

          <Button onClick={()=> {setInfoInvoice(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
 }
}

