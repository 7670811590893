import React, { useCallback, useState, useEffect } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle, 
  Position,
  ReactFlowProvider,
  Panel,
  useReactFlow,
  ConnectionLineType,
} from 'reactflow';
 import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import 'reactflow/dist/style.css';
import ContentTypeProducer from './ContentTypeData'
import ContentTypeHarvest from './ContentTypeDataNext'
import {PutContentStatus} from 'views/pages/examples/Trazability/SectionsViewTrace/InfoLogistic'
import Dagre from '@dagrejs/dagre';
import ContentLogisticKdes from 'views/pages/examples/LogisticsForVistaTrace/ContentLogisticKdes'
import {put_arr_data_kdes, findCodeLoteGlobal, searhCodeLoteGlobal} from './DataConnectionsTablesKdes';
const nodeTypes = { 
 producerData: ContentTypeProducer,
 harvestData: ContentTypeHarvest,
 // importData:contentTypeImport, 
 // distributorData:contentTypeDistributor, 
 // detailsData:contentTypeDetails 
};

export default function App(props) {
  const [dataProdPlant,setDataProdPlant] = useState({})


  const [dataSelectColumn,setDataSelectColumn] = useState([])
  const [dataSelectTable,setDataSelectTable] = useState([])
  const [dataSelect,setDataSelect] = useState([])
  const [dataSelected,setDataSelected] = useState('')
  const [dataToken,setDataToken] = useState('')

  const [dataProdProducer,setDataProdProducer] = useState([])
  
  const [initialNodesState,setInitialNodes] = useState([])
  const [initialEdgesState,setInitialEdges] = useState([])

  const [dataArray,setDataArray] = useState(props.allDataTrza)
  const [dataArrayAll,setDataArrayAll] = useState(props.allDataTrza)

  const findCodeLote =(table, column, secondColumn, codeTraza, data)=>{
    const regex = new RegExp('^1_lote_', 'g');
    var getDataPlantKdes = dataArray[table].data_array.filter((a) => a[column]?.match(regex) && a.codigo==codeTraza )
    if (getDataPlantKdes[0]!=null) {
      return getDataPlantKdes[0][secondColumn]
    }else{
      return 'none'
    }
  }

    const disassembleArray = (data) => {
      return data;
    }

    // const filterPutLote = (dataLote,field,fieldReceiber,columnChange,column)=>{
    //   dataArray[field].data_array_full.map((a,b)=>{
    //     a.map((aT,bT)=>{
    //       if (aT[column]==dataLote) {
    //         var dataProdVar = dataArray.fruit_vegetable_user.data_array_full;
    //         [...disassembleArrayTwo(...dataProdVar)].map((valValid,indValid)=>{
    //           var getDataValid = dataArray.fruit_vegetable_user.data_array.some((v)=>v.token_id==valValid.token_id && v.lote_numero==valValid.lote_numero)
    //           if (!getDataValid) {
    //             dataArray.fruit_vegetable_user.data_array.push(valValid);
    //           }
    //         })

    //         var dataPlantReceiverVar = dataArray[fieldReceiber].data_array_full;
    //         var dataPlantReceiverVarOrigin=[...dataArray[fieldReceiber].data_array, ...disassembleArrayTwo(...dataPlantReceiverVar)]
    //         dataProdPlant[fieldReceiber]={...dataPlantReceiverVarOrigin};

    //         var dataPlantVar = dataArray[field].data_array_full;
    //         dataArray[field].data_array.push(...disassembleArrayTwo(...dataPlantVar));
    //       }
    //     })
    //   })
    // }
const disassembleArrayTwo = (data)=>{
  var setData = []
  data.map((a,b)=>{
    if (a.length!=undefined) {
      setData.push(...a)
    }else{
      setData.push(a)
    }
  })
  return setData
}
const setUserData = (dataArray) =>{
var dataProdVar = [[]]
if (Object.values(dataArray.fruit_vegetable_user.data_array_full).length>0) {
  dataProdVar = Object.values(dataArray.fruit_vegetable_user.data_array_full);
}else{
  if (dataArray.fruit_vegetable_user.data_array.length>0) {
  dataProdVar = [dataArray.fruit_vegetable_user.data_array];
  }
}

  //[...disassembleArrayTwo(...dataProdVar)]
  disassembleArrayTwo(dataProdVar).map((valValid,indValid)=>{
    var getDataValid = dataArray.fruit_vegetable_user.data_array.some((v)=>v.token_id==valValid.token_id && v.lote_numero==valValid.lote_numero)
    if (!getDataValid) {
      var dataArrOne=dataArray
      var dataArrTwo =  dataArrOne.fruit_vegetable_user
      dataArrTwo.data_array=[...dataArrTwo.data_array,valValid]
      dataArrOne.fruit_vegetable_user=dataArrTwo
    }
  })
}
const filterPutLoteImport = (dataLote,fieldReceiber,field,columnChange,column,codeAsigned)=>{
  var regex = new RegExp('^1_lote_', 'g');
  //console.log(Object.values(dataArray[fieldReceiber].data_array_full))
  if (Object.values(dataArray[fieldReceiber].data_array_full).length==0) {
    setUserData(dataArray)
  }

  // if (Object.values(dataArray[fieldReceiber].data_array).length!=0) {  
  //   Object.values(dataArray[fieldReceiber].data_array).map((a,b)=>{
  //     if (a[columnChange]?.match(regex)) {
  //       if (a[column]==codeAsigned) {
  //         console.log(a[column],codeAsigned)
  //       }
  //     }
  //   })
  // }

  if (Object.values(dataArray[fieldReceiber].data_array_full).length!=0) {
    Object.values(dataArray[fieldReceiber].data_array_full).map((a,b)=>{
      a.map((aT,bT)=>{
        if (aT[columnChange]?.match(regex)) {
          if (aT[column]==codeAsigned) {
            setUserData(dataArray)
            var dataPlantReceiverVar = Object.values(dataArray[fieldReceiber].data_array_full);
            var dataPlantReceiverVarOrigin=[...disassembleArrayTwo(dataPlantReceiverVar)]
            dataProdPlant[fieldReceiber]={...dataPlantReceiverVarOrigin};
            var dataPlantVar = Object.values(dataArray[fieldReceiber].data_array_full);
            dataArray[fieldReceiber].data_array.push(...disassembleArrayTwo(dataPlantVar));
          }else{
            var dataProdVar = [[]]
            if (Object.values(dataArray.fruit_vegetable_user.data_array_full).length>0) {
              dataProdVar = Object.values(dataArray.fruit_vegetable_user.data_array_full);
            }else{
              if (dataArray.fruit_vegetable_user.data_array.length>0) {
              dataProdVar = dataArray.fruit_vegetable_user.data_array;
              }
            }
            [...disassembleArrayTwo(dataProdVar)].map((valValid,indValid)=>{
              var getDataValid = dataArray.fruit_vegetable_user.data_array.filter((v)=>v.token_id==valValid.token_id && v.lote_numero==valValid.lote_numero)
              if (getDataValid.length!=0) {
              }
            })
          }
        }
      })
    })
  }
}
    const getDataVTConfig = (id_token,get_data_main,title_column,view_table,table_selected,idRow)=>{
      var put_title=''
      var dataUp=[]
      var dataDown=[]
      if (get_data_main!=null) {
        put_title=get_data_main[0][title_column]
        //Object.entries(dataArray).map((padVal,padIndx)=>{

          if (JSON.parse(get_data_main[0][view_table])!=null) {
            if (JSON.parse(get_data_main[0][view_table])[table_selected]!=undefined) {
              var data_array_first = Object.values(dataArray[table_selected].data_array)
              var data_array_all = Object.values(dataArray[table_selected].data_array_full)
              var getDataContent = []
              if (data_array_all.length!=0) {
                getDataContent = [...data_array_first,...disassembleArrayTwo(...data_array_all)].filter((a) => a.token_id==id_token && a.id==idRow);
              }else{
                getDataContent = [...data_array_first].filter((a) => a.token_id==id_token && a.id==idRow);
              }
              if (getDataContent.length!=0) {
                [getDataContent[getDataContent.length-1]].map((cVal,cInd)=>{
                  var getdatacolumnup = JSON.parse(get_data_main[0][view_table])[table_selected].map((tVal,iVal)=>{
                    if (tVal.position=='1') {
                      dataUp.push(cVal[tVal.column])
                    }
                  })
                  var getdatacolumndown = JSON.parse(get_data_main[0][view_table])[table_selected].map((tVal,iVal)=>{
                    if (tVal.position=='2') {
                      dataDown.push(cVal[tVal.column])
                    }
                  })
                })
              }
            }
          }

        //})
      }
            //console.log(dataDown,dataUp)
      //var getDataUp = dataUp.filter((a,b)=> a.number==lote_numero)
      //var getDataUp = dataDown.filter((a)=> console.log(a))
      //console.log(dataDown)
      return {title:put_title,data_up:dataUp,data_down:dataDown}
    }

    const getDataVTDetails = (id_token,title_column,view_table,idRow)=>{
      var get_data_detail=props.data_vt_config.filter((vtR)=> vtR.type_selected=='vt_data_important')
      var put_title=''
      var dataUp=[]
      var dataDown=[]
      if (get_data_detail!=null) {
        put_title=get_data_detail[0][title_column]
        Object.entries(dataArray).map((padVal,padIndx)=>{
          if (JSON.parse(get_data_detail[0][view_table])!=null) {
            if (JSON.parse(get_data_detail[0][view_table])[padVal[0]]!=undefined) {
              var data_array_first = Object.values(dataArray[padVal[0]].data_array)
              var data_array_all = Object.values(dataArray[padVal[0]].data_array_full)
              var getDataContent = []
              if (data_array_all.length!=0) {
                getDataContent = [...data_array_first,...disassembleArrayTwo(...data_array_all)].filter((a) => a.token_id==id_token && a.id==idRow);
              }else{
                getDataContent = [...data_array_first].filter((a) => a.token_id==id_token && a.id==idRow);
              }
              //var getDataContent = [...data_array_first,...disassembleArrayTwo(...data_array_all)].filter((a) => a.token_id==id_token);
              if (getDataContent.length!=0) {
                [getDataContent[getDataContent.length-1]].map((cVal,cInd)=>{
                  var getdatacolumnup = JSON.parse(get_data_detail[0][view_table])[padVal[0]].map((tVal,iVal)=>{
                      dataUp.push(cVal[tVal.column])
                  })
                })
              }
            }
          }
        })
      }
      return {data_up:dataUp}
    }
  const dataReducer =(data)=>{
    var returnData = [...new Set(data)]
    return returnData
  }
  const putTokenData = (dataAll,tableArr,tableSelected,num_lot) =>{
    var getDataTable = put_arr_data_kdes.filter((st)=> st.table_receive.table==tableSelected)
    var table_column='none'
    var table_column_change='none'
    if (getDataTable[0]!=undefined) {
      table_column=getDataTable[0].table_receive.codigo
      table_column_change=getDataTable[0].table_receive.change
    }

    var setTable = null
    if (typeof tableArr=='string') {
      setTable = tableArr
    }else{
      if (tableArr!=null) {
        setTable = tableSelected
      }
    }
    if (setTable!=null) {
      var data_order_columns = []
      var getDataContent = []

      var data_array_first = Object.values(props.allDataTrza[setTable].data_array)
      var data_array_all = Object.values(props.allDataTrza[setTable].data_array_full)

      dataArray[setTable].divider_title_select.split('|').map((a,b)=>{
        if (data_array_first.length!=0) {
          var get_valid_column = Object.keys(data_array_first[0]).some((col) => col==a.split('*')[0])
          if (data_array_all[0]!=undefined) {
            get_valid_column= Object.keys(data_array_all[0][0]).some((col) => col==a.split('*')[0])
          }
          if (get_valid_column) {
            data_order_columns[a.split('*')[1]] = {title: a.split('*')[2], column: a.split('*')[0]}
          }
        }else{
          if (data_array_all[0]!=undefined) {
            if (data_array_all[0][0]!=undefined) {            
              var get_valid_column = Object.keys(data_array_all[0][0]).some((col) => col==a.split('*')[0])

              if (get_valid_column) {
                data_order_columns[a.split('*')[1]] = {title: a.split('*')[2], column: a.split('*')[0]}
              }
            }

          }

        }
      })

      if (data_array_all.length!=0) {
        if (table_column=='none') {
          getDataContent = dataReducer([...data_array_first,...disassembleArrayTwo(data_array_all)].filter((a) => a.token_id==dataAll.token_id));
        }else{
          getDataContent = dataReducer([...data_array_first,...disassembleArrayTwo(data_array_all)].filter((a) => a[table_column].indexOf(num_lot)!=-1));
        }
      }else{
        if (table_column=='none') {
          getDataContent = dataReducer([...data_array_first].filter((a) => a.token_id==dataAll.token_id));
        }else{
          getDataContent = dataReducer([...data_array_first].filter((a) => a[table_column].indexOf(num_lot)!=-1 ));
        }
      }
      if (typeof tableArr!='string') {
        setDataSelect(tableArr)
      }
      setDataSelected([setTable,table_column_change,table_column])
      setDataSelectTable(getDataContent.reverse())
      setDataSelectColumn(data_order_columns)
      setDataToken(dataAll.token_id)
    }
  }
  useEffect(()=>{
    //setDataArrayAll(props.allDataTrza)
    initData()
  },[])

  const initData = ()=>{
  var regex = new RegExp('^1_lote_', 'g');

   var initialNodes = [];
   var initialEdges = [];

   var putDataLinkEdge = {}

   var get_data_main=props.data_vt_config.filter((vtR)=> vtR.type_selected=='vt_data_main')
   var release_kdes = put_arr_data_kdes.filter((fk)=> Object.keys(dataArray).join().indexOf(fk.table_receive.table)!=-1)
    release_kdes.map((data_arr,data_arr_idx)=>{
   //console.log(Object.values(dataArray[data_arr.table_receive.table].data_array_full))
      var data_array_main = Object.values(dataArray[data_arr.table_receive.table].data_array)
      var data_array_all = Object.values(dataArray[data_arr.table_receive.table].data_array_full)
      var getDataPlantKdes = []

      if (data_array_main.length>0) {
        getDataPlantKdes = data_array_main.filter((a) => a[data_arr.table_receive.change]?.match(regex) )
        //console.log(data_array_main,getDataPlantKdes,data_arr.table_receive.change,data_arr.table_receive.table,regex)
      }else{
        if (data_array_all.length>0) {
          getDataPlantKdes = [...disassembleArrayTwo(...data_array_all)]
        }
      }
      getDataPlantKdes.map((val,ind)=>{
        var getDataCodeLoteShipping = findCodeLote(data_arr.table_receive.table, data_arr.table_receive.change, data_arr.table_receive.codigo, val.codigo, props)
        var getDataVT = getDataVTConfig(val.token_id,get_data_main,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,data_arr.table_receive.table,val.id)
        var getDetailVT = getDataVTDetails(val.token_id,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,val.id)

        var fromProduct = [...disassembleArrayTwo(dataArray[data_arr.table_receive.table].data_array_full)].filter((hf)=> hf[data_arr.table_receive.change]?.match(regex) && val.token_id.indexOf(hf.token_id)!=-1 )
        var dataProdName = ''
        if (fromProduct[fromProduct.length-1]!=undefined) {
          dataProdName = fromProduct[fromProduct.length-1].alimento_o_producto
        }else{
          dataProdName = val.alimento_o_producto
        }
          var dataContent = { 
                              lote_numero:data_arr.prefix+val[data_arr.table_receive.codigo], 
                              idTarget:data_arr.prefix+val[data_arr.table_receive.change].replace(regex,''), 
                              colorStatus:PutContentStatus(dataArray,data_arr.table_receive.table,val.status).color,
                              main_title:getDataVT.title,
                              data_top:getDataVT.data_up.reverse(),
                              data_bottom:getDataVT.data_down.reverse(),
                              icon:data_arr.icon,
                              data_details:getDetailVT.data_up,
                              token_id:val.token_id,
                              clkDataTraza:putTokenData,
                              table_selected_info:data_arr.table_receive.table,
                              table_column:data_arr.table_receive.codigo,
                              table_column_change:data_arr.table_receive.change,
                              data_select: data_arr.data_details_select,
                              statusTitle:val.status,
                              producto:dataProdName,
                              from:val.empresa
                            }

        initialNodes.push({ id: data_arr.prefix+val[data_arr.table_receive.codigo], type: 'harvestData', position: { x: 250, y: 0 }, data: dataContent })
        if (data_array_main.length>0) {
          filterPutLoteImport(val[data_arr.table_receive.change].replace(regex,''),
            data_arr.table_receive.table,
            data_arr.table_receive.table,
            data_arr.table_receive.change,
            data_arr.table_receive.codigo,
            val[data_arr.table_receive.codigo])
        }else{
          setUserData(dataArray)
          //console.log(data_array_main)
        }
//console.log(val.status,dataProdName,val.token_id)
          putDataLinkEdge[data_arr.prefix+ind]={
                  'status':(val.status=='desarrollo')?(true):(false),
                  'statusTitle':val.status,
                  'idTarget':data_arr.prefix+val[data_arr.table_receive.change].replace(regex,''),
                  'idSource':data_arr.prefix+val[data_arr.table_receive.codigo],
                  'token_id':val.token_id+val.user_id,
                  'producto':dataProdName
                  }

      })
      //console.log(dataProdPlant)
      if (dataProdPlant[data_arr.table_receive.table]!=undefined) {
        Object.values(dataProdPlant[data_arr.table_receive.table]).filter((a) => a[data_arr.table_receive.change]?.match(regex) ).map((valPlant,indPlant)=>{
          var getDataCodeLoteShipping = findCodeLote(data_arr.table_receive.table, data_arr.table_receive.change, data_arr.table_receive.codigo, valPlant.codigo, props)
          var getDataVT = getDataVTConfig(valPlant.token_id,get_data_main,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,data_arr.table_receive.table,valPlant.id)
          var getDetailVT = getDataVTDetails(valPlant.token_id,data_arr.data_f_config_vt.title,data_arr.data_f_config_vt.view,valPlant.id)
          var fromProduct = [...disassembleArrayTwo(dataArray[data_arr.table_receive.table].data_array_full)].filter((hf)=> hf[data_arr.table_receive.change]?.match(regex) && valPlant.token_id.indexOf(hf.token_id)!=-1 )
          var dataProdName = ''
          if (fromProduct[fromProduct.length-1]!=undefined) {
            dataProdName = fromProduct[fromProduct.length-1].alimento_o_producto
          }else{
            dataProdName = valPlant.alimento_o_producto
          }

          var dataContent = { 
                              lote_numero:data_arr.prefix+valPlant[data_arr.table_receive.codigo], 
                              idTarget:data_arr.prefix+valPlant[data_arr.table_receive.change].replace(regex,''), 
                              colorStatus:PutContentStatus(dataArray,data_arr.table_receive.table,valPlant.status).color,
                              main_title:getDataVT.title,
                              data_top:getDataVT.data_up.reverse(),
                              data_bottom:getDataVT.data_down.reverse(),
                              icon:data_arr.icon,
                              data_details:getDetailVT.data_up,
                              token_id:valPlant.token_id,
                              clkDataTraza:putTokenData,
                              table_selected_info:data_arr.table_receive.table,
                              table_column:data_arr.table_receive.codigo,
                              table_column_change:data_arr.table_receive.change,
                              data_select: data_arr.data_details_select,
                              statusTitle:valPlant.status,
                              producto:dataProdName,
                              from:valPlant.empresa
                            }
              //initialNodes.push({ id: data_arr.prefix+val[data_arr.table_receive.codigo], type: 'harvestData', position: { x: 250, y: 0 }, data: dataContent })
          // initialNodes.push({ id: data_arr.prefix+valPlant[data_arr.table_receive.codigo], type: 'harvestData', position: { x: 250, y: 0 }, data: dataContent })
          // filterPutLoteImport(valPlant[data_arr.table_receive.change].replace(regex,''),
          //   data_arr.table_receive.table,
          //   data_arr.table_receive.table,
          //   data_arr.table_receive.change,
          //   data_arr.table_receive.codigo,
          //   valPlant[data_arr.table_receive.codigo])

          putDataLinkEdge[data_arr.prefix+indPlant+'_edge']={
                  'status':(valPlant.status=='desarrollo')?(true):(false),
                  'statusTitle':valPlant.status,
                  'idTarget':data_arr.prefix+valPlant[data_arr.table_receive.change].replace(regex,''),
                  'idSource':data_arr.prefix+valPlant[data_arr.table_receive.codigo],
                  'token_id':valPlant.token_id+valPlant.user_id,
                  'producto':dataProdName
                  }
          
        })
      }

    })
    var getDataProductorKdes = dataArray.fruit_vegetable_user?.data_array.filter((a) => a.lote_numero!=null)
      getDataProductorKdes.map((valProductor,indProductor)=>{
        var getDataVT = getDataVTConfig(valProductor.token_id,get_data_main,'title_prod_harvest','view_table_harvest','fruit_vegetable_user',valProductor.id)
        var getDetailVT = getDataVTDetails(valProductor.token_id,'title_prod_harvest','view_table_harvest',valProductor.id)
        var fromProduct = [...disassembleArrayTwo(dataArray['create_harvest_kdes'].data_array_full)].filter((hf)=> valProductor.token_id.indexOf(hf.token_id)!=-1 )
        var dataProdName = ''
        if (fromProduct[fromProduct.length-1]!=undefined) {
          dataProdName = fromProduct[fromProduct.length-1].alimento_o_producto
        }
        var dataContent = { 
                            lote_numero:'producer-'+valProductor.lote_numero,
                            colorStatus:PutContentStatus(dataArray, 'fruit_vegetable_user',valProductor.status).color,
                            main_title:getDataVT.title,
                            data_top:getDataVT.data_up.reverse(),
                            data_bottom:getDataVT.data_down.reverse(),
                            data_details:getDetailVT.data_up,
                            icon:'https://cdn-icons-png.flaticon.com/512/2674/2674482.png',
                            token_id:valProductor.token_id,
                            clkDataTraza:putTokenData,
                            table_selected_info:'create_harvest_kdes',
                            table_column:'none',
                            table_column_change:'none',
                            data_select: [{value:'fruit_vegetable_user',label:'Datos Productor'},{value:'create_harvest_kdes',label:'Cosecha'}],
                            statusTitle:valProductor.status,
                            producto:dataProdName,
                            from:valProductor.empresa
                          }
        initialNodes.push({ id: 'producer-'+valProductor.lote_numero, type: 'producerData', position: { x: 0, y: 0 }, data: dataContent })
        putDataLinkEdge['producer-'+indProductor]={
        'status':(valProductor.status=='desarrollo')?(true):(false),
        'statusTitle':valProductor.status,
        //'idSource':'producer-'+valProductor.lote_numero,
        'idTarget':'producer-'+valProductor.lote_numero,
        'token_id':valProductor.token_id+valProductor.user_id,
        'producto':dataProdName
      }
      })
      var getOnlyPerms = []
      release_kdes.map((valEdge,indEdge)=>{
        Object.entries(putDataLinkEdge).map((edVal,edIndx)=>{
           switch (true) {
            case (edVal[0].indexOf(valEdge.prefix) !=-1):
              var getDataCodePlant = searhCodeLoteGlobal(valEdge.prefix, Object.values(putDataLinkEdge), edVal[1].idTarget, edIndx)
              var getDataF = initialNodes.filter((gdf)=> edVal[1].idTarget.indexOf(gdf.id)!=-1 && edVal[1].token_id.indexOf(gdf.data.token_id)!=-1)
              getOnlyPerms.push(getDataCodePlant+'-'+edVal[1].idSource)
                initialEdges.push({
                  //id: edVal[1].idTarget+'-'+edVal[1].idSource,
                  //source: edVal[1].idTarget,
                  id: getDataCodePlant+'-'+edVal[1].idSource,
                  source: getDataCodePlant,
                  target: edVal[1].idSource,
                  //animated: edVal[1].status,
                  animated:(getDataF[0]?.data?.statusTitle=='desarrollo')?(true):(false),
                  //label: edVal[1].producto,
                  label: getDataF[0]?.data?.producto,
                })
              break;

            default:
              break;
           }
        })
      })
  var filterSelected = initialNodes
  if (initialEdges.length>0) {
    var otherFilterSelected = initialNodes.filter((aft,bft)=> aft.data.token_id.indexOf(props.code_traza)!=-1 )
    var getafo = getOnlyPerms.filter((afo)=> afo.indexOf(otherFilterSelected[otherFilterSelected.length-1]?.data?.lote_numero?.split('-')[1])!=-1)
    filterSelected = initialNodes.filter((af,bf)=> getafo.join().indexOf(af.id)!=-1 )
  }
              //console.log(filterSelected,initialEdges)
  setInitialNodes(filterSelected)
  setInitialEdges(initialEdges)
  props.setDataForExcel(initialEdges,filterSelected)
  }

  if (initialNodesState.length!=0) {    
    return(<>
      <ContentFlow propNode={initialNodesState} propEdge={initialEdgesState}/>
      <br />
      <br />

        <ContentLogisticKdes
          dataSelectTable={dataSelectTable}
          dataSelectColumn={dataSelectColumn}
          allDataTraza={initialNodesState}
          dataSelect={dataSelect}
          dataSelected={dataSelected}
          putTokenData={putTokenData}
          dataToken={dataToken}
        />
    </>)
  }else{
    return(
    <div style={{ width: '90vw', height: '60vh' }}>
      Sin datos
    </div>
    )
  }
}

export function ContentFlow({propNode,propEdge}) {
    // const [nodes, setNodes, onNodesChange] = useNodesState(propNode);
    // const [edges, setEdges, onEdgesChange] = useEdgesState(propEdge);
    const dagreGraph = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    const nodeWidth = 172;
    const nodeHeight = 100;

    const getLayoutedElements = (nodes, edges, direction = 'LR') => {
      const isHorizontal = direction === 'LR';
      dagreGraph.setGraph({ rankdir: direction });

      nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
      });

      edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      Dagre.layout(dagreGraph);

      nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';
        node.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
      });

      return { nodes, edges };
    };

    // const getLayoutedElements = (nodes, edges, options) => {
    //   dagreGraph.setGraph({ rankdir: options.direction });

    //   edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));
    //   nodes.forEach((node) => dagreGraph.setNode(node.id, node));

    //   Dagre.layout(dagreGraph);

    //   return {
    //     nodes: nodes.map((node) => {
    //       const { x, y } = dagreGraph.node(node.id);
    //       console.log(x, y)
    //       return { ...node, position: { x, y } };
    //     }),
    //     edges,
    //   };
    // };
const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  propNode,
  propEdge,
  'LR'
  //{direction:'LR'}
);

  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   [setEdges],
  // );
  useEffect(()=>{
    //initData()
    //onLayout('LR')
  },[])
const LayoutFlow = () => {
  const [valueH, setValueH] = useState('');
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onLayout = useCallback(
    (direction) => {
      const layouted = getLayoutedElements(nodes, edges, direction);

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [nodes, edges]
  );
  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)
      ),
    []
  );
  return (<>
    <div style={{ width: '100%', height: '60vh' }}>
      <ReactFlow
        // nodesDraggable={false}
        // panOnDrag={false}
        // zoomOnScroll={false}
        // zoomOnPinch={false}
        // zoomOnDoubleClick={false}
        //defaultViewport={ {x: 0, y: 0, zoom: 0.8} }
        onInit={() => {fitView()} }
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        //onConnect={onConnect}
        fitView
        //attributionPosition="bottom-left"
        nodeTypes={nodeTypes}
        connectionLineType={ConnectionLineType.SmoothStep}
        //style={{backgroundColor: '#8080805e'}}
      >
      <Controls />
      <Background variant="dots" gap={12} size={1} />
{/*      <Panel position="top-right">
        <button onClick={() => onLayout('TB')}>vertical layout</button>
        <button onClick={() => onLayout('LR')}>horizontal layout</button>
      </Panel>*/}
{/*        
        <MiniMap />
        <Background variant="dots" gap={12} size={1} />*/}
      </ReactFlow>
    </div>
  </>);
  }
  return (
    <ReactFlowProvider>
      <LayoutFlow />
    </ReactFlowProvider>
  );
}