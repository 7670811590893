import React, {createRef, useEffect} from "react";
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_finalized, data_list_processes } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import { get_permission } from "network/ApiAxios";
import jQuery from 'jquery';
import {UserContext} from "layouts/store";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

export default class FinalizedTraceProduction extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    data_traza_meat:[],
    company_id:null,
    setinfoState:false,
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
  }
 async componentDidMount(){
   this.context.getAffiliates()
  }
 setInfoInit = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
          var setTypeT = ""
          if (dataPermission.type_traza.indexOf("P")!=-1) {
            setTypeT="P"
          }else{
            if (dataPermission.type_traza.indexOf("VCF")!=-1) {
              setTypeT="VCF"
            }
          }
          var data_type_area = dataPermission.type_traza.indexOf(setTypeT)
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
          if (dataPermission.type_traza==null) {
    jQuery('#trazanamag').remove()
    return
  }else{
    if (dataPermission.type_traza=="G") {
      jQuery('#trazanamag').remove()
    }
  }
  jQuery("#contaivfm").hide()
  if (dataPermission.role==2) {
    jQuery("#contaivfm").show()
    if (dataPermission.type_traza=="G") {
      jQuery('#trazanamag').remove()
    }
  }else{
    jQuery("#contaivfm").show()
  }
  var exten = this
  var data = await data_list_finalized(jsondata._id,dataPermission.user_id_connection,dataPermission.type,'th',jsondata.userType)
  var gerParam = new URLSearchParams(this.props.location.search).get("company");

        if (setTypeT=="P") {
          this.setState({
            company_id:gerParam,
            full_data_gc:data,
            data_nama_g:data.nama_grain[1],
            data_nama_g_th:data.nama_grain[0],
            type_role_user:dataPermission.type_traza,
          })
        }

        if (setTypeT=="VCF") {
          this.setState({
            company_id:gerParam,
            full_data_gc:data,
            data_nama_g:data.FV_GC[1],
            data_nama_g_th:data.FV_GC[0],
            type_role_user:dataPermission.type_traza,
          })
        }

  // this.setState({
  //   company_id:gerParam,
  //   data_nama_g:data.nama_grain[1],
  //   data_nama_g_th:data.nama_grain[0],
  // })
      }
  setContent = (data) => {
    if (data==undefined) {
      return
    }
    if (data.length!=0) {
    var content=[]
      data.map((a,b)=>{
         var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/vista-trace-admin-grain-nama"
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/vista-trace-admin"
              }
            }
 var content_two = <>
  {(val[0]!='codigo')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
  {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td><td><ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:url_selected,
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver en vista trace
                     </ComponentLink><br />
                    <ComponentLink
                      className="font-weight-bold"
                      to={{
                        pathname:"/admin/vista-qr",
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}>
                     Ver Código Qr
                     </ComponentLink>
                   </td></>):(null)}
                      </>
                      return content_two
        })
        content[b] = <tr>{content_one}</tr>
      })
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]= {title:a.split('*')[0],table:a.split('*')[1]}
      })
    }
    return content
  }
  changeRegistersType = (type) => {
    var setData=[]
    if (type=="P") {
      setData = this.state.full_data_gc.nama_grain
    }
    if (type=="VCF") {
      setData = this.state.full_data_gc.FV_GC
    }
    this.setState({
      data_nama_g:setData[1],
      data_nama_g_th:setData[0],
    })
  }
render(){
  this.setInfoInit()
    const header = (
        <div className="flex flex-column flex-md-row md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Tabla gestión de cultivos resumen de actividades
            </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
              <Input 
              type="select" 
              // value={trazabilidad} 
              onChange={e => {
                this.changeRegistersType(e.target.value)
              }}
              >
                <option value="0">Filtro de Registros</option>
                {(this.state.type_role_user?.indexOf("VCF")!=-1)?(<option value="VCF">Frutas y Vegetales</option>):(null)}
                {(this.state.type_role_user?.indexOf("P")!=-1)?(<option value="P">Granos</option>):(null)}
              </Input>
            </span>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                type="search" 
                value={this.state.globalFilter}
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>
        </div>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button 
                    icon="pi pi-cog" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    onClick={ () => {
                      var setUrlPush = 'vista-trace-admin-grain-nama'
                      if (rowData.codigo.indexOf('FCTVFGC-')!=-1) {
                        setUrlPush = 'trace_view_admin_fv_gc'
                      }
                      this.props.history.push( "/admin/"+setUrlPush+( (this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo) ) )
                    }}
                    tooltip="Ver este proceso" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                />
            </>
        );
    };
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
  return (
    <>
      {/*<SimpleHeader name="Historial" parentName="Historial" section="Producción" section_two="Tabla" section_table_form="table_history" />*/}
        <div className="mt-3">
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    {/*<Toast ref={toast} />*/}

                    <DataTable
                        value={this.state.data_nama_g}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        <Column field="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>
                        {this.setContentTh(this.state.data_nama_g_th).map((a,b)=>{
                          return <Column 
                            body={(data)=>{
                              if (data[a.table]!=null) {
                                if (data[a.table].length>10) {
                                  return `${data[a.table].substring(0, 10)}...`
                                }else{
                                  return data[a.table]
                                }
                                console.log(data[a.table].length)
                              }else{
                                return "Sin datos"
                              }
                            }}
                           field={a.table} header={a.title} ></Column>
                        })}
                        <Column field="created_at" header="Creado" sortable></Column>
                        <Column field="codigo" header="Código" sortable></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>

    
                </div>
            </div>
        </div>
      </div>
    </>
  );
 }
}