import React, {Component} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import QRCode from "react-qr-code";
class VistaQr extends Component {
  state={
    dataQr:''
  }
  componentDidMount(){
    var gerParam = new URLSearchParams(this.props.location.search).get("code");
    var urlRoute;
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (gerParam.indexOf("FCTM-") != -1) {
       urlRoute = 'http://foodchaintrazabilidad.com/home/vista-trace-meat?code='+gerParam;
    }
    if (gerParam.indexOf("FCTGN-") != -1) {
      urlRoute = 'http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code='+gerParam+'&db='+jsondata.database;
    }
    if (gerParam.indexOf("FCT-") != -1) {
     urlRoute = 'http://foodchaintrazabilidad.com/home/vista-trace?code='+gerParam+'&db='+jsondata.database;
    }
    if (gerParam.indexOf("FCTGT-") != -1) {
      urlRoute = 'http://foodchaintrazabilidad.com/home/vista_trace_grain_traza?code='+gerParam+'&db='+jsondata.database;
    }
    this.setState({
      dataQr:urlRoute,
    })
  }
  render(){
  return (
    <>
      <SimpleHeader name="Código Qr" parentName="Qr" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
          <Row>
          <Col md={10}>
            <h3 className="mb-0">Código Qr - Vista Trace</h3>
          </Col>
          <Col md={2}>
            <Button onClick={()=>{this.props.history.goBack()}}>Volver</Button>
          </Col>
          </Row>
          </CardHeader>
          <CardBody className="text-center">
            {<QRCode value={this.state.dataQr} />}
          </CardBody>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}

export default VistaQr;
