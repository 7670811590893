import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import ModalSlaughterhouseExtend from "./ModalExtend/ModalSlaughterhouseExtend";
import { row_slaughterhouse } from "network/ApiAxios";

export default class StartLogistics extends React.Component {
    state={
    dataSlaughterhouseExplode:[],
    dataSlaughterhousePacking:[],
    dataSlaughterhouseFreezing:[]
    //dataSlaughterhouseAll:[],
  }
async componentDidMount(){
        this.initData()
}
initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = this.props.paramsCode
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
        var getParamLote = this.props.paramsCodLote
        var dataSlaughterhouse = await row_slaughterhouse(jsondata._id,gerParam,getParamLote,codigoTrazaTwo)
        this.setState({dataSlaughterhouseExplode:dataSlaughterhouse.explode})
        this.setState({dataSlaughterhousePacking:dataSlaughterhouse.packing})
        this.setState({dataSlaughterhouseFreezing:dataSlaughterhouse.freezing})
        //this.setState({dataTransportAll:dataTransport})


}
  render(){

  return (
    <>
      <Container className="mt--6" fluid>
        <ModalSlaughterhouseExtend sendInfoProps={this.state} setParamsUrl={this.props.paramsCode} refreshData={this.initData} />
      </Container>
    </>
  );
 }
}

//export default Elements;
