import React, {createRef} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import { CountrysData } from "network/DataCountry";
import NotificationAlert from "react-notification-alert";
import config from "config";
import jQuery from 'jquery';
import Web3 from 'web3';
import "./StyleCompany.css";

class Subscription extends React.Component {
async componentDidMount(){
}
async componentWillUnmount(){
}

render(){

  return (<>
   <div>
 <div className="pricing-area">
  <div className="container">
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single-price" style={{width: "70%"}}>
          <div className="deal-top">
            <h3>Actual</h3>
            <h4> Free <span className="sup">$</span> </h4> 
          </div>
          <div className="deal-bottom">
            <ul className="deal-item">
              <li>5 colaboradores</li>
              <li>30 trazabilidades</li>
              <li>24 hour Support </li>
            </ul>
     <div className="btn-area">
              <a href="#">Ingresar</a>       
            </div>
          </div>
        </div>
      </div>
    <div className="row">


      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single-price" style={{width: "70%"}}>
          <div className="deal-top">
            <h3>Basico</h3>
            <h4> 30 <span className="sup">$</span> </h4> 
          </div>
          <div className="deal-bottom">
            <ul className="deal-item">
              <li>25 colaboradores</li>
              <li>50 trazabilidades</li>
              <li>24 hour Support </li>
            </ul>
     <div className="btn-area">
              <a href="#">Ingresar</a>       
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single-price" style={{width: "70%"}}>
          <div className="deal-top">
            <h3>Standard</h3>
            <h4> 60 <span className="sup">$</span> </h4>
          </div>
          <div className="deal-bottom">
            <ul className="deal-item">
              <li>50 colaboradores</li>
              <li>100 trazabilidades</li>
              <li>24 hour Support </li>
            </ul>
            <div className="btn-area">
              <a href="#">Ingresar</a>       
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single-price" style={{width: "70%"}}>
          <div className="deal-top">
            <h3>Pro</h3>
            <h4> 80 <span className="sup">$</span> </h4> 
          </div>
          <div className="deal-bottom">
            <ul className="deal-item">
              <li>100 colaboradores</li>
              <li>1000 trazabilidades</li>
              <li>24 hour Support </li>
            </ul>
            <div className="btn-area">
              <a href="#">Ingresar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>       
</div>
   </div>   
  </>);
 }
}
export default Subscription;
