import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
//filter: hue-rotate(28deg);
import config from "config";

export default class SectionDocuments extends React.Component {
  async componentDidMount(){
  }
  render(){
  return (
    <>
    <div id="documentos-tab" className="clearfix eael-tab-content-item active" data-title-link="documentos-tab">
    <div data-elementor-type="section" data-elementor-id="5570" className="elementor elementor-5570">
    <div className="elementor-section-wrap">
    <section className="elementor-section elementor-top-section elementor-element elementor-element-1fac86c8 elementor-section-content-middle animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="1fac86c8" data-element_type="section" id="services" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-497571a6" data-id="497571a6" data-element_type="column">
    <div className="elementor-widget-wrap elementor-element-populated">

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- TRAZABILIDAD </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- TRAZABILIDAD</span></Col>
    <section className="elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Carnicos info general</h2>    
    </div>
    </div>
    </div>
    </div>*/}
                    {   (this.props.DataRow.traza_meat==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.traza_meat].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- ANIMALES </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- ANIMALES</span></Col>
     <section className="col-md-12 elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos planta: Muestra de calidad</h2>    
    </div>
    </div>
    </div>
    </Col>*/}
    <Row>

                    { (this.props.DataRow.trazaDataAnimal==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.trazaDataAnimal.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COMPARTIMENTO: Recibo animales </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- COMPARTIMENTO</span></Col>
     <section className="col-md-12 elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Recibo animales</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.compartment_receipt_animal==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.compartment_receipt_animal.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COMPARTIMENTO: Salida de animales </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*<section className="col-md-12 elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Salida de animales</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.compartment_exit_animal==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.compartment_exit_animal.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- FRIGORIFICO: Datos frigorifico </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- FRIGORIFICO</span></Col>

<section className="col-md-12 elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos frigorifico</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.fridge_info_fridge==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.fridge_info_fridge.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- FRIGORIFICO: Prodcuto recibido </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*<section className="col-md-12 elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Prodcuto recibido</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.fridge_produc_received==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.fridge_produc_received.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- FRIGORIFICO: Faena </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/* <section className="elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Faena</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.fridge_produc_task==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.fridge_produc_task.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- MATADERO: Despiece </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- MATADERO</span></Col>
     <section className="elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Despiece</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.slaughterhouse_exploded_record==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.slaughterhouse_exploded_record.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- MATADERO: Empaque </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*     <section className="elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Empaque</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.slaughterhouse_packing==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.slaughterhouse_packing.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- MATADERO: Camara de Frio </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">

{/*     <section className="elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Camara de Frio</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.slaughterhouse_freezing==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.slaughterhouse_freezing.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
   </CardBody>
    </Col>
    </Card>
     
         <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- LOGISTICA Y TRANSPORTE: Datos Transporte </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
        
{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- LOGISTICA Y TRANSPORTE</span></Col>
     <section className="elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos Transporte</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.logistics_transport_from==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.logistics_transport_from.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
   </CardBody>
    </Col>
    </Card>

         <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#9d0e0d'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- LOGISTICA Y TRANSPORTE: Datos Transporte Exportación </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
        
{/*        <section className="elementor-section elementor-inner-section elementor-element elementor-element-635b709 animated-fast elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    <Col md="3" style={{alignSelf: 'center'}}>
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos Transporte Exportación</h2>    
    </div>
    </div>
    </div>
    </Col>
    <Col md="9">*/}
    <Row>

                    { (this.props.DataRow.logistics_transport_export==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.logistics_transport_export.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      var name_file_noline = String(value.archivo).split('_').pop();

                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?(null):(
    <div className="col-md-4" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file_noline}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
    </div>
                  )))
                })}
    </Row>
   </CardBody>
    </Col>
    </Card>
    
    </div>
    </div>
    </div>
    </section>
    </div>
    </div>
    </div>
    </>
  );
}
}

//export default Elements;
