import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table
} from "reactstrap";

import { delete_traza_animal, data_details_animal, set_vacuna_register, set_peso_register, set_aftosa_register, set_fattening_register, set_medicine_register, set_moreinfo_register, set_task_register, delete_vacuna, delete_peso, delete_aftosa, delete_fattening, delete_medicine, delete_moreinfo, delete_task, delete_arete, set_arete_register } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import jQuery from "jquery";
import config from "config";

export default class DetailsAnimal extends React.Component {
  state ={
    notificationAlertRef: React.createRef(),
    sumFieldVacuna:0,
    sumFieldpeso:0,
    sumFieldaftosa:0,
    sumFieldfattening:0,
    sumFieldmedicine:0,
    sumFieldmoreinfo:0,
    sumFieldtask:0,
    dataAnimal: null,
    fecha_registro:'',
    madre_id:'',
    animal_arete_id:'',
    fecha_nacimiento:'',
    raza:'',
    edad:'',
    unidad_edad:'',
    sexo:'',
    type_lote:[],
    compartiment:[],
    btnd:false,
    input_vacuna_fecha:[],
    dataVacunas:[],
    dataVacunasTatic:[],
    datapeso:[],
    datapesoTatic:[],
    dataaftosa:[],
    dataaftosaTatic:[],
    datafattening:[],
    datafatteningTatic:[],
    datamedicine:[],
    datamedicineTatic:[],
    datamoreinfo:[],
    datamoreinfoTatic:[],
    datatask:[],
    datataskTatic:[],
    sumFieldarete:0,
    dataarete:[],
    dataareteTatic:[],
    openModal:false,
    sumFieldtaskAnimalGlobal:[],
    datataskAnimalGlobal:[],
    datataskAnimalGlobalTatic:[],
    animal_register_id:'',
    codigo_traza:'',
    nombre_traza:''
  } 
 async componentDidMount(){
    this.dataInitSet()
  }
  dataInitSet = async () => {
    var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
    var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
    var gerParamTree = new URLSearchParams(this.props.location.search).get("edit");
    var gerParamFour = new URLSearchParams(this.props.location.search).get("lote");
    var data = await data_details_animal(gerParamOne,gerParamTwo,gerParamFour)
    this.setState({animal_id:gerParamTwo})
    this.setState({codigo:gerParamOne})
    this.setState({confirmEdit:gerParamTree})

if (data.vacuna.length!=0) {
    this.setState({
      sumFieldVacuna:data.vacuna.length,
      dataVacunas:data.vacuna,
      dataVacunasTatic:data.vacuna
    })
}
if (data.peso.length!=0) {
    this.setState({
      sumFieldpeso:data.peso.length,
      datapeso:data.peso,
      datapesoTatic:data.peso
    })
}
if (data.aftosa.length!=0) {
    this.setState({
      sumFieldaftosa:data.aftosa.length,
      dataaftosa:data.aftosa,
      dataaftosaTatic:data.aftosa
    })
}
if (data.fattening.length!=0) {
    this.setState({
      sumFieldfattening:data.fattening.length,
      datafattening:data.fattening,
      datafatteningTatic:data.fattening
    })
}
if (data.medicine.length!=0) {
    this.setState({
      sumFieldmedicine:data.medicine.length,
      datamedicine:data.medicine,
      datamedicineTatic:data.medicine
    })
}
if (data.moreinfo.length!=0) {
    this.setState({
      sumFieldmoreinfo:data.moreinfo.length,
      datamoreinfo:data.moreinfo,
      datamoreinfoTatic:data.moreinfo
    })
}
if (data.task.length!=0) {
    this.setState({
      sumFieldtask:data.task.length,
      datatask:data.task,
      datataskTatic:data.task
    })
}
if (data.arete.length!=0) {
    this.setState({
      sumFieldarete:data.arete.length,
      dataarete:data.arete,
      dataareteTatic:data.arete
    })
}

if (data.taskAnimalGlobal.length!=0) {
    this.setState({
      sumFieldtaskAnimalGlobal:data.taskAnimalGlobal.length,
      datataskAnimalGlobal:data.taskAnimalGlobal,
      datataskAnimalGlobalTatic:data.taskAnimalGlobal,
    })
  }
    if (data.dataAnimal==null) {
      this.setState({
        animal_register_id:'Sin data',
        nombre_traza:'Sin data',
        codigo_traza:'Sin data',
        detailAnimal:'Sin data',
        fecha_registro:'Sin data',
        madre_id:'Sin data',
        animal_arete_id:'Sin data',
        fecha_nacimiento:'Sin data',
        raza:'Sin data',
        edad:'Sin data',
        unidad_edad:'Sin data',
        sexo:'Sin data',
        img_animal:'Sin data',
        type_lote:'Sin data',
        compartiment:'Sin data',
      })
    }else{      
      this.setState({
        animal_register_id:data.dataAnimal.id,
        nombre_traza:data.dataAnimal.nombre_traza,      
        codigo_traza:data.dataAnimal.codigo,
        detailAnimal:data.dataAnimal,
        fecha_registro:data.dataAnimal.fecha_registro,
        madre_id:data.dataAnimal.madre_id,
        animal_arete_id:data.dataAnimal.animal_id,
        fecha_nacimiento:data.dataAnimal.fecha_nacimiento,
        raza:(data.dataAnimal.raza=="null")?('Sin Datos'):(data.dataAnimal.raza),
        edad:(data.dataAnimal.edad=="null")?('Sin Datos'):(data.dataAnimal.edad),
        unidad_edad:(data.dataAnimal.unidadedad=="null")?('Sin Datos'):(data.dataAnimal.unidadedad),
        sexo:(data.dataAnimal.sexo=="null")?('Sin Datos'):(data.dataAnimal.sexo),
        img_animal:(data.dataAnimal.archivo==null)?('Sin imagen'):(data.dataAnimal.archivo),
        type_lote:(data.dataAnimal.type_lote=="0")?('Sin lote asignado'):(data.dataAnimal.type_lote),
        compartiment:(data.compartment==null)?('Sin fecha'):((data.compartment.fecha_recibido=="0")?('Sin fecha'):(data.compartment.fecha_recibido+' '+data.compartment.hora_recibido)),
      })
    }
  }
  render(){

const sumFieldVacuna = () => {
     /* var content = document.querySelectorAll('.dataVacunaRowVacuna')
      var contentDosis = document.querySelectorAll('.dataVacunaRowDosis')
  for (var i = 0; i < content.length; i++) {
      if (content!=null) {
        if (content[i]!=undefined) {
        content[i].value=content[i].value
        contentDosis[i].value=contentDosis[i].value
        }
      }
  }*/
  this.setState({sumFieldVacuna:this.state.sumFieldVacuna+1})
   this.setState({dataVacunas:[1]})


  //this.setState({dataVacunas:[...this.state.dataVacunas,{id:this.state.sumFieldVacuna+1,vacuna:null,dosis:null}]})
}
const restFieldVacuna = async (index,id_vacuna) => {
  //document.querySelector("."+index).remove()
  
  if (id_vacuna!="idNone") {
    var result = await delete_vacuna(id_vacuna)
    if (result=="exito") {
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }

  }
}

const vacunasContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var index = 0; index < this.state.sumFieldVacuna; index++) {
  //document.querySelectorAll(".dataVacunaRowVacuna")[i].value = (value==undefined)?(null):(value.vacuna)
             //this.state.dataVacunas.map((value,index)=>{

             data[index] = <Row style={{marginBottom: '5px'}} className={"vacunaSection"+index}>
                        <Col md={3}>

                              <span>{(this.state.dataVacunas[index]==undefined)?(completeDate):('Fecha: '+this.state.dataVacunas[index].fecha)}</span>
                              
                        </Col> 
                         <Col md={3}>

                              <span>{(this.state.dataVacunas[index]==undefined)?(null):('Vacuna: '+this.state.dataVacunas[index].vacuna)}</span>
                        </Col>
                        <Col md={3}>
                              <span>{(this.state.dataVacunas[index]==undefined)?(null):('Dosis: '+this.state.dataVacunas[index].dosis)}</span>
                        </Col>

                      </Row>

             //})
                    }
                return(data)
    }
const loadInfoVacuna = (result,section) => {
var content = document.querySelectorAll('.dataVacunaRowVacuna')[section]
var contentDosis = document.querySelectorAll('.dataVacunaRowDosis')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.vacuna==undefined)?(content.value):(result.vacuna)
    contentDosis.value=(result.dosis==undefined)?(contentDosis.value):(result.dosis)
    }
  }
}
  const saveInfoVacuna = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var dataVacunaGetFecha = document.querySelectorAll(".dataVacunaRowFecha");
      var dataVacunaGetVacuna = document.querySelectorAll(".dataVacunaRowVacuna");
      var dataVacunaGetDosis = document.querySelectorAll(".dataVacunaRowDosis");
      for (var i = 0; i < dataVacunaGetFecha.length; i++) {
        let id = dataVacunaGetFecha[i].getAttribute("vacuna-id");
        let vacuna_fecha = dataVacunaGetFecha[i].value
        let vacuna_vacuna = dataVacunaGetVacuna[i].value
        let vacuna_dosis = dataVacunaGetDosis[i].value

        dataInfo[i] = {id_vacuna:id,vacuna_fecha:vacuna_fecha,vacuna_vacuna:vacuna_vacuna,vacuna_dosis:vacuna_dosis}
      }
       var data_vacuna_result = await set_vacuna_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_vacuna_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }

const sumFieldpeso = () => {
  this.setState({sumFieldpeso:this.state.sumFieldpeso+1})
  this.setState({datapeso:[this.state.sumFieldpeso+1]})
}
const restFieldpeso = async (index,id_peso) => {

  if (id_peso!="idNone") {
        var result = await delete_peso(id_peso)
    if (result=="exito") {
      this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  //document.querySelector("."+index).remove()
}
    const pesoContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldpeso; i++) {
  //document.querySelectorAll(".datapesoRowVacuna")[i].value = (this.state.datapeso[i]==undefined)?(null):(this.state.datapeso[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"pesoSection"+i}>
                        <Col md={3}>

                              <span>{(this.state.datapeso[i]==undefined)?(null):('Fecha: '+this.state.datapeso[i].fecha)}</span>
                             
                        </Col> 
                         <Col md={6}>

                              <span>{(this.state.datapeso[i]==undefined)?(null):('Peso: '+this.state.datapeso[i].agregar_peso+' '+this.state.datapeso[i].unidad_peso)}</span>
                              
                        </Col>
                        
                      </Row>

                    }
                return(data)
    }
    const loadInfopeso = (result,section) => {
    var content = document.querySelectorAll('.datapesoRowpeso')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.agregar_peso==undefined)?(content.value):(result.agregar_peso)
    }
  }
}

  const saveInfopeso = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datapesoGetFecha = document.querySelectorAll(".datapesoRowFecha");
      var datapesoGetpeso = document.querySelectorAll(".datapesoRowpeso");
      for (var i = 0; i < datapesoGetFecha.length; i++) {
        let id = datapesoGetFecha[i].getAttribute("peso-id");
        let peso_fecha = datapesoGetFecha[i].value
        let peso_peso = datapesoGetpeso[i].value

        dataInfo[i] = {id_peso:id,peso_fecha:peso_fecha,peso_peso:peso_peso}
      }
       var data_peso_result = await set_peso_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_peso_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldaftosa = () => {
  this.setState({sumFieldaftosa:this.state.sumFieldaftosa+1})
  this.setState({dataaftosa:[this.state.sumFieldaftosa+1]})
}
const restFieldaftosa = async (index,id_aftosa) => {
  if (id_aftosa!="idNone") {
        var result = await delete_aftosa(id_aftosa)
    if (result=="exito") {
    //  this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  document.querySelector("."+index).remove()
}
    const aftosaContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldaftosa; i++) {
  //document.querySelectorAll(".dataaftosaRowVacuna")[i].value = (this.state.dataaftosa[i]==undefined)?(null):(this.state.dataaftosa[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"aftosaSection"+i}>
                        <Col md={3}>
                              <span>{(this.state.dataaftosa[i]==undefined)?(null):('Fecha: '+this.state.dataaftosa[i].fecha)}</span>
                        </Col> 
                         <Col md={6}>
                              <span>{(this.state.dataaftosa[i]==undefined)?(null):('Resultado: '+this.state.dataaftosa[i].resultado)}</span>
                        </Col>
                      </Row>

                    }
                return(data)
    }
        const loadInfoaftosa = (result,section) => {
    var content = document.querySelectorAll('.dataaftosaRowaftosa')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.resultado==undefined)?(content.value):(result.resultado)
    }
  }
}
  const saveInfoaftosa = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var dataaftosaGetFecha = document.querySelectorAll(".dataaftosaRowFecha");
      var dataaftosaGetaftosa = document.querySelectorAll(".dataaftosaRowaftosa");
      var dataaftosaGetDosis = document.querySelectorAll(".dataaftosaRowDosis");
      for (var i = 0; i < dataaftosaGetFecha.length; i++) {
        let id = dataaftosaGetFecha[i].getAttribute("aftosa-id");
        let aftosa_fecha = dataaftosaGetFecha[i].value
        let aftosa_resultado = dataaftosaGetaftosa[i].value

        dataInfo[i] = {id_aftosa:id,aftosa_fecha:aftosa_fecha,aftosa_resultado:aftosa_resultado}
      }
       var data_aftosa_result = await set_aftosa_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_aftosa_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
const sumFieldfattening = () => {
  this.setState({sumFieldfattening:this.state.sumFieldfattening+1})
  this.setState({datafattening:[this.state.sumFieldfattening+1]})
}
const restFieldfattening = async (index,id_fattening) => {
  if (id_fattening!="idNone") {
        var result = await delete_fattening(id_fattening)
    if (result=="exito") {
    //  this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  document.querySelector("."+index).remove()
}
    const fatteningContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldfattening; i++) {
  //document.querySelectorAll(".datafatteningRowVacuna")[i].value = (this.state.datafattening[i]==undefined)?(null):(this.state.datafattening[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"fatteningSection"+i}>
                        <Col md={3}>

                              <span>{(this.state.datafattening[i]==undefined)?(null):('Fecha: '+this.state.datafattening[i].fecha)}</span>
                             
                        </Col> 
                         <Col md={6}>
                              <span>{(this.state.datafattening[i]==undefined)?(null):('Tipo de alimento: '+this.state.datafattening[i].tipo_alimento)}</span>
                        </Col>
                      </Row>

                    }
                return(data)
    }
const loadInfofattening = (result,section) => {
    var content = document.querySelectorAll('.datafatteningRowfattening')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.tipo_alimento==undefined)?(content.value):(result.tipo_alimento)
    }
  }
}
  const saveInfofattening = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datafatteningGetFecha = document.querySelectorAll(".datafatteningRowFecha");
      var datafatteningGetfattening = document.querySelectorAll(".datafatteningRowfattening");
      for (var i = 0; i < datafatteningGetFecha.length; i++) {
        let id = datafatteningGetFecha[i].getAttribute("fattening-id");
        let fattening_fecha = datafatteningGetFecha[i].value
        let fattening_alimento = datafatteningGetfattening[i].value

        dataInfo[i] = {id_fattening:id,fattening_fecha:fattening_fecha,fattening_alimento:fattening_alimento}
      }
       var data_fattening_result = await set_fattening_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_fattening_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldmedicine = () => {
  this.setState({sumFieldmedicine:this.state.sumFieldmedicine+1})
  this.setState({datamedicine:[this.state.sumFieldmedicine+1]})
}
const restFieldmedicine = async (index,id_medicine) => {
  if (id_medicine!="idNone") {
        var result = await delete_medicine(id_medicine)
    if (result=="exito") {
    //  this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  document.querySelector("."+index).remove()
}
    const medicineContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldmedicine; i++) {
  //document.querySelectorAll(".datamedicineRowVacuna")[i].value = (this.state.datamedicine[i]==undefined)?(null):(this.state.datamedicine[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"medicineSection"+i}>
                        <Col md={3}>
                              <span>{(this.state.datamedicine[i]==undefined)?(null):('Fecha: '+this.state.datamedicine[i].fecha)}</span>                             
                        </Col> 
                         <Col md={3}>
                              <span>{(this.state.datamedicine[i]==undefined)?(null):('Medicamento: '+this.state.datamedicine[i].medicamento)}</span>
                        </Col>
                        <Col md={3}>
                              <span>{(this.state.datamedicine[i]==undefined)?(null):('Dosis: '+this.state.datamedicine[i].dosis)}</span>
                        </Col>
                      </Row>

                    }
                return(data)
    }
const loadInfomedicine = (result,section) => {
    var content = document.querySelectorAll('.datamedicineRowmedicine')[section]
    var contentDosis = document.querySelectorAll('.datamedicineRowDosis')[section]
  if (content!=null) {
    if (result!=undefined) {
      content.value=(result.medicamento==undefined)?(content.value):(result.medicamento)
     contentDosis.value=(result.dosis==undefined)?(content.value):(result.dosis)

    }
  }
}
  const saveInfomedicine = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datamedicineGetFecha = document.querySelectorAll(".datamedicineRowFecha");
      var datamedicineGetmedicine = document.querySelectorAll(".datamedicineRowmedicine");
      var datamedicineGetDosis = document.querySelectorAll(".datamedicineRowDosis");
      for (var i = 0; i < datamedicineGetFecha.length; i++) {
        let id = datamedicineGetFecha[i].getAttribute("medicine-id");
        let medicine_fecha = datamedicineGetFecha[i].value
        let medicine_medicine = datamedicineGetmedicine[i].value
        let medicine_dosis = datamedicineGetDosis[i].value

        dataInfo[i] = {id_medicine:id,medicine_fecha:medicine_fecha,medicine_medicine:medicine_medicine,medicine_dosis:medicine_dosis}
      }
       var data_medicine_result = await set_medicine_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_medicine_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldmoreinfo = () => {
  this.setState({sumFieldmoreinfo:this.state.sumFieldmoreinfo+1})
  this.setState({datamoreinfo:[this.state.sumFieldmoreinfo+1]})
}
const restFieldmoreinfo = async (index,id_moreinfo) => {
  if (id_moreinfo!="idNone") {
        var result = await delete_moreinfo(id_moreinfo)
    if (result=="exito") {
    //  this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  document.querySelector("."+index).remove()
}
    const moreinfoContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldmoreinfo; i++) {
  //document.querySelectorAll(".datamoreinfoRowVacuna")[i].value = (this.state.datamoreinfo[i]==undefined)?(null):(this.state.datamoreinfo[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"moreinfoSection"+i}>
                        <Col md={3}>

                              <span>{(this.state.datamoreinfo[i]==undefined)?(null):('Fecha: '+this.state.datamoreinfo[i].fecha)}</span>                             
                        </Col> 
                         <Col md={6}>

                              <span>{(this.state.datamoreinfo[i]==undefined)?(null):('Información adicional: '+this.state.datamoreinfo[i].informacion_adicional)}</span>                              
                        </Col>
                      </Row>

                    }
                return(data)
    }
const loadInfomoreinfo = (result,section) => {
    var content = document.querySelectorAll('.datamoreinfoRowmoreinfo')[section]
  if (content!=null) {
    if (result!=undefined) {
      content.value=(result.informacion_adicional==undefined)?(content.value):(result.informacion_adicional)

    }
  }
}
  const saveInfomoreinfo = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datamoreinfoGetFecha = document.querySelectorAll(".datamoreinfoRowFecha");
      var datamoreinfoGetmoreinfo = document.querySelectorAll(".datamoreinfoRowmoreinfo");
      for (var i = 0; i < datamoreinfoGetFecha.length; i++) {
        let id = datamoreinfoGetFecha[i].getAttribute("moreinfo-id");
        let moreinfo_fecha = datamoreinfoGetFecha[i].value
        let moreinfo_moreinfo = datamoreinfoGetmoreinfo[i].value

        dataInfo[i] = {id_moreinfo:id,moreinfo_fecha:moreinfo_fecha,moreinfo_moreinfo:moreinfo_moreinfo}
      }
       var data_moreinfo_result = await set_moreinfo_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_moreinfo_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
    const sumFieldtask = () => {
  this.setState({sumFieldtask:this.state.sumFieldtask+1})
  this.setState({datatask:[this.state.sumFieldtask+1]})
}
const restFieldtask = async (index,id_task) => {
  if (id_task!="idNone") {
        var result = await delete_task(id_task)
    if (result=="exito") {
    //  this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  document.querySelector("."+index).remove()
}
    const taskContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldtask; i++) {
  //document.querySelectorAll(".datataskRowVacuna")[i].value = (this.state.datatask[i]==undefined)?(null):(this.state.datatask[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"taskSection"+i}>
                        <Col md={3}>
                              <span>{(this.state.datatask[i]==undefined)?(null):('Fecha: '+this.state.datatask[i].fecha)}</span>
                        </Col> 
                         <Col md={3}>
                              <span>{(this.state.datatask[i]==undefined)?(null):('Hora: '+this.state.datatask[i].hora)}</span>
                            </Col>

                      </Row>

                    }
                return(data)
    }
    const taskContentGlobal = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldtaskAnimalGlobal; i++) {
  //document.querySelectorAll(".datataskRowVacuna")[i].value = (this.state.datatask[i]==undefined)?(null):(this.state.datatask[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"taskSection"+i}>
                        <Col md={4}>
                              <span>{(this.state.datataskAnimalGlobal[i]==undefined)?(null):('Fecha: '+this.state.datataskAnimalGlobal[i].fecha)}</span>
                        </Col> 
                         <Col md={4}>
                              <span>{(this.state.datataskAnimalGlobal[i]==undefined)?(null):('Hora Inicio: '+this.state.datataskAnimalGlobal[i].hora)}</span>                           
                        </Col>
                         <Col md={4}>
                              <span>{(this.state.datataskAnimalGlobal[i]==undefined)?(null):('Hora Final: '+this.state.datataskAnimalGlobal[i].hora_final)}</span>                           
                        </Col>
                      </Row>

                    }
                return(data)
    }
const loadInfotask = (result,section) => {
    var content = document.querySelectorAll('.datataskRowtime')[section]
  if (content!=null) {
    if (result!=undefined) {
      content.value=(result.hora==undefined)?(content.value):(result.hora)
    }
  }
}
  const saveInfotask = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var datataskGetFecha = document.querySelectorAll(".datataskRowFecha");
      var datataskGetTime = document.querySelectorAll(".datataskRowtime");
      for (var i = 0; i < datataskGetFecha.length; i++) {
        let id = datataskGetFecha[i].getAttribute("task-id");
        let task_fecha = datataskGetFecha[i].value
        let task_time = datataskGetTime[i].value

        dataInfo[i] = {id_task:id,task_fecha:task_fecha,task_time:task_time}
      }
       var data_task_result = await set_task_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_task_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }

    const sumFieldarete = () => {
  this.setState({sumFieldarete:this.state.sumFieldarete+1})
  this.setState({dataarete:[this.state.sumFieldarete+1]})
}
const restFieldarete = async (index,id_arete) => {
  if (id_arete!="idNone") {
        var result = await delete_arete(id_arete)
    if (result=="exito") {
    //  this.dataInitSet()
      notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    }
  }
  document.querySelector("."+index).remove()
}
    const areteContent = () => {
var date = new Date();
var completeDate = date.toLocaleDateString("en-CA")
var data = []
for (var i = 0; i < this.state.sumFieldarete; i++) {
  //document.querySelectorAll(".dataareteRowVacuna")[i].value = (this.state.dataarete[i]==undefined)?(null):(this.state.dataarete[i].vacuna)
             data[i] = <Row style={{marginBottom: '5px'}} className={"areteSection"+i}>
                        <Col md={3}>

                              <span>{(this.state.dataarete[i]==undefined)?(null):('Fecha: '+this.state.dataarete[i].fecha)}</span>
                        </Col> 
                         <Col md={6}>

                              <span>{(this.state.dataarete[i]==undefined)?(null):(this.state.dataarete[i].informacion_adicional)}</span>
                              
                        </Col>
                      </Row>

                    }
                return(data)
    }
const loadInfoarete = (result,section) => {
    var content = document.querySelectorAll('.dataareteRowarete')[section]
  if (content!=null) {
    if (result!=undefined) {
    content.value=(result.informacion_adicional==undefined)?(content.value):(result.informacion_adicional)
    }
  }
}
  const saveInfoarete = async () => {
      var gerParamOne = new URLSearchParams(this.props.location.search).get("code");
      var gerParamTwo = new URLSearchParams(this.props.location.search).get("id_animal");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var dataInfo = []
      var dataareteGetFecha = document.querySelectorAll(".dataareteRowFecha");
      var dataareteGetarete = document.querySelectorAll(".dataareteRowarete");
      for (var i = 0; i < dataareteGetFecha.length; i++) {
        let id = dataareteGetFecha[i].getAttribute("arete-id");
        let arete_fecha = dataareteGetFecha[i].value
        let arete_info = dataareteGetarete[i].value

        dataInfo[i] = {id_arete:id,arete_fecha:arete_fecha,arete_info:arete_info}
      }
       var data_arete_result = await set_arete_register(dataInfo,gerParamOne,gerParamTwo,jsondata._id)

       if (data_arete_result=='exito') {
        this.dataInitSet()
        notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
       }else{
        notify("danger", "¡Registrado Fallido!", '!!No se pudo Guardar.!!');
       }
    }
// const deleteAnimal = async (id) => {
//     if (window.confirm("Deseas eliminar este registro?")) {
//     var dataReturn = await delete_traza_animal(id)
//             if (dataReturn=="exito") {
//             notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
//             this.props.history.push('/admin/meats/add-animal?code='+this.state.codigo)
//           }else{
//             notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
//           }
//   }
// }
  const deleteAnimal = async (selectMotive,fecha_muerte,motivo,lugar_siniestro) => {
    if (motivo=="") {
      notify("danger","¡Fallo de eliminación!", 'Por favor escriba un motivo.');
    }else{
      var selectMotiveChange;
      if (selectMotive=="2") {
        selectMotiveChange = selectMotive
      }else{
        selectMotiveChange =lugar_siniestro
      }
       if (window.confirm("Deseas eliminar este registro?")) {
       var dataReturn = await delete_traza_animal(this.state.idDeleteAnimal,fecha_muerte,motivo,selectMotiveChange)
               if (dataReturn=="exito") {
                this.setState({
                  openModal:false,
                })

               notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
               //this.initData()
               this.props.history.push('/admin/meats/eliminate-animal?code='+this.state.codigo)
             }else{
               notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
             }
     }
    }

}
  const funcOpenModal = (chan,idAnimal) =>{
    this.setState({
      openModal:chan,
      idDeleteAnimal:idAnimal
    })
  }
      const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    return(<>
<Card>
<CardBody style={{padding: '3.5rem'}}>
<Row>
<Card className="mt-6">
        <CardBody className="col-md-12">
          <CardHeader className="border-0">
            <Row>
              <Col md="11">
                <h3 className="mb-0">Datos Animal Individual</h3>
              </Col>
            <a href="javascript:;" onClick={()=>{this.props.history.goBack()}}>
              <Col md="1" style={{fontSize: '30px',fontWeight: '500'}}>X</Col>
            </a>
            </Row>
          </CardHeader>
          <Row>
            <Col md={12}>
                <Col md={12} className="text-left">
                {(this.state.img_animal==null)?('Sin Imagen'):((this.state.img_animal=='null')?('Sin imagen'):
                  ((this.state.img_animal=='Sin imagen')?('Sin imagen'):(<img src={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.img_animal} />
                                    )))}
{/*                  <img src={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.img_animal}
                  />*/}
                </Col>
                <Col md={12} className="text-center">
                      <span style={{color:"#32325d",fontWeight: '700',fontSize: '12px'}}>{(this.state.dataareteTatic[0]==undefined)?(null):('Nuevo arete registrado: '+this.state.dataareteTatic[this.state.dataareteTatic.length-1].informacion_adicional+'. Arete original: '+this.state.animal_arete_id)}</span>
                </Col>
                <Col md={12}>
                <Row>
                    <Col md={6}>
                      <span style={style.titleOne}>Nombre de la trazabilidad actual: {this.state.nombre_traza}</span><br />                    
                      <span style={style.titleOne}>Foodchain ID: {this.state.codigo_traza}</span><br />
                      <span style={style.titleOne}>Animal ID: {this.state.animal_register_id}</span><br />
                      <span style={style.title}>Lote Nro: {this.state.type_lote}</span><br />
                      <span style={style.titleTwo}>Fecha de Registro: {this.state.fecha_registro}</span><br />
                      <span style={style.titleTwo}>Arete de la Madre: {this.state.madre_id}</span><br />
                      <span style={style.titleTwo}>Arete del Animal: {this.state.animal_arete_id}</span><br />
                      <span style={style.titleTwo}>Fecha de Nacimiento: {this.state.fecha_nacimiento}</span><br />
                      <span style={style.titleTwo}>Raza: {this.state.raza}</span><br />
                      <span style={style.titleTwo}>Edad: {this.state.edad+' '+this.state.unidad_edad}</span><br />
                      <span style={style.titleTwo}>Sexo: {this.state.sexo}</span>
                    </Col>
                    <Col md={6}>
                      <span style={style.title}>Ingreso a Compartimento: {this.state.compartiment}</span><br /><br />
                      <span style={style.title}>Vacunas:</span><br />
                      <span className="vacunaSectionSpan" style={style.titleTwo}>{vacunasContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldVacuna()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfoVacuna()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Peso:</span><br />
                      <span style={style.titleTwo}>{pesoContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldpeso()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfopeso()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Medicamento:</span><br />
                      <span style={style.titleTwo}>{medicineContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldmedicine()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfomedicine()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Prueba de Aftosa:</span><br />
                      <span style={style.titleTwo}>{aftosaContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldaftosa()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfoaftosa()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Faena:</span><br />
                      <span style={style.titleTwo}>{taskContentGlobal()}</span>
                      <span style={style.titleTwo}>{taskContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldtask()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfotask()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Información:</span><br />
                      <span style={style.titleTwo}>{moreinfoContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldmoreinfo()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfomoreinfo()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Engorde:</span><br />
                      <span style={style.titleTwo}>{fatteningContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldfattening()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfofattening()}}>Guardar</Button>
                        </>
                      }
                    </Col>
                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Nuevo arete por perdida del anterior:</span><br />
                      <span style={style.titleTwo}>{areteContent()}</span>
                      {(this.state.confirmEdit == null)
                        ?
                        (null)
                        :
                        <>
                        <Button style={style.titleBtnPlus} onClick={() => {sumFieldarete()}}>+</Button>
                        <Button style={style.titleBtnPlus} onClick={() => {saveInfoarete()}}>Guardar</Button>
                        </>
                      }
                    </Col>
{/*                    <Col md={6} style={{marginTop: '12px'}}>
                      <span style={style.title}>Peso en Carne:</span><br />
                      <span style={style.titleTwo}>Fecha: - info:</span><br />
                    </Col>*/}
                  </Row>
                </Col>
            </Col>
          </Row>
          <br />
{/*              <Col md="12" className="text-center" style={{marginBottom: "20px"}}>
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfo}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar Información</span>
                </Button>
                </Col>*/}
            </CardBody>
           </Card>
          </Row>
         </CardBody>
        </Card>

     </> )
  }
}
const style = {
  titleOne:{
     color: "#204D74",
     fontWeight: '700',
     fontSize: '12px'
  },
  title:{
     color: "#9d0d0d",
     fontWeight: '700',
     fontSize: '12px'
  },
  titleBtnPlus:{
    boxShadow:'none'
  },
  titleTwo:{
     color: "#8e8e8e",
     fontWeight: '700',
     fontSize: '12px'
  }
}