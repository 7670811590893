import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import ModalStartLogistics from "./ModalExtend/ModalLogisticsTransportExtend";
import { row_transport_logistics } from "network/ApiAxios";

export default class StartLogistics extends React.Component {
    state={
    dataTransporte:[],
    dataTransportAll:[],
    dataTransportExport:[],
  }
async componentDidMount(){
        this.initData()
}
initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = this.props.paramsCode
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
        var dataTransport = await row_transport_logistics(jsondata._id,gerParam,codigoTrazaTwo)
        this.setState({dataTransportAll:dataTransport})
        this.setState({
          dataTransporte:dataTransport.dataLogisticsTransport,
          dataTransportExport:dataTransport.dataLogisticsTransportExport
        })
}
  render(){

  return (
    <>
      <Container className="mt--6" fluid>
        <ModalStartLogistics sendInfoProps={this.state} setParamsUrl={this.props.paramsCode} refreshData={this.initData} />
      </Container>
    </>
  );
 }
}

//export default Elements;
