import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
//import {PutContent, PutContentStatus, PutContentSub} from './InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'
const datacontent = [
{table:'fruit_vegetable',title:'Datos Trazabilidad',icon:'far fa-newspaper','multiple_tables':0},
]
export default class SectionTraza extends React.Component {
  render(){
  return (
    <>
      <ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
    </>
  );
}
}

//export default Elements;
