import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import ModalSlaughterhouse from "./Modal/ModalSlaughterhouse";
import { row_slaughterhouse } from "network/ApiAxios";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class StartLogistics extends React.Component {
    state={
    dataSlaughterhouseExplode:[],
    dataSlaughterhousePacking:[],
    dataSlaughterhouseFreezing:[]
    //dataSlaughterhouseAll:[],
  }
async componentDidMount(){
        this.initData()
}
initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");
        var getParamLote = new URLSearchParams(this.props.location.search).get("lote");
        var dataSlaughterhouse = await row_slaughterhouse(jsondata._id,gerParam,getParamLote)

        this.setState({dataSlaughterhouseExplode:dataSlaughterhouse.explode})
        this.setState({dataSlaughterhousePacking:dataSlaughterhouse.packing})
        this.setState({dataSlaughterhouseFreezing:dataSlaughterhouse.freezing})
        //this.setState({dataTransportAll:dataTransport})
}
  render(){

  return (
    <>
    <MeatsNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Matadero" parentName="Matadero" />
      <Container className="mt--6" fluid>
        <ModalSlaughterhouse sendInfoProps={this.state} setParamsUrl={this.props.location.search} refreshData={this.initData} />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
 }
}

class SwiftComponent extends React.Component {
    static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
if (dataPermission.type_traza=='VF' || dataPermission.type_traza==null) {
      this.setState({get_permission:false})
      return;
    }
            var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.frigorifico_m==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <StartLogistics {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
