import React, {useState} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  Table
} from "reactstrap";
import SelectSearch from 'react-select-search';
//import "react-select-search/style.css";
import "./stylesearh.css";
import jQuery from 'jquery';
import config from "config";

const forContentText = (props,i) => {
  var content = []
    content=  <Col md={props.content[i].col_field} key={i}>
                 <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                  <InputGroup>
                    {(props.content[i].type_field_include=='select_include')?(<>
                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(props.field_params[props.content[i].name_field.replace('select_','')]==undefined)?(''):(props.field_params[props.content[i].name_field.replace('select_','')])}
                      id={props.content[i].name_field.replace('select_','')}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />

                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                         value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                         id={props.content[i].name_field}
                         onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                         disabled={(props.content[i].dis_field==1)? (true) : (false)}
                         isrequired={props.content[i].req_field}
                         type="select">
                         <option value="0">Seleccione...</option>
                         {props.content[i]?.value_option?.split('|').map((a,b)=>{
                         return <option key={b} value={a}>{a}</option>
                         })}
                       </Input>
                      </>):(<>
                            <Input
                            style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                            value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                            id={props.content[i].name_field}
                            data_filter={props.content[i].list_default}
                            isrequired={props.content[i].req_field}
                            onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                            disabled={(props.content[i].dis_field==1)? (true) : (false)}
                            placeholder={props.content[i].label_title}
                            type="text"
                          />

                    </>)}

                  </InputGroup>
                 </FormGroup>
                </Col>
                return content
}
const forContentNumeric =(props,i)=>{
  var content = []
    content=  <Col md={props.content[i].col_field} key={i}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,'numeric')}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />
                  </FormGroup>
                </Col>
                return content

}
const forContentTextArea=(props,i)=>{
  var content = []
  content=<Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >

                    {props.content[i].label_title}
                  </label>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="textarea"
                  />
                </FormGroup>
              </Col>
  return content
}
const forContentSelect=(props,i)=>{
  var content = []
  content= <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  {(props.optionsSelect[props.content[i].name_field]==undefined)?(null):(
                    (props.content[i].multiple_field==1)?(
                        putContentMultiple(props,i)
                      ):(
                        <SelectSearch
                        //style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                        id={props.content[i].name_field+'_'+props.content[i].order_field}
                        value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                        placeholder={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                        options={props.optionsSelect[props.content[i].name_field]}
                        //getOptions={(e,a)=>props.forSearhData(e,a)}
                        search
                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                        onChange={(e,a) => {props.setFieldValueSelect(a.value,a.id,a.isrequired,a.code,a.default,'code')}}
                      />
                    )
                  )}
                {forStyleCursive(props.content[i].cursive_field,props.content[i].name_field+'_'+props.content[i].order_field)}
                </FormGroup>
              </Col>
  return content
}
const putContentMultiple =(data,indx)=>{
  var content = []
  //data.setFieldValue(e.target.value)
  //setValueMultiFunc(e.target.value,e.target.checked)
          content=<Row>
                    {data.optionsSelect[data.content[indx].name_field].map((val,ind)=>{
                      if (val.value=="") {
                         return <Col md={12}>
                            <label className="form-control-label" style={{fontSize: '11px'}}>Sin lote</label>
                          </Col>
                      }else{
                        return <Col md={6}>
                        <label className="form-control-label" style={{fontSize: '11px'}}>{val.value}</label>
                        <input
                        className={"set_value_multi_"+ind}
                        type="checkbox"
                        id={data.content[indx].name_field}
                        onChange={(e) => {data.setFieldValue(setValueMultiFunc(e.target.value,e.target.checked), e.target.id, e.target)}}
                        value={val.value}
                        onLoad={loadValueMultiFunc('set_value_multi_'+ind, (data.field_params[data.content[indx].name_field]==undefined)?(''):(data.field_params[data.content[indx].name_field]), val.value )} 
                        />
                        </Col>
                      }
                    })}
                  </Row>
          return content
}
const forStyleCursive = (data,field)=>{
  if (data==1) {
    jQuery('#'+field).find('.select-search__input').css('font-style','oblique')
    jQuery('#'+field).find('.select-search__option').css('font-style','oblique')
  }
}
const setValueMultiFunc = (value,checked)=>{
  var valores=[]
  jQuery('input[class*="set_value_multi_"]:checked').map((a,e)=>{
    valores[a]=e.value
  })
  return valores.join('|')
}
const loadValueMultiFunc = (classSelect,value, valueIdent)=>{
  //console.log(classSelect,value, valueIdent)
  jQuery('.'+classSelect).prop('checked',false)
  if (value.indexOf(valueIdent)!=-1) {
    jQuery('.'+classSelect).prop('checked',true)
  }
}
const forContentDate=(props,i)=>{
  var content = []
  content=<Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    defaultValue={(props.field_params[props.content[i].name_field]==undefined?(null):(props.field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="date" />
                </FormGroup>
              </Col>  
  return content
}
const forContentTime=(props,i)=>{
  var content = []
  content=<Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    value={(props.field_params[props.content[i].name_field]==undefined?(null):(props.field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="time" />
                </FormGroup>
              </Col>
  return content
}
const forContentFile=(props,i)=>{
  var content = []
  content=<Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                    <Input
                      id={props.content[i].name_field}
                      className="upload_files"
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValueFiles(e.target.files, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      type="file" 
                      multiple
                      style={{display:'none'}} />
                    <button 
                      className="btn" 
                      style={{border: 'solid 1px',marginLeft: '5px',borderRadius: '2px',padding: '5px'}}
                      data-id={props.content[i].name_field} 
                      onClick={(e)=>{
                        jQuery('#'+e.target.getAttribute('data-id')).click()
                      }}>+ Añadir Documentos
                    </button>
                    {contentFilesShow(props.field_params[props.content[i].name_field],props,props.content[i].name_field)}
                </FormGroup>
              </Col>
  return content
}
const forContentGeo=(props,i)=>{
  var content = []
  content=<Col md={props.content[i].col_field} key={i}>
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        {props.content[i].label_title}
                                      </label>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                        style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                                        value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                                        id={props.content[i].name_field}
                                        isrequired={props.content[i].req_field}
                                        onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                                        type="textarea"
                                        />
                                          {props.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <button style={{color: '#002a5c',background: 'transparent',border: '0px'}} onClick={(e)=> {props.geolocacion(e.target)}}>
                                          <span isrequired={props.content[i].req_field} id={props.content[i].name_field} style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </button>
                                        </Col>  
                                      </Row>
                                      </FormGroup>
                                  </Col>
  return content
}
export function PutFormContentProDuplicate(props) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content = []
    if (props.content.length>0) {
      for (var i = 0; i < props.content.length; i++) {
          switch (props.content[i].type_field) {
            case 'text':
              data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field.replace('select_','')]==undefined)
              ?
              (forContentText(props,i))
              :
              ( 
                (props.for_filter_options[props.content[i].name_field.replace('select_','')].valid==null)
                ?
                (forContentText(props,i))
                :
                (
                  (props.for_filter_options[props.content[i].name_field.replace('select_','')].valid)?(forContentText(props,i)):(null)
                )
              )
              break;
            case 'numeric':
              data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field]==undefined)
              ?
              (forContentNumeric(props,i))
              :
              ( 
                (props.for_filter_options[props.content[i].name_field].valid==null)
                ?
                (forContentNumeric(props,i))
                :
                (
                  (props.for_filter_options[props.content[i].name_field].valid)?(forContentNumeric(props,i)):(null)
                )
              )
              break;
            case 'textarea':
              data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field]==undefined)
              ?
              (forContentTextArea(props,i))
              :
              ( 
                (props.for_filter_options[props.content[i].name_field].valid==null)
                ?
                (forContentTextArea(props,i))
                :
                (
                  (props.for_filter_options[props.content[i].name_field].valid)?(forContentTextArea(props,i)):(null)
                )
              )
              break;
            case 'select':
              data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field]==undefined)
              ?
              (forContentSelect(props,i))
              :
              ( 
                (props.for_filter_options[props.content[i].name_field].valid==null)
                ?
                (forContentSelect(props,i))
                :
                (
                  (props.for_filter_options[props.content[i].name_field].valid)?(forContentSelect(props,i)):(null)
                )
              )
              break;
            case 'date':
              data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field]==undefined)
              ?
              (forContentDate(props,i))
              :
              ( 
                (props.for_filter_options[props.content[i].name_field].valid==null)
                ?
                (forContentDate(props,i))
                :
                (
                  (props.for_filter_options[props.content[i].name_field].valid)?(forContentDate(props,i)):(null)
                )
              )
              break;
            case 'time':
              data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field]==undefined)
              ?
              (forContentTime(props,i))
              :
              ( 
                (props.for_filter_options[props.content[i].name_field].valid==null)
                ?
                (forContentTime(props,i))
                :
                (
                  (props.for_filter_options[props.content[i].name_field].valid)?(forContentTime(props,i)):(null)
                )
              )
              break;
            // case 'file':
            //   data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field]==undefined)
            //   ?
            //   (forContentFile(props,i))
            //   :
            //   ( 
            //     (props.for_filter_options[props.content[i].name_field].valid==null)
            //     ?
            //     (forContentFile(props,i))
            //     :
            //     (
            //       (props.for_filter_options[props.content[i].name_field].valid)?(forContentFile(props,i)):(null)
            //     )
            //   )
            //   break;
            case 'georeference':
              data_content[props.content[i].order_field] = (props.for_filter_options[props.content[i].name_field]==undefined)
              ?
              (forContentGeo(props,i))
              :
              ( 
                (props.for_filter_options[props.content[i].name_field].valid==null)
                ?
                (forContentGeo(props,i))
                :
                (
                  (props.for_filter_options[props.content[i].name_field].valid)?(forContentGeo(props,i)):(null)
                )
              )
              break;              
            default:
              
              break;
          }
        }
    }
  return (data_content);
}
const contentFilesShow = (data,props,fields) => {
  var content = []
  if (data!='') {
    if (data!=undefined) {
      if (data.length>0) {
        if (typeof data=='object') {
          data.map((a,b)=>{
           if (a.name!='') {      
             if (a.filed==null) {
               var url_selected = config.URL_PRODUCTION+"/storage/file_documents/"+a.name
               var url_selected_two = "/storage/file_documents/"+a.name
                     content[b]=<Row> <Col md="1"><button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {props.deleteImgState(b,fields,a,url_selected_two)}}>
                     <i className="fas fa-eraser" style={{color:'#00234d'}}></i>
                     </button></Col>
                     <Col md="11"><Button data-url={url_selected} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(a.name).split('/')[1]}</Button></Col></Row>
             }else{
                     content[b]=<Row> <Col md="1"><button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {props.deleteImgState(b,fields,a,url_selected_two)}}>
                     <i className="fas fa-eraser" style={{color:'#00234d'}}></i>
                     </button></Col>
                     <Col md="11"><label className="form-control-label" htmlFor="example3cols2Input">{a.name}</label></Col></Row>
             }
           }
          })
        }
       }
    }

  }
  return content
}

export function PutFormContentFinalDuplicate(props) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content = []
    if (props.content.length>0) {
      for (var i = 0; i < props.content.length; i++) {
          switch (props.content[i].type_field) {
            case 'text':
               data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field.replace('select_','')]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                  <FormGroup style={{margin:'0px'}}>
                  
                    {(props.content[i].type_field_include=='select_include')?(<>
                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field.replace('select_','')]==undefined)?(''):(props.field_params[props.content[i].name_field.replace('select_','')])}</span>
                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                      </>):(
                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                      )}
                  </FormGroup>
                </Col>
              break;
            case 'numeric':
               data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                  <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                  </FormGroup>
                </Col>
              break;
            case 'textarea':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>    
              break;
            case 'select':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>    
              break;
            case 'date':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>    
              break;
            case 'time':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>
              break;
            case 'file':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                <Form>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">
                    {/*{contentFilesShow(props.field_params[props.content[i].name_field],props,props.content[i].name_field)}*/}
                    {(props.field_params[props.content[i].name_field]==null) ? "Sin archivo": (<Button data-url={config.URL_PRODUCTION+"/storage/file_documents/"+props.field_params[props.content[i].name_field]} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(props.field_params[props.content[i].name_field]).split('/')[1]}</Button>)}
                    </span>
                </Form>
              </Col>
              break;
            case 'georeference':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Georeferencia
                                      </label>
                                    <FormGroup style={{margin:'0px'}}>
                                      <Row>
                                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span> 
                                      </Row>
                                      </FormGroup>
                                  </Col>              
              break;              
            default:
              
              break;
          }
        }
    }
  return (data_content);
}