export const CodigoAranM = [
 {
  "Codigo": "010011000090",
  "Titulo": "SECCIÓN I - ANIMALES VIVOS Y PRODUCTOS DEL REINO ANIMAL"
 },
 {
  "Codigo": "010021000090",
  "Titulo": "CAPÍTULO 1 - ANIMALES VIVOS"
 },
 {
  "Codigo": "010100000080",
  "Titulo": "Caballos, asnos, mulos y burdéganos, vivos"
 },
 {
  "Codigo": "010121000010",
  "Titulo": "- Caballos"
 },
 {
  "Codigo": "010121000080",
  "Titulo": "-- Reproductores de raza pura"
 },
 {
  "Codigo": "010129000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010129100080",
  "Titulo": "--- Que se destinen al matadero"
 },
 {
  "Codigo": "010129900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010130000080",
  "Titulo": "- Asnos"
 },
 {
  "Codigo": "010190000080",
  "Titulo": "- Los demás"
 },
 {
  "Codigo": "010200000080",
  "Titulo": "Animales vivos de la especie bovina"
 },
 {
  "Codigo": "010221000010",
  "Titulo": "- Bovinos domésticos"
 },
 {
  "Codigo": "010221000080",
  "Titulo": "-- Reproductores de raza pura"
 },
 {
  "Codigo": "010221100080",
  "Titulo": "--- Terneras (que no hayan parido nunca)"
 },
 {
  "Codigo": "010221300080",
  "Titulo": "--- Vacas"
 },
 {
  "Codigo": "010221900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010229000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010229050080",
  "Titulo": "--- De los subgéneros Bibos o Poephagus"
 },
 {
  "Codigo": "010229100010",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010229100080",
  "Titulo": "---- De peso inferior o igual a 80 kg"
 },
 {
  "Codigo": "010229210010",
  "Titulo": "---- De peso superior a 80 kg pero inferior o igual a 160 kg"
 },
 {
  "Codigo": "010229210080",
  "Titulo": "----- Que se destinen al matadero"
 },
 {
  "Codigo": "010229290080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "010229410010",
  "Titulo": "---- De peso superior a 160 kg pero inferior o igual a 300 kg"
 },
 {
  "Codigo": "010229410080",
  "Titulo": "----- Que se destinen al matadero"
 },
 {
  "Codigo": "010229490080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "010229510010",
  "Titulo": "---- De peso superior a 300 kg"
 },
 {
  "Codigo": "010229510020",
  "Titulo": "----- Terneras (que no hayan parido nunca)"
 },
 {
  "Codigo": "010229510080",
  "Titulo": "------ Que se destinen al matadero"
 },
 {
  "Codigo": "010229590080",
  "Titulo": "------ Los demás"
 },
 {
  "Codigo": "010229610010",
  "Titulo": "----- Vacas"
 },
 {
  "Codigo": "010229610080",
  "Titulo": "------ Que se destinen al matadero"
 },
 {
  "Codigo": "010229690080",
  "Titulo": "------ Las demás"
 },
 {
  "Codigo": "010229910010",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "010229910080",
  "Titulo": "------ Que se destinen al matadero"
 },
 {
  "Codigo": "010229990080",
  "Titulo": "------ Los demás"
 },
 {
  "Codigo": "010231000010",
  "Titulo": "- Búfalos"
 },
 {
  "Codigo": "010231000080",
  "Titulo": "-- Reproductores de raza pura"
 },
 {
  "Codigo": "010239000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010239100080",
  "Titulo": "--- De las especies domésticas"
 },
 {
  "Codigo": "010239900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010290000080",
  "Titulo": "- Los demás"
 },
 {
  "Codigo": "010290200080",
  "Titulo": "-- Reproductores de raza pura"
 },
 {
  "Codigo": "010290910010",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010290910080",
  "Titulo": "--- De las especies domésticas"
 },
 {
  "Codigo": "010290990080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010300000080",
  "Titulo": "Animales vivos de la especie porcina"
 },
 {
  "Codigo": "010310000080",
  "Titulo": "- Reproductores de raza pura"
 },
 {
  "Codigo": "010391000010",
  "Titulo": "- Los demás"
 },
 {
  "Codigo": "010391000080",
  "Titulo": "-- De peso inferior a 50 kg"
 },
 {
  "Codigo": "010391100080",
  "Titulo": "--- De las especies domésticas"
 },
 {
  "Codigo": "010391900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010392000080",
  "Titulo": "-- De peso superior o igual a 50 kg"
 },
 {
  "Codigo": "010392110010",
  "Titulo": "--- De las especies domésticas"
 },
 {
  "Codigo": "010392110080",
  "Titulo": "---- Cerdas que hayan parido por lo menos una vez y con un peso superior o igual a 160 kg"
 },
 {
  "Codigo": "010392190080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "010392900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010400000080",
  "Titulo": "Animales vivos de las especies ovina o caprina"
 },
 {
  "Codigo": "010410000080",
  "Titulo": "- De la especie ovina"
 },
 {
  "Codigo": "010410100080",
  "Titulo": "-- Reproductores de raza pura"
 },
 {
  "Codigo": "010410300010",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010410300080",
  "Titulo": "--- Corderos (que no tengan más de un año)"
 },
 {
  "Codigo": "010410800080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010420000080",
  "Titulo": "- De la especie caprina"
 },
 {
  "Codigo": "010420100080",
  "Titulo": "-- Reproductores de raza pura"
 },
 {
  "Codigo": "010420900080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010500000080",
  "Titulo": "Gallos, gallinas, patos, gansos, pavos (gallipavos) y pintadas, de las especies domésticas, vivos"
 },
 {
  "Codigo": "010511000010",
  "Titulo": "- De peso inferior o igual a 185 g"
 },
 {
  "Codigo": "010511000080",
  "Titulo": "-- Aves de la especie Gallus domesticus"
 },
 {
  "Codigo": "010511110010",
  "Titulo": "--- Pollitos hembras de selección y de multiplicación"
 },
 {
  "Codigo": "010511110080",
  "Titulo": "---- Razas ponedoras"
 },
 {
  "Codigo": "010511190080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "010511910010",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010511910080",
  "Titulo": "---- Razas ponedoras"
 },
 {
  "Codigo": "010511990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "010512000080",
  "Titulo": "-- Pavos (gallipavos)"
 },
 {
  "Codigo": "010513000080",
  "Titulo": "-- Patos"
 },
 {
  "Codigo": "010514000080",
  "Titulo": "-- Gansos"
 },
 {
  "Codigo": "010515000080",
  "Titulo": "-- Pintadas"
 },
 {
  "Codigo": "010594000010",
  "Titulo": "- Los demás"
 },
 {
  "Codigo": "010594000080",
  "Titulo": "-- Aves de la especie Gallus domesticus"
 },
 {
  "Codigo": "010599000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010599100080",
  "Titulo": "--- Patos"
 },
 {
  "Codigo": "010599200080",
  "Titulo": "--- Gansos"
 },
 {
  "Codigo": "010599300080",
  "Titulo": "--- Pavos (gallipavos)"
 },
 {
  "Codigo": "010599500080",
  "Titulo": "--- Pintadas"
 },
 {
  "Codigo": "010600000080",
  "Titulo": "Los demás animales vivos"
 },
 {
  "Codigo": "010611000010",
  "Titulo": "- Mamíferos"
 },
 {
  "Codigo": "010611000080",
  "Titulo": "-- Primates"
 },
 {
  "Codigo": "010612000080",
  "Titulo": "-- Ballenas, delfines y marsopas (mamíferos del orden Cetacea); manatíes y dugones o dugongos (mamíferos del orden Sirenia); otarios y focas, leones marinos y morsas (mamíferos del suborden Pinnipedia)"
 },
 {
  "Codigo": "010613000080",
  "Titulo": "-- Camellos y demás camélidos (Camelidae)"
 },
 {
  "Codigo": "010614000080",
  "Titulo": "-- Conejos y liebres"
 },
 {
  "Codigo": "010614100080",
  "Titulo": "--- Conejos domésticos"
 },
 {
  "Codigo": "010614900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010619000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010620000080",
  "Titulo": "- Reptiles (incluidas las serpientes y tortugas de mar)"
 },
 {
  "Codigo": "010631000010",
  "Titulo": "- Aves"
 },
 {
  "Codigo": "010631000080",
  "Titulo": "-- Aves de rapiña"
 },
 {
  "Codigo": "010632000080",
  "Titulo": "-- Psitaciformes (incluidos los loros, guacamayos, cacatúas y demás papagayos)"
 },
 {
  "Codigo": "010633000080",
  "Titulo": "-- Avestruces; emúes (Dromaius novaehollandiae)"
 },
 {
  "Codigo": "010639000080",
  "Titulo": "-- Las demás"
 },
 {
  "Codigo": "010639100080",
  "Titulo": "--- Palomas"
 },
 {
  "Codigo": "010639800080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "010641000010",
  "Titulo": "- Insectos"
 },
 {
  "Codigo": "010641000080",
  "Titulo": "-- Abejas"
 },
 {
  "Codigo": "010649000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "010690000080",
  "Titulo": "- Los demás"
 },
 {
  "Codigo": "020021000090",
  "Titulo": "CAPÍTULO 2 - CARNE Y DESPOJOS COMESTIBLES"
 },
 {
  "Codigo": "020100000080",
  "Titulo": "Carne de animales de la especie bovina, fresca o refrigerada"
 },
 {
  "Codigo": "020110000080",
  "Titulo": "- En canales o medias canales"
 },
 {
  "Codigo": "020120000080",
  "Titulo": "- Los demás cortes (trozos) sin deshuesar"
 },
 {
  "Codigo": "020120200080",
  "Titulo": "-- Cuartos llamados «compensados»"
 },
 {
  "Codigo": "020120300080",
  "Titulo": "-- Cuartos delanteros, unidos o separados"
 },
 {
  "Codigo": "020120500080",
  "Titulo": "-- Cuartos traseros, unidos o separados"
 },
 {
  "Codigo": "020120900080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020130000080",
  "Titulo": "- Deshuesada"
 },
 {
  "Codigo": "020200000080",
  "Titulo": "Carne de animales de la especie bovina, congelada"
 },
 {
  "Codigo": "020210000080",
  "Titulo": "- En canales o medias canales"
 },
 {
  "Codigo": "020220000080",
  "Titulo": "- Los demás cortes (trozos) sin deshuesar"
 },
 {
  "Codigo": "020220100080",
  "Titulo": "-- Cuartos llamados «compensados»"
 },
 {
  "Codigo": "020220300080",
  "Titulo": "-- Cuartos delanteros unidos o separados"
 },
 {
  "Codigo": "020220500080",
  "Titulo": "-- Cuartos traseros unidos o separados"
 },
 {
  "Codigo": "020220900080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020230000080",
  "Titulo": "- Deshuesada"
 },
 {
  "Codigo": "020230100080",
  "Titulo": "-- Cuartos delanteros enteros o cortados en cinco trozos como máximo, presentándose cada cuarto delantero en un solo bloque de congelación, cuartos llamados «compensados» presentados en dos bloques de congelación que contengan, uno, el cuarto delantero completo o cortado en cinco trozos como máximo y, el otro, el cuarto trasero en un solo trozo (excepto el del solomillo)"
 },
 {
  "Codigo": "020230500080",
  "Titulo": "-- Cortes de cuartos delanteros y cortes de pecho, llamados «australianos»"
 },
 {
  "Codigo": "020230900080",
  "Titulo": "-- Las demás"
 },
 {
  "Codigo": "020300000080",
  "Titulo": "Carne de animales de la especie porcina, fresca, refrigerada o congelada"
 },
 {
  "Codigo": "020311000010",
  "Titulo": "- Fresca o refrigerada"
 },
 {
  "Codigo": "020311000080",
  "Titulo": "-- En canales o medias canales"
 },
 {
  "Codigo": "020311100080",
  "Titulo": "--- De animales de la especie porcina doméstica"
 },
 {
  "Codigo": "020311900080",
  "Titulo": "--- Las demás"
 },
 {
  "Codigo": "020312000080",
  "Titulo": "-- Piernas, paletas, y sus trozos, sin deshuesar"
 },
 {
  "Codigo": "020312110010",
  "Titulo": "--- De animales de la especie porcina doméstica"
 },
 {
  "Codigo": "020312110080",
  "Titulo": "---- Piernas y trozos de pierna"
 },
 {
  "Codigo": "020312190080",
  "Titulo": "---- Paletas y trozos de paleta"
 },
 {
  "Codigo": "020312900080",
  "Titulo": "--- Las demás"
 },
 {
  "Codigo": "020319000080",
  "Titulo": "-- Las demás"
 },
 {
  "Codigo": "020319110010",
  "Titulo": "--- De animales de la especie porcina doméstica"
 },
 {
  "Codigo": "020319110080",
  "Titulo": "---- Partes delanteras y trozos de partes delanteras"
 },
 {
  "Codigo": "020319130080",
  "Titulo": "---- Chuleteros y trozos de chuletero"
 },
 {
  "Codigo": "020319150080",
  "Titulo": "---- Panceta y trozos de panceta"
 },
 {
  "Codigo": "020319550010",
  "Titulo": "---- Las demás"
 },
 {
  "Codigo": "020319550080",
  "Titulo": "----- Deshuesadas"
 },
 {
  "Codigo": "020319590080",
  "Titulo": "----- Las demás"
 },
 {
  "Codigo": "020319900080",
  "Titulo": "--- Las demás"
 },
 {
  "Codigo": "020321000010",
  "Titulo": "- Congelada"
 },
 {
  "Codigo": "020321000080",
  "Titulo": "-- En canales o medias canales"
 },
 {
  "Codigo": "020321100080",
  "Titulo": "--- De animales de la especie porcina doméstica"
 },
 {
  "Codigo": "020321900080",
  "Titulo": "--- Las demás"
 },
 {
  "Codigo": "020322000080",
  "Titulo": "-- Piernas, paletas, y sus trozos, sin deshuesar"
 },
 {
  "Codigo": "020322110010",
  "Titulo": "--- De animales de la especie porcina doméstica"
 },
 {
  "Codigo": "020322110080",
  "Titulo": "---- Piernas y trozos de pierna"
 },
 {
  "Codigo": "020322190080",
  "Titulo": "---- Paletas y trozos de paleta"
 },
 {
  "Codigo": "020322900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "020329000080",
  "Titulo": "-- Las demás"
 },
 {
  "Codigo": "020329110010",
  "Titulo": "--- De animales de la especie porcina doméstica"
 },
 {
  "Codigo": "020329110080",
  "Titulo": "---- Partes delanteras y trozos de partes delanteras"
 },
 {
  "Codigo": "020329130080",
  "Titulo": "---- Chuleteros y trozos de chuletero"
 },
 {
  "Codigo": "020329150080",
  "Titulo": "---- Panceta y trozos de panceta"
 },
 {
  "Codigo": "020329550010",
  "Titulo": "---- Las demás"
 },
 {
  "Codigo": "020329550080",
  "Titulo": "----- Deshuesadas"
 },
 {
  "Codigo": "020329590080",
  "Titulo": "----- Las demás"
 },
 {
  "Codigo": "020329900080",
  "Titulo": "--- Las demás"
 },
 {
  "Codigo": "020400000080",
  "Titulo": "Carne de animales de las especies ovina o caprina, fresca, refrigerada o congelada"
 },
 {
  "Codigo": "020410000080",
  "Titulo": "- Canales o medias canales de cordero, frescas o refrigeradas"
 },
 {
  "Codigo": "020421000010",
  "Titulo": "- Las demás carnes de animales de la especie ovina, frescas o refrigeradas"
 },
 {
  "Codigo": "020421000080",
  "Titulo": "-- En canales o medias canales"
 },
 {
  "Codigo": "020422000080",
  "Titulo": "-- Los demás cortes (trozos) sin deshuesar"
 },
 {
  "Codigo": "020422100080",
  "Titulo": "--- Parte anterior de la canal o cuarto delantero"
 },
 {
  "Codigo": "020422300080",
  "Titulo": "--- Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "Codigo": "020422500080",
  "Titulo": "--- Parte trasera de la canal o cuarto trasero"
 },
 {
  "Codigo": "020422900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "020423000080",
  "Titulo": "-- Deshuesadas"
 },
 {
  "Codigo": "020430000080",
  "Titulo": "- Canales o medias canales de cordero, congeladas"
 },
 {
  "Codigo": "020441000010",
  "Titulo": "- Las demás carnes de animales de la especie ovina, congeladas"
 },
 {
  "Codigo": "020441000080",
  "Titulo": "-- En canales o medias canales"
 },
 {
  "Codigo": "020442000080",
  "Titulo": "-- Los demás cortes (trozos) sin deshuesar"
 },
 {
  "Codigo": "020442100080",
  "Titulo": "--- Parte anterior de la canal o cuarto delantero"
 },
 {
  "Codigo": "020442300080",
  "Titulo": "--- Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "Codigo": "020442500080",
  "Titulo": "--- Parte trasera de la canal o cuarto trasero"
 },
 {
  "Codigo": "020442900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "020443000080",
  "Titulo": "-- Deshuesadas"
 },
 {
  "Codigo": "020443100080",
  "Titulo": "--- De cordero"
 },
 {
  "Codigo": "020443900080",
  "Titulo": "--- Las demás"
 },
 {
  "Codigo": "020450000080",
  "Titulo": "- Carne de animales de la especie caprina"
 },
 {
  "Codigo": "020450110010",
  "Titulo": "-- Fresca o refrigerada"
 },
 {
  "Codigo": "020450110080",
  "Titulo": "--- En canales o medias canales"
 },
 {
  "Codigo": "020450130080",
  "Titulo": "--- Parte anterior de la canal o cuarto delantero"
 },
 {
  "Codigo": "020450150080",
  "Titulo": "--- Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "Codigo": "020450190080",
  "Titulo": "--- Parte trasera de la canal o cuarto trasero"
 },
 {
  "Codigo": "020450310010",
  "Titulo": "--- Las demás"
 },
 {
  "Codigo": "020450310080",
  "Titulo": "---- Cortes (trozos) sin deshuesar"
 },
 {
  "Codigo": "020450390080",
  "Titulo": "---- Cortes (trozos) deshuesados"
 },
 {
  "Codigo": "020450510010",
  "Titulo": "-- Congelada"
 },
 {
  "Codigo": "020450510080",
  "Titulo": "--- En canales o medias canales"
 },
 {
  "Codigo": "020450530080",
  "Titulo": "--- Parte anterior de la canal o cuarto delantero"
 },
 {
  "Codigo": "020450550080",
  "Titulo": "--- Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "Codigo": "020450590080",
  "Titulo": "--- Parte trasera de la canal o cuarto trasero"
 },
 {
  "Codigo": "020450710010",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "020450710080",
  "Titulo": "---- Cortes (trozos) sin deshuesar"
 },
 {
  "Codigo": "020450790080",
  "Titulo": "---- Cortes (trozos) deshuesados"
 },
 {
  "Codigo": "020500000080",
  "Titulo": "Carne de animales de las especies caballar, asnal o mular, fresca, refrigerada o congelada"
 },
 {
  "Codigo": "020500200080",
  "Titulo": "- Fresca o refrigerada"
 },
 {
  "Codigo": "020500800080",
  "Titulo": "- Congelada"
 },
 {
  "Codigo": "020600000080",
  "Titulo": "Despojos comestibles de animales de las especies bovina, porcina, ovina, caprina, caballar, asnal o mular, frescos, refrigerados o congelados"
 },
 {
  "Codigo": "020610000080",
  "Titulo": "- De la especie bovina, frescos o refrigerados"
 },
 {
  "Codigo": "020610100080",
  "Titulo": "-- Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "Codigo": "020610950010",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020610950080",
  "Titulo": "--- Entraña gruesa y entraña fina"
 },
 {
  "Codigo": "020610980080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "020621000010",
  "Titulo": "- De la especie bovina, congelados"
 },
 {
  "Codigo": "020621000080",
  "Titulo": "-- Lenguas"
 },
 {
  "Codigo": "020622000080",
  "Titulo": "-- Hígados"
 },
 {
  "Codigo": "020629000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020629100080",
  "Titulo": "--- Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "Codigo": "020629910010",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "020629910080",
  "Titulo": "---- Entraña gruesa y entraña fina"
 },
 {
  "Codigo": "020629990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020630000080",
  "Titulo": "- De la especie porcina, frescos o refrigerados"
 },
 {
  "Codigo": "020641000010",
  "Titulo": "- De la especie porcina, congelados"
 },
 {
  "Codigo": "020641000080",
  "Titulo": "-- Hígados"
 },
 {
  "Codigo": "020649000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020680000080",
  "Titulo": "- Los demás, frescos o refrigerados"
 },
 {
  "Codigo": "020680100080",
  "Titulo": "-- Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "Codigo": "020680910010",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020680910080",
  "Titulo": "--- De las especies caballar, asnal y mular"
 },
 {
  "Codigo": "020680990080",
  "Titulo": "--- De las especies ovina y caprina"
 },
 {
  "Codigo": "020690000080",
  "Titulo": "- Los demás, congelados"
 },
 {
  "Codigo": "020690100080",
  "Titulo": "-- Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "Codigo": "020690910010",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020690910080",
  "Titulo": "--- De las especies caballar, asnal y mular"
 },
 {
  "Codigo": "020690990080",
  "Titulo": "--- De las especies ovina y caprina"
 },
 {
  "Codigo": "020700000080",
  "Titulo": "Carne y despojos comestibles, de aves de la partida 0105, frescos, refrigerados o congelados"
 },
 {
  "Codigo": "020711000010",
  "Titulo": "- De aves de la especie Gallus domesticus"
 },
 {
  "Codigo": "020711000080",
  "Titulo": "-- Sin trocear, frescos o refrigerados"
 },
 {
  "Codigo": "020711100080",
  "Titulo": "--- Desplumados y destripados, con la cabeza y las patas, llamados «pollos 83 %»"
 },
 {
  "Codigo": "020711300080",
  "Titulo": "--- Desplumados, eviscerados, sin cabeza ni patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pollos 70 %»"
 },
 {
  "Codigo": "020711900080",
  "Titulo": "--- Desplumados, eviscerados, sin cabeza, patas, cuello, corazón, hígado ni molleja, llamados «pollos 65 %», o presentados de otro modo"
 },
 {
  "Codigo": "020712000080",
  "Titulo": "-- Sin trocear, congelados"
 },
 {
  "Codigo": "020712100080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pollos 70 %»"
 },
 {
  "Codigo": "020712900080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas y sin el cuello, el corazón, el hígado ni la molleja, llamados «pollos 65 %», o presentados de otro modo"
 },
 {
  "Codigo": "020713000080",
  "Titulo": "-- Trozos y despojos, frescos o refrigerados"
 },
 {
  "Codigo": "020713100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020713100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020713200010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020713200080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020713300080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020713400080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020713500080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020713600080",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020713700080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020713910010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020713910080",
  "Titulo": "---- Hígados"
 },
 {
  "Codigo": "020713990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020714000080",
  "Titulo": "-- Trozos y despojos, congelados"
 },
 {
  "Codigo": "020714100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020714100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020714200010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020714200080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020714300080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020714400080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020714500080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020714600080",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020714700080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020714910010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020714910080",
  "Titulo": "---- Hígados"
 },
 {
  "Codigo": "020714990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020724000010",
  "Titulo": "- De pavo (gallipavo)"
 },
 {
  "Codigo": "020724000080",
  "Titulo": "-- Sin trocear, frescos o refrigerados"
 },
 {
  "Codigo": "020724100080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pavos 80 %»"
 },
 {
  "Codigo": "020724900080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza, el cuello, las patas, el corazón, el hígado ni la molleja, llamados «pavos 73 %», o presentados de otro modo"
 },
 {
  "Codigo": "020725000080",
  "Titulo": "-- Sin trocear, congelados"
 },
 {
  "Codigo": "020725100080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pavos 80 %»"
 },
 {
  "Codigo": "020725900080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza, el cuello, las patas, el corazón, el hígado ni la molleja, llamados «pavos 73 %», o presentados de otro modo"
 },
 {
  "Codigo": "020726000080",
  "Titulo": "-- Trozos y despojos, frescos o refrigerados"
 },
 {
  "Codigo": "020726100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020726100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020726200010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020726200080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020726300080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020726400080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020726500080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020726600010",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020726600080",
  "Titulo": "------ Muslos y trozos de muslo"
 },
 {
  "Codigo": "020726700080",
  "Titulo": "------ Los demás"
 },
 {
  "Codigo": "020726800080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020726910010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020726910080",
  "Titulo": "---- Hígados"
 },
 {
  "Codigo": "020726990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020727000080",
  "Titulo": "-- Trozos y despojos, congelados"
 },
 {
  "Codigo": "020727100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020727100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020727200010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020727200080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020727300080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020727400080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020727500080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020727600010",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020727600080",
  "Titulo": "------ Muslos y trozos de muslo"
 },
 {
  "Codigo": "020727700080",
  "Titulo": "------ Los demás"
 },
 {
  "Codigo": "020727800080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020727910010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020727910080",
  "Titulo": "---- Hígados"
 },
 {
  "Codigo": "020727990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020741000010",
  "Titulo": "- De pato"
 },
 {
  "Codigo": "020741000080",
  "Titulo": "-- Sin trocear, frescos o refrigerados"
 },
 {
  "Codigo": "020741200080",
  "Titulo": "--- Desplumados, sangrados, sin intestinos pero sin eviscerar, con la cabeza y las patas, llamados «patos 85 %»"
 },
 {
  "Codigo": "020741300080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas, con el cuello, el corazón, el hígado y la molleja, llamados «patos 70 %»"
 },
 {
  "Codigo": "020741800080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas y sin el cuello, el corazón, el hígado ni la molleja, llamados «patos 63 %», o presentados de otro modo"
 },
 {
  "Codigo": "020742000080",
  "Titulo": "-- Sin trocear, congelados"
 },
 {
  "Codigo": "020742300080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «patos 70 %»"
 },
 {
  "Codigo": "020742800080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza, las patas, el cuello, el hígado ni la molleja, llamados «patos 63 %», o presentados de otro modo"
 },
 {
  "Codigo": "020743000080",
  "Titulo": "-- Hígados grasos, frescos o refrigerados"
 },
 {
  "Codigo": "020744000080",
  "Titulo": "-- Los demás, frescos o refrigerados"
 },
 {
  "Codigo": "020744100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020744100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020744210010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020744210080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020744310080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020744410080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020744510080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020744610080",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020744710080",
  "Titulo": "----- Patos semideshuesados"
 },
 {
  "Codigo": "020744810080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020744910010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020744910080",
  "Titulo": "---- Hígados (excepto los hígados grasos de pato)"
 },
 {
  "Codigo": "020744990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020745000080",
  "Titulo": "-- Los demás, congelados"
 },
 {
  "Codigo": "020745100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020745100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020745210010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020745210080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020745310080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020745410080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020745510080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020745610080",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020745710080",
  "Titulo": "----- Patos semideshuesados"
 },
 {
  "Codigo": "020745810080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020745930010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020745930020",
  "Titulo": "---- Hígados"
 },
 {
  "Codigo": "020745930080",
  "Titulo": "----- Hígados grasos de pato"
 },
 {
  "Codigo": "020745950080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020745990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020751000010",
  "Titulo": "- De ganso"
 },
 {
  "Codigo": "020751000080",
  "Titulo": "-- Sin trocear, frescos o refrigerados"
 },
 {
  "Codigo": "020751100080",
  "Titulo": "--- Desplumados, sangrados, sin eviscerar, con la cabeza y las patas, llamados «gansos 82 %»"
 },
 {
  "Codigo": "020751900080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas, con el corazón y la molleja o sin ellos, llamados «gansos 75 %», o presentados de otro modo"
 },
 {
  "Codigo": "020752000080",
  "Titulo": "-- Sin trocear, congelados"
 },
 {
  "Codigo": "020752100080",
  "Titulo": "--- Desplumados, sangrados, sin eviscerar, con la cabeza y las patas, llamados «gansos 82 %»"
 },
 {
  "Codigo": "020752900080",
  "Titulo": "--- Desplumados, eviscerados, sin la cabeza ni las patas, con el corazón y la molleja o sin ellos, llamados «gansos 75 %», o presentados de otro modo"
 },
 {
  "Codigo": "020753000080",
  "Titulo": "-- Hígados grasos, frescos o refrigerados"
 },
 {
  "Codigo": "020754000080",
  "Titulo": "-- Los demás, frescos o refrigerados"
 },
 {
  "Codigo": "020754100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020754100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020754210010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020754210080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020754310080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020754410080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020754510080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020754610080",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020754710080",
  "Titulo": "----- Gansos semideshuesados"
 },
 {
  "Codigo": "020754810080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020754910010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020754910080",
  "Titulo": "---- Hígados (excepto los hígados grasos de ganso)"
 },
 {
  "Codigo": "020754990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020755000080",
  "Titulo": "-- Los demás, congelados"
 },
 {
  "Codigo": "020755100010",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020755100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020755210010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020755210080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020755310080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020755410080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020755510080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020755610080",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020755710080",
  "Titulo": "----- Gansos semideshuesados"
 },
 {
  "Codigo": "020755810080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020755930010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020755930020",
  "Titulo": "---- Hígados"
 },
 {
  "Codigo": "020755930080",
  "Titulo": "----- Hígados grasos de ganso"
 },
 {
  "Codigo": "020755950080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020755990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020760000080",
  "Titulo": "- De pintada"
 },
 {
  "Codigo": "020760050080",
  "Titulo": "-- Sin trocear, frescos, refrigerados o congelados"
 },
 {
  "Codigo": "020760100010",
  "Titulo": "-- Los demás, frescos, refrigerados o congelados"
 },
 {
  "Codigo": "020760100020",
  "Titulo": "--- Trozos"
 },
 {
  "Codigo": "020760100080",
  "Titulo": "---- Deshuesados"
 },
 {
  "Codigo": "020760210010",
  "Titulo": "---- Sin deshuesar"
 },
 {
  "Codigo": "020760210080",
  "Titulo": "----- Mitades o cuartos"
 },
 {
  "Codigo": "020760310080",
  "Titulo": "----- Alas enteras, incluso sin la punta"
 },
 {
  "Codigo": "020760410080",
  "Titulo": "----- Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "Codigo": "020760510080",
  "Titulo": "----- Pechugas y trozos de pechuga"
 },
 {
  "Codigo": "020760610080",
  "Titulo": "----- Muslos, contramuslos, y sus trozos"
 },
 {
  "Codigo": "020760810080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "020760910010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "020760910080",
  "Titulo": "---- Hígados"
 },
 {
  "Codigo": "020760990080",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "020800000080",
  "Titulo": "Las demás carnes y despojos comestibles, frescos, refrigerados o congelados"
 },
 {
  "Codigo": "020810000080",
  "Titulo": "- De conejo o liebre"
 },
 {
  "Codigo": "020810100080",
  "Titulo": "-- De conejos domésticos"
 },
 {
  "Codigo": "020810900080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020830000080",
  "Titulo": "- De primates"
 },
 {
  "Codigo": "020840000080",
  "Titulo": "- De ballenas, delfines y marsopas (mamíferos del orden Cetacea); de manatíes y dugones o dugongos (mamíferos del orden Sirenia); de otarios y focas, leones marinos y morsas (mamíferos del suborden Pinnipedia)"
 },
 {
  "Codigo": "020840100080",
  "Titulo": "-- Carne de ballenas"
 },
 {
  "Codigo": "020840200080",
  "Titulo": "-- Carne de focas"
 },
 {
  "Codigo": "020840800080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020850000080",
  "Titulo": "- De reptiles (incluidas las serpientes y tortugas de mar)"
 },
 {
  "Codigo": "020860000080",
  "Titulo": "- De camellos y demás camélidos (Camelidae)"
 },
 {
  "Codigo": "020890000080",
  "Titulo": "- Los demás"
 },
 {
  "Codigo": "020890100080",
  "Titulo": "-- De palomas domésticas"
 },
 {
  "Codigo": "020890300080",
  "Titulo": "-- De caza (excepto de conejo o liebre)"
 },
 {
  "Codigo": "020890600080",
  "Titulo": "-- De renos"
 },
 {
  "Codigo": "020890700080",
  "Titulo": "-- Ancas (patas) de rana"
 },
 {
  "Codigo": "020890980080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "020900000080",
  "Titulo": "Tocino sin partes magras y grasa de cerdo o de ave sin fundir ni extraer de otro modo, frescos, refrigerados, congelados, salados o en salmuera, secos o ahumados"
 },
 {
  "Codigo": "020910000080",
  "Titulo": "- De cerdo"
 },
 {
  "Codigo": "020910110010",
  "Titulo": "-- Tocino"
 },
 {
  "Codigo": "020910110080",
  "Titulo": "--- Fresco, refrigerado, congelado, salado o en salmuera"
 },
 {
  "Codigo": "020910190080",
  "Titulo": "--- Seco o ahumado"
 },
 {
  "Codigo": "020910900080",
  "Titulo": "-- Grasa de cerdo"
 },
 {
  "Codigo": "020990000080",
  "Titulo": "- Los demás"
 },
 {
  "Codigo": "021000000080",
  "Titulo": "Carne y despojos comestibles, salados o en salmuera, secos o ahumados; harina y polvo comestibles, de carne o de despojos"
 },
 {
  "Codigo": "021011000010",
  "Titulo": "- Carne de la especie porcina"
 },
 {
  "Codigo": "021011000080",
  "Titulo": "-- Jamones, paletas, y sus trozos, sin deshuesar"
 },
 {
  "Codigo": "021011110010",
  "Titulo": "--- De la especie porcina doméstica"
 },
 {
  "Codigo": "021011110020",
  "Titulo": "---- Salados o en salmuera"
 },
 {
  "Codigo": "021011110080",
  "Titulo": "----- Jamones y trozos de jamón"
 },
 {
  "Codigo": "021011190080",
  "Titulo": "----- Paletas y trozos de paleta"
 },
 {
  "Codigo": "021011310010",
  "Titulo": "---- Secos o ahumados"
 },
 {
  "Codigo": "021011310080",
  "Titulo": "----- Jamones y trozos de jamón"
 },
 {
  "Codigo": "021011390080",
  "Titulo": "----- Paletas y trozos de paleta"
 },
 {
  "Codigo": "021011900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "021012000080",
  "Titulo": "-- Tocino entreverado de panza (panceta) y sus trozos"
 },
 {
  "Codigo": "021012110010",
  "Titulo": "--- De la especie porcina doméstica"
 },
 {
  "Codigo": "021012110080",
  "Titulo": "---- Salados o en salmuera"
 },
 {
  "Codigo": "021012190080",
  "Titulo": "---- Secos o ahumados"
 },
 {
  "Codigo": "021012900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "021019000080",
  "Titulo": "-- Las demás"
 },
 {
  "Codigo": "021019100010",
  "Titulo": "--- De la especie porcina doméstica"
 },
 {
  "Codigo": "021019100020",
  "Titulo": "---- Salados o en salmuera"
 },
 {
  "Codigo": "021019100080",
  "Titulo": "----- Medias canales de tipo «bacon» o tres cuartos delanteros"
 },
 {
  "Codigo": "021019200080",
  "Titulo": "----- Tres cuartos traseros o centros"
 },
 {
  "Codigo": "021019300080",
  "Titulo": "----- Partes delanteras y trozos de partes delanteras"
 },
 {
  "Codigo": "021019400080",
  "Titulo": "----- Chuleteros y trozos de chuletero"
 },
 {
  "Codigo": "021019500080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "021019600010",
  "Titulo": "---- Secos o ahumados"
 },
 {
  "Codigo": "021019600080",
  "Titulo": "----- Partes delanteras y trozos de partes delanteras"
 },
 {
  "Codigo": "021019700080",
  "Titulo": "----- Chuleteros y partes de chuletero"
 },
 {
  "Codigo": "021019810010",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "021019810080",
  "Titulo": "------ Deshuesados"
 },
 {
  "Codigo": "021019890080",
  "Titulo": "------ Los demás"
 },
 {
  "Codigo": "021019900080",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "021020000080",
  "Titulo": "- Carne de la especie bovina"
 },
 {
  "Codigo": "021020100080",
  "Titulo": "-- Sin deshuesar"
 },
 {
  "Codigo": "021020900080",
  "Titulo": "-- Deshuesada"
 },
 {
  "Codigo": "021091000010",
  "Titulo": "- Los demás, incluidos la harina y polvo comestibles, de carne o de despojos"
 },
 {
  "Codigo": "021091000080",
  "Titulo": "-- De primates"
 },
 {
  "Codigo": "021092000080",
  "Titulo": "-- De ballenas, delfines y marsopas (mamíferos del orden Cetacea); de manatíes y dugones o dugongos (mamíferos del orden Sirenia); de otarios y focas, leones marinos y morsas (mamíferos del suborden Pinnipedia)"
 },
 {
  "Codigo": "021092100080",
  "Titulo": "--- De ballenas, delfines y marsopas (mamíferos del orden Cetáceos); de manatíes y dugones o dugongos (mamíferos del orden Sirenios)"
 },
 {
  "Codigo": "021092910010",
  "Titulo": "--- Los demás"
 },
 {
  "Codigo": "021092910080",
  "Titulo": "---- Carne"
 },
 {
  "Codigo": "021092920080",
  "Titulo": "---- Despojos"
 },
 {
  "Codigo": "021092990080",
  "Titulo": "---- Harina y polvo comestibles, de carne o de despojos"
 },
 {
  "Codigo": "021093000080",
  "Titulo": "-- De reptiles (incluidas las serpientes y tortugas de mar)"
 },
 {
  "Codigo": "021099000080",
  "Titulo": "-- Los demás"
 },
 {
  "Codigo": "021099100010",
  "Titulo": "--- Carne"
 },
 {
  "Codigo": "021099100080",
  "Titulo": "---- De caballo, salada, en salmuera o seca"
 },
 {
  "Codigo": "021099210010",
  "Titulo": "---- De las especies ovina y caprina"
 },
 {
  "Codigo": "021099210080",
  "Titulo": "----- Sin deshuesar"
 },
 {
  "Codigo": "021099290080",
  "Titulo": "----- Deshuesada"
 },
 {
  "Codigo": "021099310080",
  "Titulo": "---- De renos"
 },
 {
  "Codigo": "021099390080",
  "Titulo": "---- Las demás"
 },
 {
  "Codigo": "021099410010",
  "Titulo": "--- Despojos"
 },
 {
  "Codigo": "021099410020",
  "Titulo": "---- De la especie porcina doméstica"
 },
 {
  "Codigo": "021099410080",
  "Titulo": "----- Hígados"
 },
 {
  "Codigo": "021099490080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "021099510010",
  "Titulo": "---- De la especie bovina"
 },
 {
  "Codigo": "021099510080",
  "Titulo": "----- Entraña gruesa y entraña fina"
 },
 {
  "Codigo": "021099590080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "021099710010",
  "Titulo": "---- Los demás"
 },
 {
  "Codigo": "021099710020",
  "Titulo": "----- Hígados de aves"
 },
 {
  "Codigo": "021099710080",
  "Titulo": "------ Hígados grasos de ganso o de pato, salados o en salmuera"
 },
 {
  "Codigo": "021099790080",
  "Titulo": "------ Los demás"
 },
 {
  "Codigo": "021099850080",
  "Titulo": "----- Los demás"
 },
 {
  "Codigo": "021099900080",
  "Titulo": "--- Harina y polvo comestibles, de carne o de despojos"
 }
]