import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production_kdes, data_fertilizer_kdes, delete_fertilizer_kdes, delete_file_prod_kdes } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {getContent, PutFormContent, forNumeric, PutFormContentProKdes} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";

export default class ModalFertilizer extends React.Component {
  state={
    notificationAlertRef: React.createRef(),
    field_params:[],
    field_content:[],
    content_motive:{},
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],    
    dataStatesS:0,
    content_render:null,
    get_content:[],
    get_first_info:'',
    btnd:false,
    userTypeH:1,
    for_filters:{},
    openModalMotive:false,
    changeTableMotive:null
  }
  async componentDidMount(){
    // var getData = await main_content(this.props.tableCall)
    // this.putContentFunct(getData)
  }
  putContentFunct=(getData)=>{
    this.putContentFilter(getData)
    getData.map((a,b)=>{
      if (a.include_info==1) {
        this.setState({get_first_info:a.name_field})
      }
    })
    this.setState({get_content: getData})    
    this.setState({field_content_log: getData})
    this.setState({field_content: getData})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({userTypeH:jsondata.userType})
  }
inArray=(needle, haystack)=>{
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}
putContentFilter=(propsFilter)=>{
var get_select_data_filter = {}
   var get_data = propsFilter.filter(a=> a.link_field!=null && a.link_field!='')
   get_data.map((val,ind)=>{
    var get_data_filter = val.link_field.split('|')
    var get_option_data = val.value_option.split('|')
    get_data_filter.map((valTwo,indxTwo)=>{
      valTwo.split('*').map((valThree,indxThree)=>{
        get_select_data_filter[valThree]= {option:get_option_data[indxTwo],field:val.name_field,valid:false}
      })
    })
   })
   this.setState({for_filters:get_select_data_filter})
        //console.log(get_select_data_filter)
}
  initData = async (id_set,verify)=>{
    this.setState({
         sendOpenModal:verify,
       })
    var data_select = []

        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);

        var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
         
        var dataTrazabilidadvf = await data_fertilizer_kdes(id_set,this.props.tableCall,JSON.stringify(getContent(this.state.field_content_log).f_data),gerParam, null, jsondata.type_traza)
        //console.log(dataTrazabilidadvf)
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {
      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
      }
        if (prevProps.sendInfoProps !== this.props.sendInfoProps) {
          if (this.props.sendInfoProps.data_content!=undefined) {
            //console.log(Object.values(this.props.sendInfoProps.data_content))
            this.putContentFunct(Object.values(this.props.sendInfoProps.data_content))
          }
        }
      } 
    setDataCampos = (data)=>{
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      this.state.field_content.map((a,b)=>{
        //console.log(data[a.name_field],a.name_field)
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
         if (a.type_field=='date') {
          if (data[a.name_field]==null) {
           this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
          }
         }
         if (a.type_field=='time') {
          if (data[a.name_field]==null) {
             this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
         }
          if (a.type_field=='file') {
              if (data[a.name_field]==null) {
                var multi_fil = [{name:'',filed:null}]
                this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
              }else{
                if (typeof data[a.name_field]=='string') {
                 if (data[a.name_field].split('|').length>0) {
                  var multi_fil = []
                   data[a.name_field].split('|').map((a,b)=>{
                     multi_fil[b] = {name:a,filed:null}
                   })
                    var item = []
                    item = [...multi_fil]
                  //field_params_params[a.name_field]=item
                  this.setState({field_params: {...this.state.field_params, [a.name_field]:item }})
                 }
                }
              }
          }
          if (a.list_default=='motive_change') {
            if (a.id==undefined) {
              if (data[a.name_field]!=null) {
                    this.setState({field_params: {...this.state.field_params, [a.name_field]:1+'_lote_'+data[a.name_field] }})
              }
            }else{
              if (data['change_'+a.name_field]!=null) {
                this.setState({field_params: {...this.state.field_params, [a.name_field]:data['change_'+a.name_field] }})
              }
            }
            //if (a.id!=undefined) {
            //}
          }
        if (a.type_field=='select') {
          this.for_filter_select_show(data[a.name_field],a.name_field)
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })
    }
    cleanData = () =>{
          this.setState({idT:'noind'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        if (a.type_field=='file') {
              var multi_fil = [{name:'',filed:null}]
              this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
          if (a.list_default=='motive_change') {
              this.setState({field_params: {...this.state.field_params, [a.name_field]:1+'_lote_none' }})
          }
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })          
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async () =>{
      this.setState({btnd:true})
      this.setState({mess:['Enviando...']})
      if (this.state.field_params_required==undefined) {
        this.setState({btnd:false})
        return
      }
    var field_data = Object.entries(this.state.field_params)
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {        
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {         
          if (field_data[b][0]==a[0]) {
           if ((a[1]==1 || a[1]=='1') && field_data[b][1]=='') {
            counter++
            data_mess[counter] = 'El campo '+a[0]+' es requerido';
           }
          }
        }
    })
    if (data_mess.length>0) {
      this.setState({mess: data_mess.reverse() })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = new URLSearchParams(window.location.search).get("code");
      // if (jsondata.userType==1) {
      //   gerParam = new URLSearchParams(window.location.search).get("code");
      // }
      this.setState({btnd:true})
      const var_vf = await create_production_kdes(jsondata._id,field_data,field_data_name,gerParam,this.props.tableCall,this.state.idT,data_for_file,this.props.titleCall,jsondata.userType);
      if (var_vf==null) {
        this.setState({btnd:false})
        return
      }
          if (var_vf.message=="exito") {
            this.setState({mess:''})
            this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
            await this.initData(null,false)
            this.setState({btnd:false})
            this.props.refreshData()
          }
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
 geolocacion = (options) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(e,options)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (posicion,optcon)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }

    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
      this.for_filter_select_show(value,field)
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
    for_filter_select_show = (value,field)=>{
      var getData={}
      var getDataValid = this.state.get_content.filter(val=>val.name_field==field)
      if (getDataValid[0].link_field==null || getDataValid[0].link_field=='') {
        return
      }

      var getDataParam = this.state.field_params
      Object.entries(this.state.for_filters).map((a,b)=>{
        if (a[1].option==value) {
          a[1].valid=true
        }else{
          if (a[1].field==field) {
            a[1].valid=false
            getDataParam[a[0]]=null
          }
        }
        getData[a[0]] = a[1]
      })
      this.setState({field_params: getDataParam })
      this.setState({for_filters:getData})
    }
     setFieldValue = (value, field, isrequired) =>{
      if (field!="") {
       this.setState({field_params: {...this.state.field_params, [field]:value }})
      }
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            //this.setState({field_params: {...this.state.field_params, [a]:value }})
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
     setFieldValueFiles = (value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       //console.log(item,field)
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod_kdes(this.state.idT,this.props.tableCall,field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
            this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
             //var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
          }
         }
      this.setState({field_params: item })
       }
    }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
openModalForChangeMotive=(table,changeOpen)=>{
  //this.setState({content_motive: { ['change_'+table]:'', ['change_description_'+table]:'' } })
  this.setState({openModalMotive:changeOpen,changeTableMotive: table })
}
saveInfoMotive=()=>{
  // var getTable = this.state.field_params
  // getTable['change_'+this.state.changeTableMotive]=1+'_lote_'+getTable[this.state.changeTableMotive]
  // this.setState({field_params:getTable})
  this.notify("warning", "Información", 'Para aplicar los cambios guarde este registro.');
  this.openModalForChangeMotive(null,false)
}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
deleteFer = async (id_set) => {
  await this.initData(id_set,false)
   var userdata = localStorage.getItem('user');
   var jsondata = JSON.parse(userdata);
   var gerParam = new URLSearchParams(window.location.search).get("code");
   if (window.confirm("Deseas eliminar este registro?")) {
    var field_data = Object.entries(this.state.field_params)
    var field_data_name = Object.entries(this.state.field_params_title)
    var dataReturn = await delete_fertilizer_kdes(id_set,jsondata._id,field_data,field_data_name,gerParam,this.props.tableCall,'Datos Fertilizantes / Agroquímicos')
            if (dataReturn=="exito") {
            this.setState({mess:'Se eliminó exitosamente.'})
            this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
 }
     listCountryCode = CountrysData.sort(this.listaArray)    
  render(){
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>

  <Modal isOpen={this.state.openModalMotive} size="xl">
    <ModalBody>
       <CardHeader>
            <Row>
              <Col md={11}>
                <h3 className="mb-0">Número de lote</h3>
              </Col>
              <Col md={1}>
              <a href="#" onClick={()=> {this.openModalForChangeMotive(null,false)} }>
                <h3 class="mb-0">X</h3>
              </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <Row>
            <Col md={6}>
              <Input
              //value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
              // onChange={(e) => { 
              //   this.setState
              // }}
              id={this.state.changeTableMotive}
              onChange={(e) => {
                this.setFieldValue(e.target.value, e.target.id, e.target)
              }}
              placeholder="Nuevo valor"
              type="text"
              />
            </Col>
            <Col md={6}>
              <Input
              id={'change_description_'+this.state.changeTableMotive}
              onChange={(e) => {
                this.setFieldValue(e.target.value, e.target.id, e.target)
              }}
              placeholder="Motivo"
              type="textarea"
              />
            </Col>
          </Row>
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button"
                //disabled={this.state.btnd}
                onClick={()=>{this.saveInfoMotive()}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </ModalBody>
        <ModalFooter>

          <Button onClick={()=> {this.openModalForChangeMotive(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

  <Modal isOpen={this.state.sendOpenModal} size="xl">
    <ModalBody>
       <CardHeader>
                      <Row>
            <Col md={11}>
              <h3 className="mb-0">{this.props.titleCall}</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {this.initData(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
            <PutFormContentProKdes
            content={this.state.field_content}
            field_params={this.state.field_params}
            optionsSelect={this.state.optionsSelect}
            setFieldValueFiles={this.setFieldValueFiles}
            deleteImgState={this.deleteImgState}
            setFieldValue={this.setFieldValue}
            setFieldValueSelect={this.setFieldValueSelect}
            forSearhData={this.forSearhData}
            geolocacion={this.geolocacion}
            for_filter_options={this.state.for_filters}
            openModalForChangeMotive={this.openModalForChangeMotive}

             />
            </Row>
                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}            
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button"
                disabled={this.state.btnd}
                onClick={()=>{this.saveInfo()}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </ModalBody>
        <ModalFooter>

          <Button onClick={()=> {this.initData(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

       <Row>
        <Card className="mb-1" md="4" style={{backgroundColor:'transparent',boxShadow: 'none'}}>
        <CardHeader className="p-0" style={{borderRadius: '20px',backgroundColor:'transparent',border: 'none'}}>
        <Row>
          <Col md={6}>
              <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ this.initData(null,true) }}>
                <h3 className="mb-0" style={{color:'white'}}>
                 Ingresar Datos {this.props.titleCall} +
                </h3>
                {/*<span className="btn-inner--text">+</span>*/}
              </Button>
              {/*<GuideMessage  />*/}
          </Col>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataFertilizer.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                       <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {Object.entries(value).map((a,b)=>{
                                              if (a[0]==this.state.get_first_info) {
                                                return a[1]
                                              }
                                             })}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => this.initData(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                                          {(this.state.userTypeH==1)?(
                                             <Col md="6">
                                             <Button 
                                               className="btn-icon" 
                                               type="button"
                                               onClick={() => this.deleteFer(value.id)}>
                                                 <span className="btn-inner--icon mr-1">
                                                   <i className="fas fa-trash" />
                                                 </span>
                                               </Button>
                                             </Col>
                                            ):(

                                              (this.state.userTypeH==5)?(
                                               <Col md="6">
                                               <Button 
                                                 className="btn-icon" 
                                                 type="button"
                                                 onClick={() => this.deleteFer(value.id)}>
                                                   <span className="btn-inner--icon mr-1">
                                                     <i className="fas fa-trash" />
                                                   </span>
                                                 </Button>
                                               </Col>
                                                ):(null)

                                            )}


                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

