import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production_kdes, data_fertilizer_kdes, delete_fertilizer_kdes, delete_file_prod_kdes } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {getContent, PutFormContent, forNumeric, PutFormContentProKdes} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";
export default class ModalFertilizer extends React.Component {
  static contextType = UserContext
  state={
    notificationAlertRef: React.createRef(),
    field_params:[],
    field_content:[],
    content_motive:{},
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],    
    dataStatesS:0,
    content_render:null,
    get_content:[],
    get_first_info:'',
    get_first_info_column:'',
    btnd:false,
    userTypeH:1,
    for_filters:{},
    openModalMotive:false,
    changeTableMotive:null,
    globalFilter:'',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    visibleRight:false
  }
  async componentDidMount(){
    // var getData = await main_content(this.props.tableCall)
    // this.putContentFunct(getData)
    this.putContentFunct(Object.values(this.props.sendInfoProps.data_content))
  }
  putContentFunct=(getData)=>{
    this.putContentFilter(getData)
    getData.map((a,b)=>{
      if (a.include_info==1) {
        this.setState({get_first_info:a.name_field, get_first_info_column:a.label_title})
      }
    })
    this.setState({get_content: getData})    
    this.setState({field_content_log: getData})
    this.setState({field_content: getData})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({userTypeH:jsondata.userType})
  }
inArray=(needle, haystack)=>{
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}
putContentFilter=(propsFilter)=>{
var get_select_data_filter = {}
   var get_data = propsFilter.filter(a=> a.link_field!=null && a.link_field!='')
   get_data.map((val,ind)=>{
    var get_data_filter = val.link_field.split('|')
    var get_option_data = val.value_option.split('|')
    get_data_filter.map((valTwo,indxTwo)=>{
      valTwo.split('*').map((valThree,indxThree)=>{
        get_select_data_filter[valThree]= {option:get_option_data[indxTwo],field:val.name_field,valid:false}
      })
    })
   })
   this.setState({for_filters:get_select_data_filter})
        //console.log(get_select_data_filter)
}
  initData = async (id_set,verify)=>{
    this.setState({
         sendOpenModal:verify,
       })
    var data_select = []

        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);

        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataTrazabilidadvf = null
        if (id_set==null) {
          dataTrazabilidadvf = await data_fertilizer_kdes(id_set,this.props.tableCall,JSON.stringify(getContent(this.state.field_content_log).f_data),gerParam, null, jsondata.type_traza)
        }else{
          dataTrazabilidadvf=id_set
        }
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {
      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
      }
        if (prevProps.sendInfoProps !== this.props.sendInfoProps) {
          if (this.props.sendInfoProps.data_content!=undefined) {
            //console.log(Object.values(this.props.sendInfoProps.data_content))
            //this.putContentFunct(Object.values(this.props.sendInfoProps.data_content))
          }
        }
      } 
    setDataCampos = (data)=>{
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      //this.setState({field_params:data})
      var field_params_title={}
      var field_params_params={}
      var field_params_required={}

      this.state.field_content.map((a,b)=>{
        // if (data[a.name_field]!=null) {
        // }
          field_params_params[a.name_field]=data[a.name_field]
          field_params_title[a.name_field]=a.label_title
        //console.log(data[a.name_field],a.name_field)
        //this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        //this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        field_params_required[a.name_field]=a.req_field
         if (a.type_field=='date') {
          if (data[a.name_field]==null) {
           field_params_params[a.name_field]=completeDate
           //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
          }
         }
         if (a.type_field=='time') {
          if (data[a.name_field]==null) {
             field_params_params[a.name_field]=timeString
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
         }
          if (a.type_field=='file') {
              if (data[a.name_field]==null) {
                var multi_fil = [{name:'',filed:null}]
                field_params_params[a.name_field]=multi_fil
                //this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
              }else{
                if (typeof data[a.name_field]=='string') {
                 if (data[a.name_field].split('|').length>0) {
                  var multi_fil = []
                   data[a.name_field].split('|').map((a,b)=>{
                     multi_fil[b] = {name:a,filed:null}
                   })
                    var item = []
                    item = [...multi_fil]
                  //field_params_params[a.name_field]=item
                  field_params_params[a.name_field]=item
                  //this.setState({field_params: {...this.state.field_params, [a.name_field]:item }})
                 }
                }
              }
          }
          if (a.list_default=='motive_change') {
            if (a.id==undefined) {
              if (data[a.name_field]!=null) {
                let textS = /^1_lote_/; 
                let ns = textS.test(data[a.name_field]);
                if (ns) {
                  field_params_params[a.name_field]=data[a.name_field]
                }else{
                    field_params_params[a.name_field]=1+'_lote_'+data[a.name_field]
                }
                    //this.setState({field_params: {...this.state.field_params, [a.name_field]:1+'_lote_'+data[a.name_field] }})
              }
            }else{
              if (data['change_'+a.name_field]!=null) {
                //console.log(a.name_field,'change_'+a.name_field)
                let textRS = /(^[a-zA-Z]*\-)|(1_lote_[a-zA-Z]*\-)/;
                var getNumbL = data['change_'+a.name_field].split(textRS)
                field_params_params['change_'+a.name_field]=data['change_'+a.name_field]
                field_params_params[a.name_field]=getNumbL[getNumbL.length-1]
                //this.setState({field_params: {...this.state.field_params, [a.name_field]:data['change_'+a.name_field] }})
              }
            }
            //if (a.id!=undefined) {
            //}
          }
        if (a.type_field=='select') {
          this.for_filter_select_show(data[a.name_field],a.name_field)
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })
      this.setState({field_params: field_params_params })
      this.setState({field_params_title: field_params_title})
      this.setState({field_params_required: field_params_required})
    }
    cleanData = () =>{
          this.setState({idT:'noind'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];

          var field_params_title={}
          var field_params_required={}
          var field_params_params={}
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        field_params_params[a.name_field] = ''
        if (a.type_field=='date') {
          field_params_params[a.name_field] = completeDate
        }
        if (a.type_field=='time') {
          field_params_params[a.name_field] = timeString
        }
        if (a.type_field=='file') {
              var multi_fil = [{name:'',filed:null}]
              field_params_params[a.name_field] = multi_fil
        }
        field_params_title[a.name_field]=a.label_title
        field_params_required[a.name_field] = a.req_field

          if (a.list_default=='motive_change') {
            if (a.id==undefined) {
                field_params_params[a.name_field]=1+'_lote_none'
            }
          }
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })          
        this.setState({field_params_title: field_params_title })
        this.setState({field_params: field_params_params })
        this.setState({field_params_required: field_params_required })
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async () =>{
      this.setState({btnd:true})
      this.setState({mess:['Enviando...']})
      if (this.state.field_params_required==undefined) {
        this.setState({btnd:false})
        return
      }
    var field_data = Object.entries(this.state.field_params)
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {        
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {         
          if (field_data[b][0]==a[0]) {
           if ((a[1]==1 || a[1]=='1') && field_data[b][1]=='') {
            counter++
            data_mess[counter] = 'El campo '+a[0]+' es requerido';
           }
          }
        }
    })
    if (data_mess.length>0) {
      this.setState({mess: data_mess.reverse() })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = new URLSearchParams(window.location.search).get("code");
      // if (jsondata.userType==1) {
      //   gerParam = new URLSearchParams(window.location.search).get("code");
      // }
      this.setState({btnd:true})

      const var_vf = await create_production_kdes(jsondata._id,field_data,field_data_name,gerParam,this.props.tableCall,this.state.idT,data_for_file,this.props.titleCall,jsondata.userType,'FCTK-');
      if (var_vf==null) {
        this.setState({btnd:false})
        return
      }
      if (gerParam==null) {
        this.props.history.push( '/admin/process-kdes' )
      }
          if (var_vf.message=="exito") {
            this.setState({mess:''})
            //this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
            this.context.setToastMess('success', "¡Registrado exitosamente!", 'Se guardo exitosamente.')
            await this.initData(null,false)
            this.setState({visibleRight:false})
            this.setState({btnd:false})
            this.props.refreshData()
          }
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
 geolocacion = (options) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(e,options)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (posicion,optcon)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }

    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
      this.for_filter_select_show(value,field)
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
    for_filter_select_show = (value,field)=>{
      var getData={}
      var getDataValid = this.state.get_content.filter(val=>val.name_field==field)
      if (getDataValid[0].link_field==null || getDataValid[0].link_field=='') {
        return
      }

      var getDataParam = this.state.field_params
      Object.entries(this.state.for_filters).map((a,b)=>{
        if (a[1].option==value) {
          a[1].valid=true
        }else{
          if (a[1].field==field) {
            a[1].valid=false
            getDataParam[a[0]]=null
          }
        }
        getData[a[0]] = a[1]
      })
      this.setState({field_params: getDataParam })
      this.setState({for_filters:getData})
    }
     setFieldValue = (value, field, isrequired) =>{
        var data_field = this.state.field_params
      if (field!="") {
        data_field[field]=value
       //this.setState({field_params: {...this.state.field_params, [field]:value }})
      }
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a=='motive_change') {
              var gerParam = new URLSearchParams(window.location.search).get("code");
              if (gerParam==null) {
               var data_field = this.state.field_params
               data_field['change_'+field]=1+'_lote_'+value
              }
          }
          if (a!='upf') {
            //this.setState({field_params: {...this.state.field_params, [a]:value }})
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
      this.setState({field_params: data_field })
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
     setFieldValueFiles = (value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       //console.log(item,field)
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod_kdes(this.state.idT,this.props.tableCall,field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
            this.context.setToastMess("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.')
             //var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
          }
         }
      this.setState({field_params: item })
       }
    }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
openModalForChangeMotive=(table,changeOpen)=>{
  //this.setState({content_motive: { ['change_'+table]:'', ['change_description_'+table]:'' } })
  this.setState({openModalMotive:changeOpen,changeTableMotive: table })
}
saveInfoMotive=()=>{
  // var getTable = this.state.field_params
  // getTable['change_'+this.state.changeTableMotive]=1+'_lote_'+getTable[this.state.changeTableMotive]
  // this.setState({field_params:getTable})
  this.context.setToastMess("warning", "Información", 'Para aplicar los cambios guarde este registro.')
  this.openModalForChangeMotive(null,false)
}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
deleteFer = async (id_set) => {
  await this.initData(id_set,false)
   var userdata = localStorage.getItem('user');
   var jsondata = JSON.parse(userdata);
   var gerParam = new URLSearchParams(window.location.search).get("code");
   if (window.confirm("Deseas eliminar este registro?")) {
    var field_data = Object.entries(this.state.field_params)
    var field_data_name = Object.entries(this.state.field_params_title)
    var dataReturn = await delete_fertilizer_kdes(id_set,jsondata._id,field_data,field_data_name,gerParam,this.props.tableCall,'Datos Fertilizantes / Agroquímicos')
            if (dataReturn=="exito") {
            this.setState({mess:'Se eliminó exitosamente.'})
            this.context.setToastMess("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.')
            this.props.refreshData()
          }else{
            this.context.setToastMess("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!')
            this.setState({btnd:false})
          }
  }
 }
     listCountryCode = CountrysData.sort(this.listaArray)    
  render(){
    const header = (
        <div className="flex flex-column flex-md-row md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Gestionar Producción
            </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                type="search" 
                value={this.state.globalFilter}
                //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>

            <Button 
                label="Nueva" 
                icon="pi pi-plus" 
                severity="success" 
                className="btn-fod-green mr-2" 
                onClick={() => {
                  this.setState({visibleRight:true})
                  this.initData(null,true)
                }}
                />
        </div>
    );
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button 
                    icon="pi pi-pencil" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    tooltip="Editar Información" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                    onClick={()=>{
                      this.setState({visibleRight:true})
                      this.initData(rowData,true)
                    }}
                />
            </>
        );
    };
    const actionBodyCreate = (rowData) => {
      const today = new Date(rowData.created_at);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = dd + '/' + mm + '/' + yyyy;
      return formattedToday
    }
  return (
    <>
{/*  <Modal isOpen={this.state.openModalMotive} size="xl">
    <ModalBody>
       <CardHeader>
            <Row>
              <Col md={11}>
                <h3 className="mb-0">Número de lote</h3>
              </Col>
              <Col md={1}>
              <a href="#" onClick={()=> {this.openModalForChangeMotive(null,false)} }>
                <h3 class="mb-0">X</h3>
              </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <Row>
            <Col md={6}>
              <Input
              id={this.state.changeTableMotive}
              onChange={(e) => {
                this.setFieldValue(e.target.value, e.target.id, e.target)
              }}
              placeholder="Nuevo valor"
              type="text"
              />
            </Col>
            <Col md={6}>
              <Input
              id={'change_description_'+this.state.changeTableMotive}
              onChange={(e) => {
                this.setFieldValue(e.target.value, e.target.id, e.target)
              }}
              placeholder="Motivo"
              type="textarea"
              />
            </Col>
          </Row>
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button"
                onClick={()=>{this.saveInfoMotive()}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </ModalBody>
        <ModalFooter>

          <Button onClick={()=> {this.openModalForChangeMotive(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
*/}

      <SidebarCustom 
          onVisible={this.state.visibleRight} 
          onHide={() => this.setState({visibleRight:false})}
          onWidth={ 650 }
          position="right" 
          title={this.props.titleCall}>
        <div>
            <div className="p-fluid mt-5">
              <div className="formgrid grid">
                <PutFormContentProKdes
                content={this.state.field_content}
                field_params={this.state.field_params}
                optionsSelect={this.state.optionsSelect}
                setFieldValueFiles={this.setFieldValueFiles}
                deleteImgState={this.deleteImgState}
                setFieldValue={this.setFieldValue}
                setFieldValueSelect={this.setFieldValueSelect}
                forSearhData={this.forSearhData}
                geolocacion={this.geolocacion}
                for_filter_options={this.state.for_filters}
                openModalForChangeMotive={this.openModalForChangeMotive}
                 />
              </div>
            </div>

            <div className="absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400">
                <div className="flex justify-content-end pt-3 pr-3 pl-3">
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button 
                                label="Cerrar"
                                 severity="secondary" 
                                 onClick={() => {
                                  this.setState({visibleRight:false})
                                  //this.props.hide()
                                }} size="small" />
                        </div>

                        <div className="field col">
                            <Button 
                            disabled={this.state.btnd}
                            onClick={()=>{
                              this.saveInfo()
                            }} label="Guardar" size="small" className='btn-fod-green' />
                        </div>
                    </div>
                </div>
            </div>
                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}
            
        </div>

      </SidebarCustom>

        <Container className="mt-0">
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card card-table">
                    <DataTable
                        value={this.props.sendInfoProps.dataFertilizer}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        {/*<Column field="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>*/}
                        <Column body={actionBodyCreate} header="Fecha" sortable></Column>
                        <Column field={this.state.get_first_info} header={this.state.get_first_info_column} sortable></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
      </Container>
    </>
  );
 }
}

