import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table
} from "reactstrap";


import { NavLink as ComponentLink } from "react-router-dom";

export default class SectionLote extends React.Component {
  state={
    openColapse:[false],
    openColapseChild:[false]
  }
  render(){
    const opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
    const openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
  return (
    <>

    <div id="lote-tab" className="clearfix eael-tab-content-item inactive mt-4" data-title-link="lote-tab">
        <article data-elementor-type="section" data-elementor-id="5420" className="elementor elementor-5420"><div className="elementor-section-wrap">
          <section data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">

              <Container  fluid>
            {(this.props.DataRow.table_lote==null)
              ?
              ('Sin datos')
            :
              this.props.DataRow.table_lote.map((value,index)=>{
              return(
        <Card>
          <CardHeader className="border-0" style={{backgroundColor: 'rgb(157, 14, 13)'}}>
            <Row>
              <Col md="12">
                <h3 className="mb-0" style={{color: 'white'}}>Inventario Lote: {value.lote_numero}</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Animal número</th>
                <th>Fecha registro</th>
                <th>Madre arete (ID)</th>
                <th>Animal arete (ID)</th>
                <th>Fecha nacimiento</th>
                <th>Raza</th>
                <th>Edad</th>
                <th>Sexo</th>
                <th>Peso</th>
              </tr>
            </thead>
            <tbody>
            <TrRegister sendValue={value}/>
            </tbody>
          </Table>
        </Card>
                 )
            })
          }
      </Container>


              </div>
    </section>
          <section data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">

              <Container  fluid>

        <Card>
          <CardHeader className="border-0" style={{backgroundColor: 'rgb(157, 14, 13)'}}>
            <Row>
              <Col md="12">
                <h3 className="mb-0" style={{color: 'white'}}>Animales Eliminados</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Animal número</th>
                <th>Fecha registro</th>
                <th>Animal arete (ID)</th>
                <th>Lote Número</th>
                <th>Peso</th>
                <th>Fecha de eliminación</th>
                <th>Motivo</th>
                <th>Lugar de siniestro</th>
              </tr>
            </thead>
            <tbody>
            {(this.props.DataRow.trazaDataAnimalEliminado==null)
              ?
              ('Sin datos')
            :
              this.props.DataRow.trazaDataAnimalEliminado.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                                      <ComponentLink
                                        className="font-weight-bold"
                                                              to={{
                                          pathname:"/home/meats/details-animal",
                                          search: "?code="+value.codigo+"&id_animal="+value.id+'&lote='+value.type_lote,
                                          state:{
                                            codigoTraza:value.codigo
                                          }
                                          }}
                                        >
                                       Ver animal {value.id}
                                      </ComponentLink>

                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.animal_id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.type_lote}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.peso_inicial}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.fecha_muerte}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.motivo}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {(value.lugar_siniestro=="2")?('Sin lugar'):(value.lugar_siniestro)}
                    </span>
                   </td>
{/*                   <td>
                      <Col md="12">
                        <Input style={{width: '100%',margin: 0}} className="lote_check_send" name="lote_check_send" onClick={()=>{checkSendLote()}} type="checkbox" value={value.id}/>
                      </Col>
                   </td>
                   <td>
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/meats/details-animal",
                        search: "?code="+value.codigo+"&id_animal="+value.id,
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}
                      >
                     Editar
                     </ComponentLink>
                   </td>*/}
                 </tr>)
            })
          }
            </tbody>
          </Table>
        </Card>
      </Container>


              </div>
    </section>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section>
    </div>
    </article>
    </div>
    </>
  );
}
}
export class TrRegister extends React.Component {
  state = {
    animal_id_register:[],
    codigo:[],
    fecha_registro:[],
    madre_arete:[],
    animal_arete:[],
    fecha_nacimiento:[],
    raza:[],
    edad:[],
    sexo:[],
    peso:[],
  }
  async componentDidMount(){
    console.log(this.props.sendValue)
    this.setState({
    animal_id_register: String(this.props.sendValue.animal_register_id).split("*"),
    codigo: String(this.props.sendValue.register_codigo_animal).split("*"),
    fecha_registro: String(this.props.sendValue.fecha_registro).split("*"),
    madre_arete: String(this.props.sendValue.madre_id).split("*"),
    animal_arete: String(this.props.sendValue.animal_id).split("*"),
    fecha_nacimiento: String(this.props.sendValue.fecha_nacimiento).split("*"),
    raza: String(this.props.sendValue.raza).split("*"),
    edad: String(this.props.sendValue.edad).split("*"),
    sexo: String(this.props.sendValue.sexo).split("*"),
    peso: String(this.props.sendValue.peso_inicial).split("*"),
    peso_compare: String(this.props.sendValue.peso_inicial).split("*"),
    })
  }
  eventOnclick = ()=>{
    console.log('hola')
  }
  render(){


    return(
      this.state.animal_id_register.map((value,index)=>{
                  return (<tr>
                                     <td>
                                      <span className="text-muted">
                                      </span>
                                      <ComponentLink
                                        className="font-weight-bold"
                                                              to={{
                                          pathname:"/home/meats/details-animal",
                                          search: "?code="+this.state.codigo[index]+"&id_animal="+value+'&lote='+this.props.sendValue.lote_numero,
                                          state:{
                                            codigoTraza:this.state.codigo[index]
                                          }
                                          }}
                                        >
                                       Ver animal {value}
                                       </ComponentLink>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {(this.state.fecha_registro[index]=="null")?('Sin datos'):(this.state.fecha_registro[index])}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {(this.state.madre_arete[index]=="null")?('Sin datos'):(this.state.madre_arete[index])}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {(this.state.animal_arete[index]=="null")?('Sin datos'):(this.state.animal_arete[index])}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {(this.state.fecha_nacimiento[index]=="null")?('Sin datos'):(this.state.fecha_nacimiento[index])}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {(this.state.raza[index]=="null")?('Sin datos'):(this.state.raza[index])}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {(this.state.edad[index]=="null")?('Sin datos'):(this.state.edad[index])}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {(this.state.sexo[index]=="null")?('Sin datos'):(this.state.sexo[index])}
                                      </span>
                                     </td>
                                     <td style={{padding: 0}}>
                                      <span className="text-muted">
                                       {(this.state.peso[index]=="null")?('Sin datos'):(this.state.peso[index])}
                                      </span>
                                     </td>
                                   </tr>)
                })
      )
  }
}
//export default Elements;
