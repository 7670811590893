export const globalUrlList = [
  "https://ipfs.io/ipfs/:hash",
  "https://dweb.link/ipfs/:hash",
  "https://gateway.ipfs.io/ipfs/:hash",
  "https://ipfs.infura.io/ipfs/:hash",
  "https://infura-ipfs.io/ipfs/:hash",
  "https://ninetailed.ninja/ipfs/:hash",
  "https://ipfs.eternum.io/ipfs/:hash",
  "https://hardbin.com/ipfs/:hash",
  "https://gateway.blocksec.com/ipfs/:hash",
  "https://cloudflare-ipfs.com/ipfs/:hash",
  "https://astyanax.io/ipfs/:hash",
  "https://cf-ipfs.com/ipfs/:hash",
  "https://ipns.co/ipfs/:hash",
  "https://ipfs.mrh.io/ipfs/:hash",
  "https://gateway.originprotocol.com/ipfs/:hash",
  "https://gateway.pinata.cloud/ipfs/:hash",
  "https://ipfs.doolta.com/ipfs/:hash",
  "https://ipfs.sloppyta.co/ipfs/:hash",
  "https://ipfs.busy.org/ipfs/:hash",
  "https://ipfs.greyh.at/ipfs/:hash",
  "https://gateway.serph.network/ipfs/:hash",
  "https://jorropo.net/ipfs/:hash",
  "https://ipfs.fooock.com/ipfs/:hash",
  "https://cdn.cwinfo.net/ipfs/:hash",
  "https://aragon.ventures/ipfs/:hash",
  "https://ipfs-cdn.aragon.ventures/ipfs/:hash",
  "https://permaweb.io/ipfs/:hash",
  "https://ipfs.best-practice.se/ipfs/:hash",
  "https://storjipfs-gateway.com/ipfs/:hash",
  "https://ipfs.runfission.com/ipfs/:hash",
  "https://ipfs.trusti.id/ipfs/:hash",
  "https://ipfs.overpi.com/ipfs/:hash",
  "https://gateway.ipfs.lc/ipfs/:hash",
  "https://ipfs.ink/ipfs/:hash",
  "https://ipfsgateway.makersplace.com/ipfs/:hash",
  "https://gateway.ravenland.org/ipfs/:hash",
  "https://ipfs.funnychain.co/ipfs/:hash",
  "https://ipfs.telos.miami/ipfs/:hash",
  "https://ipfs.mttk.net/ipfs/:hash",
  "https://ipfs.fleek.co/ipfs/:hash",
  "https://ipfs.jbb.one/ipfs/:hash",
  "https://ipfs.yt/ipfs/:hash",
  "https://hashnews.k1ic.com/ipfs/:hash",
  "https://ipfs.vip/ipfs/:hash",
  "https://ipfs.drink.cafe/ipfs/:hash",
  "https://ipfs.azurewebsites.net/ipfs/:hash",
  "https://gw.ipfspin.com/ipfs/:hash",
  "https://ipfs.kavin.rocks/ipfs/:hash",
  "https://ipfs.denarius.io/ipfs/:hash",
  "https://ipfs.mihir.ch/ipfs/:hash",
  "https://crustwebsites.net/ipfs/:hash",
  "https://ipfs0.sjc.cloudsigma.com/ipfs/:hash",
  "http://ipfs.genenetwork.org/ipfs/:hash",
  "https://ipfs.eth.aragon.network/ipfs/:hash",
  "https://ipfs.smartholdem.io/ipfs/:hash",
  "https://ipfs.xoqq.ch/ipfs/:hash",
  "https://natoboram.mynetgear.com/ipfs/:hash",
  "https://video.oneloveipfs.com/ipfs/:hash",
  "http://ipfs.anonymize.com/ipfs/:hash",
  "https://ipfs.taxi/ipfs/:hash",
  "https://ipfs.scalaproject.io/ipfs/:hash",
  "https://search.ipfsgate.com/ipfs/:hash",
  "https://ipfs.decoo.io/ipfs/:hash",
  "https://ivoputzer.xyz/ipfs/:hash",
  "https://alexdav.id/ipfs/:hash",
  "https://ipfs.uploads.nu/ipfs/:hash",
  "https://hub.textile.io/ipfs/:hash",
  "https://ipfs1.pixura.io/ipfs/:hash",
  "https://ravencoinipfs-gateway.com/ipfs/:hash",
  "https://konubinix.eu/ipfs/:hash",
  "https://3cloud.ee/ipfs/:hash",
  "https://ipfs.tubby.cloud/ipfs/:hash",
  "https://ipfs.lain.la/ipfs/:hash",
  "https://ipfs.adatools.io/ipfs/:hash",
  "https://ipfs.kaleido.art/ipfs/:hash",
  "https://ipfs.slang.cx/ipfs/:hash",
  "https://ipfs.arching-kaos.com/ipfs/:hash",
  "https://storry.tv/ipfs/:hash",
  "https://ipfs.kxv.io/ipfs/:hash",
  "https://ipfs.1-2.dev/ipfs/:hash",
  "https://ipfs-nosub.stibarc.com/ipfs/:hash",
  "https://dweb.eu.org/ipfs/:hash",
  "https://permaweb.eu.org/ipfs/:hash",
  "https://ipfs.namebase.io/ipfs/:hash",
  "https://ipfs.tribecap.co/ipfs/:hash",
  "https://ipfs.kinematiks.com/ipfs/:hash",
  "https://c4rex.co/ipfs/:hash",
  "https://ipfs.zod.tv/ipfs/:hash",
  "https://nftstorage.link/ipfs/:hash",
  "https://gravity.jup.io/ipfs/:hash",
  "http://fzdqwfb5ml56oadins5jpuhe6ki6bk33umri35p5kt2tue4fpws5efid.onion/ipfs/:hash",
  "https://tth-ipfs.com/ipfs/:hash",
  "https://ipfs.natoboram.com/ipfs/:hash"
]
