import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import React from "react";
import IndexNavbar from "components/Navbars/NavbarTrace.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import { get_data_row_admin_grain_vt,get_permission,save_signature_mrv,save_signature_production } from "network/ApiAxios";
import SectionNamaG from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionNamaGView";
import SectionNamaGP from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionNamaGPView";
import SectionNamaGM from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionNamaMPView";
import SectionNamaGH from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionNamaGHView";
import SectionGasCo from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionGasCoView";

import SectionFVGCSale from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionFVGCSaleView";

import SectionMVR from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionMVRView";
import SectionDocuments from "views/pages/examples/Production_FV/SectionViewTraceGNMRV/SectionNamaDocumentView";

import { NavLink as ComponentLink } from "react-router-dom";
import {GuideMessage} from "views/pages/components/GuideMessage";

import "./StyleCss.css";
import informacion from "assets/img/icons/Informacion2.png";
import Produccion from "assets/img/icons/Produccion2.png";
import Fitosanitario from "assets/img/icons/Fitosanitario2.png";
import Cosecha from "assets/img/icons/Cosecha2.png";
import SalesImg from "assets/img/icons/growth.png";

import GasesCo from "assets/img/icons/carbon-dioxide.png";
import ImgMVR from "assets/img/icons/mvrimg.png";
import Folder from "assets/img/icons/Folder2.png";
import Check from "assets/img/icons/check.png";
import SignatureCanvas from 'react-signature-canvas';
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';

export default class VistaTraceNGMRV extends React.Component {
	state ={
    notificationAlertRef: React.createRef(),
		statusClass:[],
		statusColor:[],
		allDataTrza:null,
		code_traza:'Cargando...',
		data_user:null,
		data_user_ad:null,
		table_nama_section:[],
		components:SectionNamaG,
    sendOpenModal:false,
    get_data_user:null,
    sigDrawn:false,
    asigSigDrawn:false,
    btndis:false,
    entidad:'',
    name_mvr:'',
    monitor_number:'',
    monitor_date:'',
    init_time:'',
    monitor_last_date:'',
    last_time:'',
    title_end:'',
    dataDocuments:{}
	}
	async componentDidMount(){
		var gerParam = this.props.code;
		if (gerParam==null) {
			this.setState({code_traza:'Sin Trazabilidad'});
		}else{
		this.setNameDB(gerParam)
		}
	}
  componentDidUpdate(){
  }
	configAllDataStatus = (dataRow) => {
//['create_plant_cold','create_plant_information_empaque','create_plant_pre_cold','create_plant_product','create_plant_sample','create_plant_treatment','create_plant_weight_product','fruit_vegetable','production_create','production_fertilizer','transport_cosecha','transport_vehicule_cosecha','commercialization_export','commercialization_market','logistics_transport_shipment','logistics_transport_transporte','create_cosecha'];
if (dataRow==undefined) {
	var data_array_get = []
}else{
	var data_array_get = Object.keys(dataRow)
}
data_array_get.map((a,b)=>{
 			 if (dataRow[a]['data_array'].length==0) {
 			 	this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
 				this.setState({statusColor:{...this.state.statusColor,[a]:'#7a7a7a78'}});
 			 }else{
 			 	  dataRow[a]['data_array'].map((value,index)=>{
				 	if (value.status=='finalizado') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-check-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#58d99978'}});
				 	}
				 	if (value.status=='desarrollo') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#f8d01478'}});
					}
 			 	  })
 			 }
 })

	}
	setNameDB = async(data) =>{
	    var userdata = localStorage.getItem('user');
	    var jsondata = JSON.parse(userdata);
		this.setState({code_traza:data});
		var dataRow = await get_data_row_admin_grain_vt(data,this.props.db_user,'.info_producer_vf_gc')
    var get_data_saved = dataRow.data.data_mvr.filter((a,b)=> a.monitor_type=='VA' && a.mvr_id==jsondata._id)
    if (get_data_saved[0]==undefined) {
      this.setState({statusColor:{...this.state.statusColor,['validate_tab']:'#f8d01400'}});
      this.setState({title_end:'Validar Gestión de cultivos'})
    }else{    
      if (get_data_saved[0].nro_autorization!=null || get_data_saved[0].nro_autorization!='' || get_data_saved[0].nro_autorization!='none') {
        this.setState({statusColor:{...this.state.statusColor,['validate_tab']:'#58d99978'}});
        this.setState({title_end:'Click para ver'})
      }else{
        this.setState({statusColor:{...this.state.statusColor,['validate_tab']:'#f8d01400'}});
        this.setState({title_end:'Validar Gestión de cultivos'})
      }
    }
		this.configAllDataStatus(dataRow.data.data_traza_row)

	    var dataPermission = await get_permission(jsondata._id)
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
	    this.setState({data_user_ad:jsondata.type_traza})
      this.setState({get_data_user:dataPermission[0]})
      if (dataPermission[0].asigned_signature==1) {
        this.setState({asigSigDrawn:true})
      }else{
        this.setState({asigSigDrawn:false})
      }
		this.setState({allDataTrza: dataRow.data.data_traza_row})
    this.setState({allDataSatelite: dataRow.data.satelite})
		this.setState({allDataMVR: dataRow.data.data_mvr})
		this.setState({data_user:dataRow.data.data_user});
    this.setState({dataDocuments:dataRow.data.documents})
		if (dataRow.data=='Sin trazabilidad') {
			return
		}
        jQuery(jQuery('.clk_change_color')[0]).css('background-color','#2d5980')
      if (this.props.location.state!=undefined && this.props.location.state.table_params!=undefined) {
        this.putTableTitleSection(this.props.location.state.table_params,this.props.location.state.title_params,0)
      }else{
        if (this.props.table_params!=undefined) {
          this.putTableTitleSection(this.props.table_params,this.props.title_params,1)
        }
      }
	}
  putTableTitleSection=(table_params,title_params,type_change)=>{
        if (table_params=='info_producer_vf_gc' || 
            table_params=='info_crops_vf_gc' || 
            table_params=='info_prev_vf_gc' || 
            table_params=='info_financing_vf_gc') {
          jQuery('#infprod_c').click()
          jQuery('#'+table_params).click()
        }
        if (table_params=='production_land_preparation_vf_gc' ||
          table_params=='production_irrigation_vf_gc' ||
          table_params=='production_seed_vf_gc' ||
          table_params=='production_fertilizer_vf_gc' ||
          table_params=='production_sowing_vf_gc' ||
          table_params=='production_sowing_transplant_vf_gc' ||
          table_params=='production_producer_monitoring_vf_gc'){
          jQuery(jQuery('.clk_change_color')[1]).css('background-color','#2d5980')
          jQuery('#produccion_c').click()
          jQuery('#'+table_params).click()
        }
        if (table_params=='hading_fert_agro_vf_gc' ||
        table_params=='hading_info_weeds_vf_gc' ||
        table_params=='hading_inventory_weeds_vf_gc' ||
        table_params=='hading_info_plague_vf_gc' ||
        table_params=='hading_inventory_plague_vf_gc' ||
        table_params=='hading_info_diseases_vf_gc'){
          jQuery(jQuery('.clk_change_color')[2]).css('background-color','#2d5980')
          jQuery('#hadinf_c').click()
          jQuery('#'+table_params).click()
        }
        if (table_params=='harvest_info_harvest_vf_gc' ||
        table_params=='harvest_info_machinery_vf_gc' ||
        table_params=='harvest_info_transport_vf_gc' ||
        table_params=='harvest_info_pos_harvest_vf_gc'){
          jQuery(jQuery('.clk_change_color')[3]).css('background-color','#2d5980')
          jQuery('#harvinf_c').click()
          jQuery('#'+table_params).click()
        }
        if (table_params=='commercialization_market_vf_gc' ||
        table_params=='commercialization_export_vf_gc'){
          jQuery(jQuery('.clk_change_color')[3]).css('background-color','#2d5980')
          jQuery('#sale_vf').click()
          jQuery('#'+table_params).click()
        }
        if (type_change==0) {      
          var get_title = title_params?.split('-')
          if (get_title!=undefined) {
            var title_trim = jQuery.trim(get_title[get_title.length-1].replaceAll(' ','').replaceAll('*',''))
            jQuery('.'+title_trim).click()
          }
        }
  }
		pushSection = (components,element) => {
      if (components=='signature_validate') {
        var dataPermission = this.state.get_data_user
        var date = new Date()
        var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
        var completeDateBefore = completeDateAfter.split('/')
        var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
        var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        this.setState({
          monitor_type:jsondata.type_traza,
          entidad:dataPermission.empresa,
          name_mvr:dataPermission.name,
          monitor_number:'',
          monitor_date:completeDate,
          init_time:timeString,
          monitor_last_date:completeDate,
          last_time:timeString,
          signature:'',
          nro_autorization:'',
         })
        this.setState({sendOpenModal:!this.state.sendOpenModal})
      }else{
        jQuery('.clk_change_color').css('background-color','transparent')
        var elementForStyleParent = jQuery(element.target)
        if (elementForStyleParent[0].tagName=='IMG') {
          var elementForStyle = elementForStyleParent.parent().css('background-color','#2d5980')
        }else{
          var elementForStyle = elementForStyleParent.css('background-color','#2d5980')
        }
			 this.setState({components:components})
      }
			
		}
    asignedSigDraw=async(data)=>{
          this.setState({asigSigDrawn:data})
          this.setState({sigDrawn:data})
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,data,1)
    }
    saveSignature= async()=>{
         var get_signature=this.sigPad.getTrimmedCanvas().toDataURL('image/png')
          if (get_signature.indexOf('iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC')!=-1) {
            alert('Es Necesario una Firma')
            return
          }
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,get_signature,0)
          if (data_result.success) {
            var get_data_user = await get_permission(jsondata._id)
            this.setState({get_data_user:get_data_user[0]})
            this.setState({sigDrawn:false})
          }
    }
    changeSigDraw=(data)=>{
      this.setState({sigDrawn:data})
    }
    generateSigDraw=async()=>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var getDataUser = this.state.get_data_user
          getDataUser.system_digital_signature = 'Generando Código'
          var putDataUser = getDataUser
          this.setState({get_data_user:putDataUser})
          var data_result = await save_signature_mrv(jsondata._id,null,2)
          if (data_result.success) {
            var get_data_user = await get_permission(jsondata._id)
            this.setState({get_data_user:get_data_user[0]})
          }
    }
    closeModal=(data)=>{
      this.setState({sendOpenModal:data})
    }
    saveActivity=async(dataUser,typeSave)=>{
      if (typeSave==1) {
        if (!window.confirm('Deseas eliminar?')) {
          return
        }
      }
          var getParamDB = new URLSearchParams(window.location.search).get("db");
          var getParamCode = new URLSearchParams(window.location.search).get("code");
          this.setState({btndis:true})
          var data_result = await save_signature_production(getParamDB,getParamCode,dataUser.draw_digital_signature,dataUser.system_digital_signature,this.state,typeSave)
          if (data_result.success) {
            this.setNameDB(this.props.code)
            this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
            this.setState({btndis:false})
          }
          this.setState({sendOpenModal:!this.state.sendOpenModal})
    }
    notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
	render(){
  return (
    <>
  <div className="rna-wrapper">
    <NotificationAlert ref={this.state.notificationAlertRef} />
  </div>
  <Modal isOpen={this.state.sendOpenModal} size="xl">
  <ModalHeader>
    <h3>Mi Firma</h3>
  </ModalHeader>
        <ModalBody>
            <div style={{textAlign:'center'}}>
              <label className="form-control-label">Asignar dibujo firma</label> <input type="checkbox" onChange={()=>{this.asignedSigDraw(!this.state.asigSigDrawn)}} checked={this.state.asigSigDrawn}/>
            </div>
{(this.state.asigSigDrawn)?(
          (this.state.sigDrawn)?(<>
                      <div style={{border: '1px solid #dee2e6',width:"380px",height:"200px",margin:'auto',marginBottom:'40px'}}>
                        <SignatureCanvas 
                        penColor='black' 
                        canvasProps={{width: 380, height: 200, className: 'sigCanvas'}}
                        ref={(ref) => { this.sigPad = ref }}
                        />
                      <button className="btn" onClick={()=>{this.saveSignature()}}>Guardar Firma</button>
                      </div>
            </>):(
                  (this.state.get_data_user?.draw_digital_signature==null)?(null):(<>
                    <div style={{border: '1px solid #dee2e6',width:"380px",height:"auto",margin:'auto'}}>
                      <div style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
                        <img 
                          style={{ backgroundSize: '200px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                          src={this.state.get_data_user?.draw_digital_signature} 
                          />
                      </div>
                        <br />
                    </div>
                    <div style={{width:"380px",margin:'auto'}}>
                      <button className="btn" onClick={()=>{this.changeSigDraw(!this.state.sigDrawn)}}>Cambiar Firma</button>
                    </div>
                  </>)
            )
  ):(null)}

          <div style={{textAlign:'center'}}>
            {(this.state.get_data_user?.system_digital_signature==null)?(<>
                    <div style={{width:"380px",margin:'auto'}}>
                      <button className="btn" onClick={()=>{this.generateSigDraw()}}>Generar firma digital (Genere la firma digital para guardar)</button>
                    </div>
              </>):(<>
                  <h2>{this.state.get_data_user?.system_digital_signature}</h2>
              </>)}
          </div>
        <hr />
          <div style={{textAlign:'center'}}>
            <h2>{this.state.get_data_user?.email}</h2>
            <h3>DNI N# {this.state.get_data_user?.dni}</h3>
          </div>
        </ModalBody>
        <ModalFooter>
        {(this.state.get_data_user?.system_digital_signature==null)?(null):(<>
            {(this.state.get_data_user?.system_digital_signature=='Generando Código')?(null):(<>
              <Button 
              className="btn-icon" 
              color="traza-blue" 
              type="button"
              disabled={this.state.btndis}
              onClick={()=>{this.saveActivity(this.state.get_data_user,0)}}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-check-circle" />
                </span>
                <span className="btn-inner--text">Guardar</span>
              </Button>
            </>)}
          </>)}
          {(this.state.title_end=='Click para ver')?(<>
          <Button disabled={this.state.btndis} onClick={()=> {this.saveActivity(this.state.get_data_user,1)} }>
            Borrar este registro
          </Button>
            </>):(null)}
          <Button onClick={()=> {this.closeModal(!this.state.sendOpenModal)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <div className="main-content pt-1" style={{backgroundColor: 'white'}}>
          	<Col md="12" style={{margin: 'auto'}}>
                <CardHeader style={{padding: '5px',backgroundColor: 'rgb(122, 193, 66)',borderRadius: '5px',textAlign: 'center'}}>
                <Row>
                <Col md={2}>
	                <Row>
		              <div style={{width:'100%',paddingLeft:'15px'}}>
	                  	<p className="mb-0" style={{color:'#ffffff'}}>Gestión de cultivos</p>
					        </div>
	                  <div>
	             	  	  <GuideMessage section="fuits_vegetable" section_two="Trace" section_table_form="null" />
	                  </div>
	                </Row>
                </Col>
        				  <div className="col-md-3">
        	         <p className="mb-0" style={{color:'#ffffff'}}>{(this.state.data_user==null)?(null):(' '+this.state.data_user.name+', '+this.state.data_user.second_name)}</p>
        				  </div>
                  <div className="col-md-3">
                	<p className="mb-0" style={{color:'#ffffff'}}>{this.state.code_traza}</p>
                  </div>
                  <div className="col-md-4">
                  <p className="mb-0" style={{color:'#ffffff'}}>Ultimo inicio de sesión: {(this.state.data_user==null)?(null):(' '+this.state.data_user.init_session_date)}</p>
                  </div>
                </Row>
                </CardHeader>
            </Col>
		<section className="elementor-section elementor-top-section elementor-element elementor-element-ddc63bc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddc63bc" data-element_type="section">
		  <div className="elementor-container elementor-column-gap-default">
		    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70ec96d" data-id="70ec96d" data-element_type="column">
		      <div className="elementor-widget-wrap">
		  	  </div>
		    </div>
		  </div>
	    </section>
    			<section style={{backgroundColor: 'white'}} className="elementor-section elementor-top-section elementor-element elementor-element-9f9c7a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9f9c7a0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"><div >
          		<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4d21c55" data-id="4d21c55" data-element_type="column">
      			<div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-1a59632 elementor-widget elementor-widget-eael-adv-tabs" data-id="1a59632" data-element_type="widget" data-widget_type="eael-adv-tabs.default">
        		<div className="elementor-widget-container">
              	<div id="eael-advance-tabs-1a59632" className="eael-advance-tabs eael-tabs-horizontal" data-tabid="1a59632">
            	<div className="eael-tabs-nav">
                <ul className="eael-tab-top-icon">
                <Col id="infprod" aria-selected="false" data-tab="1" role="tab" aria-controls="infprod-tab" aria-expanded="false" className='text-center'>
	                <Row>
                  <Col md={3}>
                    <li id="infprod_c" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
  	                onClick={(e)=>{this.pushSection(SectionNamaG,e)}} className="eael-tab-item-trigger clk_change_color">
  	                <img src={informacion} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
  	                </li>
                  </Col>
                  <Col md={9} style={{alignSelf: 'center'}}>
	                 <span className="eael-tab-title title-after-icon">Información General</span>
                  </Col>
                  </Row>
                </Col>

                <Col id="produccion" aria-selected="false" data-tab="2" role="tab"  aria-controls="produccion-tab" aria-expanded="false" className='text-center'>
                  <Row>
                  <Col md={3}>
                    <li id="produccion_c" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
  	                onClick={(e)=>{this.pushSection(SectionNamaGP,e)}} className="eael-tab-item-trigger clk_change_color">
  	                <img src={Produccion} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
  	                </li>
                  </Col>

                  <Col md={4} style={{alignSelf: 'center'}}>
	                 <span className="eael-tab-title title-after-icon">Producción</span>
                  </Col>

                  </Row>
                </Col>
                <Col id="hadinf" aria-selected="false" data-tab="3" role="tab"  aria-controls="hadinf-tab" aria-expanded="false" className='text-center'>
                  <Row>

                  <Col md={3}>
                    <li id="hadinf_c" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
  	                onClick={(e)=>{this.pushSection(SectionNamaGM,e)}} className="eael-tab-item-trigger clk_change_color">
  	                <img src={Fitosanitario} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
  	                </li>
                  </Col>

                    <Col md={9} style={{alignSelf: 'center'}}>
  	                 <span className="eael-tab-title title-after-icon">Manejo Fitosanitario</span>
                    </Col>
                  </Row>

                </Col>

                <Col id="harvinf" aria-selected="false" data-tab="5" role="tab"  aria-controls="harvinf-tab" aria-expanded="false" className='text-center'>
                  <Row>
                  <Col md={3}>
                    <li id="harvinf_c" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
                    onClick={(e)=>{this.pushSection(SectionNamaGH,e)}} className="eael-tab-item-trigger clk_change_color">
                    	<img src={Cosecha} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
                    </li>
                  </Col>
                  <Col md={2} style={{alignSelf: 'center'}}>
                	 <span className="eael-tab-title title-after-icon">Cosecha</span>
                  </Col>
                  </Row>
                </Col>

                <Col id="salevf" aria-selected="false" data-tab="5" role="tab"  aria-controls="salevf-tab" aria-expanded="false" className='text-center'>
                  <Row>
                  <Col md={3}>
                    <li id="sale_vf" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
                    onClick={(e)=>{this.pushSection(SectionFVGCSale,e)}} className="eael-tab-item-trigger clk_change_color">
                      <img src={SalesImg} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
                    </li>
                  </Col>
                  <Col md={2} style={{alignSelf: 'center'}}>
                   <span className="eael-tab-title title-after-icon">Venta</span>
                  </Col>
                  </Row>
                </Col>

                <Col id="uploadirgi" aria-selected="false" data-tab="5" role="tab"  aria-controls="uploadirgi-tab" aria-expanded="false" className='text-center'>
                  <Row>
                  <Col md={3}>
                    <li id="harvinf_c" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
                    onClick={(e)=>{this.pushSection(SectionGasCo,e)}} className="eael-tab-item-trigger clk_change_color">
                      <img src={GasesCo} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
                    </li>
                  </Col>
                  <Col md={2} style={{alignSelf: 'center'}}>
                   <span className="eael-tab-title title-after-icon">Gases</span>
                  </Col>
                  </Row>
                </Col>

{/*                <Col id="mvr" aria-selected="false" data-tab="5" role="tab"  aria-controls="mvr-tab" aria-expanded="false" className='text-center'>
                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
                onClick={(e)=>{this.pushSection(SectionMVR,e)}} className="eael-tab-item-trigger clk_change_color">
                	<img src={ImgMVR} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
                </li>
                	<span className="eael-tab-title title-after-icon">MVR</span>
                </Col>*/}

                <Col id="documentos" aria-selected="false" data-tab="7" role="tab"  aria-controls="documentos-tab" aria-expanded="false" className='text-center'>
                  <Row>

                  <Col md={3}>
                    <li id="documentos_c" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}}
  	                onClick={(e)=>{this.pushSection(SectionDocuments,e)}} className="eael-tab-item-trigger clk_change_color">
  	                {/*<i className="fas fa-clipboard-check"></i>*/}
  	                <img src={Folder} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
  					        </li>
                  </Col>

                  <Col md={4} style={{alignSelf: 'center'}}>
	                 <span className="eael-tab-title title-after-icon">Documentos</span>
                  </Col>

                  </Row>
                </Col>
                {(this.state.data_user_ad==null)?(null):(
                  (this.state.data_user_ad=='VA')?(<>
                    <Col id="validate" aria-selected="false" data-tab="5" role="tab"  aria-controls="validate-tab" aria-expanded="false" className='text-center'>
                      <Row>
                      <Col md={3}>
                        <li id="validate_c" style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:this.state.statusColor.validate_tab}}
                        onClick={()=>{this.pushSection('signature_validate')}} className="eael-tab-item-trigger">
                          <img src={Check} alt="Logo" style={{mixBlendMode: 'multiply',width: '25px'}} />
                        </li>
                      </Col>
                      <Col md={9} style={{alignSelf: 'center'}}>
                          <span className="eael-tab-title title-after-icon">{this.state.title_end}</span>
                      </Col>
                      </Row>
                    </Col>
                    </>):(null)
                  )}

                </ul>
                </div>
                <hr />
            {(this.state.allDataTrza==null)?('Contenido.'):(< this.state.components DataRow={this.state} />)}
        </div>
    </div>
        </div>
          </div>
    </div>
              </div>
    </section>

    </div>
    </>
  );
 }
}

