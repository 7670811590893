import React, {createRef} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import { get_permission, edit_user, upload_file_photo, send_email_repeat, check_guides, send_email_contact } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import NotificationAlert from "react-notification-alert";
import config from "config";
import jQuery from 'jquery';
import {GuideMessage} from "views/pages/components/GuideMessage";
import {UserContext} from "layouts/store";

class Contacts extends React.Component {
  static contextType = UserContext
state={
  nombre:'',
  apellido:'',
  email:'',
  img_profile:null,
  notificationAlertRef: React.createRef(),
  disabledbtn:false,
  verfificar_label:'Comprobando',
  motive:''
}
async componentDidMount(){
  var user_email = JSON.parse(localStorage.getItem("user")).email;
  this.setState({email:user_email})
}
componentDidUpdate(prevP,prevS){
}
send_mail=async()=>{
  this.setState({disabledbtn:true})
  if (this.state.nombre=='' || this.state.apellido=='' || this.state.email=='' || this.state.motive=='') {
    this.setState({disabledbtn:false})
    this.notify('danger','Alerta','Es necesario llenar todos los campos.')
    return
  }
    var result = await send_email_contact(this.state.nombre,this.state.apellido,this.state.email,this.state.motive)
    if (result=='exito') {
      this.notify("success", 'Enviado','Mensaje enviado exitosamente.');
      this.setState({disabledbtn:false})
    }
}
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
render(){
  const user = JSON.parse(localStorage.getItem("user")).email.split("@")[0];
  
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <div
        className="header pb-8 d-flex align-items-center"
        style={{
          minHeight: "335px",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-traza-green opacity-8" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-3 text-white">¡HOLA, {user.toUpperCase()}!</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt--9" fluid>
        <Row>
          <Col className="order-xl-1" xl="6">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Enviar Correo</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="traza-blue"
                      onClick={()=>{this.send_mail()}}
                      size="sm"
                      disabled={this.state.disabledbtn}
                    >
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Nombre
                          </label>
                          <Input
                            value={this.state.nombre}
                            onChange={e => this.setState({nombre:e.target.value})}
                            id="input-name"
                            placeholder="Ingresa un nombre"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Apellido
                          </label>
                          <Input
                            value={this.state.apellido}
                            onChange={e => this.setState({apellido:e.target.value})}
                            id="input-name"
                            placeholder="Ingresa un apellido"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup className="mb-0">
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Correo electrónico
                          </label>
                          <Input
                            value={this.state.email}
                            onChange={e => this.setState({email:e.target.value})}
                            id="input-email"
                            placeholder="Ingresa un correo electrónico"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Motivo
                            </label>
                            <Row>
                              <Col md="12">
                              <Input
                                value={this.state.motive}
                                id="example3cols2Input"
                                className="ubicacionex"
                                placeholder="Escriba su Motivo"
                                type="textarea"
                                onChange={(e) => this.setState({motive:e.target.value})}
                              />
                              </Col>  
                            </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
 }
}

export default Contacts;
