import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import GNamaPLPreparation from "views/pages/forms/Production/Grains/GrainNama/GestionOfCultiveExtend.js";
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";

class CardPhytosanitary extends React.Component {
  state={
    selectedProdItems:null,
    contentActivity:null,
    visibleRight:false
  }
//const CardProduction = () => {
    render () {
    //const router = useHistory()
    
    const itemsProd = [
        //{ name: 'Agroquímicos', table:'hading_fert_agro', content:GNamaPLPreparation, permission_column:'hading_nama_g', code: 'agroquimicos' },
        { name: 'Información de Malezas', table:'hading_info_weeds', content:GNamaPLPreparation, permission_column:'hading_nama_g', code: 'malezas' },
        { name: 'Inventario de Malezas', table:'hading_inventory_weeds', content:GNamaPLPreparation, permission_column:'hading_nama_g', code: 'malezas' },
        { name: 'Información de Plagas', table:'hading_info_plague', content:GNamaPLPreparation, permission_column:'hading_nama_g', code: 'plagas' },
        { name: 'Inventario de Plagas', table:'hading_inventory_plague', content:GNamaPLPreparation, permission_column:'hading_nama_g', code: 'plagas' },
        { name: 'Información de Enfermedades', table:'hading_info_diseases', content:GNamaPLPreparation, permission_column:'hading_nama_g', code: 'enfermedades' },
    ];

    const header = (
        <img 
            alt="production" 
            src="/layout/images/icons/ladybug.png"  
            className='w-2'
        />
    );

    const selectedItemTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const itemOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>
                    {option.name}
                </div>
            </div>
        );
    };
    return (
        <div>
            <Card title="Control Fitosanitario" header={ header }>

                <Dropdown 
                    value={this.state.selectedProdItems} 
                    onChange={(e) => { 
                        this.setState({selectedProdItems:e.value})
                        if (e.value!=undefined) {
                            this.setState({contentActivity: e.value.content })
                            this.setState({visibleRight:true}) 
                        }
                    }}
                    options={ itemsProd } 
                    optionLabel="name" 
                    showClear 
                    placeholder="Selecciona" 
                    className="w-full p-inputtext-sm" 
                    itemTemplate={ itemOptionTemplate } 
                    dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                    }}
                />
                    <p className="mt-4">
                        {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                    </p> 
            </Card>
              <SidebarCustom 
                  onVisible={this.state.visibleRight}
                  onHide={() => {
                        this.setState({
                            visibleRight:false,
                            contentActivity:null,
                            selectedProdItems:null
                        })
                }}
                  //onWidth={ this.state.selectedProdItems?.table=='production_info_satelite_dni_code' ? 1000 : 800 }
                  onWidth={ this.state.selectedProdItems?.table=='production_info_satelite_dni_code' ? ((document.body.offsetWidth<=991)?("100%"):(800))  : ((document.body.offsetWidth<=991)?("100%"):(800)) }
                  position="right" 
                  title={this.state.selectedProdItems?.name}
                  >
                    {
                        (this.state.contentActivity==null)
                        ?
                        ('Contenido.')
                        :
                        (<this.state.contentActivity {...this.props} itemProd={this.state.selectedProdItems} />)
                    }
              </SidebarCustom>

        </div>
    )
  }
}

export default CardPhytosanitary;