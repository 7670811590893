import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import {GuideMessage} from "views/pages/components/GuideMessage";
import AlertMRV from "components/Headers/AlertMRVOR.js";
function SimpleHeaderKdes({ name, parentName, section, section_two, section_table_form, mrvtable },props) {
  return (
    <>
      <div className="header header-dark bg-traza-blue pb-1 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <Link to="/#" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link className={'section_header_prod'} to="/#" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <GuideMessage section={section} section_two={section_two} section_table_form={section_table_form} />
                  </BreadcrumbItem>


                  {/*<BreadcrumbItem aria-current="page" className="active">
                                      {name}
                                    </BreadcrumbItem>*/}
                </Breadcrumb>
              </Col>
            </Row>
            {(mrvtable!=undefined)?(
              <AlertMRV {...props} table={mrvtable} content_area="1"/>
              ):(null)}
          </div>
        </Container>
      </div>
    </>
  );
}

SimpleHeaderKdes.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default SimpleHeaderKdes;
