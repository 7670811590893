import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import {PutContent, PutContentSub, PutContentStatus} from 'views/pages/examples/Trazability/SectionsViewTrace/InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'

    const datacontent = [
    {table:'commercialization_market_vf_gc',title:'Mercado Nacional',icon:'fas fa-info','multiple_tables':0},
    {table:'commercialization_export_vf_gc',title:'Exportación',icon:'fas fa-hdd','multiple_tables':0},
    ]
export default class SectionFVGCSale extends React.Component {
  state={
    openColapse:[false],
    openColapseChild:[false]
  }
  contentRows = () =>{
    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
      <Col md={12}>
            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
            <div className="elementor-widget-wrap"></div></div>

            <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-7c54668 elementor-widget elementor-widget-spacer" data-id="7c54668" data-element_type="widget" data-widget_type="spacer.default">
            <div className="elementor-widget-container">
            <div className="elementor-spacer">
            </div>
            </div>
            </div>
{/*            <div className="elementor-image-box-content">
            <span className="eael-tab-title title-after-icon">{val.title}</span>
            </div>*/}
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
            <div className="elementor-toggle-item" style={{zIndex: '99'}}>
            <h6 onClick={()=>{this.opened(this.state.openColapse[indx+1],indx+1)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
              <div style={{marginBottom:'10px'}} className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
              <div className="elementor-widget-container">
              <div className="elementor-image-box-wrapper">
              <figure className="elementor-image-box-img">
              <div style={{width:30,height:30,backgroundColor:PutContentStatus(this.props.DataRow.allDataTrza,val.table).color,borderRadius: '100px', textAlign: 'center'}}>
              <span className={val.icon} style={{color:'#204d74',fontSize: '23px',position: 'relative',bottom: '0px',left: '0px'}}></span>
              </div>
              </figure>
              <div className="elementor-image-box-content">
              <span className="eael-tab-title title-after-icon" style={{fontWeight: 'normal',fontSize: '12px'}}>Datos {val.title}</span>
              </div>
              </div>
              </div>
              </div>
            </h6>
                </div>
                </div>
                </div>
                </div>
                <div className="elementor-element elementor-element-81e561d elementor-widget elementor-widget-spacer" data-id="81e561d" data-element_type="widget" data-widget_type="spacer.default">
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
            </div>
            </div>
            </div>
            </div>
        </Col>
                  </>
    })
    return content
  }
  contentRowsCard = () =>{
    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
        
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
            <div className="elementor-toggle-item">
                  <Collapse isOpen={this.state.openColapse[indx+1]} id="elementor-tab-content-6961" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-6961">
                    <ul>
                      <PutContentSub
                      data_function={this.props.DataRow} 
                      type_function={val.table}
                      states={this.state}
                      openedChild={this.openedChild}
                      chillSelector={"data"}
                      />
                    </ul>
                  </Collapse>
            </div>
            </div>
            </div>
            </div>

                  </>
    })
    return content
  }
     opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
     openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
      render(){
        return (
          <>
            <ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
          </>
        );
      }
}

