import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import jQuery from 'jquery';
import {UserContext} from "layouts/store";

class ServicePay extends React.Component {
  static contextType = UserContext
  state={
    selectBtnStyle:{[0]:style.btnSelect},
    stateService:[],
    stateServiceActual:[],
    typeMA:'Mes',
    type_traza:null,
    setinfoState:false
  }
  async componentDidMount(){

  }
  render(){
  return (
    <>
      <Container className="mt--6" fluid>
        <div style={{width: '100%', textAlign:'center',marginTop: '80px'}}>
          <p>¡¡Pago realizado exitosamente!!</p>
        </div>

        <div style={{color: 'green',display: 'flex',border: 'solid 1px', borderRadius: '100px',width: '200px',height: '200px',marginRight: 'auto',marginLeft: 'auto'}}>
          <div style={{fontSize: '160px',color: 'green',width: '100%',textAlign: 'center',margin:'auto'}} className="fa fa-check"></div>
        </div>

        <div style={{width: '100%', textAlign:'center'}}>
          <p>¡¡Revise su correo para ver su factura!!</p>
        </div>        
      </Container>
    </>
  );
 }
}
const style = {
  btnSelect:{
    backgroundColor: "#00295b",
    color:"white"
  }
}
export default ServicePay;
