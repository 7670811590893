import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";

import {register_colab,data_list_addcoll, get_permission} from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import { NavLink as ComponentLink } from "react-router-dom";
import {UserContext} from "layouts/store";
//function Elements() {
class AddCollab extends React.Component {
state = {
  autorization:'0',
  trazabilidad:'0',
  guide_m:'0',
  guide_establishment_m:'',
  guide_incription_m:'',
  entidad:'',
  nombre:'',
  email:'',
  password:'',
  mess:'',
  notificationAlertRef: React.createRef(),
  btndisab:false,
  check_nama_data:[],
  data_traza:[],
  data_traza_m:[],
  data_production_user:[],
  data_traza_g:[],
  setAreawork:"",
  select_mrv:0
}
async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    //const data = await data_list_addcoll(jsondata._id)
    //this.setState({data_traza_m:data.trazabilidad_meat,data_production_user:data.data_production,data_traza_g:data.traza_grain})
}
render(){
  const saveColab = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var globlalthis = this
            this.setState({btndisab: true})
    if ( this.state.setAreawork=='' || this.state.entidad=='' || this.state.nombre=='' || this.state.email=='') {
            this.setState({mess: 'Todos los campos son requeridos'})
            this.setState({btndisab: false})
    }else{
    if (this.state.setAreawork.indexOf('T')!=-1 ) {
      if (this.state.autorization=='0' || (this.state.trazabilidad=='0' || this.state.trazabilidad=='')) {
        this.setState({mess: 'Seleccione una Trazabilidad y una Autorización.'})
        this.setState({btndisab: false})
        return;
      }
    }
        var getValue = [];
      if (this.state.data_traza.length!=0) {
        var documentTag = document.querySelectorAll(".lote_check_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && ( this.state.trazabilidad.search(/VF|V1F|V2F|V3F|V4F/gi)!=-1 )) {
          if (documentTag.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en frutas y vegetales')
          return
        }else{
          for (var i = 0; i < documentTag.length; i++) {
              getValue[i] = documentTag[i].value
            }
          }
        }
      }
        var getValueM = [];
      if (this.state.data_traza_m.length!=0) {
        var documentTagM = document.querySelectorAll(".lote_check_m_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.trazabilidad.indexOf('M')!=-1) ) {
          if (documentTagM.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en carnicos')
          return
        }else{
          for (var i = 0; i < documentTagM.length; i++) {
              getValueM[i] = documentTagM[i].value
            }
          }
        }
      }

        var getValueG = [];
      if (this.state.data_traza_g.length!=0) {
        var documentTagGrain = document.querySelectorAll(".check_traza_g_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.trazabilidad.indexOf('G')!=-1)) {
          if (documentTagGrain.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en granos')
          return
        }else{
          for (var i = 0; i < documentTagGrain.length; i++) {
              getValueG[i] = documentTagGrain[i].value
            }
          }
        }
      }

        var getValueP = [];
      if (this.state.data_production_user.length!=0) {
        var documentTagP = document.querySelectorAll(".check_prod_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.setAreawork.indexOf('P')!=-1)) {
          if (documentTagP.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione un registro para producción')
          return
        }else{
          for (var i = 0; i < documentTagP.length; i++) {
              getValueP[i] = documentTagP[i].value
            }
          }
        }
      }

        var getValueFVGC = [];
      if (this.state.data_vf_gc.length!=0) {
        var documentTagP = document.querySelectorAll(".check_fv_gc_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.setAreawork.indexOf('VCF')!=-1)) {
          if (documentTagP.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione un registro para producción')
          return
        }else{
          for (var i = 0; i < documentTagP.length; i++) {
              getValueFVGC[i] = documentTagP[i].value
            }
          }
        }
      }

      
        this.setState({btndisab: false})
         const response = await register_colab(this.state.nombre,
                                               this.state.email,
                                               this.state.entidad,
                                               this.state.password,
                                               this.state.autorization,
                                               this.state.setAreawork+'|'+this.state.trazabilidad,
                                               this.state.guide_m,
                                               this.state.guide_establishment_m,
                                               this.state.guide_incription_m,
                                               this.state.select_mrv,
                                               2,
                                               jsondata._id,
                                               JSON.stringify(this.state.check_nama_data),
                                               getValue.filter(Boolean),
                                               getValueM.filter(Boolean),
                                               getValueG.filter(Boolean),
                                               getValueP.filter(Boolean),
                                               getValueFVGC.filter(Boolean),
                                               Object.keys(this.state.check_nama_data).join('-'))
         .catch(function (message){
          if (message.response.status==500) {
           globlalthis.setState({mess: 'Ha ocurrido un error, intente mas tarde.'})
          }
          if (message.response.status==401) {
           globlalthis.setState({mess: message.response.data.errors.email[0]})
          }
          if (message.response.status==422) {
            globlalthis.setState({mess: 'No puedes registrar este correo.' })
          }
          if (message.response.status==403) {
            globlalthis.setState({mess: message.response.data.error })
          }
           globlalthis.setState({btndisab: false})
         })
           if (response==undefined) {
           return;
         }else{
           const {data} = response;
           if (!data.success) {
           globlalthis.setState({btndisab: false})
           this.setState({mess: data.message})
               return;
           }
         }
         notify("success", "¡Registrado exitosamente!", "Ahora inicia sesión con correo y contraseña.");
         this.props.history.push('/admin/users')
    }
  }
  // const logistSwitch = () => {
  //       if (this.state.setAreawork.indexOf("P")!=-1) {
  //         var contentViewsProd = []
  //       if (this.state.autorization=="view") {
  //         if (this.state.data_production_user.length!=0) {
  //           contentViewsProd[0]= sectionTableP(this.state.data_production_user)
  //         }
  //       }
  //       if (this.state.autorization=="options") {
  //            contentViewsProd[1]= contentG()
  //       }
  //         return contentViewsProd
  //       }

  //     if (this.state.setAreawork.indexOf("T")!=-1) {
  //       var contentViews = []
  //       if (this.state.autorization=="view") {
  //         if (this.state.trazabilidad.indexOf("VF")!=-1) {
  //           if (this.state.data_traza.length!=0) {
  //             contentViews[0]= sectionTableVF(this.state.data_traza)
  //           }
  //         }
  //         if (this.state.trazabilidad.indexOf("M")!=-1) {
  //           if (this.state.data_traza_m.length!=0) {
  //             contentViews[1]= sectionTableM(this.state.data_traza_m)
  //           }
  //         }
  //         if (this.state.trazabilidad.indexOf("G")!=-1) {
  //           if (this.state.data_traza_g.length!=0) {
  //             contentViews[1]= sectionTableG(this.state.data_traza_g)
  //           }
  //         }
          
  //       return contentViews
  //       }

  //       var contentOptions = []
  //       if (this.state.autorization=="options") {
  //         if (this.state.trazabilidad.indexOf("VF")!=-1) {
  //           contentOptions[0]= contentVF()
  //         }
  //         if (this.state.trazabilidad.indexOf("M")!=-1) {
  //           contentOptions[1]= contentM()
  //         }
  //         if (this.state.trazabilidad.indexOf("G")!=-1) {
  //           contentOptions[1]= contentTG()
  //         }
  //       return contentOptions
  //       }
  //     }

  // }
  const logistSwitch = () => {
      if (this.state.setAreawork.indexOf("P")!=-1) {
        var contentViewsProd = []
        if (this.state.autorization=="view") {
          if (this.state.data_production_user.length!=0) {
            contentViewsProd[0]= sectionTableP(this.state.data_production_user)
          }
        }
        if (this.state.autorization=="options") {
             contentViewsProd[0]= contentG()
             contentViewsProd[1]= sectionTableP(this.state.data_production_user)
        }
        return contentViewsProd
      }

      if (this.state.setAreawork.indexOf("VCF")!=-1) {
        var contentViewsFVGC = []
        if (this.state.autorization=="view") {
          if (this.state.data_vf_gc.length!=0) {
            contentViewsFVGC[0]= sectionTableFVGC(this.state.data_vf_gc)
          }
        }
        if (this.state.autorization=="options") {
             contentViewsFVGC[0]= contentVFGC()
             contentViewsFVGC[1]= sectionTableFVGC(this.state.data_vf_gc)
        }
        return contentViewsFVGC
      }

      if (this.state.setAreawork.search(/VF|V1F|V2F|V3F|V4F/gi)!=-1) {
        
        if (this.state.autorization=="view") {
          return sectionViewSelection()
        }

        var contentViews = []
        if (this.state.autorization=="options") {
        return sectionViewSelectionTwo()
         }
      }
  }
  const sectionViewSelectionTwo =()=>{
        var contentViews = []
        //this.state.trazabilidad.indexOf("VF")!=-1
          if (this.state.trazabilidad.search(/VF|V1F|V2F|V3F|V4F/gi)!=-1) {
            if (this.state.data_traza.length!=0) {
              contentViews[0]= contentVF()
              contentViews[1]= sectionTableVF(this.state.data_traza)
            }
          }
          if (this.state.trazabilidad.indexOf("M")!=-1) {
            if (this.state.data_traza_m.length!=0) {
              contentViews[2]= contentM()
              contentViews[3]= sectionTableM(this.state.data_traza_m)
            }
          }
          if (this.state.trazabilidad.indexOf("G")!=-1) {
            if (this.state.data_traza_g.length!=0) {
              contentViews[4]= contentTG()
              contentViews[5]= sectionTableG(this.state.data_traza_g)
            }
          }
        return contentViews
  }
  const sectionViewSelection =()=>{
        var contentViews = []
    var regexStr = /VF|V1F|V2F|V3F|V4F/gi
    var data_type_area = this.state.trazabilidad.search(regexStr)
          if (data_type_area!=-1) {
            if (this.state.data_traza.length!=0) {
              contentViews[0]= sectionTableVF(this.state.data_traza)
            }
          }
          if (this.state.trazabilidad.indexOf("M")!=-1) {
            if (this.state.data_traza_m.length!=0) {
              contentViews[1]= sectionTableM(this.state.data_traza_m)
            }
          }
          if (this.state.trazabilidad.indexOf("G")!=-1) {
            if (this.state.data_traza_g.length!=0) {
              contentViews[2]= sectionTableG(this.state.data_traza_g)
            }
          }
        return contentViews
  }
  const logistGuides = () => {
      var component=null;
      if (this.state.trazabilidad=="0") {
        return;
      }
      if (this.state.trazabilidad.indexOf("M")==-1) {
        return;
      }
      if ((this.state.trazabilidad.indexOf("M")!=-1) && (this.state.autorization=="view" || this.state.autorization=="0")) {
        return;
      }
    component =<><Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Asignar Guias de propiedad y transito
                  </label>
                  <br />
                <input
                  onChange={(e)=>{this.setState({guide_m:e.target.value})}}
                  id="guide_pt" name="guide_pt" type="checkbox" value={(this.state.guide_m=="0")?("1"):("0")}/>
                </FormGroup>
              </Col></>
              return component
  }
  const sectionTableVF = (data) => {
    var componenteData = []
    componenteData = <Col md={6} className="table-responsive">
              <Col>
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista frutas y vegetales
                        </span>
              </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Token</th>
                        <th style={{border: 'solid 1px'}}>Lote asignado</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.token_id}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.numero_lote}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                        <input style={{width: '100%',margin: 0}} className="lote_check_send" name="lote_check_send" type="checkbox" value={value.token_id}/>
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table></Col>
          return componenteData
  }
  const sectionTableM = (data) => {
    var componenteData = []
    componenteData = <Col md={4} className="table-responsive">
              <Col>
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista carnicos
                        </span>
              </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>Nombre</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.nombre_traza}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>

                                                        <input style={{width: '100%',margin: 0}} className="lote_check_m_send" name="lote_check_m_send" type="checkbox" value={value.codigo}/>

                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table></Col>
          return componenteData
  }
  const sectionTableP = (data) => {
    var componenteData = []
    componenteData = <Col md={4} className="table-responsive">
              <Col>
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista producción
                        </span>
              </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>producto</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.producto}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>

                                                        <input style={{width: '100%',margin: 0}} className="check_prod_send" name="check_prod_send" type="checkbox" value={value.codigo}/>

                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table></Col>
          return componenteData
  }
  const sectionTableG = (data) => {
    var componenteData = []
    componenteData = <Col md={4} className="table-responsive">
              <Col>
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista granos arroz
                        </span>
              </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>

                                                        <input style={{width: '100%',margin: 0}} className="check_traza_g_send" name="check_traza_g_send" type="checkbox" value={value.codigo}/>

                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table></Col>
          return componenteData
  }

  const contentVF = () => {
const data = [
//{title:'Crear',attr:'create',stateVal:'create'},
{title:'Editar',attr:'edit',stateVal:'edit'},
{title:'Compartir',attr:'share',stateVal:'share'},
{title:'Documentos',attr:'documents',stateVal:'documents'},
]
return(<Col md={3} style={{border: '1px solid',marginTop: '5px'}}>
                                      
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Vegetales Y frutas
                                                </span>
                                      
                                      {getDataState(data)}
                                    </Col>)
  }
const contentM = () => {
const data = [

{title:'Trazabilidad',attr:'trazabilidad_m',stateVal:'trazabilidad_m'},
{title:'Lote',attr:'lote_m',stateVal:'lote_m'},
{title:'Logistíca',attr:'logist_m',stateVal:'logistica_m'},
{title:'Compartimento',attr:'compart_m',stateVal:'compartimento_m'},
{title:'Frigorífico',attr:'fridge_m',stateVal:'frigorifico_m'},
{title:'Documentos',attr:'documento_m',stateVal:'documentos_m'},
]
return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Carnicos
                                                </span>
                                      {getDataState(data)}
                                    </Col>)
  }

const contentTG = () => {
const data = [
{title:'Información General',attr:'information_grain_t',stateVal:'information_grain_t'},
{title:'Producción',attr:'production_grain_t',stateVal:'production_grain_t'},
{title:'Cosecha',attr:'harvest_grain_t',stateVal:'harvest_grain_t'},
{title:'Documento',attr:'document_grain_t',stateVal:'document_grain_t'},
]
return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Arroz
                                                </span>
                                      {getDataState(data)}
                                    </Col>)
  }
const contentG = () => {

                            return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Selección de Producción
                                                </span>
                                      {setContentP()}
{/*                                      <Col md="12">
                                        <Row>
                                          <Col md="6">
                                            <Button style={{boxShadow: 'none', backgroundColor: 'white', border: 'none'}} onClick={()=>{this.setState({setTypeOption:'trazabilidad'})}}>Trazabilidad de arroz</Button>
                                          </Col>
                                          <Col md="6">
                                            <Button style={{boxShadow: 'none', backgroundColor: 'white', border: 'none'}} onClick={()=>{this.setState({setTypeOption:'nama'})}}>NAMA de arroz</Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                      {contentPSelect()}*/}
                                   </Col>)
  }
  const contentPSelect = (setTypeOption) => {
    if (this.state.setTypeOption=='nama') {
      return setContentP()
    }
    if (this.state.setTypeOption=='trazabilidad') {
      return null
    }

  }
  const setContentP = () => {
const data = [
{title:'Información General',attr:'information_nama_g',stateVal:'information_nama_g'},
{title:'Producción',attr:'prodction_nama_g',stateVal:'production_nama_g'},
{title:'Manejo Fitosanitario',attr:'hading_nama_g',stateVal:'hading_nama_g'},
{title:'Cosecha',attr:'harvest_nama_g',stateVal:'harvest_nama_g'},
{title:'Documento',attr:'documento_n_g',stateVal:'document_nama_g'},
]
return(
        <>
        <br />
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Opciones de Namas Arroz
                        </span>
                {getDataState(data)}
          </>
        )
  }
  const sectionTableFVGC = (data) => {
    var componenteData = []
    componenteData = <Col md={4} className="table-responsive">
              <Col>
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista producción
                        </span>
              </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>producto</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.producto}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>

                                                        <input style={{width: '100%',margin: 0}} className="check_fv_gc_send" name="check_fv_gc_send" type="checkbox" value={value.codigo}/>

                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table></Col>
          return componenteData
  }
  const contentVFGC = () => {

                            return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Selección de Producción
                                                </span>
                                      {setContentVFGC()}

                                   </Col>)
  }

  const setContentVFGC = () => {
  const data = [
  {title:'Información General',attr:'information_gc_fv',stateVal:'information_gc_fv'},
  {title:'Producción',attr:'production_gc_fv',stateVal:'production_gc_fv'},
  {title:'Manejo Fitosanitario',attr:'hading_gc_fv',stateVal:'hading_gc_fv'},
  {title:'Cosecha',attr:'harvest_gc_fv',stateVal:'harvest_gc_fv'},
  {title:'Venta',attr:'sell_gc_fv',stateVal:'sell_gc_fv'},
  {title:'Documento',attr:'document_gc_fv',stateVal:'document_gc_fv'},
  ]
  return(
          <>
          <br />
                          <span
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect1">
                            Opciones de Gestión de FV
                          </span>
                  {getDataState(data)}
            </>
          )
    }

  const getDataState = (data) => {
    var content = []
      data.map((val,ind)=>{
      content[ind] = <>
            <Col key={ind} md="12" sm="12">
              <Row>
                <div style={{paddingRight: '5px'}} className="col-md-1 p-0">
                    <input
                     name={val.attr}
                     onChange={(e)=>{
                      if (e.target.checked) {
                        this.setState({check_nama_data: {...this.state.check_nama_data,[val.stateVal]:(e.target.checked)?('1'):('0')}})
                      }else{
                        delete this.state.check_nama_data[val.stateVal]
                      }
                    }}
                     id={val.attr} 
                     type="checkbox" 
                     value={this.state.check_nama_data[val.stateVal]}/>
                </div>
                <div className="col-md-10 p-0">
                      <span
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        {val.title}
                      </span>
                </div>
              </Row>
            </Col>
            </>
          })
    return content
  }
      const contentTraza = () => {
        var get_data = this.props.dataUser.type_traza
        if (get_data!=undefined) {
        var dataTableTraza = [
          {prefix:'V4F',table:'create_establishment_receiving_kdes',title:'Recibido'},

          {prefix:'V3F',table:'create_distributor_receiving_kdes',title:'Recibido'},
          {prefix:'V3F',table:'create_distributor_shipping_kdes',title:'Enviado'},

          {prefix:'V2F',table:'create_producer_receiving_kdes',title:'Recibido'},
          {prefix:'V2F',table:'create_producer_food_kdes',title:'Comida'},
          {prefix:'V2F',table:'create_producer_transformation_kdes',title:'Transformación'},
          {prefix:'V2F',table:'create_producer_shipping_kdes',title:'Envio'},

          {prefix:'V1F',table:'create_plant_receiver_kdes',title:'Recibido'},
          {prefix:'V1F',table:'create_plant_cooling_kdes',title:'Enfriamiento'},
          {prefix:'V1F',table:'create_plant_product_kdes',title:'Inicial'},
          {prefix:'V1F',table:'create_plant_shipping_kdes',title:'Envio'},

          {prefix:'VF',table:'fruit_vegetable_user',title:'Datos Productor'}
        ]
        // {
        //       dataTableTraza.map((av,bv)=>{
        //         if (get_data.indexOf(av.prefix)!=-1) {
        //         }
        //       })
        // }
        return(<>
                  <option value={get_data}>Registros FDA</option>
{/*          {(get_data=='M')?(<>
                    <option value="M">Carnicos</option>
            </>):(null)}
          {(get_data=='G')?(<>
                    <option value="G">Granos</option>
            </>):(null)}
          {(get_data=='VF-M')?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="M">Carnicos</option>
                    <option value="VF-M">Vegetales y frutas - Carnicos</option>
            </>):(null)}
          {(get_data=='VF-G')?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="G">Granos</option>
                    <option value="VF-G">Vegetales y frutas - Granos</option>
            </>):(null)}
          {(get_data=='M-G')?(<>
                    <option value="M">Carnicos</option>
                    <option value="G">Granos</option>
                    <option value="M-G">Carnicos - Granos</option>
            </>):(null)}
          {(get_data=='VF-M-G')?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="M">Carnicos</option>
                    <option value="G">Granos</option>
                    <option value="VF-M">Vegetales y frutas - Carnicos</option>
                    <option value="M-G">Carnicos - Granos</option>
                    <option value="VF-G">Vegetales y frutas - Granos</option>
                    <option value="VF-M-G">Vegetales y frutas - Carnicos - Granos</option>
            </>):(null)}*/}
               </>)
        }
    }
      const contentAreaWork = () => {
        var get_data = this.props.dataUser.type_traza
        if (get_data!=undefined) {
        return(<>
          {(get_data.indexOf('P')!=-1)?(<>
                  <span>Granos GC: </span><input type="radio" name="work_area" value="P" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}

          {(get_data.indexOf('VCF')!=-1)?(<>
                  <span>Frutas y Vegetales GC: </span><input type="radio" name="work_area" value="VCF" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}

          {(get_data.indexOf('VF')!=-1)?(<>
                  <span>Trazabilidad FDA: </span><input type="radio" name="work_area" value="VF" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
          {(get_data.indexOf('V1F')!=-1)?(<>
                  <span>Trazabilidad FDA: </span><input type="radio" name="work_area" value="V1F" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
          {(get_data.indexOf('V2F')!=-1)?(<>
                  <span>Trazabilidad FDA: </span><input type="radio" name="work_area" value="V2F" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
          {(get_data.indexOf('V3F')!=-1)?(<>
                  <span>Trazabilidad FDA: </span><input type="radio" name="work_area" value="V3F" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
          {(get_data.indexOf('V4F')!=-1)?(<>
                  <span>Trazabilidad FDA: </span><input type="radio" name="work_area" value="V4F" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}

          {/*{(get_data.indexOf('C')!=-1)?(<>
                  <span>Comercialización: </span><input type="radio" name="work_area" value="C" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}*/}
               </>)
        }
    }
    const changeAreaWork = async (val) => {
      // console.log(val)
      // var data = ''
      // if (val.checked) {
      //   data = this.state.setAreawork+val.value
      //   //this.state.setAreawork
      // }else{
      //   data = this.state.setAreawork.replace(val.value,'')
      // }
      await finder_register_table(val.value)
      this.setState({setAreawork:val.value})
    }
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  const finder_register_table = async (valData)=>{
    this.setState({trazabilidad:valData})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    const data = await data_list_addcoll(jsondata._id,undefined,valData)
    var setData = []
    data.trazabilidad.map((a,b)=>{
      //var reducerData = [...new Map(a.map(v => [v.numero_lote, v])).values()]
      a.map((at,bt)=>{
        setData.push(at)
      })
    })
    var reducerData = [...new Map(setData.map(v => [v.numero_lote, v])).values()]
    //console.log(setData,reducerData)
    this.setState({data_traza:reducerData})
    this.setState({
      data_traza_m:data.trazabilidad_meat,
      data_production_user:data.data_production,
      data_traza_g:data.traza_grain,
      data_vf_gc:data.data_vf_gc
    })
  }
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
{/*<SimpleHeader name="Operaciones" parentName="Agregar colaborador" section="add_colab" section_two="none" section_table_form="null" />*/}
      <div className="mt-3">
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Agregar colaborador</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nombre y apellido
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({nombre:e.target.value})}}
                    placeholder="Escribe un nombre"
                    type="text"
                    autoComplete="none"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Entidad/Empresa
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({entidad:e.target.value})}}
                    placeholder="Escribe un entidad"
                    type="text"
                    autoComplete="none"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Área de Trabajo
                  </label>
                  <br/>
                    {contentAreaWork()}
                </FormGroup>
              </Col>
{/*              {(this.state.setAreawork.indexOf('P')!=-1)?(
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Elegir MRV
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({select_mrv:e.target.value})}}
                   id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="monitoreo">Monitoreo</option>
                    <option value="revision">Revisión</option>
                    <option value="validacion">Validación</option>
                  </Input>
                </FormGroup>
              </Col>
              ):(null)}*/}
              {(this.state.setAreawork.search(/VF|V1F|V2F|V3F|V4F/gi)!=-1)?(
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Trazabilidad
                  </label>
                  <Input
                    value={this.state.trazabilidad}
                    onChange={(e)=>{
                      finder_register_table(e.target.value)
                      //this.setState({trazabilidad:e.target.value})
                    }}
                   id="exampleFormControlSelect1" 
                   type="select">
                    <option value="0">Seleccione...</option>
                    {/*<option value="VFM">Todos</option>*/}
                    {contentTraza()}
                  </Input>
                </FormGroup>
              </Col>
                ):(null)}
              {(this.state.setAreawork.search(/VF|V1F|V2F|V3F|V4F/gi)!=-1 || 
                this.state.setAreawork.indexOf('P')!=-1 ||
                this.state.setAreawork.indexOf('VCF')!=-1)?(
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Autorización
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({autorization:e.target.value})}}
                    id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      {/*<option value="admin">Agregar administrador</option>*/}
                      <option value="view">Agregar Colaborador para que vea el proceso solamente</option>
                      <option value="options">Agregar Colaborador para editar solo secciones</option>
{/*                      {(this.state.trazabilidad=="VF")?(<>
                        <option value="prod_invt">Invitar usuario </option>
                        </>):(null)}*/}

                    </Input>
                  </FormGroup>
                </Col>
                ):(null)}
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Correo electrónico
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({email:e.target.value})}}
                    placeholder="Escribe un correo"
                    type="email"
                  />
                </FormGroup>
              </Col>
              {logistGuides()}
{(this.state.guide_m=='1')?(<><Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Establecimiento
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({guide_establishment_m:e.target.value})}}
                  id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="P">Propietario</option>
                    <option value="C">Consignatario</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Número de inscripción del establecimiento o empresa
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({guide_incription_m:e.target.value})}}
                    type="numer"
                  />
                </FormGroup>
              </Col></>):(null)}
{/*              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                    placeholder="Escribe una contraseña para el colaborador"
                    type="text"
                  />
                </FormGroup>
              </Col>*/}
            </Row>
            <Row>
              {logistSwitch()}
            </Row>
            <hr /><br />
                   {this.state.mess ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button onClick={()=>{saveColab()}} disabled={this.state.btndisab} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Invitar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
 }
}
class SwiftComponent extends React.Component {
    static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false,
    dataUser:[]
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    this.setState({dataUser:dataPermission})
    //console.log(dataPermission.role,jsondata.userType)
    if (jsondata.userType==1 || jsondata.userType==5) {
      this.setState({get_permission:true})
    }else{
      this.setState({get_permission:false})
    }
    if (jsondata.userType==2) {
      this.setState({get_permission:false})
    }
      }
render(){
  this.setInfoInit()  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <AddCollab {...this.props} dataUser={this.state.dataUser} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}

export default SwiftComponent;
