import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_transport_logistics, fridge_create_task, fridge_create_fridge, fridge_create_received, data_logic_fridge_task_received, deleteFridgeFunct, data_trazabilidad_meat, row_traza_lote } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";

  export default class ModalFridgeExtend extends React.Component {
  render(){
  return (
    <>
            <Row>
            {
                  this.props.sendInfoProps.dataFridge.map((value,index)=>{
                            return (
                                     <Col md="12" className="text-center">
                                     <SectionModalFridge getIdFridge={value.id} getIndex={index} setParamsUrl={this.props.setParamsUrl} dataFridgeExtend={this.props.sendInfoProps.dataFridgeAll} />
                                     </Col>
                                    ) 
                     })
           }
            </Row>

    </>
  );
 }
}

 export class SectionModalFridge extends React.Component {

      state={
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    data_lote:[],
    dsableBtn:false,
    dsableBtnTrans:false,
    dsableBtnTask:false,
    data_trnsport:[]
  }
    async componentDidMount(){
        this.setInfoFridge(this.props.getIdFridge)
  }
      setDataCamposEmpty = ()=>{

      }
          setDataCampos = (data,dataTrazabilidadAnimal,dataLote,dataTransport)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA")
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
     if (data.fridge!=undefined) {
        this.setState({
                fridge_id:(data.fridge.id=="null")?(null):(data.fridge.id),
                nombre_frigorifico:(data.fridge.nombre_frigorifico=="null")?(null):(data.fridge.nombre_frigorifico),
                registro_numero:(data.fridge.registro_numero=="null")?(null):(data.fridge.registro_numero),
                pais:(data.fridge.pais=="null")?(null):(data.fridge.pais),
                ciudad:(data.fridge.ciudad=="null")?(null):(data.fridge.ciudad),
                estado:( data.fridge.estado=="null")?(null):( data.fridge.estado),
                georeferencia:(data.fridge.georeferencia=="null")?(null):(data.fridge.georeferencia),
                informacion_adicional_fridge:(data.fridge.informacion_adicional=="null")?(null):(data.fridge.informacion_adicional),
                file_document_fridge:(data.fridge.archivo=="null")?(null):(data.fridge.archivo),
        })
      }else{
              this.setState({
                fridge_id:'noind',
                nombre_frigorifico:'',
                registro_numero:'',
                pais:'',
                ciudad:'',
                estado:'',
                georeferencia:'',
                informacion_adicional_fridge:'',
                file_document_fridge:undefined,
            })

      }

      if (data.received!=undefined) {
        this.setState({
                data_lote:dataLote.trazaDataLote,
                data_trnsport:dataTransport.dataLogisticsTransport,
                fridge_received_id:(data.received.id=="null")?(null):(data.received.id),
                nombre_finca:dataTrazabilidadAnimal.nombre_finca,
                lote_numero_received:(data.received.lote_numero=="null")?(completeDate):(data.received.lote_numero),
                guia_numero:(data.received.guia_numero=="null")?(null):(data.received.guia_numero),
                precinto_numero:(data.received.precinto_numero=="null")?(null):(data.received.precinto_numero),
                cert_inspeccion_nro:(data.received.cert_inspeccion_nro=="null")?(null):(data.received.cert_inspeccion_nro),
                fecha_recibido:(data.received.fecha_recibido=="null")?(null):(data.received.fecha_recibido),
                hora_recibido:(data.received.hora_recibido=="null")?(null):(data.received.hora_recibido),
                cantidad_animales_received:(data.received.cantidad_animales=="null")?(null):(data.received.cantidad_animales),
                peso_total:(data.received.peso_total=="null")?(null):(data.received.peso_total),
                lavado_desinfeccion:(data.received.lavado_desinfeccion=="null")?(null):(data.received.lavado_desinfeccion),
                tratamiento_aplicado_received:(data.received.tratamiento_aplicado=="null")?(null):(data.received.tratamiento_aplicado),
                informacion_adicional_received:(data.received.informacion_adicional=="null")?(null):(data.received.informacion_adicional),
                file_document_received:(data.received.file_document=="null")?(null):(data.received.file_document),
        })
      if (data.received.lote_numero=="null" || data.received.lote_numero==null || data.received.lote_numero=="0") {
        this.setState({cantidad_animales_received:data.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales_received:data.cantidad_animales,
          dsableBtn:true
        })
      }
      if (data.received.guia_numero=="null" || data.received.guia_numero==null || data.received.guia_numero=="0") {
                this.setState({precinto_numero:data.precinto_numero})
      }else{
                this.setState({
                  precinto_numero:data.precinto_numero,
                  dsableBtnTrans:true
                })
      }
      }else{
            this.setState({
                data_lote:dataLote.trazaDataLote,
                data_trnsport:dataTransport.dataLogisticsTransport,
                fridge_received_id:'noind',
                nombre_finca:dataTrazabilidadAnimal.nombre_finca,
                lote_numero_received:'',
                guia_numero:'',
                precinto_numero:'',
                cert_inspeccion_nro:'',
                fecha_recibido:completeDate,
                hora_recibido:timeString,
                cantidad_animales_received:'',
                peso_total:'',
                lavado_desinfeccion:'',
                tratamiento_aplicado_received:'',
                informacion_adicional_received:'',
                file_document_received:undefined,
            })

            this.setState({
                fecha_carga:completeDate,
            })
      }
            if (data.task!=undefined) {
        this.setState({
                fridge_task_id:(data.task.id=="null")?(null):(data.task.id),
                lote_numero_task:(data.task.lote_numero=="null")?(null):(data.task.lote_numero),
                fecha_faena:(data.task.fecha_faena=="null")?(null):(data.task.fecha_faena),
                hora_faena:(data.task.hora_faena=="null")?(null):(data.task.hora_faena),
                cantidad_animales_task:(data.task.cantidad_animales=="null")?(completeDate):(data.task.cantidad_animales),
                lavado_faena:(data.task.lavado_faena=="null")?(null):(data.task.lavado_faena),
                tratamiento_aplicado_task:(data.task.tratamiento_aplicado=="null")?(null):(data.task.tratamiento_aplicado),
                informacion_adicional_task:(data.task.informacion_adicional=="null")?(null):(data.task.informacion_adicional),
                file_document_task:(data.task.file_document=="null")?(null):(data.task.file_document),
        })
      if (data.task.lote_numero=="null" || data.task.lote_numero==null || data.task.lote_numero=="0") {
        this.setState({cantidad_animales_task:data.cantidad_animales})
      }else{
        this.setState({
          cantidad_animales_task:data.cantidad_animales,
          dsableBtnTask:true
        })
      }
      }else{
            this.setState({
                fridge_task_id:'noind',
                lote_numero_task:'',
                fecha_faena:completeDate,
                hora_faena:timeString,
                cantidad_animales_task:'',
                lavado_faena:'',
                tratamiento_aplicado_task:'',
                informacion_adicional_task:'',
                file_document_task:undefined,
            })

      }


    }
       setInfoFridge = async (id_set) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = this.props.setParamsUrl
              var dataTransExportLogisc = await data_logic_fridge_task_received(this.props.dataFridgeExtend,id_set)
              var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
              var dataLote = await row_traza_lote(jsondata._id,gerParam,codigoTrazaTwo)
              var dataTrazabilidadAnimal = await data_trazabilidad_meat(jsondata._id,gerParam,codigoTrazaTwo)
              var dataTransport = await row_transport_logistics(jsondata._id,gerParam,codigoTrazaTwo)
            this.setDataCampos(dataTransExportLogisc,dataTrazabilidadAnimal,dataLote,dataTransport)
          /*if (dataTransExportLogisc==null) {
            this.setDataCamposEmpty()
          }else{

          }
      }else{
            this.setDataCamposEmpty()
      }*/

  }
  render(){
    return (<>
        <Col md="12" className="mb-2 mt-4">
            <h3 className="mb-0">
             Datos Frigorifico {this.props.getIndex+1}
            </h3>
        </Col>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Nombre del Frigorifico
                  </label>
                  <br /><span>
                    {(this.state.nombre_frigorifico=="undefined")?(null):(this.state.nombre_frigorifico)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Registro Numero
                  </label>
                  <br /><span>
                  {(this.state.registro_numero=="undefined")?(null):(this.state.registro_numero)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Pais
                  </label>
                  <br /><span>
                    {(this.state.pais=="undefined")?(null):(this.state.pais)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Ciudad
                  </label>
                  <br /><span>
                    {(this.state.ciudad=="undefined")?(null):(this.state.ciudad)}"
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                   Estado
                  </label>
                  <br /><span>
                    {(this.state.estado=="undefined")?(null):(this.state.estado)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Georeferencia
                  </label>
                  <br /><span>
                    {(this.state.georeferencia=="undefined")?(null):(this.state.georeferencia)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <br /><span>
                    {(this.state.informacion_adicional_fridge=="undefined")?(null):(this.state.informacion_adicional_fridge)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label><br />
                {(this.state.file_document_fridge==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_fridge}>{String(this.state.file_document_fridge).split('/').pop()}</a>)}

              </Col>
            </Row>



          <Row style={{display:(this.state.guia_numero=='')?('none'):('')}}>
            <Col md={12}>
              <h3 className="mb-0">Información del Producto Recibido</h3>
            </Col>
            </Row>
              <Row style={{display:(this.state.guia_numero=='')?('none'):('')}}>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Nombre de la finca
                    </label>
                    <br /><span>
                    ed
                    </span>
                  </FormGroup>
                </Col>
<Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label>
                  <br /><span>
                    {this.state.lote_numero_received}
                  </span>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Guía Numero
                    </label>
                    <br /><span>
                    {this.state.guia_numero}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Precinto Numero
                    </label>
                    <br /><span>
                    {this.state.precinto_numero}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cert. Inspeccion Nro
                    </label>
                    <br /><span>
                    {this.state.cert_inspeccion_nro}
                    </span>
                  </FormGroup>
                </Col>
                
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Recibido
                  </label>
                  <br /><span>
                    {(this.state.fecha_recibido=="undefined")?(null):(this.state.fecha_recibido)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Recibido
                  </label>
                  <br /><span>
                    {(this.state.hora_recibido=="undefined")?(null):(this.state.hora_recibido)}
                    </span>
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <br /><span>
                    {this.state.cantidad_animales_received}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Peso Total Est.
                    </label>
                    <br /><span>
                    {this.state.peso_total}
                    </span>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                 Lavado y desinfeccion
                  </label>
                  <br /><span>
                    {(this.state.lavado_desinfeccion=="undefined")?(null):(this.state.lavado_desinfeccion)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Tratamiento aplicado
                  </label>
                  <br /><span>
                    {(this.state.tratamiento_aplicado_received=="undefined")?(null):(this.state.tratamiento_aplicado_received)}
                  </span>
                </FormGroup>
              </Col>
                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <br /><span>
                      {this.state.informacion_adicional_received}
                    </span>
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                   Documento
                  </label><br />
                {(this.state.file_document_received==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_received}>{String(this.state.file_document_received).split('/').pop()}</a>)}
                </Col>
              </Row>

          <Col md="12" style={{display:(this.state.fecha_faena=='')?('none'):('')}}>
            <h3 className="mb-0">Información de la Faena</h3>
          </Col>
            <Row style={{display:(this.state.fecha_faena=='')?('none'):('')}}>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Numero
                    </label>
                    <br /><span>
                      {this.state.lote_numero_task}
                    </span>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de la Faena
                  </label>
                  <br /><span>
                    {(this.state.fecha_faena=="undefined")?(null):(this.state.fecha_faena)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de la Faena
                  </label>
                  <br /><span>
                    {(this.state.hora_faena=="undefined")?(null):(this.state.hora_faena)}
                  </span>
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Animales
                    </label>
                    <br /><span>
                    {this.state.cantidad_animales_task}
                    </span>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                 Lavado Pos Faena
                  </label>
                  <br /><span>
                    {(this.state.lavado_faena=="undefined")?(null):(this.state.lavado_faena)}
                  </span>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Tratamiento aplicado
                  </label>
                  <br /><span>
                    {(this.state.tratamiento_aplicado_task=="undefined")?(null):(this.state.tratamiento_aplicado_task)}
                  </span>
                </FormGroup>
              </Col>

              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <br /><span>
                    {(this.state.informacion_adicional_task=="undefined")?(null):(this.state.informacion_adicional_task)}
                    </span>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Documento
                </label>
                <br />
                {(this.state.file_document_task==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_task}>{String(this.state.file_document_task).split('/').pop()}</a>)}
              </Col>
            </Row></>
            )
  }
}
