import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";
import { useLocation } from "react-router-dom";
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';

import VegFruitsProducersKdes from "views/pages/forms/Trazability/FrutasYVegetalesKdes/VegFruitsProducersKdes.js";
class CardElement extends React.Component {
  state={
    selectedProdItems:null,
    contentActivity:null,
    visibleRight:false
  }
    render () {
    
    const itemsProd = [
        { name: 'Recibido', code: 'IO', table: 'create_plant_receiver_kdes', content: VegFruitsProducersKdes, permission_column: 'planta', typeRole:'V1F' },
        { name: 'Enfriamiento', code: 'IC', table: 'create_plant_cooling_kdes', content: VegFruitsProducersKdes, permission_column: 'planta', typeRole:'V1F' },
        { name: 'Empaque Inicial', code: 'IC', table: 'create_plant_product_kdes', content: VegFruitsProducersKdes, permission_column: 'planta', typeRole:'V1F' },
        { name: 'Envío', code: 'IC', table: 'create_plant_shipping_kdes', content: VegFruitsProducersKdes, permission_column: 'planta', typeRole:'V1F' },
    ];

    const header = (
        <img 
            alt="production"
            style={{filter: 'invert(75%) sepia(6%) saturate(4859%) hue-rotate(48deg) brightness(106%) contrast(62%)' }}
            src="/layout/images/icons/caja.png"  
            className='w-2'
        />
    );

    const selectedItemTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const itemOptionTemplate = (option) => {
        return (
            <div 
                    className="flex align-items-center">
                <div>
                    {option.name}
                </div>
            </div>
        );
    };
    return (
        <div>
            <Card title="Empaque" header={ header } >

                <Dropdown 
                    value={this.state.selectedProdItems} 
                    onChange={(e) => { 
                        this.setState({selectedProdItems:e.value})
                        if (e.value!=undefined) {
                            this.setState({contentActivity: e.value.content })
                            this.setState({visibleRight:true}) 
                        }
                    }}
                    options={ itemsProd } 
                    optionLabel="name" 
                    showClear 
                    placeholder="Selecciona" 
                    className="w-full p-inputtext-sm" 
                    itemTemplate={ itemOptionTemplate } 
                    dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                    }}
                />
                    <p className="mt-4">
                        {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                    </p> 
            </Card>
              <SidebarCustom 
                  onVisible={this.state.visibleRight}
                  onHide={() => this.setState({visibleRight:false}) }
                  onWidth={ 800 }
                  position="right" 
                  title={this.state.selectedProdItems?.name}
                  >
                    {
                        (this.state.contentActivity==null)
                        ?
                        ('Contenido.')
                        :
                        (<this.state.contentActivity 
                            {...this.props} 
                            info_select={this.state.selectedProdItems}
                            //info_select={selectedOptions}
                            //location={useLocation()}
                            hide={ () => this.setState({visibleRight:false}) }
                            />)
                    }
              </SidebarCustom>

        </div>
    )
  }
}

export default CardElement;