import React, {useState} from 'react';
import {
//  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Button } from 'primereact/button';
import jQuery from 'jquery';
import SimpleHeaderProd from "components/Headers/SimpleHeaderProd.js";
import GrainNamaNavBar from "views/pages/components/GrainNamaNavBar.js";
import FinalNama from "./FinalNama/FinalNamaGrain";
import { generate_trazabilidad_grain, get_data_row_admin_grain } from "network/ApiAxios";
import { generateChain } from "network/GenerateTokenChain";
import { NavLink } from "react-router-dom";
import $ from 'jquery'
import QRCode from "react-qr-code";
import {UserContext} from "layouts/store";
import './style.css';
import Web3 from 'web3';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import buffer from 'buffer';
import jsPDF from 'jspdf';
import { Panel } from 'primereact/panel';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

var web3 = new Web3('https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/')

var tituloGlobal=[];
export  class GNamaFinal extends React.Component {
  static contextType = UserContext
  state={
    collapse:[],
    displayCard:"none",
    dataQr:"Sin datos",
    loading:'Cargando...',
    collapsefuve:true,
    idTraza:null,
    codigoTrza:null,
    routeget: new URLSearchParams(window.location.search).get("code"),
    routegetTwo: new URLSearchParams(window.location.search).get("up"),
    loadingupload:'none',
    btnend:'block',
    'info_producer_g':'none',
    fordisplay:{},
    walletData:null,
    openModal:false,
    disabledbtn:false,
  }
  async componentDidMount(){
   // tituloGlobal = [
   //  {Titulos:'Producción: Datos',fordisplay: 'producciondatos', Componente:<ProduccionDatos paramsCode={this.state.routeget} />},
   //  {Titulos:'Producción: Fertilizantes / Agroquímicos',fordisplay: 'produccionagroquimicoview', Componente:<ProduccionAgroquimicoView paramsCode={this.state.routeget} />},
   //  {Titulos:'Cosecha: Datos Cosecha',fordisplay: 'cosechadatos', Componente:<CosechaDatos paramsCode={this.state.routeget} />},
   //  {Titulos:'Cosecha: Datos Transporte de Campo',fordisplay: 'cosechatransportecampoview', Componente:<CosechaTransporteCampoView paramsCode={this.state.routeget} />},
   //  {Titulos:'Planta: Datos de planta',fordisplay: 'plantadatosview', Componente:<PlantaDatosView paramsCode={this.state.routeget} />},
   //  {Titulos:'Planta: Información de Empaque',fordisplay: 'plantaempaqueview', Componente:<PlantaEmpaqueView paramsCode={this.state.routeget} />},
   //  {Titulos:'Planta: Enfriamiento',fordisplay: 'plantacamarafrio', Componente:<PlantaCamaraFrio paramsCode={this.state.routeget} />},
   //  {Titulos:'Logistica y Transporte',fordisplay: 'logisticatransporte', Componente:<LogisticaTransporte paramsCode={this.state.routeget} />},
   //  {Titulos:'Comercialización',fordisplay: 'comercializa', Componente:<Comercializa paramsCode={this.state.routeget} />}];
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var status='';
    
    var walletdata = localStorage.getItem('user_wallet');
    var jsondatawallet = JSON.parse(walletdata);
    this.setState({walletData:jsondatawallet})
      var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
      var dataRow = await get_data_row_admin_grain(jsondata._id,this.state.routeget,codigoTrazaTwo,null,'prod_grain')
      Object.entries(dataRow['end_data_grain']).map((a,b)=>{
        var name_nama = null
        if(a[0]=='info_producer_g'){
          this.setState({info_producer_g:(a[1].length==0)?'none':'flex'})
          name_nama = 'Información Productor'
        }
        if(a[0]=='info_crops_g'){
          name_nama = 'Info del Cultivo'
        }
        if(a[0]=='info_prev_g'){
          name_nama = 'Gestión de cultivos Previa'
        }
        if(a[0]=='production_land_preparation_g'){
          name_nama = 'Preparación de tierra'
        }
        if(a[0]=='production_irrigation_g'){
          name_nama = 'Riego'
        }
        if(a[0]=='production_seed_g'){
          name_nama = 'Semilla'
        }
        if(a[0]=='production_fertilizer_g'){
          name_nama = 'Fertilización/Agroquímicos'
        }
        if(a[0]=='production_sowing_g'){
          name_nama = 'Siembra'
        }
        if(a[0]=='production_sowing_transplant'){
          name_nama = 'Transplante'
        }
        if(a[0]=='production_producer_monitoring_g'){
          name_nama = 'Monitoreo Productor'
        }

        // if(a[0]=='production_info_satelite_dni_code'){
        //   name_nama = 'Satelite'
        // }
        if(a[0]=='hading_fert_agro'){
          name_nama = 'Información de Agroquimicos'
        }
        if(a[0]=='hading_info_weeds'){
          name_nama = 'Información de malezas'
        }
        if(a[0]=='hading_inventory_weeds'){
          name_nama = 'Inventario de malezas'
        }
        if(a[0]=='hading_info_plague'){
          name_nama = 'Información de Plagas'
        }
        if(a[0]=='hading_inventory_plague'){
          name_nama = 'Inventario de Plagas'
        }
        if(a[0]=='hading_info_diseases'){
          name_nama = 'Información de enferedades'
        }
        if(a[0]=='harvest_info_harvest'){
          name_nama = 'Información de cosecha'
        }
        if(a[0]=='harvest_info_machinery'){
          name_nama = 'Calibración de maquinaria'
        }
        if(a[0]=='harvest_info_transport'){
          name_nama = 'Información de transporte'
        }
        if(a[0]=='harvest_info_pos_harvest'){
          name_nama = 'Información de Pos-Cosecha'
        }
        if (a[0]!='production_info_satelite_ndre' && a[0]!='production_info_satelite_ndmi' && a[0]!='production_info_satelite_ndhs' && a[0]!='production_info_satelite') {
          tituloGlobal[b] = {Titulos:name_nama,fordisplay: a[0], Componente:<FinalNama 
            title={name_nama} 
            table_ex={a[0]} 
            getData={this.getDataForqr} 
            putDataAll={a[1]} 
            dbCode={this.props.location?.state} 
            fullData={dataRow.end_data_grain} 
            fullDataSatelite={dataRow.satelite} />}
        }

        this.setState({fordisplay:{...this.state.fordisplay,[a[0]]:(a[1].length==0)?'none':'block'} })
      })

  }
  componentWillMount(){
    tituloGlobal = []
  }
  getDataForqr = (data) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (data==null) {
      this.setState({
        loading:'Sin gestión de cultivos.'
      })
    }else{
      if (this.state.routeget==null || this.state.routeget==undefined) {
        var codigo_set = 'FCTGN-'+jsondata._id+data.id
      }else{
        var codigo_set = this.state.routeget
      }
      this.setState({
        displayCard:"flex",
        dataQr:"http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code="+codigo_set+"&db="+jsondata.database,
        loading:null,
        idTraza:data.id,
        codigoTrza:codigo_set,
      })
    }
  }
 printer_pdf = async () => {
  window.scrollTo({ top:0, left:0, behavior: "instant"})

  //console.log('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous">')
             // await jQuery('.collapseIdewntifier').removeClass('collapse')
             // var counterCollapse=0
             // jQuery('.collapseIdewntifier').map((a,b)=>{
             //  if (jQuery(b).hasClass('collapse')) {
             //    counterCollapse=counterCollapse
             //    counterCollapse++
             //  }
             // })

             var component = await document.querySelector(".contentBlockChain").innerHTML
             var divContents = component

             //console.log(window.html2pdf)
            window.html2pdf().from(divContents).toPdf().get('pdf').then(function (pdf) {
              window.open(pdf.output('bloburl'), '_blank');
            });
             //window.html2pdf(divContents);

            // var doc = new jsPDF({
            //   orientation: 'portrait' ,
            //   unit: 'px',
            //   format: 'A3',
            // });
            // doc.setFont('Inter-Regular', 'normal');
            // this.setState({btnd:true})
            // var thisState = this
            // doc.html(divContents, {
            //   async callback(doc) {
            //     var get_blob = await doc.output('bloburl')
            //     window.open(get_blob,'_blank')
            //     thisState.setState({btnd:false})
            //     jQuery('.collapseIdewntifier').addClass('collapse')
            //   },
            //     x: 0,
            //     y: 0,
            //     width: 640,
            //     windowWidth: 1100
            // });
}
render(){
  const getRoutesComponents = () =>{

  }
  const toggleFunctionFirst = () => {
      if (this.state.collapsefuve==true) {
        this.state.collapsefuve = false;
      }else{
        this.state.collapsefuve = true;
      }
  }
  const toggleFunction = (index,res) =>{
    if (res==undefined) {
    this.state.collapse[index] = true;
    }else{
      if (this.state.collapse[index]==true) {
        this.state.collapse[index] = false;
      }else{
        this.state.collapse[index] = true;
      }
    }
  }
  const saveInfoTraza = async() =>{
    if(window.confirm('Estas seguro en finalizar esta gestión de cultivos?')){
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var result = await generate_trazabilidad_grain(jsondata._id,this.state.idTraza,this.state.codigoTrza,'P');
      if (result==null) {
        return
      }
        if (result.data.message=="exito") {
          this.context.setToastMess("success", "¡Registrado exitosamente!", 'Gestión de cultivos finalizada.')
          //notify();
          
          var thisFVar = this
          setTimeout(function(){thisFVar.props.history.push("/admin/finalized-production")}, 2000);
        }else{
          this.context.setToastMess("danger", "¡Registrado Fallido!", result.data)
          //notify();
          this.setState({btnd:false})
        }
    }
  }
  const saveInfoTrazaQr =  async () =>{
      if (this.state.walletData==null) {
        this.props.history.push("/admin/wallet")
    }else{
      if (this.state.walletData.crypto!=null) {
        optionModal(true)
      }else{
       if(window.confirm('Estas seguro en finalizar esta gestión de cultivos?')){
         jQuery("#loadingupload").show();
         jQuery(".btnend").hide()
         var userdata = localStorage.getItem('user');
         var jsondata = JSON.parse(userdata);
         var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
         jQuery('.collapseIdewntifier').removeClass('collapse')
         var data = await generateChain(jsondata._id,this.state.routeget,this.props.history,this.state.idTraza,codigoTrazaTwo,this.state.walletData,'P')
       }
      }
    }
  }
    const optionModal = (type) => {
    this.setState({openModal:type})
  };
  const accessAccount = (password) => {
    this.setState({disabledbtn:true})
    try{
        this.setState({disabledbtn:false})
        var privateWalletEncrip = web3.eth.accounts.decrypt(this.state.walletData,password)
        this.setState({walletData:privateWalletEncrip})
        optionModal(false)
        this.context.setToastMess("success", "Cambio exitoso", "Cuenta desbloqueada.")
        //notify();
    }catch(error){
        this.context.setToastMess("danger", "No se pudo guardar", "La contraseña es incorrecta.")
        //notify();
        this.setState({disabledbtn:false})
    }


  };
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    const accept = () => {
      saveInfoTraza()
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Cancelado', life: 3000 });
    }
    const confirm1 = () => {
        confirmDialog({
            message: 'Estas seguro en guardar esta información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept,
            reject
        });
    };
    const confirmBlock = () => {
        confirmDialog({
            message: 'Estas seguro en guardar esta información en la blockchain?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => saveInfoTrazaQr(),
            reject
        });
    };
    
    const headerTemplate = (title,options) => {
        const className = `${options.className} justify-content-space-between text-white`;

        return (
            <div className={className} style={{ background: '#7ac142', border: '1px', borderStyle: 'solid', borderColor: '#002a5c', padding: '0px', paddingLeft: '5px' }}>
                <div className="flex align-items-center gap-2">
                    <span className="font-bold">{title}</span>
                </div>
                <div>
                    {options.togglerElement}
                </div>
            </div>
        );
    };
const contentBtn = []
if (this.state.info_producer_g != 'none') {
  if (this.state.routegetTwo!='uploadblock') {
    contentBtn[0] = <>
      <div className='col-md-1 flex justify-content-end'>
          <span className="p-buttonset">
              <Button 
              tooltip="Finalizar esta gestión de cultivos" 
              onClick={confirm1} 
              className='button-color-fod-blue' 
              icon="pi pi-save" 
              size='small' />
          </span>
      </div>
    </>
  }
    contentBtn[1] = <>
      <div className='col-md-1 flex justify-content-end'>
          <span className="p-buttonset">
              <Button 
              tooltip={(this.state.routegetTwo=='uploadblock')?('Guardar esta gestión de cultivos en la Blockchain'):('Finalizar y Guardar esta gestión de cultivos en la Blockchain')} 
              onClick={confirmBlock} 
              className='button-color-fod-blue' 
              icon="pi pi-cloud-upload" size='small' />
          </span>
      </div>
    </>
    contentBtn[2] = <>
      <div className='col-md-1 flex justify-content-end'>
          <span className="p-buttonset">
              <Button tooltip="Generar PDF" onClick={this.printer_pdf} className='button-color-fod-blue' icon="pi pi-file-pdf" size='small' />
          </span>
      </div>
    </>
}

  return (
    <>
    <ConfirmDialog />
    <ModalEditUserWallet
      sendOpenModal={this.state.openModal}
      setModal={optionModal}
      saveEncrypt={accessAccount} 
      disabledextend={this.state.disabledbtn} />

          <div className="mt-1">
              <div className="card">
                <div className="grid">
                    <div className='col-8'>
                        <h5 className='font-semibold'>
                            Vista de Verificación y Confirmación para Finalizar
                        </h5>
                    </div>
                    {contentBtn}
                </div>
              <div className="contentBlockChain">
                  { tituloGlobal.map((value,index)=>{
                  return (
                            <div className="field col-12" style={{display: this.state.fordisplay[value.fordisplay]}}>
                                <Panel headerTemplate={headerTemplate.bind(this,value.Titulos)} toggleable={true} collapsed={false}>
                                            <div className="text-center">
                                              {value.Componente}
                                            </div>
                                </Panel>
                            </div>
                           )  
                         })
                        }
                      {(this.state.loading==null)?(null):(
                        <Card className="mb-4">
                          <CardHeader id={"sectionnull"}>
                            <a style={{color: "#32325d",fontWeight:"600"}} href={"#sectionnull"} className="mb-0">{this.state.loading}</a>
                          </CardHeader>
                        </Card>
                        )}
              </div>
              <div className="grid">
              {contentBtn}
              </div>
            </div>
      
            </div>
    </>
  );
 }
}

export class ModalEditUserWallet extends React.Component {
  state={
    password:''
      }
render(){
  return (
  <Modal isOpen={this.props.sendOpenModal} size="md">
        <ModalBody>
          <CardHeader>
            <Row>
            <Col md={11}>
            <h3 className="mb-0">Desbloquear Cuenta</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {this.props.setModal(false)} }>
              <h3 className="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>

        <Card className="mb-4">
                <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                    placeholder="Escribe una contraseña para encryptar"
                    type="password"
                  />
                </FormGroup>
              </Col>
          <CardBody>
            <Row>
              <Col md="5">
                <Button onClick={()=>{this.props.saveEncrypt(this.state.password)}} disabled={this.props.disabledextend} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Aceptar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.props.setModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
  );
 }
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
      this.setState({get_permission:false})
      return;
    }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.information_nama_g==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <GNamaFinal {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
