const config = {
	// API_URL: 'https://api2.foodchaintrazabilidad.com/developer/foodchain-back/public/api',
	// URL_PRODUCTION: 'https://api2.foodchaintrazabilidad.com/developer/foodchain-back/public',

	// API_URL: 'https://api2.foodchaintrazabilidad.com/api',
	// URL_PRODUCTION: 'https://api2.foodchaintrazabilidad.com',

    //API_URL: 'http://localhost:8000/api',
    
 //    API_URL: 'https://api.foodchaintrazabilidad.com/developer/foodchain-back/public/api',
	// URL_PRODUCTION: 'https://api.foodchaintrazabilidad.com/developer/foodchain-back/public',

	API_URL: 'https://api.foodchaintrazabilidad.com/api',
	URL_PRODUCTION: 'https://api.foodchaintrazabilidad.com',

    //API_URL_FOOD: 'https://api.foodchaintrace.com/api/v1',
    //DOMAIN_NAME: 'https://www.foodchaintrace.com',
    //DEMO: false
}

export default config;
