import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import React from "react";
import IndexNavbar from "components/Navbars/NavbarTrace.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import { get_data_row_admin_grain_vt } from "network/ApiAxios";
import SectionTrazaG from "views/pages/examples/Trazability/SectionViewTraceGT/SectionTrazaGView";
import SectionTrazaGP from "views/pages/examples/Trazability/SectionViewTraceGT/SectionTrazaGPView";
import SectionTrazaGH from "views/pages/examples/Trazability/SectionViewTraceGT/SectionTrazaGHView";
import SectionDocuments from "views/pages/examples/Trazability/SectionViewTraceGT/SectionTrazaDocumentView";

import { NavLink as ComponentLink } from "react-router-dom";
import {GuideMessage} from "views/pages/components/GuideMessage";

import "./StyleCss.css";
import informacion from "assets/img/icons/Informacion2.png";
import Produccion from "assets/img/icons/Produccion2.png";
import Fitosanitario from "assets/img/icons/Fitosanitario2.png";
import Cosecha from "assets/img/icons/Cosecha2.png";
import ImgMVR from "assets/img/icons/mvrimg.png";
import Folder from "assets/img/icons/Folder2.png";
export default class VistaTrace extends React.Component {
	state ={
		statusClass:[],
		statusColor:[],
		allDataTrza:null,
    	allDataSatelite:null,
		code_traza:'Cargando...',
		data_user:null,
		table_nama_section:[],
		components:SectionTrazaG,
    	dataDocuments:{}
	}
	async componentDidMount(){
		var gerParam = new URLSearchParams(this.props.location.search).get("code");
		if (gerParam==null) {
			this.setState({code_traza:'Sin Trazabilidad'});
		}else{
		this.setNameDB(gerParam)
		}
	}
	configAllDataStatus = (dataRow) => {
if (dataRow==undefined) {
	var data_array_get = []
}else{
	var data_array_get = Object.keys(dataRow)
}
data_array_get.map((a,b)=>{
 			 if (dataRow[a]['data_array'].length==0) {
 			 	this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
 				this.setState({statusColor:{...this.state.statusColor,[a]:'#7a7a7a78'}});
 			 }else{
 			 	  dataRow[a]['data_array'].map((value,index)=>{
				 	if (value.status=='finalizado') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-check-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#58d99978'}});
				 	}
				 	if (value.status=='desarrollo') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#f8d01478'}});
					}
 			 	  })
 			 }
 })

	}
	setNameDB = async(data) =>{
		this.setState({code_traza:data+':'});
		var dataRow = await get_data_row_admin_grain_vt(data,null,'.grain_info_crops_t')
		this.configAllDataStatus(dataRow.data.data_traza_row)
		this.setState({allDataTrza: dataRow.data.data_traza_row})
		this.setState({allDataMVR: dataRow.data.data_mvr})
    this.setState({allDataSatelite: dataRow.data.satelite})
		this.setState({data_user:dataRow.data.data_user});
		this.setState({dataDocuments:dataRow.data.documents})
		if (dataRow.data=='Sin trazabilidad') {
			return
		}
	}
		pushSection = (components) => {
			this.setState({components:components})
			
		}
	render(){
  return (
    <>
      <div className="main-content pt-1" style={{backgroundColor: 'white'}}>
          	<Col md="10" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                <Row>
                <Col md={4}>
	                <Row>
		              <div style={{width:'100px',paddingLeft:'15px'}}>
	                  	<h3 className="mb-0" style={{color:'#ffffff'}}>Producción</h3>
					  </div>
	                  <div>
	             	  	<GuideMessage section="fuits_vegetable" section_two="Trace" section_table_form="null" />
	                  </div>
	                </Row>
                </Col>
				  <div className="col-md-4">
	               <h3 className="mb-0" style={{color:'#ffffff', textAlign:'center'}}>{(this.state.data_user==null)?(null):(' '+this.state.data_user.name+', '+this.state.data_user.second_name)}</h3>
				  </div>
                  <div className="col-md-4">
                	<h3 className="mb-0" style={{color:'#ffffff', textAlign:'end'}}>{this.state.code_traza}</h3>
                  </div>
                </Row>
                </CardHeader>
              </Card>
            </Col>
		<section className="elementor-section elementor-top-section elementor-element elementor-element-ddc63bc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddc63bc" data-element_type="section">
		  <div className="elementor-container elementor-column-gap-default">
		    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70ec96d" data-id="70ec96d" data-element_type="column">
		      <div className="elementor-widget-wrap">
		  	  </div>
		    </div>
		  </div>
	    </section>
    			<section style={{backgroundColor: 'white'}} className="elementor-section elementor-top-section elementor-element elementor-element-9f9c7a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9f9c7a0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
          		<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4d21c55" data-id="4d21c55" data-element_type="column">
      			<div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-1a59632 elementor-widget elementor-widget-eael-adv-tabs" data-id="1a59632" data-element_type="widget" data-widget_type="eael-adv-tabs.default">
        		<div className="elementor-widget-container">
              	<div id="eael-advance-tabs-1a59632" className="eael-advance-tabs eael-tabs-horizontal" data-tabid="1a59632">
            	<div className="eael-tabs-nav">
                <ul className="eael-tab-top-icon">
                <Col id="infprod" aria-selected="false" data-tab="1" role="tab" aria-controls="infprod-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionTrazaG)}} className="eael-tab-item-trigger">
	                <img src={informacion} alt="Logo" style={{mixBlendMode: 'multiply'}} />
	                </li>
	                <span className="eael-tab-title title-after-icon">Información General</span>
                </Col>

                <Col id="produccion" aria-selected="false" data-tab="2" role="tab"  aria-controls="produccion-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
	                onClick={()=>{this.pushSection(SectionTrazaGP)}} className="eael-tab-item-trigger">
	                <img src={Produccion} alt="Logo" style={{mixBlendMode: 'multiply'}} />
	                </li>

	                <span className="eael-tab-title title-after-icon">Producción</span>
                </Col>

                <Col id="harvinf" aria-selected="false" data-tab="5" role="tab"  aria-controls="harvinf-tab" aria-expanded="false" className='text-center'>
                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor:'#f8d01400'}} 
                onClick={()=>{this.pushSection(SectionTrazaGH)}} className="eael-tab-item-trigger">
                	<img src={Cosecha} alt="Logo" style={{mixBlendMode: 'multiply'}} />
                </li>
                	<span className="eael-tab-title title-after-icon">Cosecha</span>
                </Col>

                <Col id="documentos" aria-selected="false" data-tab="7" role="tab"  aria-controls="documentos-tab" aria-expanded="false" className='text-center'>
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto'}} 
	                onClick={()=>{this.pushSection(SectionDocuments)}} className="eael-tab-item-trigger">
	                <img src={Folder} alt="Logo" style={{mixBlendMode: 'multiply'}} />
					</li>
	                <span className="eael-tab-title title-after-icon">Documentos</span>
                </Col>

                </ul>
                </div>
                <hr />
            {(this.state.allDataTrza==null)?('Contenido.'):(< this.state.components DataRow={this.state} />)}
        </div>
    </div>
        </div>
          </div>
    </div>
    </section>

    </div>
    </>
  );
 }
}