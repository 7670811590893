import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
//filter: hue-rotate(28deg);
import config from "config";
import {PutContentFileG} from 'views/pages/examples/Trazability/SectionsViewTrace/InfoLogistic'

export default class SectionDocuments extends React.Component {
  async componentDidMount(){
  }
  contentRows = () =>{
    const datacontent = [
    {table:'grain_info_crops_t',title:'Info del Cultivo'},
    {table:'grain_seed_t',title:'Semilla'},
    {table:'grain_fertilizer_t',title:'Fertilización'},
    {table:'grain_fert_agro_t',title:'Agroquimico'},
    {table:'grain_harvest_info_transport_t',title:'Info de transporte'},
    {table:'grain_harvest_info_pos_harvest_t',title:'Info de Pos-Cosecha'},
    ]
    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
                      <Card key={indx} style={{width: '100%'}}>
                      <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- {val.title} </span></CardHeader>
                      <Col md={12}>
                      <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="row">
                                    <PutContentFileG
                                    data_function={this.props.DataRow} 
                                    type_function={val.table}
                                    />
                      </div>
                      </CardBody>
                      </Col>
                      </Card>
                  </>
    })
    return content
  }
  render(){
  return (
    <>
    <div id="documentos-tab" className="clearfix eael-tab-content-item active" data-title-link="documentos-tab">
    <div data-elementor-type="section" data-elementor-id="5570" className="elementor elementor-5570">
    <div className="elementor-section-wrap">
    <section className="elementor-section elementor-top-section elementor-element elementor-element-1fac86c8 elementor-section-content-middle animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="1fac86c8" data-element_type="section" id="services" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-497571a6" data-id="497571a6" data-element_type="column">
    <div className="elementor-widget-wrap elementor-element-populated">

    {this.contentRows()}

    </div>

    </div>
    </div>
    </section>
    </div>
    </div>
    </div>
    </>
  );
}
}

//export default Elements;
