import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
//import { useRouter } from 'next/navigation';
import { Sidebar } from 'primereact/sidebar';
// import EditAdd from '../EditAdd';
// import InfoCultivation from '../InfoCultivation';
//import SidebarCustom from '../SidebarCustom';
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";
import { useLocation } from "react-router-dom";

import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';

import StartVegFruitsKdes from "views/pages/forms/Trazability/FrutasYVegetalesKdes/StartVegFruitsKdes.js";
import VegFruitsProducersKdes from "views/pages/forms/Trazability/FrutasYVegetalesKdes/VegFruitsProducersKdes.js";
class CardInfo extends React.Component {
  state={
    selectedProdItems:null,
    contentActivity:null,
    visibleRight:false
  }
//const CardInfo = () => {
    render () {
    //const router = useHistory()
    
    const itemsProd = [
        { name: 'Datos productor', code: 'IO', table: 'fruit_vegetable_user', content: StartVegFruitsKdes, permission_column: 'trazabilidad', typeRole:'VF' },
        { name: 'Cosecha', code: 'IC', table: 'create_harvest_kdes', content: VegFruitsProducersKdes, permission_column: 'trazabilidad', typeRole:'VF' },
    ];

    const header = (
        <img 
            alt="production"
            style={{filter: 'invert(75%) sepia(6%) saturate(4859%) hue-rotate(48deg) brightness(106%) contrast(62%)' }}
            src="/layout/images/icons/cosecha.png"  
            className='w-2'
        />
    );

    const selectedItemTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const itemOptionTemplate = (option) => {
        return (
            <div 
                    // onClick={ () => {
                    //     this.setState({contentActivity: option.content })
                    //     this.setState({visibleRight:true}) 
                    // } } 
                    className="flex align-items-center">
                <div>
                    {option.name}
                </div>
            </div>
        );
    };
    return (
        <div>
            <Card title="Información cosecha" header={ header } >

                <Dropdown 
                    value={this.state.selectedProdItems} 
                    onChange={(e) => { 
                        this.setState({selectedProdItems:e.value})
                        if (e.value!=undefined) {
                            this.setState({contentActivity: e.value.content })
                            this.setState({visibleRight:true}) 
                        }
                    }}
                    options={ itemsProd } 
                    optionLabel="name" 
                    showClear 
                    placeholder="Selecciona" 
                    className="w-full p-inputtext-sm" 
                    itemTemplate={ itemOptionTemplate } 
                    dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                    }}
                />
                    <p className="mt-4">
                        {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                    </p> 
            </Card>
              <SidebarCustom 
                  onVisible={this.state.visibleRight}
                  onHide={() => this.setState({visibleRight:false}) }
                  onWidth={ this.state.selectedProdItems?.table=='production_info_satelite_dni_code' ? 1000 : 800 }
                  position="right" 
                  title={this.state.selectedProdItems?.name}
                  >
                    {
                        (this.state.contentActivity==null)
                        ?
                        ('Contenido.')
                        :
                        (<this.state.contentActivity 
                            {...this.props} 
                            info_select={this.state.selectedProdItems}
                            //info_select={selectedOptions}
                            //location={useLocation()}
                            hide={ () => this.setState({visibleRight:false}) }
                            />)
                    }
              </SidebarCustom>

        </div>
    )
  }
}

export default CardInfo;
// const CardInfo = (props) => {

//     const [selectedOptions, setSelectedOptions] = useState(null);
//     const [visibleRight, setVisibleRight] = useState(false);

//     const [visibleRightCultivation, setVisibleRightCultivation] = useState(false);

//     const options = [
//         { name: 'Datos productor', code: 'IO', table: 'fruit_vegetable_user', content: StartVegFruitsKdes, permission_column: 'trazabilidad', typeRole:'VF' },
//         { name: 'Información Cultivo', code: 'IC', table: 'create_harvest_kdes', content: VegFruitsProducersKdes, permission_column: 'trazabilidad', typeRole:'VF' },
//     ];

//     const header = (
//         <img 
//             alt="production" 
//             src="/layout/images/icons/task-list.png"  
//             className='w-2'
//         />
//     );

//     const openSidebar = (code) => {
//             setVisibleRight(true)
//     }

//     const selectedOptionTemplate = (option, props) => {
//         if (option) {
//             return (
//                 <div onClick={ openSidebar(option.code) }>{option.name}</div>
//             );
//         }

//         return <span>{props.placeholder}</span>;
//     };

//     const itemOptionTemplate = (option) => {
 
//         return (
//             <div className="flex align-items-center">
//                 <div>
//                     {option.name}
//                 </div>
//             </div>
//         );
//     };

//     const clearDropdpwn = (visible) => {
//         setSelectedOptions(null);
//         visible(false);
//     }

//     return (
//         <div>
//             <Card title="Información cosecha" header={ header } >
//             <Dropdown 
//                 style={{fontSize: '1rem',color: '#4b5563'}}
//                 value={selectedOptions} 
//                 onChange={(e) => setSelectedOptions(e.value)}
//                 onChange={(e) => { 
//                     this.setState({selectedProdItems:e.value})
//                     if (e.value!=undefined) {
//                         this.setState({contentActivity: e.value.content })
//                         this.setState({visibleRight:true}) 
//                     }
//                 }}
//                 options={options} 
//                 optionLabel="name" 
//                 showClear 
//                 placeholder="Selecciona" 
//                 itemTemplate={itemOptionTemplate} 
//                 valueTemplate={ selectedOptionTemplate }
//                 dropdownIcon={(opts) => {
//                         return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
//                     }}
//                 className="w-full p-inputtext-sm" />
//                 <p className="mt-4">
//                 </p>
//             </Card>

//             <SidebarCustom
//                 onVisible={ visibleRight }
//                 onHide={ () => clearDropdpwn(setVisibleRight) }
//                 onWidth={ 650 }
//                 position="right" 
//                 title={selectedOptions?.name}
//             >
//             <StartVegFruitsKdes 
//                 {...props}
//                 info_select={selectedOptions}
//                 location={useLocation()}
//                 hide={ () => clearDropdpwn(setVisibleRight) }
//             />
//             </SidebarCustom>

//         </div>
//     )
// }

// export default CardInfo;