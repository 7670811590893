import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { login } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
// import { getToken, onMessage } from "firebase/messaging";
// import { messaging } from "network/ConfigFirebase";
const Login = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [checkbox, setCheckbox] = React.useState(true);
  const [changeForm, setChangeForm] = React.useState("1");

  const [focusedNamesn, setfocusedNamesn] = React.useState(false);
  const [namesn, setNamesn] = React.useState("");

  const [focusedDocuments, setfocusedDocuments] = React.useState(false);
  const [documents, setDocuments] = React.useState("");

 const tryLogin = async () => {
  setCheckbox(false)
    setError("");
    // if (changeForm=="1") {
    // }else{
    //   if (documents=="") {
    //     setCheckbox(true)
    //     setError('Es necesario que anote su Cédula')
    //     return
    //   }
    // }
    var changeFormVar = 2
    var password_dni = password
      var valid_email = String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (!valid_email) {
        password_dni = password.replace(/\D/g, "")
        changeFormVar=2
      }else{
        changeFormVar=1
      }

//    const response = undefined
    const response = await login(email, password_dni, changeFormVar).catch(err => {
      setCheckbox(true)
      alert(err.response.data.message)      
    });
    if (response==undefined) {
      return
    }
    //console.log(response.data)
    if (response.data=='incorrecta') {
      setCheckbox(true)
      setError("Datos incorrectos o no existen.");
  }else{
    if (response.data=='deshabilitar') {
      setCheckbox(true)
      setError("Esta cuenta se encuentra temporalmente inactiva.");
      return;
    }
    if (response.data=='validate_email') {
      setCheckbox(true)
      setError("Esta cuenta no se encuentra verificada, se le ha enviado un correo para su activación.");
      setTimeout(function(){ props.history.push('/home/validation',{email:email}) }, 3000);
      return;
    }
      if (response.data=='contrasena') {
        setCheckbox(true)
        setError("Contraseña incorrecta.");
      }else{
        const { data } = response;
        if (data) {
          setError("");
          localStorage.setItem("token", data.token);
          localStorage.setItem("photo_profile", data.namephoto);
          localStorage.setItem("user_wallet", data.wallet);
          localStorage.setItem("user", JSON.stringify(data));
          setCheckbox(false)
            if (data.type_traza=='ON' || data.type_traza=='RE' || data.type_traza=='VA') {
              props.history.push('/admin/report-production-users')
            }else{
              props.history.push("/admin/home");
            }
        } else {
          setCheckbox(true)
          setPassword("");
          setError("Hubo un error al iniciar sesión. Intenta más tarde.");
        }
      }
    }
  };
  const saveTokenFirebase = (getIdUser) =>{
  }
  /*const tryLogin = async () => {
    const response = await login(email, password);
    const { data } = response;

    if (data) {
      setError("");
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      props.history.push("/admin/home");
    } else {
      setPassword("");
      setError("Hubo un error al iniciar sesión. Intenta más tarde.");
    }
  };*/
  return (
    <>
      <AuthHeader
        title="¡Bienvenido!"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb--4">
              <div>
                <div className="text-center text-muted" style={{fontWeight: 'bold', fontSize:'18px'}}>
                  Ingrese su Nombre y Cedula
                </div>
                <div className="text-center text-muted" style={{fontWeight: 'bold', fontSize:'15px'}}>
                  o
                </div>
                <div className="text-center text-muted" style={{fontWeight: 'bold', fontSize:'18px'}}>
                  Correo electronico y Contraseña
                </div>

              <div className="text-center" style={{fontWeight: 'bold',fontSize: '13px',color: 'red'}}>
              <span style={{color: 'red'}}>*Advertencia: </span>
              Ingrese una de esta combinación solamente: <br /> Nombre y Cédula o Correo Electrónico y Contraseña.</div>
                <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-at" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Nombre o Correo electrónico"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                    style={{margin:0}}
                      className={classnames({
                        focused: focusedPassword,
                      }) }
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Cédula o Contraseña"
                          type="text"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </InputGroup>
                    </FormGroup>
                  <Col>
{/*                     <ComponentLink
                      className="font-weight-bold" onClick={()=>{
                        if (changeForm=="1") {
                          setChangeForm("2")
                        }else{
                          setChangeForm("1")
                        }
                      }} to={{pathname:"/home/login"}}>
                      {(changeForm=="1")?("Ingresar datos personales"):("Ingresar correo y contraseña")}
                     </ComponentLink>*/}
                  </Col>
                  <Col style={{textAlign: 'center'}}>
                  {(changeForm=="1")?(
                    <ComponentLink
                      style={{fontSize:'18px'}}
                      className="font-weight-bold" to={{pathname:"/home/reset-password"}}>
                        Presione aqui si se le olvido la contraseña
                     </ComponentLink>
                    ):(null)}
                  </Col>
                  {error ? (
                    <div className="text-muted font-italic">
                      <small>
                        error:{" "}
                        <span className="text-red font-weight-700">
                          {error}
                        </span>
                      </small>
                    </div>
                  ) : null}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="traza-green"
                      type="button"
                      onClick={tryLogin}
                      disabled={
                        (changeForm=="1") ? (!email || !password) : (!namesn || !documents)
                      }
                    >
                      Entrar
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
