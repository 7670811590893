import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production_nama_g, data_trazabilidad, main_content, data_fertilizer_production, fertilizer_production, delete_prodction_nama_g, delete_file_prod } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {getContent, PutFormContentPro, PutFormContentTableProd, forNumeric, PutFormContentFinal} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";
import jQuery from 'jquery';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default class GNamaPLPreparation extends React.Component {
  static contextType = UserContext
  state={
    field_params:[],
    field_params_view:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],    
    dataStatesS:0,
    content_render:null,
    get_content:[],
    get_first_info:[],
    get_first_info_column:[],
    btnd:false,
    change_mrv_data:0,
    userTypeH:1,
    globalFilter:'',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    visibleRight:false
  }
  async componentDidMount(){
     var getData = await Object.values(this.props.sendInfoProps.dataMainContent)
     var getDataColumn = []
     //var getDataColumnTitle = []
   getData.map((a,b)=>{
     if (a.include_info==1) {
      getDataColumn[b]={column:a.name_field,title:a.label_title}
      //getDataColumnTitle[b]=a.label_title
     }
   })
       this.setState({get_first_info:getDataColumn})
  // this.setState({get_content:getData})    
  // this.setState({field_content_log:getData})
  //this.initData(this.props.sendInfoProps)
    if (this.props.setParamsUrl.state!=null) {
      if (this.props.setParamsUrl.state.verify_mrv=='ON') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvON').click()
        })
      }
      if (this.props.setParamsUrl.state.verify_mrv=='RE') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvRE').click()
        })
      }
      if (this.props.setParamsUrl.state.verify_mrv=='VA') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvVA').click()
        })
      }
    }
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({userTypeH:jsondata.userType})
    if (this.props.sendInfoProps.trazaData.length==0) {
      this.setState({visibleRight:true})
      this.initData(null,true)
    }
  }
  initData = async (set_data,verify)=>{
    var data_select = []
      if (this.props.sendInfoProps.dataMainContent!=null) {
        this.setState({field_content: Object.values(this.props.sendInfoProps.dataMainContent) })
      }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataTrazabilidadvf = set_data

          var dataRestore = localStorage.getItem('data_register_storage_'+this.props.tableCall)
        if (dataTrazabilidadvf==null) {
          if (dataRestore!=null) {
              dataTrazabilidadvf=JSON.parse(dataRestore)
          }
        }else{
          // if (dataTrazabilidadvf.id==null) {
          //   dataTrazabilidadvf=JSON.parse(dataRestore)
          // }
          if (dataTrazabilidadvf.id==null) {
            if (dataRestore!=null) {
              dataTrazabilidadvf=JSON.parse(dataRestore)
            }
          }
        }

        //var dataTrazabilidadvf = await data_fertilizer_production(id_set,this.props.tableCall,JSON.stringify(getContent(this.state.field_content_log).f_data),gerParam)
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
            this.cleanData(Object.values(this.props.sendInfoProps.dataMainContent),verify)
        }else{
            this.setDataCampos(dataTrazabilidadvf,Object.values(this.props.sendInfoProps.dataMainContent),verify)
        }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {
      //console.log(this.state.idT,this.state.field_params)
      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
      }
        if (prevState.field_params !== this.state.field_params) {
        }
      }

     setDataCampos = (data,dataMainContent,pass_select)=>{
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
       //delete data.id
       delete data.count_table
       //this.setState({field_params:data})
       var field_params_title={}
       var field_params_required={}
       var field_params_select={}
       var field_params_params={}
       var field_params_detail_mrv={}
       var new_content=[]
       dataMainContent.map((a,b)=>{
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field

         if (data[a.name_field]==null) {
          field_params_params[a.name_field]=''
          field_params_detail_mrv[a.name_field]=''
         }else{
          field_params_params[a.name_field]=data[a.name_field]
          field_params_detail_mrv[a.name_field]=""
            if (a.type_field=='date') {
              //field_params_params[a.name_field]=data[a.name_field]
              field_params_detail_mrv[a.name_field]= data[a.name_field]
            }
            if (a.type_field=='time') {
              //field_params_params[a.name_field]=data[a.name_field]
              field_params_detail_mrv[a.name_field]=data[a.name_field]
            }
          if (a.type_field=='file') {
            if (typeof data[a.name_field]=='string') {
             if (data[a.name_field].split('|').length>0) {
              var multi_fil = []
               data[a.name_field].split('|').map((a,b)=>{
                 multi_fil[b] = {name:a,filed:null}
               })
                var item = []
                item = [...multi_fil]
              field_params_params[a.name_field]=item
             }
            }
          }
          if (a.include_info==1) {
            field_params_detail_mrv[a.name_field]=data[a.name_field]
          }
         }
          if (a.type_field=='date') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=completeDate
            }else{
              field_params_params[a.name_field]= new Date(data[a.name_field]+"T00:00:00")
            }
            //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
          }
          if (a.type_field=='time') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=timeString
            }
              //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
         if (a.type_field=='select') {
            var get_data_select = this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            //field_params_select[a.name_field] = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
       })
       if (pass_select) {
        // var data_select_release = {}
        // Object.entries(field_params_select).map((as,bs)=>{
        //   if (as[1].length!=0) {
        //     data_select_release[as[0]]=as[1]
        //   }
        // })
         this.setState({optionsSelect: field_params_select})
         this.setState({selectForFilter: field_params_select})

       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
      //  var thisState= this
      // jQuery(document).click(function(e) {
      //     if (jQuery(e.target).hasClass('view_detail_panel')) {
      //       thisState.setState({idT:'null'})
      //       thisState.setState({idTP:data?.id})
      //       thisState.setState({change_mrv_data:1})
      //       thisState.setState({field_params: field_params_detail_mrv,field_params_view:field_params_params })
      //       dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=1}})
      //       var listItem = jQuery(e.target);
      //       var getIndex = jQuery( ".view_detail_panel" ).index( listItem )
      //       thisState.setState({putPositionBtn:getIndex})
      //     }else{
      //       thisState.setState({change_mrv_data:0})
      //       if (jQuery(e.target).hasClass('newRegister')) {
      //         thisState.setState({idT:'null'})
      //         thisState.setState({field_params_view:[] })
      //       }else{
      //         if (!jQuery(e.target).hasClass('upload_files')) {
      //         }
      //       }
      //        dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=0}})
      //     }
      // });

        //this.setState({idT:data?.id})
        if (data?.id==undefined) {
          this.setState({idT:null})
        }else{
          this.setState({idT:data?.id})
        }
        this.setState({field_params: field_params_params,field_params_view:[] })
        this.setState({field_params_title: field_params_title})
        this.setState({field_params_required: field_params_required})
     }
     cleanData = (dataMainContent,pass_select) =>{
           this.setState({idT:'null'})
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
           var field_params_title={}
           var field_params_required={}
           var field_params_select={}
           var field_params_params={}
       dataMainContent.map((a,b)=>{
         field_params_params[a.name_field]=''
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field
         if (pass_select) {
           if (a.type_field=='date') {
            field_params_params[a.name_field]=completeDate
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=timeString
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
           }
         }else{          
           if (a.type_field=='date') {
            field_params_params[a.name_field]=null
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=null
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
           }
         }
          // if (a.type_field=='file') {
          //     var item = [{name:'',filed:null}]
          //     field_params_params[a.name_field]=item
          // }
         if (a.type_field=='select') {
            var get_data_select =this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')

            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
       })
       if (pass_select) {
        if (Object.keys(field_params_select).length!=0) {
         this.setState({optionsSelect: field_params_select})
         this.setState({selectForFilter: field_params_select})
        }
       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
         this.setState({field_params: field_params_params })
         this.setState({field_params_title: field_params_title })
         this.setState({field_params_required: field_params_required })
     }

  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async () =>{

      this.setState({btnd:true})
      this.setState({mess:['Enviando...']})
      if (this.state.field_params_required==undefined) {
        this.setState({btnd:false})
        return
      }
    
    var field_data = Object.entries(this.state.field_params)
     var field_data_remove=[]
     var counter= 0
     field_data.map((c,i)=>{
       if (c[0]!='created_at' && c[0]!='id') {
         field_data_remove[counter]=c
       }
       counter++
     })
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess_asis = []
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
      // if (this.state.for_filters!=undefined) {
      //   if (this.state.for_filters[a[0]]!=undefined) {
      //     if (!this.state.for_filters[a[0]].valid) {
      //       a[1]=0
      //     }
      //   }
      // }
        if (field_data_remove[b]!=undefined) {
          if (field_data_remove[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data_remove[b][0]]:field_data_remove[b][1]}
          }
        }
        if (field_data_remove[b]!=undefined) {
          if (field_data_remove[b][0]==a[0]) {
           if (a[1]==1 && (field_data_remove[b][1]=='' || field_data_remove[b][1]==null)) {
            counter++
            var get_t = field_data_name.filter(af=>af[0]==a[0])
            if (get_t[0]!=null) {
              data_mess_asis[counter]=get_t[0][1]
              data_mess[counter] = 'El campo '+get_t[0][1]+' es requerido';
            }else{
              data_mess_asis[counter]=a[0]
              data_mess[counter] = 'El campo '+a[0]+' es requerido';
            }
           }
          }
        }
    })
    if (data_mess.length>0) {
      window.setAssistantValidate(data_mess_asis.filter(Boolean)[0],'fields_validations')
      this.context.setToastMess('error', 'Validación', data_mess.filter(Boolean)[0])
      //this.setState({mess: data_mess.filter(Boolean) })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

       var gerParam = new URLSearchParams(window.location.search).get("code");
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
         this.setState({btnd:true})
          const var_vf = await create_production_nama_g(jsondata._id,field_data_remove.sort(),field_data_name.sort(),gerParam,this.props.tableCall,this.state.idT,data_for_file,this.props.titleCall);
          if (var_vf==null) {
            this.setState({btnd:false})
            return
          }
              if (var_vf.message=="exito") {
                this.context.setToastMess('success', 'Guardado', 'Información actualizado')
                this.setState({mess:''})
                window.setAssistantValidate(null,'save_success')
                this.setState({btnd:false})
                await this.props.refreshData()
                if (this.state.idT=='null' || this.state.idT==null) {
                  var dataRestore = localStorage.getItem('data_register_storage_'+this.props.tableCall)
                  if (dataRestore!=null) {
                    localStorage.removeItem('data_register_storage_'+this.props.tableCall)
                  }
                }
                if (this.state.idT=='null') {
                  this.initData(null,false)
                }else{
                  var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
                  if (Object.values(this.state.optionsSelect).length!=0) {
                    this.initData(null,false)
                   // this.setState({optionsSelect: []})
                   // this.setState({selectForFilter: []})
                  }
                 //this.initData(get_data_new[0],true)
                }
               if (this.state.change_mrv_data==1) {
                //jQuery('#btn_change_state_'+this.state.idTP).click()
                jQuery(jQuery('.btn_change_state')[this.state.putPositionBtn]).click()
               }
               if (this.state.visibleRight) {
                this.setState({visibleRight:false})
               }
              }
  }

 geolocacion = (options,indx_position) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(e,options,indx_position)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (posicion,optcon,indx_position)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    this.setFieldValue(lat+','+lon+'-(Información restante)',optcon.id,optcon)
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon,indx_position)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }
    //      setinfolocation = (lat,lon,data,optcon,indx_position) => {
    //           this.setState({loadlocali:null})
    //           this.state.field_content.map((val,ind)=>{
    //             if (val.link!=null) {
    //               var get_csc=val.link.split('.')[1]
    //               if (get_csc=='vgp') {
    //                var itemvgp = this.state.field_params
    //                var itemvgps = itemvgp[indx_position]
    //                itemvgps[val.name_field] = String(data.results[0].components.country)
    //                itemvgp[indx_position]=itemvgps
    //                 this.setState({field_params: itemvgp })
    //               }
    //               if (get_csc=='vge') {
    //                var itemvge = this.state.field_params
    //                var itemvges = itemvge[indx_position]
    //                itemvges[val.name_field] = String(data.results[0].components.state)
    //                itemvge[indx_position]=itemvgps
    //                 this.setState({field_params: itemvge })
    //               }
    //               if (get_csc=='vgc') {
    //                var itemvgc = this.state.field_params
    //                var itemvgcs = itemvgc[indx_position]
    //                itemvgcs[val.name_field] = String(data.results[0].components.city)
    //                itemvgc[indx_position]=itemvgps
    //                 this.setState({field_params: itemvgc })
    //               }
    //             }
    //             if (val.select_geo=="geo_country") {
    //               if (val.list_default=='ldp') {
    //                 this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',null)
    //               }
    //               if (val.list_default=='lde') {
    //                 this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
    //               }
    //             }
    //           })
    //           this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    // }

    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    }
     contentForSelect = (data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        if (this.state.idT==null || this.state.idT=='null') {
          var thisSet = this
          setTimeout(function() {
            thisSet.setSessionTimer()
          },500)
        }
    }
    //  setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states,indx_position) => {
    //     this.setState({dataStatesS:0})
    //     this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
    //       if (if_states==undefined) {
    //          var itemState = this.state.field_params
    //          var itemStates = itemState[indx_position]
    //          itemStates[field] = value
    //          itemState[indx_position]=itemStates
    //         this.setState({field_params: itemState})
    //       }
    //     if (list_default=='lde') {
    //       if (if_states!=undefined) {
    //         var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
    //          var item = this.state.field_params
    //          var items = item[indx_position]
    //          items[field] = value_for_states[0].value
    //          item[indx_position]=items
    //         this.setState({field_params: item})
    //       }
    //   }
    //     this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    // }
     setFieldValue = (value, field, isrequired) =>{
       this.setState({field_params: {...this.state.field_params, [field]:value }})
       if (isrequired!=null) {
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
        if (isrequired.getAttribute('data_filter')!=null) {
          isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
            if (a!='upf') {
              Object.entries(this.state.selectForFilter).map((val,indx)=>{
                if (val[0]==a) {
                  var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                  this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
                }
              })
            }
          })
        }
       }
        if (this.state.idT==null || this.state.idT=='null') {
          var thisSet = this
          setTimeout(function() {
            thisSet.setSessionTimer()
          },500)
        }
    }
    setSessionTimer =()=>{
      localStorage.setItem('data_register_storage_'+this.props.tableCall, JSON.stringify(this.state.field_params) )
    }
     setFieldValueFiles = (value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod(this.state.idT,this.props.tableCall,field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {

             var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
             // this.setState({optionsSelect: []})
             // this.setState({selectForFilter: []})
             // this.initData(get_data_new[0],true)
          }
         }
      this.setState({field_params: item })
       }
    }

     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          var dataSelect = {}
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        dataSelect = this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default)
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
          return dataSelect
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      var dataSelect = {}
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          dataSelect = this.contentForSelect(CodigoAranF,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          dataSelect = this.contentForSelect(CodigoAranM,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      //this.setState({selectForFilter: this.state.optionsSelect})
      return dataSelect
          //console.log(list_default,value_option,name_field,req_field,for_values,this.state.optionsSelect)
    }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
deleteFer = async (set_val,id_set) => {
    await this.initData(set_val,false)
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(window.location.search).get("code");

    var field_data = Object.entries(this.state.field_params)
    var field_data_remove=[]
    var counter= -1
    field_data.map((c,i)=>{
      if (c[0]!='created_at') {
        field_data_remove[counter]=c
      }
      counter++
    })
       var field_data_name = Object.entries(this.state.field_params_title)
       var dataReturn = await delete_prodction_nama_g(set_val.id,jsondata._id,field_data_remove,field_data_name,gerParam,this.props.tableCall,this.props.titleCall)
               if (dataReturn=="exito") {
               //this.setState({mess:'Se eliminó exitosamente.'})
               this.context.setToastMess('success', 'Eliminado', 'Información eliminada')
               //this.cleanData(Object.values(this.props.sendInfoProps.dataMainContent),false)
               await this.props.refreshData()
               //window.location.reload()
             }else{
              this.context.setToastMess('error', 'Error', 'La información no pudo ser eliminada')
              this.setState({btnd:false})
             }

 }
 insertNewRegister =()=>{
  var date = new Date()
  var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
  var completeDateBefore = completeDateAfter.split('/')
  var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
  var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
  //if (this.state.field_params[this.state.field_params.length-1]!=undefined) {
  // var push_shift = [...this.state.field_params,{id:this.state.field_params[this.state.field_params.length-1].id+1}]
  // }else{
  // }
  var push_shift = [...this.state.field_params,{id:null}]
  this.setState({field_params: push_shift })
 }
     listCountryCode = CountrysData.sort(this.listaArray)
  render(){
    const header = (
        <div className="flex flex-column flex-md-row md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Gestionar Producción
            </h5>
            {(this.props.sendInfoProps.trazaData.length==0)?(null):(<>
              <span className="block mt-2 md:mt-0 p-input-icon-left">
                  <i className="pi pi-search" />
                  <Input 
                  type="search" 
                  value={this.state.globalFilter}
                  //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                  onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                  placeholder="Buscar..." 
                  />
              </span>
              </>)}

            <Button 
                label="Nueva" 
                icon="pi pi-plus" 
                severity="success" 
                className="btn-fod-green mr-2" 
                onClick={() => {
                  this.setState({visibleRight:true})
                  this.initData(null,true)
                }}
                />

        </div>
    );
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
    const accept = () => {
      //this.saveInfo()
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Cancelado', life: 3000 });
    }
    const confirm1 = (data,bool,mess) => {
        confirmDialog({
            message: 'Estas seguro en '+mess+' esta información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept:()=>{
              this.deleteFer(data,bool)
            },
            reject
        });
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button 
                    icon="pi pi-pencil" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    tooltip="Editar Información" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                    onClick={()=>{
                      this.setState({visibleRight:true})
                      this.initData(rowData,true)
                    }}
                />

                 <Button 
                    icon="pi pi-trash" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    tooltip="Borrar Información" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                    onClick={() => {
                      //console.log('eliminar')
                      //this.deleteFer(rowData,true)
                      confirm1(rowData,true,'eliminar')
                    }}
                />
            </>
        );
    };
    const actionBodyText = (column,rowData) => {
      if (rowData[column]!=null) {
        if (rowData[column].length>20) {
          return `${rowData[column].substring(0, 20)}...`
        }else{
          return rowData[column]
        }
      }else{
        return rowData[column]
      }
    }
    const hideErase = () => {
      this.setState({visibleRight:false})

      if (Object.values(this.state.optionsSelect).length!=0) {
        //this.initData(null,false)
        this.setState({optionsSelect: []})
        this.setState({selectForFilter: []})
      }
      window.setValueMultiPassFunc(true)
    }
  return (
    <>
    <ConfirmDialog />

      <SidebarCustom 
          onVisible={this.state.visibleRight} 
          onHide={() => hideErase() }
          onWidth={ 650 }
          position="right" 
          title={this.props.titleCall}>
        <div id={"insideModal-"+this.props.tableCall+'-'+this.props.titleCall.replaceAll(' ','_')}>
            <div className="p-fluid mt-5">
              <div className="formgrid grid">
              {(this.state.field_content.length!=0)?(<>

                  <PutFormContentPro 
                  content={this.state.field_content}
                  field_params={this.state.field_params}
                  optionsSelect={this.state.optionsSelect}
                  setFieldValue={this.setFieldValue}
                  setFieldValueFiles={this.setFieldValueFiles}
                  setFieldValueSelect={this.setFieldValueSelect}
                  deleteImgState={this.deleteImgState}
                  forSearhData={this.forSearhData}
                  geolocacion={this.geolocacion}
                   />
                </>):(null)}

              </div>
            </div>

            <div className="absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400">
                <div className="flex justify-content-end pt-3 pr-3 pl-3">
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button 
                                label="Cerrar"
                                 severity="secondary" 
                                 onClick={() => {
                                  hideErase()
                                  //this.props.hide()
                                }} size="small" />
                        </div>

                        <div className="field col">
                            <Button 
                            disabled={this.state.btnd}
                            onClick={()=>{
                              this.saveInfo()
                            }} label="Guardar" size="small" className='btn-fod-green' />
                        </div>
                    </div>
                </div>
            </div>
{/*                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}*/}
            
        </div>
            <br />
            <br />
            <br />
      </SidebarCustom>
        <Container className="mt-0">
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card card-table">
                    <DataTable
                        value={this.props.sendInfoProps.trazaData}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        <Column key="id" field="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>
                        {/*<Column field="created_at" header="Fecha" sortable></Column>*/}
                        {this.state.get_first_info.map((a,b)=>{
                          return (<Column 
                            key={b}
                            field={a.column}
                            body={actionBodyText.bind(this,a.column)}
                            header={a.title} 
                            sortable
                            ></Column>)
                        })}
                        <Column key={this.state.get_first_info.length} body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
      </Container>
    </>
  );
 }
}

