import React, { useContext, useState } from 'react';
import CardPack from './CardPack';
import CardInfo from './CardInfo';
import CardProducer from './CardProducer';
import CardDistributor from './CardDistributor';
import CardEstablishment from './CardEstablishment';
import CardDocuments from './CardDocuments';
import CardEndProduction from './CardProductionEnd';
import {UserContext} from "layouts/store";
import { ProgressSpinner } from 'primereact/progressspinner';

const Panel = (props) => {
    const { valueGlobal } = useContext(UserContext);
    const { type_traza } = valueGlobal
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
  return (

    <div className='card-table'>
      {(type_traza==undefined)?(<ProgressSpinner style={{display:'block'}} />):(<>
        <div className="formgrid grid justify-content-center">
          {(type_traza?.indexOf('VF')!=-1)?(<>
            <div className="field col-3">
              <CardInfo {...props}/>
            </div>
            </>):(null)}

            {(type_traza?.indexOf('V1F')!=-1)?(
              <div className="field col-3">
                <CardPack {...props} />
              </div>
            ):(null)}

            {(type_traza?.indexOf('V2F')!=-1)?(
              <div className="field col-3">
                <CardProducer {...props} />
              </div>
            ):(null)}


  {/*        <div className="formgrid grid justify-content-center">
          </div>*/}
            {(type_traza?.indexOf('V3F')!=-1)?(
              <div className="field col-3">
                <CardDistributor {...props} />
              </div>
            ):(null)}

            {(type_traza?.indexOf('V4F')!=-1)?(
              <div className="field col-3">
                <CardEstablishment {...props} />
              </div>
            ):(null)}

            {(valueGlobal.permission?.indexOf('documents')!=-1)?(
              <div className="field col-3">
                <CardDocuments {...props} />
              </div>
            ):(null)}
            {
              (jsondata.userType==2)?(null):(
                <div className="field col-3">
                  <CardEndProduction {...props} />
                </div>
                )
            }

        </div>
      </>)}
    </div>
  )
}

export default Panel