import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import ModalPlantDatosView from "./ModalExtend/ModalPlantDatosView";
import { row_fertilizer_production_general } from "network/ApiAxios";
import jQuery from 'jquery';

export default class PlantaDatosView extends React.Component {
    state={
    dataPlant:[],
  }
async componentDidMount(){
      if(this.props.paramsCode==undefined || this.props.paramsCode==null){
        var codigoTraza = 'nocode';
      }else{
        var codigoTraza = this.props.paramsCode;
      }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
        var dataPlant = await row_fertilizer_production_general(jsondata._id,codigoTraza,null,'create_plant_product,create_plant_sample,create_plant_treatment,create_plant_weight_product')
        this.setState({dataPlant:dataPlant})
}
  render(){

  return (
    <>
        <ModalPlantDatosView sendInfoProps={this.state} passRouteTraza={this.props.paramsCode} />
    </>
  );
 }
}

//export default Elements;
