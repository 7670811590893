import React, {createRef} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import { get_account_wallet, create_wallet, update_wallet } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import NotificationAlert from "react-notification-alert";
import config from "config";
import jQuery from 'jquery';
import Web3 from 'web3';
import {GuideMessage} from "views/pages/components/GuideMessage";
//https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/
var web3 = new Web3('https://bsc-dataseed.binance.org/')
var web3Socket = new Web3('wss://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/');
//var web3Socket = new Web3("wss://bsc-dataseed.binance.org/");

class UserWallet extends React.Component {
constructor(props,other){
  super(props)
  // var getdata = await get_account_wallet(jsondata._id)
  // var walletAdd = JSON.parse(getdata.account)
   //var getBalance = web3Socket.eth.getBalance('0xACcf510E44Dd55c4768C5d3175472286f2AFAC35').then(result=> {console.log(result)});
   //web3Socket.eth.getBlockNumber().then(console.log);
   this.initWebSocket(1)
}
state={
  accounts_data:'',
  getBalance:'',
  notificationAlertRef: React.createRef(),
  disabledbtn:false,
  openModal:false,
  changeToTransfer:1,
  password_wallet:'',
  trasfer_address:'',
  value_send:0,
  encrypt_btn_valid:1,
  loadSection:'Cargando...'
}
async componentDidMount(){
this.setData()
}
async componentWillUnmount(){
  this.initWebSocket(2)
}
initWebSocket = async (type) => {
  if (type==1) {

    const subscription = web3Socket.eth.subscribe('pendingTransactions', (error, blockHeader) => {
    if (error) return console.log(error);
    this.trasactionValidation(blockHeader)
    }).on('data', (blockHeader) => {
      console.log(blockHeader)
    });

    // unsubscribes the subscription
    subscription.unsubscribe((error, success) => {
    if (error) return console.log(error);

    console.log('Successfully unsubscribed!');
    });

  }
}
trasactionValidation = async (txnAdress) => {
  if (this.state.accounts_data.address!=undefined) {
    var varGlobal = this;
    var addressUserNormal = this.state.accounts_data.address;
    var addressUser = this.state.accounts_data.address.toLocaleLowerCase()
    web3Socket.eth.getTransaction(txnAdress, async (err, returnedValue) => {
      if (returnedValue!=undefined) {
            if (returnedValue.from!=null) {
              var validAdressOne = returnedValue.from.toLocaleLowerCase().indexOf(addressUser) !=-1;
            }else{
              var validAdressOne = false
            }

            if (returnedValue.to!=null) {
              var validAdressTwo = returnedValue.to.toLocaleLowerCase().indexOf(addressUser) !=-1;
            }else{
              var validAdressTwo = false
            }
           if ( validAdressOne || validAdressTwo ) {
            // console.log(`TransactionHash: ${returnedValue.hash}`);
               // var getBalance = await web3.eth.getBalance(addressUserNormal);
               // var ethBalance = web3.utils.fromWei(getBalance, 'ether')
               // varGlobal.setState({getBalance:ethBalance})
               this.setData()
           }
      }
      })
  }
}
setData = async () => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var getdata = await get_account_wallet(jsondata._id)
  if (getdata=='no_account') {
        this.setState({
      getBalance:'no_account',
      accounts_data:'no_account',
      loadSection:'Sin Cuenta.'})
  }else{
    var walletAdd = JSON.parse(getdata.account)
    var getBalance = await web3.eth.getBalance(walletAdd.address);
    var ethBalance = web3.utils.fromWei(getBalance, 'ether')
    console.log(Web3.givenProvider)
    this.setState({
      getBalance:ethBalance,
      accounts_data:walletAdd})
  }
}

render(){
  const createWallet = async () => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    const strWallet = web3.utils.sha3( Math.random(0, 1000000).toString(16) + web3.utils.randomHex(32) )
    const wallet = web3.eth.accounts.create(strWallet)
    var result = await create_wallet(wallet,jsondata._id)
    if (result=='exito') {
      localStorage.setItem("user_wallet", JSON.stringify(wallet));
      this.setData()
    }else{
      alert('Ha ocurrido un error, intente mas tarde.')
    }
  }
  const encripWallet = async (password,password_repeat) => {
    this.setState({disabledbtn:true})
    if (password=='') {
      notify("danger", "No se pudo guardar", "Es necesario una contraseña.");
      this.setState({disabledbtn:false})
    }else{
      if (password!=password_repeat) {
      notify("danger", "No se pudo guardar", "La contraseña no coincide.");
      this.setState({disabledbtn:false})
      }else{
            var userdata = localStorage.getItem('user');
            var jsondata = JSON.parse(userdata);
            try{
            var privateWalletEncrip = null
            if (this.state.accounts_data.crypto!=null) {
             privateWalletEncrip = web3.eth.accounts.decrypt(this.state.accounts_data,password)
            }else{
             privateWalletEncrip = web3.eth.accounts.encrypt(this.state.accounts_data.privateKey,password)
            }
             var result = await update_wallet(privateWalletEncrip,jsondata._id)
             if (result=='exito') {
              localStorage.setItem("user_wallet", JSON.stringify(privateWalletEncrip));
               this.setState({disabledbtn:false})
               this.setState({openModal:false})
               notify("success", "Cambios realizados exitosamente!", "Cuenta encryptada exitosamente.");
               this.setData()
             }else{
               this.setState({disabledbtn:false})
               alert('Ha ocurrido un error, intente mas tarde.')
             }
            }catch(error){
              notify("danger", "No se pudo cambiar", "La contraseña es incorrecta.");
              this.setState({disabledbtn:false})
            }
      }
    }
  }

  const optionModal = (type) => {
    this.setState({openModal:type})
  };
  const accessAccount = (password) => {
    this.setState({disabledbtn:true})
    try{

        this.setState({disabledbtn:false,changeToTransfer:1})
        var privateWalletEncrip = web3.eth.accounts.decrypt(this.state.accounts_data,password)
        this.setState({accounts_data:privateWalletEncrip,encrypt_btn_valid:2})
    }catch(error){
        notify("danger", "No se pudo guardar", "La contraseña es incorrecta.");
        this.setState({disabledbtn:false})
    }


  };
  const transferCrypto = (type) => {
    this.setState({changeToTransfer:type})
  };
  const sendGas = async (address_send,value,my_address) => {
    this.setState({disabledbtn:true})
    if (address_send=='' || value=='') {
              this.setState({mess:'Todos los campos son necesarios.'})
              this.setState({disabledbtn:false})
    }else{
      this.setState({mess:'Enviando'})
      this.setState({disabledbtn:false})
      const nonce = await web3.eth.getTransactionCount(my_address.address, 'latest');
      var valueClean = value.replaceAll(',','.');
      var minValue = web3.utils.toWei(String(valueClean), 'ether')
       const tx = {
         'from': my_address.address,
         'to': address_send,
         'nonce': nonce,
         'value': minValue,
         'gas':300000
       };
       var globalVar = this;
        const signPromise = web3.eth.accounts.signTransaction(tx, my_address.privateKey)
         signPromise.then((signedTx) => {
            web3.eth.sendSignedTransaction(signedTx.rawTransaction)
            .once('sending', function(payload){ 
              globalVar.setState({mess:'Enviando'})
              console.log(payload)
               })
            .once('sent', function(payload){ 
              globalVar.setState({mess:'Enviado'})
              console.log(payload)
               })
            .once('transactionHash', function(hash){ globalVar.setState({mess:'Confirmando hash: '+hash}) })
            .once('receipt', function(result){ console.log(result) })
            .on('confirmation', function(result){ console.log(result) })
            .on('error', function(result){ console.log(result)
                                            globalVar.setState({mess:'Error en la transaccion'})
                                          })
            .then(async function(result){ 
              var getBalance = await web3.eth.getBalance(my_address.address);
              var ethBalance = web3.utils.fromWei(getBalance, 'ether')
              globalVar.setState({
                      mess:'Enviado Correctamente.',
                      value_send:0,
                      getBalance:ethBalance})
              console.log(result)
            });
         }).catch((err) => {
           console.log("Promise failed:", err);
           globalVar.setState({mess:'Error en la transaccion'})
         });

    }
  };

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <ModalEditUserWallet
      sendOpenModal={this.state.openModal}
      setModal={optionModal}
      saveEncrypt={encripWallet} 
      disabledextend={this.state.disabledbtn} />
      <div
        className="header pb-8 d-flex align-items-center"
        style={{
          minHeight: "250px",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-traza-green opacity-8" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col md="12">
              <h1 className="display-4 text-white">Mi Billetera</h1>
            </Col>
              <Col md={4}>
                <GuideMessage section="wallet" section_two="none" section_table_form="null" />
              </Col>
          </Row>
        </Container>
      </div>
          <br />

      <Container className="mt--9" fluid>
        <Row>
          <Col className="order-xl-1" xl="6">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col md="4">
                    <h3 className="mb-0">Cuenta</h3>
                  </Col>
                                                                            {(this.state.accounts_data.address==null)?(null):(
                                                                              (this.state.changeToTransfer==1)?(
                                                                                <Col className="text-right" md="4">
                                                                                <Button
                                                                                  color="traza-blue"
                                                                                  href="#"
                                                                                  onClick={() => {transferCrypto(2)}}
                                                                                  size="sm"
                                                                                >
                                                                                  Hacer transferencia
                                                                                </Button>
                                                                              </Col>
                                                                                ):(
                                                                                <Col className="text-right" md="4">
                                                                                <Button
                                                                                  color="traza-blue"
                                                                                  href="#"
                                                                                  onClick={() => {transferCrypto(1)}}
                                                                                  size="sm"
                                                                                >
                                                                                 Volver
                                                                                </Button>
                                                                              </Col>
                                                                                )
                                                                              )}
                  {(this.state.accounts_data=='no_account')?(<Col className="text-right" md="4">
                                      <Button
                                        color="traza-blue"
                                        href="#"
                                        onClick={createWallet}
                                        size="sm"
                                        disabled={this.state.disabledbtn}
                                      >
                                        Generar Cuenta
                                      </Button>
                                    </Col>):
                  ((this.state.accounts_data.crypto!=null)?(<Col className="text-right" md="4">
                                      <Button
                                        color="traza-blue"
                                        href="#"
                                        onClick={() => {optionModal(true)}}
                                        size="sm"
                                        disabled={this.state.disabledbtn}
                                      >
                                        Desencriptar Esta Cuenta
                                      </Button>
                                    </Col>):
                    ((this.state.accounts_data.address==null)?(null):((this.state.encrypt_btn_valid==2)?(null):(<Col className="text-right" md="4">
                                                                              <Button
                                                                                color="traza-blue"
                                                                                href="#"
                                                                                onClick={() => {optionModal(true)}}
                                                                                size="sm"
                                                                                disabled={this.state.disabledbtn}
                                                                              >
                                                                                Encriptar Esta Cuenta
                                                                              </Button>
                                                                            </Col>))))}
                  <Col md="12">
                    <label style={{color:'red'}}>*</label><b>Atención: </b><p style={{marginBottom: 0}}>Antes de hacer alguna transacción por favor corrija y asegúrese de que la dirección sea la correcta.</p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                <Col md={12} className="text-center pb-4">
                {
                  (this.state.accounts_data.address==null)?(<h2>{this.state.loadSection}</h2>):(
                  (this.state.accounts_data.crypto!=null)?(<>
                    <Col className="text-left" md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Contraseña
                        </label>
                        <Input
                        onChange={(e)=>{this.setState({password_wallet:e.target.value})}}
                          placeholder="Escribe la contraseña para besbloquear"
                          type="password"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="text-right" md="12">
                                                          <Button
                                                            color="traza-blue"
                                                            href="#"
                                                            onClick={() => {accessAccount(this.state.password_wallet)}}
                                                            size="sm"
                                                            disabled={this.state.disabledbtn}
                                                          >
                                                            Desbloquear
                                                          </Button>
                                                        </Col>
                  </>):(
                  (this.state.changeToTransfer==1)?(<>
                  <h2> {this.state.getBalance} BNB</h2>
                  <h4> {this.state.accounts_data.address} </h4></>
                  ):(
                  <>
                  <h2> {this.state.getBalance} BNB</h2>
                  <h4> {this.state.accounts_data.address} </h4>
                    <Col className="text-left" md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Dirección:
                        </label>
                        <Input
                          onChange={(e)=>{this.setState({trasfer_address:e.target.value})}}
                          value={this.state.trasfer_address}
                          placeholder="Pega una Dirección: 0xXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="text-left" md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Valor a enviar:
                        </label>
                        <Input
                          onChange={(e)=>{this.setState({value_send:e.target.value})}}
                          value={this.state.value_send}
                          placeholder="Establezca un valor a enviar"
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                                    {this.state.mess ? (
                                      <div className="text-muted font-italic">
                                        <small>
                                          <span className="text-red font-weight-700">
                                            {this.state.mess}
                                          </span>
                                        </small>
                                      </div>
                                    ) : null}
                    <Col className="text-right" md="12">
                                                          <Button
                                                            color="traza-blue"
                                                            href="#"
                                                            onClick={() => {sendGas(this.state.trasfer_address,this.state.value_send,this.state.accounts_data)}}
                                                            size="sm"
                                                            disabled={this.state.disabledbtn}
                                                          >
                                                            Enviar
                                                          </Button>
                                                        </Col>
                  </>
                  )
                  )
                  )
                  
                    }
                </Col>

                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
 }
}

export default UserWallet;

export class ModalEditUserWallet extends React.Component {
  state={
    password:'',
    password_repeat:''
  }
render(){
  return (
  <Modal isOpen={this.props.sendOpenModal} size="md">
        <ModalBody>
          <CardHeader>
            <Row>
            <Col md={11}>
            <h3 className="mb-0">Encryptar Cuenta</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {this.props.setModal(false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>

        <Card className="mb-4">
                <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                    placeholder="Escribe una contraseña para encryptar"
                    type="password"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Repetir Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password_repeat:e.target.value})}}
                    placeholder="Repita la contraseña"
                    type="password"
                  />
                </FormGroup>
              </Col>
          <CardBody>
            <Row>
              <Col md="5">
                <Button onClick={()=>{this.props.saveEncrypt(this.state.password,this.state.password_repeat)}} disabled={this.props.disabledextend} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Aceptar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.props.setModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
  );
 }
}